/* eslint-disable @typescript-eslint/ban-types */
import { assignIn, flow, map, reduce } from 'lodash/fp';
import { gql, InMemoryCache, Resolvers } from '@apollo/client';

const mergeGet = (attributeName: string) =>
  flow(
    // pick a single attribute from each object
    map(attributeName),
    // merge all values into a single object
    reduce(assignIn, {}),
  );

export const buildDefaultStore = (stores: Record<string, unknown>[]) =>
  mergeGet('defaults')(stores);
export class CreateClientStore {
  resolvers: Resolvers;

  constructor(cache: InMemoryCache, stores: {}[]) {
    // Merge all defaults
    const defaultStore = buildDefaultStore(stores);
    // Merge all mutations
    const mutations = mergeGet('mutations')(stores);
    // Construct the Client State with the given mutations and defaults
    this.resolvers = { Mutation: mutations };
    cache.writeQuery({
      query: gql`
        {
          planView
          previousMapView
          oldMapBounds
          mapMoved
          hoveredLocation
          recentViews
          navigatorInfo
          firstToFailView
          notFoundLocation
          locationUploadFile
          locationBuildingMatch
          editLocationSource
          roiBuiltObjects
          simulationForm
        }
      `,
      data: defaultStore,
    });
  }
}
