import * as React from 'react';
import { Navigate, Outlet, Link as RouterLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material/';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { makeStyles } from '@mui/styles';

import {
  BLURRY,
  FINANCE_HOME_PATH,
  GLASS_BG_MED,
  HOME_PATH,
  ORANGE,
  PURPLE,
  SIDE_PANEL_WIDTH,
} from '../../../util/productGlobals';
import {
  LocationStatus,
  MibRequestStatus,
  useGetEditLocationSourceQuery,
  useGetOriginalLocationsFileRecordQuery,
} from '../../../__generated__/graphql';
import { App } from '../../../PlanningApp/AppConfig';
import DetailCardBackButton from '../SidePanel/DetailView/DetailCardBackButton';
import MatchQuality from './MatchQuality';
import useHasFinanceEntitlement from '../../../Hooks/useHasFinanceEntitlement';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'absolute',
    zIndex: 5,
    overflow: 'hidden',
    pointerEvents: 'none',
    right: '10px',
    width: `${SIDE_PANEL_WIDTH}px`,
    top: 86,
    height: `calc(100vh - 180px)`,
    '& > *': {
      pointerEvents: 'auto',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    overflowY: 'auto',
    maxHeight: '100%',
    backgroundColor: GLASS_BG_MED,
    borderRadius: theme.spacing(1.5),
    backdropFilter: BLURRY,
    padding: theme.spacing(2, 2, 4, 2),
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  cardHeader: {
    padding: theme.spacing(0, 4, 0, 2),
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'end',
    padding: theme.spacing(2, 0),
    overflow: 'hidden',
  },
  addressStyle: {
    marginTop: theme.spacing(4),
  },
  cardContent: {
    overflowX: 'clip',
    padding: theme.spacing(4, 2, 6, 2),
  },
  link: {
    marginTop: theme.spacing(3),
  },
  icon: {
    alignContent: 'start',
    marginRight: theme.spacing(2),
  },
}));

const LocationMatchCard: React.FC = () => {
  const { container, card, cardActions, cardHeader, cardContent, addressStyle, icon } = useStyles();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;

  const {
    data: { editLocationSource },
  } = useGetEditLocationSourceQuery();

  const { data, loading, error } = useGetOriginalLocationsFileRecordQuery({
    variables: { locationID: id },
  });

  if (loading) {
    return (
      <div className={container} data-test-id="locationManagementSidePanel">
        <Card className={card} elevation={0} />
      </div>
    );
  }

  if (error || !data) {
    App.error('[LocationMatchCard - useGetOriginalLocationsFileRecordQuery] error: ', error);
    return <Navigate to="/locations/notFound" />;
  }

  const locRecord = data?.user?.productSettings?.originalFile?.[0];
  if (!locRecord?.audit) {
    return null;
  }

  const {
    locationMeta: {
      address: { formattedAddress: originalAddrStr },
    },
    builtObjectRequestStatus,
    processStatus,
    locationMeta: { name: locationName },
    builtObjectID,
  } = locRecord.audit;
  const matchedBuildingAddrStr =
    locRecord?.audit?.locationMeta?.builtObjectAddress?.formattedAddress;

  if (!originalAddrStr) {
    return null;
  }

  const matchIsValid =
    processStatus === LocationStatus.Success &&
    builtObjectID &&
    matchedBuildingAddrStr &&
    builtObjectRequestStatus !== MibRequestStatus.Pending;

  return (
    <div className={container} data-test-id="locationManagementSidePanel">
      <Card className={card} elevation={0}>
        <div data-test-id="LocationMatchCard" style={{ overflowX: 'hidden' }}>
          <CardActions className={cardActions} disableSpacing>
            <Grid container direction="row">
              <Grid item>
                <DetailCardBackButton
                  backUrl={
                    editLocationSource?.url || (isFinanceEntitled ? FINANCE_HOME_PATH : HOME_PATH)
                  }
                />
              </Grid>
            </Grid>
          </CardActions>
          <CardHeader
            className={cardHeader}
            title={
              <Typography
                variant="h2"
                data-test-id={`${LocationMatchCard.displayName}-locationName`}
              >
                {locationName || t('locationMatch:unknownLocation')}
              </Typography>
            }
            action={
              <IconButton
                component={RouterLink}
                to={`/locations/${id}/edit`}
                data-test-id={`${LocationMatchCard.displayName}-editButton`}
                aria-label="edit address"
                title="edit address"
              >
                <EditIcon color="info" />
              </IconButton>
            }
          />
          <CardContent data-test-id="LocationMatchCard-content" className={cardContent}>
            <>
              <div className={addressStyle}>
                <Typography color="textSecondary">
                  {t('locationMatch:mapPopup:originalAddressPin')}
                </Typography>
                <Grid container direction="row" alignItems="center" style={{ flexWrap: 'nowrap' }}>
                  <LocationOnIcon
                    className={icon}
                    style={{ color: ORANGE }}
                    data-test-id="PinIcon-original-address"
                  />
                  <Typography data-test-id={`${LocationMatchCard.displayName}-originalAddress`}>
                    {originalAddrStr}
                  </Typography>
                </Grid>
              </div>

              <div className={addressStyle}>
                <Typography color="textSecondary">
                  {t('locationMatch:mapPopup:buildingMatchPin')}
                </Typography>

                {matchIsValid && (
                  <>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      sx={{ flexWrap: 'nowrap', mb: 2 }}
                    >
                      <LocationOnIcon
                        style={{ color: PURPLE }}
                        className={icon}
                        data-test-id="PinIcon-building-match"
                      />
                      <Typography
                        data-test-id={`${LocationMatchCard.displayName}-matchedBuildingAddress`}
                      >
                        {matchedBuildingAddrStr}
                      </Typography>
                    </Grid>
                    <div className={addressStyle}>
                      <Typography color="textSecondary">
                        {t('locationMatch:matchQualityDescriptor')}
                      </Typography>
                    </div>
                  </>
                )}
              </div>
              <MatchQuality locRecord={locRecord} />
            </>
          </CardContent>
          <Outlet context={{ locRecord }} />
        </div>
      </Card>
    </div>
  );
};
LocationMatchCard.displayName = 'LocationMatchCard';
export default LocationMatchCard;

export const LocationMatchNotFound: React.FC = () => {
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;
  const { container, card, cardActions, cardContent } = useStyles();
  return (
    <div className={container} data-test-id="locationManagementSidePanel">
      <Card className={card} elevation={0}>
        <div data-test-id="LocationMatchCard-inner" style={{ overflowX: 'hidden' }}>
          <CardActions className={cardActions} disableSpacing>
            <Grid container direction="row">
              <Grid item>
                <DetailCardBackButton backUrl={isFinanceEntitled ? FINANCE_HOME_PATH : HOME_PATH} />
              </Grid>
            </Grid>
          </CardActions>

          <CardContent data-test-id="LocationMatchCard-notFound" className={cardContent}>
            <Typography>PLACEHOLDER: Location Not Found</Typography>
          </CardContent>
        </div>
      </Card>
    </div>
  );
};
LocationMatchNotFound.displayName = 'LocationMatchNotFound';
