import { ApolloCache, NormalizedCacheObject, Resolver } from '@apollo/client';
import { cloneDeep } from 'lodash/fp';

import {
  ClearSimulationMutationVariables,
  GetSimulationFormDocument,
  GetSimulationFormQuery,
  MutationAddRegionArgs,
  MutationUpdateLifelineDowntimesArgs,
  MutationUpdateRegionOccupancyTypesArgs,
  MutationUpdateSimulationInfoArgs,
  SimulationForm,
  SimulationFormRegion,
} from '../../../__generated__/graphql';

export const addRegion: Resolver = (
  _: unknown,
  { region, occupancyTypes, downtimes }: MutationAddRegionArgs,
  { cache }: { cache: ApolloCache<NormalizedCacheObject> },
) => {
  const storeData = cache.readQuery<GetSimulationFormQuery>({
    query: GetSimulationFormDocument,
  });
  const oldData = cloneDeep(storeData);
  const newRegion: SimulationFormRegion = {
    id: String(oldData.simulationForm.simulationFormRegions.length + 1),
    priority: oldData.simulationForm.simulationFormRegions.length + 1,
    region,
    occupancyTypes: occupancyTypes || [],
    lifelineDowntimes: {
      id: String(oldData.simulationForm.simulationFormRegions.length + 1),
      port: downtimes?.port || 0,
      power: downtimes?.power || 0,
      repairTime: downtimes?.repairTime || 0,
      highway: downtimes?.highway || 0,
      bridge: downtimes?.bridge || 0,
      airport: downtimes?.airport || 0,
      building: downtimes?.building || 0,
      __typename: 'SimulationLifelineDowntime',
    },
    __typename: 'SimulationFormRegion',
  };

  const newData: GetSimulationFormQuery['simulationForm']['simulationFormRegions'] =
    oldData.simulationForm.simulationFormRegions.concat(newRegion);
  cache.writeQuery({
    data: {
      simulationForm: {
        name: oldData.simulationForm.name,
        type: oldData.simulationForm.type,
        simulationFormRegions: newData,
        __typename: 'SimulationForm',
      },
    },
    query: GetSimulationFormDocument,
  });
  return newRegion;
};

export const updateLifelineDowntimes: Resolver = (
  _: unknown,
  { regionid, downtimes }: MutationUpdateLifelineDowntimesArgs,
  { cache }: { cache: ApolloCache<NormalizedCacheObject> },
) => {
  const storeData = cache.readQuery<GetSimulationFormQuery>({
    query: GetSimulationFormDocument,
  });
  const newData = cloneDeep(storeData);
  const region = newData?.simulationForm?.simulationFormRegions?.find(
    (region) => region.id === regionid,
  );

  if (region) {
    region.lifelineDowntimes = {
      id: region.lifelineDowntimes.id,
      ...downtimes,
      __typename: 'SimulationLifelineDowntime',
    };
  }

  cache.writeQuery({
    data: newData,
    query: GetSimulationFormDocument,
  });
  return region;
};

export const updateRegionOccupancyTypes: Resolver = (
  _: unknown,
  { regionid, occupancyTypes }: MutationUpdateRegionOccupancyTypesArgs,
  { cache }: { cache: ApolloCache<NormalizedCacheObject> },
) => {
  const storeData = cache.readQuery<GetSimulationFormQuery>({
    query: GetSimulationFormDocument,
  });
  const newData = cloneDeep(storeData);
  const region = newData?.simulationForm?.simulationFormRegions?.find(
    (region) => region.id === regionid,
  );

  if (region) {
    region.occupancyTypes = [...occupancyTypes];
  }

  cache.writeQuery({
    data: newData,
    query: GetSimulationFormDocument,
  });

  return region;
};

export const clearSimulation: Resolver = (
  _: unknown,
  // eslint-disable-next-line no-empty-pattern
  {}: ClearSimulationMutationVariables,
  { cache }: { cache: ApolloCache<NormalizedCacheObject> },
) => {
  cache.writeQuery({
    data: {
      simulationForm: {
        name: '',
        type: '',
        simulationFormRegions: [],
        __typename: 'SimulationForm',
      } as SimulationForm,
    },
    query: GetSimulationFormDocument,
  });
  return {
    simuationForm: {
      name: '',
      type: '',
      simulationFormRegions: [],
      __typename: 'SimulationForm',
    },
  };
};

export const updateSimulationInfo: Resolver = (
  _: unknown,
  { name, type }: MutationUpdateSimulationInfoArgs,
  { cache }: { cache: ApolloCache<NormalizedCacheObject> },
) => {
  const storeData = cache.readQuery<GetSimulationFormQuery>({
    query: GetSimulationFormDocument,
  });
  const oldData = cloneDeep(storeData);
  if (name !== undefined && name !== null) {
    oldData.simulationForm.name = name;
  }
  if (type !== undefined && type !== null) {
    oldData.simulationForm.type = type;
  }
  cache.writeQuery({
    data: oldData,
    query: GetSimulationFormDocument,
  });
  return oldData;
};

export const simulationFormMutations = {
  addRegion,
  updateLifelineDowntimes,
  updateRegionOccupancyTypes,
  clearSimulation,
  updateSimulationInfo,
};
