import * as React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Layer, Source } from 'react-mapbox-gl';
import { GeoJSONSourceRaw, LngLatBounds, Map } from 'mapbox-gl';
import { LayerMouseEvent, LocationFeatureProps, LocationProps, OnecWindow } from 'onec-types';
import {
  GeoJsonFeature,
  GetCompanyMarkersQuery,
  useGetCompanyMarkersQuery,
  useGetHoveredLocationQuery,
  useGetMapViewQuery,
  useUpdateHoveredLocationMutation,
} from '../../../__generated__/graphql';
import useApiErrSnackbar from '../../CommonComponents/Snackbar/useApiErrSnackbar';
import { clusterLayersConfig, mapFeatureToLocationProps } from '../Map/Cluster/clusterHelpers';
import useSetPreviousMapBounds from '../../../Hooks/useSetPreviousMapBounds';
import { FINANCE_HOME_PATH } from '../../../util/productGlobals';
import Spinner from '../../CommonComponents/Spinner/Spinner';
import LocationPopup from '../Map/Cluster/LocationPopup';
import { CLUSTERED_FILTER, TEXT_LAYOUT, UNCLUSTERED_FILTER } from '../Map/Cluster/ClusterLayers';

const MapWrapperFi: React.FC = () => {
  const { id: companyid } = useParams<{ id: string }>();
  const { data, error, loading } = useGetCompanyMarkersQuery({
    variables: {
      company: companyid,
    },
  });
  const mapboxMap = (window as OnecWindow).reactMap;
  const [showLocationPopup, setShowLocationPopup] = React.useState(false);
  const {
    data: {
      hoveredLocation: { hoveredLocationId },
    },
  } = useGetHoveredLocationQuery();

  const [updateHoveredLocation] = useUpdateHoveredLocationMutation();
  const onHoverPoint = React.useCallback(
    (locationId: string) => {
      updateHoveredLocation({ variables: { locationId } });
    },
    [updateHoveredLocation],
  );

  const sourceId = 'demo-markers-source';

  const locConfig = React.useMemo(() => {
    const { clusterMarker, locationMarker, halo } = clusterLayersConfig.notVulnerable;
    return { clusterMarker, locationMarker, halo };
  }, []);

  const locVulConfig = React.useMemo(() => {
    const { clusterMarker, locationMarker, halo } = clusterLayersConfig.vulnerable;
    return { clusterMarker, locationMarker, halo };
  }, []);

  const [clusteredPaint, clusteredAtRiskPaint, textPaint, haloPaint] = [
    {
      'circle-color': locConfig.clusterMarker.backgroundColor,
      'circle-radius': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        locConfig.clusterMarker.radius - locConfig.clusterMarker.borderWidthHovered,
        locConfig.clusterMarker.radius - locConfig.clusterMarker.borderWidth,
      ],
      'circle-stroke-width': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        locConfig.clusterMarker.borderWidthHovered,
        locConfig.clusterMarker.borderWidth,
      ],
      'circle-stroke-color': locConfig.clusterMarker.borderColor,
    },
    {
      'circle-color': locVulConfig.clusterMarker.backgroundColor,
      'circle-radius': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        locVulConfig.clusterMarker.radius - locVulConfig.clusterMarker.borderWidthHovered,
        locVulConfig.clusterMarker.radius - locVulConfig.clusterMarker.borderWidth,
      ],
      'circle-stroke-width': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        locVulConfig.clusterMarker.borderWidthHovered,
        locVulConfig.clusterMarker.borderWidth,
      ],
      'circle-stroke-color': locVulConfig.clusterMarker.borderColor,
    },
    { 'text-color': locConfig.clusterMarker.textColor },
    {
      'circle-radius': [
        'match',
        ['get', 'atRisk'],
        'true',
        locVulConfig.halo.haloRadius,
        locConfig.halo.haloRadius,
      ],
      'circle-blur': 0.57,
      'circle-color': [
        'match',
        ['get', 'atRisk'],
        'true',
        locVulConfig.halo.haloColor,
        locConfig.halo.haloColor,
      ],
      'circle-opacity': [
        'match',
        ['get', 'atRisk'],
        'true',
        locVulConfig.halo.haloOpacity,
        locConfig.halo.haloOpacity,
      ],
    },
  ];

  const setMapBounds = useSetPreviousMapBounds();
  const navigate = useNavigate();

  const setFeatureState = React.useCallback(
    (featureSourceId: string, locId: string | number, state: any) => {
      mapboxMap.setFeatureState(
        { source: featureSourceId, id: locId },
        {
          ...mapboxMap.getFeatureState({ source: featureSourceId, id: locId }),
          ...state,
        },
      );
    },
    [mapboxMap],
  );

  const clearFeatureState = React.useCallback(
    (featureSourceId: string) => {
      if (mapboxMap) {
        mapboxMap.removeFeatureState({ source: featureSourceId });
      }
    },
    [mapboxMap],
  );

  const onClickPoint = React.useCallback(
    async (location?: LocationProps) => {
      if (location && location.id) {
        setMapBounds();
        navigate(`${FINANCE_HOME_PATH}/detail/${location.id}`);
      } else {
        navigate(FINANCE_HOME_PATH);
      }
    },
    [navigate, setMapBounds],
  );

  const onClickCompanyMarker = React.useCallback(
    (evt: LayerMouseEvent<LocationFeatureProps>) => {
      const location = evt.features[0];
      onClickPoint(mapFeatureToLocationProps(location));
      mapboxMap.getCanvas().style.cursor = '';
    },
    [onClickPoint, mapboxMap],
  );

  const onMouseEnterUnclusteredPoint = React.useCallback(
    (evt: LayerMouseEvent<LocationFeatureProps>) => {
      if (hoveredLocationId) {
        clearFeatureState(sourceId);
      }
      const { id: locationId } = evt.features[0].properties;
      const featureId = evt.features[0].id;
      onHoverPoint(String(locationId));
      setShowLocationPopup(true);
      setFeatureState(sourceId, featureId, { hover: true });
      mapboxMap.getCanvas().style.cursor = onClickPoint ? 'pointer' : 'default';
    },
    [hoveredLocationId, onHoverPoint, setFeatureState, mapboxMap, onClickPoint, clearFeatureState],
  );

  const onMouseLeaveUnclusteredPoint = React.useCallback(() => {
    if (hoveredLocationId) {
      clearFeatureState(sourceId);
    }
    onHoverPoint(null);
    setShowLocationPopup(false);
    mapboxMap.getCanvas().style.cursor = '';
  }, [hoveredLocationId, onHoverPoint, mapboxMap, clearFeatureState]);

  const companyMarkers = data?.getCompany?.builtObjects;

  const createJSON = React.useMemo(() => {
    // Make a geojson from the list of locations, where each location is a Geojson Point Feature.
    const features: GeoJsonFeature[] = (companyMarkers ?? []).map(
      (location: GetCompanyMarkersQuery['getCompany']['builtObjects'][0], locationIdx: number) => {
        const { id, coordinates, finance } = location;
        const position: GeoJSON.Position = coordinates;

        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: position,
          },
          id: String(locationIdx), // Note that in a geojson, the feature id must be an int that is turned into a string
          properties: {
            id,
            coordinates: position,
            atRisk: finance?.highRisk > 0.5 ? 'true' : 'false',
          },
        } as GeoJsonFeature;
      },
    );
    const atRiskFeatures = features.filter(
      (feature: GeoJsonFeature) => feature.properties.atRisk === 'true',
    );
    const regularFeatures: GeoJsonFeature[] = features.filter(
      (feature: GeoJsonFeature) => feature.properties.atRisk === 'false',
    );

    return {
      atRiskJSON: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: atRiskFeatures,
        },
      } as GeoJSONSourceRaw,
      regularJSON: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: regularFeatures,
        },
      } as GeoJSONSourceRaw,
    };
  }, [companyMarkers]);

  const { enqueueApiErrSnackbar } = useApiErrSnackbar();

  const { data: previousMapViewData } = useGetMapViewQuery();
  React.useEffect(() => {
    // If coming from Detail View (or another URL) back to List View, then move the map
    // bounds back to bounds  previously saved just before last leaving list view.
    if (previousMapViewData) {
      const { southEast, northWest } = previousMapViewData.previousMapView;
      const map: Map = (window as OnecWindow).reactMap;
      if (map) {
        const bounds = new LngLatBounds(
          [southEast.longitude, southEast.latitude],
          [northWest.longitude, northWest.latitude],
        );
        map.fitBounds(bounds, {}, { fitboundUpdate: true });
      }
    }
  }, [previousMapViewData]);

  React.useEffect(() => {
    if (error) {
      enqueueApiErrSnackbar();
    }
  }, [enqueueApiErrSnackbar, error]);

  if (error) {
    return null;
  }

  if (loading) {
    return <Spinner />;
  }

  // TODO: Refactoring into reuseable layers

  return (
    <>
      <Source
        id={sourceId}
        geoJsonSource={{
          ...createJSON.atRiskJSON,
          cluster: true,
          clusterMaxZoom: 15,
          clusterRadius: 75,
        }}
      />
      <Source
        id={`${sourceId}-nv`}
        geoJsonSource={{
          ...createJSON.regularJSON,
          cluster: true,
          clusterMaxZoom: 15,
          clusterRadius: 75,
        }}
      />

      {/* Cluster circles (aka "cluster markers", although they are really map features) */}
      <Layer
        id={`${sourceId}-circles-nv`}
        sourceId={`${sourceId}-nv`}
        type="circle"
        filter={CLUSTERED_FILTER}
        paint={clusteredPaint}
      />
      {/* The text showing the number (of unclustered points) inside each cluster circle */}
      <Layer
        id={`${sourceId}-count-labels-nv`}
        sourceId={`${sourceId}-nv`}
        type="symbol"
        filter={CLUSTERED_FILTER}
        layout={TEXT_LAYOUT}
        paint={textPaint}
      />
      {/* Cluster circles (aka "cluster markers", although they are really map features) */}
      <Layer
        id={`${sourceId}-circles`}
        sourceId={sourceId}
        type="circle"
        filter={CLUSTERED_FILTER}
        paint={clusteredAtRiskPaint}
      />
      {/* The text showing the number (of unclustered points) inside each cluster circle */}
      <Layer
        id={`${sourceId}-count-labels`}
        sourceId={sourceId}
        type="symbol"
        filter={CLUSTERED_FILTER}
        layout={TEXT_LAYOUT}
        paint={textPaint}
      />
      <Layer
        id="demo_markers-layer-nv"
        sourceId={`${sourceId}-nv`}
        type="circle"
        filter={UNCLUSTERED_FILTER}
        paint={{
          'circle-opacity': 1,
          'circle-radius': [
            'case',
            [
              'all',
              ['==', ['get', 'atRisk'], 'false'],
              ['boolean', ['feature-state', 'hover'], false],
            ],
            locConfig.locationMarker.radius - locConfig.locationMarker.borderWidthHovered,
            [
              'all',
              ['==', ['get', 'atRisk'], 'false'],
              ['boolean', ['feature-state', 'hover'], true],
            ],
            locConfig.locationMarker.radius - locConfig.locationMarker.borderWidth,
            0,
          ],
          'circle-stroke-width': [
            'case',
            [
              'all',
              ['==', ['get', 'atRisk'], 'false'],
              ['boolean', ['feature-state', 'hover'], false],
            ],
            locConfig.locationMarker.borderWidthHovered,
            [
              'all',
              ['==', ['get', 'atRisk'], 'false'],
              ['boolean', ['feature-state', 'hover'], true],
            ],
            locConfig.locationMarker.borderWidth,
            0,
          ],
          'circle-stroke-color': [
            'match',
            ['get', 'atRisk'],
            'false',
            locConfig.locationMarker.borderColor,
            locVulConfig.locationMarker.borderColor,
          ],
          'circle-color': [
            'match',
            ['get', 'atRisk'],
            'false',
            locConfig.locationMarker.backgroundColor,
            locVulConfig.locationMarker.backgroundColor,
          ],
        }}
        onMouseEnter={onMouseEnterUnclusteredPoint}
        onMouseLeave={onMouseLeaveUnclusteredPoint}
        onClick={onClickCompanyMarker}
      />
      <Layer
        id="demo_markers-layer-v"
        sourceId="demo-markers-source"
        type="circle"
        filter={UNCLUSTERED_FILTER}
        paint={{
          'circle-opacity': 1,
          'circle-radius': [
            'case',
            [
              'all',
              ['==', ['get', 'atRisk'], 'true'],
              ['boolean', ['feature-state', 'hover'], false],
            ],
            locVulConfig.locationMarker.radius - locVulConfig.locationMarker.borderWidthHovered,
            [
              'all',
              ['==', ['get', 'atRisk'], 'true'],
              ['boolean', ['feature-state', 'hover'], true],
            ],
            locVulConfig.locationMarker.radius - locVulConfig.locationMarker.borderWidth,
            0,
          ],
          'circle-stroke-width': [
            'case',
            [
              'all',
              ['==', ['get', 'atRisk'], 'true'],
              ['boolean', ['feature-state', 'hover'], false],
            ],
            locVulConfig.locationMarker.borderWidthHovered,
            [
              'all',
              ['==', ['get', 'atRisk'], 'true'],
              ['boolean', ['feature-state', 'hover'], true],
            ],
            locVulConfig.locationMarker.borderWidth,
            0,
          ],
          'circle-stroke-color': [
            'match',
            ['get', 'atRisk'],
            'true',
            locVulConfig.locationMarker.borderColor,
            locConfig.locationMarker.borderColor,
          ],
          'circle-color': [
            'match',
            ['get', 'atRisk'],
            'true',
            locVulConfig.locationMarker.backgroundColor,
            locConfig.locationMarker.backgroundColor,
          ],
        }}
        onMouseEnter={onMouseEnterUnclusteredPoint}
        onMouseLeave={onMouseLeaveUnclusteredPoint}
        onClick={onClickCompanyMarker}
      />
      <Layer id={`${sourceId}-halo`} sourceId={sourceId} type="circle" paint={haloPaint} />
      {/* Only show location popup on map when hovered and in List View */}
      {showLocationPopup && hoveredLocationId && <LocationPopup locationId={hoveredLocationId} />}
      <Outlet />
    </>
  );
};
MapWrapperFi.displayName = 'MapWrapperFi';
export default MapWrapperFi;
