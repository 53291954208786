import * as jsCookie from 'js-cookie';
import * as request from 'superagent';

// eslint-disable-next-line import/no-cycle
import { AuthConfig, CsrfContext } from './Authorizer';

import {
  BadInputErr,
  ImpersonateErr,
  InvalidBodyParamErr,
  KeycloakApiErr,
  LogoutErr,
  UserinfoErr,
} from './AuthError';
import { App } from '../AppConfig';

// js-cookie import is treated differently in browser vs test environment
// `jsCookie` is undefined if imported directly in test
const cookies = (jsCookie as any).default ?? jsCookie;

/*
 * A module to perform user administration operations with keycloak API.
 *
 * Features provided as middlewares:
 *  * impersonating another user
 *  *  handle 401 errors specifically to signal relogin
 *
 * TODOs
 * * [ ] explore agentOptions: { ca: ... }
 */

export class Admin {
  config: AuthConfig;

  csrf: CsrfContext;

  onCsrfChange: any;

  csrfToken: string;

  constructor(config: AuthConfig) {
    if (!(config != null && config instanceof AuthConfig)) {
      throw new BadInputErr('expected arg {Authconfig} in constructor');
    }
    this.config = config;
    this.csrf = new CsrfContext({ csrfTokenHeader: this.config.get('csrfTokenHeader') });
    this.onCsrfChange = null;
    // log.setLevel(this.config.get('loglevel') || 'info');
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  setOnCsrfChange(handler: Function): void {
    this.onCsrfChange = handler;
  }

  setCsrf(csrfProps: CsrfContext): void {
    this.csrf = Object.assign(this.csrf, csrfProps);
  }

  getCsrf(): CsrfContext {
    return this.csrf;
  }

  // getNonce generates a random state (256 bits) to be checked after a
  // loop-back exchange.
  static getNonce(): string {
    const randArray = new Uint32Array(8);
    const { crypto } = window;
    crypto.getRandomValues(randArray);
    return randArray[0].toString();
  }

  // updateCsrfState updates the current state with the latest CSRF token returned in a response.
  updateCsrfState(resp: request.Response): void {
    if (this.csrf && resp.header[this.csrf.csrfTokenHeader]) {
      // notify parent of an updated CSRF token
      this.csrf.csrfToken = resp.header[this.csrf.csrfTokenHeader];
      if (this.onCsrfChange && typeof this.onCsrfChange === 'function') {
        this.onCsrfChange(resp.header[this.csrf.csrfTokenHeader]);
      }
    }
  }

  // ensureCsrf returns a promise to perform an initial GET request to make
  // sure the CSRF token is enabled. This is only carried out
  // when the token is empty.
  // eslint-disable-next-line consistent-return
  ensureCsrf() {
    if (!(this.csrf && this.csrf.csrfTokenHeader) || this.csrf.csrfToken !== '') {
      // resolve immediately: either CSRF is not relevant or we have already have a token
      return Promise.resolve({});
    }

    // now we call a dummy service asynchronously
    // TODO: jake this should hit a different app endpoint
    try {
      request
        .get(this.config.get('authApiURL'))
        .type('json')
        .accept('json')
        .withCredentials()
        .ok(() => true)
        .set(this.getCsrfTokenHeader()) // if the passed object is empty, no header set
        .then((dummyResponse) => this.updateCsrfState(dummyResponse));
    } catch (e) {
      // ignore errors here
      return Promise.resolve({});
    }
  }

  getCsrfTokenHeader() {
    const csrfTokenHeader = {} as {
      [index: string]: string;
    };

    if (!(this.csrf && this.csrf.csrfTokenHeader && this.csrfToken !== '')) {
      return csrfTokenHeader;
    }
    csrfTokenHeader[this.csrf.csrfTokenHeader] = this.csrf.csrfToken;
    return csrfTokenHeader;
  }

  // impersonate switches current session to the provider userid.
  // It returns a cookie.
  impersonate(userid: any, token: string) {
    if (!userid) {
      // usage error
      return Promise.reject(new InvalidBodyParamErr('userid is required'));
    }
    if (!token) {
      // usage error
      return Promise.reject(new InvalidBodyParamErr('token is required'));
    }

    // exchange keycloak session cookies
    // this POST is pushed against the keycloak server, not the API gateway.
    // We are only interested in the returned cookies.
    return (
      request
        .post([this.config.get('impersonateURL'), userid, 'impersonation'].join('/'))
        .type('json')
        .accept('json')
        // note that this endpoint is CORS-enabled,
        // and does not accept cookies as credentials (only headers)
        .withCredentials()
        .auth(token, { type: 'bearer' })
        .ok((res: request.Response) => {
          return res.status < 300;
        })
        .catch((e: string) => {
          return Promise.reject(new ImpersonateErr(e));
        })
    );
  }

  // getUser retrieves a user's description
  getUser(userid: string) {
    if (!userid) {
      // usage error
      return Promise.reject(new InvalidBodyParamErr('userid is required'));
    }

    return request
      .get([this.config.get('adminURL'), 'users', userid].join('/'))
      .type('json')
      .accept('json')
      .withCredentials()
      .ok((res) => res.status < 300)
      .then((resp) => {
        this.updateCsrfState(resp);
        return resp.body; // UserRepresentation object
      })
      .catch((e) => {
        return Promise.reject(new KeycloakApiErr(e));
      });
  }

  makeClientCookie(nonce: string): void {
    this.clearClientCookie();

    const encodedRef = btoa(`${this.config.get('signInConfirmationURL')}?nonce=${nonce}`);
    const fiveMinutes = 0.006944444;
    cookies.set('request_uri', encodedRef, {
      expires: fiveMinutes,
      path: this.config.get('callbackPath'),
      domain: this.config.get('cookieDomain'),
      secure: true,
    });
  }

  // clearClientCookie removes the cookie create by the user agent, needed at sign-in time.
  // This cookie is used by the gatekeeper to capture the final redirection to app.
  clearClientCookie(): void {
    const domain = this.config.get('cookieDomain');
    const callbackPath = this.config.get('callbackPath');
    cookies.remove('request_uri', { domain, path: callbackPath });

    const domainArray = domain.split('.').slice(1);
    const oldDomain = `${domainArray.join('.')}`;
    cookies.remove('request_uri', { domain: oldDomain, path: callbackPath });
  }

  // userinfo returns claims in the access token known to the API gatekeeper
  // NOTE: this is not the OIDC userinfo endpoint.
  userinfo() {
    return request
      .get(this.config.get('tokenURL'))
      .withCredentials()
      .then((resp) => {
        // update CSRF context
        this.updateCsrfState(resp);
        return resp.body;
      })
      .catch((e) => {
        return Promise.reject(new UserinfoErr(e));
      });
  }

  logout() {
    return (
      request
        .get(this.config.get('logoutURL'))
        .withCredentials()
        // TODO: this raises an error for empty response: not blocking, but pollutes the console
        .type('json')
        .ok(() => true)
    );
  }
}

export const AppLogout = (): void => {
  App.debug('logging out');
  sessionStorage.removeItem('impersonator');

  const adm = new Admin(
    new AuthConfig({
      loginURL: App.config.authconfig.loginurl,
      homeURL: App.config.authconfig.homeurl,
      clientID: App.config.authconfig.clientid,
      cookieDomain: App.config.authconfig.cookiedomain,
    }),
  );

  adm
    .logout()
    .catch((e: any) => {
      // non blocking error
      App.error(new LogoutErr(e));
    })
    .finally(() => {
      window.location.reload();
    });
};
