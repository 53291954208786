import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import { Button, Theme } from '@mui/material';

type FormActionButtonsProps = {
  onClickRestoreDefaults: (e: React.FormEvent) => void;
  onClickCancel: () => void;
  onClickApply: (e: React.FormEvent) => void;
  submitting: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  actionButtonsFooter: {
    marginTop: theme.spacing(8),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

const FormActionButtonsFC: React.FC<FormActionButtonsProps> = ({
  onClickApply,
  onClickRestoreDefaults,
  onClickCancel,
  submitting,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <footer className={classes.actionButtonsFooter}>
      <Button
        name="resetToDefaults"
        variant="text"
        color="primary"
        disabled={submitting}
        onClick={onClickRestoreDefaults}
        style={{ marginRight: '20px' }}
      >
        {t('buttons:restoreDefaults')}
      </Button>
      <div>
        <Button
          name="cancel"
          variant="outlined"
          color="primary"
          disabled={submitting}
          onClick={onClickCancel}
          style={{ marginRight: '10px' }}
        >
          {t('buttons:cancel')}
        </Button>
        <Button
          name="apply"
          type="submit"
          variant="contained"
          color="primary"
          disabled={submitting}
          onClick={onClickApply}
        >
          {t('buttons:apply')}
        </Button>
      </div>
    </footer>
  );
};
const FormActionButtons = React.memo(FormActionButtonsFC);

FormActionButtons.displayName = 'FormActionButtons';
export default FormActionButtons;
