import * as React from 'react';

import {
  Button,
  CardContent,
  CircularProgress,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { DARKBLUE, GLASS_BG, OVERFLOW_Y } from '../../../../util/productGlobals';
import Icon from '../../../CommonComponents/Icon/Icon';
import PowerROISidebar from './PowerROISidebar';
import { getLocalizedRange, getLocalizedValue } from '../../../../util/productUtils';
import { CalculateRoiMutation, RoiStatus } from '../../../../__generated__/graphql';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
    margin: 0,
    padding: 0,
    height: '100%',
  },
  articleContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%',
    '& article': {
      maxWidth: '100% - 100px',
      '& ul, & ol': {
        paddingInlineStart: '1.25rem',
      },
      '& li': {
        fontSize: theme.typography.body1.fontSize,
        lineHeight: 'initial',
        'list-style-type': 'inherit',
      },
      '& p': {
        fontSize: theme.typography.body1.fontSize,
        lineHeight: 'initial',
      },
    },
    backgroundColor: DARKBLUE,
  },
  scrollContainer: {
    flexGrow: 1,
    marginLeft: theme.spacing(18.5),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(32),
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: GLASS_BG,
    },
  },
  closeContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(4),
  },
  closeButton: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(248, 247, 242, 0.1)',
    },
    '&:active': {
      backgroundColor: 'rgba(248, 247, 242, 0.2)',
    },
  },
  table: {
    marginBottom: theme.spacing(8),
    paddingBottom: theme.spacing(2),
    maxWidth: '100% - 100px',
    '& .MuiTableCell-root': {
      border: '1px solid #979794',
      ...theme.typography.caption,
    },
    '& .MuiTableCell-head': {
      fontWeight: 800,
      ...theme.typography.body2,
    },
  },
}));

const TextSkeleton = () => (
  <>
    <Skeleton variant="text" height={28} width="100%" />
    <Skeleton variant="text" height={28} width="100%" />
    <Skeleton variant="text" height={28} width="100%" />
  </>
);

const TableSkeleton = () => <Skeleton variant="rectangular" width="100%" height={100} />;

type PowerROIContentProps = {
  builtObjectDetails?: {
    propertyType?: string;
    id?: string;
    builtObjectID?: string;
    type: 'LocationObject' | 'BuiltObject';
  };
  handleClose: () => void;
  powerROIData: {
    suggestions: string;
    status: RoiStatus;
    zeroMeanStddevMsg: string;
    options: any[];
    error: CalculateRoiMutation['calculateROI']['error'];
    assumptions: string;
    handleRetry: () => void;
  };
  isDemoUser?: boolean;
};

const PowerROIContent: React.FC<PowerROIContentProps> = ({
  builtObjectDetails,
  handleClose,
  powerROIData,
  isDemoUser,
}) => {
  const { container, articleContainer, scrollContainer, closeContainer, closeButton, table } =
    useStyles();
  const scrollContainerRef = React.useRef<HTMLDivElement>();
  const { status, error, suggestions, zeroMeanStddevMsg, options, assumptions, handleRetry } =
    powerROIData;
  const { t } = useTranslation();

  return (
    <CardContent className={container} data-test-id="PowerROIContent-container">
      {!isDemoUser && <PowerROISidebar builtObjectDetails={builtObjectDetails} />}
      <div className={articleContainer} data-test-id="PowerROIContent-articleContainer">
        <div className={closeContainer}>
          <IconButton
            className={closeButton}
            data-test-id="ROIModal-CloseBtn"
            onClick={handleClose}
          >
            <Icon name="close" variant="white" />
          </IconButton>
        </div>

        <div
          ref={scrollContainerRef}
          className={`${scrollContainer} ${OVERFLOW_Y}`}
          data-test-id="PowerROIContent-scrollContainer"
        >
          <article data-test-id="PowerROIContent-article">
            {/* eslint-disable-next-line no-nested-ternary */}
            {status === RoiStatus.Error ? (
              <div className="u-width--100" data-test-id="PowerROI-Error">
                <Typography variant="body1" paragraph>
                  {error?.message}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className="u-clickable"
                  data-test-id="ROI-Retry-Btn"
                  onClick={handleRetry}
                >
                  {t('roi:button:tryAgain')}
                </Button>
              </div>
            ) : status === RoiStatus.Completed && zeroMeanStddevMsg ? (
              <Typography variant="body1" paragraph>
                {zeroMeanStddevMsg}
              </Typography>
            ) : (
              <div className="u-width--100" data-test-id="PowerROIAssumptions-title">
                <Typography variant="h2" mb={4}>
                  <strong>{t('roi:analysisAssumptions')}</strong>
                </Typography>
                {assumptions ? (
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>{assumptions}</ReactMarkdown>
                ) : (
                  <TextSkeleton />
                )}
                <Typography variant="h2" mt={6} mb={4}>
                  <strong>{t('roi:analysisSummary')}</strong>
                </Typography>
                {options?.length > 0 ? (
                  <TableContainer data-test-id="ROIAnalysisSummaryTable">
                    <Table className={table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t('roi:options:description')}</TableCell>
                          <TableCell>{t('roi:options:initialInvestment')}</TableCell>
                          <TableCell>{t('roi:options:annualSavingsRange')}</TableCell>
                          <TableCell>{t('roi:options:rangeNPV')}</TableCell>
                          <TableCell>{t('roi:options:rangeIRR')}</TableCell>
                          <TableCell>{t('roi:options:rangeROI')}</TableCell>
                          <TableCell>{t('roi:options:carbonEmissionReduction')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {options?.map((option) => (
                          <TableRow key={option.id}>
                            <TableCell>{option.description}</TableCell>
                            <TableCell>{getLocalizedValue(option.initialInvestment)}</TableCell>
                            <TableCell>{getLocalizedRange(option.annualSavingsRange)}</TableCell>
                            <TableCell>{getLocalizedRange(option.rangeNPV)}</TableCell>
                            <TableCell>{option.rangeIRR}</TableCell>
                            <TableCell>{option.rangeROI}</TableCell>
                            <TableCell>
                              {getLocalizedValue(option.carbonEmissionReduction)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <TableSkeleton />
                )}
                <Typography variant="h2" mt={6} mb={4}>
                  <strong>{t('roi:mitigationSuggestions')}</strong>
                </Typography>
                {suggestions ? (
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>{suggestions}</ReactMarkdown>
                ) : (
                  <div className="u-width--100" data-test-id="PowerROISuggestions">
                    <div className="o-flex-container u-marginbottom--8">
                      <CircularProgress size={20} thickness={6} />
                      <Typography variant="body2" ml={2}>
                        {t('roi:waitForAIProcessing')}
                      </Typography>
                    </div>
                    <TextSkeleton />
                  </div>
                )}
              </div>
            )}
          </article>
        </div>
      </div>
    </CardContent>
  );
};

export default PowerROIContent;
