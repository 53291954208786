import * as React from 'react';
import { cloneDeep } from 'lodash';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import UnknownErrSnackbar, {
  unknownErrEnqueueOptions,
} from '../../../CommonComponents/Snackbar/UnknownErrSnackbar';
import {
  useGetBuiltObjectLifelineMarkersQuery,
  useGetLocationLifelineMarkersQuery,
  useGetSliceIdxQuery,
  useGetThresholdsQuery,
} from '../../../../__generated__/graphql';
import { App } from '../../../../PlanningApp/AppConfig';
import { Lifeline } from '../../../../util/productEnums';
import LifelineArcs from './LifelineArcs';
import LifelineMarkers from './LifelineMarkers';
import useLifelineEntitlements from '../../../../Hooks/useLifelineEntitlements';
import { VALID_LIFELINES } from '../../../../util/productGlobals';
import LifelineMarkersDefault from './LifelineMarkersDefault';
import LifelineArcsDefault from './LifelineArcsDefault';
import useHasFinanceEntitlement from '../../../../Hooks/useHasFinanceEntitlement';

type IndexableLifelineEntitlementsType = {
  [key: string]: boolean;
};

export const lifelineToLocFieldName = (llName: Lifeline): string => {
  switch (llName) {
    case Lifeline.STRUCTURE:
      return 'structural';
    case Lifeline.PORT:
    case Lifeline.AIRPORT:
    case Lifeline.HIGHWAY:
    case Lifeline.BRIDGE:
      return `${llName}s`;
    default:
      // people, power
      return llName as string;
  }
};

const LifelineMarkersContainer: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const entitlements = useLifelineEntitlements();
  const { id: selectedLocationId } = useParams<{ id: string }>();
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;

  const {
    data: {
      planView: { sliceIdx: activeSliceIdx },
    },
  } = useGetSliceIdxQuery();

  const { data: userData, error: thresholdsError } = useGetThresholdsQuery();
  const thresholds = userData?.user?.productSettings?.thresholds;

  const { data: locLifelineData, error: locLifelineError } = useGetLocationLifelineMarkersQuery({
    variables: { locationID: selectedLocationId, sliceIdx: activeSliceIdx },
    skip: !selectedLocationId,
  });
  const { data: bobjLifelineData, error: bobjLifelineError } =
    useGetBuiltObjectLifelineMarkersQuery({
      variables: { id: selectedLocationId, sliceIdx: activeSliceIdx },
      skip: !selectedLocationId || !locLifelineError,
    });

  React.useEffect(() => {
    if (thresholdsError || (locLifelineError && bobjLifelineError)) {
      const err = thresholdsError || bobjLifelineError;
      enqueueSnackbar(<UnknownErrSnackbar />, unknownErrEnqueueOptions);
      App.error([
        `[LifelineMarkersContainer] Error retrieving ${
          thresholdsError ? 'UserThresholds' : 'LifelineMarkers'
        }`,
        err.message,
      ]);
    }
  }, [enqueueSnackbar, thresholdsError, locLifelineError, bobjLifelineError]);

  const entitledLifelineData = React.useMemo(() => {
    const locOrBobj = locLifelineData?.location || bobjLifelineData?.builtObject;
    if (locOrBobj && entitlements) {
      const newlocOrBobj = cloneDeep(locOrBobj);
      VALID_LIFELINES.forEach((posLL) => {
        if (!(entitlements as IndexableLifelineEntitlementsType)?.[posLL as string]) {
          const fieldName = lifelineToLocFieldName(posLL);
          (newlocOrBobj as { [key: string]: any })[fieldName] = null;
        }
      });
      return newlocOrBobj;
    }
    return null;
  }, [entitlements, locLifelineData, bobjLifelineData]);

  if (isFinanceEntitled && entitledLifelineData) {
    return (
      <div data-test-id="LifelineMarkersContainer">
        <LifelineMarkersDefault locOrBobj={entitledLifelineData} />
        <LifelineArcsDefault builtObject={entitledLifelineData} />
      </div>
    );
  }

  if (thresholds && entitledLifelineData && !isFinanceEntitled) {
    return (
      <div data-test-id="LifelineMarkersContainer">
        <LifelineArcs builtObject={entitledLifelineData} thresholds={thresholds} />
        <LifelineMarkers locOrBobj={entitledLifelineData} thresholds={thresholds} />
      </div>
    );
  }

  // If queries are still loading or result in errors,  don't render any markers or arcs on the map
  return null;
};

LifelineMarkersContainer.displayName = 'LifelineMarkersContainer';
export default LifelineMarkersContainer;
