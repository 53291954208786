import * as React from 'react';
import { ApolloError } from '@apollo/client';

import {
  ResilienceStats,
  useGetBuiltObjectResilienceStatsQuery,
  useGetLocationResilienceStatsQuery,
} from '../__generated__/graphql';
import { App } from '../PlanningApp/AppConfig';
import useApiErrSnackbar from '../Components/CommonComponents/Snackbar/useApiErrSnackbar';

type UseGetResStatsReturnType = {
  resStats?: ResilienceStats[];
  loading: boolean;
  error?: ApolloError | Error;
};

function useGetResStats(
  id: string,
  slices: number[],
  showErrSnackbars?: boolean,
  loadDnaStats?: boolean,
): UseGetResStatsReturnType {
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();

  const {
    data: locData,
    loading: locLoading,
    error: locError,
  } = useGetLocationResilienceStatsQuery({
    skip: !id,
    variables: { id, slices, loadDnaStats },
  });

  // If the GetLocationResilienceStats query errors out, request server look for a BuiltObject
  // with the same ID.
  const {
    data: builtData,
    loading: builtLoading,
    error: builtError,
  } = useGetBuiltObjectResilienceStatsQuery({
    skip: !id || !locError,
    variables: { id, slices, loadDnaStats },
  });

  React.useEffect(() => {
    if ((builtError || !id) && (showErrSnackbars ?? true)) {
      enqueueApiErrSnackbar();
    }
  }, [id, builtError, showErrSnackbars, enqueueApiErrSnackbar]);

  if (!id) {
    return {
      resStats: null,
      loading: false,
      error: new Error('[useGetLocOrBobj] Error: id parameter to query was undefined or null'),
    };
  }

  if (locData || builtData) {
    return {
      resStats: locData?.location?.resilienceStats || builtData?.builtObject?.resilienceStats,
      loading: false,
    };
  }

  if (locLoading || builtLoading) {
    return {
      resStats: null,
      loading: true,
    };
  }

  if (builtError) {
    // assert that locError is also an error, because the GetBuiltObjectQuery that failed should
    // not have even run unless there was already a locError
    App.assert(!!locError, '[useGetLocOrBobj] - if builtError there should also be a locError.');
    return {
      resStats: null,
      loading: false,
      error: builtError,
    };
  }

  App.assert(
    !!locError && !builtError && !builtLoading && !builtData,
    '[useGetLocOrBobj] GetLocationQuery failed, but fallback to GetBuiltObjectQuery has not started',
  );
  return {
    resStats: null,
    loading: true,
  };
}

export default useGetResStats;
