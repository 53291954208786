import * as React from 'react';
import { LocationProps } from 'onec-types';

import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Chip from '../../../CommonComponents/Chip/Chip';
import { getDisplayableAddress } from '../../../../util/addresses/addressUtils';
import { GRAY_MUTED } from '../../../../util/productGlobals';

const useStyles = makeStyles((theme: Theme) => ({
  rankContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '0 0 auto',
    width: theme.spacing(5.5),
    height: theme.spacing(5.5),
    borderRadius: '50%',
    backgroundColor: GRAY_MUTED,
    lineHeight: 0,
  },
  dataContainer: {
    marginLeft: theme.spacing(4),
  },
  addressContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  typeChipIfGroup: {
    marginLeft: theme.spacing(2),
  },
}));

const VulnerableLocationFC: React.FC<Omit<LocationProps, 'coordinates'>> = ({
  id,
  rank,
  group,
  type,
  name,
  address,
}) => {
  const { rankContainer, dataContainer, addressContainer, typeChipIfGroup } = useStyles();

  const [aLine1, aLine2] = getDisplayableAddress(address);

  return (
    <>
      <div className={rankContainer} data-test-id={`VulnerableLocation:${id}-rank`}>
        <Typography variant="caption">{rank}</Typography>
      </div>
      <div className={dataContainer}>
        <Typography variant="body1" data-test-id={`VulnerableLocation:${id}-name`}>
          {name}
        </Typography>
        <div className={addressContainer} data-test-id={`VulnerableLocation:${id}-address`}>
          {aLine1 && (
            <Typography variant="body2" data-test-id="VLoc-address-line1">
              {aLine1}
            </Typography>
          )}
          {aLine2 && (
            <Typography variant="body2" data-test-id="VLoc-address-line2">
              {aLine2}
            </Typography>
          )}
        </div>
        {(group || type) && (
          <div data-test-id={`VulnerableLocation:${id}-categories`}>
            {group && <Chip label={group} data-test-id={`VulnerableLocation:${id}-group`} />}
            {type && (
              <Chip
                label={type}
                additionalClasses={group ? typeChipIfGroup : null}
                data-test-id={`VulnerableLocation:${id}-type`}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

const VulnerableLocation = React.memo(VulnerableLocationFC);
VulnerableLocationFC.displayName = 'VulnerableLocationFC';
VulnerableLocation.displayName = 'VulnerableLocation';
export default VulnerableLocation;
