import * as React from 'react';
import { Button, Divider, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  AMP_EVENT_DELETE_LOCATIONS,
  AMP_EVENT_VIEW_DETAILS,
} from '../../../../../plugins/amplitudeevents';
import {
  GetLocationsFileDetailsDocument,
  GetLocationsWithFilterDocument,
  GetOriginalLocationsFileDocument,
  LocationFile,
  useDeleteLocationsFileMutation,
  useGetSliceIdxQuery,
  useUpdateLocationsFilterMutation,
} from '../../../../../__generated__/graphql';
import { QUIET_BG, WHITE } from '../../../../../util/productGlobals';
import { allLocFilterChoicesVar } from '../../../../../PlanningApp/client/cache';
import { App } from '../../../../../PlanningApp/AppConfig';
import Callout from '../../../../CommonComponents/Callout/Callout';
import DeleteCsvModal from './DeleteCsvModal';
import { formatDateString } from '../../../../../util/time';
import Icon from '../../../../CommonComponents/Icon/Icon';
import LocationsCsvStats from './LocationsCsvStats';
import { sendAmplitudeData } from '../../../../../plugins/amplitude';
import Spinner from '../../../../CommonComponents/Spinner/Spinner';
import useApiErrSnackbar from '../../../../CommonComponents/Snackbar/useApiErrSnackbar';

const SECTION_KEY = 'settings:sections:locations:details:';

type LocationsCsvDetailsProps = {
  file: Omit<LocationFile, 'warning' | 'notices' | 'isDefault'>;
  userId: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  titleText: {
    marginTop: theme.spacing(8),
    overflowWrap: 'break-word',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  nameContainer: {
    lineBreak: 'anywhere',
    flex: 1,
    minWidth: 180,
    maxWidth: 360,
  },
  warningContainer: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  calloutText: {
    color: WHITE,
    '&:nth-child(2)': {
      marginTop: theme.spacing(2),
    },
  },
  outerButtonsContainer: {
    height: 72,
    maxHeight: 72,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingTop: 24,
  },
  divider: {
    margin: theme.spacing(8, 0),
    backgroundColor: QUIET_BG,
  },
}));

const LocationsCsvDetails: React.FC<LocationsCsvDetailsProps> = ({ file, userId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    titleText,
    detailsContainer,
    nameContainer,
    warningContainer,
    calloutText,
    outerButtonsContainer,
    divider,
  } = useStyles();
  const {
    lastUpdatedAt,
    name,
    totalAccepted,
    totalRejected,
    totalPending,
    totalWarnings,
    totalRows,
    uploadedAt,
  } = file;
  const showWarning = totalRejected > 0;

  const [showModal, setShowModal] = React.useState(false);
  const toggleModal = React.useCallback(() => setShowModal((show) => !show), [setShowModal]);

  const {
    data: { planView },
  } = useGetSliceIdxQuery();

  const [resetLocationsFilter] = useUpdateLocationsFilterMutation({
    variables: { locFilter: { locGroups: [], locTypes: [] } },
  });

  const [deleteLocationsFile, { error: deleteError, loading: deleteInProgress }] =
    useDeleteLocationsFileMutation({
      refetchQueries: [
        {
          query: GetLocationsWithFilterDocument,
          variables: {
            sliceIdx: planView?.sliceIdx,
            input: { matchCriteria: { isPendingMIB: false } },
          },
        },
        { query: GetLocationsFileDetailsDocument },
        { query: GetOriginalLocationsFileDocument },
      ],
      onCompleted: () => {
        allLocFilterChoicesVar({ locTypes: [], locGroups: [] });
        resetLocationsFilter();

        // Send delete locations event data to amplitude
        sendAmplitudeData(AMP_EVENT_DELETE_LOCATIONS, {
          userId,
          fileId: file.id,
        });
      },
    });

  const toggleConfirmation = React.useCallback(() => setShowModal((show) => !show), [setShowModal]);
  const handleClickViewDetails = React.useCallback(() => {
    navigate('/locations/list');
    sendAmplitudeData(AMP_EVENT_VIEW_DETAILS, {
      userId,
      fileId: file.id,
    });
  }, [file.id, navigate, userId]);

  const { enqueueApiErrSnackbar } = useApiErrSnackbar();
  React.useEffect(() => {
    if (deleteError) {
      App.error('[LocationsCsvDetails] - useDeleteLocationsFileMutation error: ', deleteError);
      enqueueApiErrSnackbar();
    }
  }, [deleteError, enqueueApiErrSnackbar]);

  if (deleteInProgress || deleteError) {
    return <Spinner />;
  }

  return (
    <div data-test-id="LocationsCsvDetails">
      <Typography className={titleText} variant="body1">
        {t(`${SECTION_KEY}title`)}
      </Typography>

      <div className={detailsContainer}>
        <Grid container spacing={4} wrap="wrap" justifyContent="space-between" alignItems="center">
          <Grid item xs="auto" className={nameContainer}>
            <Typography variant="h3">{name}</Typography>
          </Grid>

          <Grid item className={warningContainer} xs="auto">
            {showWarning && (
              <Callout
                className={calloutText}
                variant="inline"
                iconOverride={<Icon name="info2" variant="white" />}
                text={t(`${SECTION_KEY}callout`)}
              />
            )}
            {lastUpdatedAt && (
              <Callout
                className={calloutText}
                variant="inline"
                iconOverride={<Icon name="info2" variant="white" />}
                text={t(`${SECTION_KEY}locationsModified`)}
              />
            )}
          </Grid>

          <Grid item className={outerButtonsContainer} xs="auto">
            <Button
              variant="outlined"
              color="primary"
              onClick={toggleConfirmation}
              key="deleteButton"
              data-test-id="deleteButton"
            >
              {t(`${SECTION_KEY}buttonDelete`)}
            </Button>
          </Grid>
        </Grid>
      </div>

      <Typography variant="caption" data-test-id="lastUploadedTimeDate">
        {t(`${SECTION_KEY}lastUpdated`, {
          date: formatDateString(uploadedAt, null, 'dateStringWithHourMin'),
        })}
      </Typography>

      <Divider className={divider} />

      <div className="o-flexcol-container">
        <LocationsCsvStats
          totalProcessed={totalRows}
          totalAccepted={totalAccepted}
          totalWarnings={totalWarnings}
          totalRejected={totalRejected}
          totalPending={totalPending}
        />
        <Divider className={divider} />
        <div className="o-flex-hcenter-container">
          <Button
            variant="outlined"
            color="primary"
            key="viewDetailsButton"
            data-test-id="viewDetailsButton"
            onClick={handleClickViewDetails}
          >
            {t(`${SECTION_KEY}viewDetails`)}
          </Button>
        </div>
      </div>
      <DeleteCsvModal
        isOpen={showModal}
        toggleModal={toggleModal}
        deleteLocationsFile={deleteLocationsFile}
      />
    </div>
  );
};

LocationsCsvDetails.displayName = 'LocationsCsvDetails';
export default LocationsCsvDetails;
