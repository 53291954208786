import * as React from 'react';
import { ListItem, ListItemText, Theme } from '@mui/material';
import { ListItemTextProps } from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';

import { BLACK } from '../../../util/productGlobals';

const useStyles = makeStyles((theme: Theme) => ({
  dividerLineBox: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    width: `calc(100% - ${theme.spacing(4)})`,
  },
  divider: {
    borderBottom: `1px solid ${BLACK}`,
  },
  hoverHighlightedBox: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  listItemTextRoot: {
    marginTop: 0,
    marginBottom: 0,
    '&.MuiListItemText-multiline': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
}));

const ListItemWithHighlight: React.FC<ListItemTextProps & { name: string; divider?: boolean }> = ({
  name,
  primary: primaryText,
  secondary: secondaryText,
  divider = true,
  ...restProps
}) => {
  const classes = useStyles();

  const dataTestId = name;

  return (
    <div
      className={`${classes.dividerLineBox} ${divider ? classes.divider : ''}`}
      data-test-id={`${dataTestId}-hoverhighlight`}
    >
      <ListItem
        button
        data-test-id={dataTestId}
        classes={{ root: classes.hoverHighlightedBox }}
        disableGutters
      >
        <ListItemText
          primary={primaryText}
          secondary={secondaryText}
          {...restProps}
          data-test-id={`${dataTestId}-text`}
          classes={{ root: classes.listItemTextRoot }}
        />
      </ListItem>
    </div>
  );
};
ListItemWithHighlight.displayName = 'ListItemWithHighlight';
export default ListItemWithHighlight;
