import * as React from 'react';

import Card from '@mui/material/Card';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { BLURRY, GLASS_BG } from '../../../../util/productGlobals';
import {
  NavigatorViewType,
  useGetCurrentUserQuery,
  useGetNotFoundLocationQuery,
} from '../../../../__generated__/graphql';

import { App } from '../../../../PlanningApp/AppConfig';
import { Lifeline } from '../../../../util/productEnums';
import LocationDetailCardContainer from './LocationDetailCardContainer';
import ShareLocation from './ShareLocation';
import useApiErrSnackbar from '../../../CommonComponents/Snackbar/useApiErrSnackbar';
import useConsultantOrganization from '../../../../Hooks/useConsultantOrganization';
import useShareableURL from '../../../../Hooks/useShareableURL';
import useHasFinanceEntitlement from '../../../../Hooks/useHasFinanceEntitlement';
import LearnMore from './LearnMore';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    '& > *': {
      pointerEvents: 'auto',
    },
  },
  btnContainer: {
    display: 'flex',
    columnGap: '8px',
  },
  card: {
    backgroundColor: GLASS_BG,
    borderRadius: '6px',
    backdropFilter: BLURRY,
    padding: '8px 8px 16px 8px',
  },
}));

interface LocationDetailViewProps {
  currentView: NavigatorViewType;
  currentLifeline: string;
}

const LocationDetailView: React.FC<LocationDetailViewProps> = ({
  currentView,
  currentLifeline,
}) => {
  const { card, btnContainer, container } = useStyles();
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();
  const { isConsultantOrg } = useConsultantOrganization();
  const { id } = useParams();
  const { url, error: urlErr } = useShareableURL(id);

  const {
    data: {
      notFoundLocation: { address },
    },
  } = useGetNotFoundLocationQuery();
  const { data, loading, error: userErr } = useGetCurrentUserQuery();
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;
  const isFound = !address;
  const allowShare = isConsultantOrg && !loading && isFound && !!url;

  React.useEffect(() => {
    if (userErr || urlErr) {
      if (userErr) App.error('[LocationDetailView] - GetCurrentUser failed: ', userErr.message);
      enqueueApiErrSnackbar();
    }
  }, [userErr, urlErr, enqueueApiErrSnackbar]);

  if (currentView !== NavigatorViewType.MapView && currentLifeline === Lifeline.EMPTY) return null;

  return (
    <div className={`o-flexcol-container ${container}`} data-test-id="LocationDetailView">
      <Card
        id="PowerDowntime-Card"
        className={`o-flexcol-container u-overflowy-auto u-marginbottom--8 ${card}`}
        elevation={0}
      >
        <LocationDetailCardContainer
          currentView={currentView}
          currentLifeline={currentLifeline}
          currentUserEmail={data?.user?.personalInfo?.contact?.email}
        />
      </Card>
      <div className={btnContainer}>
        {/* TODO - Disable the LearnMore button if there are no ResStats */}
        {isFound && <LearnMore />}
        {allowShare && !isFinanceEntitled && <ShareLocation />}
      </div>
    </div>
  );
};

LocationDetailView.displayName = 'LocationDetailView';
export default LocationDetailView;
