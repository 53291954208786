import * as React from 'react';

import { CONTINENTAL_USA_MAX_EXTENTS, JAPAN_MAX_EXTENTS } from '../util/productGlobals';
import { App } from '../PlanningApp/AppConfig';

import useApiErrSnackbar from '../Components/CommonComponents/Snackbar/useApiErrSnackbar';
import useEntitledModules from './useEntitledModules';

type MaxExtentsType = [number, number, number, number];

export const JAPAN_PLUS_USA_MAX_EXTENTS: MaxExtentsType = [-56.0, 50.0, -240.46, 23.0];

function useGetEntitledMapExtents(): MaxExtentsType {
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();
  const { data, error } = useEntitledModules();

  React.useEffect(() => {
    if (error) {
      App.error('[useEntitledMapBounds] error: ', error);
      enqueueApiErrSnackbar();
    }
  }, [error, enqueueApiErrSnackbar]);

  const { hasJPModule, hasUSModule } = data ?? {};

  const mapExtents = React.useMemo(() => {
    let extents: [number, number, number, number];

    if (hasJPModule && hasUSModule) {
      // todo - make this more flexible and able to fixure out the bounds as each user can
      extents = JAPAN_PLUS_USA_MAX_EXTENTS;
    } else if (hasJPModule) {
      extents = JAPAN_MAX_EXTENTS as MaxExtentsType;
    } else if (hasUSModule) {
      extents = CONTINENTAL_USA_MAX_EXTENTS as MaxExtentsType;
    } else {
      extents = CONTINENTAL_USA_MAX_EXTENTS as MaxExtentsType;
    }
    return extents;
  }, [hasJPModule, hasUSModule]);

  return mapExtents;
}

export default useGetEntitledMapExtents;
