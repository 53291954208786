import { ForecastPeriodType, PlanFilters, Slice, SliceHazardType } from '../__generated__/graphql';
import { VALID_RETURN_PERIODS } from './productGlobals';

/**
 *
 * @param slice `Slice`: `climate`, `returnPeriod`
 * @returns `false` for slice definition that is legal for the backend but the frontend is not
 * allowed to ever request
 */
export function isLegalSlice({ climate, returnPeriod }: Slice): boolean {
  return climate !== 2 && (returnPeriod === null || VALID_RETURN_PERIODS.includes(returnPeriod));
}

/**
 *
 * @param slice `Slice`: `historicalEventID`, `severityIndex`
 * @param filters `PlanFilters`: `historicalId`, `isHistoricalSeverityWorse`
 * @returns `true` for slice definition that matches the historical event ID and severity selected
 * in the plan filters
 */
export function isHistoricalSliceMatch(
  { historicalEventID, severityIndex }: Slice,
  { historicalId, isHistoricalSeverityWorse }: PlanFilters,
): boolean {
  return (
    historicalEventID === historicalId &&
    // severity = 1 for "normal", 2 for "worse"
    severityIndex > 1 === isHistoricalSeverityWorse
  );
}

/**
 *
 * @param slice `Slice`: `hazardType`, `climate`
 * @param filters `PlanFilters`: `hazardType`, `isClimateChangeEnabled`
 * @returns `true` for slice definition that matches the hazard type, and the climate setting as
 * well if the selected hazard filter is not `Earthquake`
 */
export function isSimulatedSliceMatch(
  { hazardType, climate }: Slice,
  { hazardType: hazardFilter, isClimateChangeEnabled }: PlanFilters,
): boolean {
  return (
    hazardType === hazardFilter &&
    (hazardFilter === SliceHazardType.Earthquake || climate > 1 === isClimateChangeEnabled)
  );
}

/**
 *
 * @param slice `Slice`: `returnPeriod`, `planningHorizon`
 * @param filters `PlanFilters`: `forecastPeriod`: `forecastPeriodType`, `duration`
 * @returns `true` for slice definition that matches the forecast period type selected in the plan
 * filters
 */
export function isForecastPeriodMatch(
  slice: Slice,
  { forecastPeriod: { forecastPeriodType, duration } }: PlanFilters,
): boolean {
  const forecastFilter =
    forecastPeriodType === ForecastPeriodType.ReturnPeriod ? 'returnPeriod' : 'planningHorizon';
  return slice[forecastFilter] === duration;
}

export function findSlice(slices: Slice[], findSlice: Omit<Slice, 'id' | 'slice'>): Slice {
  return slices.find(
    (slice) =>
      findSlice.climate === slice.climate &&
      findSlice.hazardType === slice.hazardType &&
      findSlice.historicalEventID === slice.historicalEventID &&
      findSlice.planningHorizon === slice.planningHorizon &&
      findSlice.returnPeriod === slice.returnPeriod &&
      findSlice.severityIndex === slice.severityIndex,
  );
}
