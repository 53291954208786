import { RecentView } from '../../../__generated__/graphql';
import { recentViewMutations } from '../resolvers/RecentViewResolver';

export const STORE_DEFAULTS = {
  recentViews: [] as RecentView[],
};

export const recentViewStore = {
  defaults: STORE_DEFAULTS,
  mutations: recentViewMutations,
};
