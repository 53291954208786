import * as React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Card,
  CardContent,
  Grid,
  Grow,
  InputAdornment,
  Link,
  Modal,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DARKBLUE, GLASS_BG } from '../../../util/productGlobals';
import { Address, EditAddressInput } from '../../../__generated__/graphql';
import { LocInfo } from './locationMatchHelpers';
import { POSTAL_MARK } from '../../../util/addresses/addressUtilsJP';

const useStyles = makeStyles((theme: Theme) => {
  return {
    inputFieldStyle: {
      '&:-webkit-autofill': {
        '-webkit-box-shadow': `0 0 0 100px ${DARKBLUE} inset`,
      },
    },
    textFieldStyle: {
      '& > label': {
        opacity: 0.5,
      },
    },
    modalStyle: {
      width: '60vw',
      maxWidth: '70vw',
      maxHeight: '80vh',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%) !important',
      outline: 0,
      backgroundColor: GLASS_BG,
      padding: theme.spacing(6),
      overflowY: 'scroll',
    },
  };
});

type FormItemConfig = {
  id: string;
  labelTransKey: string;
  fullWidth: boolean;
  value: string;
  startAdornment?: React.ReactNode;
  notRequired?: boolean;
};

function getAddressConfig(address: Address | EditAddressInput): FormItemConfig[] {
  if (address.countryCode === 'JP' || address.country === 'Japan') {
    return [
      {
        id: 'postCode',
        labelTransKey: 'locationMatch:editModal:postCode',
        fullWidth: true,
        value: address?.postCode ?? '',
        startAdornment: <InputAdornment position="start">{POSTAL_MARK}</InputAdornment>,
      },
      {
        id: 'stateCode',
        labelTransKey: 'locationMatch:editModal:prefecture',
        fullWidth: true,
        value: address?.stateCode ?? '',
      },
      {
        id: 'city',
        labelTransKey: 'locationMatch:editModal:city',
        fullWidth: true,
        value: address?.city ?? '',
      },
      {
        id: 'street',
        labelTransKey: 'locationMatch:editModal:street',
        fullWidth: true,
        value: address?.street ?? '',
      },
    ];
  }

  return [
    // US Addresses
    {
      id: 'street',
      labelTransKey: 'locationMatch:editModal:street',
      fullWidth: true,
      value: `${address?.houseNumber ?? ''}${address?.houseNumber ? ' ' : ''}${
        address?.street ?? ''
      }`,
    },
    {
      id: 'city',
      labelTransKey: 'locationMatch:editModal:city',
      fullWidth: false,
      value: address?.city ?? '',
    },
    {
      id: 'stateCode',
      labelTransKey: 'locationMatch:editModal:stateCode',
      fullWidth: false,
      value: address?.stateCode ?? '',
    },
    {
      id: 'postCode',
      labelTransKey: 'locationMatch:editModal:postCode',
      fullWidth: true,
      value: address?.postCode ?? '',
    },
  ];
}

function getFormConfig(unsubmittedLocInfo: LocInfo): FormItemConfig[] {
  const addressFields = getAddressConfig(unsubmittedLocInfo.addressInput);
  return [
    {
      id: 'name',
      labelTransKey: 'locationMatch:editModal:locationName',
      fullWidth: true,
      value: unsubmittedLocInfo?.name ?? '',
    },
    ...addressFields,
    {
      id: 'type',
      labelTransKey: 'locationMatch:editModal:locationType',
      fullWidth: false,
      value: unsubmittedLocInfo?.type ?? '',
      notRequired: true,
    },
    {
      id: 'group',
      labelTransKey: 'locationMatch:editModal:locationGroup',
      fullWidth: false,
      value: unsubmittedLocInfo?.group ?? '',
      notRequired: true,
    },
  ];
}

const EditLocationModalContent: React.FC<{
  locInfo: LocInfo;
  onClickSubmit: (submittedLocInfo: LocInfo) => void;
  submitting?: boolean;
  // hasJPModule?: boolean;
}> = ({
  locInfo,
  onClickSubmit,
  submitting,
  //  hasJPModule
}) => {
  const { modalStyle, inputFieldStyle, textFieldStyle } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id } = locInfo;
  const [unsubmittedLocInfo, setUnsubmittedLocInfo] = React.useState<LocInfo>(locInfo);

  const [isOpen, setIsOpen] = React.useState(true);

  const formConfig = React.useMemo(() => {
    return getFormConfig(unsubmittedLocInfo);
  }, [unsubmittedLocInfo]);
  //   return getFormConfig(unsubmittedLocInfo, hasJPModule);
  // }, [unsubmittedLocInfo, hasJPModule]);

  const isReadyToSubmit = React.useMemo(() => {
    const notReady = formConfig.some((fieldConfig) => {
      const required = !fieldConfig.notRequired;
      let value: string;
      if (['name', 'type', 'group'].includes(fieldConfig.id)) {
        value = unsubmittedLocInfo?.[fieldConfig.id];
      } else {
        value = unsubmittedLocInfo?.addressInput?.[fieldConfig.id];
      }
      // user didn't provide a value for a required field
      return required && (value?.length ?? 0) === 0;
    });
    return !notReady;
  }, [formConfig, unsubmittedLocInfo]);

  const handleModalClose = React.useCallback(() => {
    if (isOpen) {
      setIsOpen(false);
      // need to wait for the modal close animation to finish before navigating to previous page
      setTimeout(() => navigate(-1), 500);
      document.title = t('windowTitle');
    }
  }, [isOpen, navigate, t]);

  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newUnsubmitted = { ...unsubmittedLocInfo };
      const elId = e.currentTarget.id;
      const val = e.currentTarget.value;
      if (['name', 'type', 'group'].includes(elId)) {
        newUnsubmitted[elId] = val;
      } else {
        newUnsubmitted.addressInput[elId] = val;

        // These are hacks to get around inconsistencies in the backend's handling of prefecture
        // in some queries/mutations, prefecture uses the 'stateCode' field and for others
        // it uses the 'state' field.
        if (elId === 'stateCode' && !newUnsubmitted.state) {
          newUnsubmitted.addressInput.state = val;
        } else if (elId === 'state' && !newUnsubmitted.stateCode) {
          newUnsubmitted.addressInput.stateCode = val;
        }
      }
      setUnsubmittedLocInfo(newUnsubmitted);
    },
    [unsubmittedLocInfo],
  );

  const onClick = React.useCallback(() => {
    // Check if any required fields are blank.
    if (isReadyToSubmit) {
      onClickSubmit(unsubmittedLocInfo);
    }
  }, [isReadyToSubmit, unsubmittedLocInfo, onClickSubmit]);

  return (
    <Modal
      className="o-flex-center-container"
      open
      onClose={handleModalClose}
      aria-labelledby="edit-location-modal-title"
      aria-describedby="edit-location-modal-description"
      closeAfterTransition
      data-test-id="EditLocationModal"
    >
      <Grow in={isOpen} timeout={1000}>
        <Card className={modalStyle} data-test-id="EditLocationModal-card">
          <CardContent>
            <Grid container spacing={2} sx={{ maxWidth: '700px' }}>
              <Grid item xs={12}>
                <Typography id="edit-location-modal-title" variant="h2" component="h2">
                  {t('locationMatch:editModal:modalTitle')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography data-test-id="edit-location-modal-instructions1" sx={{ mb: 2 }}>
                  {t('locationMatch:editModal:instructions1')}
                </Typography>
                <Typography data-test-id="edit-location-modal-instructions2" sx={{ mb: 2 }}>
                  {t('locationMatch:editModal:instructions2')}
                </Typography>
                <Typography>
                  <Link
                    component={RouterLink}
                    to={`/locations/${id}/match`}
                    id="edit-location-modal-linkToMatch"
                    data-test-id="edit-location-modal-linkToMatch"
                  >
                    {t('locationMatch:editModal:bldgMatchLinkText')}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12} lg={9}>
                {locInfo && (
                  <Grid container rowSpacing={6} columnSpacing={2} sx={{ m: 2 }}>
                    {formConfig.map((fieldConfig2) => {
                      const {
                        id: fieldId,
                        fullWidth,
                        notRequired,
                        value,
                        labelTransKey,
                        startAdornment,
                      } = fieldConfig2;
                      const dataTestId = `${fieldId}-edit`;
                      const error = !notRequired && value.length === 0;
                      const validationText = error
                        ? t('locationMatch:editModal:mustProvideValue', {
                            fieldName: t(labelTransKey).toLocaleLowerCase(),
                          })
                        : null;
                      return (
                        <TextField
                          error={!notRequired && value.length === 0}
                          helperText={validationText}
                          fullWidth={fullWidth}
                          required={!notRequired}
                          id={fieldId}
                          label={t(labelTransKey)}
                          value={value}
                          variant="outlined"
                          size="small"
                          className={textFieldStyle}
                          inputProps={{
                            autoComplete: 'off',
                            className: inputFieldStyle,
                            'data-test-id': dataTestId,
                          }}
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          InputProps={{ startAdornment }}
                          onChange={onChange}
                          key={fieldId}
                          sx={{ m: 2 }}
                        />
                      );
                    })}

                    <Grid container item spacing={2} xs={12}>
                      <Grid item>
                        <Button
                          type="reset"
                          name="cancel"
                          fullWidth
                          color="primary"
                          variant="outlined"
                          disabled={submitting}
                          onClick={handleModalClose}
                        >
                          {t('buttons:cancel')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          type="submit"
                          name="apply"
                          fullWidth
                          color="primary"
                          disabled={submitting || !isReadyToSubmit}
                          variant="contained"
                          onClick={onClick}
                        >
                          {t('buttons:apply')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grow>
    </Modal>
  );
};
EditLocationModalContent.displayName = 'EditLocationModalContent';
export default EditLocationModalContent;
