import * as React from 'react';

import { App } from '../PlanningApp/AppConfig';
import { useGetCurrentUserQuery } from '../__generated__/graphql';
import useGetLocOrBobj from './useGetLocOrBobj';

function useShareableURL(locationID: string) {
  const {
    locOrBobj,
    error: locOrBobjErr,
    loading: locOrBobjLoading,
  } = useGetLocOrBobj(locationID, false);

  const { data: userData, loading, error } = useGetCurrentUserQuery();

  React.useEffect(() => {
    if (error || locOrBobjErr) {
      App.error('[useShareableURL] error: ', error || locOrBobjErr);
    }
  }, [error, locOrBobjErr]);

  let matchingBuiltObjectID = null;
  let locationType: string;
  if (locOrBobj?.__typename === 'LocationObject') {
    matchingBuiltObjectID = locOrBobj.builtObjectId;
    locationType = locOrBobj.type;
  } else if (locOrBobj?.__typename === 'BuiltObject') {
    matchingBuiltObjectID = locationID;
  }

  const userID = userData?.user?.id;
  let url: string = null;
  if (matchingBuiltObjectID && userID) {
    url = `analysis/detail/${matchingBuiltObjectID}?uid=${userID}`;
  }

  return {
    url,
    builtObjectId: matchingBuiltObjectID,
    loading: locOrBobjLoading || loading,
    error: locOrBobjErr || error,
    locationType,
  };
}

export default useShareableURL;
