import * as React from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Popper,
  Select,
  SelectChangeEvent,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
} from '@mui/material';

import { autocompleteClasses } from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ShapeLineIcon from '@mui/icons-material/ShapeLine';
import { cloneDeep } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import {
  ALL_SIMULATION_TYPES,
  GLASS_BG,
  REGION_COLOR_SPECTRUM,
  selectMenuStyle,
  SIMULATION_AVAILABLE_LIFELINES,
} from '../../../util/productGlobals';
import {
  GetNavigatorInfoDocument,
  GetSimulationsDocument,
  NavigatorViewType,
  SimulationFormRegion,
  SimulationInput,
  SimulationRegionInput,
  useCreateSimulationMutation,
  useGetNavigatorInfoQuery,
  useGetSimulationFormQuery,
  useUpdateLifelineDowntimesMutation,
  useUpdateNavigatorInfoMutation,
  useUpdateRegionOccupancyTypesMutation,
  useUpdateSimulationInfoMutation,
} from '../../../__generated__/graphql';
import { Lifeline, SimulationType } from '../../../util/productEnums';
import DaysHoursInput from '../../CommonComponents/InputControls/DaysHoursInput/DaysHoursInput';
import useApiErrSnackbar from '../../CommonComponents/Snackbar/useApiErrSnackbar';
import { App } from '../../../PlanningApp/AppConfig';
import ALL_OCCUPANCY_TYPES from '../../../util/occTypes';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'absolute',
    top: 72,
    right: 10,
    width: 'calc(100% - 18px)',
    height: 'calc(100% - 144px)',
  },
  card: {
    overflowY: 'auto',
    maxHeight: '100%',
    height: '100%',
    backgroundColor: GLASS_BG,
    borderRadius: theme.spacing(1.5),
    backdropFilter: 'blur(16px)',
    pointerEvents: 'auto',
  },
  step: {
    height: '100%',
  },
  hideContainer: {
    display: 'none',
  },
  parent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  inputRoot: {
    width: 76,
    height: 36,
    margin: theme.spacing(0, 2),
  },
  inputInput: {
    fontSize: '14px',
    padding: theme.spacing(2, 0, 2, 3),
  },
  inputAdornedEnd: {
    fontSize: '14px',
  },
  cell: {
    padding: '1px',
  },
}));

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox} .MuiAutocomplete-option[aria-selected="true"].Mui-focused`]: {
    backgroundColor: 'rgba(248, 247, 242, 0.3)',
  },
  [`& .${autocompleteClasses.listbox} .MuiAutocomplete-option[aria-selected="true"]`]: {
    backgroundColor: 'transparent',
  },
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SimulationsFormContainer = () => {
  const { card, container, hideContainer, inputAdornedEnd, inputInput, inputRoot, parent, cell } =
    useStyles();
  const steps = ['Simulation Details', 'Draw Regions', 'Create!'];
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const {
    data: {
      navigatorInfo: { currentView: currentNavigatorView },
    },
    error,
    loading,
  } = useGetNavigatorInfoQuery();
  const [updateNavigatorInfoMutation] = useUpdateNavigatorInfoMutation({
    refetchQueries: [GetNavigatorInfoDocument],
  });
  const { data: simulationData } = useGetSimulationFormQuery();
  const [updateLifelineDowntimesMutation] = useUpdateLifelineDowntimesMutation();
  const [updateRegionOccupancyTypesMutation] = useUpdateRegionOccupancyTypesMutation();
  const [updateSimulationInfoMutation] = useUpdateSimulationInfoMutation();
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();
  const [createSimulationMutation, { data: mutationData, error: mutationError }] =
    useCreateSimulationMutation({
      refetchQueries: [{ query: GetSimulationsDocument }],
      awaitRefetchQueries: true,
      onError: (err: ApolloError) => {
        App.error('[Simulations Form] - Create Simulation ', err.message);
        enqueueApiErrSnackbar();
      },
    });
  const [nameFieldError, setNameFieldError] = React.useState(false);
  const [typeFieldError, setTypeFieldError] = React.useState(false);

  const isStepOptional = (step: number) => {
    return step === -1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep === 0 && simulationData?.simulationForm?.name.trim().length === 0) {
      setNameFieldError(true);
      return;
    }

    if (activeStep === 0 && simulationData?.simulationForm?.type.trim().length === 0) {
      setTypeFieldError(true);
      return;
    }

    if (
      activeStep === 0 &&
      (simulationData?.simulationForm?.type === SimulationType.HURRICANEIDA ||
        simulationData?.simulationForm?.type === SimulationType.HURRICANEIAN)
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 2);
      setSkipped(newSkipped);
      return;
    }
    if (activeStep === steps.length - 1) {
      const regions =
        simulationData?.simulationForm?.type !== SimulationType.HURRICANEIAN &&
        simulationData?.simulationForm?.type !== SimulationType.HURRICANEIDA
          ? simulationData?.simulationForm?.simulationFormRegions.map(
              (region) =>
                ({
                  polygon: region.region,
                  lifeline_downtime_hrs: {
                    port: region.lifelineDowntimes.port,
                    airport: region.lifelineDowntimes.airport,
                    bridge: region.lifelineDowntimes.bridge,
                    repairTime: region.lifelineDowntimes.repairTime,
                    building: region.lifelineDowntimes.building,
                    highway: region.lifelineDowntimes.highway,
                    power: region.lifelineDowntimes.power,
                  },
                  occupancyTypes: region.occupancyTypes,
                } as SimulationRegionInput),
            )
          : [
              {
                polygon: 'test',
                lifeline_downtime_hrs: {
                  port: 0,
                  airport: 0,
                  bridge: 0,
                  repairTime: 0,
                  building: 0,
                  highway: 0,
                  power: 0,
                },
              } as SimulationRegionInput,
            ];
      await createSimulationMutation({
        variables: {
          simulation: {
            name: simulationData?.simulationForm?.name,
            simulation_region: regions,
          } as SimulationInput,
        },
      });
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    if (
      activeStep === 2 &&
      (simulationData?.simulationForm?.type === SimulationType.HURRICANEIDA ||
        simulationData?.simulationForm?.type === SimulationType.HURRICANEIAN)
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep - 2);
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleNameChange = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setNameFieldError(false);
      updateSimulationInfoMutation({ variables: { name: e.target.value } });
    },
    [updateSimulationInfoMutation],
  );

  const handleChangeSimulationType = React.useCallback(
    (e: SelectChangeEvent<string>) => {
      setTypeFieldError(false);
      updateSimulationInfoMutation({ variables: { type: e.target.value } });
    },
    [updateSimulationInfoMutation],
  );

  const onChangeHours = React.useCallback(
    ({ id, value: hours }: { id: string; value: number }) => {
      const regionID = id.split('-')[1];
      const lifeline: string = id.split('-')[0];
      const regionDowntimes: SimulationFormRegion['lifelineDowntimes'] = cloneDeep(
        simulationData?.simulationForm?.simulationFormRegions?.find(
          (region) => region.id === regionID,
        )?.lifelineDowntimes,
      );
      switch (lifeline) {
        case 'power':
          regionDowntimes.power = hours;
          break;
        case 'airport':
          regionDowntimes.airport = hours;
          break;
        case 'bridge':
          regionDowntimes.bridge = hours;
          break;
        case 'port':
          regionDowntimes.port = hours;
          break;
        case 'repairTime':
          regionDowntimes.repairTime = hours;
          break;
        case 'highway':
          regionDowntimes.highway = hours;
          break;
        case 'building':
          regionDowntimes.building = hours;
          break;
        default:
          break;
      }
      updateLifelineDowntimesMutation({
        variables: { regionid: regionID, downtimes: regionDowntimes },
      });
    },
    [simulationData?.simulationForm?.simulationFormRegions, updateLifelineDowntimesMutation],
  );

  const onChangeOccupancyTypes = React.useCallback(
    ({ id, value }: { id: string; value: string[] }) => {
      updateRegionOccupancyTypesMutation({
        variables: { regionid: id, occupancyTypes: value },
      });
    },
    [updateRegionOccupancyTypesMutation],
  );

  const simulationType = simulationData?.simulationForm?.type as SimulationType;
  if (!error && !loading && currentNavigatorView) {
    const hasRegions = simulationData?.simulationForm?.simulationFormRegions.length > 0;
    const regionNumber = simulationData?.simulationForm?.simulationFormRegions.length;
    return (
      <div
        className={`u-zindex--5 ${
          currentNavigatorView === NavigatorViewType.SimulationFormDraw ? hideContainer : container
        }`}
      >
        <Card className={`u-padding--32 u-margintop--8 ${card}`} elevation={0}>
          <CardHeader title={<Typography variant="h2">Create a new simulation</Typography>} />
          <Box sx={{ width: '100%', height: 'calc(100% - 120px)' }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};
                if (isStepOptional(index)) {
                  labelProps.optional = <Typography variant="caption">Optional</Typography>;
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === 0 && (
              <Grid
                container
                minHeight={80}
                height="100%"
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item justifyContent="center" flexDirection="column" xs={4}>
                  <Box display="flex" flexDirection="column" rowGap={4} sx={{ maxWidth: 300 }}>
                    <TextField
                      size="small"
                      label="Name"
                      type="text"
                      variant="outlined"
                      value={simulationData?.simulationForm?.name ?? ''}
                      onChange={handleNameChange}
                      inputProps={{ 'data-test-id': 'simulation-name' }}
                      error={nameFieldError}
                      helperText={nameFieldError ? '*Required' : ''}
                    />
                    <FormControl size="small" error={typeFieldError}>
                      <InputLabel id="simulation-type-label">Type</InputLabel>
                      <Select
                        size="small"
                        labelId="simulation-type-label"
                        id="simulation-type-select"
                        value={simulationData?.simulationForm?.type ?? ''}
                        label="Type"
                        onChange={handleChangeSimulationType}
                        IconComponent={ExpandMoreIcon}
                        data-test-id="simulation-type"
                        MenuProps={{
                          PaperProps: {
                            sx: selectMenuStyle,
                          },
                        }}
                      >
                        {ALL_SIMULATION_TYPES.map((type) => (
                          <MenuItem value={type} key={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                      {typeFieldError && <FormHelperText>*Required</FormHelperText>}
                    </FormControl>
                  </Box>
                </Grid>
                <Divider orientation="vertical" sx={{ height: '100%', marginTop: '10px' }} />
                {simulationType !== SimulationType.HURRICANEIAN &&
                simulationType !== SimulationType.HURRICANEIDA ? (
                  <Grid item justifyContent="center" xs={4}>
                    <Typography variant="caption" align="center" gutterBottom>
                      The next step entails drawing a custom region. To draw a region, follow these
                      steps:
                    </Typography>
                    <Typography variant="caption" align="left" gutterBottom>
                      <ol>
                        <li>Click on the &quot;Draw a New Region&quot; button.</li>
                        <li>
                          After landing on the map, click on the &quot;Start Drawing&quot; button to
                          start drawing.
                        </li>
                        <li>
                          Click the &quot;Draw Region&quot; button first, and then the cursor should
                          turn into a + sign.
                        </li>
                        <li>Click on the map to start drawing.</li>
                        <li>
                          Double-click when the final vertex is drawn to complete the polygon.
                        </li>
                        <li>Click the &quot;End Drawing&quot; button to go back to the form.</li>
                        <li>
                          Use the &quot;Erase&quot; button if you need to erase the polygon or
                          points on the polygon.
                        </li>
                      </ol>
                    </Typography>
                    <Typography variant="caption" align="center" gutterBottom>
                      Click &quot;Next&quot; when you are ready!
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item justifyContent="center" xs={4}>
                    <Typography variant="caption" align="center" gutterBottom>
                      Hurricane simulations are configured for their specific region and downtime.
                      Click &quot;Next&quot; when you are ready!
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
            {activeStep === 1 && (
              <Box sx={{ height: '100%', flexDirection: 'row' }}>
                <Grid
                  container
                  minHeight={80}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        updateNavigatorInfoMutation({
                          variables: {
                            currentView: NavigatorViewType.SimulationFormDraw,
                            currentLifeline: Lifeline.EMPTY,
                          },
                        })
                      }
                      disabled={regionNumber >= 12}
                      aria-label="draw/select region"
                      title="draw/select region"
                      startIcon={<ShapeLineIcon />}
                    >
                      Draw New Region
                    </Button>
                  </Grid>
                </Grid>
                {hasRegions && (
                  <Box sx={{ alignContent: 'center' }}>
                    <Typography variant="subtitle1" mb={2}>
                      Please select occupancy types to exclude and enter your desired downtimes.
                    </Typography>
                  </Box>
                )}
                {hasRegions && (
                  <Box sx={{ height: 'calc(100% - 80px)', overflowY: 'hidden' }}>
                    <TableContainer sx={{ height: 'calc(100% - 40px)' }}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Region</TableCell>
                            <TableCell align="center">Occupancy Types to Exclude</TableCell>
                            {SIMULATION_AVAILABLE_LIFELINES[simulationType].power && (
                              <TableCell align="center">Power</TableCell>
                            )}
                            {SIMULATION_AVAILABLE_LIFELINES[simulationType].highway && (
                              <TableCell align="center">Highway</TableCell>
                            )}
                            {SIMULATION_AVAILABLE_LIFELINES[simulationType].bridge && (
                              <TableCell align="center">Bridge</TableCell>
                            )}
                            {SIMULATION_AVAILABLE_LIFELINES[simulationType].port && (
                              <TableCell align="center">Port</TableCell>
                            )}
                            {SIMULATION_AVAILABLE_LIFELINES[simulationType].airport && (
                              <TableCell align="center">Airport</TableCell>
                            )}
                            {SIMULATION_AVAILABLE_LIFELINES[simulationType].repairTime && (
                              <TableCell align="center">Repair Time</TableCell>
                            )}
                            {SIMULATION_AVAILABLE_LIFELINES[simulationType].building && (
                              <TableCell align="center">Building</TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {simulationData?.simulationForm?.simulationFormRegions?.map((region) => (
                            <TableRow key={region.priority}>
                              <TableCell className={cell}>
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="space-evenly"
                                  alignItems="center"
                                >
                                  <Typography>{region.priority} </Typography>
                                  <Box
                                    sx={{
                                      width: 20,
                                      height: 20,
                                      borderRadius: 1,
                                      bgcolor: REGION_COLOR_SPECTRUM[Number(region.id) % 12],
                                    }}
                                  />
                                </Grid>
                              </TableCell>
                              <TableCell sx={{ minWidth: 300, maxWidth: 300 }}>
                                <Autocomplete
                                  multiple
                                  id={`occupancy-types-list-${region.id}`}
                                  value={ALL_OCCUPANCY_TYPES.filter((occType) =>
                                    (region.occupancyTypes as string[])?.includes(
                                      occType.onecCode.toString(),
                                    ),
                                  )}
                                  options={ALL_OCCUPANCY_TYPES.filter(
                                    (occType) => occType.isSearchable,
                                  )}
                                  disableCloseOnSelect
                                  getOptionLabel={(option) => option.onecCodeDescr}
                                  renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option.onecCodeDescr}
                                    </li>
                                  )}
                                  fullWidth
                                  PopperComponent={StyledPopper}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Occupancy Types"
                                      placeholder="Select occupancy types"
                                      sx={{
                                        '& .MuiAutocomplete-inputRoot': {
                                          height: 'auto',
                                          minHeight: 40,
                                        },
                                      }}
                                    />
                                  )}
                                  onChange={(_, value) =>
                                    onChangeOccupancyTypes({
                                      id: region.id,
                                      value: value.map((option) => option.onecCode.toString()),
                                    })
                                  }
                                  data-test-id={`occupancy-types-list-${region.id}`}
                                />
                              </TableCell>
                              {SIMULATION_AVAILABLE_LIFELINES[simulationType].power && (
                                <TableCell className={cell}>
                                  <DaysHoursInput
                                    hours={region.lifelineDowntimes.power}
                                    minHours={1}
                                    maxHours={999 * 24 + 23}
                                    classes={{
                                      parent,
                                      root: inputRoot,
                                      input: inputInput,
                                      inputAdornedEnd,
                                    }}
                                    onChangeHours={onChangeHours}
                                    id={`power-${region.id}`}
                                  />
                                </TableCell>
                              )}
                              {SIMULATION_AVAILABLE_LIFELINES[simulationType].highway && (
                                <TableCell className={cell}>
                                  <DaysHoursInput
                                    hours={region.lifelineDowntimes.highway}
                                    minHours={1}
                                    maxHours={999 * 24 + 23}
                                    classes={{
                                      parent,
                                      root: inputRoot,
                                      input: inputInput,
                                      inputAdornedEnd,
                                    }}
                                    onChangeHours={onChangeHours}
                                    id={`highway-${region.id}`}
                                  />
                                </TableCell>
                              )}
                              {SIMULATION_AVAILABLE_LIFELINES[simulationType].bridge && (
                                <TableCell className={cell}>
                                  <DaysHoursInput
                                    hours={region.lifelineDowntimes.bridge}
                                    minHours={1}
                                    maxHours={999 * 24 + 23}
                                    classes={{
                                      parent,
                                      root: inputRoot,
                                      input: inputInput,
                                      inputAdornedEnd,
                                    }}
                                    onChangeHours={onChangeHours}
                                    id={`bridge-${region.id}`}
                                  />
                                </TableCell>
                              )}
                              {SIMULATION_AVAILABLE_LIFELINES[simulationType].port && (
                                <TableCell className={cell}>
                                  <DaysHoursInput
                                    hours={region.lifelineDowntimes.port}
                                    minHours={1}
                                    maxHours={999 * 24 + 23}
                                    classes={{
                                      parent,
                                      root: inputRoot,
                                      input: inputInput,
                                      inputAdornedEnd,
                                    }}
                                    onChangeHours={onChangeHours}
                                    id={`port-${region.id}`}
                                  />
                                </TableCell>
                              )}
                              {SIMULATION_AVAILABLE_LIFELINES[simulationType].airport && (
                                <TableCell className={cell}>
                                  <DaysHoursInput
                                    hours={region.lifelineDowntimes.airport}
                                    minHours={1}
                                    maxHours={999 * 24 + 23}
                                    classes={{
                                      parent,
                                      root: inputRoot,
                                      input: inputInput,
                                      inputAdornedEnd,
                                    }}
                                    onChangeHours={onChangeHours}
                                    id={`airport-${region.id}`}
                                  />
                                </TableCell>
                              )}
                              {SIMULATION_AVAILABLE_LIFELINES[simulationType].repairTime && (
                                <TableCell className={cell}>
                                  <DaysHoursInput
                                    hours={region.lifelineDowntimes.repairTime}
                                    minHours={1}
                                    maxHours={999 * 24 + 23}
                                    classes={{
                                      parent,
                                      root: inputRoot,
                                      input: inputInput,
                                      inputAdornedEnd,
                                    }}
                                    onChangeHours={onChangeHours}
                                    id={`repairTime-${region.id}`}
                                  />
                                </TableCell>
                              )}
                              {SIMULATION_AVAILABLE_LIFELINES[simulationType].building && (
                                <TableCell className={cell}>
                                  <DaysHoursInput
                                    hours={region.lifelineDowntimes.building}
                                    minHours={1}
                                    maxHours={999 * 24 + 23}
                                    classes={{
                                      parent,
                                      root: inputRoot,
                                      input: inputInput,
                                      inputAdornedEnd,
                                    }}
                                    onChangeHours={onChangeHours}
                                    id={`building-${region.id}`}
                                  />
                                </TableCell>
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
              </Box>
            )}
            {activeStep === 2 && (
              <Grid
                container
                minHeight={80}
                height="100%"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography sx={{ mt: 2, mb: 1 }} data-test-id="simulation-summary">
                    {simulationType !== SimulationType.HURRICANEIAN &&
                    simulationType !== SimulationType.HURRICANEIDA
                      ? `Your Simulation: ${simulationData?.simulationForm?.name} has a total of ${simulationData?.simulationForm?.simulationFormRegions.length} regions, Please verify before submitting a run!`
                      : `Your Simulation: ${simulationData?.simulationForm?.name} has hurricane type selected, Please verify before submitting a run!`}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {activeStep === steps.length &&
              (!mutationError || !mutationData?.createSimulation?.error) && (
                <Grid
                  container
                  spacing={2}
                  minHeight={80}
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Grid item>
                    <Typography sx={{ mt: 2, mb: 1, marginBottom: '10px' }}>
                      Your simulation has been submitted
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      component={RouterLink}
                      to="/finance/analysis"
                      aria-label="see results"
                      title="See Results"
                    >
                      See Results
                    </Button>
                  </Grid>
                </Grid>
              )}
            {activeStep < steps.length && (
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )}
                {(activeStep !== 1 ||
                  simulationData?.simulationForm?.simulationFormRegions.length > 0) && (
                  <Button onClick={handleNext}>
                    {activeStep === steps.length - 1 ? 'Run' : 'Next'}
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </Card>
      </div>
    );
  }
  return null;
};

export default SimulationsFormContainer;
