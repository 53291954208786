import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { List, ListItem, ListItemIcon, ListItemText, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import NoVulnerableLocationIcon from '../../../../../assets/images/NoVulnerableLocationFound.svg';
import { WHITE } from '../../../../util/productGlobals';

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    padding: '0 !important',
  },
  listItemIcon: {
    width: '4px',
    height: '4px',
    minWidth: '4px',
    backgroundColor: WHITE,
    borderRadius: '50%',
    marginRight: 6,
  },
  listItemText: {
    margin: '0 !important',
  },
  listItemTextPrimary: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(18),
    fontWeight: 500,
  },
}));

const EmptyVulnerableLocationList: React.FC = () => {
  const { t } = useTranslation();
  const { listItem, listItemIcon, listItemText, listItemTextPrimary } = useStyles();

  return (
    <div
      className="o-flex-center-container u-flex-column u-paddingx--16 u-paddingy--32 u-marginbottom--100"
      data-test-id="EmptyVulnerableLocationList"
    >
      <img
        src={NoVulnerableLocationIcon}
        alt={t('vulnerableList:noVulnerableLocation')}
        width={80}
        data-test-id="noVulnerableLocationIcon"
      />
      <Typography
        variant="body1"
        className="u-paddingtop--10 u-paddingbottom--24"
        color="textPrimary"
      >
        {t('vulnerableList:noVulnerableLocation')}
      </Typography>
      <div className="o-flexcol-container" data-test-id="noVulnerableLocationFoundDescription">
        <Typography variant="caption" color="textPrimary">
          {t('detailView:noDataSolution:description')}
        </Typography>
        <List>
          {[
            t('detailView:noDataSolution:changeFilteringCriteria'),
            t('detailView:noDataSolution:changeSearchCriteria'),
            t('detailView:noDataSolution:zoomOut'),
          ].map((body) => (
            <ListItem classes={{ root: listItem }} key={body}>
              <ListItemIcon classes={{ root: listItemIcon }} />
              <ListItemText classes={{ root: listItemText, primary: listItemTextPrimary }}>
                {body}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};

EmptyVulnerableLocationList.displayName = 'EmptyVulnerableLocationList';
export default EmptyVulnerableLocationList;
