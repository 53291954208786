import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { WHITE } from '../../../../util/productGlobals';

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    border: `1px solid rgba(248, 247, 242, 0.35)`,
    backdropFilter: 'blur(4px)',
    boxSizing: 'border-box',
    padding: '6px 15px 6px 12px',
  },
  inner: {
    border: `1px solid ${WHITE}`,
    boxSizing: 'border-box',
    borderRadius: '50%',
    width: 9,
    height: 9,
  },
  label: {
    whiteSpace: 'nowrap',
    marginLeft: 11,
  },
}));

const NoDataChip: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={`${classes.root} u-radius--46`} data-test-id="NoData-Chip">
      <div className={`${classes.inner}`} />
      <Typography className={`${classes.label} f-lightgrey2`} variant="caption">
        {t('detailView:noData')}
      </Typography>
    </div>
  );
};
NoDataChip.displayName = 'NoDataChip';

export default NoDataChip;
