import {
  GetLocationsWithFilterQuery,
  GetMaterialityKpIsQuery,
  useGetLocationsFilterQuery,
  useGetMaterialityKpIsQuery,
  useGetMaterialitySettingsQuery,
  useGetSliceIdxQuery,
} from '../__generated__/graphql';

import { ApolloError } from '@apollo/client';
import { App } from '../PlanningApp/AppConfig';
import { Lifeline } from '../util/productEnums';

export interface MaterialityKPI {
  meanAnnualLoss: number;
  percentByRevenue: number;
  overMaterialityPercent: number; // Can be negative value if below
  overMateriality: number; // Can be negative value if below
}

export interface LocationsLoss {
  lifeline: Lifeline;
  loss: number;
  location:
    | GetLocationsWithFilterQuery['locations'][0]
    | GetMaterialityKpIsQuery['calculateMateriality'][0];
}

export type UseGetMaterialityDataReturnType = {
  values?: MaterialityKPI;
  lifelineLosses?: LocationsLoss[];
  loading?: boolean;
  error?: ApolloError;
};

export interface LocTypeAnnualRevenue {
  name: string;
  annualRevenue?: string;
  additionalCost?: string;
}

export type LocTypeLossRate = {
  name: string;
  structure?: number;
  power?: number;
  people?: number;
  highway?: number;
  bridge?: number;
  port?: number;
  airport?: number;
};

function useGetMaterialityData(): UseGetMaterialityDataReturnType {
  const {
    data: {
      planView: { sliceIdx },
    },
  } = useGetSliceIdxQuery();
  const {
    data: {
      planView: { locFilter },
    },
  } = useGetLocationsFilterQuery();

  if (!sliceIdx) {
    App.error('[useGetMaterialityData] - Bad sliceIdx.');
  }

  const { data, loading, error } = useGetMaterialityKpIsQuery({
    skip: !sliceIdx,
    variables: {
      sliceIdx,
    },
  });

  const {
    data: dataMaterialitySettings,
    loading: loadingMaterialitySettings,
    error: errorMaterialitySettings,
  } = useGetMaterialitySettingsQuery();

  if (error) {
    App.error(`[useGetMaterialityData] - GetMaterialityKpIsQuery error: `, error);
    return { error, loading: false, values: null, lifelineLosses: null };
  }

  if (errorMaterialitySettings) {
    App.error(`[useGetMaterialityData] - GetMaterialitySettings error: `, error);
    return { error: errorMaterialitySettings, loading: false, values: null, lifelineLosses: null };
  }

  if (loading || loadingMaterialitySettings) {
    return { loading: true, error: null, values: null, lifelineLosses: null };
  }

  if (!data?.calculateMateriality) {
    return { loading: false, error, values: null, lifelineLosses: null };
  }

  const materialitySettings = dataMaterialitySettings?.getMaterialitySettings;

  if (materialitySettings?.locationTypeMaterialitySettings?.length === 0) {
    return {
      loading: false,
      error: new ApolloError({ errorMessage: 'No materiality settings' }),
      values: null,
      lifelineLosses: null,
    };
  }

  if (data?.calculateMateriality?.length === 0) {
    return {
      loading: false,
      error: null,
      values: {
        meanAnnualLoss: 0,
        percentByRevenue: 0,
        overMaterialityPercent: 0,
        overMateriality: 0,
      },
      lifelineLosses: null,
    };
  }

  const materialityThreshold = materialitySettings?.materialityThreshold;

  const annualRevenues: LocTypeAnnualRevenue[] =
    materialitySettings?.locationTypeMaterialitySettings?.map((setting) => ({
      name: setting.locationType,
      annualRevenue: setting.annualRevenue.toString(),
    }));

  const materialityData =
    locFilter?.locTypes?.length > 0
      ? data?.calculateMateriality?.filter((location) =>
          locFilter?.locTypes?.includes(location.type),
        )
      : data?.calculateMateriality;
  const count = materialityData?.length;

  // The sum of the losses across locations
  const totalLosses = materialityData?.reduce(
    (acc, cur) => acc + (cur.resilienceStats?.[0]?.sumMaxMaterialityLosses ?? 0),
    0,
  );

  // Total revenue (sum of revenue across location types, as provided by the user)
  const totalRevenues = materialityData?.reduce((acc, cur) => {
    const revenue = annualRevenues.find((rev) => rev.name === cur.type);
    return acc + (revenue ? Number(revenue.annualRevenue) : 0);
  }, 0);

  // Mean annual loss
  const meanAnnualLoss = count ? totalLosses / count : 0;

  // totalLosses % of revenue
  const percentByRevenue =
    totalRevenues > 0 ? Number(((totalLosses * 100) / totalRevenues).toFixed(2)) : 0;

  const lifelineLosses: LocationsLoss[] = [];
  materialityData?.forEach((location) => {
    const { nearbyPower, nearbyPeople, nearbyHighway, nearbyBridge, nearbyPort, nearbyAirport } =
      location.resilienceStats?.[0] ?? {};
    if (nearbyPower?.materialityLoss > 0) {
      lifelineLosses.push({
        lifeline: Lifeline.POWER,
        loss: nearbyPower?.materialityLoss,
        location,
      });
    }
    if (nearbyPeople?.materialityLoss > 0) {
      lifelineLosses.push({
        lifeline: Lifeline.PEOPLE,
        loss: nearbyPeople?.materialityLoss,
        location,
      });
    }
    if (nearbyHighway?.materialityLoss > 0) {
      lifelineLosses.push({
        lifeline: Lifeline.HIGHWAY,
        loss: nearbyHighway?.materialityLoss,
        location,
      });
    }
    if (nearbyBridge?.materialityLoss > 0) {
      lifelineLosses.push({
        lifeline: Lifeline.BRIDGE,
        loss: nearbyBridge?.materialityLoss,
        location,
      });
    }
    if (nearbyPort?.materialityLoss > 0) {
      lifelineLosses.push({
        lifeline: Lifeline.PORT,
        loss: nearbyPort?.materialityLoss,
        location,
      });
    }
    if (nearbyAirport?.materialityLoss > 0) {
      lifelineLosses.push({
        lifeline: Lifeline.AIRPORT,
        loss: nearbyAirport?.materialityLoss,
        location,
      });
    }
  });
  return {
    loading: false,
    error: null,
    values: {
      meanAnnualLoss,
      percentByRevenue,
      overMaterialityPercent: percentByRevenue - materialityThreshold,
      // Mean over materiality by threshold
      overMateriality: (totalLosses - (materialityThreshold / 100) * totalRevenues) / count,
    },
    lifelineLosses: lifelineLosses.sort((a, b) => b.loss - a.loss),
  };
}

export default useGetMaterialityData;
