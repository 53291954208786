import * as React from 'react';
import {
  Button,
  Paper,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LocTypeAnnualRevenue } from '../../../../../Hooks/useGetMaterialityData';
import useUpdateMaterialitySettings from '../../../../../Hooks/useUpdateMaterialitySettings';
import CurrencyInput from '../../../../CommonComponents/InputControls/CurrencyInput/CurrencyInput';

type LocTypesAnnualRevenuesFormProps = {
  locTypes: string[];
};

const tableCellStyle: SxProps = {
  borderBottom: 'none',
  p: 2,
};

const LocTypesAnnualRevenuesForm: React.FC<LocTypesAnnualRevenuesFormProps> = ({ locTypes }) => {
  const { t } = useTranslation();
  const [annualRevenues, setAnnualRevenues] = React.useState<LocTypeAnnualRevenue[]>(
    locTypes.map((locType) => ({
      name: locType,
      annualRevenue: '',
    })),
  );
  const updateMaterialitySettings = useUpdateMaterialitySettings();

  const onChange = React.useCallback(
    ({ inputKey, idx, value }: { inputKey: string; idx: number; value: string }) => {
      const newAnnualRevenues = [...annualRevenues];
      newAnnualRevenues.splice(idx, 1, {
        ...newAnnualRevenues[idx],
        [inputKey]: value,
      });
      setAnnualRevenues(newAnnualRevenues);
    },
    [annualRevenues],
  );

  const renderTableCellItem = (inputKey: string, value: string, idx: number) => {
    if (inputKey === 'name') {
      return <Typography variant="body2">{value}</Typography>;
    }
    return (
      <CurrencyInput
        id={`${idx}-${inputKey}`}
        value={value}
        onChange={({ value }: { value: string }) => onChange({ inputKey, idx, value })}
      />
    );
  };

  const handleResetRevenues = React.useCallback(() => {
    setAnnualRevenues(
      locTypes.map((locType) => ({
        name: locType,
        annualRevenue: '',
      })),
    );
  }, [locTypes]);

  const handleSaveRevenues = React.useCallback(() => {
    updateMaterialitySettings({
      variables: {
        input: {
          locationTypeLossRatesInput: annualRevenues.map((item) => ({
            locationType: item.name,
            annualRevenue: Number(item.annualRevenue),
          })),
        },
      },
    });
  }, [annualRevenues, updateMaterialitySettings]);

  return (
    <div data-test-id="LocTypesAnnualRevenuesForm">
      <Typography variant="h3" sx={{ mt: 8 }} data-test-id="LocTypesAnnualRevenuesForm-title">
        {t('materiality:sections:dashboard:revenues:title')}
      </Typography>
      <TableContainer
        component={Paper}
        sx={{ mt: 8, backgroundColor: 'transparent', boxShadow: 'none' }}
      >
        <Table aria-label="AnnualRevenues" data-test-id="AnnualRevenuesTable">
          <TableBody>
            {annualRevenues.map((rowData, idx) => (
              <TableRow
                key={rowData.name}
                data-test-id={`row-${rowData.name}`}
                sx={{ borderBottom: '0.5px solid #111212' }}
              >
                {Object.entries(rowData).map(([key, value]) => (
                  <TableCell key={key} sx={tableCellStyle}>
                    {renderTableCellItem(key, value, idx)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction="row" justifyContent="flex-end" mt={8}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          className="u-clickable u-marginright--8"
          data-test-id="ResetRevenues"
          onClick={handleResetRevenues}
        >
          {t('materiality:sections:dashboard:buttons:reset')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className="u-clickable"
          data-test-id="SaveRevenues"
          onClick={handleSaveRevenues}
          disabled={!annualRevenues.every((item) => item.annualRevenue)}
        >
          {t('materiality:sections:dashboard:buttons:analyze')}
        </Button>
      </Stack>
    </div>
  );
};

export default LocTypesAnnualRevenuesForm;
