import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const MoreLocationsButtonFC: React.FC<{ handleClick: () => void }> = ({ handleClick }) => {
  const { t } = useTranslation();

  return (
    <Button fullWidth onClick={handleClick} data-test-id="MoreLocationsButton">
      <div className="o-flex-center-container">
        <Typography variant="body2">{t('buttons:showMoreLocations')}</Typography>
        <ExpandMoreIcon />
      </div>
    </Button>
  );
};

const MoreLocationsButton = React.memo(MoreLocationsButtonFC);
MoreLocationsButton.displayName = 'MoreLocationsButton';
MoreLocationsButtonFC.displayName = 'MoreLocationsButtonFC';
export default MoreLocationsButton;
