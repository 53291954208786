import * as React from 'react';
import { lineString, point } from '@turf/helpers';
import bearing from '@turf/bearing';
import midpoint from '@turf/midpoint';
import bezierSpline from '@turf/bezier-spline';
import transformRotate from '@turf/transform-rotate';

import {
  Airport,
  Bridge,
  GetBuiltObjectLifelineMarkersQuery,
  GetLocationLifelineMarkersQuery,
  HighwaySegment,
  Port,
  SliceHazardType,
  useGetPlanViewFiltersQuery,
} from '../../../../__generated__/graphql';
import { Lifeline } from '../../../../util/productEnums';
import { BI_LIFELINE_ARC_SOURCE, BI_LIFELINE_SOURCE } from '../../../../util/productGlobals';
import MapSource from '../../../CommonComponents/Map/MapSource';

export type LifelineArcsFCProps = {
  builtObject:
    | GetLocationLifelineMarkersQuery['location']
    | GetBuiltObjectLifelineMarkersQuery['builtObject'];
};

export const vulnerableColor = '#B200FD';
export const defaultOpacity = 1;

const LifelineArcsFC: React.FC<LifelineArcsFCProps> = ({
  builtObject: { coordinates, airports, ports, bridges, highways },
}) => {
  const {
    data: {
      planView: { planFilters },
    },
  } = useGetPlanViewFiltersQuery();

  const calculateBezierCurve = React.useCallback((locationCoords: any, lifelineCoords: any) => {
    const center = midpoint(locationCoords, lifelineCoords);
    const options = { pivot: locationCoords };
    const angle = bearing(locationCoords, lifelineCoords);
    const rotatedCenter = transformRotate(center, angle > 0 ? -10 : 10, options);

    const line = lineString([
      locationCoords.geometry.coordinates,
      rotatedCenter.geometry.coordinates,
      lifelineCoords.geometry.coordinates,
    ]);
    return bezierSpline(line);
  }, []);

  const arcData = React.useMemo(() => {
    const features: GeoJSON.Feature<GeoJSON.Geometry>[] = [];
    const locationCoords = point(coordinates);
    const lifelines: { [key: string]: any } = {
      airports,
      ports,
      bridges:
        planFilters.hazardType === SliceHazardType.Flood ||
        planFilters.hazardType === SliceHazardType.Wind
          ? []
          : bridges,
      highways,
    };
    const maxArcs = 16;
    const categoryLength = 4;
    let equalDistribution = maxArcs / categoryLength;
    let index = 1;
    for (const key in lifelines) {
      if (Object.prototype.hasOwnProperty.call(lifelines, key)) {
        let value = lifelines[key];
        if (value) {
          if (value.length > equalDistribution) {
            value = value.slice(0, equalDistribution);
          }
          features.push(
            ...value.map((lifeline: Airport | Port | Bridge | HighwaySegment) => {
              let category = '';
              switch (key) {
                case 'airports':
                  category = Lifeline.AIRPORT;
                  break;
                case 'ports':
                  category = Lifeline.PORT;
                  break;
                case 'bridges':
                  category = Lifeline.BRIDGE;
                  break;
                case 'highways':
                  category = Lifeline.HIGHWAY;
                  break;
                default:
                  category = '';
              }

              return {
                id: lifeline.id,
                type: 'Feature',
                geometry: calculateBezierCurve(locationCoords, point(lifeline.coordinates))
                  .geometry,
                properties: {
                  id: lifeline.id,
                  category,
                },
              };
            }),
          );
          equalDistribution = (maxArcs - features.length) / (categoryLength - index);
          index += 1;
        }
      }
    }

    return features;
  }, [airports, bridges, calculateBezierCurve, coordinates, highways, planFilters, ports]);

  return (
    <MapSource
      id={BI_LIFELINE_ARC_SOURCE}
      type="line"
      features={arcData}
      iconPaint={{
        'line-width': 3,
        'line-opacity': defaultOpacity,
        'line-color': vulnerableColor,
      }}
      previousSourceId={BI_LIFELINE_SOURCE}
    />
  );
};

const LifelineArcsDefault = React.memo(LifelineArcsFC);
LifelineArcsDefault.displayName = 'LifelineArcs';
export default LifelineArcsDefault;
