import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SYWSectionProps } from 'onec-types';

import { Typography } from '@mui/material';

import { hoursToDayHourAbbrev } from '../../../../util/time';
import RecoveryCurveChart from '../../Charts/RecoveryCurveChart';
import ReturnPeriodChart from '../../Charts/ReturnPeriodChart';
import showYourWorkStyles from './showYourWorkStyles';
import useHasFinanceEntitlement from '../../../../Hooks/useHasFinanceEntitlement';

const SECTION_KEY = 'showYourWork:sections:power:';

const SYWPowerFC: React.FC<SYWSectionProps> = ({ stats, gutterTop }) => {
  const { t } = useTranslation();
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;
  const { mean, stddev, threshold, recoveryCurve, returnPeriodData } = stats;
  const { subtitle, statLabel, statValue, range } = showYourWorkStyles({
    isVulnerable: mean > threshold,
  });

  const { min, max } = React.useMemo(
    () => ({
      min: hoursToDayHourAbbrev(Math.max(0, mean - stddev)),
      max: hoursToDayHourAbbrev(mean + stddev),
    }),
    [mean, stddev],
  );

  return (
    <section
      className="u-width--100"
      /// gutterTop is true when displayed under SYW content
      id={`power-section${!gutterTop ? '-detail' : ''}`}
      data-test-id="SYWPower"
    >
      <Typography style={gutterTop && { marginTop: '28px' }} variant="h2" paragraph>
        {t(`${SECTION_KEY}downtime:title`)}
      </Typography>

      <Typography className={statValue} data-test-id="SYWPower-mean">
        {hoursToDayHourAbbrev(mean)}
      </Typography>
      <Typography className={statLabel} paragraph>
        {t(`${SECTION_KEY}downtime:averageDowntime`)}
      </Typography>
      <Typography variant="h5" className={range} gutterBottom data-test-id="downtimeRange">
        {min !== max && max !== '0h' && t('showYourWork:range', { min, max })}
      </Typography>
      <Typography className={statLabel} paragraph>
        {t('showYourWork:threshold', { value: hoursToDayHourAbbrev(threshold) })}
      </Typography>

      <Typography paragraph>{t(`${SECTION_KEY}downtime:desc`)}</Typography>
      <Typography paragraph>
        <strong>{t('showYourWork:resultsBasedOn')}</strong>
      </Typography>
      {!isFinanceEntitled && recoveryCurve?.length > 0 && (
        <>
          <Typography variant="subtitle1" className={subtitle} gutterBottom>
            {t(`${SECTION_KEY}recovery:title`)}
          </Typography>
          <Typography paragraph data-test-id="RecoveryChartDesc">
            {t(`${SECTION_KEY}recovery:desc`)}
          </Typography>
          <Typography paragraph data-test-id="RecoveryChartIntro">
            {t(`${SECTION_KEY}recovery:descChart`)}
          </Typography>
          <RecoveryCurveChart curve={recoveryCurve} lifeline="power" />
        </>
      )}
      <Typography variant="subtitle1" className={subtitle} gutterBottom>
        {t(`${SECTION_KEY}severityImpact:title`)}
      </Typography>
      <Typography paragraph data-test-id="ReturnPeriodChartIntro">
        {t(`${SECTION_KEY}severityImpact:desc`)}
      </Typography>
      {returnPeriodData?.length > 0 && (
        <ReturnPeriodChart points={returnPeriodData} threshold={threshold} lifeline="power" />
      )}

      <Typography paragraph>
        <Trans
          i18nKey={`showYourWork:${
            isFinanceEntitled ? 'exploreAnalysisCatnet' : 'exploreAnalysis'
          }`}
        >
          <strong>Did you know?</strong>
        </Trans>
      </Typography>
    </section>
  );
};

const SYWPower = React.memo(SYWPowerFC);
SYWPower.displayName = 'SYWPower';
export default SYWPower;
