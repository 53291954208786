import * as React from 'react';
import { useTranslation } from 'react-i18next';

import HomeIcon from '@mui/icons-material/Home';
import { makeStyles } from '@mui/styles';
import { MenuItem, Theme, Typography } from '@mui/material';

import {
  LIST_HIGHLIGHT,
  PURPLE,
  PURPLE_DARK_TRANSPARENT,
  WHITE,
} from '../../../../util/productGlobals';
import Icon from '../../../CommonComponents/Icon/Icon';
import { Lifeline } from '../../../../util/productEnums';
import { SliceHazardType } from '../../../../__generated__/graphql';

type SYWNavItemProps = {
  category: string;
  isActive: boolean;
  isVulnerable: boolean;
  hazardType: string;
  handleClick?: (e: React.MouseEvent) => void;
  children?: React.ReactNode | undefined;
};

type SYWNavItemStyleProps = {
  isActive?: boolean;
  isVulnerable?: boolean;
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      margin: `${theme.spacing(1)} 0`,
      padding: theme.spacing(2),
      borderRadius: '6px',
      backgroundColor: (props: SYWNavItemStyleProps) =>
        props.isActive ? LIST_HIGHLIGHT : 'transparent',
    },
    lifelineTag: {
      backgroundColor: (props: SYWNavItemStyleProps) =>
        props.isVulnerable ? PURPLE_DARK_TRANSPARENT : LIST_HIGHLIGHT,
      border: (props: SYWNavItemStyleProps) =>
        props.isVulnerable ? `0.5px solid ${PURPLE}` : `0.5px solid ${WHITE}`,
      display: 'inline-flex',
      alignItems: 'flex-start',
      backdropFilter: 'blur(4px)',
    },
    homeIcon: {
      fontSize: '1rem',
    },
  };
});

const SYWNavItemFC: React.FC<SYWNavItemProps> = ({
  category,
  isActive,
  isVulnerable,
  hazardType,
  handleClick,
  children,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ isActive, isVulnerable });

  return (
    <MenuItem
      className={`o-flexcol-container u-full-width ${classes.root} ${
        isActive ? 'syw-active-item' : ''
      }`}
      onClick={handleClick}
      data-category={category}
      tabIndex={0}
      data-test-id={`SYWNavItem-${category}`}
    >
      <div className="o-flex-vcenter-container u-full-width">
        {category !== 'transportation' && (
          <div
            className={`u-clickable u-radius--100 u-marginright--16 u-paddingx--8 u-paddingy--4 ${classes.lifelineTag}`}
          >
            {category === 'overview' ? (
              <HomeIcon fontSize="small" className={classes.homeIcon} />
            ) : (
              <Icon name={`Lifeline-${category}${isVulnerable ? '-down' : ''}`} />
            )}
          </div>
        )}
        <Typography variant="h5">
          {category === Lifeline.BRIDGE && hazardType === SliceHazardType.Flood
            ? t(`showYourWork:sections:${category}:nav*`)
            : t(`showYourWork:sections:${category}:nav`)}
        </Typography>
      </div>
      {children}
    </MenuItem>
  );
};

const SYWNavItem = React.memo(SYWNavItemFC);
SYWNavItem.displayName = 'SYWNavItem';
export default SYWNavItem;
