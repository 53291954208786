import * as React from 'react';
import { ApolloError } from '@apollo/client';

import { Product, useGetUserEntitlementsModulesQuery } from '../__generated__/graphql';
import { App } from '../PlanningApp/AppConfig';

type EntitlementsResult = {
  error: ApolloError;
  loading: boolean;
  data: boolean | null;
};
/** π
 * Function to query the entitlements and also to be used
 * to evolve into more conditions around features for querying
 * @param ent Internal Enum for entitlements
 * @returns Errors, Domino entitlements, Specific logic per entitlement type
 */
function useHasMaterialityEntitlement(): EntitlementsResult {
  const { data, error, loading } = useGetUserEntitlementsModulesQuery();

  if (error) {
    App.error(`[useMaterialityEntitlements] Error: ${error.message}`);
  }
  return React.useMemo(() => {
    if (error) return { error, data: null, loading };
    return {
      error,
      data:
        data?.user?.entitlements?.enabledProductsModule.findIndex(
          (product) => product.module === 'materiality' && product.product === Product.Domino,
        ) > -1,
      loading,
    };
  }, [error, loading, data?.user?.entitlements?.enabledProductsModule]);
}

export default useHasMaterialityEntitlement;
