import urlSearchParams from '@ungap/url-search-params';
import * as d3 from 'd3';

import {
  GetLocationsWithFilterQuery,
  LocFilter,
  MapView,
  SliceHazardType,
} from '../__generated__/graphql';
import { Order } from './productEnums';
import { MAPBOX_LIFELINES_ARC_LIMIT } from './productGlobals';

const URLSearchParams = window.URLSearchParams || urlSearchParams;
export const getParam = (queryString: string, name: string): string =>
  new URLSearchParams(queryString).get(name);

export const getLocationsFilterChoices: (
  locations: GetLocationsWithFilterQuery['locations'],
) => LocFilter = (locations) => {
  const locTypesSet = new Set<string>();
  const locGroupsSet = new Set<string>();
  locations.forEach((loc) => {
    if (loc?.type) locTypesSet.add(loc.type);
    if (loc?.group) locGroupsSet.add(loc.group);
  });
  const allFilterChoices: LocFilter = {
    locTypes: Array.from(locTypesSet).sort(),
    locGroups: Array.from(locGroupsSet).sort(),
  };
  return allFilterChoices;
};

export const getTopRiskLifelineAssets = (assets: any[]): any[] => {
  const sortedAssets = (assets ?? []).slice().sort((a, b) => b.stats[0].mean - a.stats[0].mean);
  return sortedAssets.slice(0, MAPBOX_LIFELINES_ARC_LIMIT);
};

export const splitLifelineAssets = (assets: any[]) => {
  const sortedAssets = (assets ?? []).slice().sort((a, b) => b.stats[0].mean - a.stats[0].mean);
  return {
    topAssets: sortedAssets.slice(0, MAPBOX_LIFELINES_ARC_LIMIT),
    otherAssets: sortedAssets.slice(MAPBOX_LIFELINES_ARC_LIMIT),
  };
};

export const areMapBoundsSame = (firstBounds: MapView, secondBounds: MapView) => {
  return (
    firstBounds.northWest.latitude.toPrecision(8) ===
      secondBounds.northWest.latitude.toPrecision(8) &&
    firstBounds.northWest.longitude.toPrecision(8) ===
      secondBounds.northWest.longitude.toPrecision(8) &&
    firstBounds.southEast.latitude.toPrecision(8) ===
      secondBounds.southEast.latitude.toPrecision(8) &&
    firstBounds.southEast.longitude.toPrecision(8) ===
      secondBounds.southEast.longitude.toPrecision(8)
  );
};

/// This function returns pretty ticks of axis according to input value
export const getRecoveryChartXTicks = (val: number) => {
  const ticks: number[] = [];
  if (val < 1e-6) return ticks;
  let x = val / 4;

  /// 10 <= x * (10 ^ exp) < 100
  /// => log10(10 / x) <= exp < log10(100 / x)
  /// => 1 - log10(x) <= exp < 2 - log10(x)
  /// => exp = Math.ceil(1 - log10(x))
  const exp = Math.ceil(1 - Math.log10(x));
  x *= 10 ** exp;

  const prettyNumbers: number[] = [10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90];
  let interval = prettyNumbers.reduce(
    (min, cur) => (Math.abs(min - x) < Math.abs(cur - x) ? min : cur),
    10,
  );
  interval /= 10 ** exp;
  for (let value = 0; value < val; value += interval) {
    ticks.push(value);
  }
  ticks.push(val);
  return ticks;
};

export const validateEmail = (email: string) => {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const escapeDelimiter = (string: string) => {
  if (string?.includes(',') || string?.includes('"')) {
    return `"${string.replace(/"/g, '""')}"`;
  }

  return string;
};

export const omitTypename = (obj: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { __typename, ...others } = obj;
  return others;
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// Remove patch number in version so that only major and minor left.
//  i.e. 1.2.0 -> 1.2
export const removePatchVersion = (version: string) => {
  if (!version?.includes('.')) return version;
  return version.slice(0, version.lastIndexOf('.'));
};

export const getLocalizedValue = (value: string) => {
  return Number(value).toLocaleString();
};

export const getLocalizedRange = (range: string) => {
  const [min, max] = range.split(' - ');
  return `${Number(min).toLocaleString()} - ${Number(max).toLocaleString()}`;
};

export const convertSliceHazardType = (hazard: string) =>
  hazard === 'seismic' ? SliceHazardType.Earthquake : (hazard.toUpperCase() as SliceHazardType);

export const formatNumber = (value: string | number) =>
  Number(Number(value).toFixed(2)).toLocaleString();
export const formatNumberByThousands = (value: string | number) =>
  Number((Number(value) / 1000).toFixed(2)).toLocaleString();
export const formatGoodCurrency = (value: string | number) =>
  (Math.round(Number(value) / 1000) - (Math.round(Number(value) / 1000) % 10)).toLocaleString();
export const formatPercent = (value: string | number) => Number(Number(value).toFixed(2));

// Takes an array of numbers and compute some summary statistics from it like quantiles, median..
// Those summary statistics are the info needed to draw a boxplot
export const getSummaryStats = (data: number[]) => {
  const sortedData = data.sort(function (a, b) {
    return a - b;
  });

  const q1 = d3.quantile(sortedData, 0.25);
  const median = d3.quantile(sortedData, 0.5);
  const q3 = d3.quantile(sortedData, 0.75);
  const min = d3.quantile(sortedData, 0.05);
  const max = d3.quantile(sortedData, 0.95);

  if (!q3 || !q1 || !median || !min || !max) {
    return null;
  }

  return { min, q1, median, q3, max };
};
