import { Dict } from 'onec-types';

import {
  BasicLifelineAsset,
  HighwaySegment,
  ResilienceStats,
  ResilienceThresholds,
} from '../__generated__/graphql';
import { LifelineEntitlementsType } from '../Hooks/useLifelineEntitlements';
import { LocationWithScore } from '../Components/ProductComponents/locationViewHelpers';
import { TOP_ASSETS_LIMIT } from './productGlobals';
import { ValidLifeline } from './productEnums';

export type Segment = Omit<HighwaySegment, 'coordinates'>;

const VULNERABLE_CALC_CONFIG: Record<
  ValidLifeline,
  { valKey: string; threshKey: string; weight: number }
> = {
  structure: {
    valKey: 'structuralDamage',
    threshKey: 'structuralDamage',
    weight: 0.25,
  },
  power: {
    valKey: 'nearbyPower',
    threshKey: 'powerDowntime',
    weight: 0.25,
  },
  people: {
    valKey: 'nearbyPeople',
    threshKey: 'peopleDowntime',
    weight: 0.25,
  },
  highway: {
    valKey: 'nearbyHighway',
    threshKey: 'highwayDowntime',
    weight: 0.0625,
  },
  bridge: {
    valKey: 'nearbyBridge',
    threshKey: 'bridgeDowntime',
    weight: 0.0625,
  },
  port: {
    valKey: 'nearbyPort',
    threshKey: 'portDowntime',
    weight: 0.0625,
  },
  airport: {
    valKey: 'nearbyAirport',
    threshKey: 'airportDowntime',
    weight: 0.0625,
  },
};

/**
 * Derive array of lifelines from return value of useLifelineEntitlements hook
 */
export function getEntitledLifelinesArr(entitlements: LifelineEntitlementsType): ValidLifeline[] {
  const lifelines: ValidLifeline[] = [];

  for (const lifeline in entitlements) {
    if (entitlements[lifeline as ValidLifeline]) lifelines.push(lifeline as ValidLifeline);
  }

  return lifelines;
}

/**
 * Calculate a resilience statistic's percentage of the mean value in excess of its threshold.
 */
export function getExcess(val: number, thresh: number): number {
  if (val === 0) return 0;

  return Math.max((val - thresh) / val, 0);
}

/**
 * Calculate the vulnerability score of a location using the weighted mean of each statistic's
 * percentage in excess of its threshold.
 */
export function calcVulnerabilityScore(
  resStatsDict: Dict,
  thresholds: ResilienceThresholds,
  entitlements: LifelineEntitlementsType,
): number {
  let vulnerabilityScore = 0;
  if (entitlements) {
    const entitledLifelinesArr = getEntitledLifelinesArr(entitlements);

    const totEntitledWeight = entitledLifelinesArr.reduce((prevTotWeight, lifeline) => {
      return prevTotWeight + VULNERABLE_CALC_CONFIG[lifeline].weight;
    }, 0);
    entitledLifelinesArr.forEach((lifeline) => {
      const { valKey, threshKey, weight } = VULNERABLE_CALC_CONFIG[lifeline];
      const pctExcess = getExcess(resStatsDict[valKey] as number, (thresholds as any)[threshKey]);
      vulnerabilityScore += (pctExcess * weight) / totEntitledWeight;
    });
  }
  return vulnerabilityScore;
}

/**
 * Calculate the vulnerability score of a location using the weighted mean of each statistic's
 * percentage in excess of its threshold.
 */
export function calcVulScoreFromResStats(
  resStats: ResilienceStats,
  thresholds: ResilienceThresholds,
  entitlements: LifelineEntitlementsType,
): number {
  const resStatsDict: Dict = {
    structuralDamage: resStats?.structuralDamage.mean ?? 0,
    nearbyPower: resStats.nearbyPower.mean ?? 0,
    nearbyPeople: resStats.nearbyPeople.mean ?? 0,
    nearbyHighway: resStats.nearbyHighway.mean ?? 0,
    nearbyBridge: resStats.nearbyBridge.mean ?? 0,
    nearbyPort: resStats.nearbyPort.mean ?? 0,
    nearbyAirport: resStats.nearbyAirport.mean ?? 0,
  };

  return calcVulnerabilityScore(resStatsDict, thresholds, entitlements);
}

/**
 * Determine whether a location is at risk based on its vulnerability score. A score over 0 means
 * at least one lifeline with downtime or damage has exceeded the user's specified thresholds.
 */
export function isLocVulnerable(score: number): boolean {
  return score > 0;
}

/**
 * Sort and return a list of locations by vulnerability score in descending order.
 */
export function sortVulnerableLocs(locations: LocationWithScore[]): LocationWithScore[] {
  return [...locations].sort((loc1, loc2) => loc2.vulnerabilityScore - loc1.vulnerabilityScore);
}

/**
 * Count the number of assets whose average downtime exceeds the threshold. Assumes that stats for
 * only one slice index are included.
 */
export function countVulnerableAssets(assets: BasicLifelineAsset[], threshold: number): number {
  return assets?.reduce((count, { stats }) => {
    const { mean } = stats[0];
    const isVulnerable = mean > threshold;
    return isVulnerable ? count + 1 : count;
  }, 0);
}

/**
 * Sort and return top three assets by average downtime. Assumes that stats for only one slice index
 * are included.
 */
export function getTopVulnerableAssets(assets: BasicLifelineAsset[]): BasicLifelineAsset[] {
  if (!assets) return [];
  const sortedAssets = [...assets].sort((a, b) => b.stats[0].mean - a.stats[0].mean);
  return sortedAssets.slice(0, TOP_ASSETS_LIMIT);
}
