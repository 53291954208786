import { Lifeline, ValidLifeline } from '../../../../util/productEnums';
import { defaultThresholds } from '../../../../util/productGlobals';
import { hoursToDayHourLonghand } from '../../../../util/time';
import i18next from '../../../../PlanningApp/LocalizationClient';
import { LifelineEntitlementsType } from '../../../../Hooks/useLifelineEntitlements';
import { ResilienceThresholds } from '../../../../__generated__/graphql';

type dict = { [key: string]: number };

export type Diff = { key: string; value: string };
export type DiffList = Diff[];

type LifelineMeasure =
  | 'structuralDamage'
  | 'powerDowntime'
  | 'peopleDowntime'
  | 'highwayDowntime'
  | 'bridgeDowntime'
  | 'portDowntime'
  | 'airportDowntime';

type ThresholdConfigType = {
  key: LifelineMeasure;
  llType: ValidLifeline;
  inputType: 'dayHours' | 'percent';
};

export const thresholdTableConfig: ThresholdConfigType[] = [
  {
    key: 'structuralDamage',
    llType: Lifeline.STRUCTURE,
    inputType: 'percent',
  },
  {
    key: 'powerDowntime',
    llType: Lifeline.POWER,
    inputType: 'dayHours',
  },
  {
    key: 'peopleDowntime',
    llType: Lifeline.PEOPLE,
    inputType: 'dayHours',
  },
  {
    key: 'highwayDowntime',
    llType: Lifeline.HIGHWAY,
    inputType: 'dayHours',
  },
  {
    key: 'bridgeDowntime',
    llType: Lifeline.BRIDGE,
    inputType: 'dayHours',
  },
  {
    key: 'portDowntime',
    llType: Lifeline.PORT,
    inputType: 'dayHours',
  },
  {
    key: 'airportDowntime',
    llType: Lifeline.AIRPORT,
    inputType: 'dayHours',
  },
];

export const getThresholdsDiff: (
  newThresholds: ResilienceThresholds,
  entitlements: LifelineEntitlementsType,
) => DiffList = (newThresholds, entitlements) => {
  const defaults = defaultThresholds as unknown as dict;
  const newThresh = newThresholds as unknown as dict;
  const result: DiffList = [];

  thresholdTableConfig.forEach((config) => {
    if (!entitlements[config.llType]) {
      return;
    }
    if (defaults[config.key] !== newThresh[config.key]) {
      let value = String(newThresh[config.key]);
      if (config.inputType === 'percent') {
        value = i18next.t('units:percent', { num: value });
      } else if (config.inputType === 'dayHours') {
        value = hoursToDayHourLonghand(value);
      }
      result.push({ key: config.key, value });
    }
  });
  return result;
};
