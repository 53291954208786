import * as React from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  AMP_EVENT_EXPORT_LOCATIONS,
  AMP_EVENT_EXPORT_LOCATIONS_ERROR_REPORT_TYPE,
} from '../../../plugins/amplitudeevents';
import { App } from '../../../PlanningApp/AppConfig';
import Callout from '../../CommonComponents/Callout/Callout';
import Icon from '../../CommonComponents/Icon/Icon';
import { sendAmplitudeData } from '../../../plugins/amplitude';
import Spinner from '../../CommonComponents/Spinner/Spinner';
import { useGetOriginalLocationsFileQuery } from '../../../__generated__/graphql';
import useLocationsCsv from '../../../Hooks/useLocationsCsv';

const useStyles = makeStyles((theme: Theme) => ({
  errorReportCallout: {
    marginTop: theme.spacing(4),
  },
  loadingContainer: {
    height: 60,
  },
}));

const ErrorReportDownloadLink: React.FC = () => {
  const { t } = useTranslation();
  const { errorReportCallout, loadingContainer } = useStyles();

  const { data, loading, error } = useGetOriginalLocationsFileQuery();
  const originalFile = !loading && data ? data.user?.productSettings?.originalFile : null;
  const { csvData, csvHeaders } = useLocationsCsv(originalFile, true);

  if (error) {
    App.error(
      `[ErrorReportDownloadLink] ${error.message ?? 'Error retrieving original location file'}`,
    );
  }

  const handleDownloadErrorReports = React.useCallback(
    (_: React.MouseEventHandler<HTMLAnchorElement>, done: (proceed?: boolean) => void) => {
      if (loading || !data) {
        done(false);
        return;
      }

      const userId = data.user.personalInfo.contact.email;
      const fileId = data.user.productSettings.locationFile.id;
      sendAmplitudeData(AMP_EVENT_EXPORT_LOCATIONS, {
        userId,
        fileId,
        type: AMP_EVENT_EXPORT_LOCATIONS_ERROR_REPORT_TYPE,
      });
      done();
    },
    [data, loading],
  );

  if (!data || loading) {
    return (
      <div className={loadingContainer}>
        <Spinner style={{ justifyContent: 'flex-start' }} />
      </div>
    );
  }

  return (
    <CSVLink
      asyncOnClick
      onClick={handleDownloadErrorReports}
      data={csvData} // TODO fix
      headers={csvHeaders} /// TODO fix
      enclosingCharacter=""
      filename={data.user.productSettings.locationFile.name}
    >
      <Typography
        className={errorReportCallout}
        variant="subtitle1"
        component="div"
        paragraph
        data-test-id="downloadErrorReport"
      >
        <Callout
          className="u-clickable"
          type="warning"
          variant="inline"
          iconOverride={<Icon name="document" variant="orange" />}
        >
          {t('settings:sections:locations:details:downloadErrorReport')}
        </Callout>
      </Typography>
    </CSVLink>
  );
};
ErrorReportDownloadLink.displayName = 'ErrorReportDownloadLink';
export default ErrorReportDownloadLink;
