import * as React from 'react';
import { ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { ListItemIcon, ListSubheader, Menu, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import HazardFilterButton, { HAZARD_ICON_NAMES } from './HazardFilterButton';
import {
  HistoricalEvent,
  SliceHazardType,
  useGetCurrentUserQuery,
  useGetHistoricalEventsQuery,
  useGetSelectedHazardQuery,
  useUpdateSelectedHazardMutation,
} from '../../../../__generated__/graphql';
import { AMP_EVENT_CHANGE_HAZARD } from '../../../../plugins/amplitudeevents';
import { App } from '../../../../PlanningApp/AppConfig';
import Icon from '../../../CommonComponents/Icon/Icon';
import { isEarlierTimestamp } from '../../../../util/time';
import MenuItemWithHighlight from '../../../CommonComponents/MenuItemWithHighlight/MenuItemWithHighlight';
import { sendAmplitudeData } from '../../../../plugins/amplitude';
import useApiErrSnackbar from '../../../CommonComponents/Snackbar/useApiErrSnackbar';
import useHazardEntitlements from '../../../../Hooks/useHazardEntitlements';

type HazardSelectorProps = {
  historicalEventsMenuVisible?: boolean;
};

const HazardSelector: React.FC<HazardSelectorProps> = ({ historicalEventsMenuVisible }) => {
  const { t } = useTranslation();
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [subAnchorEl, setSubAnchorEl] = React.useState<null | HTMLElement>(null);
  const [updateSelectedHazard] = useUpdateSelectedHazardMutation();
  const subMenuRef = React.useRef(null);
  const {
    data: {
      planView: {
        planFilters: { hazardType, historicalId },
      },
    },
  } = useGetSelectedHazardQuery();
  const { data: userData } = useGetCurrentUserQuery();

  const { data: historicalData, error: historicalError } = useGetHistoricalEventsQuery({
    skip: !historicalEventsMenuVisible,
  });

  const {
    data: entData,
    error: entError,
    loading: entLoading,
  } = useHazardEntitlements() as {
    data: SliceHazardType[];
    error: ApolloError;
    loading: boolean;
  };

  React.useEffect(() => {
    if (historicalError && historicalEventsMenuVisible) {
      App.error('[HazardSelector] - GetHistoricalEvents query: ', historicalError);
      enqueueApiErrSnackbar();
    }
  }, [
    enqueueApiErrSnackbar,
    entData,
    entError,
    entLoading,
    hazardType,
    historicalError,
    historicalEventsMenuVisible,
    updateSelectedHazard,
  ]);

  const selectedEvent = historicalData?.historicalEvents?.find(
    (event) => event.id === historicalId,
  );

  const [groupedHistoricalEvents, historicalTypes] = React.useMemo(() => {
    const events: { [key: string]: HistoricalEvent[] } = {};

    if (historicalData?.historicalEvents) {
      for (const event of historicalData.historicalEvents) {
        if (events[event.type]) {
          events[event.type].push(event);
        } else {
          events[event.type] = [event];
        }
      }
    }
    const historicalHazardTypes = Object.keys(events).sort();
    historicalHazardTypes.forEach((hazType) => {
      // Sort within the HazardType by event name
      events[hazType].sort((a, b) => (a.name < b.name ? -1 : 1));
    });
    return [events, historicalHazardTypes];
  }, [historicalData]);

  // if there is only one event and it gets selected, hide the entire category
  const showCategory = React.useCallback(
    (type: string) => {
      if (historicalId) {
        const categoryEvents = groupedHistoricalEvents?.[type];
        const categoryIsSelected = !!categoryEvents.find((ev) => ev.id === historicalId);
        return (
          (categoryIsSelected && categoryEvents.length > 1) ||
          (!categoryIsSelected && categoryEvents?.length > 0)
        );
      }
      return true;
    },
    [historicalId, groupedHistoricalEvents],
  );

  // if historical events exist, and if only one type with one event, not currently selected
  const showSubMenu = React.useMemo(
    () =>
      historicalTypes.length === 1 ? showCategory(historicalTypes[0]) : historicalTypes.length > 0,
    [historicalTypes, showCategory],
  );

  const openMenu = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget),
    [],
  );

  const openSubMenu = React.useCallback(
    ({ currentTarget }: React.MouseEvent<HTMLElement>) => {
      // closing sub-menu triggers parent MenuItem onclick, so check that it isn't already open
      if (!subAnchorEl) {
        setSubAnchorEl(currentTarget);
      }
    },
    [subAnchorEl],
  );

  const openSubMenuByKey = React.useCallback(({ key, target }: React.KeyboardEvent) => {
    if (key === 'ArrowRight' && target instanceof HTMLElement) {
      setSubAnchorEl(target);
    }
  }, []);

  const closeMenus = React.useCallback(() => {
    setSubAnchorEl(null);
    setAnchorEl(null);
  }, []);

  const selectHazard = React.useCallback(
    (target: EventTarget) => {
      if (target instanceof HTMLElement) {
        const { type, eventid } = target.dataset;

        if (type) {
          if (type !== hazardType) {
            updateSelectedHazard({
              variables: { hazardFilter: { type: type as SliceHazardType, id: eventid } },
            });
            if (userData) {
              const userId = userData.user.personalInfo.contact.email;
              sendAmplitudeData(AMP_EVENT_CHANGE_HAZARD, {
                userId,
                hazard: type,
              });
            }
          }
          closeMenus();
        }
      }
    },
    [closeMenus, hazardType, updateSelectedHazard, userData],
  );

  const handleClick = React.useCallback(
    ({ currentTarget }: React.MouseEvent) => {
      selectHazard(currentTarget);
    },
    [selectHazard],
  );

  const handleKeyDown = React.useCallback(
    ({ key, target }: React.KeyboardEvent) => {
      if (key === 'Enter') {
        selectHazard(target);
      }
      if (key === 'Escape') {
        closeMenus();
      }
    },
    [closeMenus, selectHazard],
  );

  const handleSubMenuKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      // prevent keyboard events from affecting parent menu
      e.stopPropagation();
      const { key, target } = e;

      if (key === 'Enter') selectHazard(target);
      if (key === 'Escape') closeMenus();
      if (key === 'ArrowLeft') setSubAnchorEl(null);
    },
    [closeMenus, selectHazard],
  );

  return (
    <>
      <HazardFilterButton
        hazardType={historicalId ? selectedEvent?.type : hazardType}
        historicalName={selectedEvent?.name}
        handleClick={openMenu}
      />
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={closeMenus}
        data-test-id="HazardSelector-menu"
        variant="menu"
      >
        {!entError &&
          !entLoading &&
          entData.map((type) => {
            const hazardIconName = HAZARD_ICON_NAMES[type];
            const hazType = type.toLowerCase();
            const hazDescription = t(`hazard:${hazType}Description`);

            return (
              <MenuItemWithHighlight
                data-type={type}
                key={type}
                dataTestId={`HazardSelector-${type}`}
                onClick={handleClick}
                onKeyDown={handleKeyDown}
                autoFocus={type === hazardType}
                primary={
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <ListItemIcon>
                      <Icon
                        name={hazardIconName}
                        variant="24"
                        additionalClasses="u-marginright--8"
                      />
                    </ListItemIcon>
                    <span style={{ marginRight: 32, width: 112 }}>{t(`hazard:${hazType}`)}</span>
                    <Typography
                      color="textSecondary"
                      variant="caption"
                      style={{ width: 230, whiteSpace: 'normal' }}
                    >
                      {hazDescription}
                    </Typography>
                  </div>
                }
              />
            );
          })}

        {historicalEventsMenuVisible && showSubMenu && (
          <MenuItemWithHighlight
            dataTestId="HazardSelector-historical"
            ref={subMenuRef}
            onClick={openSubMenu}
            onKeyDown={openSubMenuByKey}
            primary={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  height: 44,
                }}
              >
                <div>{t('hazard:historical:title')}</div>
              </div>
            }
            secondary={<ChevronRightIcon className="u-marginleft--8" />}
            divider={false}
          />
        )}
      </Menu>

      <Menu
        className="u-marginleft--4"
        anchorEl={subMenuRef.current}
        open={!!subAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={closeMenus}
        onKeyDown={handleSubMenuKeyDown}
        disableAutoFocusItem
        data-test-id="HazardSelector-submenu"
      >
        {historicalTypes.map((type) => {
          const isVisible = showCategory(type);
          const events = groupedHistoricalEvents[type];
          // sort events by year
          events.sort((eventA, eventB) => (isEarlierTimestamp(eventA.year, eventB.year) ? 1 : -1));

          const subMenuChildren = [
            <ListSubheader
              key={`HazardSelector-${type}-subheader`}
              data-test-id={`HazardSelector-${type}-subheader`}
              tabIndex={null}
            >
              {t(`hazard:historical:${type.toLowerCase()}`)}
            </ListSubheader>,
          ];

          return (
            isVisible &&
            events.reduce((acc, { id, name, year }, eventIdx) => {
              const lastEventIdx = events.length - 1;
              const isLastItemSelected = historicalId === events[lastEventIdx].id;
              const isSecondLastItem = eventIdx === lastEventIdx - 1;
              // show divider unless it is the last item of last visible category
              const showDivider =
                eventIdx < lastEventIdx && !(isSecondLastItem && isLastItemSelected);
              const eventYear = new Date(year).getFullYear();
              acc.push(
                <MenuItemWithHighlight
                  primary={`${name} (${eventYear})`}
                  key={id}
                  data-type={type}
                  data-eventid={id}
                  data-name={name}
                  dataTestId={`HazardSelector-${type}:${name}`}
                  onClick={handleClick}
                  divider={showDivider}
                />,
              );

              return acc;
            }, subMenuChildren)
          );
        })}
      </Menu>
    </>
  );
};

HazardSelector.displayName = 'HazardSelector';
export default HazardSelector;
