import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { FormControlLabel, Radio, RadioGroup, Slider, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ForecastPeriod, ForecastPeriodType } from '../../../../__generated__/graphql';
import {
  GRAY4,
  PLANNING_HORIZON_YEAR_OPTIONS,
  PLANNING_PERIOD_DEFAULT,
  RETURN_PERIOD_DEFAULT,
  RETURN_PERIOD_YEAR_OPTIONS,
  WHITE,
} from '../../../../util/productGlobals';

const useStyles = makeStyles((theme: Theme) => ({
  planningPeriodForm: {
    width: 406,
    margin: theme.spacing(4),
  },
  formControlLabelRoot: {
    marginRight: theme.spacing(5),
  },
  radioGroupRoot: {
    justifyContent: 'space-between',
  },
  radioRoot: {
    width: 20,
    height: 20,
    margin: theme.spacing(2),
    '&:hover': {
      boxShadow: '0px 0px 0px 8px rgb(242 146 32 / 10%)',
    },
    '&.Mui-focusVisible': {
      boxShadow: '0px 0px 0px 8px rgb(242 146 32 / 20%)',
    },
    '&.MuiSlider-active': {
      boxShadow: '0px 0px 0px 8px rgb(242 146 32 / 40%)',
    },
  },
  radioColorPrimary: {},
  radioChecked: {},
  sliderOuterWrapper: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 0,
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(8),
    alignItems: 'center',
  },
  biSliderOuterWrapper: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 0,
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    alignItems: 'center',
  },
  sliderInnerWrapper: {
    display: 'flex',
    width: 260,
    justifyContent: 'left',
  },
  slider: {
    width: 182,
    verticalAlign: 'middle',
    height: 2,
  },
  sliderThumb: {
    width: 22,
    height: 22,
    '&:hover': {
      boxShadow: '0px 0px 0px 8px rgba(242, 146, 32, 0.1)',
    },
    '&.Mui-focusVisible': {
      boxShadow: '0px 0px 0px 8px rgba(242, 146, 32, 0.2)',
    },
    '&.MuiSlider-active': {
      boxShadow: '0px 0px 0px 8px rgba(242, 146, 32, 0.4)',
    },
  },
  sliderRail: {
    backgroundColor: GRAY4,
    opacity: 0.8,
  },
  sliderTrack: {
    backgroundColor: theme.palette.primary.main,
    opacity: 0.5,
  },
  sliderMark: {
    backgroundColor: WHITE,
  },
  helpLink: {
    textDecoration: 'underline',
    alignSelf: 'center',
  },
}));

type PlanningPeriodPickerFormBodyProps = {
  preSubmitVal: ForecastPeriod;
  onChangePreSubmitVal: (threshold: Partial<ForecastPeriod>) => void;
};

const PlanningPeriodPickerFormBodyFC: React.FC<PlanningPeriodPickerFormBodyProps> = ({
  preSubmitVal,
  onChangePreSubmitVal,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { duration, forecastPeriodType } = preSubmitVal;
  const markYrVals = React.useMemo(
    () =>
      forecastPeriodType === ForecastPeriodType.ReturnPeriod
        ? RETURN_PERIOD_YEAR_OPTIONS
        : PLANNING_HORIZON_YEAR_OPTIONS,
    [forecastPeriodType],
  );
  const activeMarkIdx = React.useMemo(() => {
    const markIdx = markYrVals.findIndex((markVal) => markVal === preSubmitVal.duration);
    return markIdx;
  }, [markYrVals, preSubmitVal]);

  const handlePeriodDurationChange = React.useCallback(
    (e: Event, valueIdx: number) => {
      onChangePreSubmitVal({ duration: markYrVals[valueIdx] });
    },
    [onChangePreSubmitVal, markYrVals],
  );

  const handlePeriodTypeChange = React.useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      changedPeriodType: ForecastPeriodType,
      newDuration?: number,
    ) => {
      if (typeof duration !== 'undefined' || changedPeriodType !== forecastPeriodType) {
        onChangePreSubmitVal({
          duration:
            newDuration ||
            (changedPeriodType === ForecastPeriodType.PlanningHorizon
              ? PLANNING_PERIOD_DEFAULT
              : RETURN_PERIOD_DEFAULT),
          forecastPeriodType: changedPeriodType,
        });
      }
    },
    [onChangePreSubmitVal, duration, forecastPeriodType],
  );

  return (
    <div className={classes.planningPeriodForm}>
      <RadioGroup
        row
        aria-label="position"
        name="period"
        value={preSubmitVal.forecastPeriodType}
        onChange={handlePeriodTypeChange}
        classes={{ root: classes.radioGroupRoot }}
      >
        <div>
          <FormControlLabel
            value={ForecastPeriodType.PlanningHorizon}
            control={
              <Radio
                color="primary"
                classes={{
                  root: classes.radioRoot,
                  checked: classes.radioChecked,
                  colorPrimary: classes.radioColorPrimary,
                }}
              />
            }
            label={
              <Typography variant="body2">
                {t(`buttons:${ForecastPeriodType.PlanningHorizon}`)}
              </Typography>
            }
            labelPlacement="end"
            classes={{ root: classes.formControlLabelRoot }}
          />

          <FormControlLabel
            value={ForecastPeriodType.ReturnPeriod}
            control={
              <Radio
                color="primary"
                classes={{
                  root: classes.radioRoot,
                  checked: classes.radioChecked,
                  colorPrimary: classes.radioColorPrimary,
                }}
              />
            }
            label={
              <Typography variant="body2">
                {t(`buttons:${ForecastPeriodType.ReturnPeriod}`)}
              </Typography>
            }
            labelPlacement="end"
          />
        </div>
      </RadioGroup>
      <div className={classes.sliderOuterWrapper} data-test-id="slider-outer-wrapper">
        <Typography variant="body2" style={{ display: 'inline-block', width: 112 }}>
          {preSubmitVal.duration} {t('inputs:year', { count: preSubmitVal.duration })}
        </Typography>
        <div className={classes.sliderInnerWrapper} data-test-id="slider-inner-wrapper">
          <Slider
            classes={{
              root: classes.slider,
              rail: classes.sliderRail,
              track: classes.sliderTrack,
              mark: classes.sliderMark,
              thumb: classes.sliderThumb,
            }}
            value={activeMarkIdx}
            color="primary"
            marks={markYrVals.map((x, idx) => ({ value: idx }))}
            min={-0.7} // A little bit of track to the left of the first slider value
            max={markYrVals.length - 0.3} // a little bit of track to right of last slider value
            step={null}
            onChange={handlePeriodDurationChange}
            aria-label={`Years for ${forecastPeriodType}`}
            aria-valuetext={`${activeMarkIdx} years selected`}
            getAriaValueText={(value) => String(markYrVals[value])}
          />
        </div>
      </div>
    </div>
  );
};
const PlanningPeriodPickerFormBody = React.memo(PlanningPeriodPickerFormBodyFC);
export default PlanningPeriodPickerFormBody;
