import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

type SymbolicAssetProps = {
  isVulnerable: boolean;
  stableImg: string;
  vulnerableImg: string;
  title: string;
};

const useStyles = makeStyles({
  lifelineImg: {
    '-webkit-user-drag': 'none',
    userSelect: 'none',
    position: 'absolute',
    opacity: 1,
    transition: 'opacity 1s ease',
  },
});

const SymbolicAsset: React.FC<SymbolicAssetProps> = ({
  isVulnerable,
  stableImg,
  vulnerableImg,
  title,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className="u-position--rel">
      <img
        src={vulnerableImg}
        alt={t('symbolicView:lifelineVulnerableAltText', {
          name: title,
        })}
        srcSet={`${vulnerableImg} 1x`}
        className={`${classes.lifelineImg} ${!isVulnerable ? 'u-no-opacity' : ''}`}
        data-test-id={`Lifeline-${title}-Vulnerable-Img`}
      />
      <img
        src={stableImg}
        alt={t('symbolicView:lifelineAltText', {
          name: title,
        })}
        srcSet={`${stableImg} 1x`}
        className={`${classes.lifelineImg} ${isVulnerable ? 'u-no-opacity' : ''}`}
        data-test-id={`Lifeline-${title}-Img`}
      />
    </div>
  );
};

export default SymbolicAsset;
