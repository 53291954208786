import { ApolloCache, NormalizedCacheObject, Resolver } from '@apollo/client';
import { cloneDeep } from 'lodash/fp';

import {
  GetEditLocationSourceDocument,
  GetEditLocationSourceQuery,
  GetLocationBuildingMatchDocument,
  GetLocationBuildingMatchQuery,
  MutationSetEditLocationSourceArgs,
  MutationUpdateLocationBuildingMatchArgs,
} from '../../../__generated__/graphql';

export const updateLocationBuildingMatch: Resolver = (
  _: unknown,
  { match }: MutationUpdateLocationBuildingMatchArgs,
  { cache }: { cache: ApolloCache<NormalizedCacheObject> },
) => {
  const storeData = cache.readQuery<GetLocationBuildingMatchQuery>({
    query: GetLocationBuildingMatchDocument,
  });
  const newData = cloneDeep(storeData);
  newData.locationBuildingMatch = match;
  cache.writeQuery({ data: newData, query: GetLocationBuildingMatchDocument });
  return newData.locationBuildingMatch;
};

export const setEditLocationSource: Resolver = (
  _: unknown,
  { url }: MutationSetEditLocationSourceArgs,
  { cache }: { cache: ApolloCache<NormalizedCacheObject> },
) => {
  const storeData = cache.readQuery<GetEditLocationSourceQuery>({
    query: GetEditLocationSourceDocument,
  });
  const newData = cloneDeep(storeData);
  newData.editLocationSource.url = url;
  cache.writeQuery({ data: newData, query: GetEditLocationSourceDocument });
  return newData.editLocationSource;
};

export const locationMatchMutations = {
  setEditLocationSource,
  updateLocationBuildingMatch,
};
