import * as React from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import { SelectChangeEvent } from '@mui/material/Select';

import { DARKBLUE, selectMenuStyle } from '../../../../../util/productGlobals';
import {
  GetOrganizationUsersDocument,
  InviteUserMutationVariables,
  useGetCurrentUserQuery,
  useInviteUserMutation,
  UserRole,
} from '../../../../../__generated__/graphql';
import { App } from '../../../../../PlanningApp/AppConfig';
import StyledSnackbarContent from '../../../../CommonComponents/Snackbar/StyledSnackbarContent';
import { validateEmail } from '../../../../../util/productUtils';

const useStyles = makeStyles(() => {
  return {
    accessTypeLabel: {
      opacity: 0.5,
    },
    accessTypeControl: {
      flex: 3,
    },
    button: {
      height: 36,
      marginBottom: 8,
      paddingLeft: 10,
      paddingRight: 10,
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      '& > div': {
        marginBottom: 8,
        marginRight: 8,
        minWidth: 100,
      },
    },
    inputField: {
      '&:-webkit-autofill': {
        '-webkit-box-shadow': `0 0 0 100px ${DARKBLUE} inset`,
      },
    },
    startIcon: {
      marginRight: 4,
    },
    textFieldEmail: {
      flex: 4,
      '& > label': {
        opacity: 0.5,
      },
    },
    textFieldName: {
      flex: 2,
      '& > label': {
        opacity: 0.5,
      },
    },
  };
});

type UserAddProps = {
  isConsultantOrg?: boolean;
};

const UserAdd: React.FC<UserAddProps> = ({ isConsultantOrg }) => {
  const {
    accessTypeControl,
    accessTypeLabel,
    button,
    container,
    inputField,
    startIcon,
    textFieldEmail,
    textFieldName,
  } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [accessType, setAccessType] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [firstName, setFirstName] = React.useState<string>('');
  const [lastName, setLastName] = React.useState<string>('');
  const { data: userData, error } = useGetCurrentUserQuery();

  if (error) {
    App.error('[UserAdd] - useGetCurrentUserQuery Error: ', error);
  }

  const showError = React.useCallback(
    (cause: string) => {
      enqueueSnackbar(
        <StyledSnackbarContent
          title={t('settings:sections:userManagement:addUser:errors:title')}
          description={t(`settings:sections:userManagement:addUser:errors:${cause}Description`)}
        />,
        {
          variant: 'error',
        },
      );
    },
    [enqueueSnackbar, t],
  );

  const [inviteUserMutation] = useInviteUserMutation({
    refetchQueries: [{ query: GetOrganizationUsersDocument }],
    onCompleted: () => {
      setEmail('');
      setFirstName('');
      setLastName('');
      setAccessType('');
    },
    onError: (err) => {
      showError(err.message?.includes('postUsersConflict') ? 'duplicateEmail' : 'other');
    },
  });

  const handleChangeAccessType = React.useCallback(
    (e: SelectChangeEvent) => setAccessType(e.target.value as string),
    [setAccessType],
  );
  const handleChangeEmail = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setEmail(e.target.value),
    [],
  );

  const handleChangeFirstName = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setFirstName(e.target.value),
    [],
  );

  const handleChangeLastName = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setLastName(e.target.value),
    [],
  );

  const onBlurTrimFieldValue = React.useCallback(
    (
      e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      fieldName: 'lastName' | 'firstName' | 'email',
    ) => {
      const trimmedVal = e.target.value.trim();
      switch (fieldName) {
        case 'firstName':
          setFirstName(trimmedVal);
          break;
        case 'lastName':
          setLastName(trimmedVal);
          break;
        case 'email':
          setEmail(trimmedVal);
          break;
        default:
      }
    },
    [],
  );

  const isDisabled = React.useMemo(() => {
    if (!firstName.trim() || !lastName.trim() || !email.trim() || !accessType) return true;
    return !validateEmail(email);
  }, [accessType, email, firstName, lastName]);

  const handleAddUser = React.useCallback(() => {
    const variables: InviteUserMutationVariables = {
      invite: {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim(),
        role: accessType as UserRole,
        preferences: {
          language: userData?.user?.preferences?.language,
        },
      },
    };

    if (accessType === UserRole.Viewer) {
      let redirectUrl = window.location.origin;

      // localhost URL will return an error
      if (redirectUrl.endsWith(':4455')) {
        redirectUrl = 'https://domino.dev2.onec.cloud';
      }

      variables.invite.viewerInput = { redirectUrl };
    }

    inviteUserMutation({ variables });
  }, [accessType, email, inviteUserMutation, firstName, lastName, userData]);

  const firstNameField = (
    <TextField
      label={t('settings:sections:userManagement:addUser:firstName')}
      variant="outlined"
      size="small"
      value={firstName}
      onChange={handleChangeFirstName}
      onBlur={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
        onBlurTrimFieldValue(e, 'firstName')
      }
      className={textFieldName}
      inputProps={{
        autoComplete: 'off',
        className: inputField,
        'data-test-id': 'AddUserFirstNameInput',
      }}
    />
  );

  const lastNameField = (
    <TextField
      label={t('settings:sections:userManagement:addUser:lastName')}
      variant="outlined"
      size="small"
      value={lastName}
      onChange={handleChangeLastName}
      onBlur={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
        onBlurTrimFieldValue(e, 'lastName')
      }
      className={textFieldName}
      inputProps={{
        autoComplete: 'off',
        className: inputField,
        'data-test-id': 'AddUserLastNameInput',
      }}
    />
  );

  return (
    <div data-test-id="UserAddControls">
      <div className={container}>
        {t('settings@personalNames:fieldOrder') === 'lastFirst' ? (
          <>
            {lastNameField}
            {firstNameField}
          </>
        ) : (
          <>
            {firstNameField}
            {lastNameField}
          </>
        )}
        <TextField
          label={t('settings:sections:userManagement:allUsers:emailAddress')}
          variant="outlined"
          size="small"
          value={email}
          onChange={handleChangeEmail}
          onBlur={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
            onBlurTrimFieldValue(e, 'email')
          }
          className={textFieldEmail}
          inputProps={{
            autoComplete: 'off',
            className: inputField,
            'data-test-id': 'AddUserEmailInput',
          }}
        />
        <FormControl size="small" className={accessTypeControl}>
          <InputLabel
            id="add-user-access-type-label"
            data-test-id="AddUserAccessTypeLabel"
            className={accessTypeLabel}
          >
            {t('settings:sections:userManagement:addUser:accessType')}
          </InputLabel>
          <Select
            size="small"
            labelId="add-user-access-type-label"
            id="add-user-access-type-select"
            value={accessType}
            label={t('settings:sections:userManagement:addUser:accessType')}
            onChange={handleChangeAccessType}
            IconComponent={ExpandMoreIcon}
            data-test-id="AddUserAccessTypeSelect"
            MenuProps={{
              PaperProps: {
                sx: selectMenuStyle,
              },
            }}
          >
            <MenuItem value={UserRole.Admin} data-test-id="AddUserAccessTypeSelect-admin">
              {t('settings:sections:userManagement:allUsers:permission:administrator')}
            </MenuItem>
            <MenuItem value={UserRole.Contributor} data-test-id="AddUserAccessTypeSelect-user">
              {t('settings:sections:userManagement:allUsers:permission:user')}
            </MenuItem>
            {isConsultantOrg && (
              <MenuItem value={UserRole.Viewer} data-test-id="AddUserAccessTypeSelect-viewer">
                {t('settings:sections:userManagement:allUsers:permission:viewer')}
              </MenuItem>
            )}
          </Select>
        </FormControl>
        <Button
          disabled={isDisabled}
          variant="contained"
          color="primary"
          size="small"
          className={`u-clickable ${button}`}
          classes={{
            startIcon,
          }}
          startIcon={<AddIcon fontSize="small" />}
          data-test-id="UserAddButton"
          onClick={handleAddUser}
        >
          {t('settings:sections:userManagement:addUser:action')}
        </Button>
      </div>
    </div>
  );
};

UserAdd.displayName = 'UserAdd';
export default UserAdd;
