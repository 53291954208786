import {
  CountryCode,
  LanguagePreferences,
  MeasurementPreferences,
  UserDetailsFragment,
} from '../__generated__/graphql';

import { LifelineEntitlementsType } from '../Hooks/useLifelineEntitlements';

export const NOGALES_MAXEXTENTS = [
  -111.0598049456998, 31.296076526790984, -110.8201950542959, 31.44821589280805,
];

export const KUMAMOTO_MAXEXTENTS = [
  130.56082670428137, 32.660295113915126, 130.82896289065116, 32.97987007244798,
];

export const PA_ALLEGHENY_MAXEXTENTS = [
  -80.4423447769999, 40.0996357500001, -79.5358047789999, 40.8624625090001,
];

export const PA_LYCOMING_MAXEXTENTS = [
  -77.733829092, 40.9595453530001, -76.3769682189999, 41.7456508210001,
];

export const PA_MAXEXTENTS = [
  Math.min(PA_ALLEGHENY_MAXEXTENTS[0], PA_LYCOMING_MAXEXTENTS[0]),
  Math.min(PA_ALLEGHENY_MAXEXTENTS[1], PA_LYCOMING_MAXEXTENTS[1]),
  Math.max(PA_ALLEGHENY_MAXEXTENTS[2], PA_LYCOMING_MAXEXTENTS[2]),
  Math.max(PA_ALLEGHENY_MAXEXTENTS[3], PA_LYCOMING_MAXEXTENTS[3]),
];

export const prefsMetricEnUS: UserDetailsFragment['preferences'] = {
  language: LanguagePreferences.En,
  country: 'United States',
  countryCode: CountryCode.Us,
  units: MeasurementPreferences.MetricSystem,
  // Pacific Standard Time (PST) or Pacific Daylight Time (PDT)
  timezone: 'America/Los_Angeles',
};

export const prefsImperialEnUS: UserDetailsFragment['preferences'] = {
  language: LanguagePreferences.En,
  country: 'United States',
  countryCode: CountryCode.Us,
  units: MeasurementPreferences.ImperialSystem,
  // Mountain Standard Time (MST) or Mountain Daylight Time (MDT)
  timezone: 'America/Phoenix', // Nogalies is in Mountain Time
};

export const prefsImperialEnBD: UserDetailsFragment['preferences'] = {
  language: LanguagePreferences.En,
  country: 'Bangladesh',
  countryCode: CountryCode.Bd,
  units: MeasurementPreferences.ImperialSystem,
  // Mountain Standard Time (MST) or Mountain Daylight Time (MDT)
  timezone: 'America/Phoenix', // Nogalies is in Mountain Time
};

export const prefsMetricJaJP: UserDetailsFragment['preferences'] = {
  language: LanguagePreferences.Ja,
  country: 'Japan',
  countryCode: CountryCode.Jp,
  units: MeasurementPreferences.MetricSystem,
  timezone: 'Asia/Tokyo', // All of Japan is in Japan Standard Time, no daylight savings
};

/**
 * Used internally, made to avoid confusion on Storybook
 */
export const prefsMetricEnJP: UserDetailsFragment['preferences'] = {
  language: LanguagePreferences.En,
  country: 'Japan',
  countryCode: CountryCode.Jp,
  units: MeasurementPreferences.MetricSystem,
  timezone: 'Asia/Tokyo', // All of Japan is in Japan Standard Time, no daylight savings
};

// In Germany, numbers have "," for decimal symbol and
// "." for thousands separators
export const prefsMetricEnDE: UserDetailsFragment['preferences'] = {
  language: LanguagePreferences.En,
  country: 'Germany',
  countryCode: CountryCode.De,
  units: MeasurementPreferences.MetricSystem,
  // Central European Time (CET) or Centeral European Summer Time (CEST)
  timezone: 'Europe/Berlin',
};

// India uses different numbering:
// 4,333,222,111.00  =>  4,33,32,22,111.00
export const prefsMetricEnIN: UserDetailsFragment['preferences'] = {
  language: LanguagePreferences.En,
  country: 'India',
  countryCode: CountryCode.In,
  units: MeasurementPreferences.MetricSystem,
  timezone: 'Asia/Kolkata', // All of India is in India Standard Time (IST), no daylight savings
};

export const lifelineEntitlementsUS: LifelineEntitlementsType = {
  structure: true,
  power: true,
  bridge: true,
  highway: true,
  port: true,
  airport: true,
  people: true,
};

export const lifelineEntitlementsJP: LifelineEntitlementsType = {
  structure: true,
  power: true,
  bridge: false,
  highway: false,
  port: false,
  airport: false,
  people: false,
};
