import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Marker } from 'react-mapbox-gl';
import { Divider, Theme } from '@mui/material';

import {
  GRAY2,
  GRAY_DARK,
  GRAY_MUTED,
  PURPLE,
  PURPLE_DEEP,
  PURPLE_MUTED,
  WHITE,
} from '../../../../util/productGlobals';
import Icon from '../../../CommonComponents/Icon/Icon';

type LifelinePillIconProps = {
  name: string;
  isVulnerable?: boolean;
  showBorder?: boolean;
  handleClick?: () => void;
};

type LifelinePillMarkerProps = {
  coordinates: number[];
  icons?: LifelinePillIconProps[];
};

const useStyles = makeStyles((theme: Theme) => ({
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  root: {
    flexDirection: 'column',
  },
  pill: {
    padding: theme.spacing(1, 2),
    backgroundColor: WHITE,
    borderRadius: '76px',
  },
  stem: {
    background: `linear-gradient(to bottom, ${WHITE} 75%, rgba(255, 255, 255, 0.5))`,
    width: '3px',
    height: '24px',
  },
  icon: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  iconPurple: {
    border: `1px solid ${PURPLE}`,
    backgroundColor: PURPLE_DEEP,
  },
  iconPurpleMuted: {
    backgroundColor: PURPLE_MUTED,
  },
  iconGray: {
    border: `1px solid ${GRAY2}`,
    backgroundColor: GRAY_DARK,
  },
  iconGrayMuted: {
    background: GRAY_MUTED,
  },
  divider: {
    height: theme.spacing(4),
    margin: theme.spacing(0, 2),
    backgroundColor: GRAY2,
  },
}));

const LifelinePillMarker: React.FC<LifelinePillMarkerProps> = ({ coordinates, icons }) => {
  const {
    centered,
    root,
    pill,
    stem,
    icon,
    iconPurple,
    iconPurpleMuted,
    iconGray,
    iconGrayMuted,
    divider,
  } = useStyles();
  const handleKeyDown = React.useCallback(
    (selectLifeline: () => void) => (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') selectLifeline();
    },
    [],
  );

  return (
    <Marker coordinates={coordinates} anchor="bottom" offset={6}>
      <div className={`${centered} ${root}`}>
        <div className={`${centered} ${pill}`}>
          {icons.map(({ name, isVulnerable, showBorder, handleClick }, idx) => {
            let iconClasses = `${centered} ${icon}`;

            if (isVulnerable) {
              iconClasses += ` ${showBorder ? iconPurple : iconPurpleMuted}`;
            } else {
              iconClasses += ` ${showBorder ? iconGray : iconGrayMuted}`;
            }

            return (
              <React.Fragment key={name}>
                <div
                  className={iconClasses}
                  onClick={handleClick}
                  onKeyDown={handleKeyDown(handleClick)}
                  role="button"
                  tabIndex={0}
                >
                  <Icon name={name} />
                </div>
                {idx < icons.length - 1 && <Divider className={divider} orientation="vertical" />}
              </React.Fragment>
            );
          })}
        </div>
        <div className={stem} />
      </div>
    </Marker>
  );
};

export default LifelinePillMarker;
