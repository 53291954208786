import * as React from 'react';
import { Button, Container, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { App } from '../../../PlanningApp/AppConfig';
import { BLACK } from '../../../util/productGlobals';
import onecLogo2x from '../../../../assets/images/Primary_Logo_Orange-2x.png';
import pic404 from '../../../../assets/images/404.png';

const useStyles = makeStyles(() => ({
  container: {
    width: '100vw',
    height: '100vh',
    background: BLACK,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    zIndex: 3,
  },
  main: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0px 32px',
  },
  mainLeft: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 32,
    maxWidth: 580,
  },
  mainRight: {
    marginLeft: 70,
    '@media (max-width: 1024px)': {
      display: 'none',
    },
  },
  onecLogoImg: {
    position: 'absolute',
    top: 32,
    left: 36,
    width: 148,
  },
  returnHomeBtn: {
    color: BLACK,
    fontSize: '1rem',
    justifyContent: 'space-between',
    width: 265,
    height: 50,
    marginTop: 64,
    padding: '15px 26px',
  },
}));

export type ErrorPageProps = {
  /**
   * 401 | 403 | 404 | 500 | 502
   */
  errorCode: number;
  /**
   * An optional message to display under the error code header
   */
  internalErrMsg?: string;
  /**
   * Used for testing (data-test-id)
   */
  parentID?: string;
};

/**
 * Used to indicate what kind of error has occurred and what actions, if any, can be taken.
 */
const ErrorPage: React.FC<ErrorPageProps> = ({ errorCode, internalErrMsg, parentID }) => {
  const dataTestIdName = parentID ? `${parentID}-errorpage` : 'errorpage';
  const { t } = useTranslation();
  const { container, main, mainLeft, mainRight, onecLogoImg, returnHomeBtn } = useStyles();

  App.debug(`ErrorPage - errorCode: ${errorCode}, msg: ${internalErrMsg}`);

  return (
    <div data-test-id="error-page" className={container}>
      <img
        src={onecLogo2x}
        className={onecLogoImg}
        alt={t('productLogo')}
        srcSet={`${onecLogo2x} 2x`}
        data-test-id="OnecLogo"
      />
      <Container className={main}>
        <div className={mainLeft}>
          <Typography
            variant="h1"
            color="textPrimary"
            className="u-preline"
            data-test-id={`${dataTestIdName}-emsg`}
          >
            {t('err:errorGeneral')}
          </Typography>
          {App.config.features.enabledebug && internalErrMsg && (
            <div
              style={{ border: '1px solid grey', margin: 24, padding: 16 }}
              className="u-textalign-center"
              data-test-id={`${dataTestIdName}-msg`}
            >
              <Typography variant="body2" color="textSecondary" gutterBottom>
                One Concern Internal Use Only:
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {`Error ${errorCode}: ${internalErrMsg}`}
              </Typography>
            </div>
          )}
          <Button
            variant="contained"
            color="primary"
            endIcon={<ArrowForwardIcon />}
            component={RouterLink}
            to="/"
            className={returnHomeBtn}
            data-test-id="returnHomeLink"
          >
            {t('err:returnHome')}
          </Button>
        </div>
        {errorCode === 404 && (
          <div className={mainRight}>
            <img src={pic404} alt="404" data-test-id="404-graphic" />
          </div>
        )}
      </Container>
    </div>
  );
};

ErrorPage.displayName = 'ErrorPage';
export default ErrorPage;
