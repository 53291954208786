import * as React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { Skeleton, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  ResilienceThresholds,
  useGetSliceIndexesQuery,
  useGetThresholdsQuery,
} from '../../../__generated__/graphql';
import { App } from '../../../PlanningApp/AppConfig';
import FirstToFailPlayButton from './PlayButton';
import { GLASS_BG_MED } from '../../../util/productGlobals';
import Timeline from './Timeline';
import useApiErrSnackbar from '../../CommonComponents/Snackbar/useApiErrSnackbar';
import useGetLocOrBobj from '../../../Hooks/useGetLocOrBobj';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    background: GLASS_BG_MED,
    borderRadius: 6,
    padding: theme.spacing(4, 8),
    height: 134,
    width: 'auto',
    boxSizing: 'border-box',
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  skeletonCard: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  timelineSkeleton: {
    marginLeft: 40,
    borderRadius: 100,
  },
}));

const FirstToFailControlsSkeleton: React.FC = () => {
  const { card, skeletonCard, timelineSkeleton } = useStyles();
  return (
    <div className={`${card} ${skeletonCard}`} data-test-id="FirstFailTimeline">
      <Skeleton variant="circular" width={60} height={60} />
      <Skeleton
        variant="rectangular"
        width={891}
        height={10}
        classes={{ root: timelineSkeleton }}
      />
    </div>
  );
};
FirstToFailControlsSkeleton.displayName = 'FirstToFailControlsSkeleton';

const FirstToFailControlsContainerFC: React.FC = () => {
  const { id: selectedId } = useParams<{ id: string }>();
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();
  const { card } = useStyles();
  const {
    data: userData,
    error: thresholdsError,
    loading: thresholdsLoading,
  } = useGetThresholdsQuery();
  const serverThresholds: ResilienceThresholds = userData?.user?.productSettings?.thresholds;

  const {
    data: slicesData,
    loading: slicesLoading,
    error: slicesError,
  } = useGetSliceIndexesQuery();
  const slices = slicesData?.sliceIndexes || [];

  const {
    locOrBobj,
    loading: locOrBobjLoading,
    error: locOrBobjErr,
  } = useGetLocOrBobj(
    selectedId,
    true, // have the useGetLocOrBobj hook display error messages in snackbars
  );

  const loading = slicesLoading || thresholdsLoading || locOrBobjLoading;
  const error = slicesError || thresholdsError || locOrBobjErr;
  React.useEffect(() => {
    if (slicesError) {
      App.error('[FirstToFailControlsContainer] - GetSlicesIndexesQuery error: ', slicesError);
      enqueueApiErrSnackbar();
    }
    if (thresholdsError) {
      App.error('[FirstToFailControlsContainer] - GetThresholdsQuery error: ', thresholdsError);
      enqueueApiErrSnackbar();
    }
    if (locOrBobjErr) {
      App.error('[FirstToFailControlsContainer] - useGetLocOrBobj error: ', locOrBobjErr);
      // no need to enqueue a snackbar because useGetLocOrBobj hook already does it
    }
  }, [enqueueApiErrSnackbar, slicesError, thresholdsError, locOrBobjErr]);

  if (loading || error) {
    return <FirstToFailControlsSkeleton />;
  }
  return (
    <div className={`o-flexsb-container ${card}`} data-test-id="FirstFailTimeline">
      <FirstToFailPlayButton />
      <Timeline slices={slices} serverThresholds={serverThresholds} builtObject={locOrBobj} />
    </div>
  );
};
const FirstToFailControlsContainer = React.memo(FirstToFailControlsContainerFC);
FirstToFailControlsContainer.displayName = 'FirstToFailControlsContainer';

const FirstToFailControlsContainerWrapperFC: React.FC = () => {
  return (
    <Routes>
      <Route path="detail/:id" element={<FirstToFailControlsContainer />} />
    </Routes>
  );
};

const FirstToFailControlsContainerWrapper = React.memo(FirstToFailControlsContainerWrapperFC);
FirstToFailControlsContainerWrapper.displayName = 'FirstToFailControlsContainerWrapper';
export default FirstToFailControlsContainerWrapper;
