import * as React from 'react';
import ReactMapboxGl, { MapContext, ZoomControl } from 'react-mapbox-gl';
import { Map as MapboxMap } from 'mapbox-gl';
import { Events as MapEvents } from 'react-mapbox-gl/lib/map-events';
import { OnecWindow } from 'onec-types';

import {
  changeMapLanguage,
  getFeaturesAtClickedPoint,
  getFitBoundsFromMaxExtents,
} from '../../../util/mapUtils';
import { DEFAULT_ZOOM, MAX_ZOOM, MIN_ZOOM } from '../../../util/productGlobals';
import {
  LanguagePreferences,
  MeasurementPreferences,
  NavigatorViewType,
  useUpdateNavigatorInfoMutation,
} from '../../../__generated__/graphql';

import { App } from '../../../PlanningApp/AppConfig';
import { AppLogout } from '../../../PlanningApp/auth/admin';
import CustomScaleControl from './CustomScaleControl';
import { Lifeline } from '../../../util/productEnums';
import MapBoundsButton from './MapBoundsButton';

import MapDrawButton from './MapDrawButton';
import useHasFinanceEntitlement from '../../../Hooks/useHasFinanceEntitlement';

function removeMapFromWindow() {
  App.debug('Unmounting BaseMap and removing mapboxMap from window.reactMap');
  (window as OnecWindow).reactMap = undefined;
}

function addMapToWindow(map: MapboxMap) {
  (window as OnecWindow).reactMap = map;
}

function onStyleData(map: MapboxMap) {
  // NOTE: While the onStyleLoad event is officially recommended as the hook for getting a "map"
  //  object, this event is only triggered _after_ the styles are downloaded from mapbox's server
  // and several other mapbox are triggered first. other components that rely on the map being
  // at window.reactMap fail.  So, we moved addMapToWindow to onStyleData event handler instead,
  // which happens much earlier and onStyleLoad.
  App.debug('[BaseMap] - onStyleData called: adding maboxMap to window.reactMap');
  addMapToWindow(map);
}

function onStyleLoad(map: MapboxMap, langCode: string) {
  // Find all standard mapbox layers that display text labels, and request the label be displayed in
  // our preferred language (if available)
  changeMapLanguage(map, langCode);
  enableMobileExperience(map);
  // addDrawControl(map);
}
/**
 * Function to host all things required for mapbox to enable a mobile friendly experience
 * @param map Self Explanatory
 */
function enableMobileExperience(map: MapboxMap) {
  if (map && !map.touchZoomRotate.isEnabled()) {
    map.touchZoomRotate.enable();
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onError = (map: MapboxMap, evt: any) => {
  App.debug('[BaseMap - onError]', evt);
  if (evt?.error) {
    const { status } = evt.error;
    if (status === 401) {
      // Mapbox throws 401 errors when the access_token is bad.  Is the OneC account with Mapbox
      // current and working?
      App.error('[BaseMap] - ReactMapbox 401 error, so logout.');
      AppLogout();
    }
  }
};

type BaseMapProps = MapEvents & {
  maxExtents: number[];
  language: LanguagePreferences;
  units: MeasurementPreferences; // for the scale control (km or miles)
  children?: React.ReactNode;
};

const BaseMapFC: React.FC<BaseMapProps> = ({ maxExtents, units, language, children, ...rest }) => {
  const mapboxToken = App.config.tokens.mapboxapi;
  const style = App.config.endpoints.mapstyle;

  const [updateNavigatorInfoMutation] = useUpdateNavigatorInfoMutation();
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;

  React.useEffect(removeMapFromWindow, []); // when BaseMap unmonts

  const ReactMap = React.useMemo(
    () =>
      ReactMapboxGl({
        accessToken: mapboxToken,
        pitchWithRotate: true, // must be true if we want elevation/pitch change
        dragRotate: true, // must be true if we want elevation/pitch change
        touchZoomRotate: false,
        logoPosition: 'bottom-right',
        minZoom: MIN_ZOOM,
        maxZoom: MAX_ZOOM,
        attributionControl: true,
      }),
    [mapboxToken],
  );

  const fitBounds = React.useMemo(() => getFitBoundsFromMaxExtents(maxExtents), [maxExtents]);

  // Making initZoom a memoized object instead of passing as a object literal to the <ReactMap >
  // is what keeps the map from resetting to the initZoom when the entire ReactMap re-renders
  // (but doesn't re-initialize). react-mapbox-gl checks to see if it should re-initialize the map's
  // zoom level by checking the referenc equality between the previous value of zoom and the new one
  const initZoom: [number] = React.useMemo(() => [DEFAULT_ZOOM], []);

  const handleMapClick = React.useCallback(
    (mapboxMap: MapboxMap, evt: any) => {
      const filteredFeatures = getFeaturesAtClickedPoint(mapboxMap, evt);
      if (filteredFeatures.length === 0 && !isFinanceEntitled) {
        /// go back to the full arc map with everything highlighted when click no feature
        updateNavigatorInfoMutation({
          variables: { currentView: NavigatorViewType.MapView, currentLifeline: Lifeline.EMPTY },
        });
      }
    },
    [isFinanceEntitled, updateNavigatorInfoMutation],
  );

  return (
    <ReactMap
      style={style}
      containerStyle={{
        width: '100%',
        height: '100%',
      }}
      fitBounds={fitBounds}
      animationOptions={{ animate: false }}
      onStyleLoad={(map: MapboxMap) => onStyleLoad(map, language)}
      onStyleData={onStyleData}
      onError={onError}
      zoom={initZoom}
      onClick={handleMapClick}
      {...rest}
    >
      <>
        {children}
        <ZoomControl
          zoomDiff={1}
          position="bottom-left"
          className="zoom-control"
          style={{
            bottom: 250,
            left: 8,
            boxShadow: 'none',
            border: 'none',
            zIndex: 'auto',
            backgroundColor: '#131D22',
            backdropFilter: 'blur(13px)',
            borderRadius: 100,
            padding: '0px 8px',
          }}
        />
        <MapContext.Consumer>
          {(mapboxMap) => (
            <>
              <CustomScaleControl units={units} map={mapboxMap} zoom={mapboxMap.getZoom()} />
              <MapBoundsButton mapboxMap={mapboxMap} fitBounds={fitBounds} />
              <MapDrawButton mapboxMap={mapboxMap} />
            </>
          )}
        </MapContext.Consumer>
      </>
    </ReactMap>
  );
};
const BaseMap = React.memo(BaseMapFC);
BaseMap.displayName = 'BaseMap';
export default BaseMap;
