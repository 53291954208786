import { LocationFeatureProps, MapFeature } from 'onec-types';
import { GeoJSONSourceRaw } from 'mapbox-gl';

import { BLUEGRAY, PURPLE, PURPLE_DARK, WHITE } from '../../../../util/productGlobals';
import { GeoJsonFeature } from '../../../../__generated__/graphql';
import { LocationType } from '../../locationViewHelpers';

export const makeGeoJson: (locations: LocationType[], promoteId?: string) => GeoJSONSourceRaw = (
  locations,
  promoteId,
) => {
  // Make a geojson from the list of locations, where each location is a Geojson Point Feature.
  const features: GeoJsonFeature[] = (locations ?? []).map(
    (location: LocationFeatureProps, locationIdx: number) => {
      const { id, coordinates, group, type } = location;
      const position: GeoJSON.Position = coordinates;

      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: position,
        },
        id: String(locationIdx), // Note that in a geojson, the feature id must be an int that is turned into a string
        properties: {
          id,
          coordinates: position,
          group,
          type,
        },
      } as GeoJsonFeature;
    },
  );

  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features,
    },
    promoteId,
  } as GeoJSONSourceRaw;
};

export const mapFeatureToLocationProps: (
  feature: MapFeature<LocationFeatureProps>,
) => LocationFeatureProps = (feature) => {
  // Even though LocationProps type specifies that 'coordinates' has type GeoJson.Position, the
  // returned mouse event returns it as a JSON-ified string.  So we need to convert this
  // coordinate string into the GeoJson.Position array we expected
  const coordinatesString = feature.properties.coordinates as unknown as string;
  const coordinatePosition: GeoJSON.Position = JSON.parse(coordinatesString);

  return {
    coordinates: coordinatePosition,
    id: feature.properties.id,
  } as LocationFeatureProps;
};

export const clusterLayersConfig = {
  vulnerable: {
    halo: {
      showHalo: true,
      haloColor: PURPLE_DARK,
      haloRadius: 45,
      haloRadiusSelected: 22,
      haloOpacity: 0.2,
    },
    clusterMarker: {
      backgroundColor: PURPLE_DARK,
      borderColor: WHITE,
      borderWidth: 1,
      borderWidthHovered: 4,
      textColor: WHITE,
      radius: 18,
    },
    locationMarker: {
      backgroundColor: PURPLE,
      backgroundColorSelected: WHITE,
      borderColor: WHITE,
      borderColorSelected: PURPLE,
      borderWidth: 1,
      borderWidthHovered: 4,
      borderWidthSelected: 1,
      radius: 7,
    },
  },
  notVulnerable: {
    halo: {
      showHalo: false,
      haloColor: 'rgba(0,0,0,0)',
      haloRadius: 0,
      haloRadiusSelected: 0,
      haloOpacity: 0,
    },
    clusterMarker: {
      backgroundColor: BLUEGRAY,
      borderColor: WHITE,
      borderWidth: 1,
      borderWidthHovered: 4,
      textColor: WHITE,
      radius: 18,
    },
    locationMarker: {
      backgroundColor: BLUEGRAY,
      backgroundColorSelected: WHITE,
      borderColor: WHITE,
      borderColorSelected: WHITE,
      borderWidth: 1,
      borderWidthHovered: 3,
      borderWidthSelected: 7,
      radius: 7,
    },
  },
};
