import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { GeocodeFeature } from '@mapbox/mapbox-sdk/services/geocoding';
import Highlighter from 'react-highlight-words';
import { Link as RouterLink } from 'react-router-dom';
import { SearchOptions } from 'onec-types';

import { Link as MuiLink, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { GRAY2, WHITE } from '../../../util/productGlobals';
import { fixBadAddressStrJP } from '../../../util/addresses/addressUtilsJP';
import { fixBadAddressStrUS } from '../../../util/addresses/addressUtilsUS';
import { getOneLineAddress } from '../../../util/addresses/addressUtils';
import ListItemWithHighlight from '../../CommonComponents/ListItemWithHighlight/ListItemWithHighlight';
import { SearchLocationWithFilterQueryResult } from '../../../__generated__/graphql';

const useStyles = makeStyles((theme: Theme) => ({
  noResultsItem: {
    margin: theme.spacing(4),
    marginTop: theme.spacing(2),
    cursor: 'default',
  },
}));

export const LocationsSearchListItem: React.FC<{
  option: SearchOptions[0];
  inputValue: string;
  isViewer: boolean;
}> = ({ option, inputValue, isViewer }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const RouterLinkToLocations = React.useMemo(
    () =>
      // eslint-disable-next-line react/display-name, react/no-unstable-nested-components
      React.forwardRef((linkProps, ref: React.Ref<HTMLAnchorElement>) => (
        <RouterLink ref={ref} to="/locations" {...linkProps} data-test-id="link-to-locations" />
      )),
    [],
  );

  if (option.groupType === 'LocationObject' || option.groupType === 'RecentViewObject') {
    const locationObject = option.data as SearchLocationWithFilterQueryResult['data']['search'][0];

    if (locationObject.id === 'INVALID_SEARCH') {
      if (isViewer) {
        return null;
      }
      return (
        <div className={classes.noResultsItem} data-test-id="locationsSearch-no-match">
          <Typography variant="body1" color="textSecondary">
            {t('search:noResults')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <Trans i18nKey="search:locationNoResult">
              <MuiLink underline="always" color="textPrimary" component={RouterLinkToLocations}>
                {t('search:locationNoResult:link')}
              </MuiLink>
            </Trans>
          </Typography>
        </div>
      );
    }
    // Simple tokenization for now.
    const tokenizeInput = inputValue?.match(/\S+/g) ?? [];
    const displayableOneLineAddress = getOneLineAddress(locationObject.address);

    return (
      <ListItemWithHighlight
        name={`locationsSearch-location-${locationObject.id}`}
        primary={
          <Highlighter
            highlightStyle={{ backgroundColor: 'unset', fontWeight: 'bold', color: WHITE }}
            searchWords={tokenizeInput}
            autoEscape
            textToHighlight={locationObject?.name ?? ''}
          />
        }
        secondary={
          <Highlighter
            highlightStyle={{ backgroundColor: 'unset', fontWeight: 'bold', color: GRAY2 }}
            searchWords={tokenizeInput}
            autoEscape
            textToHighlight={displayableOneLineAddress}
          />
        }
      />
    );
  }
  const mapboxObject = option.data as GeocodeFeature;
  const mapboxOptionId = mapboxObject?.id || mapboxObject?.properties?.id;

  if (mapboxOptionId === 'INVALID_SEARCH') {
    return (
      <div data-test-id="locationsSearch-no-mapbox-match" className={classes.noResultsItem}>
        <Typography color="textSecondary">{t('search:noMapboxAddressFound')}</Typography>
      </div>
    );
  }
  // Simple tokenization for now.
  const tokenizeInput = inputValue.match(/\S+/g) || [];

  // Japan Search API returns the matching address in mapboxObject.properties.place_name.
  // The Mapbox Geocoding API (used for US address) returns it in mapboxObject.place_ame
  const jpMatch = mapboxObject?.properties?.place_name;
  const usMatch = mapboxObject?.place_name;

  const { oneLine: mapboxMatchName } = !!jpMatch
    ? fixBadAddressStrJP(jpMatch)
    : fixBadAddressStrUS(usMatch);

  return (
    <ListItemWithHighlight
      name={`locationsSearch-mapbox-${mapboxOptionId}`}
      primary={
        <Highlighter
          highlightStyle={{ backgroundColor: 'unset', fontWeight: 'bold', color: WHITE }}
          searchWords={tokenizeInput}
          autoEscape
          textToHighlight={mapboxMatchName}
        />
      }
    />
  );
};
LocationsSearchListItem.displayName = 'LocationsSearchListItem';

type LocationsSearchListItemContainerProps = {
  containerProps: React.HTMLAttributes<HTMLLIElement>;
  option: SearchOptions[0];
  inputValue: string;
  isViewer: boolean;
};
const LocationsSearchListItemContainer: React.FC<LocationsSearchListItemContainerProps> = ({
  containerProps,
  option,
  inputValue,
  isViewer,
}) => {
  return (
    <li {...containerProps} className="u-no-padding">
      <LocationsSearchListItem option={option} inputValue={inputValue} isViewer={isViewer} />
    </li>
  );
};

LocationsSearchListItemContainer.displayName = 'LocationsSearchListItemContainer';
export default LocationsSearchListItemContainer;
