import * as React from 'react';
import { debounce } from 'lodash';

import { CardContent, IconButton, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DARKBLUE, GLASS_BG, OVERFLOW_Y } from '../../../../util/productGlobals';
import { App } from '../../../../PlanningApp/AppConfig';
import Icon from '../../../CommonComponents/Icon/Icon';
import ShowYourWorkSidebar from './ShowYourWorkSidebar';
import Spinner from '../../../CommonComponents/Spinner/Spinner';
import SYWAirport from './SYWAirport';
import SYWBridge from './SYWBridge';
import SYWHighway from './SYWHighway';
import SYWOverview from './SYWOverview';
import SYWPeople from './SYWPeople';
import SYWPort from './SYWPort';
import SYWPower from './SYWPower';
import SYWStructure from './SYWStructure';
import useLifelineEntitlements from '../../../../Hooks/useLifelineEntitlements';
import useLocationSliceStats from '../../../../Hooks/useLocationSliceStats';
import SYWFilters from './SYWFilters';
import usePreviousNonNullish from '../../../../Hooks/usePreviousNonNullish';
import { useGetCurrentUserQuery } from '../../../../__generated__/graphql';
import useHasFinanceEntitlement from '../../../../Hooks/useHasFinanceEntitlement';

const CATEGORIES = [
  'overview',
  'structure',
  'power',
  'people',
  'highway',
  'bridge',
  'airport',
  'port',
];

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
    margin: 0,
    padding: 0,
    height: '100%',
  },
  articleContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%',
    '& article': {
      maxWidth: '900px',
    },
    backgroundColor: DARKBLUE,
  },
  scrollContainer: {
    flexGrow: 1,
    marginLeft: theme.spacing(18.5),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(32),
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: GLASS_BG,
    },
  },
  closeContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(4),
  },
  closeButton: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(248, 247, 242, 0.1)',
    },
    '&:active': {
      backgroundColor: 'rgba(248, 247, 242, 0.2)',
    },
  },
}));

type SYWContentProps = {
  id: string;
  handleClose: () => void;
};

const ShowYourWorkContent: React.FC<SYWContentProps> = ({ id, handleClose }) => {
  const { container, articleContainer, scrollContainer, closeContainer, closeButton } = useStyles();
  const [activeCategory, setActiveCategory] = React.useState('overview');
  const entitledLifelines = useLifelineEntitlements();
  const scrollContainerRef = React.useRef<HTMLDivElement>();
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;
  const { data: userData } = useGetCurrentUserQuery();
  const { data: newData, loading } = useLocationSliceStats(id);
  const prevData = usePreviousNonNullish(newData);

  const data = newData ?? prevData;

  const handleClick = React.useCallback((e: React.MouseEvent) => {
    if (e.currentTarget instanceof HTMLElement) {
      const { category } = e.currentTarget.dataset;
      setActiveCategory(category);
      document.getElementById(`${category}-section`)?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  // set category as active if scrolled up top 1/3rd of the screen
  React.useEffect(() => {
    if (data && scrollContainerRef.current) {
      const contentScroller = scrollContainerRef.current;

      const handleScroll = debounce(() => {
        const scrollPosition =
          contentScroller.scrollTop + contentScroller.offsetTop + contentScroller.offsetHeight / 3;
        const visibleCategory = CATEGORIES.find((category) => {
          const section = document.getElementById(`${category}-section`);
          if (!section) {
            return false;
          }
          const { offsetTop, offsetHeight } = section;
          return scrollPosition > offsetTop && scrollPosition < offsetTop + offsetHeight;
        });

        if (visibleCategory && visibleCategory !== activeCategory) {
          setActiveCategory(visibleCategory);
        }
      }, 200);

      contentScroller.addEventListener('scroll', handleScroll);

      return () => {
        contentScroller.removeEventListener('scroll', handleScroll);
      };
    }

    return undefined;
  }, [data, activeCategory]);

  if (!data && loading) {
    return (
      <CardContent className={container} data-test-id="ShowYourWorkContent-container">
        <div className={articleContainer} data-test-id="ShowYourWorkContent-articleContainer">
          <div className={closeContainer} data-test-id="ShowYourWorkContent-closeButtonContainer">
            <IconButton className={closeButton} onClick={handleClose}>
              <Icon name="close" variant="white" />
            </IconButton>
          </div>
          <Spinner />
        </div>
      </CardContent>
    );
  }

  if (!data) {
    App.error(`[ShowYourWorkContent] No data received for location or built object ID ${id}`);

    return (
      <CardContent className={container} data-test-id="ShowYourWorkContent-container">
        <div className={articleContainer} data-test-id="ShowYourWorkContent-articleContainer">
          <div className={closeContainer} data-test-id="ShowYourWorkContent-closeButtonContainer">
            <IconButton className={closeButton} onClick={handleClose}>
              <Icon name="close" variant="white" />
            </IconButton>
            No data available for this location.
          </div>
        </div>
      </CardContent>
    );
  }

  const { name, address, group, type, stats, multiBuilding } = data;

  type IndexableLifelineEntitlementsType = {
    [key: string]: boolean;
  };

  const entitledCategories = CATEGORIES.filter(
    (category) =>
      ((entitledLifelines as IndexableLifelineEntitlementsType)?.[category] ||
        category === 'overview') &&
      (category !== 'people' || !isFinanceEntitled),
  );

  return (
    <CardContent className={container} data-test-id="ShowYourWorkContent-container">
      <ShowYourWorkSidebar
        name={name}
        address={address}
        group={group}
        type={type}
        stats={stats}
        activeCategory={activeCategory}
        handleClick={handleClick}
        categories={entitledCategories}
      />

      <div className={articleContainer} data-test-id="ShowYourWorkContent-articleContainer">
        <div className={closeContainer} data-test-id="ShowYourWorkContent-closeButtonContainer">
          <IconButton className={closeButton} onClick={handleClose}>
            <Icon name="close" variant="white" />
          </IconButton>
        </div>

        <div
          ref={scrollContainerRef}
          className={`${scrollContainer} ${OVERFLOW_Y}`}
          data-test-id="ShowYourWorkContent-scrollContainer"
        >
          {isFinanceEntitled && userData && (
            <SYWFilters userId={userData?.user?.personalInfo?.contact?.email} />
          )}
          <article data-test-id="ShowYourWorkContent-article">
            <SYWOverview multiBuilding={multiBuilding} />
            {entitledLifelines?.structure && <SYWStructure stats={stats.structure} gutterTop />}
            {entitledLifelines?.power && <SYWPower stats={stats.power} gutterTop />}
            {!isFinanceEntitled && entitledLifelines?.people && (
              <SYWPeople stats={stats.people} gutterTop />
            )}
            {entitledLifelines?.highway && <SYWHighway stats={stats.highway} gutterTop />}
            {entitledLifelines?.bridge && <SYWBridge stats={stats.bridge} gutterTop />}
            {entitledLifelines?.airport && <SYWAirport stats={stats.airport} gutterTop />}
            {entitledLifelines?.port && <SYWPort stats={stats.port} gutterTop />}
          </article>
        </div>
      </div>
    </CardContent>
  );
};

export default ShowYourWorkContent;
