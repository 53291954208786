import { MaterialitySidebarCategory, SettingsSidebarCategory } from 'onec-types';
import { SxProps } from '@mui/material';

import {
  CountryCode,
  ForecastPeriod,
  ForecastPeriodType,
  LanguagePreferences,
  LifelineDowntimeHoursInput,
  MeasurementPreferences,
  ResilienceThresholds,
  SliceHazardType,
  UserDetailsFragment,
} from '../__generated__/graphql';
import { Lifeline, SimulationType, ValidLifeline } from './productEnums';
import { cssSupported } from '../PlanningApp/helpers';

export const CONTINENTAL_USA_MAX_EXTENTS = [-125.5, 23.0, -66.0, 50.0];
export const JAPAN_MAX_EXTENTS = [122.97, 23.5, 145.81, 45.5];

export const MAX_ZOOM = 19;
export const MIN_ZOOM = 2;
export const DEFAULT_ZOOM = 5;

export const BORDER_RADIUS = 6;

export const RED = '#FF6262';
export const RED_DARK_TRANSPARENT = 'rgba(255, 98, 98, 0.1)';
export const BLUE = '#6F62FF';
export const ORANGE = '#F29220';
export const ORANGE_DARK_TRANSPARENT = 'rgba(242, 146, 32, 0.1)';
export const ORANGELIGHT = '#F48500';
export const ORANGEDARK = '#BD6700';
export const PURPLE = '#B662FF';
export const PURPLE_DARK = '#B200FD';
export const PURPLE_DARK_TRANSPARENT = 'rgba(178, 0, 253, 0.2)';
export const PURPLE_DEEP = '#4D1D6F';
export const PURPLE_MUTED = '#382351';
export const GREEN = '#5DAF4F';
export const GREEN_DARK_TRANSPARENT = 'rgba(93, 175, 79, 0.1)';
export const BLUEGRAY = '#8591A9';
export const DARKBLUE = '#283238';
export const DARKBLUE2 = '#2B363C';

export const LIST_HIGHLIGHT = 'rgba(248, 247, 242, 0.1)'; // WHITE with opacity 0.1
export const WHITE = '#F8F7F2';
export const BLACK = '#111212';
export const GRAY5 = '#5C5C5C';
export const GRAY4 = '#495057';
export const GRAY3 = '#8D9493';
export const GRAY2 = '#ACB5BD';
export const GRAY1 = '#E8EAEB';
export const GRAY_DARK = '#4A5156';
export const GRAY_MUTED = '#2E353D';

export const GLASS_BG = 'rgba(2, 12, 17, 0.7)';
export const GLASS_BG_MED = 'rgba(2, 12, 17, 0.6)';
export const GLASS_BG_LIGHT = 'rgba(2, 12, 17, 0.4)';
export const GLASS_BG_DARK = '#0D171D';

export const QUIET_BG = 'rgba(248, 247, 242, 0.1)';
export const QUIET_LIGHT = 'rgba(10, 16, 18, 0.4)';
export const QUIET_DARK = 'rgba(10, 16, 18, 0.2)';
export const BLURRY = 'blur(13px)';

export const VULNERABLE_COLOR = PURPLE;
export const NOT_VULNERABLE_COLOR = BLUEGRAY;

export const TOP_CONCERNS_LIGHT_BG_GRADIENT =
  'radial-gradient(38.33% 43.42% at 50% 48.73%, #111212 1.38%, #2b363c 100%)';
export const TOP_CONCERNS_DARK_BG_GRADIENT =
  'radial-gradient(38.33% 43.42% at 50% 48.73%, #2B363C 1.38%, #111212 100%)';

/* If our GetCurrentUser query fails, we use OneConcern HQ userPreferences */
export const DEFAULT_USER_PREFS: UserDetailsFragment['preferences'] = {
  language: LanguagePreferences.En,
  country: 'United States',
  countryCode: CountryCode.Us,
  units: MeasurementPreferences.ImperialSystem,
  timezone: 'America/Los_Angeles',
};

export const HOME_PATH = '/analysis';
export const FINANCE_HOME_PATH = '/finance/analysis';

// Expand on this later
export const DETAIL_VIEW_ZOOM = 9;
export const MAPBOX_ADDRESS_LIMIT = 3;
export const MAPBOX_POPUP_OFFSET = 39;
// Set recent viewed locations limit
export const RECENT_VIEW_LOCATION_LIMIT = 3;

export const OVERFLOW_Y = cssSupported('overflow', 'overlay')
  ? 'u-overflowy-overlay'
  : 'u-overflowy-auto';

export const VULNERABLE_SOURCE_ID = 'vulnerableLocations';
export const NOT_VULNERABLE_SOURCE_ID = 'notVulnerableLocations';
export const ALL_LOCATIONS_SOURCE_ID = 'allLocationsInvisible';
export const BUILT_OBJECT_SOURCE_ID = 'selectedBuiltObject';
export const ACTIVE_LOCATION_LAYER_ID = 'activeLocationLayer';

export const NON_VULNERABLE_LIFELINE_ARC_SOURCE = 'lifelineArcs';
export const NON_VULNERABLE_LIFELINE_SOURCE = 'lifelines';
export const TOP_NON_VULNERABLE_LIFELINE_SOURCE = 'topLifelines';
export const TOP_VULNERABLE_LIFELINE_SOURCE = 'topVulnerableLifelines';
export const VULNERABLE_LIFELINE_ARC_SOURCE = 'vulnerableLifelineArcs';
export const VULNERABLE_LIFELINE_SOURCE = 'vulnerableLifelines';
export const NEARBY_BUILDINGS_SOURCE_ID = 'NearbyBuildingsSource';

export const BI_LIFELINE_ARC_SOURCE = 'lifelineArcs';
export const BI_LIFELINE_SOURCE = 'lifelines';

export const VALID_LIFELINES: ValidLifeline[] = [
  Lifeline.STRUCTURE,
  Lifeline.POWER,
  Lifeline.PEOPLE,
  Lifeline.HIGHWAY,
  Lifeline.BRIDGE,
  Lifeline.PORT,
  Lifeline.AIRPORT,
];

export const defaultThresholds: ResilienceThresholds = {
  structuralDamage: 5,
  powerDowntime: 2,
  peopleDowntime: 2,
  highwayDowntime: 2,
  bridgeDowntime: 2,
  portDowntime: 2,
  airportDowntime: 2,
};

// Slice Index that corresponds to default of "Flood, no climate change, 5 Yr Planning Horizon"
export const DEFAULT_SLICE_IDX = 107;
export const HAZARD_FILTER_ORDER = [
  SliceHazardType.Flood,
  SliceHazardType.Wind,
  SliceHazardType.Earthquake,
];
export const FINANCE_HAZARD_ORDER = [
  SliceHazardType.Flood,
  SliceHazardType.Wind,
  SliceHazardType.Earthquake,
  SliceHazardType.Integrated,
];

export const CLIMATE_ON_RETURN_PERIOD = 3;
export const CLIMATE_ON_PLANNING_HORIZON = 4;
export const CLIMATE_OFF = 1;
export const VALID_RETURN_PERIODS = [50, 100, 250, 500, 1000];
export const RETURN_PERIOD_YEAR_OPTIONS = VALID_RETURN_PERIODS;
export const RETURN_PERIOD_DEFAULT = 250;
export const PLANNING_HORIZON_YEAR_OPTIONS = [1, 5, 10, 20, 30];
export const PLANNING_PERIOD_DEFAULT = 5;
export const DEFAULT_FORECAST_PERIOD: ForecastPeriod = {
  forecastPeriodType: ForecastPeriodType.PlanningHorizon,
  duration: PLANNING_PERIOD_DEFAULT,
  financeDefaultPH: 1,
  financeRP1: 100,
  financeRP2: 250,
};

export const SIDE_PANEL_WIDTH = 400;
export const DETAIL_VIEW_POSITION_TIMER_INTERVAL = 200;

export const MATCH_ADDRESS_DIST = 100;
export const MATCH_ADDRESS_DIST_LIMIT = 500;

export const NAVIGATOR_USER_ENGAGE_THRESHOLD = 3;
export const MAPBOX_SEARCH_CHARACTER_LIMIT = 3;
export const MAX_CHIP_LABEL_LENGTH = 16;
export const BUTTON_DISABLE_OPACITY = 0.3;
export const FILE_DROPZONE_DISABLE_OPACITY = 0.3;

export const MAPBOX_LIFELINES_ARC_LIMIT = 3;
export const HISTORICAL_EVENTS_MENU_VISIBLE = false;
export const UPLOAD_FILE_LIMIT = 4 * 1024 * 1024;
export const UPLOAD_LOCATIONS_LIMIT = 5000;

export const TOP_ASSETS_LIMIT = 3;
export const BRIDGE_RESTRICTION_HAZARD_TYPES = [SliceHazardType.Flood, SliceHazardType.Wind];
export const SETTINGS_SIDEBAR_CATEGORIES: SettingsSidebarCategory[] = [
  {
    name: 'profile',
    icon: 'User',
  },
  { name: 'userManagement', icon: 'Users' },
  { name: 'locations', icon: 'MapPin' },
];

export const MATERIALITY_SIDEBAR_CATEGORIES: MaterialitySidebarCategory[] = [
  {
    name: 'dashboard',
    icon: 'Assess',
  },
  { name: 'settings', icon: 'Calculate' },
];

export const LOCALSTORAGE_KEY_PREFIX = `oneconcern:${document?.location?.hostname ?? 'TESTENV'}:`;
export const RECOVERY_CHART_X_AXIS_MIN_VALUE = 60;

export const AMPLITUDE_INSTANCE = 'planning';

export const selectMenuStyle: SxProps = {
  '& .MuiMenuItem-root.Mui-selected, & .MuiMenuItem-root.Mui-focusVisible': {
    backgroundColor: 'transparent',
  },
  '& .MuiMenuItem-root:hover, & .MuiMenuItem-root.Mui-selected:hover': {
    backgroundColor: 'rgba(248, 247, 242, 0.3)',
  },
};

export const LOCATIONS_MATCH_TABLE_PAGINATION_SIZE = 100;

export const DRAG_DROP_SNAP_RADIUS_KM = 0.05;
export const NEARBY_BUILDINGS_RADIUS_METERS = 500;
export const MAPBOX_SEARCH_API_URL = 'https://api.mapbox.com/search/v1';

export const MATERIALITY_THRESHOLD = 1; // 1%

export const SIDE_PANEL_VULNERABLE_LOCATIONS_LIST_VIEW = 'vulnerableLocations';
export const SIDE_PANEL_DOWNTIME_MATERIALITY_VIEW = 'materiality';

export const SWIPE_ANIMATE_STYLE: React.CSSProperties = {
  transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s',
};

export const ONECONCERN_SUPPORT_EMAIL = 'support@oneconcern.com';

export const DEMO_ACCOUNT_SUFFIX = 'proddemo@oneconcern.com';

// colorbrewer2.org/#type=qualitative&scheme=Set3&n=12
export const REGION_COLOR_SPECTRUM = [
  '#8dd3c7',
  '#ffffb3',
  '#bebada',
  '#fb8072',
  '#80b1d3',
  '#fdb462',
  '#b3de69',
  '#fccde5',
  '#d9d9d9',
  '#bc80bd',
  '#ccebc5',
  '#ffed6f',
];

export const ALL_SIMULATION_TYPES: SimulationType[] = [
  SimulationType.PANDEMIC,
  SimulationType.CYBERATTACK,
  SimulationType.EXTENDEDPOWEROUTAGE,
  // SimulationType.HURRICANEIAN,
  // SimulationType.HURRICANEIDA,
];

type SimulationSupportLifelines = {
  power?: boolean;
  highway?: boolean;
  bridge?: boolean;
  port?: boolean;
  airport?: boolean;
  repairTime?: boolean;
  building?: boolean;
};

export const SIMULATION_AVAILABLE_LIFELINES: Record<SimulationType, SimulationSupportLifelines> = {
  Pandemic: {
    building: true,
  },
  'Cyber attack': {
    power: true,
    highway: true,
    bridge: true,
    port: true,
    airport: true,
    repairTime: true,
  },
  'Extended Power Outage': {
    power: true,
  },
  'Hurricane Ian': {},
  'Hurricane Ida': {},
};

export const SIMULATION_DEFAULT_OCCUPANCY_TYPES: Record<SimulationType, string[]> = {
  Pandemic: [],
  'Cyber attack': [],
  'Extended Power Outage': [],
  'Hurricane Ian': [],
  'Hurricane Ida': [],
};

export const SIMULATION_DEFAULT_DOWNTIMES: Record<SimulationType, LifelineDowntimeHoursInput> = {
  Pandemic: {
    building: 4320,
  },
  'Cyber attack': {},
  'Extended Power Outage': {
    power: 168,
  },
  'Hurricane Ian': {},
  'Hurricane Ida': {},
};
