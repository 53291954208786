import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Card, Grow, Modal, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  DEMO_ACCOUNT_SUFFIX,
  FINANCE_HOME_PATH,
  GLASS_BG,
  HOME_PATH,
} from '../../../../util/productGlobals';
import ShowYourWorkContent from './ShowYourWorkContent';
import useHasFinanceEntitlement from '../../../../Hooks/useHasFinanceEntitlement';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: `calc(100dvw - ${theme.spacing(4)})`,
    height: `calc(100dvh - ${theme.spacing(4)})`,
    backgroundColor: GLASS_BG,
    outline: 0,
    margin: 0,
    padding: 0,
  },
}));

const ShowYourWorkModal: React.FC<{ userId: string }> = ({ userId }) => {
  const { card } = useStyles();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(true);
  const { data: financeModules } = useHasFinanceEntitlement();

  const goToDetailView = React.useCallback(() => {
    setIsOpen(false);
    setTimeout(
      () => navigate(`${financeModules?.enablebi ? FINANCE_HOME_PATH : HOME_PATH}/detail/${id}`),
      300,
    );
  }, [id, navigate, financeModules?.enablebi]);

  const isDemoUser = userId?.endsWith(DEMO_ACCOUNT_SUFFIX);

  // display logo on top of modal
  React.useEffect(() => {
    const logo = document.getElementById('onec-logo');
    const { zIndex } = logo.style;
    if (!isDemoUser) {
      logo.style.zIndex = '1301';
      logo.style.left = '8px';
    }

    return () => {
      logo.style.zIndex = zIndex;
      logo.style.left = '0';
    };
  }, [isDemoUser]);

  return (
    <Modal
      className="o-flex-center-container"
      onClose={goToDetailView}
      open={isOpen}
      closeAfterTransition
    >
      <Grow in={isOpen}>
        <Card className={card} data-test-id="ShowYourWorkModal-card">
          <ShowYourWorkContent id={id} handleClose={goToDetailView} />
        </Card>
      </Grow>
    </Modal>
  );
};

export default ShowYourWorkModal;
