import * as React from 'react';
import { useOutletContext } from 'react-router-dom';

import { CardContent, IconButton, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DARKBLUE, GLASS_BG, OVERFLOW_Y } from '../../../../util/productGlobals';
import Icon from '../../../CommonComponents/Icon/Icon';
import FinanceDriversSettings from './FinanceDriversSettings';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
    margin: 0,
    padding: 0,
    height: '100%',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%',
    backgroundColor: DARKBLUE,
  },
  scrollContainer: {
    flexGrow: 1,
    marginLeft: theme.spacing(18.5),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(16),
    paddingRight: theme.spacing(16),
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: GLASS_BG,
    },
  },
  closeContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(4),
  },
  closeButton: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(248, 247, 242, 0.1)',
    },
    '&:active': {
      backgroundColor: 'rgba(248, 247, 242, 0.2)',
    },
  },
  backIcon: {
    minWidth: 36,
    padding: 0,
    marginRight: 16,
  },
  backArrowIcon: {
    margin: '0px !important',
  },
}));

const FinanceSettingsContent: React.FC = () => {
  const { body, container, scrollContainer, closeContainer, closeButton } = useStyles();
  const { handleClose } = useOutletContext() as { handleClose: () => void };

  return (
    <CardContent className={container} data-test-id="FinanceSettings-container">
      <div className={body} data-test-id="FinanceSettings-body">
        <div className={closeContainer} data-test-id="FinanceSettings-closeButtonContainer">
          <IconButton
            className={closeButton}
            onClick={handleClose}
            data-test-id="FinanceSettings-closeButton"
          >
            <Icon name="close" variant="white" />
          </IconButton>
        </div>
        <div
          className={`${scrollContainer} ${OVERFLOW_Y} o-flexcol-container`}
          data-test-id="FinanceSettings-content"
          id="FinanceSettingsPanel"
        >
          <FinanceDriversSettings />
        </div>
      </div>
    </CardContent>
  );
};

export default FinanceSettingsContent;
