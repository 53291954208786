import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ResStatsArray } from 'onec-types';

import { Typography } from '@mui/material';

import {
  ForecastPeriodType,
  PlanFilters,
  ResilienceThresholds,
  Slice,
} from '../../../../../__generated__/graphql';
import useLifelineEntitlements, {
  LifelineEntitlementsType,
} from '../../../../../Hooks/useLifelineEntitlements';
import { App } from '../../../../../PlanningApp/AppConfig';
import { capitalize } from '../../../../../util/string';
import { getActiveSliceIdx } from '../../../locationViewHelpers';
import i18next from '../../../../../PlanningApp/LocalizationClient';
import { Lifeline } from '../../../../../util/productEnums';
import { LifelineFirstToFailTableSkeleton } from '../Skeleton/LocationDetailCardSkeleton';
import LifelinesDataTable from '../../Common/LifelinesDataTable';
import { VALID_RETURN_PERIODS } from '../../../../../util/productGlobals';

export type FirstToFailDatum = { lifeline: string; vulnerableAtReturnPeriod: string | number };

// Calculate Stress test Data
// return period is the earliest return period of the slices which are vulnerable
// for the lifeline at the location formatted in years
// If there is no vulnerable slices, return period is "Not at risk"
// Stress test data is sorted by return period, ascending with “Not at risk”
// and "n/a" values at bottom.
export const calculateFirstToFailData = (
  resilienceStats: ResStatsArray,
  planFilters: PlanFilters,
  slices: Slice[],
  thresholds: ResilienceThresholds,
  entitlements: LifelineEntitlementsType,
): FirstToFailDatum[] => {
  const nA = i18next.t('na');

  if (resilienceStats?.length <= 1) {
    App.debug('[calculateFirstToFailData] - need resilienceStats for multiple slices');
    return [];
  }

  const newData: FirstToFailDatum[] = [];
  if (entitlements) {
    Object.entries(entitlements).forEach(([lifeline, entitled]) => {
      if (entitled) {
        newData.push(createFirstToFailData(lifeline, 0));
      }
    });
  }

  const returnPeriodFilters = {
    ...planFilters,
    forecastPeriod: {
      ...planFilters.forecastPeriod,
      forecastPeriodType: ForecastPeriodType.ReturnPeriod,
      duration: VALID_RETURN_PERIODS[0],
    },
  };
  const matchSliceId = getActiveSliceIdx(slices, returnPeriodFilters);
  const selectedSlice = slices.find((sliceDef) => sliceDef.slice === matchSliceId);
  const filteredSlices = slices.filter(
    (sliceDef) =>
      VALID_RETURN_PERIODS.includes(sliceDef.returnPeriod) &&
      !sliceDef.planningHorizon &&
      selectedSlice &&
      sliceDef.climate === selectedSlice.climate &&
      sliceDef.hazardType === selectedSlice.hazardType &&
      sliceDef.historicalEventID === selectedSlice.historicalEventID,
  );

  newData.forEach((item) => {
    const vulnerableSlices = filteredSlices.filter((sliceDef) => {
      return resilienceStats.some((resilienceStat) => {
        if (resilienceStat.slice !== sliceDef.slice) return false;
        const lifelineStat = (resilienceStat as Record<string, any>)[
          item.lifeline === 'structure' ? 'structuralDamage' : `nearby${capitalize(item.lifeline)}`
        ];
        const downtime = lifelineStat ? parseInt(lifelineStat.mean, 10) : 0;
        const threshold =
          (thresholds as Record<string, any>)[
            item.lifeline === 'structure' ? 'structuralDamage' : `${item.lifeline}Downtime`
          ] || 0;
        return downtime > threshold;
      });
    });
    if (!vulnerableSlices || vulnerableSlices.length === 0) {
      item.vulnerableAtReturnPeriod = i18next.t('detailView:lifelines:notVulnerable').toString();
    } else {
      const validReturnPeriods: Array<number> = vulnerableSlices
        .map((sliceDef) => sliceDef.returnPeriod)
        .filter((rp) => !!rp);
      if (validReturnPeriods.length > 0)
        item.vulnerableAtReturnPeriod = Math.min(...validReturnPeriods);
      else item.vulnerableAtReturnPeriod = nA;
    }
  });
  newData.sort((a, b) => {
    if (
      typeof a.vulnerableAtReturnPeriod === 'string' &&
      typeof b.vulnerableAtReturnPeriod === 'string'
    ) {
      if (a.vulnerableAtReturnPeriod === b.vulnerableAtReturnPeriod) return 0;
      if (b.vulnerableAtReturnPeriod === nA) return 1;
      if (a.vulnerableAtReturnPeriod === nA) return -1;
    }
    if (typeof b.vulnerableAtReturnPeriod === 'string') return -1;
    if (typeof a.vulnerableAtReturnPeriod === 'string') return 1;
    return a.vulnerableAtReturnPeriod - b.vulnerableAtReturnPeriod;
  });
  return newData;
};

export function createFirstToFailData(
  lifeline: string,
  vulnerableAtReturnPeriod: number | string,
): FirstToFailDatum {
  return { lifeline, vulnerableAtReturnPeriod };
}

const FirstToFailTab: React.FC<{
  resilienceStatsArr: ResStatsArray;
  planFilters: PlanFilters;
  slices: Slice[];
  thresholds: ResilienceThresholds;
  showSkeleton: boolean;
  showLifelineDetailView: (lifeline: Lifeline) => void;
}> = ({
  resilienceStatsArr,
  planFilters,
  slices,
  thresholds,
  showSkeleton,
  showLifelineDetailView,
}) => {
  const { t } = useTranslation();
  const entitlements = useLifelineEntitlements();

  const [firstToFailData, setFirstToFailData] = React.useState<FirstToFailDatum[]>([]);

  React.useEffect(() => {
    if (resilienceStatsArr) {
      const newFirstToFailData = calculateFirstToFailData(
        resilienceStatsArr,
        planFilters,
        slices,
        thresholds,
        entitlements,
      );
      setFirstToFailData(newFirstToFailData);
    }
  }, [resilienceStatsArr, planFilters, slices, thresholds, entitlements]);

  const firstToFailReady =
    !showSkeleton &&
    resilienceStatsArr?.length > 1 &&
    firstToFailData?.length > 0 &&
    slices?.length > 0 &&
    Object.keys(thresholds).length > 0;

  return (
    <div data-test-id="FirstToFailTab">
      <Typography variant="body2" className="u-paddingbottom--16" color="textPrimary">
        {t('detailView:lifelines:firstToFail:description')}
      </Typography>
      {firstToFailReady ? (
        <LifelinesDataTable
          headerFields={[t('lifeline:title'), t('detailView:lifelines:vulnerableAtReturnPeriod')]}
          data={firstToFailData}
          hazardType={planFilters.hazardType}
          showLifelineDetailView={showLifelineDetailView}
          dataTestId="firstToFail"
        />
      ) : (
        <LifelineFirstToFailTableSkeleton />
      )}
    </div>
  );
};
FirstToFailTab.displayName = 'FirstToFailTab';
export default FirstToFailTab;
