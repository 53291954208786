import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  FormControl,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';

import { DARKBLUE, LIST_HIGHLIGHT, selectMenuStyle } from '../../../../../util/productGlobals';
import {
  GetCurrentUserQuery,
  GetOrganizationUsersDocument,
  useDisableUserMutation,
  useEnableUserMutation,
  UserRole,
  UserStatus,
  useUpdateOtherUserMutation,
} from '../../../../../__generated__/graphql';
import { AMP_EVENT_DISABLE_USER } from '../../../../../plugins/amplitudeevents';
import Icon from '../../../../CommonComponents/Icon/Icon';
import { sendAmplitudeData } from '../../../../../plugins/amplitude';
import UserRoleTag from './UserRoleTag';

export interface UserData {
  name: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  role: UserRole;
  status: UserStatus;
}

type UserTableProps = {
  data: UserData[];
  user: GetCurrentUserQuery['user'];
  isConsultantOrg?: boolean;
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    bodyCell: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      borderBottom: `0.5px solid ${LIST_HIGHLIGHT} !important`,
      padding: '8px 8px 8px 0px !important',
      lineHeight: theme.typography.pxToRem(20),
      width: 160,
      '@media (max-width: 1424px)': {
        width: 140,
      },
      '&:first-child': {
        width: 200,
      },
      '& > p': {
        overflowWrap: 'anywhere',
      },
    },
    button: {
      height: 36,
      marginBottom: 8,
      paddingLeft: 10,
      paddingRight: 10,
    },
    buttons: {
      marginTop: 16,
      justifyContent: 'flex-end',
    },
    editCell: {
      padding: '8px 8px 8px 0px !important',
      verticalAlign: 'top',
    },
    editNameWrapper: {
      display: 'flex',
      flexDirection: (props: { fieldOrder: string }) =>
        props.fieldOrder === 'lastFirst' ? 'row-reverse' : 'row',
      alignItems: 'center',
      gap: '8px',
    },
    headerCell: {
      borderBottom: 'none !important',
      padding: '0 0 8px 0 !important',
    },
    iconBtn: {
      padding: '0 !important',
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
    inputField: {
      '&:-webkit-autofill': {
        '-webkit-box-shadow': `0 0 0 100px ${DARKBLUE} inset`,
      },
    },
    rowBlur: {
      opacity: 0.3,
    },
  };
});

const headerFields = [
  'settings:sections:userManagement:allUsers:employeeName',
  'settings:sections:userManagement:allUsers:emailAddress',
  'settings:sections:userManagement:allUsers:permission:title',
  'settings:sections:userManagement:allUsers:status:title',
];

const UserTable: React.FC<UserTableProps> = ({ data, user, isConsultantOrg }) => {
  const { t } = useTranslation();
  const {
    bodyCell,
    button,
    buttons,
    editCell,
    editNameWrapper,
    headerCell,
    iconBtn,
    inputField,
    root,
    rowBlur,
  } = useStyles({ fieldOrder: t('settings@personalNames:fieldOrder') });
  const [editing, setEditing] = React.useState(false);
  const [editUserId, setEditUserId] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [firstName, setFirstName] = React.useState<string>('');
  const [lastName, setLastName] = React.useState<string>('');
  const [permission, setPermission] = React.useState<string>('');
  const [status, setStatus] = React.useState<string>('');

  const showEditingRow = React.useCallback(
    (id: string) => {
      const row = data.find((r) => r.id === id);
      if (row) {
        setEditing(true);
        setEditUserId(id);
        setEmail(row.email);
        setFirstName(row.firstName);
        setLastName(row.lastName);
        setPermission(row.role);
        setStatus(row.status === UserStatus.Disabled ? '0' : '1');

        /// Move scroll to top so that edit row is visible
        const settingsPanel = document.getElementById('SettingsPanel');
        settingsPanel?.scrollTo(0, 0);
      }
    },
    [
      data,
      setEditUserId,
      setEditing,
      setEmail,
      setFirstName,
      setLastName,
      setPermission,
      setStatus,
    ],
  );

  const hideEditingRow = React.useCallback(() => {
    setEditing(false);
  }, [setEditing]);

  const [disableUserMutation] = useDisableUserMutation();
  const [enableUserMutation] = useEnableUserMutation();
  const [updateUserMutation] = useUpdateOtherUserMutation({
    refetchQueries: [{ query: GetOrganizationUsersDocument }],
    onCompleted: () => {
      hideEditingRow();
    },
  });

  const isDisabled = React.useMemo(
    () => !firstName.trim() || !lastName.trim() || !permission || !status,
    [firstName, lastName, permission, status],
  );

  const handleChangeFirstName = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setFirstName(e.target.value),
    [setFirstName],
  );

  const handleChangeLastName = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setLastName(e.target.value),
    [setLastName],
  );

  const handleChangePermission = React.useCallback(
    (e: SelectChangeEvent) => setPermission(e.target.value as string),
    [setPermission],
  );

  const handleChangeStatus = React.useCallback(
    (e: SelectChangeEvent) => setStatus(e.target.value as string),
    [setStatus],
  );

  const handleEditUser = React.useCallback(() => {
    const row = data.find((r) => r.id === editUserId);
    if (row.status === UserStatus.Disabled && status === '1') {
      enableUserMutation({
        variables: {
          id: editUserId,
        },
      });
    } else if (row.status !== UserStatus.Disabled && status === '0') {
      sendAmplitudeData(AMP_EVENT_DISABLE_USER, {
        admin: user?.personalInfo?.contact?.email,
        disabled: row.email,
      });
      disableUserMutation({
        variables: {
          id: editUserId,
        },
      });
    }
    updateUserMutation({
      variables: {
        id: editUserId,
        userInput: {
          personalInfo: {
            firstName: firstName.trim(),
            lastName: lastName.trim(),
          },
          role: permission as UserRole,
        },
      },
    });
  }, [
    data,
    disableUserMutation,
    editUserId,
    enableUserMutation,
    firstName,
    lastName,
    permission,
    status,
    updateUserMutation,
    user?.personalInfo?.contact?.email,
  ]);

  const renderTableCellItem = (key: string, value: string, id: string) => {
    if (key === 'role') return <UserRoleTag userRole={value as UserRole} />;
    if (key === 'status') {
      let statusText: string;
      switch (value) {
        case UserStatus.Joined:
          statusText = t('settings:sections:userManagement:allUsers:status:enabled');
          break;
        case UserStatus.InvitationPending:
          statusText = t('settings:sections:userManagement:allUsers:status:invited');
          break;
        default:
          statusText = t('settings:sections:userManagement:allUsers:status:disabled');
          break;
      }
      return (
        <div className="o-flexsb-container">
          <Typography variant="body2">{statusText}</Typography>
          <div className="o-flex-vcenter-container">
            <IconButton
              color="default"
              data-test-id="UserEditBtn"
              size="small"
              className={iconBtn}
              onClick={() => showEditingRow(id)}
            >
              <Icon name="Edit" />
            </IconButton>
            {/* <IconButton
              color="default"
              data-test-id="UserDeleteBtn"
              size="small"
              className={classes.iconBtn}
            >
              <Icon name="Delete" />
            </IconButton> */}
          </div>
        </div>
      );
    }
    return <Typography variant="body2">{value}</Typography>;
  };

  return (
    <TableContainer className={root} component={Paper}>
      <Table aria-label="UserTable" data-test-id="UserTable">
        <TableHead>
          <TableRow data-test-id="user-table-header">
            {headerFields.map((header) => (
              <TableCell className={headerCell} key={header}>
                <Typography variant="caption">{t(header)}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {editing && (
            <TableRow data-test-id="EditUserRow">
              <TableCell className={editCell}>
                <div className={editNameWrapper}>
                  <TextField
                    placeholder={t('settings:sections:userManagement:addUser:firstName')}
                    variant="outlined"
                    size="small"
                    value={firstName}
                    onChange={handleChangeFirstName}
                    inputProps={{
                      autoComplete: 'off',
                      className: inputField,
                      'data-test-id': 'EditUserFirstNameInput',
                    }}
                  />
                  <TextField
                    placeholder={t('settings:sections:userManagement:addUser:lastName')}
                    variant="outlined"
                    size="small"
                    value={lastName}
                    onChange={handleChangeLastName}
                    inputProps={{
                      autoComplete: 'off',
                      className: inputField,
                      'data-test-id': 'EditUserLastNameInput',
                    }}
                  />
                </div>
              </TableCell>
              <TableCell className={editCell}>
                <TextField
                  placeholder={t('settings:sections:userManagement:allUsers:emailAddress')}
                  variant="outlined"
                  size="small"
                  value={email}
                  inputProps={{
                    autoComplete: 'off',
                    className: inputField,
                    readOnly: true,
                    'data-test-id': 'EditUserEmailInput',
                  }}
                  fullWidth
                />
              </TableCell>
              <TableCell className={editCell}>
                <FormControl size="small" fullWidth data-test-id="EditUserPermissionSelect">
                  <Select
                    size="small"
                    id="edit-user-permission-select"
                    value={permission}
                    onChange={handleChangePermission}
                    IconComponent={ExpandMoreIcon}
                    MenuProps={{
                      PaperProps: {
                        sx: selectMenuStyle,
                      },
                    }}
                  >
                    <MenuItem value={UserRole.Admin}>
                      {t('settings:sections:userManagement:allUsers:permission:administrator')}
                    </MenuItem>
                    <MenuItem value={UserRole.Contributor}>
                      {t('settings:sections:userManagement:allUsers:permission:user')}
                    </MenuItem>
                    {isConsultantOrg && (
                      <MenuItem value={UserRole.Viewer}>
                        {t('settings:sections:userManagement:allUsers:permission:viewer')}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell className={editCell}>
                <div>
                  <FormControl size="small" fullWidth data-test-id="EditUserStatusSelect">
                    <Select
                      size="small"
                      id="edit-user-status-select"
                      value={status}
                      onChange={handleChangeStatus}
                      IconComponent={ExpandMoreIcon}
                      MenuProps={{
                        PaperProps: {
                          sx: selectMenuStyle,
                        },
                      }}
                    >
                      <MenuItem value="1">
                        {t('settings:sections:userManagement:allUsers:status:enabled')}
                      </MenuItem>
                      <MenuItem value="0">
                        {t('settings:sections:userManagement:allUsers:status:disabled')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <div className={`o-flex-vcenter-container ${buttons}`}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      className={`u-clickable u-marginright--8 ${button}`}
                      data-test-id="CancelEditing"
                      onClick={hideEditingRow}
                    >
                      {t('buttons:cancel')}
                    </Button>
                    <Button
                      disabled={isDisabled}
                      variant="contained"
                      color="primary"
                      size="small"
                      className={`u-clickable ${button}`}
                      data-test-id="SaveEditing"
                      onClick={handleEditUser}
                    >
                      {t('buttons:save')}
                    </Button>
                  </div>
                </div>
              </TableCell>
            </TableRow>
          )}

          {
            /* eslint-disable @typescript-eslint/no-unused-vars */
            data.map(({ firstName: _firstName, id, lastName: _lastName, ...rowData }, idx) => (
              <TableRow
                key={id}
                data-test-id={`row-user-${idx}`}
                className={editing ? rowBlur : ''}
              >
                {Object.entries(rowData).map(([key, value]) => (
                  <TableCell className={bodyCell} key={key}>
                    {renderTableCellItem(key, value, id)}
                  </TableCell>
                ))}
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

UserTable.displayName = 'UserTable';
export default UserTable;
