import * as React from 'react';
import { makeStyles } from '@mui/styles';

import {
  ForecastPeriodType,
  GetBuiltObjectQuery,
  GetLocationQuery,
  ResilienceStats,
  ResilienceThresholds,
  Slice,
  useGetBuiltObjectResilienceStatsLazyQuery,
  useGetFirstToFailQuery,
  useGetLocationResilienceStatsLazyQuery,
  useGetPlanViewFiltersQuery,
} from '../../../__generated__/graphql';
import { RETURN_PERIOD_YEAR_OPTIONS, VALID_LIFELINES } from '../../../util/productGlobals';
import { averageArr } from '../../../util/utils';
import { capitalize } from '../../../util/string';
import { getActiveSliceIdx } from '../locationViewHelpers';
import { Lifeline } from '../../../util/productEnums';
import TimelineItem from './TimelineItem';
import useLifelineEntitlements from '../../../Hooks/useLifelineEntitlements';
import useHasFinanceEntitlement from '../../../Hooks/useHasFinanceEntitlement';

type ResStatsArray = ResilienceStats[];

type IndexableLifelineEntitlementsType = {
  [key: string]: boolean;
};

interface TimelineFCProps {
  slices: Slice[];
  serverThresholds: ResilienceThresholds;
  builtObject: GetLocationQuery['location'] | GetBuiltObjectQuery['builtObject'];
}

const useStyles = makeStyles(() => ({
  container: {
    marginRight: 138,
    flex: 1,
  },
  timeLine: {
    width: '1026px',
    margin: '0 auto',
    position: 'relative',
    height: '84px',
    '@media (max-width: 1424px)': {
      width: '846px',
    },
  },
  timeLineBar: {
    background: 'rgba(248, 247, 242, 0.1)',
    borderRadius: 100,
    width: '891px',
    height: 10,
    top: 40,
    left: 52,
    position: 'absolute',
    '@media (max-width: 1424px)': {
      width: '741px',
      left: 38,
    },
  },
  progressBar: {
    background: 'linear-gradient(270deg, #FF3535 33.02%, #F29220 85.29%, #EBE780 105.65%)',
    borderRadius: 100,
    width: '891px',
    height: 10,
    top: 40,
    left: 52,
    position: 'absolute',
    clipPath: (props: { returnPeriodIndex: number }) =>
      `inset(0% calc(100% - ${props.returnPeriodIndex} * 176px - 11px) 0% 0% round 40px 40px)`,
    transition: 'clip-path 1s ease',
    '@media (max-width: 1424px)': {
      width: '741px',
      left: 38,
      clipPath: (props: { returnPeriodIndex: number }) =>
        `inset(0% calc(100% - ${props.returnPeriodIndex} * 146px - 11px) 0% 0% round 40px 40px)`,
    },
  },
}));

const TimelineFC: React.FC<TimelineFCProps> = ({ slices, serverThresholds, builtObject }) => {
  const lifelineEntitlements = useLifelineEntitlements();
  const { id, __typename } = builtObject;
  const {
    data: {
      firstToFailView: { returnPeriodIndex },
    },
  } = useGetFirstToFailQuery();
  const {
    data: {
      planView: { planFilters },
    },
  } = useGetPlanViewFiltersQuery();

  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;
  const loadDnaStats = isFinanceEntitled ? true : undefined;

  const [
    getLocationResilienceStatsLazyQuery,
    { data: locResStatsData, loading: locLoading, error: locError },
  ] = useGetLocationResilienceStatsLazyQuery();

  const [
    getBuiltObjectResilienceStatsLazyQuery,
    { data: builtResStatsData, loading: builtLoading, error: builtError },
  ] = useGetBuiltObjectResilienceStatsLazyQuery();

  const classes = useStyles({ returnPeriodIndex });
  const [vulnerableStatus, setVulnerableStatus] = React.useState([]);
  const [returnPeriodSlices, setReturnPeriodSlices] = React.useState([]);

  React.useEffect(() => {
    const allReturnPeriodSlices: number[] = [];
    const newReturnPeriodSlices: number[][] = [];
    RETURN_PERIOD_YEAR_OPTIONS.forEach((returnPeriod) => {
      const returnPeriodFilters = {
        ...planFilters,
        forecastPeriod: {
          ...planFilters.forecastPeriod,
          forecastPeriodType: ForecastPeriodType.ReturnPeriod,
          duration: returnPeriod,
        },
      };
      const matchSliceId = getActiveSliceIdx(slices, returnPeriodFilters);
      const selectedSlice = slices.find((sliceDef) => sliceDef.slice === matchSliceId);

      const sliceIds = slices
        .filter(
          (sliceDef) =>
            sliceDef.returnPeriod === returnPeriod &&
            !sliceDef.planningHorizon &&
            selectedSlice &&
            sliceDef.climate === selectedSlice.climate &&
            sliceDef.hazardType === selectedSlice.hazardType &&
            sliceDef.historicalEventID === selectedSlice.historicalEventID,
        )
        .map((sliceDef) => sliceDef.slice);
      newReturnPeriodSlices.push(sliceIds);
      allReturnPeriodSlices.push(...sliceIds);
    });
    setReturnPeriodSlices(newReturnPeriodSlices);

    if (__typename === 'LocationObject') {
      getLocationResilienceStatsLazyQuery({
        variables: {
          id,
          slices: allReturnPeriodSlices,
          loadDnaStats,
        },
      });
    }
    if (__typename === 'BuiltObject') {
      getBuiltObjectResilienceStatsLazyQuery({
        variables: {
          id,
          slices: allReturnPeriodSlices,
          loadDnaStats,
        },
      });
    }
  }, [
    id,
    __typename,
    slices,
    planFilters,
    getLocationResilienceStatsLazyQuery,
    getBuiltObjectResilienceStatsLazyQuery,
    loadDnaStats,
  ]);

  const resilienceStatsArr: ResStatsArray = React.useMemo(() => {
    if (!locError && !locLoading && !builtError && !builtLoading) {
      return __typename === 'LocationObject'
        ? locResStatsData?.location?.resilienceStats
        : builtResStatsData?.builtObject?.resilienceStats;
    }
    return null;
  }, [
    __typename,
    locResStatsData,
    builtResStatsData,
    locLoading,
    locError,
    builtError,
    builtLoading,
  ]);

  React.useEffect(() => {
    if (!resilienceStatsArr || returnPeriodSlices.length < 1) return;

    const newVulnerableStatus: Lifeline[][] = [];

    const lifelines = VALID_LIFELINES.filter((posLl) => {
      return (lifelineEntitlements as IndexableLifelineEntitlementsType)?.[posLl];
    });

    const lifelineAlreadyOnTimeline: Lifeline[] = [];
    RETURN_PERIOD_YEAR_OPTIONS.forEach((returnPeriod, index) => {
      const filteredResilienceStatsArr: ResStatsArray = resilienceStatsArr.filter((stat) =>
        returnPeriodSlices[index].includes(stat.slice),
      );
      if (filteredResilienceStatsArr.length > 0) {
        const lifelineVulnerables: Lifeline[] = [];
        lifelines?.forEach((lifeline: Lifeline) => {
          const downtime =
            lifeline === Lifeline.STRUCTURE
              ? averageArr(
                  filteredResilienceStatsArr.map((rStat) => rStat.structuralDamage?.mean),
                  true,
                )
              : averageArr(
                  filteredResilienceStatsArr.map(
                    (rStat) =>
                      (rStat as Record<string, any>)[`nearby${capitalize(lifeline)}`]?.mean,
                  ),
                  true,
                );
          const threshold =
            lifeline === Lifeline.STRUCTURE
              ? serverThresholds.structuralDamage
              : (serverThresholds as any)[`${lifeline}Downtime`] || 0;
          if (downtime > threshold && !lifelineAlreadyOnTimeline.includes(lifeline)) {
            lifelineVulnerables.push(lifeline);
            lifelineAlreadyOnTimeline.push(lifeline);
          }
        });
        newVulnerableStatus.push(lifelineVulnerables);
      } else {
        newVulnerableStatus.push([]);
      }
    });

    setVulnerableStatus(newVulnerableStatus);
  }, [resilienceStatsArr, serverThresholds, slices, returnPeriodSlices, lifelineEntitlements]);

  return (
    <div className={classes.container}>
      <div className={classes.timeLine} data-test-id="Timeline">
        <div className={classes.timeLineBar} />
        <div className={classes.progressBar} />
        {vulnerableStatus.length === RETURN_PERIOD_YEAR_OPTIONS.length && (
          <div className="o-flexsb-container">
            {[0, ...RETURN_PERIOD_YEAR_OPTIONS].map((option, index) => (
              <TimelineItem
                lineIndex={index}
                returnPeriodIndex={returnPeriodIndex}
                returnPeriodYear={option}
                vulnerableStatus={index > 0 ? vulnerableStatus[index - 1] : []}
                key={option}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const Timeline = React.memo(TimelineFC);
Timeline.displayName = 'Timeline';
export default Timeline;
