import * as React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import Icon from '../../CommonComponents/Icon/Icon';
import { Lifeline } from '../../../util/productEnums';
import { useUpdatePlayStateMutation } from '../../../__generated__/graphql';
import { WHITE } from '../../../util/productGlobals';

interface TimelineItemFCProps {
  lineIndex: number;
  returnPeriodIndex: number;
  returnPeriodYear: number;

  vulnerableStatus: Lifeline[];
}

const useStyles = makeStyles(() => ({
  circle: {
    width: 8,
    height: 8,
    borderRadius: 20,
    background: '#E8EAEB',
    boxSizing: 'content-box',
  },
  returnPeriodOption: {
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '84px',
    width: '176px',
    zIndex: 1,
    '&:first-child': {
      width: '146px',
      paddingRight: 30,
    },
    '@media (max-width: 1424px)': {
      width: '146px',
      '&:first-child': {
        width: '116px',
      },
    },
  },
  vulnerableLifelines: {
    padding: '2px 8px 4px 8px',
    height: 24,
    background: 'rgba(178, 0, 253, 0.3)',
    opacity: 0.2,
    border: '1px solid #B662FF',
    boxSizing: 'border-box',
    backdropFilter: 'blur(6px)',
    borderRadius: 100,
    transition: 'opacity 1.5s ease',
  },
  vulnerableLifelineIcon: {
    marginRight: 8,
    '&:last-child': {
      marginRight: 0,
    },
  },
  activeVulnerableLifeline: {
    opacity: 1,
  },
  returnPeriodTitle: {
    color: WHITE,
    width: 52,
    textAlign: 'center',
    letterSpacing: 0,
    whiteSpace: 'nowrap',
    lineHeight: '18px',
  },
  empty: {
    minHeight: 24,
  },
}));

const TimelineItemFC: React.FC<TimelineItemFCProps> = ({
  lineIndex,
  returnPeriodIndex,
  returnPeriodYear,
  vulnerableStatus,
  // sliceIds,
}) => {
  const { t } = useTranslation();

  const classes = useStyles({ returnPeriodIndex });
  const [updatePlayState] = useUpdatePlayStateMutation();

  const selectReturnPeriodIndex = React.useCallback(
    (index: number) => {
      updatePlayState({
        variables: {
          returnPeriodIndex: index,
        },
      });
    },
    [updatePlayState],
  );

  const onKeyDown = React.useCallback(
    ({ key }: React.KeyboardEvent, selLineIndex: number) => {
      if (key === 'Enter') {
        selectReturnPeriodIndex(selLineIndex);
      }
    },
    [selectReturnPeriodIndex],
  );

  return (
    <div
      className={`o-flexcol-container ${classes.returnPeriodOption}`}
      key={returnPeriodYear}
      onClick={() => selectReturnPeriodIndex(lineIndex)}
      onKeyDown={(e: React.KeyboardEvent) => onKeyDown(e, lineIndex)}
      data-test-id={`TimelineElement-${lineIndex}`}
      role="button"
      tabIndex={0}
    >
      {lineIndex === 0 || vulnerableStatus.length === 0 ? (
        <div className={classes.empty} data-test-id="elementEmpty" />
      ) : (
        <div
          className={`${classes.vulnerableLifelines} ${
            lineIndex === returnPeriodIndex ? classes.activeVulnerableLifeline : ''
          }`}
          data-test-id="lifelines"
        >
          {vulnerableStatus.map((caption: Lifeline) => (
            <Icon
              name={`Lifeline-${caption}-down ${classes.vulnerableLifelineIcon}`}
              key={caption}
              dataTestId={`Lifeline-${caption}-down`}
            />
          ))}
        </div>
      )}
      <span className={classes.circle} />
      <Typography variant="caption" className={classes.returnPeriodTitle}>
        {returnPeriodYear === 0
          ? t('symbolicView:firstToFail:baseline')
          : t('symbolicView:firstToFail:returnPeriodYears', { returnPeriod: returnPeriodYear })}
      </Typography>
    </div>
  );
};

const TimelineItem = React.memo(TimelineItemFC);
TimelineItem.displayName = 'TimelineItem';
export default TimelineItem;
