import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, CardHeader, Grid, Theme, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { GeocodeFeature } from '@mapbox/mapbox-sdk/services/geocoding';
import { makeStyles } from '@mui/styles';

import {
  BuiltObject,
  LocationObject,
  RecentViewType,
  useGetRecentViewsQuery,
  useSetEditLocationSourceMutation,
} from '../../../../__generated__/graphql';
import { fixBadAddressStr, getDisplayableAddress } from '../../../../util/addresses/addressUtils';
import { App } from '../../../../PlanningApp/AppConfig';
import { compareStreetAddressesJP } from '../../../../util/addresses/addressUtilsJP';
import { compareStreetAddressesUS } from '../../../../util/addresses/addressUtilsUS';

import Chip from '../../../CommonComponents/Chip/Chip';

import { ORANGE } from '../../../../util/productGlobals';
import TooltipWithHeaderFC from '../../../CommonComponents/Tooltip/TooltipWithHeader';

export type DetailCardHeaderProps = Pick<BuiltObject, 'id' | 'name' | 'address' | 'coordinates'> & {
  group?: LocationObject['group'];
  type?: LocationObject['type'];
  locationAddress?: LocationObject['locationAddress'];
  isLocation: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0, 2, 8, 2),
  },
  editLocationLink: {
    color: ORANGE,
    cursor: 'pointer',
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  matchWarning: {
    color: ORANGE,
    borderRadius: 6,
    border: `1px solid ${ORANGE}`,
    padding: 8,
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginBottom: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 368,
    whiteSpace: 'nowrap',
  },
}));

type MismatchWarningProps = {
  id: string;
  isLocation: boolean;
  editLocation: () => void;
};

type OriginalAddressComponentProps = {
  address: string;
  isLocation: boolean;
  editLocation: () => void;
};

const MismatchWarning: React.FC<MismatchWarningProps> = ({ id, isLocation, editLocation }) => {
  const { editLocationLink, matchWarning } = useStyles();
  const { t } = useTranslation();

  if (!isLocation) {
    return null;
  }

  return (
    <div
      className={`${matchWarning} u-marginbottom--16`}
      data-test-id="builtObject-doesnt-match-location-warning"
      data-test-val-bobj-id={id}
    >
      <ErrorOutlineIcon fontSize="small" />
      <Typography component="div" variant="body2" className="u-marginleft--8">
        <Trans
          i18nKey={`detailView:${
            isLocation ? 'matchObjectWarningWithEditLocation' : 'matchObjectWarning'
          }`}
        >
          <Typography
            classes={{ root: editLocationLink }}
            component="span"
            color="textPrimary"
            variant="body2"
            onClick={editLocation}
            data-test-id="location-mismatch-messenger"
          >
            {t('detailView:clickHereWithEditLocation')}
          </Typography>
        </Trans>
      </Typography>
    </div>
  );
};
MismatchWarning.displayName = 'Warning';

const OriginalAddressComponent: React.FC<OriginalAddressComponentProps> = ({
  address,
  isLocation,
  editLocation,
}) => {
  const { t } = useTranslation();

  if (address) {
    const { line1, line2 } = fixBadAddressStr(address);
    return (
      <Typography
        variant="body1"
        component="div"
        data-test-id={isLocation ? 'original-uploaded-address' : 'search-bobj-user-input'}
      >
        {line1 && <div data-test-id="orig-addr-line1">{line1}</div>}
        {line2 && <div data-test-id="orig-addr-line2">{line2}</div>}
        {isLocation && (
          <Button
            variant="outlined"
            color="primary"
            className="u-marginy--16"
            onClick={editLocation}
            key="editLocationButton"
            data-test-id="editLocationButton"
          >
            {t(`detailView:editLocation`)}
          </Button>
        )}
      </Typography>
    );
  }
  return null;
};
OriginalAddressComponent.displayName = 'OriginalAddressComponent';

const DetailCardHeaderFC: React.FC<DetailCardHeaderProps> = ({
  id,
  name,
  address,
  group,
  type,
  isLocation,
  locationAddress,
}) => {
  const { root, title } = useStyles();
  const { t } = useTranslation();

  const [aLine1, aLine2] = getDisplayableAddress(address);
  const addressCountryCode = address?.countryCode.toUpperCase();
  const location = useLocation();
  const navigate = useNavigate();

  const [setEditLocationSource] = useSetEditLocationSourceMutation();
  const editLocation = React.useCallback(() => {
    setEditLocationSource({
      variables: {
        url: location.pathname,
      },
    });
    navigate(`/locations/${id}`);
  }, [id, location, navigate, setEditLocationSource]);

  const { data: RecentViewData } = useGetRecentViewsQuery({ skip: isLocation });

  const buildingSearchInput = React.useMemo(() => {
    if (!isLocation && RecentViewData) {
      const { recentViews } = RecentViewData;
      const searchInput = recentViews.find(
        (recentView) => recentView.id === id && recentView.type === RecentViewType.Building,
      );
      if (searchInput?.userInput) {
        return JSON.parse(searchInput?.userInput) as GeocodeFeature;
      }
    }
    return null;
  }, [isLocation, RecentViewData, id]);

  const buildingSearchStr =
    buildingSearchInput?.properties?.place_name ?? buildingSearchInput?.place_name;
  const inputAddress = isLocation ? locationAddress : buildingSearchStr;
  const isLocationMatch = React.useMemo(() => {
    /// Invalid inputAddress means that location info is still being loaded yet, or locationAddress
    /// is null because this is a builtObject, not a location.
    /// Don't show a warning in either of these cases.
    if (!inputAddress) return true;
    switch (addressCountryCode) {
      case 'US':
        return compareStreetAddressesUS(aLine1, inputAddress.trim());
      case 'JP': {
        const { formattedAddress } = address;
        return compareStreetAddressesJP(formattedAddress, inputAddress.trim());
      }
      default:
        App.debug('[DetailCardHeader] invalid or unsupported country code: ', addressCountryCode);
        return true;
    }
  }, [address, addressCountryCode, aLine1, inputAddress]);

  return (
    <CardHeader
      className={root}
      title={
        name && (
          <Typography className={title} variant="h2" data-test-id={`builtObject:${id}-name`}>
            {name}
          </Typography>
        )
      }
      subheader={
        <>
          {!isLocationMatch && (
            <MismatchWarning id={id} isLocation={isLocation} editLocation={editLocation} />
          )}
          <Grid container direction="row" spacing={1}>
            <Grid item>
              {aLine1 && (
                <Typography
                  color="textPrimary"
                  variant="body1"
                  data-test-id={`builtObject:${id}-address-1`}
                  data-test-id2="builtObject-address-line1"
                >
                  {aLine1}
                </Typography>
              )}
              {aLine2 && (
                <Typography
                  color="textPrimary"
                  variant="body1"
                  data-test-id={`builtObject:${id}-address-2`}
                  data-test-id2="builtObject-address-line2"
                >
                  {aLine2}
                </Typography>
              )}
            </Grid>
            {inputAddress && (
              <Grid item data-test-id={`builtObject:${id}-originalAddress`}>
                <TooltipWithHeaderFC
                  title={t('detailView:addressInfoTitle')}
                  body={
                    <OriginalAddressComponent
                      address={inputAddress}
                      isLocation={isLocation}
                      editLocation={editLocation}
                    />
                  }
                />
              </Grid>
            )}
          </Grid>

          {(group || type) && (
            <div className="u-margintop--16">
              {group && <Chip label={group} data-test-id={`builtObject:${id}-group`} />}
              {type && (
                <Chip
                  label={type}
                  additionalClasses="u-marginleft--10"
                  data-test-id={`builtObject:${id}-type`}
                />
              )}
            </div>
          )}
        </>
      }
    />
  );
};
const DetailCardHeader = React.memo(DetailCardHeaderFC);
DetailCardHeader.displayName = 'DetailCardHeader';
export default DetailCardHeader;
