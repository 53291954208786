import * as React from 'react';
import { HashRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import { jssPreset, StylesProvider } from '@mui/styles';
import { create } from 'jss';

import { App, AppConfigProps } from './PlanningApp/AppConfig';
import PlanningAppContainer from './PlanningApp/PlanningAppContainer';
import { initAmplitude } from './plugins/amplitude';

const root = createRoot(document.getElementById('root'));

declare global {
  interface Window {
    oneconcern: AppConfigProps;
  }
}

const jss = create({
  ...jssPreset(),
  // Define a custom insertion point that JSS will look for when injecting the styles into the DOM.
  insertionPoint: document.getElementById('jss-insertion-point'),
});

App.getConfig().then((data: AppConfigProps) => {
  // Setting global namespace oneconcern for all ui configuration if required
  window.oneconcern = data;
  App.config = data;

  initAmplitude();

  root.render(
    <StylesProvider jss={jss}>
      <HashRouter>
        <PlanningAppContainer />
      </HashRouter>
    </StylesProvider>,
  );
});
