import * as React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Card, Grow, Modal, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  RoiStatus,
  useAddRoiBuiltObjectMutation,
  useCalculateRoiMutation,
  useGetCurrentUserQuery,
} from '../../../../__generated__/graphql';
import { DEMO_ACCOUNT_SUFFIX, GLASS_BG } from '../../../../util/productGlobals';
import { sendAmplitudeData } from '../../../../plugins/amplitude';
import { AMP_EVENT_ROI_ANALYSIS_REQUEST } from '../../../../plugins/amplitudeevents';
import PowerROIContent from './PowerROIContent';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: `calc(100dvw - ${theme.spacing(4)})`,
    height: `calc(100dvh - ${theme.spacing(4)})`,
    backgroundColor: GLASS_BG,
    outline: 0,
    margin: 0,
    padding: 0,
  },
}));

type PowerROIModalFCProps = {
  builtObjectDetails?: {
    propertyType?: string;
    id?: string;
    builtObjectID?: string;
    type: 'LocationObject' | 'BuiltObject';
  };
  modalOpen?: boolean;
  setModalOpen?: (open: boolean) => void;
  coordinates?: number[];
  setROIVisible?: (visible: boolean) => void;
};

const PowerROIModalFC: React.FC<PowerROIModalFCProps> = ({
  builtObjectDetails,
  modalOpen,
  setModalOpen,
  coordinates,
  setROIVisible,
}) => {
  const classes = useStyles();
  const [assumptions, setAssumptions] = React.useState('');
  const [cached, setCached] = React.useState(true);
  const [options, setOptions] = React.useState([]);
  const [suggestions, setSuggestions] = React.useState('');
  const [initialFetch, setInitialFetch] = React.useState(true);
  const { data: userData } = useGetCurrentUserQuery();
  const [addROIBuiltObject] = useAddRoiBuiltObjectMutation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { id: analysisURLID } = useParams<{ id: string }>();
  const { builtObjectID } = builtObjectDetails;
  const [calculateROIMutation, { data }] = useCalculateRoiMutation({
    onCompleted: (dataROI) => {
      const roiStatus = dataROI?.calculateROI?.status;
      setAssumptions(dataROI?.calculateROI?.assumptions);
      setOptions(dataROI?.calculateROI?.options);
      setSuggestions(dataROI?.calculateROI?.chatBotResponse);
      setROIVisible(true);
      if (userData && (roiStatus === RoiStatus.Completed || roiStatus === RoiStatus.Error)) {
        const userId = userData.user.personalInfo.contact.email;
        sendAmplitudeData(AMP_EVENT_ROI_ANALYSIS_REQUEST, {
          userId,
          builtObjectID,
          propertyType: dataROI?.calculateROI?.propertyType,
          status: roiStatus,
          coordinates: coordinates.join(', '),
          cache: cached,
        });
      } else if (roiStatus === RoiStatus.InProgress) {
        setCached(false);
        if (modalOpen && !initialFetch) {
          setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            calculateROI();
          }, 2000);
        }
      }
    },
  });

  const calculateROI = React.useCallback(
    (retry?: boolean) => {
      calculateROIMutation({
        variables: {
          input: {
            builtObjectID,
            propertyType: builtObjectDetails.propertyType,
            retry,
          },
        },
      });
    },
    [builtObjectDetails.propertyType, builtObjectID, calculateROIMutation],
  );

  const status = data?.calculateROI?.status;
  const zeroMeanStddevMsg = data?.calculateROI?.zeroMeanStddevMsg;

  // This triggers mutation when user enters Top Concerns view
  // in order to make AI calculation happen by background
  React.useEffect(() => {
    calculateROI();
  }, [calculateROI]);

  React.useEffect(() => {
    if (modalOpen && status === RoiStatus.InProgress && initialFetch) {
      calculateROI();
      setInitialFetch(false);
    }
  }, [calculateROI, modalOpen, status, initialFetch]);

  const handleCloseModal = () => {
    setInitialFetch(true);
    setModalOpen(false);
    if (status !== RoiStatus.Completed && status !== RoiStatus.Error) {
      addROIBuiltObject({
        variables: {
          input: {
            builtObjectID,
            propertyType: builtObjectDetails.propertyType ?? null,
            analysisURLID,
          },
        },
      });
    }
    const param = searchParams.get('showROI');
    if (param) {
      searchParams.delete('showROI');
      setSearchParams(searchParams);
    }
  };

  const handleRetry = React.useCallback(() => {
    calculateROI(true);
  }, [calculateROI]);

  if (!modalOpen) {
    return null;
  }

  const isDemoUser = userData?.user?.personalInfo?.contact?.email?.endsWith(DEMO_ACCOUNT_SUFFIX);

  return (
    <Modal
      className="o-flex-center-container"
      onClose={handleCloseModal}
      open={modalOpen}
      closeAfterTransition
    >
      <Grow in={modalOpen}>
        <Card className={classes.card} data-test-id="ROIModal">
          <PowerROIContent
            builtObjectDetails={builtObjectDetails}
            handleClose={handleCloseModal}
            powerROIData={{
              suggestions,
              options,
              zeroMeanStddevMsg,
              status,
              error: data?.calculateROI?.error,
              assumptions,
              handleRetry,
            }}
            isDemoUser={isDemoUser}
          />
        </Card>
      </Grow>
    </Modal>
  );
};

const PowerROIModal = React.memo(PowerROIModalFC);
PowerROIModal.displayName = 'PowerROIModal';

export default PowerROIModal;
