import * as React from 'react';
import { datadogRum } from '@datadog/browser-rum';

import { App } from './AppConfig';
import { useGetWidgetsInfoQuery } from '../__generated__/graphql';

const DatadogWidget: React.FC = () => {
  // retrieve datadog token
  const { data, loading, error } = useGetWidgetsInfoQuery();

  App.debug(
    `[DatadogWidget] datadog RUM feature enabled: ${App.config.tokens.datadog.applicationid}`,
  );
  // NOTE: we don't want the token to be stored locally

  if (error) {
    App.error('[DatadogWidget] useGetWidgetsInfoQuery error: ', error);
    return null;
  }

  if (loading || !data) {
    App.debug('[DatadogWidget] Loading useGetWidgetsInfoQuery');
    return null;
  }
  const widgetsInfo = data?.widgetsInfo;
  if (!widgetsInfo?.datadog?.clientToken) {
    App.error('[DatadogWidget] unexpected missing datadog data from WidgetsInfo');
    App.debug(widgetsInfo);
    return null;
  }

  // initialize datadog's component
  datadogRum.init({
    applicationId: App.config.tokens.datadog.applicationid,
    clientToken: widgetsInfo.datadog.clientToken,
    site: App.config.datadog.site || 'datadoghq.com',
    service: App.config.datadog.service || 'domino-app',
    env: App.config.datadog.env || 'dev',
    version: App.config.datadog.version || 'master',
    sampleRate: App.config.datadog.samplerate || 100,
    trackInteractions: App.config.datadog.trackinteractions || false,
    silentMultipleInit: true,
  });

  // don't have to render anything
  return null;
};
DatadogWidget.displayName = 'DatadogWidget';
export default DatadogWidget;
