import * as React from 'react';
import { Button, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { RequiredScopeNotGrantedErr } from './AuthError';

import logo from '../../../assets/images/Primary_Logo_Orange-2x.png';

type LoginErrorPageProps = {
  error: Error;
};

const LoginErrorPage: React.FC<LoginErrorPageProps> = ({ error }) => {
  const { t } = useTranslation();

  const message =
    error instanceof RequiredScopeNotGrantedErr
      ? 'You do not have permission to access Domino.'
      : 'An error has occurred while signing in. Please try again.';

  return (
    <div
      className="o-flexcol-container o-flex-center-container u-height--100 u-width--50"
      data-test-id="LoginErrorPage"
    >
      <img src={logo} alt={t('productLogo')} data-test-id="LoginErrorPage-Logo" />
      <Typography
        className="f-font f-white u-margintop--100 u-marginbottom--100 u-aligncenter"
        variant="h5"
      >
        {message}
      </Typography>
      <Button
        color="primary"
        variant="contained"
        component={Link}
        href={document.location.origin}
        className="f-font u-marginbottom--100"
        data-test-id="LoginErrorPage-HomeButton"
      >
        {t('err:returnHome')}
      </Button>
    </div>
  );
};

export default LoginErrorPage;
