export function capitalize(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function titleize(s: string, delimiter?: string): string {
  const stringArray = s.split(delimiter && delimiter[0] ? delimiter[0] : ' ');
  for (let i = 0; i < stringArray.length; i += 1) {
    stringArray[i] = capitalize(stringArray[i]);
  }
  return stringArray.join(' ');
}
