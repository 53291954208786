import { locationUploadFileMutations } from '../resolvers/LocationUploadFileResolver';

export const STORE_DEFAULTS = {
  locationUploadFile: {
    file: null as any,
  },
};

export const locationUploadFileStore = {
  defaults: STORE_DEFAULTS,
  mutations: locationUploadFileMutations,
};
