import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import CurrencyInput from '../../../CommonComponents/InputControls/CurrencyInput/CurrencyInput';
import {
  useGetCompaniesQuery,
  useGetCompanyLazyQuery,
  useGetCurrentUserQuery,
} from '../../../../__generated__/graphql';
import Spinner from '../../../CommonComponents/Spinner/Spinner';
import { LOCALSTORAGE_KEY_PREFIX, WHITE } from '../../../../util/productGlobals';
import { formatNumberByThousands } from '../../../../util/productUtils';
import { titleize } from '../../../../util/string';
import useLocalStorage from '../../../../Hooks/useLocalStorage';

const useStyles = makeStyles(() => ({
  container: {
    '& .MuiAccordion-root': {
      boxShadow: 'none',
    },
  },
  tableCell: {
    border: '1px solid rgba(81, 81, 81, 1)',
    padding: '8px',
  },
}));

const locationHeaderFields = ['locationAddress', 'dailyRevenue', 'propertyValue'];

const FinanceRevenueTable = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState('');
  const [pageIndex, setPageIndex] = React.useState(0);
  const { data, loading } = useGetCompaniesQuery();
  const [getCompanyLazyQuery, { data: dataCompany, loading: loadingCompany }] =
    useGetCompanyLazyQuery();
  const { data: userData } = useGetCurrentUserQuery();
  const userId = userData?.user?.personalInfo?.contact?.email;
  const [selectedCompanies] = useLocalStorage<string[]>(
    `${LOCALSTORAGE_KEY_PREFIX}${userId}:selectedCompanies`,
    [],
  );

  React.useEffect(() => {
    if (expanded) {
      getCompanyLazyQuery({
        variables: {
          id: expanded,
        },
      });
    }
  }, [expanded, getCompanyLazyQuery]);

  const renderTableCellItem = (inputKey: string, value: number | string, locationId: string) => {
    if (inputKey !== 'dailyRevenue' && inputKey !== 'propertyValue') {
      return (
        <Typography variant="body2">
          {inputKey === 'type' ? titleize(value as string, '_') : value}
        </Typography>
      );
    }

    return (
      <CurrencyInput
        readOnly
        id={`${locationId}-finance-${inputKey}`}
        value={formatNumberByThousands(value)}
      />
    );
  };

  const companiesList = React.useMemo(
    () => data?.getCompanies?.filter((item) => selectedCompanies.includes(item.name)),
    [data?.getCompanies, selectedCompanies],
  );

  const builtObjects = React.useMemo(
    () =>
      dataCompany?.getCompany?.builtObjects?.map((builtObject) => ({
        id: builtObject.id,
        address: builtObject.address.formattedAddress,
        dailyRevenue: builtObject.finance.dailyRevenue,
        propertyValue: builtObject.finance.propertyValue,
      })),
    [dataCompany?.getCompany?.builtObjects],
  );

  if (loading || !companiesList.length) {
    return <Spinner />;
  }

  return (
    <div className={classes.container} data-test-id="FinanceRevenueSettingsTable">
      <Grid container alignItems="center" my={4}>
        <Grid item xs={8}>
          <Typography variant="body1" fontWeight="bold" px={2}>
            Company
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight="bold">
            {t('financeView:settings:drivers:revenue:headerFields:annualRevenue')}
          </Typography>
        </Grid>
      </Grid>
      {companiesList?.slice(0, (pageIndex + 1) * 10)?.map((company) => (
        <Accordion
          key={company.id}
          expanded={expanded === company.id}
          onChange={(_, expanded) => {
            setExpanded(expanded ? company.id : '');
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: WHITE }} />}
            aria-controls={`${company.name}-Revenue`}
            id={`${company.name}-Revenue`}
            data-test-id={`${company.name}-Revenue`}
            sx={{
              '&.Mui-focusVisible': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <Grid container alignItems="center">
              <Grid item xs={8}>
                <Typography variant="body2">{titleize(company.name, '_')}</Typography>
              </Grid>
              <Grid item xs={4}>
                <CurrencyInput
                  readOnly
                  id={`${company.name}-Revenue`}
                  value={formatNumberByThousands(company.meta?.revenueYearly)}
                />
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            {expanded === company.id &&
              (loadingCompany ? (
                <Spinner />
              ) : (
                <TableContainer
                  component={Paper}
                  sx={{ backgroundColor: 'transparent', boxShadow: 'none', borderRadius: '0px' }}
                >
                  <Table>
                    <TableHead>
                      <TableRow data-test-id="FinanceLossHeader">
                        {locationHeaderFields.map((header) => (
                          <TableCell className={classes.tableCell} key={header}>
                            <Typography variant="body2">
                              {t(
                                `financeView:settings:drivers:revenue:locationHeaderFields:${header}`,
                              )}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {builtObjects?.map((rowData) => (
                        <TableRow key={rowData.id} data-test-id={`LocationRevenue-${rowData.id}`}>
                          {Object.entries(rowData).map(
                            ([key, value]) =>
                              key !== 'id' && (
                                <TableCell key={key} className={classes.tableCell}>
                                  {renderTableCellItem(key, value, rowData.id)}
                                </TableCell>
                              ),
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ))}
          </AccordionDetails>
        </Accordion>
      ))}
      {companiesList?.length > (pageIndex + 1) * 10 && (
        <div className="u-textalign-center u-margintop--16">
          <Button variant="outlined" color="primary" onClick={() => setPageIndex(pageIndex + 1)}>
            {t('financeView:settings:drivers:loadMore')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default FinanceRevenueTable;
