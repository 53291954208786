import { ApolloCache, NormalizedCacheObject, Resolver } from '@apollo/client';
import { cloneDeep } from 'lodash/fp';

import {
  GetCurrentUserDocument,
  GetCurrentUserQuery,
  GetRecentViewsDocument,
  GetRecentViewsQuery,
  MutationAddRecentViewArgs,
  MutationInitializeRecentViewsArgs,
  RecentViewFragmentFragment,
} from '../../../__generated__/graphql';
import { LOCALSTORAGE_KEY_PREFIX, RECENT_VIEW_LOCATION_LIMIT } from '../../../util/productGlobals';

export const addRecentView: Resolver = (
  _: unknown,
  { id, type, userInput }: MutationAddRecentViewArgs,
  { cache }: { cache: ApolloCache<NormalizedCacheObject> },
) => {
  const storeData = cache.readQuery<GetRecentViewsQuery>({ query: GetRecentViewsDocument });
  const oldData = cloneDeep(storeData);
  const newView: RecentViewFragmentFragment = {
    id,
    type,
    userInput,
    __typename: 'RecentView',
  };

  const isDuplicate = (a: RecentViewFragmentFragment, b: RecentViewFragmentFragment) => {
    return a.id === b.id && a.type === b.type;
  };

  const oldView = oldData?.recentViews?.find((view) => isDuplicate(newView, view));
  if (oldView) {
    newView.userInput = userInput?.length > 0 ? userInput : oldView.userInput;
  }

  const newRecentViewsList: GetRecentViewsQuery['recentViews'] = [newView]
    .concat([...oldData.recentViews.filter((view) => !isDuplicate(newView, view))])
    .slice(0, RECENT_VIEW_LOCATION_LIMIT);

  cache.writeQuery({ data: { recentViews: newRecentViewsList }, query: GetRecentViewsDocument });

  const userData = cache.readQuery<GetCurrentUserQuery>({ query: GetCurrentUserDocument });
  const userId = userData?.user?.id ?? 'UNKNOWN_USER';
  localStorage.setItem(
    `${LOCALSTORAGE_KEY_PREFIX}${userId}:recentViews`,
    JSON.stringify(newRecentViewsList),
  );
  return newView;
};

export const initializeRecentViews: Resolver = (
  _: unknown,
  { recentViewsList }: MutationInitializeRecentViewsArgs,
  { cache }: { cache: ApolloCache<NormalizedCacheObject> },
) => {
  cache.writeQuery({
    data: { recentViews: recentViewsList },
    query: GetRecentViewsDocument,
  });
  const userData = cache.readQuery<GetCurrentUserQuery>({ query: GetCurrentUserDocument });
  const userId = userData?.user?.id ?? 'UNKNOWN_USER';
  localStorage.setItem(
    `${LOCALSTORAGE_KEY_PREFIX}${userId}:recentViews`,
    JSON.stringify(recentViewsList),
  );
  return recentViewsList;
};

export const recentViewMutations = {
  addRecentView,
  initializeRecentViews,
};
