import * as React from 'react';
import MaterialityDrivers from './MaterialityDrivers';
import MaterialityKPIsContainer from './MaterialityKPIsContainer';
import RevenueTable from './RevenueTable';

const MaterialityDriversContainer = () => {
  return (
    <>
      <MaterialityDrivers />
      <MaterialityKPIsContainer />
      <RevenueTable />
    </>
  );
};

export default MaterialityDriversContainer;
