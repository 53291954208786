/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import { Popup } from 'react-mapbox-gl';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { LocationStatus, MibRequestStatus } from '../../../__generated__/graphql';
import DraggableMapPin from './DraggableMapPin';
import { LocRecord } from './locationMatchHelpers';
import { WHITE } from '../../../util/productGlobals';

const useStyles = makeStyles((theme: Theme) => ({
  popupRoot: {
    color: WHITE,
    padding: theme.spacing(2),
  },
}));

const AddressMapComponents: React.FC = () => {
  const { popupRoot: popupRootStyles } = useStyles();
  const { t } = useTranslation();

  const { locRecord } = useOutletContext() as { locRecord: LocRecord };

  const {
    audit: {
      builtObjectID,
      processStatus,
      geocodedCoordinates,
      builtObjectCoordinates: matchBobjCoordinates,
      builtObjectRequestStatus,
    },
  } = locRecord;

  const showMatchingBuildingMarker =
    processStatus === LocationStatus.Success &&
    builtObjectRequestStatus !== MibRequestStatus.Pending &&
    builtObjectID &&
    matchBobjCoordinates;

  const origCoordinates = geocodedCoordinates;

  return (
    <div data-test-id="selectedLocationPopups">
      {showMatchingBuildingMarker && (
        <>
          <div data-test-id="popup-matchedBuilding">
            {/* @ts-ignore */}
            <Popup
              coordinates={matchBobjCoordinates}
              offset={75}
              className="map-popup-location map-popup-offset-large"
              anchor={(origCoordinates?.[1] ?? 0) - matchBobjCoordinates[1] > 0 ? 'top' : 'bottom'}
            >
              <div className={popupRootStyles}>
                <Typography>{t('locationMatch:mapPopup:buildingMatchPin')}</Typography>
              </div>
            </Popup>
          </div>

          <div data-test-id="builtObject-marker-container">
            <DraggableMapPin
              coordinates={matchBobjCoordinates}
              draggable={false}
              color="secondary"
              dataTestId="matchingBobjMarker"
            />
          </div>
        </>
      )}
      {origCoordinates && (
        <>
          <div data-test-id="popup-originalAddress">
            {/* @ts-ignore */}
            <Popup
              coordinates={origCoordinates}
              offset={75}
              className="map-popup-location map-popup-offset-large"
              anchor={origCoordinates[1] - (matchBobjCoordinates?.[1] ?? 0) > 0 ? 'bottom' : 'top'}
            >
              <div className={popupRootStyles}>
                <Typography>
                  {showMatchingBuildingMarker
                    ? t('locationMatch:mapPopup:originalAddressPin')
                    : t('locationMatch:mapPopup:originalAddressPinApproximate')}
                </Typography>
              </div>
            </Popup>
          </div>

          <div data-test-id="original-location-marker-container">
            {/* We need to keep these extra divs around the DraggableMapPins, so things don't break:
                      https://stackoverflow.com/questions/50784302/ */}
            <DraggableMapPin
              coordinates={origCoordinates}
              draggable={false}
              color="primary"
              dataTestId="originalLocationMarker"
            />
          </div>
        </>
      )}
    </div>
  );
};
AddressMapComponents.displayName = 'AddressMapComponents';
export default AddressMapComponents;
