import * as React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Map as MapboxMap } from 'mapbox-gl';
import { MapContext } from 'react-mapbox-gl';

import MapFlyTo from '../Map/Cluster/MapFlyTo';
import { useGetOriginalLocationsFileRecordQuery } from '../../../__generated__/graphql';

const LocationMatchLayers: React.FC = () => {
  const params = useParams<{ id: string }>();
  const id = params?.id;
  // This component doesn't handle errors from the GetOriginalLocationsFileRecord query;
  // the LocationMatchCard component will handle the errors from this query.
  const { data } = useGetOriginalLocationsFileRecordQuery({
    variables: { locationID: id },
    skip: !id,
  });

  const locRecord = data?.user?.productSettings?.originalFile?.[0];
  if (!locRecord) {
    return null;
  }
  const {
    audit: { locationID, geocodedCoordinates: origCoordinates },
  } = locRecord;

  // TODO - determine if both origCoordinates & matchBobjCoordinates will be visibile at zoom 18.
  // if not, reduce the zoom.
  // const distanceApart =
  // reactMap.getContainer().height; // px
  // reactMap.getContainer().width;  // px

  // NOTE: We have to return our map makers and layers wrapped in a <div>, not a <>fragment.
  // See https://stackoverflow.com/a/64768491/533396
  return (
    <div data-test-id="location-match-layers-container">
      <div data-test-id="flyto" data-coordinates={origCoordinates}>
        <MapContext.Consumer>
          {(mapboxMap: MapboxMap) => {
            return (
              <MapFlyTo
                mapboxMap={mapboxMap}
                locOrBobjId={locationID}
                coordinates={origCoordinates as [number, number]}
                zoom={17}
              />
            );
          }}
        </MapContext.Consumer>
      </div>

      <div data-test-id="location-match-layers-outlet">
        {/* This outlet will be replaced with either the Map component for BobjPickerLayers 
            (if URL ends in 'match') or SelectedLocationPopup (if URL is just /locations/:id).
            Both components need LocationMatchLayer's location, so give it to these
            child components via useOutletContext */}
        <Outlet context={{ locRecord }} />
      </div>
    </div>
  );
};

LocationMatchLayers.displayName = 'LocationMatchLayers';
export default LocationMatchLayers;
