import * as React from 'react';
import { Outlet } from 'react-router-dom';

import ClusteredLocations from './ClusteredLocations';
import InvisibleLocations from './InvisibleLocations';
import useApiErrSnackbar from '../../../CommonComponents/Snackbar/useApiErrSnackbar';
import { useGetLocationsInfoWithFilterQuery } from '../../../../__generated__/graphql';
import useSliceLocations from '../../../../Hooks/useSliceLocations';

const ClusteredMapWrapper: React.FC = () => {
  const { data: allLocsDataWithFilter, error: errorAllLocsWithFilter } =
    useGetLocationsInfoWithFilterQuery({
      variables: {
        input: {
          matchCriteria: {
            isPendingMIB: false,
          },
        },
      },
    });

  const {
    locations: allLocsStats,
    error: errorAllLocsStats,
    loading: loadingAllLocsStats,
  } = useSliceLocations('ClusteredMapWrapper');

  const { enqueueApiErrSnackbar } = useApiErrSnackbar();

  React.useEffect(() => {
    if (errorAllLocsStats || errorAllLocsWithFilter) {
      enqueueApiErrSnackbar();
    }
  }, [enqueueApiErrSnackbar, errorAllLocsStats, errorAllLocsWithFilter]);

  if (errorAllLocsStats || errorAllLocsWithFilter) {
    return null;
  }

  const allLocsInfo = allLocsDataWithFilter?.locations ?? [];

  // Render even if useSliceLocations still loading, so that merely changing the slice# doesn't
  // completely reset ClusteredLocations and its subcomponents to initial state, which might
  // unexpectedly make the map pan/zoom to center the selected location. We need to preserve the
  // current map position if viewing the same location, so we don't override any manual positioning
  // that the user did, even if slice index changes.
  return (
    <>
      {allLocsInfo.length > 0 && <InvisibleLocations allLocations={allLocsInfo} />}
      <ClusteredLocations
        allLocations={allLocsStats?.length > 0 ? allLocsStats : []}
        locsAreLoading={loadingAllLocsStats}
      />
      {/* This outlet will render the UpdateButton for `/analysis' route.
          For '/analysis/detail/:id' or '/analysis/see-the-math/:id' routes, 
          it will render location-specific layers. */}
      <Outlet />
    </>
  );
};
ClusteredMapWrapper.displayName = 'ClusteredMapWrapper';
export default ClusteredMapWrapper;
