import * as React from 'react';

import { FilledInput, InputAdornment } from '@mui/material';

type PercentInputProps = {
  valuePercent: number;
  minPercent?: number;
  maxPercent?: number;
  onChangePercent: (result: { id: string; value: number }) => void;
  onMouseDownCallback?: () => void;
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  classes?: any;
  immediate?: boolean;
  readOnly?: boolean;
};

const PercentInput: React.FC<PercentInputProps> = ({
  valuePercent,
  minPercent = 1,
  maxPercent = 100,
  onChangePercent,
  id,
  classes,
  immediate = false,
  readOnly = false,
  onMouseDownCallback,
}) => {
  const [displayVal, setDisplayVal] = React.useState<string>(String(valuePercent));

  React.useEffect(() => {
    setDisplayVal(String(valuePercent));
  }, [valuePercent]);

  // When tabbing out / leaving focus / pressing enter key, autocorrect the user's input
  // to be between minPercent & maxPercent inclusive. Spaces/blanks become  max(0,minPercent)
  const adjustInput = React.useCallback(
    (value: string) => {
      let newBoundedVal = +value <= minPercent ? String(minPercent) : value;
      newBoundedVal = +newBoundedVal > maxPercent ? String(maxPercent) : newBoundedVal;
      setDisplayVal(newBoundedVal);
      onChangePercent({ id, value: +newBoundedVal });
    },
    [onChangePercent, minPercent, maxPercent, id],
  );

  const onBlur = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      adjustInput(e.target.value);
    },
    [adjustInput],
  );

  // The user's inputed value is not corrected WHILE the user is typing. That's in onBlur.
  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (immediate) {
        adjustInput(e.target.value);
      } else {
        setDisplayVal(e.target.value);
      }
    },
    [adjustInput, immediate],
  );

  const onFocus = React.useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    e.currentTarget.select();
  }, []);

  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') return;
      adjustInput((e.target as HTMLInputElement).value);
    },
    [adjustInput],
  );

  const onMouseDown = React.useCallback(
    () => onMouseDownCallback && onMouseDownCallback(),
    [onMouseDownCallback],
  );

  return (
    <FilledInput
      readOnly={readOnly}
      id={id}
      value={displayVal}
      type="number"
      inputProps={{ 'data-test-id': `PercentInput-${id}` }}
      endAdornment={
        <InputAdornment
          position="end"
          disablePointerEvents
          disableTypography
          classes={{ root: classes?.inputAdornedEnd }}
        >
          %
        </InputAdornment>
      }
      disableUnderline
      classes={classes && { root: classes.root, input: classes.input }}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      onMouseDown={onMouseDown}
    />
  );
};
PercentInput.displayName = 'PercentInput';
export default PercentInput;
