import * as React from 'react';
import { Button, CardActions, Theme } from '@mui/material';
import { Link as RouterLink, useOutletContext } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { LocRecord } from './locationMatchHelpers';
import { MibRequestStatus } from '../../../__generated__/graphql';

const useStyles = makeStyles((theme: Theme) => ({
  cardActions: {
    padding: theme.spacing(0, 2),
  },
}));

const ChooseBuildingButton: React.FC = () => {
  const { cardActions } = useStyles();
  const { t } = useTranslation();

  const { locRecord } = useOutletContext() as { locRecord: LocRecord };
  const locationID = locRecord?.audit?.locationID;

  const isPending = locRecord?.audit?.builtObjectRequestStatus === MibRequestStatus.Pending;
  if (locationID) {
    return (
      <CardActions className={cardActions}>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          component={RouterLink}
          disabled={isPending}
          to={`/locations/${locationID}/match?snap=true`}
          data-test-id="chooseBuildingButton"
        >
          {t('locationMatch:chooseBuildingButton')}
        </Button>
      </CardActions>
    );
  }
  return null;
};
ChooseBuildingButton.displayName = 'ChooseBuildingButton';
export default ChooseBuildingButton;
