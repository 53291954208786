import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

import {
  LIST_HIGHLIGHT,
  PURPLE,
  PURPLE_DARK_TRANSPARENT,
  WHITE,
} from '../../../../util/productGlobals';
import Icon from '../../../CommonComponents/Icon/Icon';
import { SliceHazardType } from '../../../../__generated__/graphql';
import { Lifeline } from '../../../../util/productEnums';

type LifelineTagProps = {
  caption: string;
  isVulnerable?: boolean;
  handleClick?: () => void;
  hazardType: string;
};

const useStyles = makeStyles(() => {
  return {
    root: {
      backgroundColor: (props: { isVulnerable: boolean }) =>
        props.isVulnerable ? PURPLE_DARK_TRANSPARENT : LIST_HIGHLIGHT,
      border: (props: { isVulnerable: boolean }) =>
        props.isVulnerable ? `0.5px solid ${PURPLE}` : `0.5px solid ${WHITE}`,
      display: 'inline-flex',
      alignItems: 'center',
      backdropFilter: 'blur(4px)',
    },
  };
});

const LifelineTag: React.FC<LifelineTagProps> = ({
  caption,
  isVulnerable,
  handleClick,
  hazardType,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ isVulnerable });

  return (
    <div
      className={`u-clickable u-radius--100 u-marginright--32 u-paddingx--8 u-paddingy--4 ${classes.root}`}
      data-test-id="LifelineTag-Container"
      onClick={handleClick}
    >
      <Icon
        name={`Lifeline-${caption}${
          isVulnerable ? '-down' : ''
        } u-clickable u-marginright--4 u-no-transition`}
      />
      <Typography
        className="u-clickable f-lightgrey2 u-overflow-ellipsis"
        variant="caption"
        data-test-id="LifelineTag-Caption"
      >
        {caption === Lifeline.BRIDGE && hazardType === SliceHazardType.Flood
          ? t('lifeline:bridge*')
          : t(`lifeline:${caption}`)}
      </Typography>
    </div>
  );
};

LifelineTag.defaultProps = {
  isVulnerable: false,
};
LifelineTag.displayName = 'LifelineTag';
export default LifelineTag;
