import * as React from 'react';
import { OptionsObject, SnackbarKey, useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { addressToEditAddressInput, getOneLineAddress } from '../../../util/addresses/addressUtils';
import {
  EditLocationInput,
  EditLocationMutation,
  GetLocationsFileDetailsDocument,
  GetLocationsInfoWithFilterDocument,
  GetLocationsWithFilterDocument,
  GetOriginalLocationsFileDocument,
  GetOriginalLocationsFileRecordDocument,
  useEditLocationMutation,
  useGetLocationsFileDetailsQuery,
  useGetOriginalLocationsFileRecordQuery,
  useGetSliceIdxQuery,
} from '../../../__generated__/graphql';
import { AMP_EVENT_EDIT_LOCATION } from '../../../plugins/amplitudeevents';
import { App } from '../../../PlanningApp/AppConfig';

import cache from '../../../PlanningApp/client/cache';
import DismissSnackbarButton from '../../CommonComponents/Snackbar/DismissSnackbarButton';
import EditLocationModalContent from './EditLocationModalContent';
import { LocInfo } from './locationMatchHelpers';
import { sendAmplitudeData } from '../../../plugins/amplitude';
import StyledSnackbarContent from '../../CommonComponents/Snackbar/StyledSnackbarContent';
import useApiErrSnackbar from '../../CommonComponents/Snackbar/useApiErrSnackbar';

const editLocSnackbarEnqueueOptions: OptionsObject = {
  persist: true,
  key: 'editLocationError',
  action: (id: SnackbarKey) => <DismissSnackbarButton id={id} />,
};

const EditLocationModal: React.FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();
  const navigate = useNavigate();
  const params = useParams();
  const id = params?.id;

  const {
    data: { planView },
  } = useGetSliceIdxQuery();

  const {
    data: locRecordData,
    error: locRecordError,
    loading: locRecordLoading,
  } = useGetOriginalLocationsFileRecordQuery({
    variables: { locationID: id },
    skip: !id,
  });
  const { data: userData } = useGetLocationsFileDetailsQuery();

  const locRecord = locRecordData?.user?.productSettings?.originalFile?.[0];
  const [editLocation, { data: editLocData, error: editLocError, loading: editLocLoading }] =
    useEditLocationMutation({
      refetchQueries: [
        // Update the upload file record for this location to reflect the new matching builtObject
        { query: GetOriginalLocationsFileRecordDocument, variables: { locationID: id } },
        // Need to update the #acceptedLocations & #errorLocations
        { query: GetOriginalLocationsFileDocument },
        { query: GetLocationsFileDetailsDocument },
        {
          query: GetLocationsWithFilterDocument,
          variables: {
            sliceIdx: planView?.sliceIdx,
            input: {
              matchCriteria: {
                isPendingMIB: false,
              },
            },
          },
        },
        {
          query: GetLocationsInfoWithFilterDocument,
          variables: {
            input: {
              matchCriteria: {
                isPendingMIB: false,
              },
            },
          },
        },
      ],
      // For amplitude event tracking, we need to get new builtObject id
      // returned by refetched useGetOriginalLocationsFileRecordQuery
      // awaitRefetchQueries: true,
      onCompleted: (data: EditLocationMutation) => {
        App.debug('[EditLocationModal - onCompleted]: editLocation mutation success. data: ', data);
        if (userData) {
          const fileId = userData.user.productSettings.locationFile.id;
          const userId = userData.user.personalInfo.contact.email;
          sendAmplitudeData(AMP_EVENT_EDIT_LOCATION, {
            userId,
            fileId,
            locationId: id,
            // Need to confirm if this builtObjectId is required for tracking
            // If yes, we should consider again about cache.evict and cache.gc
            // Because with that, we can't set awaitRefetchQueries to true
            // for getting updated builtObjectID here.
            // builtObjectId: locRecord?.audit?.builtObjectID,
          });
        }
        // Editting the address might change the associated builtObject.  So, all of this location's
        // resilience stats (for each slice), SYW stats, lifeline stats, etc. might change.
        // It's easiest to just evict the LocationObject from the cache and then garbage collect
        // to get rid of stale resilience stats and lifeline stats objects.
        cache.evict({ id: cache.identify(data.editLocation) });
        cache.gc();
        navigate(-1);
      },
      onError: (err: ApolloError) => {
        if (!editLocData?.editLocation) {
          // The mutation might throw an error even though it succeeded, due to the cache eviction
          // of the mutated locationObject that the mutation returns as data. It's OK. We don't need
          // that data in this component and other components will re-query for the locaiton that is
          // not in cache.
          App.error('[EditLocationModal - onError]: editLocationMutation failed: ', err);
        }
      },
    });

  const onClickEditLocation = React.useCallback(
    (submittedLocInfo: LocInfo) => {
      const newLocationInfo: EditLocationInput = {
        id,
        visibility: true,
        locationAddress: {
          ...submittedLocInfo.addressInput,
          formattedAddress: getOneLineAddress(submittedLocInfo.addressInput),
        },
        name: submittedLocInfo.name,
        type: submittedLocInfo.type,
        group: submittedLocInfo.group,
      };
      App.debug('[EditLocationModal] - onClickEditLocation called with: ', newLocationInfo);

      editLocation({ variables: { input: newLocationInfo } });
    },
    [editLocation, id],
  );

  const maybeAddress = locRecord?.audit?.locationMeta?.geocodedAddress?.formattedAddress;
  React.useEffect(() => {
    const err = locRecordError || editLocError;
    if (err) {
      let errMsg;

      if (locRecordError) errMsg = 'Get OriginalLocationsFileRecord query error';
      if (editLocError) errMsg = 'EditLocation mutation error';
      App.error(`[EditLocationModal] ${errMsg ?? 'error'}: `, err);

      if (locRecordError) {
        // Can't query the location or the entitlements, so probably a server or network issue.
        enqueueApiErrSnackbar();
      }
      if (editLocError) {
        // EditLocation error, probably caused by user entering invalid/poorly formatted address
        let errorDescription: string;

        switch (editLocError.message) {
          case 'inconsistent zip code for resolved address':
            errorDescription = t('locationMatch:editFailed:zipcode');
            break;
          case 'unresolved location':
            // no commercial building near this address
            errorDescription = t('locationMatch:editFailed:noCommercialBldg');
            break;
          default:
            errorDescription = t('locationMatch:editFailed:generic');
            if (App.config.features.enabledebug) {
              errorDescription = `${errorDescription} [DEBUG ONLY: ${editLocError.message}]`;
            }
            break;
        }
        enqueueSnackbar(
          <StyledSnackbarContent
            title={t('locationMatch:editFailed:title')}
            description={errorDescription}
          />,
          editLocSnackbarEnqueueOptions,
        );
      }
    }
  }, [enqueueSnackbar, enqueueApiErrSnackbar, t, locRecordError, editLocError, maybeAddress]);

  if (!locRecord) {
    return null;
  }

  const { address, geocodedAddress, name, group, type } = locRecord.audit.locationMeta;

  // sometimes certain fields in address are null so we fill them from geocodedAddress
  if (geocodedAddress) {
    address.city = address.city ?? geocodedAddress.city;
    address.country = address.country ?? geocodedAddress.country;
    address.countryCode = address.countryCode ?? geocodedAddress.countryCode;
    address.county = address.county ?? geocodedAddress.county;
    address.formattedAddress = address.formattedAddress ?? geocodedAddress.formattedAddress;
    address.houseNumber = address.houseNumber ?? geocodedAddress.houseNumber;
    address.postCode = address.postCode ?? geocodedAddress.postCode;
    address.state = address.state ?? geocodedAddress.state;
    address.stateCode = address.stateCode ?? geocodedAddress.stateCode;
    address.stateDistrict = address.stateDistrict ?? geocodedAddress.stateDistrict;
    address.street = address.street ?? geocodedAddress.street;
    address.suburb = address.suburb ?? geocodedAddress.suburb;
  }

  const addressInput = addressToEditAddressInput(address);
  const locInfo: LocInfo = { id, name, group, type, addressInput };

  return (
    <EditLocationModalContent
      locInfo={locInfo}
      onClickSubmit={onClickEditLocation}
      submitting={editLocLoading || locRecordLoading}
    />
  );
};
EditLocationModal.displayName = 'EditLocationModal';
export default EditLocationModal;
