import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LocationProps } from 'onec-types';
import { useSnackbar } from 'notistack';

import {
  GetLocationsWithFilterQuery,
  useGetHoveredLocationQuery,
  useUpdateHoveredLocationMutation,
} from '../../../../__generated__/graphql';
import {
  FINANCE_HOME_PATH,
  HOME_PATH,
  NOT_VULNERABLE_SOURCE_ID,
  VULNERABLE_SOURCE_ID,
} from '../../../../util/productGlobals';
import UnknownErrSnackbar, {
  unknownErrEnqueueOptions,
} from '../../../CommonComponents/Snackbar/UnknownErrSnackbar';
import {
  useCalcLocsVulnerabilityScores,
  useFilterLocations,
} from '../../../../Hooks/useCalcAndFilterLocs';
import ActiveLocBobjLayers from './ActiveLocBobjLayers';
import { App } from '../../../../PlanningApp/AppConfig';
import ClusterLayers from './ClusterLayers';
import ClusterSources from './ClusterSources';
import useSetPreviousMapBounds from '../../../../Hooks/useSetPreviousMapBounds';
import useHasFinanceEntitlement from '../../../../Hooks/useHasFinanceEntitlement';

const ClusteredLocations: React.FC<{
  allLocations: GetLocationsWithFilterQuery['locations'];
  locsAreLoading: boolean;
}> = ({ allLocations, locsAreLoading }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;
  const navigate = useNavigate();
  const { id: urlActiveId } = useParams();

  const setMapBounds = useSetPreviousMapBounds();

  const onClickPoint = React.useCallback(
    (location?: LocationProps) => {
      if (location && location.id) {
        setMapBounds();
        navigate(`${isFinanceEntitled ? FINANCE_HOME_PATH : HOME_PATH}/detail/${location.id}`);
      } else {
        navigate(isFinanceEntitled ? FINANCE_HOME_PATH : HOME_PATH);
      }
    },
    [isFinanceEntitled, navigate, setMapBounds],
  );

  const {
    data: {
      hoveredLocation: { hoveredLocationId },
    },
  } = useGetHoveredLocationQuery();

  const [updateHoveredLocation] = useUpdateHoveredLocationMutation();
  const onHoverPoint = React.useCallback(
    (locationId: string) => {
      updateHoveredLocation({ variables: { locationId } });
    },
    [updateHoveredLocation],
  );

  const {
    locsWithScores,
    error: calcError,
    loading: calcLoading,
  } = useCalcLocsVulnerabilityScores(allLocations, 'ClusteredLocations all');

  React.useEffect(() => {
    if (calcError) {
      enqueueSnackbar(<UnknownErrSnackbar />, unknownErrEnqueueOptions);
    }
  }, [enqueueSnackbar, calcError]);

  // Note that in Mapbox, clustering happens at the source level. If you want
  // to filter the data in the clusters, you have to filter the GeoJSON itself
  // BEFORE creating the clustered Source (can't use the filter param to <Source> to do this)
  const { filteredLocs, activeLocation } = useFilterLocations(locsWithScores, urlActiveId);
  if (calcError) {
    App.error('[ClusteredLocations] useCalcVulnerabilityScores: ', calcError);
    return null;
  }
  if (calcLoading) {
    App.debug('[ClusteredLocations - useCalcLocsVulnerabilityScores] loading');
    return null;
  }

  if (!filteredLocs) {
    App.error('[ClusteredLocations - useFilterLocations] filteredLocs is null');
    return null;
  }

  // Always render even if still loading locations or calculating vulnerability scores,
  // so that merely changing slice# doesn't completely reset ClusterLayers, ActiveLocBobjLayers,
  // and their subcomponents to initial state, because this might unexpectedly make
  // the map pan/zoom to center the selected location. We want to preserve how the
  // user manually positioned the map unless selecting a different location/builtObject.
  return (
    <>
      <ClusterSources filteredLocsWithScores={filteredLocs} />
      <ClusterLayers
        sourceId={NOT_VULNERABLE_SOURCE_ID}
        vulnerable={false}
        hoveredLocationId={hoveredLocationId}
        onClickPoint={onClickPoint}
        onHoverPoint={onHoverPoint}
      />
      <ClusterLayers
        sourceId={VULNERABLE_SOURCE_ID}
        vulnerable
        hoveredLocationId={hoveredLocationId}
        onClickPoint={onClickPoint}
        onHoverPoint={onHoverPoint}
      />
      {/* Always keep the ActiveLocBobjLayers (shows the selected location or builtObject), 
      // even if there is no active point, so that it can always be on top of the Arcs
      // and other lifelines and without having to re-render the source */}
      {urlActiveId !== 'notFound' && (
        <ActiveLocBobjLayers
          activeLocationWithScore={activeLocation}
          locsAreLoading={locsAreLoading}
        />
      )}
    </>
  );
};

ClusteredLocations.displayName = 'ClusteredLocations';
export default ClusteredLocations;
