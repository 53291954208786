import * as React from 'react';
import { ApolloError } from '@apollo/client';

import { SliceHazardType, useGetUserEntitlementsHazardsQuery } from '../__generated__/graphql';
import { App } from '../PlanningApp/AppConfig';
import { HAZARD_FILTER_ORDER } from '../util/productGlobals';
import { convertSliceHazardType } from '../util/productUtils';

type EntitlementsResult = {
  error: ApolloError;
  loading: boolean;
  data: SliceHazardType[];
};
/**
 * Function to query the entitlements and also to be used
 * to evolve into more distinct conditions for querying
 * @param ent Internal Enum for entitlements
 * @returns Errors, Domino entitlements, Specific logic per entitlement type
 */
function useHazardEntitlements(): EntitlementsResult {
  const { data, error, loading } = useGetUserEntitlementsHazardsQuery();

  if (error) {
    App.error(`[useHazardEntitlements] Error: ${error.message}`);
  }

  const entitledAllHazards = data?.user?.entitlements?.hasHazardsForProduct;

  return React.useMemo(() => {
    if (error) return { error, data: null, loading };

    const entitledHazards: SliceHazardType[] = [];
    const hazardList: SliceHazardType[] = entitledAllHazards?.map(({ hazard }) =>
      convertSliceHazardType(hazard),
    );

    for (const hazard of HAZARD_FILTER_ORDER) {
      if ((hazardList ?? []).includes(hazard)) entitledHazards.push(hazard);
    }

    return {
      error,
      data: entitledHazards,
      loading,
    };
  }, [error, loading, entitledAllHazards]);
}

export default useHazardEntitlements;
