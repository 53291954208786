import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { LIST_HIGHLIGHT } from '../../../../../util/productGlobals';
import { UserRole } from '../../../../../__generated__/graphql';

export const ROLE_STRING_MAP = {
  [UserRole.Admin]: 'administrator',
  [UserRole.Contributor]: 'user',
  [UserRole.Viewer]: 'viewer',
};

type UserRoleTagProps = {
  userRole?: UserRole;
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      backgroundColor: LIST_HIGHLIGHT,
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      borderRadius: '100px',
      width: 'fit-content',
    },
  };
});

const UserRoleTag: React.FC<UserRoleTagProps> = ({ userRole }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.root} data-test-id="UserRoleTag-Container">
      <Typography variant="caption" data-test-id="UserRoleTag-Caption">
        {userRole
          ? t(`settings:sections:userManagement:allUsers:permission:${ROLE_STRING_MAP[userRole]}`)
          : t('nA')}
      </Typography>
    </div>
  );
};

UserRoleTag.displayName = 'UserRoleTag';
export default UserRoleTag;
