import * as React from 'react';
import { Map as MapboxMap } from 'mapbox-gl';

import { DETAIL_VIEW_ZOOM, SIDE_PANEL_WIDTH } from '../../../../util/productGlobals';

export const flyToDetail: (
  mapboxMap: MapboxMap,
  coordinates: [number, number],
  zoom?: number,
) => void = (mapboxMap, coordinates, zoom = DETAIL_VIEW_ZOOM) => {
  mapboxMap.flyTo({
    center: coordinates, // as LngLatLike,
    offset: [-SIDE_PANEL_WIDTH / 2, 0],
    zoom,
    essential: true, // flyto animation is considered essential with respect to prefers-reduced-motion
  });
};

const MapFlyToFC: React.FC<{
  mapboxMap: MapboxMap;
  coordinates: [number, number];
  locOrBobjId?: string;
  zoom?: number;
}> = ({ mapboxMap, locOrBobjId, coordinates, zoom = DETAIL_VIEW_ZOOM }) => {
  const [currentLocOrBobjId, setCurrentLocOrBobjId] = React.useState(null);
  const [prevCoordinates, setPrevCoordinates] = React.useState([null, null]);

  React.useEffect(() => {
    if (
      mapboxMap &&
      coordinates?.length === 2 &&
      locOrBobjId &&
      (locOrBobjId !== currentLocOrBobjId ||
        coordinates[0] !== prevCoordinates[0] ||
        coordinates[1] !== prevCoordinates[1])
    ) {
      setCurrentLocOrBobjId(locOrBobjId);
      setPrevCoordinates(coordinates);
      flyToDetail(mapboxMap, coordinates, zoom);
    }
  }, [locOrBobjId, currentLocOrBobjId, mapboxMap, coordinates, zoom, prevCoordinates]);

  return null;
};
const MapFlyTo = React.memo(MapFlyToFC);
MapFlyTo.displayName = 'MapFlyTo';
export default MapFlyTo;
