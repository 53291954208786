import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

import { PURPLE, PURPLE_DARK_TRANSPARENT, WHITE } from '../../../../util/productGlobals';
import {
  ResilienceThresholds,
  useGetSliceIdxQuery,
  useGetThresholdsQuery,
} from '../../../../__generated__/graphql';
import { App } from '../../../../PlanningApp/AppConfig';
import { calcVulScoreFromResStats } from '../../../../util/vulnerability';
import NoDataChip from '../Common/NoDataChip';
import useApiErrSnackbar from '../../../CommonComponents/Snackbar/useApiErrSnackbar';
import useGetResStats from '../../../../Hooks/useGetResStats';
import useLifelineEntitlements from '../../../../Hooks/useLifelineEntitlements';

type VulnerableChipProps = {
  id: string;
};

type VulnerableChipWrapperProps = {
  isLocation: boolean;
  builtObjectID: string;
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: PURPLE_DARK_TRANSPARENT,
    border: `1px solid ${PURPLE}`,
    backdropFilter: 'blur(4px)',
  },
  wrapper: {
    width: 16,
    height: 16,
    marginRight: 6,
    position: 'relative',
  },
  outer: {
    border: `1px solid ${PURPLE}`,
    position: 'absolute',
    boxSizing: 'border-box',
    borderRadius: '50%',
    animation: `$vulnerableIconEffect 800ms infinite`,
    backgroundColor: 'transparent',
    width: 8.5,
    height: 8.5,
  },
  '@keyframes vulnerableIconEffect': {
    '0%': {
      backgroundColor: 'transparent',
      width: 8.5,
      height: 8.5,
    },
    '100%': {
      width: 16.5,
      height: 16.5,
      backgroundColor: 'rgba(255, 98, 98, 0.2)',
    },
  },
  vulnerableChipIcon: {
    width: 8,
    height: 8,
    backgroundColor: WHITE,
    boxShadow: '0px 0px 20px 2px rgba(255, 67, 214, 0.75)',
    borderRadius: '50%',
    position: 'absolute',
  },
}));

const VulnerableChip: React.FC<VulnerableChipProps> = ({ id }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div
      className={`${classes.root} u-radius--100 u-paddingx--8 u-paddingy--6`}
      data-test-id={`chip-${id}-vulnerable-status`}
    >
      <div
        className={`${classes.wrapper} o-flex-center-container`}
        data-test-id={`chip-${id}-vulnerable-status-animate-icon`}
      >
        <div className={classes.outer} />
        <div className={classes.vulnerableChipIcon} />
      </div>
      <Typography className="f-lightgrey2 u-overflow-ellipsis u-marginleft--8" variant="caption">
        {t('detailView:vulnerableLocation')}
      </Typography>
    </div>
  );
};

const VulnerableChipWrapper: React.FC<VulnerableChipWrapperProps> = ({
  isLocation,
  builtObjectID: id,
}) => {
  const entitlements = useLifelineEntitlements();
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();

  const {
    data: {
      planView: { sliceIdx: activeSlice },
    },
  } = useGetSliceIdxQuery();

  const {
    data: userData,
    error: thresholdsError,
    loading: thresholdsLoading,
  } = useGetThresholdsQuery();
  const thresholds: ResilienceThresholds = userData?.user?.productSettings?.thresholds;

  const {
    resStats,
    loading: resStatsLoading,
    error: resStatsErr,
  } = useGetResStats(id, [activeSlice], true);

  React.useEffect(() => {
    if (thresholdsError || resStatsErr) {
      if (thresholdsError) {
        enqueueApiErrSnackbar();
        App.error('[VulnerableChip] GetThresholdsQuery error: ', thresholdsError);
      }
      if (resStatsErr) {
        App.debug('[VulnerableChip] useGetResStats error: ', resStatsErr);
        // TODO - Assume that this is a "locationIdNotFound" error, which puts up a message in the
        // sidebar and thus does not need a snackbar.
        // One Day, when we know what types of errors the backend might return, put up snackbars
        // for other types of errors.
      }
    }
  }, [enqueueApiErrSnackbar, isLocation, thresholdsError, resStatsErr]);

  if (resStatsErr || thresholdsError) {
    return <NoDataChip />;
  }
  if (thresholdsLoading || resStatsLoading || !resStats) {
    return null;
  }

  const vulnerabilityScore = calcVulScoreFromResStats(resStats[0], thresholds, entitlements);

  const chipTestId = `${isLocation ? 'location' : 'builtObject'}:${id}`;
  if (vulnerabilityScore > 0) {
    return <VulnerableChip id={chipTestId} />;
  }

  return <div data-test-id={`nochip-${chipTestId}-not-vulnerable`} />;
};

VulnerableChipWrapper.displayName = 'VulnerableChipWrapper';
export default VulnerableChipWrapper;
