import * as React from 'react';

import { AuthContext, AuthInfo, CsrfContext } from './Authorizer';
import {
  IframeBadLoginErr,
  IframeNonceErr,
  PropRequiredErr,
  RequiredScopeNotGrantedErr,
  UserinfoErr,
} from './AuthError';
import { Admin } from './admin'; // get keycloak admin toolbox
import { App } from '../AppConfig';
import { Authable } from './Authable';
import LoginErrorPage from './LoginErrorPage';

export interface LoginStyleProps {
  height: string;
  width: string;
  position: string;
  top: string;
  left: string;
  zIndex: number;
  border: string;
}

export interface LoginProps {
  authcontext: AuthContext;
  className: string;
  title: string;
  style: LoginStyleProps;
  onChange: (csrfCtx: string | CsrfContext) => void;
}

export class Login extends Authable {
  admin: Admin;

  static defaultProps: LoginProps = {
    authcontext: null,
    onChange: null,
    title: 'signIn',
    className: '',
    style: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: '0px',
      left: '0px',
      zIndex: 5,
      border: 'none',
    },
  };

  static displayName = 'Login';

  constructor(props: LoginProps) {
    super(props);
    this.state = {
      nonce: Login.getNonce(),
    };

    if (this.props.onChange) {
      if (!(typeof this.props.onChange === 'function')) {
        throw new PropRequiredErr('onChange={function(Object)}');
      }
    }

    this.admin = new Admin(this.props.authcontext.config);
    this.admin.setOnCsrfChange(this.props.authcontext.onCsrfChange);

    // this is a client initiated cookie telling
    // the API gateway where to redirect after login has completed
    this.admin.clearClientCookie();

    this.fail = this.fail.bind(this);
  }

  // getNonce is a helper function to generate a random numeric string.
  // Verifying nonce ensures that the iframe - parent communication channel (js messaging)
  // has not been tampered with.
  static getNonce() {
    return Admin.getNonce();
  }

  componentDidMount(): void {
    window.addEventListener('message', this.handleLoginFrame, false);
  }

  componentWillUnmount(): void {
    window.removeEventListener('message', this.handleLoginFrame);
  }

  handleLoginFrame = (evt: MessageEvent) => {
    if (!evt.data.signed) {
      // bubbled message from another frame: skip it
      return null;
    }

    if (evt.data.nonce === this.state.nonce && !evt.data.error) {
      // ok, end up the handshake:
      // check nonce from child iframe
      // so we have an all-round state check, covering the iframe child-parent communication channel
      return this.admin
        .userinfo()
        .then((userinfo) => {
          this.admin.clearClientCookie();
          const newAuthinfo = new AuthInfo({
            userinfo,
            isLogged: true,
            // add possible impersonator
            // TODO jake has not been tested
            impersonator: this.props.authcontext.getAuthInfo().impersonator,
          });
          this.setState({ failed: false, error: null });
          return newAuthinfo;
        })
        .then((newAuthinfo) => {
          // notify external state handler of the new userinfo
          this.props.authcontext.onAuthInfoChange(newAuthinfo);
          return newAuthinfo;
        })
        .then((newAuthinfo) => {
          // optional callback
          if (this.props.onChange) {
            this.props.onChange(newAuthinfo);
          }
        })
        .catch((e) => {
          return this.fail(new UserinfoErr(e));
        });
    }

    if (evt.data.error) {
      return this.fail(new IframeNonceErr(evt.data.error));
    }

    return this.fail(new IframeBadLoginErr('while trying to login'));
  };

  handleIframeLoad = (evt: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
    const iframe = evt.target as HTMLIFrameElement;
    const json = JSON.parse(iframe.contentWindow.document.body.textContent);

    if (json?.error) {
      const LoginErr = json.error.includes('required scope')
        ? RequiredScopeNotGrantedErr
        : IframeBadLoginErr;
      const error = new LoginErr(json.error);

      this.setState({ error });
      App.error(error.message);
    }
  };

  // render displays the login iframe
  render(): React.ReactNode {
    // does not return anything but a possible exception
    super.render();
    if (this.failed()) {
      return null;
    }

    const { nonce, error } = this.state;
    const { authcontext, className, style, title } = this.props;

    // NOTE(fredbi) keycloak gatekeeper does not currently check the value of the state
    // but nonetheless this value must be non-empty. Hence the 'state=none' value.
    //
    // This is a todo in keycloak-gatekeeper.
    // For the record, the state SHOULD be checked, with some secret random value,
    // to ensure that the sequence of redirects has not been tampered with.
    // At this moment, gatekeeper checks session_state param instead.
    //
    let loginWithState = `${authcontext.config.get('loginURL')}?state=none`;

    // base on this url param we inject the demo flow into iframe then into gatekeeper
    // later on we will handle this into keycloak auth flow
    if (window.location.href.includes('demo=true')) {
      loginWithState = `${loginWithState}&demo=true`;
    }

    // now we need to pass this cookie to the login url in the below iframe.
    // The domain is the api gateway.
    // This cookie is sent exclusively to the gateway's callback entrypoint, hence the path
    // add here a nonce parameter (a random string) to be checked when called back
    this.admin.makeClientCookie(nonce);

    // We restrict the iframe with sandboxing, but with extreme care:
    // - scripts must be allowed (a js runs to signal the parent to close)
    // - form must be allowed
    // NOTE: this component from react-iframe package
    // exhibits a warning in strict mode because of deprecated refs
    // TODO: best to make our own version of this
    return (
      <div style={style} data-test-id="Login">
        {error ? (
          <LoginErrorPage error={error} />
        ) : (
          <iframe
            id="login-iframe"
            className={className}
            src={loginWithState}
            sandbox="allow-forms allow-same-origin allow-scripts"
            frameBorder="none"
            style={style}
            title={title}
            onLoad={this.handleIframeLoad}
          />
        )}
      </div>
    );
  }
}
