const ALL_OCCUPANCY_TYPES = [
  {
    onecCode: 10000,
    onecCodeDescr: 'RESIDENTIAL',
    isSearchable: false,
    generalUse: 'RESIDENTIAL',
    toOnecBosV1: 'MFD',
    epDsOccupancy: '',
  },
  {
    onecCode: 10100,
    onecCodeDescr: 'SFD',
    isSearchable: false,
    generalUse: 'RESIDENTIAL',
    toOnecBosV1: 'SFD',
    epDsOccupancy: '',
  },
  {
    onecCode: 10200,
    onecCodeDescr: 'MFD',
    isSearchable: true,
    generalUse: 'RESIDENTIAL',
    toOnecBosV1: 'MFD',
    epDsOccupancy: 'multi_unit_residential',
  },
  {
    onecCode: 10201,
    onecCodeDescr: 'MFD, SMALL',
    isSearchable: false,
    generalUse: 'RESIDENTIAL',
    toOnecBosV1: 'MFD',
    epDsOccupancy: '',
  },
  {
    onecCode: 10202,
    onecCodeDescr: 'MFD, LARGE',
    isSearchable: true,
    generalUse: 'RESIDENTIAL',
    toOnecBosV1: 'MFD',
    epDsOccupancy: 'multi_unit_residential',
  },
  {
    onecCode: 10203,
    onecCodeDescr: 'CONDOMINUM',
    isSearchable: true,
    generalUse: 'RESIDENTIAL',
    toOnecBosV1: 'MFD',
    epDsOccupancy: 'multi_unit_residential',
  },
  {
    onecCode: 10204,
    onecCodeDescr: 'APARTMENT',
    isSearchable: true,
    generalUse: 'RESIDENTIAL',
    toOnecBosV1: 'MFD',
    epDsOccupancy: 'multi_unit_residential',
  },
  {
    onecCode: 10300,
    onecCodeDescr: 'GROUP INSTITUTIONAL HOUSING EXCLUDE HEALTH RELATED',
    isSearchable: true,
    generalUse: 'RESIDENTIAL',
    toOnecBosV1: 'MFD',
    epDsOccupancy: 'multi_unit_residential',
  },
  {
    onecCode: 10400,
    onecCodeDescr: 'PLANNED UNIT DEVELOPMENT',
    isSearchable: false,
    generalUse: 'RESIDENTIAL',
    toOnecBosV1: 'MFD',
    epDsOccupancy: '',
  },
  {
    onecCode: 10500,
    onecCodeDescr: 'MOBILE HOMES AND OTHER MODULAR AND TEMPORARY HOMES',
    isSearchable: false,
    generalUse: 'RESIDENTIAL',
    toOnecBosV1: 'SFD',
    epDsOccupancy: '',
  },
  {
    onecCode: 20000,
    onecCodeDescr: 'COMMERCIAL',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'office',
  },
  {
    onecCode: 20100,
    onecCodeDescr: 'WHOLESALE TRADE',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'retail',
  },
  {
    onecCode: 20101,
    onecCodeDescr: 'WHOLESALE TRADE, DURABLE GOODS',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'retail',
  },
  {
    onecCode: 20102,
    onecCodeDescr: 'WHOLESALE TRADE, NON DURABLE GOODS',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'retail',
  },
  {
    onecCode: 20200,
    onecCodeDescr: 'RETAIL TRADE',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'retail',
  },
  {
    onecCode: 20201,
    onecCodeDescr: 'FOOD, BEVERAGE, AND DRUG RETAILERS',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'retail',
  },
  {
    onecCode: 20202,
    onecCodeDescr: 'GASOLINE STATIONS AND FUEL DEALERS',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'travel_tourism',
  },
  {
    onecCode: 20203,
    onecCodeDescr: 'ALL OTHER RETAILERS',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'retail',
  },
  {
    onecCode: 20204,
    onecCodeDescr: 'MIX USED RETAIL BUILDING',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'retail',
  },
  {
    onecCode: 20300,
    onecCodeDescr: 'OFFICE BUILDING',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'office',
  },
  {
    onecCode: 20301,
    onecCodeDescr: 'INFORMATION, COMMUNICATION, AND INTERNET',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'office',
  },
  {
    onecCode: 20302,
    onecCodeDescr: 'FINANCE, INSURANCE, AND REAL ESTATE RELATED BUSINESS',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'office',
  },
  {
    onecCode: 20303,
    onecCodeDescr: 'BANKS, SECURITIES AND COMMODITY EXCHANGES',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'office',
  },
  {
    onecCode: 20304,
    onecCodeDescr: 'PROFESSIONAL, SCIENTIFIC, TECHNICAL SERVICES',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'office',
  },
  {
    onecCode: 20305,
    onecCodeDescr: 'MANAGEMENT OF COMPANIES AND ENTERPRISES',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'office',
  },
  {
    onecCode: 20306,
    onecCodeDescr: 'ADMINISTRATIVE AND SUPPORT SERVICES',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'office',
  },
  {
    onecCode: 20307,
    onecCodeDescr: 'MIX USED OFFICE BUILDING',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'office',
  },
  {
    onecCode: 20600,
    onecCodeDescr: 'WASTE MANAGEMENT AND REMEDIATION SERVICES',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'office',
  },
  {
    onecCode: 20700,
    onecCodeDescr: 'HEALTH CARE, HOSPITALS, AND SOCIAL ASSISTANCE',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'health_services',
  },
  {
    onecCode: 20701,
    onecCodeDescr: 'HOSPITALS',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'health_services',
  },
  {
    onecCode: 20702,
    onecCodeDescr: 'AMBULATORY HEALTHCARE SERVICES',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'health_services',
  },
  {
    onecCode: 20703,
    onecCodeDescr: 'NURSING, RESIDENTIAL CARE FACILITIES',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'health_services',
  },
  {
    onecCode: 20704,
    onecCodeDescr: 'SOCIAL ASSISTANCE, TEMPORARY SHELTERS, AND OTHER RELIEF SERVICES',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'health_services',
  },
  {
    onecCode: 20800,
    onecCodeDescr: 'ARTS, ENTERTAINMENT, AND RECREATION',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'entertainment',
  },
  {
    onecCode: 20801,
    onecCodeDescr: 'PERFORMING ARTS, SPECTATOR SPORTS, AND RELATED ASSETS',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'entertainment',
  },
  {
    onecCode: 20802,
    onecCodeDescr: 'AMUSEMENT, GAMBLING, AND RECREATION ASSETS AND SPACES',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'entertainment',
  },
  {
    onecCode: 20803,
    onecCodeDescr: 'MUSEUMS, HISTORICAL SITES, AND SIMILAR INSTITUTIONS',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'entertainment',
  },
  {
    onecCode: 20900,
    onecCodeDescr: 'ACCOMMODATION AND FOOD SERVICES',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'travel_tourism',
  },
  {
    onecCode: 20901,
    onecCodeDescr: 'ACCOMMODATION, HOTEL',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'travel_tourism',
  },
  {
    onecCode: 20902,
    onecCodeDescr: 'ACCOMMODATION, OTHERS',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'travel_tourism',
  },
  {
    onecCode: 20903,
    onecCodeDescr: 'FOOD SERVICES AND DRINKING PLACES',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'retail',
  },
  {
    onecCode: 21000,
    onecCodeDescr: 'OTHER COMMERCIAL SERVICES',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'retail',
  },
  {
    onecCode: 21001,
    onecCodeDescr: 'REPAIR AND MAINTANENCE',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 21002,
    onecCodeDescr: 'PERSONAL AND LAUNDRY SERVICES',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'retail',
  },
  {
    onecCode: 21003,
    onecCodeDescr: 'PARKING GARAGE',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'travel_tourism',
  },
  {
    onecCode: 21004,
    onecCodeDescr: 'DEATH CARE SERVICES',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'health_services',
  },
  {
    onecCode: 30000,
    onecCodeDescr: 'INDUSTRIAL',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 30100,
    onecCodeDescr: 'AGRICULTURE, FORESTRY, FISHING AND HUNTING',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'other',
  },
  {
    onecCode: 30101,
    onecCodeDescr: 'CROP PRODUCTION',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'other',
  },
  {
    onecCode: 30102,
    onecCodeDescr: 'ANIMAL PRODUCTION AND AQUACULTURE',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'other',
  },
  {
    onecCode: 30103,
    onecCodeDescr: 'FORESTRY AND LOGGING',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'other',
  },
  {
    onecCode: 30104,
    onecCodeDescr: 'FISHING AND HUNTING',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'other',
  },
  {
    onecCode: 30105,
    onecCodeDescr: 'SUPPORT ACTIVITIES FOR AGRICULTURE, FORESTRY, FISHING AND HUNTING',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'other',
  },
  {
    onecCode: 30200,
    onecCodeDescr: 'MINING, QUARRYING, AND OIL AND GAS EXTRACTION',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 30201,
    onecCodeDescr: 'OIL AND GAS EXTRACTION',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 30202,
    onecCodeDescr: 'MINING EXCEPT OIL AND GAS',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 30203,
    onecCodeDescr: 'SUPPORT ACTIVITIES FOR MINING',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 30300,
    onecCodeDescr: 'MANUFACTURING, LIGHT',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 30301,
    onecCodeDescr: 'FOOD, BEVERAGE, TOBACCO AND DRUG MANUFACTURING',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 30302,
    onecCodeDescr: 'TEXTILE, TEXTILE PRODUCT, LEATHER, AND APPAREL MANUFACTURING',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 30303,
    onecCodeDescr: 'PAPER, ALLIED PRODUCT, PRINTING, AND RELATED SUPPORT ACTIVITIES',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 30304,
    onecCodeDescr: 'FURNITURE AND RELATED PRODUCT MANUFACTURING',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 30305,
    onecCodeDescr: 'MISCELLANEOUS MANUFACTURING, LIGHT',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 30400,
    onecCodeDescr: 'MANUFACTURING, HEAVY',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 30401,
    onecCodeDescr: 'WOOD PRODUCT MANUFACTURING',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 30402,
    onecCodeDescr: 'PETROLEUM AND COAL PRODUCTS MANUFACTURING',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 30403,
    onecCodeDescr: 'CHEMICAL MANUFACTURING',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 30404,
    onecCodeDescr: 'PLASTICS AND RUBBER PRODUCTS MANUFACTURING',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 30405,
    onecCodeDescr: 'NONMETALLIC MINERAL PRODUCT MANUFACTURING',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 30406,
    onecCodeDescr: 'PRIMARY METAL PRODUCTION AND METAL FABRICATION MANUFACTURING',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 30407,
    onecCodeDescr: 'MACHINERY MANUFACTURING',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 30408,
    onecCodeDescr: 'HIGH TECHNOLOGY PRODUCT MANUFACTURING',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 30409,
    onecCodeDescr: 'MISCELLANEOUS MANUFACTURING, HEAVY',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 30500,
    onecCodeDescr: 'OTHER INDUSTRIAL SERVICES',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 30501,
    onecCodeDescr: 'CONSTRUCTION',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 40000,
    onecCodeDescr: 'PUBLIC ADMINISTRATION, EDUCATION, AND RELIGION',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'office',
  },
  {
    onecCode: 40100,
    onecCodeDescr: 'PUBLIC ADMINISTRATION, GOVERNMENT RELATED',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'office',
  },
  {
    onecCode: 40101,
    onecCodeDescr: 'EXECUTIVE, LEGISLATIVE, AND OTHER GENERAL GOVERNMENT SUPPORT',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'office',
  },
  {
    onecCode: 40102,
    onecCodeDescr: 'JUSTICE, PUBLIC ORDER, AND SAFETY ACTIVITIES',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'office',
  },
  {
    onecCode: 40103,
    onecCodeDescr: 'OTHER PUBLIC ADMINISTRATIONS',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'office',
  },
  {
    onecCode: 40200,
    onecCodeDescr: 'RELIGIOUS, GRANTMAKING, CIVIC PROFESSIONALS',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'office',
  },
  {
    onecCode: 40300,
    onecCodeDescr: 'EDUCATION SERVICES',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'educational',
  },
  {
    onecCode: 40301,
    onecCodeDescr: 'UNIVERSITY, COLLEGES, AND TECHNICAL SCHOOLS',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'educational',
  },
  {
    onecCode: 40302,
    onecCodeDescr: 'PRIMARY AND SECONDARY SCHOOLS',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'educational',
  },
  {
    onecCode: 40303,
    onecCodeDescr: 'OTHER EDUCATION SUPPORT SERVICES',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'educational',
  },
  {
    onecCode: 40400,
    onecCodeDescr: 'OTHER PUBLIC FACILITIES AND SERVICES',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'office',
  },
  {
    onecCode: 50000,
    onecCodeDescr: 'TRANSPORTATION AND LOGISTICS',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'travel_tourism',
  },
  {
    onecCode: 50100,
    onecCodeDescr: 'AIR TRANSPORTATION',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'travel_tourism',
  },
  {
    onecCode: 50200,
    onecCodeDescr: 'RAIL TRANSPORTATION',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'travel_tourism',
  },
  {
    onecCode: 50300,
    onecCodeDescr: 'WATER TRANSPORTATION',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'travel_tourism',
  },
  {
    onecCode: 50400,
    onecCodeDescr: 'TRUCK TRANSPORTATION',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'travel_tourism',
  },
  {
    onecCode: 50500,
    onecCodeDescr: 'TRANSIT AND GROUND PASSENGER TRANSPORTATION',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'travel_tourism',
  },
  {
    onecCode: 50600,
    onecCodeDescr: 'PIPELINE TRANSPORTATION',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'other',
  },
  {
    onecCode: 50700,
    onecCodeDescr: 'SCENIC AND SIGHTSEEING TRANSPORTATION',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'other',
  },
  {
    onecCode: 50800,
    onecCodeDescr: 'POSTAL SERVICE',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'other',
  },
  {
    onecCode: 50900,
    onecCodeDescr: 'COURIER AND MESSENGER',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'retail',
  },
  {
    onecCode: 51000,
    onecCodeDescr: 'WAREHOUSE AND STORAGE',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'warehouse',
  },
  {
    onecCode: 60000,
    onecCodeDescr: 'UTILITIES',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'IND',
    epDsOccupancy: 'other',
  },
  {
    onecCode: 60100,
    onecCodeDescr: 'ELECTRIC GENERATION, TRANSMISSION, AND DISTRIBUTION',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'other',
  },
  {
    onecCode: 60101,
    onecCodeDescr: 'FOSSIL FUEL ELECTRIC POWER GENERATION',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 60102,
    onecCodeDescr: 'NUCLEAR ELECTRIC POWER GENERATION',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 60103,
    onecCodeDescr: 'SOLAR ELECTRIC POWER GENERATION',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 60104,
    onecCodeDescr: 'WIND ELECTRIC POWER GENERATION',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 60105,
    onecCodeDescr: 'GEOTHERMAL ELECTRIC POWER GENERATION',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 60106,
    onecCodeDescr: 'BIOMASS ELECTRIC POWER GENERATION',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 60107,
    onecCodeDescr: 'OTHER ELECTRIC POWER GENERATION',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 60108,
    onecCodeDescr: 'ELECTRIC BULK POWER TRANSMISSION, CONTROL, AND DISTRIBUTION',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 60200,
    onecCodeDescr: 'OIL AND NATURAL GAS DISTRIBUTION',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 60300,
    onecCodeDescr: 'WATER AND SEWAGE SYSTEMS',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'other',
  },
  {
    onecCode: 60301,
    onecCodeDescr: 'WATER TREATMENT FACILITIES',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'manufacturing',
  },
  {
    onecCode: 60302,
    onecCodeDescr: 'SEWAGE SYSTEMS',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'other',
  },
  {
    onecCode: 60400,
    onecCodeDescr: 'COMMUNICATION / INTERNET UTILITIES',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'other',
  },
  {
    onecCode: 60500,
    onecCodeDescr: 'OTHER UTILITIES',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'other',
  },
  {
    onecCode: 70000,
    onecCodeDescr: 'OTHERS',
    isSearchable: true,
    generalUse: 'NON-RESIDENTIAL',
    toOnecBosV1: 'COM',
    epDsOccupancy: 'other',
  },
  {
    onecCode: 99999,
    onecCodeDescr: 'UNKNOWN',
    isSearchable: false,
    generalUse: '',
    toOnecBosV1: '',
    epDsOccupancy: '',
  },
];

export default ALL_OCCUPANCY_TYPES;
