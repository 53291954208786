import * as React from 'react';
import { ClusterFeatureProps, LayerMouseEvent, LocationFeatureProps, OnecWindow } from 'onec-types';
import { GeoJSONSource } from 'mapbox-gl';
import { Layer } from 'react-mapbox-gl';

import { clusterLayersConfig, mapFeatureToLocationProps } from './clusterHelpers';
import { App } from '../../../../PlanningApp/AppConfig';
import LocationPopup from './LocationPopup';

export const CLUSTERED_FILTER = ['has', 'point_count'];
export const UNCLUSTERED_FILTER = ['!', CLUSTERED_FILTER];
export const TEXT_LAYOUT = {
  'text-field': '{point_count_abbreviated}',
  'text-size': 16,
  'text-font': ['Mplus 1p Medium'],
};

export type ClusterLayerProps = {
  sourceId: string;
  hoveredLocationId?: string;
  vulnerable: boolean;
  onClickPoint: (location: LocationFeatureProps) => void;
  onHoverPoint: (locationId: string) => void;
  before?: string;
};

const ClusterLayers: React.FC<ClusterLayerProps> = ({
  sourceId,
  hoveredLocationId,
  vulnerable,
  onClickPoint,
  onHoverPoint,
  before,
}) => {
  const [hoveredClusterMarkerId, setHoveredClusterMarkerId] = React.useState(null);
  const mapboxMap = (window as OnecWindow).reactMap;
  const [showLocationPopup, setShowLocationPopup] = React.useState(false);

  const [clConfig, locConfig, haloConfig] = React.useMemo(() => {
    const { clusterMarker, locationMarker, halo } =
      clusterLayersConfig[vulnerable ? 'vulnerable' : 'notVulnerable'];

    return [clusterMarker, locationMarker, halo];
  }, [vulnerable]);

  const haloPaint = React.useMemo(
    () => ({
      'circle-radius': haloConfig.haloRadius,
      'circle-blur': 0.57,
      'circle-color': haloConfig.haloColor,
      'circle-opacity': haloConfig.haloOpacity,
    }),
    [haloConfig],
  );

  const [clusteredPaint, textPaint] = React.useMemo(
    () => [
      {
        'circle-color': clConfig.backgroundColor,
        'circle-radius': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          clConfig.radius - clConfig.borderWidthHovered,
          clConfig.radius - clConfig.borderWidth,
        ],
        'circle-stroke-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          clConfig.borderWidthHovered,
          clConfig.borderWidth,
        ],
        'circle-stroke-color': clConfig.borderColor,
      },
      { 'text-color': clConfig.textColor },
    ],
    [clConfig],
  );

  const unclusteredPaint = React.useMemo(
    () => ({
      'circle-radius': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        locConfig.radius - locConfig.borderWidthHovered,
        locConfig.radius - locConfig.borderWidth,
      ],
      'circle-stroke-width': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        locConfig.borderWidthHovered,
        locConfig.borderWidth,
      ],
      'circle-stroke-color': locConfig.borderColor,
      'circle-color': locConfig.backgroundColor,
    }),
    [locConfig],
  );

  const onClickClusterMarker = React.useCallback(
    (evt: LayerMouseEvent<ClusterFeatureProps>) => {
      // unselect any previously selected location
      onClickPoint(null);
      onHoverPoint(null);

      // When user clicks on a cluster marker, zoom the map in until the cluster breaks up
      const clusterFeature = mapboxMap.queryRenderedFeatures(evt.point, {
        layers: [`${sourceId}-circles`],
      })[0];
      const clusterMarkerId = clusterFeature.properties.cluster_id as number;
      const source = mapboxMap.getSource(sourceId) as GeoJSONSource;

      // Mapbox's getClusterExpansionZoom automatically determines the minimum zoom level needed
      // to break the cluster up
      source.getClusterExpansionZoom(clusterMarkerId, (err: unknown, zoom: number) => {
        if (err) {
          App.error(`[ClusterLayers - onClickClusterMarker] ${sourceId} - Error: `, err);
          return;
        }
        mapboxMap.easeTo({
          center: (clusterFeature.geometry as GeoJSON.Point).coordinates as [number, number],
          zoom,
        });
      });
    },
    [sourceId, onClickPoint, onHoverPoint, mapboxMap],
  );

  const onClickUnclusteredPoint = React.useCallback(
    (evt: LayerMouseEvent<LocationFeatureProps>) => {
      const location = evt.features[0];
      onHoverPoint(null);
      onClickPoint(mapFeatureToLocationProps(location));
      mapboxMap.getCanvas().style.cursor = '';
    },
    [onClickPoint, onHoverPoint, mapboxMap],
  );

  const setFeatureState = React.useCallback(
    (featureSourceId: string, locId: string | number, state: any) => {
      mapboxMap.setFeatureState(
        { source: featureSourceId, id: locId },
        {
          ...mapboxMap.getFeatureState({ source: featureSourceId, id: locId }),
          ...state,
        },
      );
    },
    [mapboxMap],
  );

  const clearFeatureState = React.useCallback(
    (featureSourceId: string) => {
      if (mapboxMap) {
        mapboxMap.removeFeatureState({ source: featureSourceId });
      }
    },
    [mapboxMap],
  );

  React.useEffect(() => {
    clearFeatureState(sourceId);

    if (mapboxMap && hoveredLocationId) {
      const visibleUnclusteredLocs = mapboxMap.queryRenderedFeatures(null, {
        layers: [`${sourceId}-unclustered`],
      });
      const matchingLoc = visibleUnclusteredLocs.find(
        (loc) => loc.properties.id === hoveredLocationId,
      );

      if (matchingLoc) {
        setFeatureState(sourceId, matchingLoc?.id, { hover: true });
        setShowLocationPopup(true);
      } else {
        setShowLocationPopup(false);
      }
    }
  }, [hoveredLocationId, clearFeatureState, setFeatureState, sourceId, mapboxMap]);

  const onMouseEnterClusterMarker = React.useCallback(
    (evt: LayerMouseEvent<ClusterFeatureProps>) => {
      const { id: clusterFeatureId } = evt.features[0];

      if (hoveredClusterMarkerId) {
        clearFeatureState(sourceId);
        setShowLocationPopup(false);
      }

      onHoverPoint(null);
      setFeatureState(sourceId, clusterFeatureId, { hover: true });
      setHoveredClusterMarkerId(clusterFeatureId);
      mapboxMap.getCanvas().style.cursor = 'pointer';
    },
    [clearFeatureState, hoveredClusterMarkerId, onHoverPoint, setFeatureState, sourceId, mapboxMap],
  );

  const onMouseLeaveClusterMarker = React.useCallback(() => {
    setHoveredClusterMarkerId(null);
    setShowLocationPopup(false);

    if (mapboxMap) {
      mapboxMap.removeFeatureState({ source: sourceId });
      mapboxMap.getCanvas().style.cursor = '';
    }
  }, [sourceId, mapboxMap]);

  const onMouseEnterUnclusteredPoint = React.useCallback(
    (evt: LayerMouseEvent<LocationFeatureProps>) => {
      if (hoveredLocationId) {
        clearFeatureState(sourceId);
      }

      setHoveredClusterMarkerId(null);
      const { id: locationId } = evt.features[0].properties;
      const featureId = evt.features[0].id;
      setFeatureState(sourceId, featureId, { hover: true });
      setShowLocationPopup(true);
      onHoverPoint(String(locationId));
      mapboxMap.getCanvas().style.cursor = onClickPoint ? 'pointer' : 'default';
    },
    [
      onHoverPoint,
      onClickPoint,
      hoveredLocationId,
      clearFeatureState,
      setFeatureState,
      sourceId,
      mapboxMap,
    ],
  );

  const onMouseLeaveUnclusteredPoint = React.useCallback(() => {
    if (hoveredLocationId) {
      clearFeatureState(sourceId);
    }

    onHoverPoint(null);
    setShowLocationPopup(false);
    setHoveredClusterMarkerId(null);
    mapboxMap.getCanvas().style.cursor = '';
  }, [onHoverPoint, sourceId, hoveredLocationId, mapboxMap, clearFeatureState]);

  return (
    <>
      {/* Halo layer for both cluster circles and unclustered points */}
      {haloConfig.showHalo && (
        <Layer id={`${sourceId}-halo`} sourceId={sourceId} type="circle" paint={haloPaint} />
      )}

      {/* Cluster circles (aka "cluster markers", although they are really map features) */}
      <Layer
        id={`${sourceId}-circles`}
        sourceId={sourceId}
        type="circle"
        filter={CLUSTERED_FILTER}
        paint={clusteredPaint}
        onClick={onClickClusterMarker}
        onMouseEnter={onMouseEnterClusterMarker}
        onMouseLeave={onMouseLeaveClusterMarker}
      />

      {/* The text showing the number (of unclustered points) inside each cluster circle */}
      <Layer
        id={`${sourceId}-count-labels`}
        sourceId={sourceId}
        type="symbol"
        filter={CLUSTERED_FILTER}
        layout={TEXT_LAYOUT}
        paint={textPaint}
      />

      {/* The unclustered points (aka the actual business locations) */}
      <Layer
        id={`${sourceId}-unclustered`}
        sourceId={sourceId}
        type="circle"
        filter={UNCLUSTERED_FILTER}
        paint={unclusteredPaint}
        before={before}
        onClick={onClickUnclusteredPoint}
        onMouseEnter={onMouseEnterUnclusteredPoint}
        onMouseLeave={onMouseLeaveUnclusteredPoint}
      />
      {/* Only show location popup on map when hovered and in List View */}
      {showLocationPopup && hoveredLocationId && <LocationPopup locationId={hoveredLocationId} />}
    </>
  );
};
ClusterLayers.displayName = 'ClusterLayers';
export default ClusterLayers;
