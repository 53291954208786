import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';

const useStyles = makeStyles({
  backDrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
});

/* eslint-disable react/no-unused-prop-types */
type ConfirmDialogAction = {
  variant: 'text' | 'outlined' | 'contained';
  caption: string;
  callback: () => void;
};

interface ConfirmDialogProps extends React.PropsWithChildren {
  open: boolean;
  onClose: () => void;
  actions: ConfirmDialogAction[];
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ open, onClose, actions, children }) => {
  const { backDrop } = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      BackdropProps={{ classes: { root: backDrop } }}
      disableEnforceFocus
      data-test-id="ConfirmDialog"
    >
      <DialogContent>
        <DialogContentText
          id="ConfirmDialogDescription"
          color="textPrimary"
          data-test-id="ConfirmDialogDescription"
        >
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {actions.map(({ variant, caption, callback }: ConfirmDialogAction) => (
          <Button
            variant={variant}
            color="primary"
            onClick={callback}
            key={caption}
            sx={{ minWidth: 70 }}
            data-test-id={`ConfirmDialogAction-${caption}`}
          >
            {caption}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.displayName = 'ConfirmDialog';
export default ConfirmDialog;
