import * as React from 'react';

import { FilledInput, InputAdornment } from '@mui/material';
import { NumberFormatValues, NumericFormat } from 'react-number-format';

import { GLASS_BG_DARK } from '../../../../util/productGlobals';

type CurrencyInputProps = {
  value: string | number;
  id: string;
  onChange?: ({ value }: { value: string }) => void;
  readOnly?: boolean;
};

const CurrencyInput: React.FC<CurrencyInputProps> = ({ value, id, onChange, readOnly }) => {
  const handleChange = React.useCallback(
    (values: NumberFormatValues) => onChange && onChange({ value: values.value }),
    [onChange],
  );

  return (
    <NumericFormat
      readOnly={readOnly}
      id={`CurrencyInput${id}`}
      value={value}
      customInput={FilledInput}
      allowNegative={false}
      decimalScale={2}
      onValueChange={handleChange}
      thousandSeparator
      valueIsNumericString
      startAdornment={
        <InputAdornment position="end" disablePointerEvents disableTypography>
          $
        </InputAdornment>
      }
      inputProps={{
        'data-test-id': `CurrencyInput${id}`,
        onClick: (e: React.MouseEvent<HTMLElement>) => e.stopPropagation(),
      }}
      sx={{
        height: '36px',
        margin: 0,
        background: GLASS_BG_DARK,
        pl: 2,
        '& .MuiFilledInput-input': {
          padding: '8px 12px 8px 4px',
        },
      }}
    />
  );
};

export default CurrencyInput;
