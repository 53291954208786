import * as React from 'react';
import { ApolloError } from '@apollo/client';
import {
  LocationFile,
  useGetLocationsFileIdQuery,
  useGetLocationTypesQuery,
} from '../__generated__/graphql';

export type useGetLocationTypesAndFileIdReturnType = {
  values?: {
    fileId: string;
    locTypes: string[];
  };
  loading?: boolean;
  error?: ApolloError;
};

function useGetLocationTypesAndFileId(): useGetLocationTypesAndFileIdReturnType {
  const {
    data: dataLocationTypes,
    error: errorLocationTypes,
    loading: loadingLocationTypes,
  } = useGetLocationTypesQuery();

  const { data, error, loading } = useGetLocationsFileIdQuery();

  const locationFile = data?.user?.productSettings?.locationFile as LocationFile;
  const locTypes = React.useMemo(
    () =>
      // Get unique valid type array
      dataLocationTypes?.locations
        ? dataLocationTypes?.locations
            .map((location) => location.type)
            .filter((value, index, self) => value && self.indexOf(value) === index)
            .sort()
        : [],
    [dataLocationTypes?.locations],
  );

  if (loading || loadingLocationTypes) {
    return { values: null, loading: true, error: null };
  }

  if (error || errorLocationTypes) {
    return { error, loading: false, values: null };
  }

  return {
    values: {
      locTypes,
      fileId: locationFile.id,
    },
    loading: false,
    error: null,
  };
}

export default useGetLocationTypesAndFileId;
