import '../../assets/stylesheets/application.scss';
import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  GetCurrentUserQuery,
  useGetLocationsFileIdQuery,
  UserRole,
} from '../__generated__/graphql';
import LocationMatchCard, {
  LocationMatchNotFound,
} from '../Components/ProductComponents/LocationMatch/LocationMatchCard';
import AddressMapComponents from '../Components/ProductComponents/LocationMatch/AddressMapComponents';
import BaseMapView from '../Components/ProductComponents/BaseMapView';
import BuildingPickerCard from '../Components/ProductComponents/LocationMatch/BuildingPickerCard';
import BuildingPickerLayers from '../Components/ProductComponents/LocationMatch/BuildingPickerLayers';
import ChooseBuildingButton from '../Components/ProductComponents/LocationMatch/ChooseBuildingButton';
import ClusteredMapWrapper from '../Components/ProductComponents/Map/Cluster/ClusteredMapWrapper';
import EditLocationModal from '../Components/ProductComponents/LocationMatch/EditLocationModal';
import ErrorPage from '../Components/CommonComponents/Errors/ErrorPage';
import { DEMO_ACCOUNT_SUFFIX, FINANCE_HOME_PATH, HOME_PATH } from '../util/productGlobals';
import LifelineMarkersContainer from '../Components/ProductComponents/Map/Lifelines/LifelineMarkersContainer';
import LocationMatchLayers from '../Components/ProductComponents/LocationMatch/LocationMatchLayers';
import MaterialityContent from '../Components/ProductComponents/Modals/Materiality/MaterialityContent';
import SendLocationDataAnalytics from '../Components/ProductComponents/Plugins/SendLocationDataAnalytics';
import SettingsContent from '../Components/ProductComponents/Modals/Settings/SettingsContent';
import ShareLocationModal from '../Components/ProductComponents/Modals/ShareLocation/ShareLocationModal';
import ShowYourWorkModal from '../Components/ProductComponents/Modals/ShowYourWork/ShowYourWorkModal';
import useConsultantOrganization from '../Hooks/useConsultantOrganization';
import useHasMaterialityEntitlement from '../Hooks/useHasMaterialityEntitlement';
import Modals from '../Components/ProductComponents/Modals/Modals';
import FinanceViewContainer from '../Components/ProductComponents/FinanceViewV2/FinanceViewContainer';
import FinanceSettingsContent from '../Components/ProductComponents/Modals/Finance/FinanceSettingsContent';
import useHasFinanceEntitlement from '../Hooks/useHasFinanceEntitlement';
import PickInterestModal from '../Components/ProductComponents/Modals/PickInterest/PickInterestModal';
import AnalysisViewContainer from '../Components/ProductComponents/AnalysisViewContainer';
import MapWrapperFi from '../Components/ProductComponents/FinanceViewV2/MapWrapperFi';
import ActiveLocBobjLayers from '../Components/ProductComponents/Map/Cluster/ActiveLocBobjLayers';
import useSetEntitlementsToAmplitude from '../Hooks/useSetEntitlementsToAmplitude';
import SimulationsFormContainer from '../Components/ProductComponents/FinanceViewV2/SimulationsForm';
import SimulationRegionMapWrapper from '../Components/ProductComponents/FinanceViewV2/SimulationRegionMapWrapper';

type PlanningAppProps = {
  user: GetCurrentUserQuery['user'];
  // eslint-disable-next-line react/no-unused-prop-types
  onClickLogout?: () => void;
};

const PlanningApp: React.FC<PlanningAppProps> = ({ user }) => {
  const { isConsultantOrg } = useConsultantOrganization();
  const isViewer = user?.role === UserRole.Viewer;
  const { data, error } = useHasMaterialityEntitlement();
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEnabled = financeModules?.enablebi ?? false;
  const isSimulationEnabled = financeModules?.enablesimulations ?? false;

  const hasMateriality = data && !error;
  const userId = user?.personalInfo?.contact?.email;
  const isDemoUser = userId?.endsWith(DEMO_ACCOUNT_SUFFIX);
  const homePath = isFinanceEnabled ? FINANCE_HOME_PATH : HOME_PATH;
  const { data: locationFile } = useGetLocationsFileIdQuery();
  const fileId = locationFile?.user?.productSettings?.locationFile?.id ?? 'no-file';

  useSetEntitlementsToAmplitude();

  if (isDemoUser) {
    return (
      <>
        <Routes>
          <Route path="/" element={<BaseMapView user={user} />}>
            {isFinanceEnabled && (
              <Route path="finance/analysis">
                <Route path="company/:id" element={<MapWrapperFi />} />
                <Route path="detail/notFound" />
                <Route path="settings" />
                <Route
                  path="detail/:id"
                  element={
                    <>
                      <ActiveLocBobjLayers activeLocationWithScore={null} locsAreLoading={false} />
                      <LifelineMarkersContainer />
                    </>
                  }
                />
                <Route
                  path="see-the-math/:id"
                  element={
                    <>
                      <ActiveLocBobjLayers activeLocationWithScore={null} locsAreLoading={false} />
                      <LifelineMarkersContainer />
                    </>
                  }
                />
              </Route>
            )}
            {!isFinanceEnabled && (
              <Route path="analysis" element={<ClusteredMapWrapper />}>
                <Route index element={<Navigate to="/404" replace />} />
                <Route path="detail/notFound" />
                <Route path="detail/:id" element={<LifelineMarkersContainer />} />
                <Route path="see-the-math/:id" element={<LifelineMarkersContainer />} />
                <Route path="*" element={<Navigate to="/404" replace />} />
              </Route>
            )}
            <Route path="demo" element={null} />
            <Route path="404" element={null} />
          </Route>
        </Routes>

        <Routes>
          <Route path="/" element={<Navigate replace to="/demo" />} />
          {isFinanceEnabled && (
            <Route path="finance/analysis/*" element={<AnalysisViewContainer user={user} />} />
          )}
          {!isFinanceEnabled && (
            <Route path="/analysis/*" element={<AnalysisViewContainer user={user} />} />
          )}
          <Route path="demo" element={null} />
          <Route path="404" element={<ErrorPage errorCode={404} />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>

        <Routes>
          <Route path="/" element={<Modals isViewer={isViewer} hasMateriality={hasMateriality} />}>
            <Route path="demo" element={<PickInterestModal userId={userId} />} />
            {isFinanceEnabled && (
              <Route path="finance/analysis">
                <Route index element={<FinanceViewContainer />} />
                <Route path="detail/:id" element={<SendLocationDataAnalytics />} />
                <Route path="settings" element={<FinanceSettingsContent />} />
                <Route path="see-the-math/:id" element={<ShowYourWorkModal userId={userId} />} />
              </Route>
            )}

            {!isFinanceEnabled && (
              <Route path="analysis">
                <Route index element={null} />
                <Route path="detail/:id" element={<SendLocationDataAnalytics />} />
                <Route path="see-the-math/:id" element={<ShowYourWorkModal userId={userId} />} />
              </Route>
            )}
            <Route path="*" element={null} />
          </Route>
        </Routes>
      </>
    );
  }

  return (
    <>
      {/* These Routes are related map layers. We use them to be able to provide different layers
          as children to the BaseMap component, depending on the URL */}
      <Routes>
        <Route path="/" element={<BaseMapView user={user} />}>
          {!isFinanceEnabled && (
            <Route path="analysis" element={<ClusteredMapWrapper />}>
              <Route path="detail/notFound" />
              <Route path="detail/:id" element={<LifelineMarkersContainer />} />
              <Route path="see-the-math/:id" element={<LifelineMarkersContainer />} />
              <Route path="share-location/:id" element={<LifelineMarkersContainer />} />
            </Route>
          )}
          {isFinanceEnabled && (
            <>
              <Route path="finance/analysis">
                <Route path="detail/notFound" />
                <Route path="settings" />
                <Route path="company/:id" element={<MapWrapperFi />} />
                <Route
                  path="detail/:id"
                  element={
                    <>
                      <ActiveLocBobjLayers activeLocationWithScore={null} locsAreLoading={false} />
                      <LifelineMarkersContainer />
                    </>
                  }
                />
                <Route
                  path="see-the-math/:id"
                  element={
                    <>
                      <ActiveLocBobjLayers activeLocationWithScore={null} locsAreLoading={false} />
                      <LifelineMarkersContainer />
                    </>
                  }
                />
              </Route>
              {isSimulationEnabled && (
                <Route path="finance/simulation/*" element={<SimulationRegionMapWrapper />} />
              )}
            </>
          )}
          {!isViewer && !isDemoUser && (
            <>
              <Route path="locations" element={<ClusteredMapWrapper />} />
              <Route path="locations/list" element={<ClusteredMapWrapper />} />
              <Route path="locations/:id" element={<LocationMatchLayers />}>
                <Route index element={<AddressMapComponents />} />
                <Route path="match" element={<BuildingPickerLayers />} />
                <Route path="*" element={null} />
              </Route>
            </>
          )}
          <Route path="profile" element={<ClusteredMapWrapper />} />
          <Route path="usermanagement" element={<ClusteredMapWrapper />} />
          <Route path="materiality/*" element={<ClusteredMapWrapper />} />
          <Route path="404" element={null} />
        </Route>
      </Routes>

      {/* These Routes are related to the Side Panel contents and HTML (non-canvas)
          components that float above the Map  */}
      <Routes>
        <Route path="/" element={<Navigate replace to={homePath} />} />
        {!isFinanceEnabled && (
          <Route path="/analysis/*" element={<AnalysisViewContainer user={user} />} />
        )}
        {isSimulationEnabled && <Route path="/finance/simulation/*" />}
        {isFinanceEnabled && (
          <Route path="/finance/analysis/*" element={<AnalysisViewContainer user={user} />} />
        )}
        {!isViewer && !isDemoUser && (
          <Route path="/locations/*">
            <Route index element={null} />
            <Route path="list" element={null} />
            <Route path=":id" element={<LocationMatchCard />}>
              <Route index element={<ChooseBuildingButton />} />
              <Route path="match" element={<BuildingPickerCard />} />
              <Route path="edit" element={null} />
              <Route path="*" element={<Navigate to="/404" replace />} />
            </Route>
            <Route path="notFound" element={<LocationMatchNotFound />} />
            <Route path="*" element={<Navigate to="/locations/notFound" replace />} />
          </Route>
        )}
        <Route path="/profile" element={null} />
        <Route path="/usermanagement" element={null} />
        <Route path="/materiality/*" element={null} />
        <Route path="/404" element={<ErrorPage errorCode={404} />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>

      {/* Modal Routes */}
      <Routes>
        {/* The Modals component needs to be at the top level to keep the Settings Modal from 
        closing when switching between locations, usermanagement, and profile routes. 
        It's also needed to enable animation of the modal opening and closing */}
        <Route path="/" element={<Modals isViewer={isViewer} hasMateriality={hasMateriality} />}>
          {!isViewer && !isDemoUser && (
            <>
              <Route path="locations" element={<SettingsContent path="locations" />} />
              <Route path="locations/list" element={<SettingsContent path="locations/list" />} />
              <Route path="locations/:id/edit" element={<EditLocationModal />} />
            </>
          )}
          <Route path="profile" element={<SettingsContent path="profile" />} />
          <Route path="usermanagement" element={<SettingsContent path="usermanagement" />} />
          {hasMateriality && (
            <Route path="materiality">
              <Route index element={<MaterialityContent path="dashboard" />} />
              <Route path="dashboard" element={<MaterialityContent path="dashboard" />} />
              <Route path="settings" element={<MaterialityContent path="settings" />} />
              <Route path="*" element={<Navigate to="/404" replace />} />
            </Route>
          )}
          {!isFinanceEnabled && (
            <Route path="analysis">
              <Route index element={null} />
              <Route path="detail/:id" element={<SendLocationDataAnalytics />} />
              <Route path="see-the-math/:id" element={<ShowYourWorkModal userId={userId} />} />
              {isConsultantOrg && (
                <Route path="share-location/:id" element={<ShareLocationModal />} />
              )}
            </Route>
          )}
          {isFinanceEnabled && (
            <>
              <Route path="finance/analysis">
                {fileId === 'no-file' && <Route index element={<FinanceViewContainer />} />}
                <Route path="detail/:id" element={<SendLocationDataAnalytics />} />
                <Route path="settings" element={<FinanceSettingsContent />} />
                <Route path="see-the-math/:id" element={<ShowYourWorkModal userId={userId} />} />
              </Route>
              {isSimulationEnabled && (
                <Route path="finance/simulation/create" element={<SimulationsFormContainer />} />
              )}
            </>
          )}
          <Route path="*" element={null} />
        </Route>
      </Routes>
    </>
  );
};
PlanningApp.displayName = 'PlanningApp';
export default PlanningApp;
