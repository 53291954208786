import * as React from 'react';
import { Feature, FeatureCollection, Point } from '@turf/helpers';
import { LngLat, MapboxGeoJSONFeature, Map as MapboxMap } from 'mapbox-gl';
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import distance from '@turf/distance';
import nearestPoint from '@turf/nearest-point';
import { OnecWindow } from 'onec-types';

import {
  DRAG_DROP_SNAP_RADIUS_KM,
  NEARBY_BUILDINGS_SOURCE_ID,
  ORANGE,
} from '../../../util/productGlobals';
import {
  LocationStatus,
  MibRequestStatus,
  useUpdateLocationBuildingMatchMutation,
} from '../../../__generated__/graphql';
import { App } from '../../../PlanningApp/AppConfig';
import DraggableMapPin from './DraggableMapPin';
import { LocRecord } from './locationMatchHelpers';
import MIBRequestDialog from './MIBRequestDialog';
import NearbyBuildingsLayer from './NearbyBuildingsLayer';
import PulsingMapMarker from '../../CommonComponents/Map/PulsingMapMarker';
import useApiErrSnackbar from '../../CommonComponents/Snackbar/useApiErrSnackbar';

const mboxFeaturesToTurfFC: (
  mboxPointFeatures: MapboxGeoJSONFeature[],
) => FeatureCollection<Point> = (mboxPointFeatures) => {
  const featArray = (mboxPointFeatures as Feature<Point>[]).map((feat) => {
    return {
      type: 'Feature',
      geometry: { type: 'Point', coordinates: feat.geometry.coordinates },
      properties: feat.properties,
      id: feat?.id,
    } as Feature<Point>;
  });
  const turfFeatureCollection: FeatureCollection<Point> = {
    type: 'FeatureCollection',
    features: featArray,
  };
  return turfFeatureCollection;
};

const BuildingPickerLayers: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const satelliteEnabled = React.useMemo(
    () => searchParams?.get('satellite') === 'true',
    [searchParams],
  );
  const snapEnabled = React.useMemo(() => searchParams?.get('snap') === 'true', [searchParams]);

  const { enqueueApiErrSnackbar } = useApiErrSnackbar();
  const { locRecord } = useOutletContext() as { locRecord: LocRecord };

  const lineNumber = locRecord?.lineNumber;
  const locationID = locRecord?.audit?.locationID;

  const origCoordinates = locRecord?.audit?.geocodedCoordinates;

  const [updateLocationTentativeMatch] = useUpdateLocationBuildingMatchMutation();

  const [markerCoords, setMarkerCoords] = React.useState<number[]>(null);
  const [openLocationRequestDialog, setOpenLocationRequestDialog] = React.useState(false);

  const handleOpenLocationRequestDialog = React.useCallback(() => {
    setOpenLocationRequestDialog(true);
  }, [setOpenLocationRequestDialog]);

  const handleCloseLocationRequestDialog = React.useCallback(() => {
    setOpenLocationRequestDialog(false);
    navigate(`/locations/${locationID}`);
  }, [navigate, locationID, setOpenLocationRequestDialog]);

  React.useEffect(() => {
    const mapboxMap = (window as OnecWindow).reactMap;
    // According to satellite paramater, show/hide satellite layer
    mapboxMap.setLayoutProperty(
      'mapbox-satellite',
      'visibility',
      satelliteEnabled ? 'visible' : 'none',
    );
    return () => {
      // Before BuildingPickerLayers unmounts, hide satellite layer
      if (satelliteEnabled) {
        mapboxMap.setLayoutProperty('mapbox-satellite', 'visibility', 'none');
      }
    };
  }, [satelliteEnabled]);

  React.useEffect(() => {
    // On mount, place map pin marker at the user-provided coordinates/address for the location
    if (origCoordinates) {
      setMarkerCoords(origCoordinates);
    }
  }, [origCoordinates]);

  const onDragMarkerEnd = React.useCallback(
    (dropLngLat: LngLat, mapboxMap: MapboxMap) => {
      App.debug('[BuildingPickerLayers - onDragMarkerEnd] dropLngLat: ', dropLngLat);
      const dropCoordinates = dropLngLat && [dropLngLat?.lng, dropLngLat?.lat];

      // When the user drops the map pin, find the closest building that might match it. "Snap" the
      // draggable marker to that building's coordinates. Save the selected/closest building's id &
      // address to global state so other components (like the side panel) can use it.
      if (snapEnabled) {
        const nearbyBuildingsAr = mapboxMap.queryRenderedFeatures(null, {
          layers: [NEARBY_BUILDINGS_SOURCE_ID],
        });
        if (nearbyBuildingsAr.length > 0) {
          const nearbyBuildingsFeatCollection = mboxFeaturesToTurfFC(nearbyBuildingsAr);
          const nearestBuilding = nearestPoint(dropCoordinates, nearbyBuildingsFeatCollection);
          const nearestBuildingCoords = nearestBuilding.geometry.coordinates;
          const nearestBuildingID = nearestBuilding?.properties?.id;
          if (!locationID || !nearestBuildingID) {
            App.error(
              '[BuildingPickerLayers - onDragMarkerEnd - bad locationId or matching building',
            );
            enqueueApiErrSnackbar();
            return;
          }
          const nearestBuildingAddressJSON = nearestBuilding?.properties?.addressJSON;
          const dropToNearestBuildingDistanceKm = distance(dropCoordinates, nearestBuildingCoords);
          if (dropToNearestBuildingDistanceKm < DRAG_DROP_SNAP_RADIUS_KM) {
            // "Snap" the marker to the nearest builtObject
            setMarkerCoords(nearestBuildingCoords);
            updateLocationTentativeMatch({
              variables: {
                match: {
                  locationID,
                  buildingID: nearestBuildingID,
                  buildingAddressJSON: nearestBuildingAddressJSON,
                },
              },
            });
            return;
          }
        }
      }

      // When snap toggle is enabled, this indicates that there isn't a building in our backend
      // database that is near where the user dropped the pin. Move the map pin to where the user
      // dropped it, and kick off the workflow to request that this location be researched and added
      // to our database.
      setMarkerCoords(dropCoordinates);
      App.debug(
        '[BuildingPickerLayers] - requesting an unknown building at coordinates: ',
        dropCoordinates,
      );
      handleOpenLocationRequestDialog();
    },
    [
      handleOpenLocationRequestDialog,
      locationID,
      updateLocationTentativeMatch,
      enqueueApiErrSnackbar,
      snapEnabled,
    ],
  );

  if (!markerCoords) {
    return null;
  }

  const {
    audit: {
      builtObjectID,
      processStatus,
      builtObjectCoordinates: matchBobjCoordinates,
      builtObjectRequestStatus,
    },
  } = locRecord;
  const showMatchingBuildingMarker =
    processStatus === LocationStatus.Success &&
    builtObjectRequestStatus !== MibRequestStatus.Pending &&
    builtObjectID &&
    matchBobjCoordinates;

  return (
    <div data-test-id="bobjPickerLayers">
      {showMatchingBuildingMarker && (
        <div data-test-id="builtObject-marker-container">
          <DraggableMapPin
            coordinates={matchBobjCoordinates}
            draggable={false}
            color="secondary"
            dataTestId="matchingBobjMarker"
          />
        </div>
      )}
      <div data-test-id="original-location-marker-container">
        <DraggableMapPin
          coordinates={markerCoords}
          draggable
          color="primary"
          dataTestId="originalLocationMarker"
          onDragEnd={onDragMarkerEnd}
        />
      </div>

      {origCoordinates && (
        <NearbyBuildingsLayer locationId={locationID} coordinates={origCoordinates} />
      )}
      {origCoordinates && (
        <PulsingMapMarker
          coordinates={origCoordinates}
          pointSize={10}
          rippleSize={80}
          color={ORANGE}
        />
      )}
      {openLocationRequestDialog && (
        <MIBRequestDialog
          open={openLocationRequestDialog}
          onClose={handleCloseLocationRequestDialog}
          markerCoords={markerCoords}
          locationID={locationID}
          lineNumber={lineNumber}
        />
      )}
    </div>
  );
};

BuildingPickerLayers.displayName = 'BuildingPickerLayers';
export default BuildingPickerLayers;
