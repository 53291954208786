import * as React from 'react';
import { useSnackbar } from 'notistack';

import UnknownErrSnackbar, { unknownErrEnqueueOptions } from './UnknownErrSnackbar';

function useApiErrSnackbar(): { enqueueApiErrSnackbar: () => void } {
  const { enqueueSnackbar } = useSnackbar();

  const enqueueApiErrSnackbar = React.useCallback(
    () => enqueueSnackbar(<UnknownErrSnackbar />, unknownErrEnqueueOptions),
    [enqueueSnackbar],
  );
  return { enqueueApiErrSnackbar };
}

export default useApiErrSnackbar;
