import { ApolloCache, NormalizedCacheObject, Resolver } from '@apollo/client';
import { cloneDeep } from 'lodash/fp';

import {
  GetNotFoundLocationDocument,
  GetNotFoundLocationQuery,
  MutationSetNotFoundLocationArgs,
} from '../../../__generated__/graphql';

export const setNotFoundLocation: Resolver = (
  _: unknown,
  { input }: MutationSetNotFoundLocationArgs,
  { cache }: { cache: ApolloCache<NormalizedCacheObject> },
) => {
  const storeData = cache.readQuery<GetNotFoundLocationQuery>({
    query: GetNotFoundLocationDocument,
  });
  const newData = cloneDeep(storeData);
  newData.notFoundLocation.address = input?.address;
  newData.notFoundLocation.lng = input?.lng ?? 0;
  newData.notFoundLocation.lat = input?.lat ?? 0;

  cache.writeQuery({ data: newData, query: GetNotFoundLocationDocument });
  return newData.notFoundLocation;
};

export const locationSearchMutations = {
  setNotFoundLocation,
};
