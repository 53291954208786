import { SimulationForm } from '../../../__generated__/graphql';
import { simulationFormMutations } from '../resolvers/SimulationFormResolvers';

export const STORE_DEFAULTS = {
  simulationForm: {
    name: '',
    type: '',
    simulationFormRegions: [],
  } as SimulationForm,
};

export const simulationFormStore = {
  defaults: STORE_DEFAULTS,
  mutations: simulationFormMutations,
};
