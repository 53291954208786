import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Link, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  GetLocationsFileDetailsQuery,
  LocationFile,
  useGetLocationsFileDetailsQuery,
  useGetLocationUploadFileQuery,
} from '../../../../../__generated__/graphql';
import { App } from '../../../../../PlanningApp/AppConfig';
import { getLocationsMatchStatuses } from '../../../LocationMatch/locationMatchHelpers';
import Icon from '../../../../CommonComponents/Icon/Icon';
import LocationsCsvDetails from './LocationsCsvDetails';
import LocationsCsvUpload from './LocationsCsvUpload';
import Spinner from '../../../../CommonComponents/Spinner/Spinner';
import useEntitledModules from '../../../../../Hooks/useEntitledModules';

const useStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      marginTop: theme.spacing(8),
    },
    subheader: {
      marginTop: theme.spacing(1),
    },
    link: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
    },
    icon: {
      marginLeft: theme.spacing(3),
    },
  };
});

const ManageLocationsContent: React.FC = () => {
  const { link, icon, header, subheader } = useStyles();
  const { t } = useTranslation();
  const {
    data: {
      locationUploadFile: { file },
    },
  } = useGetLocationUploadFileQuery();
  const { data, error, loading } = useGetLocationsFileDetailsQuery({
    notifyOnNetworkStatusChange: true,
  });

  const { data: entData, loading: entLoading, error: entError } = useEntitledModules();

  let templateFile = `${window.location.origin}/assets/i18n/en/LocationsTemplate_v0_0_0.csv`;

  const locRecords = (data as GetLocationsFileDetailsQuery)?.user?.productSettings?.originalFile;
  const locationFile = data?.user?.productSettings?.locationFile as LocationFile;
  const totalCounts = React.useMemo(() => {
    const { totalCounts: counts } = getLocationsMatchStatuses(locRecords);
    return counts;
  }, [locRecords]);

  if (loading || entLoading) return <Spinner />;
  if (error)
    App.error(`[ManageLocations] ${error.message ?? 'Error retrieving locations file details'}`);
  if (entError)
    App.error(`[ManageLocations] ${entError.message ?? 'Error retrieving user entitlements'}`);
  if (
    data?.user?.productSettings?.locationFile &&
    data?.user?.productSettings?.locationFile.id !== 'no-file'
  ) {
    const newLocationFile: LocationFile = {
      ...locationFile,
      totalAccepted: totalCounts?.totalAccepted,
      totalRejected: totalCounts?.totalRejected,
      totalWarnings: totalCounts?.totalWarnings,
      totalPending: totalCounts?.totalPending,
    };

    return (
      <LocationsCsvDetails file={newLocationFile} userId={data.user.personalInfo.contact.email} />
    );
  }
  if (entData?.hasJPModule) {
    templateFile = `${window.location.origin}/assets/i18n/ja/LocationsTemplate_v0_0_0.csv`;
  }

  if (file)
    return (
      <div
        className="o-flex-center-container o-flexcol-container"
        data-test-id="ProcessingLocations"
      >
        <Typography variant="h3" align="center">
          {t('settings:sections:locations:processingLocations:title')}
        </Typography>
        <Typography align="center" className={subheader}>
          {t('settings:sections:locations:processingLocations:description')}
        </Typography>
      </div>
    );

  return (
    <div data-test-id="UploadLocations">
      <Typography variant="h3" align="center" className={header}>
        {t('settings:sections:locations:upload:title')}
      </Typography>
      <Typography align="center" className={subheader}>
        {t('settings:sections:locations:upload:subtitle')}
      </Typography>
      <Typography align="center" className={link}>
        <Icon name="document" variant="orange" />
        <Link
          href={templateFile}
          target="_blank"
          underline="hover"
          download={t('settings:sections:locations:upload:locationTemplate')}
          className={icon}
          data-test-id="ManageLocations-defaultCSV"
        >
          {t('settings:sections:locations:upload:download')}
        </Link>
      </Typography>
      <LocationsCsvUpload />
    </div>
  );
};

export default ManageLocationsContent;
