import * as React from 'react';

import { CardContent, CardHeader, MenuItem, MenuList, Theme, Typography } from '@mui/material';

import MailIcon from '@mui/icons-material/Mail';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search';
import { SearchOptions } from 'onec-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useSetPreviousMapBounds from '../../../../Hooks/useSetPreviousMapBounds';
import { App } from '../../../../PlanningApp/AppConfig';
import {
  BuiltFragmentFragmentDoc,
  LocationFragmentFragmentDoc,
  RecentViewType,
  useGetRecentViewsQuery,
} from '../../../../__generated__/graphql';
import VulnerableLocation from './VulnerableLocation';

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    margin: theme.spacing(2),
  },
  headerText: {
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 auto',
  },
  scrollContainer: {
    overflowY: 'auto',
    margin: theme.spacing(2, 0),
    padding: 0,
    paddingRight: theme.spacing(3),
  },
  itemContainer: {
    whiteSpace: 'pre-wrap',
    alignItems: 'flex-start',
  },
}));

const RecentlyViewedLocations: React.FC<any> = () => {
  const { data: RecentViewData, client } = useGetRecentViewsQuery({ variables: {} });
  const { recentViews } = RecentViewData;
  const { scrollContainer, itemContainer, headerText, headerContainer } = useStyles();
  const { t } = useTranslation();

  // TODO : Reuse this logic from search locations
  const setMapBounds = useSetPreviousMapBounds();
  const navigate = useNavigate();
  const options: SearchOptions = [];
  if (recentViews && recentViews.length > 0) {
    recentViews.forEach((recentView) => {
      const locOrBobj = client.readFragment({
        id:
          recentView.type === RecentViewType.Location
            ? `LocationObject:{"id":"${recentView.id}"}`
            : `BuiltObject:{"id":"${recentView.id}"}`,
        fragment:
          recentView.type === RecentViewType.Location
            ? LocationFragmentFragmentDoc
            : BuiltFragmentFragmentDoc,
        fragmentName:
          recentView.type === RecentViewType.Location ? 'locationFragment' : 'builtFragment',
      });
      if (locOrBobj) {
        options.push({
          data: locOrBobj,
          groupType: 'RecentViewObject',
        } as SearchOptions[0]);
      } else {
        App.error(
          `[LocationSearch - RecentView error] - No ${
            recentView.type === RecentViewType.Location ? 'Location' : 'BuiltObject'
          } found in Cache for ${recentView.type}:${recentView.id}`,
        );
      }
    });
  }
  // TODO : Reuse this logic from vllcontent
  const goToDetailView = React.useCallback(
    (e: React.MouseEvent) => {
      if (e.currentTarget instanceof HTMLElement && e.currentTarget.dataset.id) {
        const { id } = e.currentTarget.dataset;
        setMapBounds();
        navigate(`/analysis/detail/${id}`);
      }
    },
    [navigate, setMapBounds],
  );

  return (
    <div className="u-paddingy--8" data-test-id="ViewedLocations-list">
      <CardContent className={scrollContainer} data-test-id="ViewedLocations-list-content">
        <CardHeader
          className={`${headerContainer} u-no-padding`}
          title={
            <div className={headerText}>
              <Typography variant="h2" data-test-id="ViewedLocations-list-title">
                {t('recentlyViewed:title')}
              </Typography>
            </div>
          }
        />
        {options.length > 0 && (
          <MenuList disablePadding>
            {options.map((option: SearchOptions[0], index) => (
              <MenuItem
                key={option?.data?.id}
                className={itemContainer}
                onClick={goToDetailView}
                tabIndex={0}
                data-id={option?.data?.id}
                data-test-id={`ViewedLocations-list:${option?.data?.id}`}
              >
                {/* TODO rename all these components */}
                <VulnerableLocation
                  key={option?.data?.id}
                  id={option?.data?.id}
                  rank={index + 1}
                  name={option?.data?.name}
                  address={option?.data?.address}
                />
              </MenuItem>
            ))}
          </MenuList>
        )}
        {options.length === 0 && (
          <div
            className="o-flex-center-container u-flex-column u-paddingx--16 u-paddingy--32 u-marginbottom--100"
            data-test-id="EmptyViewedLocations"
          >
            <SearchIcon sx={{ verticalAlign: 'middle', fontSize: 75 }} />
            <div className="u-flex-column">
              <Typography
                variant="body1"
                className="u-paddingtop--10 u-paddingbottom--24"
                color="textPrimary"
              >
                {t('recentlyViewed:searchHint')}
              </Typography>
              <Typography variant="caption" color="textPrimary">
                <MailIcon fontSize="small" sx={{ verticalAlign: 'middle', marginRight: '5px' }} />
                {t('recentlyViewed:emailHint')}
              </Typography>
            </div>
          </div>
        )}
      </CardContent>
    </div>
  );
};

export default RecentlyViewedLocations;
