import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SYWSectionProps } from 'onec-types';
import { useLocation } from 'react-router-dom';

import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';

import ReturnPeriodChart from '../../Charts/ReturnPeriodChart';
import showYourWorkStyles from './showYourWorkStyles';
import useHasFinanceEntitlement from '../../../../Hooks/useHasFinanceEntitlement';

const SECTION_KEY = 'showYourWork:sections:structure:';

const SYWStructureFC: React.FC<SYWSectionProps> = ({ stats, gutterTop }) => {
  const { t } = useTranslation();
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;
  const { mean, stddev, threshold, topAssets, returnPeriodData } = stats;
  const { pathname }: { pathname: string } = useLocation();
  const { subtitle, statLabel, statValue, range, table } = showYourWorkStyles({
    isVulnerable: mean > threshold,
    lightScrollBar: !pathname?.includes('/analysis/see-the-math'),
  });

  const { min, max, constructionType, yearBuilt, stories } = React.useMemo(() => {
    const building = topAssets?.[0];
    const result: {
      min: string;
      max: string;
      constructionType: string;
      yearBuilt: string;
      stories: number;
    } = {
      min: t('units:percent', { num: Math.max(0, mean - stddev).toLocaleString() }),
      max: t('units:percent', { num: (mean + stddev).toLocaleString() }),
      constructionType: building?.constructionType ?? t('nA'),
      yearBuilt: building?.yearBuilt ?? t('nA'),
      stories: building?.stories ?? t('nA'),
    };

    return result;
  }, [t, mean, stddev, topAssets]);

  return (
    <section
      className="u-width--100"
      /// gutterTop is true when displayed under SYW content
      id={`structure-section${!gutterTop ? '-detail' : ''}`}
      data-test-id="SYWStructure"
    >
      <Typography style={gutterTop && { marginTop: '28px' }} variant="h2" paragraph>
        {t(`${SECTION_KEY}damage:title`)}
      </Typography>

      <Typography className={statValue} data-test-id="SYWStructure-mean">
        {t('units:percent', { num: mean.toLocaleString() })}
      </Typography>
      <Typography className={statLabel} paragraph>
        {t(`${SECTION_KEY}damage:averageRatio`)}
      </Typography>
      <Typography variant="h5" className={range} gutterBottom data-test-id="damageRange">
        {min !== max && max !== '0%' && t('showYourWork:range', { min, max })}
      </Typography>
      <Typography className={statLabel} paragraph>
        {t('showYourWork:threshold', { value: t('units:percent', { num: threshold }) })}
      </Typography>

      <Typography paragraph>{t(`${SECTION_KEY}damage:desc`)}</Typography>
      <Typography paragraph>
        <strong>{t('showYourWork:resultsBasedOn')}</strong>
      </Typography>

      <Typography variant="subtitle1" className={subtitle} gutterBottom>
        {t(`${SECTION_KEY}contributors:title`)}
      </Typography>
      <Typography paragraph>
        {t(`${SECTION_KEY}contributors:${isFinanceEntitled ? 'descCatnet' : 'desc'}`)}
      </Typography>

      {!isFinanceEntitled && (
        <TableContainer className={table} data-test-id="SYWStructure-infoTable">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <strong>{t(`${SECTION_KEY}table:constructionType`)}</strong>
                </TableCell>
                <TableCell data-test-id="SYWStructure-type">{constructionType}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>{t(`${SECTION_KEY}table:yearBuilt`)}</strong>
                </TableCell>
                <TableCell data-test-id="SYWStructure-year">{yearBuilt}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>{t(`${SECTION_KEY}table:numberOfStories`)}</strong>
                </TableCell>
                <TableCell data-test-id="SYWStructure-stories">{stories}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Typography variant="subtitle1" className={subtitle} gutterBottom>
        {t(`${SECTION_KEY}severityImpact:title`)}
      </Typography>
      <Typography paragraph>{t(`${SECTION_KEY}severityImpact:desc`)}</Typography>
      {returnPeriodData?.length > 0 && (
        <ReturnPeriodChart points={returnPeriodData} threshold={threshold} lifeline="structure" />
      )}

      <Typography paragraph>
        <Trans
          i18nKey={`showYourWork:${
            isFinanceEntitled ? 'exploreAnalysisCatnet' : 'exploreAnalysis'
          }`}
        >
          <strong>Did you know?</strong>
        </Trans>
      </Typography>
    </section>
  );
};

const SYWStructure = React.memo(SYWStructureFC);
SYWStructure.displayName = 'SYWStructure';
export default SYWStructure;
