import * as React from 'react';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { List, ListSubheader, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import AllLocationsSelector from './AllLocationsSelector';
import { allLocFilterChoicesVar } from '../../../../PlanningApp/client/cache';
// import LocationsCategorySearch from './LocationsCategorySearch'; // TODO - add back later
import LocationsFilterGroup from './LocationsFilterGroup';
import { LocFilter } from '../../../../__generated__/graphql';

type LocationsPickerFormBodyProps = {
  preSubmitVal: LocFilter;
  hiddenGroups?: boolean;
  onChangePreSubmitVal: (newPreSubmit: Partial<LocFilter>) => void;
};

const useStyles = makeStyles(() => ({
  pickerHoverListScrolling: {
    minHeight: 150,
    maxHeight: 400,
    overflowY: 'auto',
  },
}));

const LocationsPickerFormBodyFC: React.FC<LocationsPickerFormBodyProps> = ({
  preSubmitVal,
  onChangePreSubmitVal,
  hiddenGroups,
}) => {
  const allLocsTypesForFilter = useReactiveVar(allLocFilterChoicesVar);
  const { locGroups: allLocGroups, locTypes: allLocTypes } = allLocsTypesForFilter;

  const { t } = useTranslation();
  const classes = useStyles();

  const { locTypes, locGroups } = preSubmitVal;

  const numFilters = locGroups.length + locTypes.length;
  const allIsChecked =
    numFilters === 0 || numFilters === (allLocGroups?.length ?? 0) + (allLocTypes?.length ?? 0);

  const onSelectAllLocations = React.useCallback(() => {
    onChangePreSubmitVal({ locTypes: [], locGroups: [] });
  }, [onChangePreSubmitVal]);

  const onChangeSelected = React.useCallback(
    (newList: string[], name: string) => {
      let newLocFilter = {
        locTypes: name === 'types' ? newList : locTypes,
        locGroups: name === 'groups' ? newList : locGroups,
      };
      if (
        newLocFilter?.locGroups?.length === allLocGroups?.length &&
        newLocFilter?.locTypes?.length === allLocTypes?.length
      ) {
        newLocFilter = { locTypes: [], locGroups: [] };
      }
      onChangePreSubmitVal(newLocFilter);
    },
    [locGroups, locTypes, onChangePreSubmitVal, allLocGroups, allLocTypes],
  );

  if ((allLocGroups?.length ?? 0) + (allLocTypes?.length ?? 0) === 0) {
    return null;
  }

  return (
    <div data-test-id="locations-picker-form">
      {/* TODO - reenable the following once the LocationsCategorySearch component is done */}
      {/* <LocationsCategorySearch /> */}
      <AllLocationsSelector
        allIsChecked={allIsChecked}
        onSelectAllLocations={onSelectAllLocations}
      />

      <List
        disablePadding
        classes={{ root: classes.pickerHoverListScrolling }}
        role="menu"
        data-test-id="location-groups-and-types-menu"
      >
        {!hiddenGroups && allLocGroups.length > 0 && (
          <>
            <ListSubheader data-test-id="subheader-location-groups">
              <div>{t('locationGroups:locGroup_other')}</div>
              <Typography variant="caption" color="textSecondary">
                {`${
                  allIsChecked ? t('locationGroups:all').toLowerCase() : locGroups.length ?? 0
                } ${t('locationGroups:selected')}`}
              </Typography>
            </ListSubheader>
            <LocationsFilterGroup
              selectedCategories={locGroups}
              allCategories={allLocGroups}
              name="groups"
              onChangeSelected={onChangeSelected}
              data-test-id="location-groups-menu"
            />
          </>
        )}
        {allLocTypes.length > 0 && (
          <>
            <ListSubheader data-test-id="subheader-location-types">
              <div>{t('locationGroups:locType_other')}</div>
              <Typography variant="caption" color="textSecondary">
                {`${
                  allIsChecked ? t('locationGroups:all').toLowerCase() : locTypes.length ?? 0
                }  ${t('locationGroups:selected')}`}
              </Typography>
            </ListSubheader>
            <LocationsFilterGroup
              selectedCategories={locTypes}
              allCategories={allLocTypes}
              name="types"
              onChangeSelected={onChangeSelected}
              data-test-id="location-types-menu"
            />
          </>
        )}
      </List>
    </div>
  );
};

const LocationsPickerFormBody = React.memo(LocationsPickerFormBodyFC);
LocationsPickerFormBody.displayName = 'LocationsPickerFormBody';
export default LocationsPickerFormBody;
