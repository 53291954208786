import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Avatar, CircularProgress, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PersonIcon from '@mui/icons-material/Person';

import { LanguagePreferences, useGetCurrentUserQuery } from '../../../../__generated__/graphql';
import { App } from '../../../../PlanningApp/AppConfig';
import useApiErrSnackbar from '../../../CommonComponents/Snackbar/useApiErrSnackbar';

const useStyles = makeStyles(() => ({
  profileBtn: {
    padding: '0 !important',
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
}));

const getUserInitials = (
  language: LanguagePreferences,
  firstName: string | null,
  lastName: string | null,
) => {
  if (language !== LanguagePreferences.Ja) {
    return `${firstName?.[0] ?? ''}${lastName?.[0] ?? ''}`;
  }
  return '';
};

const UserProfileIcon: React.FC = () => {
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();
  const classes = useStyles();
  const navigate = useNavigate();
  const onClickProfile = React.useCallback(() => navigate('/profile'), [navigate]);

  const { data, loading, error } = useGetCurrentUserQuery();

  React.useEffect(() => {
    if (error) {
      App.error('[UserProfileIcon] - GetCurrentUserFailed: ', error);
      enqueueApiErrSnackbar();
      // If error, will return a generic Avatar icon instead of user initials.
    }
  }, [error, enqueueApiErrSnackbar]);

  if (loading) {
    return <CircularProgress />;
  }

  const lastName = data?.user?.personalInfo?.lastName; // allowed to be null, per contract
  const firstName = data?.user?.personalInfo?.firstName;
  const language = data?.user?.preferences?.language;
  const userInitials = getUserInitials(language, firstName, lastName);

  return (
    <IconButton
      aria-label="User Profile"
      aria-haspopup="true"
      aria-controls="user-profile"
      data-test-id="user-profile"
      onClick={onClickProfile}
      className={classes.profileBtn}
    >
      <Avatar>{userInitials === '' ? <PersonIcon /> : userInitials}</Avatar>
    </IconButton>
  );
};
UserProfileIcon.displayName = 'UserProfileIcon';
export default UserProfileIcon;
