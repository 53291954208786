import * as React from 'react';
import { SnackbarKey, useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import {
  AMP_EVENT_APPLY_HAZARD,
  AMP_EVENT_UPLOAD_LOCATIONS,
} from '../../../plugins/amplitudeevents';
import {
  GetLocationsFileDetailsDocument,
  GetLocationsFileIdDocument,
  GetLocationsWithFilterDocument,
  GetLocationTypesDocument,
  GetMaterialityKpIsDocument,
  GetMaterialitySettingsDocument,
  GetOriginalLocationsFileDocument,
  LocationFile,
  useGetLocationUploadFileQuery,
  useGetSelectedHazardQuery,
  useGetSliceIdxQuery,
  useSetLocationUploadFileMutation,
  useUploadLocationsFileMutation,
} from '../../../__generated__/graphql';
import { App } from '../../../PlanningApp/AppConfig';
import ReviewSnackbarButton from '../../CommonComponents/Snackbar/ReviewSnackbarButton';
import { sendAmplitudeData } from '../../../plugins/amplitude';
import StyledSnackbarContent from '../../CommonComponents/Snackbar/StyledSnackbarContent';

const reviewButton = (key: SnackbarKey) => <ReviewSnackbarButton id={key} href="/locations" />;

type LocationsUploadProgressBarProps = {
  userId: string;
};

const LocationsUploadProgressBar: React.FC<LocationsUploadProgressBarProps> = ({ userId }) => {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [openFailure, setOpenFailure] = React.useState<boolean>(false);
  const [openSuccess, setOpenSuccess] = React.useState<boolean>(false);
  const [progressSnackbarKey, setProgressSnackbarKey] = React.useState<SnackbarKey>(null);
  const [setLocationUploadFile] = useSetLocationUploadFileMutation();
  const {
    data: { planView },
  } = useGetSliceIdxQuery();
  const {
    data: {
      planView: {
        planFilters: { hazardType },
      },
    },
  } = useGetSelectedHazardQuery();

  const [uploadLocationsFileMutation, { data, loading, error }] = useUploadLocationsFileMutation({
    refetchQueries: [
      {
        query: GetLocationsWithFilterDocument,
        variables: {
          sliceIdx: planView?.sliceIdx,
          input: { matchCriteria: { isPendingMIB: false } },
        },
      },
      { query: GetLocationsFileDetailsDocument },
      { query: GetOriginalLocationsFileDocument },
      { query: GetLocationTypesDocument },
      { query: GetLocationsFileIdDocument },
      { query: GetMaterialitySettingsDocument },
      {
        query: GetMaterialityKpIsDocument,
        variables: { sliceIdx: planView?.sliceIdx },
      },
    ],
  });
  const {
    data: {
      locationUploadFile: { file },
    },
  } = useGetLocationUploadFileQuery();

  React.useEffect(() => {
    if (file) {
      uploadLocationsFileMutation({
        variables: {
          file,
        },
      });
      setLocationUploadFile({
        variables: {
          file: null,
        },
      });
    }
  }, [file, setLocationUploadFile, uploadLocationsFileMutation]);

  const triggerAmplitude = React.useCallback(
    (
      id: string,
      totalRows: number,
      totalAccepted: number,
      totalRejected: number,
      uploadedAt: string,
    ) => {
      if (userId) {
        sendAmplitudeData(AMP_EVENT_UPLOAD_LOCATIONS, {
          userId,
          fileId: id,
          totalRows,
          totalAccepted,
          totalRejected,
          uploadedAt,
        });
        sendAmplitudeData(AMP_EVENT_APPLY_HAZARD, {
          userId,
          hazard: hazardType,
        });
      }
    },
    [hazardType, userId],
  );

  React.useEffect(() => {
    if (data) {
      const { id, totalAccepted, totalRejected, totalRows, uploadedAt } = data.uploadLocationsFile;
      if (totalRows === totalAccepted) {
        setOpenSuccess(true);
      } else {
        setOpenFailure(true);
      }
      triggerAmplitude(id, totalRows, totalAccepted, totalRejected, uploadedAt);
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (error) {
      App.error('[LocationsUploadProgressBar uploadLocationsFileMutation] error: ', error);
      if (progressSnackbarKey) closeSnackbar(progressSnackbarKey);

      setLocationUploadFile({
        variables: {
          file: null,
        },
      });
      enqueueSnackbar(
        <StyledSnackbarContent
          title={t('manageLocations:uploadErrorTitle')}
          description={t('manageLocations:uploadErrorDesc')}
        />,
        {
          variant: 'error',
          persist: true,
          action: reviewButton,
        },
      );
    }
  }, [error, setLocationUploadFile, enqueueSnackbar, progressSnackbarKey, closeSnackbar, t]);

  React.useEffect(() => {
    if (loading) {
      const snackbarKey = enqueueSnackbar(
        <StyledSnackbarContent
          title={t('manageLocations:processingLocations')}
          description={t('manageLocations:processingLocationsDesc')}
          spinner
        />,
        { variant: 'default', persist: true },
      );
      setProgressSnackbarKey(snackbarKey);
    }
  }, [enqueueSnackbar, loading, setProgressSnackbarKey, t]);

  React.useEffect(() => {
    if (openSuccess && data?.uploadLocationsFile) {
      if (progressSnackbarKey) closeSnackbar(progressSnackbarKey);

      const { totalAccepted, totalWarnings } = data?.uploadLocationsFile as LocationFile;
      setOpenSuccess(false);
      setTimeout(
        () =>
          enqueueSnackbar(
            <StyledSnackbarContent
              title={t('manageLocations:locationsProcessed', { num: totalAccepted })}
            />,
            {
              variant: totalWarnings > 0 ? 'warning' : 'success',
              action: reviewButton,
            },
          ),
        300,
      );
    }
  }, [closeSnackbar, data, enqueueSnackbar, openSuccess, progressSnackbarKey, t]);

  React.useEffect(() => {
    if (openFailure) {
      if (progressSnackbarKey) closeSnackbar(progressSnackbarKey);

      setOpenFailure(false);
      setTimeout(
        () =>
          enqueueSnackbar(
            <StyledSnackbarContent
              title={
                data
                  ? t('manageLocations:locationsProcessed', {
                      num: data.uploadLocationsFile.totalAccepted,
                    })
                  : t('manageLocations:errors:title')
              }
              description={t('manageLocations:errors:description')}
            />,
            {
              variant: data ? 'warning' : 'error',
              persist: true,
              action: reviewButton,
            },
          ),
        300,
      );
    }
  }, [closeSnackbar, data, enqueueSnackbar, openFailure, progressSnackbarKey, t]);

  return null;
};

LocationsUploadProgressBar.displayName = 'LocationsUploadProgressBar';
export default LocationsUploadProgressBar;
