import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';
import { SWIPE_ANIMATE_STYLE } from '../../../../../util/productGlobals';
import Tabs from '../../../../CommonComponents/Tabs/Tabs';
import TabPanel from '../../../../CommonComponents/TabPanel/TabPanel';
import LossTable from './LossTable';
import PriceTable from './PriceTable';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 894,
  },
}));

const MaterialityDriversSettings = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [currentTabIndex, setCurrentTabIndex] = React.useState(0);

  const handleChangeIndex = React.useCallback((index: number) => {
    setCurrentTabIndex(index);
  }, []);

  return (
    <div className={classes.container} data-test-id="MaterialityDriversSettings">
      <Typography variant="h3">{t('materiality:revenueTable:title')}</Typography>
      <Typography variant="body1" mt={2} mb={8}>
        {t('materiality:sections:settings:drivers:description')}
      </Typography>
      <Tabs
        tabList={[
          t('datetime:hours', {
            hr: 12,
            count: 12,
          }),
          t('datetime:days', {
            days: 1,
            count: 1,
          }),
          t('datetime:days', {
            days: 7,
            count: 7,
          }),
          t('materiality:sections:settings:drivers:annualRevenue'),
          t('materiality:sections:settings:drivers:additionalCost'),
        ]}
        spacing={32}
        currentTabIndex={currentTabIndex}
        setCurrentTabIndex={handleChangeIndex}
      />
      <SwipeableViews
        index={currentTabIndex}
        onChangeIndex={handleChangeIndex}
        containerStyle={SWIPE_ANIMATE_STYLE}
      >
        {[0.5, 1, 7].map((downtime, index) => (
          <TabPanel
            key={`TabPanel-Downtime-${downtime}`}
            value={currentTabIndex}
            index={index}
            data-test-id={`TabPanel-${index}`}
          >
            <LossTable downtime={downtime} />
          </TabPanel>
        ))}
        <TabPanel value={currentTabIndex} index={3} data-test-id="TabPanel-3">
          <PriceTable dataKey="annualRevenue" />
        </TabPanel>
        <TabPanel value={currentTabIndex} index={4} data-test-id="TabPanel-4">
          <PriceTable dataKey="additionalCost" />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
};

export default MaterialityDriversSettings;
