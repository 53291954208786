import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ResStatsArray } from 'onec-types';

import { Typography } from '@mui/material';

import { ResilienceThresholds, SliceHazardType } from '../../../../../__generated__/graphql';
import useLifelineEntitlements, {
  LifelineEntitlementsType,
} from '../../../../../Hooks/useLifelineEntitlements';
import { App } from '../../../../../PlanningApp/AppConfig';
import { capitalize } from '../../../../../util/string';
import i18next from '../../../../../PlanningApp/LocalizationClient';
import { Lifeline } from '../../../../../util/productEnums';
import LifelinesDataTable from '../../Common/LifelinesDataTable';
import { LifelinesTopConcernsTableSkeleton } from '../Skeleton/LocationDetailCardSkeleton';

export type DowntimeDatum = {
  lifeline: string;
  downtime: number | string;
  threshold: number;
  isVulnerable: boolean;
};

export function createTopConcernsData(
  lifeline: string,
  downtime: number | string,
  threshold: number,
  isVulnerable: boolean,
): DowntimeDatum {
  return { lifeline, downtime, threshold, isVulnerable };
}

export type StructureData = {
  lifeline: string;
  damage: number | string;
  structureThreshold: number | string;
  isVulnerable: boolean;
};

const averageArr: (nums: Array<number>) => number | null = (nums) => {
  if (!nums || !nums.length) {
    return null;
  }
  const sum = nums.reduce((a, b) => a + b, 0);
  return Math.floor(sum / nums.length);
};

export function createStructureData(
  lifeline: string,
  damage: number | string,
  structureThreshold: number | string,
  isVulnerable: boolean,
): StructureData {
  return { lifeline, damage, structureThreshold, isVulnerable };
}

// Calculate Top Concern Data. For the active slice's resilience stats ONLY,
// downtime is the average downtime for the lifeline at the location.
// threshold is the user specified threshold for the lifeline.
// isVulnerable is true if downtime > threshold, false otherwise.
// lifelines are then sorted by downtime in descending order.
export const calculateTopConcernData = (
  resilienceStats: ResStatsArray,
  thresholds: ResilienceThresholds,
  activeSlice: number,
  entitlements: LifelineEntitlementsType,
): DowntimeDatum[] => {
  const nA = i18next.t('na');

  if (resilienceStats?.length !== 1 && resilienceStats?.[0].slice !== activeSlice) {
    App.debug(
      '[Lifelines] - calculateTopConcernData needs resilienceStats for JUST the activeslice',
    );
    return [];
  }

  const downtimeData: DowntimeDatum[] = [];
  if (entitlements) {
    Object.entries(entitlements).forEach(([lifeline, entitled]) => {
      if (entitled && lifeline !== Lifeline.STRUCTURE) {
        downtimeData.push(createTopConcernsData(lifeline, 0, 0, false));
      }
    });
  }

  downtimeData.forEach((item) => {
    item.downtime = (averageArr(
      resilienceStats.map((stat: Record<string, any>) => {
        const lifelineStat = stat[`nearby${capitalize(item.lifeline)}`];
        return lifelineStat ? parseInt(lifelineStat.mean, 10) : 0;
      }),
    ) ?? nA) as string | number;
    item.threshold = (thresholds as Record<string, any>)[`${item.lifeline}Downtime`] || 0;
    item.isVulnerable = typeof item.downtime !== 'string' && item.downtime > item.threshold;
  });
  downtimeData.sort((a, b) => {
    if (typeof a.downtime === 'string' && typeof b.downtime === 'string') return 0;
    if (typeof b.downtime === 'string') return -1;
    if (typeof a.downtime === 'string') return 1;
    if (b.downtime === a.downtime) {
      return b.downtime - b.threshold - (a.downtime - a.threshold);
    }
    return b.downtime - a.downtime;
  });
  return downtimeData;
};

// Calculate Structure Data
export const calculateStructureData = (
  resilienceStats: ResStatsArray,
  thresholds: ResilienceThresholds,
  activeSlice: number,
  entitlements: LifelineEntitlementsType,
): StructureData[] => {
  const nA = i18next.t('na');

  if (resilienceStats?.length !== 1 && resilienceStats?.[0].slice !== activeSlice) {
    App.debug(
      '[TopConcernsTab ] - calculateStructureData needs resilienceStats for JUST the activeslice',
    );
    return [];
  }
  if (!entitlements?.structure) {
    return [];
  }
  const structureData = [createStructureData('structure', 'n/a', 'n/a', false)];
  structureData[0].damage = averageArr(
    resilienceStats.map((stat) => {
      const lifelineStat = stat.structuralDamage;
      return lifelineStat ? lifelineStat.mean : 0;
    }),
  );
  structureData[0].structureThreshold = thresholds.structuralDamage || nA;
  if (
    typeof structureData[0].damage === 'number' &&
    typeof structureData[0].structureThreshold === 'number'
  )
    structureData[0].isVulnerable = structureData[0].damage > structureData[0].structureThreshold;
  return structureData;
};

const TopConcernsTab: React.FC<{
  resilienceStatsArr: ResStatsArray;
  thresholds: ResilienceThresholds;
  activeSlice: number;
  hazardType: SliceHazardType;
  showSkeleton: boolean;
  showLifelineDetailView: (lifeline: Lifeline) => void;
}> = ({
  resilienceStatsArr,
  thresholds,
  activeSlice,
  hazardType,
  showSkeleton,
  showLifelineDetailView,
}) => {
  const { t } = useTranslation();
  const entitlements = useLifelineEntitlements();

  const [topConcernsData, setTopConcernsData] = React.useState<DowntimeDatum[]>([]);
  const [structureData, setStructureData] = React.useState<StructureData[]>([]);

  React.useEffect(() => {
    if (resilienceStatsArr?.length > 0) {
      const downtimeTopConcernsData = calculateTopConcernData(
        resilienceStatsArr,
        thresholds,
        activeSlice,
        entitlements,
      );
      setTopConcernsData(downtimeTopConcernsData);
      const structureTopConcernsData = calculateStructureData(
        resilienceStatsArr,
        thresholds,
        activeSlice,
        entitlements,
      );
      setStructureData(structureTopConcernsData);
    }
  }, [resilienceStatsArr, thresholds, activeSlice, entitlements]);

  const numDowntimeEntitlements = React.useMemo(() => {
    if (!entitlements) return 0;
    return Object.entries(entitlements).filter(([lifeline, val]) => lifeline !== 'structure' && val)
      .length;
  }, [entitlements]);
  const expectedNumStructureRows = entitlements?.structure ? 1 : 0;

  const topConcernsReady =
    !showSkeleton &&
    resilienceStatsArr?.length === 1 && // activeSlice only
    activeSlice === resilienceStatsArr?.[0]?.slice &&
    numDowntimeEntitlements === topConcernsData?.length &&
    expectedNumStructureRows === structureData?.length;

  return (
    <div data-test-id="TopConcernsTab">
      <Typography variant="body2" className="u-paddingbottom--16" color="textPrimary">
        {t('detailView:lifelines:topConcerns:description')}
      </Typography>
      {topConcernsReady && entitlements ? (
        <>
          {numDowntimeEntitlements >= 1 && (
            <LifelinesDataTable
              headerFields={[
                t('lifeline:title'),
                t('detailView:lifelines:downtime'),
                t('detailView:lifelines:threshold'),
              ]}
              data={topConcernsData}
              hazardType={hazardType}
              showLifelineDetailView={showLifelineDetailView}
              dataTestId="downtimeLifelines"
            />
          )}
          {entitlements?.structure && (
            <LifelinesDataTable
              headerFields={['', t('damage'), t('detailView:lifelines:threshold')]}
              data={structureData}
              hazardType={hazardType}
              showLifelineDetailView={showLifelineDetailView}
              dataTestId="damageLifelines"
            />
          )}
        </>
      ) : (
        <LifelinesTopConcernsTableSkeleton />
      )}
    </div>
  );
};
TopConcernsTab.displayName = 'TopConcernsTab';
export default TopConcernsTab;
