import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { App } from '../../../../PlanningApp/AppConfig';
import StyledSwitch from './StyledSwitch';
import { useUpdateSelectedClimateMutation } from '../../../../__generated__/graphql';
import { sendAmplitudeData } from '../../../../plugins/amplitude';
import { AMP_EVENT_CLIMATE_TOGGLE } from '../../../../plugins/amplitudeevents';

const ClimateToggle: React.FC<{ isClimateSwitchOn: boolean; disabled?: boolean }> = ({
  isClimateSwitchOn,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const [updateSelectedClimateMutation] = useUpdateSelectedClimateMutation();

  const onClimateChange = React.useCallback(
    (newIsClimateSwitchOn: boolean) => {
      if (newIsClimateSwitchOn !== isClimateSwitchOn) {
        App.debug('[ClimateToggle] - change climate switch to: ', newIsClimateSwitchOn);
        updateSelectedClimateMutation({
          variables: {
            isClimateChangeEnabled: newIsClimateSwitchOn,
          },
        });
        sendAmplitudeData(AMP_EVENT_CLIMATE_TOGGLE, {
          climateState: newIsClimateSwitchOn ? 'on' : 'off',
        });
      }
    },
    [updateSelectedClimateMutation, isClimateSwitchOn],
  );

  return (
    <StyledSwitch
      dataTestId="climate-toggle"
      label={t('climate')}
      options={[
        { label: t('off'), value: false },
        { label: t('on'), value: true },
      ]}
      onChange={onClimateChange}
      selected={isClimateSwitchOn}
      disabled={disabled}
    />
  );
};
ClimateToggle.displayName = 'ClimateToggle';
export default ClimateToggle;
