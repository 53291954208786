import * as React from 'react';
import { CardContent, Divider, Skeleton, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { BORDER_RADIUS } from '../../../../util/productGlobals';

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    margin: theme.spacing(0, 0, 4, 0),
  },
  spanLine: {
    display: 'flex',
    flexDirection: 'row',
  },
  firstLineCircle: {
    margin: theme.spacing(0, 2),
  },
  textLines: {
    borderRadius: BORDER_RADIUS,
    margin: 3,
  },
  indentWrapper: {
    marginLeft: 38,
  },
  chips: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginLeft: 0,
    borderRadius: 100,
  },
  divider: {
    margin: theme.spacing(4, 0),
  },
  listChipCountLine: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(0, 0, 4, 2),
  },
  scrollContainer: {
    overflowY: 'auto',
    margin: theme.spacing(2, 0),
    padding: 0,
    paddingRight: theme.spacing(3),
  },
}));

export const SkeletonVulnerableLocation: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.itemWrapper} data-test-id="skeleton-vll-item">
      <div className={classes.spanLine}>
        <Skeleton
          variant="circular"
          width={22}
          height={22}
          classes={{ root: classes.firstLineCircle }}
        />
        <Skeleton
          variant="rectangular"
          height={16}
          width={220}
          classes={{ root: classes.textLines }}
        />
      </div>
      <div className={classes.indentWrapper}>
        <Skeleton
          variant="rectangular"
          height={14}
          width={180}
          classes={{ root: classes.textLines }}
        />
        <Skeleton
          variant="rectangular"
          height={14}
          width={120}
          classes={{ root: classes.textLines }}
        />

        <div className={classes.spanLine}>
          <Skeleton
            variant="rectangular"
            height={26}
            width={80}
            classes={{ root: classes.chips }}
          />
          <Skeleton
            variant="rectangular"
            height={26}
            width={100}
            classes={{ root: classes.chips }}
          />
        </div>
      </div>
    </div>
  );
};
SkeletonVulnerableLocation.displayName = 'SkeletonVulnerableLocation';

type SkeletonVLLProps = {
  numItems: number;
  expanded?: boolean;
};

const SkeletonVLL: React.FC<SkeletonVLLProps> = ({ numItems, expanded = true }) => {
  const classes = useStyles();
  const skeletonItems: React.ReactNode[] = [];
  for (let i = 0; i < numItems; i += 1) {
    skeletonItems.push(<SkeletonVulnerableLocation key={i} />);
    if (i < numItems - 1) {
      skeletonItems.push(<Divider classes={{ root: classes.divider }} key={`divider${i}`} />);
    }
  }
  return (
    <div data-test-id="skeleton-vll-list">
      <div className={classes.listChipCountLine}>
        <Skeleton variant="rectangular" height={26} width={80} classes={{ root: classes.chips }} />
        <Skeleton variant="rectangular" height={26} width={100} classes={{ root: classes.chips }} />
      </div>
      {expanded && (
        <CardContent
          classes={{ root: classes.scrollContainer }}
          data-test-id="skeletonVulnerableLocationsList"
        >
          {skeletonItems}
        </CardContent>
      )}
    </div>
  );
};
SkeletonVLL.displayName = 'SkeletonVLL';
export default SkeletonVLL;
