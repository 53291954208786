import { Address, EditAddressInput } from '../../__generated__/graphql';

import { getStreetWithNumJP } from './addressUtilsJP';
import i18next from '../../PlanningApp/LocalizationClient';
import { invert } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const parser = require('parse-address'); // This is a US Street Address Parser

const ZIPCODE_REGEX = '(?<zipCode>[\\d]{5}(-[\\d]{4})?)';
const STATE_ABBREV_REGEX = '(?<stateCode>[A-Z]{2})';
const STATE_NAME_REGEX = '(?<stateName>[A-Za-z ]{4,})'; // shortest state name is 4 letters (ohio)
const STATE_REGEX = `(${STATE_NAME_REGEX}|${STATE_ABBREV_REGEX})`;
const CITY_REGEX = "((?<city>[A-Za-z '-]+),[\\s]+)";
const STREET_REGEX = "((?<houseNumStreetAddr>[\\w, '.-]+),[\\s]+)";
const ADDR_REGEX = `(${STREET_REGEX}?${CITY_REGEX})?${STATE_REGEX}([\\s]+${ZIPCODE_REGEX})?$`;
const SPACE_CHAR = ' ';

const stateAbbrevDict: { [key: string]: string } = {
  Alabama: 'AL',
  Alaska: 'AK',
  'America Samoa': 'AS',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District of Columbia': 'DC',
  'District Of Columbia': 'DC',
  Micronesia: 'FM',
  Florida: 'FL',
  Georgia: 'GA',
  Guam: 'GU',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  'Marshall Islands': 'MH',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Palau: 'PW',
  Pennsylvania: 'PA',
  'Puerto Rico': 'PR',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  'Virgin Island': 'VI',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};
const abbrevToStateDict = invert(stateAbbrevDict);

export const deriveStateAbbrevUS: (stName: string) => string = (stName) => {
  if (!stName) {
    return '';
  }

  const stateName = stName?.trim();
  const stateNameWords = stateName.split(SPACE_CHAR).map((word) => word.trim());
  let stateAbbrev: string;
  if (stateNameWords.length === 1 && stateNameWords[0].length === 2) {
    // the "stateName" is one word and that word is two letters, which means it must
    // actually already be a state abbreviation and not a full state name.
    stateAbbrev = stateNameWords[0]?.toUpperCase();
  } else {
    // we have to derive the state's abbreviation from the state's full name
    const capitalizedFullStateName = stateNameWords
      .map((word: string) => {
        const [char0, ...rest] = word.toLowerCase().split('');
        return [char0?.toUpperCase(), ...rest].join('');
      })
      .join(SPACE_CHAR);
    stateAbbrev = stateAbbrevDict[capitalizedFullStateName];
  }
  // if we could not derive a legit stateAbbreviation, just return the original string
  return stateAbbrev ?? stateName;
};

export const isUnitedStatesAddressStr: (addrString: string) => boolean = (addrString) => {
  const addrStr = addrString.trim();
  const usCountryNameRegEx = /, United States$/;
  const usCountryCodeRegEx = /, USA$/;
  if (!!usCountryNameRegEx.exec(addrStr) || !!usCountryCodeRegEx.exec(addrStr)) {
    return true;
  }

  const usStateAbbrevRegEx = new RegExp(`, ${STATE_ABBREV_REGEX} ${ZIPCODE_REGEX}$`);
  const stateAbbrev = usStateAbbrevRegEx.exec(addrStr)?.groups?.stateCode;
  if (Object.keys(abbrevToStateDict).includes(stateAbbrev)) {
    return true;
  }

  const usFullStateRegEx = new RegExp(`, ${STATE_NAME_REGEX} ${ZIPCODE_REGEX}$`);
  const stateName = usFullStateRegEx.exec(addrStr)?.groups?.stateName;
  const abbrev = deriveStateAbbrevUS(stateName);
  if (Object.keys(abbrevToStateDict).includes(abbrev)) {
    return true;
  }
  return false;
};

export const compareStreetAddressesUS = (address1: string, address2: string): boolean => {
  return (
    (parser.parseLocation(address1)?.street?.toLowerCase() ?? '') ===
    (parser.parseLocation(address2)?.street?.toLowerCase() ?? '')
  );
};

export const fixBadAddressStrUS = (
  badAddressStr: string,
): { line1: string; line2: string; oneLine: string } => {
  const addrString = badAddressStr.trim();
  const countryRemovedRE = /(?<baseaddr>.*), United States$/;
  const res = countryRemovedRE.exec(addrString)?.groups;
  const addrStrNoCountry = res?.baseaddr ?? addrString;

  const addrRE = new RegExp(ADDR_REGEX);
  const addrResult = addrRE.exec(addrStrNoCountry)?.groups;
  if (!addrResult) {
    return { line1: null, line2: addrStrNoCountry, oneLine: addrStrNoCountry };
  }

  const { zipCode, stateCode, stateName, city, houseNumStreetAddr } = addrResult;
  const stateAbbrev = deriveStateAbbrevUS(stateCode || stateName);
  const line1 = houseNumStreetAddr;
  const line2 = `${city}${stateAbbrev || zipCode ? ', ' : ''}${stateAbbrev ?? ''}${
    zipCode ? SPACE_CHAR : ''
  }${zipCode ?? ''}`;
  return { line1, line2, oneLine: `${line1 ?? ''}${line1 && line2 ? ', ' : ''}${line2 ?? ''}` };
};

export const getDisplayableAddressUS: (address: Address | EditAddressInput) => string[] = (
  address,
) => {
  const { houseNumber, street, city, stateCode, postCode } = address;
  const line1 = `${houseNumber ?? ''}${houseNumber ? SPACE_CHAR : ''}${street ?? ''}`.trim();
  const line2 = `${city ?? ''}${city ? ', ' : ''}${stateCode ?? ''} ${postCode ?? ''}`.trim();

  if (!line1 && !line2) {
    return [i18next.t('address:unknownAddress'), ''];
  }
  return [line1, line2];
};

export function getOneLineAddressUS(address: Address | EditAddressInput): string {
  const [line1, line2] = getDisplayableAddressUS(address);
  return `${line1 ?? ''}${line1 && line2 ? ', ' : ''}${line2 ?? ''}`;
}

export function getStreetWithNum(street: string, houseNumber: string, countryCode: string) {
  switch (countryCode) {
    case 'JP':
      return getStreetWithNumJP(street, houseNumber);
    case 'US':
    default:
      return getStreetWithNumUS(street, houseNumber);
  }
}

export function getStreetWithNumUS(street: string, houseNumber: string) {
  const removeRedudantHouseNumRE = new RegExp(`^${houseNumber}[\\s]*`);
  const streetNoNum = street?.replace(removeRedudantHouseNumRE, '');
  return `${houseNumber ?? ''}${houseNumber ? ' ' : ''}${streetNoNum ?? ''}`;
}
