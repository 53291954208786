import * as React from 'react';
import { LocationSliceStats } from 'onec-types';
import { useTranslation } from 'react-i18next';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CardHeader,
  MenuList,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import Chip from '../../../CommonComponents/Chip/Chip';
import { getDisplayableAddress } from '../../../../util/addresses/addressUtils';
import SYWNavItem from './SYWNavItem';
import { useGetPlanViewFiltersQuery } from '../../../../__generated__/graphql';
import { WHITE } from '../../../../util/productGlobals';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    overflow: 'hidden',
    width: '420px',
    paddingBottom: '72px',
  },
  header: {
    marginLeft: theme.spacing(16),
    marginTop: theme.spacing(16),
    padding: 0,
  },
  locationInfo: {
    marginLeft: theme.spacing(16),
    marginTop: theme.spacing(10.5),
  },
  navContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    marginLeft: theme.spacing(14),
    marginTop: theme.spacing(8),
    marginRight: theme.spacing(2),
    '& .MuiAccordionSummary-root': {
      flexDirection: 'row-reverse',
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 'auto',
    },
    '& .MuiAccordionSummary-content': {
      margin: '0px 0px 0px 8px',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '0px 0px 0px 8px',
    },
  },
  lifelineNav: {
    overflow: 'auto',
    paddingRight: `${theme.spacing(22)} !important`,
  },
}));

type SYWSidebarProps = LocationSliceStats & {
  categories: string[];
  activeCategory: string;
  handleClick: (e: React.MouseEvent) => void;
};

const TRANSPORT_CATEGORIES = ['highway', 'bridge', 'airport', 'port'];

const ShowYourWorkSidebarFC: React.FC<SYWSidebarProps> = ({
  name,
  address,
  group,
  type,
  stats,
  activeCategory,
  handleClick,
  categories,
}) => {
  const { t } = useTranslation();
  const [transExpanded, setTransExpanded] = React.useState(true);
  const { container, header, locationInfo, navContainer, lifelineNav } = useStyles();
  const {
    data: {
      planView: { planFilters },
    },
  } = useGetPlanViewFiltersQuery();

  const [aLine1, aLine2] = getDisplayableAddress(address);

  const transCategories = categories.filter((category) => TRANSPORT_CATEGORIES.includes(category));
  const otherCategories = categories.filter((category) => !TRANSPORT_CATEGORIES.includes(category));

  return (
    <div className={container} data-test-id="ShowYourWorkSidebar">
      <CardHeader
        className={header}
        title={t('showYourWork:title')}
        titleTypographyProps={{ variant: 'h2' }}
      />

      <div className={locationInfo} data-test-id="ShowYourWorkSidebar-locationInfo">
        {name && (
          <Typography variant="h4" gutterBottom data-test-id="ShowYourWorkSidebar-locationName">
            {name}
          </Typography>
        )}
        {aLine1 && (
          <Typography variant="caption" data-test-id="ShowYourWorkSidebar-addressLine1">
            {aLine1}
          </Typography>
        )}
        {aLine2 && (
          <Typography variant="caption" paragraph data-test-id="ShowYourWorkSidebar-addressLine2">
            {aLine2}
          </Typography>
        )}
        {(group || type) && (
          <div>
            {group && <Chip label={group} data-test-id="ShowYourWorkSidebar-locationGroup" />}
            {type && (
              <Chip
                label={type}
                additionalClasses={group ? 'u-marginleft--6' : null}
                data-test-id="ShowYourWorkSidebar-locationType"
              />
            )}
          </div>
        )}
      </div>

      <nav className={navContainer} data-test-id="ShowYourWorkSidebar-nav">
        <MenuList className={lifelineNav} disablePadding>
          {otherCategories.map((category: string) => (
            <SYWNavItem
              key={category}
              category={category}
              isActive={activeCategory === category}
              isVulnerable={stats?.[category]?.isVulnerable}
              hazardType={planFilters.hazardType}
              handleClick={handleClick}
            />
          ))}
          <Accordion
            expanded={transExpanded}
            onChange={(_, expanded) => setTransExpanded(expanded)}
          >
            <AccordionSummary
              expandIcon={<ArrowForwardIosSharpIcon sx={{ color: WHITE, fontSize: '0.9rem' }} />}
              aria-controls="transporation-expandable-content"
              data-test-id="transporation-expandable-content"
            >
              <SYWNavItem
                category="transportation"
                isActive={false}
                isVulnerable={true}
                hazardType={planFilters.hazardType}
              />
            </AccordionSummary>
            <AccordionDetails>
              {transCategories.map((category: string) => (
                <SYWNavItem
                  key={category}
                  category={category}
                  isActive={activeCategory === category}
                  isVulnerable={stats?.[category]?.isVulnerable}
                  hazardType={planFilters.hazardType}
                  handleClick={handleClick}
                />
              ))}
            </AccordionDetails>
          </Accordion>
        </MenuList>
      </nav>
    </div>
  );
};

const ShowYourWorkSidebar = React.memo(ShowYourWorkSidebarFC);
ShowYourWorkSidebar.displayName = 'ShowYourWorkSidebar';
export default ShowYourWorkSidebar;
