import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import { MenuItem, Theme, Typography } from '@mui/material';

import { LIST_HIGHLIGHT } from '../../../../util/productGlobals';
import Icon from '../../../CommonComponents/Icon/Icon';

type SettingsNavItemProps = {
  category: string;
  icon: string;
  isActive: boolean;
  handleClick: (e: React.MouseEvent) => void;
};

type SettingsNavItemStyleProps = {
  isActive?: boolean;
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      margin: `${theme.spacing(1)} 0`,
      padding: theme.spacing(2),
      borderRadius: '6px',
      backgroundColor: (props: SettingsNavItemStyleProps) =>
        props.isActive ? LIST_HIGHLIGHT : 'transparent',
      overflow: 'hidden',
      paddingRight: theme.spacing(1),
    },
    settingsTag: {
      backgroundColor: LIST_HIGHLIGHT,
      display: 'inline-flex',
      alignItems: 'flex-start',
      backdropFilter: 'blur(4px)',
    },
  };
});
const SettingsNavItemFC: React.FC<SettingsNavItemProps> = ({
  category,
  isActive,
  icon,
  handleClick,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ isActive });

  return (
    <MenuItem
      className={`o-flex-vcenter-container ${classes.root} ${isActive ? 'syw-active-item' : ''}`}
      onClick={handleClick}
      data-category={category}
      data-test-id={`SettingsNav-${category}`}
      tabIndex={0}
    >
      <div className="o-flex-vcenter-container" style={{ overflow: 'hidden' }}>
        <div
          className={`u-clickable u-radius--100 u-marginright--16 u-paddingx--8 u-paddingy--4 ${classes.settingsTag}`}
        >
          <Icon name={icon} />
        </div>
        <Typography variant="h5" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {t(`settings:sections:${category}:nav`)}
        </Typography>
      </div>
    </MenuItem>
  );
};

const SettingsNavItem = React.memo(SettingsNavItemFC);
SettingsNavItem.displayName = 'SettingsNavItem';
export default SettingsNavItem;
