import * as React from 'react';
import { Button, IconButton, TextField, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import {
  GetCurrentUserQuery,
  GetWidgetsInfoDocument,
  useUpdateUserMutation,
} from '../../../../../__generated__/graphql';
import { DARKBLUE } from '../../../../../util/productGlobals';
import { getFullName } from '../../../../../util/utils';
import Icon from '../../../../CommonComponents/Icon/Icon';
import PasswordForm from './PasswordForm';

type UserProfileProps = {
  user: GetCurrentUserQuery['user'];
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    title: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(4),
    },
    label: {
      width: 124,
    },
    iconBtn: {
      padding: '0 !important',
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
    inputField: {
      '&:-webkit-autofill': {
        '-webkit-box-shadow': `0 0 0 100px ${DARKBLUE} inset`,
      },
    },
    rightAlignedlayout: {
      alignItems: 'flex-end',
      width: 'max-content',
    },
    textField: {
      width: 156,
      '& > label': {
        opacity: 0.5,
      },
    },
  };
});

const UserProfile: React.FC<UserProfileProps> = ({ user }) => {
  const { t } = useTranslation();
  const { title, label, iconBtn, inputField, rightAlignedlayout, textField } = useStyles();
  const email = React.useMemo(() => user?.personalInfo?.contact?.email ?? '', [user]);
  const firstName = React.useMemo(() => user?.personalInfo?.firstName ?? '', [user]);
  const lastName = React.useMemo(() => user?.personalInfo?.lastName ?? '', [user]);
  const fullName = React.useMemo(() => getFullName(firstName, lastName), [firstName, lastName]);
  const [nameEditing, setNameEditing] = React.useState(false);
  const [firstNameEditing, setFirstNameEditing] = React.useState('');
  const [lastNameEditing, setLastNameEditing] = React.useState('');

  const [updateUserMutation] = useUpdateUserMutation({
    refetchQueries: [{ query: GetWidgetsInfoDocument }],
    onCompleted: () => {
      setNameEditing(false);
    },
  });

  const handleChangeFirstName = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      setFirstNameEditing(e.target.value),
    [setFirstNameEditing],
  );

  const handleChangeLastName = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      setLastNameEditing(e.target.value),
    [setLastNameEditing],
  );

  const handleSaveName = React.useCallback(() => {
    updateUserMutation({
      variables: {
        userInput: {
          personalInfo: {
            firstName: firstNameEditing.trim(),
            lastName: lastNameEditing.trim(),
          },
        },
      },
    });
  }, [firstNameEditing, lastNameEditing, updateUserMutation]);

  const startEditName = React.useCallback(() => {
    setFirstNameEditing(firstName);
    setLastNameEditing(lastName);
    setNameEditing(true);
  }, [firstName, lastName, setNameEditing, setFirstNameEditing, setLastNameEditing]);

  const stopEditName = React.useCallback(() => setNameEditing(false), [setNameEditing]);

  const firstNameField = (
    <TextField
      label={t('settings:sections:userManagement:addUser:firstName')}
      variant="outlined"
      size="small"
      value={firstNameEditing}
      onChange={handleChangeFirstName}
      className={`${textField} u-marginright--8`}
      inputProps={{
        autoComplete: 'off',
        className: inputField,
        'data-test-id': 'EditUserFirstNameInput',
      }}
    />
  );

  const lastNameField = (
    <TextField
      label={t('settings:sections:userManagement:addUser:lastName')}
      variant="outlined"
      size="small"
      value={lastNameEditing}
      onChange={handleChangeLastName}
      className={textField}
      inputProps={{
        autoComplete: 'off',
        className: inputField,
        'data-test-id': 'EditUserLastNameInput',
      }}
    />
  );

  return (
    <div data-test-id="MyProfileView">
      <Typography variant="h5" className={title} data-test-id="PersonalInfo">
        {t('settings:sections:profile:personalInfo:title')}
      </Typography>
      {nameEditing ? (
        <div className={`o-flexcol-container u-marginbottom--16 ${rightAlignedlayout}`}>
          <div className="o-flex-vcenter-container u-marginbottom--16">
            <Typography variant="caption" className={label}>
              {t('settings:sections:profile:personalInfo:name')}
            </Typography>
            {t('settings@personalNames:fieldOrder') === 'lastFirst' ? (
              <div>
                {lastNameField}
                {firstNameField}
              </div>
            ) : (
              <div>
                {firstNameField}
                {lastNameField}
              </div>
            )}
          </div>
          <div>
            <Button
              name="cancel"
              variant="outlined"
              color="primary"
              onClick={stopEditName}
              className="u-marginright--16"
            >
              {t('buttons:cancel')}
            </Button>
            <Button
              name="save"
              variant="contained"
              color="primary"
              disabled={!firstNameEditing.trim() || !lastNameEditing.trim()}
              onClick={handleSaveName}
            >
              {t('settings:sections:profile:buttons:saveChanges')}
            </Button>
          </div>
        </div>
      ) : (
        <div className="o-flex-vcenter-container u-marginbottom--16">
          <Typography variant="caption" className={label}>
            {t('settings:sections:profile:personalInfo:name')}
          </Typography>
          <div className="o-flex-vcenter-container">
            <Typography
              variant="body2"
              data-test-id="fullName"
              data-test-val-firstname={firstName}
              data-test-val-lastname={lastName}
            >
              {fullName}
            </Typography>
            <div
              className="o-flex-vcenter-container u-clickable u-marginleft--32"
              onClick={startEditName}
              role="button"
              tabIndex={0}
              data-test-id="EditNameButton"
            >
              <IconButton
                color="default"
                data-test-id="EditName"
                size="small"
                className={`${iconBtn} u-marginright--8`}
              >
                <Icon name="Edit" />
              </IconButton>
              <Typography variant="caption">
                {t('settings:sections:profile:buttons:edit')}
              </Typography>
            </div>
          </div>
        </div>
      )}
      <div className="o-flex-vcenter-container u-marginbottom--16">
        <Typography variant="caption" className={label}>
          {t('settings:sections:profile:personalInfo:email')}
        </Typography>
        <Typography variant="body2" data-test-id="email">
          {email}
        </Typography>
      </div>

      <PasswordForm />
    </div>
  );
};
UserProfile.displayName = 'UserProfile';
export default UserProfile;
