import * as React from 'react';
import { OptionsObject, SnackbarKey } from 'notistack';
import { Trans, useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

import { BLACK } from '../../../util/productGlobals';
import DismissSnackbarButton from './DismissSnackbarButton';
import i18next from '../../../PlanningApp/LocalizationClient';
import StyledSnackbarContent from './StyledSnackbarContent';

const useStyles = makeStyles(() => ({
  snackbarLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: BLACK,
    },
  },
}));

const UnknownErrSnackbar: React.FC = () => {
  const { t } = useTranslation();
  const { snackbarLink } = useStyles();

  return (
    <StyledSnackbarContent
      title={t('err:notExpected')}
      // The dummy text below will be replaced by strings from appStrings.json by the <Trans>
      // component
      description={
        <Trans i18nKey="err:notExpectedDescription">
          Dummy description
          <Typography
            classes={{ root: snackbarLink }}
            color="textPrimary"
            variant="caption"
            data-test-id="UnknownErrSnackbar-messenger"
          >
            dummy link title
          </Typography>
        </Trans>
      }
    />
  );
};

export const unknownErrEnqueueOptions: OptionsObject = {
  persist: true,
  key: `${i18next.t('err:notExpected')}-${i18next.t('err:notExpectedDescription')}`,
  action: (id: SnackbarKey) => <DismissSnackbarButton id={id} />,
};

UnknownErrSnackbar.displayName = 'UnknownErrSnackbar';
export default UnknownErrSnackbar;
