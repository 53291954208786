import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  NavigatorViewType,
  RecentViewType,
  useAddRecentViewMutation,
  useSetNotFoundLocationMutation,
} from '../../../../__generated__/graphql';
import {
  AMP_EVENT_VIEWER_OPEN_LOCATION,
  // AMP_FINANCE_QUOTA_EXCEEDED,
} from '../../../../plugins/amplitudeevents';
import { App } from '../../../../PlanningApp/AppConfig';
import { getOneLineAddress } from '../../../../util/addresses/addressUtils';
import LocationDetailCard from './LocationDetailCard';
import LocationDetailCardSkeleton from './Skeleton/LocationDetailCardSkeleton';
import LocationIdNotFound from './LocationIdNotFound';
import { sendAmplitudeData } from '../../../../plugins/amplitude';
import useGetLocOrBobj from '../../../../Hooks/useGetLocOrBobj';
// import LocationQuotaExceededErr from './LocationQuotaExceededErr';

export interface LocationDetailCardContainerProps {
  currentView: NavigatorViewType;
  currentLifeline: string;
  currentUserEmail?: string;
}

const LocationDetailCardContainer: React.FC<LocationDetailCardContainerProps> = ({
  currentView,
  currentLifeline,
  currentUserEmail,
}) => {
  const { id: selectedId } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  // Shared URL for viewer will include `uid` param.
  const isSharedLinkForViewer = React.useMemo(() => !!searchParams?.get('uid'), [searchParams]);

  const { t } = useTranslation();
  const [addRecentViewMutation] = useAddRecentViewMutation();
  const [setNotFoundLocationMutation] = useSetNotFoundLocationMutation();

  // We don't let this hook put up snackbars if it fails, because the main error here will
  // be the location id in the url does  not match any existing location or builtObject.  For that
  // case, we put up a special graphical component <NoLocationFound />  in the sidebar, and NOT
  // and not snackbars
  const {
    locOrBobj,
    error: locOrBobjErr,
    loading: locOrBobjLoading,
  } = useGetLocOrBobj(selectedId, false);

  const locOrBobjAddr = locOrBobj?.address;

  React.useEffect(() => {
    if (locOrBobjAddr) {
      const oneLineAddress = getOneLineAddress(locOrBobjAddr);
      document.title = t('detailView:windowTitle', { oneLineAddress });
    }
  }, [t, locOrBobjAddr]);

  React.useEffect(() => {
    if (locOrBobj) {
      setNotFoundLocationMutation({
        variables: { input: { address: '' } },
      });
    }
  }, [locOrBobj, setNotFoundLocationMutation]);

  React.useEffect(() => {
    if (locOrBobj) {
      addRecentViewMutation({
        variables: {
          id: String(selectedId),
          type:
            locOrBobj.__typename === 'LocationObject'
              ? RecentViewType.Location
              : RecentViewType.Building,
        },
      });

      // Trigger amplitude event if viewer opens shared building link
      if (locOrBobj.__typename === 'BuiltObject' && isSharedLinkForViewer && currentUserEmail) {
        sendAmplitudeData(AMP_EVENT_VIEWER_OPEN_LOCATION, {
          userId: currentUserEmail,
          builtObjectId: selectedId,
        });
      }
    }
  }, [addRecentViewMutation, currentUserEmail, isSharedLinkForViewer, locOrBobj, selectedId]);

  // React.useEffect(() => {
  //   if (locOrBobjErr) {
  //     // TODO - Trigger amplitude when quota exceed error happens
  //     sendAmplitudeData(AMP_FINANCE_QUOTA_EXCEEDED, {
  //       userId: currentUserEmail,
  //     });
  //   }
  // }, [locOrBobjErr, currentUserEmail]);

  if (locOrBobjLoading) {
    return <LocationDetailCardSkeleton />;
  }
  if (locOrBobjErr) {
    // TODO - distinguish between "location not found" errors (which can be caused by user using a
    // bad URL) and other types of errors (network connectivity, etc).  Because the former can be
    // caused by the user, we handle it with a nice graphical "No Location Data" message in the
    // sidebar. But for other types of errors, we should probably use a snackbar instead.
    // To catch just the "location not found" errors, we need to know the specific error code/name
    // that will be returned by the server.
    // So for now, for all types of errors, we just put up the "No Location Data" message and
    // no snackbars.
    App.warn('[LocationDetailCardContainer] - useGetLocOrBobj error: ', locOrBobjErr);

    // TODO - Show LocationQuotaExceededErr when quota exceed error happens
    // return <LocationQuotaExceededErr />;
    return <LocationIdNotFound />;
  }

  return (
    <LocationDetailCard
      builtObject={locOrBobj}
      currentView={currentView}
      currentLifeline={currentLifeline}
      currentUserEmail={currentUserEmail}
    />
  );
};

LocationDetailCardContainer.displayName = 'LocationDetailCardContainer';
export default LocationDetailCardContainer;
