import * as React from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Popper from '@mui/material/Popper';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import {
  AMP_FINANCE_ADD_COMPANY,
  AMP_FINANCE_LIMIT_STOCKS,
} from '../../../plugins/amplitudeevents';
import { sendAmplitudeData } from '../../../plugins/amplitude';
import {
  GetCompaniesQuery,
  GetUserCompaniesDocument,
  useAddUserCompaniesMutation,
} from '../../../__generated__/graphql';
import useLocalStorage from '../../../Hooks/useLocalStorage';
import { LOCALSTORAGE_KEY_PREFIX } from '../../../util/productGlobals';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox} .MuiAutocomplete-option[aria-selected="true"].Mui-focused`]: {
    backgroundColor: 'rgba(248, 247, 242, 0.3)',
  },
  [`& .${autocompleteClasses.listbox} .MuiAutocomplete-option[aria-selected="true"]`]: {
    backgroundColor: 'transparent',
  },
});

type CompanySelectProps = {
  companies: string[];
  userId: string;
  list: GetCompaniesQuery['getCompanies'];
};

const SUPPORT_EMAIL_SUBJECT = 'I%20want%20to%20add%20more%20stock%20tickers';
const SUPPORT_EMAIL_BODY = `Hi%2C%0D%0A%0D%0AI%20am%20interested%20in%20exploring%20more%20stock%20tickers%20in%20Domino%20Copilot.%0D%0A%0D%0AThank%20you!%0D%0A%0D%0A`;
const MAX_STOCKS = 25;

const CompanySelect: React.FC<CompanySelectProps> = ({ companies, userId, list }) => {
  const [values, setValues] = React.useState<string[]>([]);
  const [addUserCompanies] = useAddUserCompaniesMutation({
    refetchQueries: [{ query: GetUserCompaniesDocument }],
    awaitRefetchQueries: true,
  });
  const [customerInfo] = useLocalStorage<{ email: string; locationType: string }>(
    `${LOCALSTORAGE_KEY_PREFIX}${userId}:demoInfo`,
    null,
  );

  const handleAddCompanies = async () => {
    const newCompanies = [...companies, ...values];
    await addUserCompanies({
      variables: {
        id: values,
      },
    });
    sendAmplitudeData(AMP_FINANCE_ADD_COMPANY, {
      customerEmail: customerInfo?.email,
      newCompanies: list
        .filter((company) => values.includes(company.id))
        .map((company) => company.name),
      currentCompanies: list
        .filter((company) => newCompanies.includes(company.id))
        .map((company) => company.name),
    });
    setValues([]);
  };

  return (
    <div className="o-flex-vcenter-container">
      <Autocomplete
        multiple
        id="company-list"
        value={list.filter((company) => values.includes(company.id))}
        options={list.filter(({ id }) => !companies.includes(id))}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </li>
        )}
        fullWidth
        PopperComponent={StyledPopper}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search for Company"
            placeholder="Select company to add"
            sx={{
              '& .MuiAutocomplete-inputRoot': {
                height: 'auto',
                minHeight: 48,
              },
            }}
          />
        )}
        onChange={(_, value) => setValues(value.map((option) => option.id))}
        data-test-id="company-list"
      />
      {companies.length + values.length <= MAX_STOCKS ? (
        <Button
          disabled={!values.length}
          variant="contained"
          color="primary"
          size="small"
          className="u-clickable u-marginleft--16"
          classes={{
            startIcon: 'u-marginright--4',
          }}
          startIcon={<AddIcon fontSize="small" />}
          data-test-id="company-add-btn"
          onClick={handleAddCompanies}
          sx={{ height: 38 }}
          aria-label="add company"
        >
          Add
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          size="small"
          className="u-clickable u-marginleft--16"
          data-test-id="contactus"
          href={`mailto:support@oneconcern.com?cc=cs%40oneconcern.com&subject=${SUPPORT_EMAIL_SUBJECT}&body=${SUPPORT_EMAIL_BODY}`}
          onClick={() =>
            sendAmplitudeData(AMP_FINANCE_LIMIT_STOCKS, {
              userId,
              customerEmail: customerInfo?.email,
              locationType: customerInfo?.locationType,
            })
          }
          sx={{ height: 38 }}
        >
          Contact Us
        </Button>
      )}
    </div>
  );
};

export default CompanySelect;
