import * as React from 'react';
import { parse } from 'papaparse';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import { NoticeCode, ParsedFileLineFragFragment } from '../__generated__/graphql';
import { App } from '../PlanningApp/AppConfig';
import { ERROR_FIELDS_JP } from '../util/productEnums';
import { escapeDelimiter } from '../util/productUtils';
import useEntitledModules from './useEntitledModules';

function useLocationsCsv(originalFile?: ParsedFileLineFragFragment[], sortByErrors?: boolean) {
  const { t } = useTranslation();
  const [headers, setHeaders] = React.useState([]);

  const { data, loading, error } = useEntitledModules();

  if (error) {
    App.warn(
      `[useLocationsCsv] GetUserEntitlements: ${
        error.message ?? 'Error retrieving user entitlements; defaulting to US module'
      }`,
    );
  }

  const { hasJPModule } = data ?? {};

  // TODO: handle multiple languages for regional modules
  const [isEntitledJP, csvTemplatePath] = React.useMemo(() => {
    const filePath = hasJPModule
      ? `${window.location.origin}/assets/i18n/ja/Locations${
          sortByErrors ? 'Error' : ''
        }Template_v0_0_0.csv`
      : `${window.location.origin}/assets/i18n/en/LocationsTemplate_v0_0_0.csv`;

    return [hasJPModule, filePath];
  }, [hasJPModule, sortByErrors]);

  // add columns to CSV headers
  React.useEffect(() => {
    let isMounted = true;

    fetch(csvTemplatePath, { method: 'GET' }).then(async (response) => {
      const body = await response.text();
      const result = parse(body);

      if (isMounted && result?.data?.length > 0) {
        const csvHeaders = (result.data[0] as string[]).map((header: string) =>
          escapeDelimiter(header),
        );

        if (sortByErrors) {
          csvHeaders.push(t('errors'), t('details'));
        }

        setHeaders(csvHeaders);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [csvTemplatePath, sortByErrors, t]);

  const errReportData = React.useMemo(() => {
    let reportData: string[][] = [];

    if (!loading && data && originalFile) {
      let locFile: (ParsedFileLineFragFragment & { noticeCode?: string; details?: string })[] =
        originalFile;

      if (sortByErrors) {
        locFile = sortBy(
          originalFile.map((line) => ({
            ...line,
            noticeCode: line.audit?.notice?.noticeCode ?? '',
            details: line.audit?.notice?.reason ?? '',
          })),
          ['visibility', 'noticeCode'],
        );
      }

      reportData = locFile.map(({ audit, fields, noticeCode, details }) => {
        // omit CS override fields
        let errorFields = fields;

        if (isEntitledJP && sortByErrors) {
          // address = postcode, perfecture, city, street address
          const address = `${fields[ERROR_FIELDS_JP.ZIPCODE]} ${
            fields[ERROR_FIELDS_JP.PREFECTURE]
          } ${fields[ERROR_FIELDS_JP.CITY]} ${fields[ERROR_FIELDS_JP.STREETADDRESS]}`;
          errorFields = [
            fields[ERROR_FIELDS_JP.LOCATIONNAME] || t('nA'),
            address || t('nA'),
            fields[ERROR_FIELDS_JP.LATITUDE] || t('nA'),
            fields[ERROR_FIELDS_JP.LONGITUDE] || t('nA'),
            fields[ERROR_FIELDS_JP.TYPE] || t('nA'),
            fields[ERROR_FIELDS_JP.GROUP] || t('nA'),
          ];
        }

        if (sortByErrors) {
          const hasError = [
            NoticeCode.ErrorGeocode,
            NoticeCode.ErrorOmit,
            NoticeCode.ErrorResolve,
          ].includes(noticeCode as NoticeCode);

          errorFields = [
            ...errorFields,
            hasError
              ? t(`settings:sections:locations:errors:${noticeCode}`, {
                  label: audit.notice?.label,
                })
              : '',
            noticeCode === NoticeCode.WarnModify ? '' : details,
          ];
        }

        return errorFields.map((field: string) => escapeDelimiter(field));
      });
    }

    return reportData;
  }, [data, loading, isEntitledJP, originalFile, sortByErrors, t]);

  return { csvData: errReportData, csvHeaders: headers };
}

export default useLocationsCsv;
