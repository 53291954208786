import { ApolloCache, NormalizedCacheObject, Resolver } from '@apollo/client';
import { cloneDeep } from 'lodash/fp';

import {
  GetLocationUploadFileDocument,
  GetLocationUploadFileQuery,
  MutationSetLocationUploadFileArgs,
} from '../../../__generated__/graphql';

export const setLocationUploadFile: Resolver = (
  _: unknown,
  { file }: MutationSetLocationUploadFileArgs,
  { cache }: { cache: ApolloCache<NormalizedCacheObject> },
) => {
  const storeData = cache.readQuery<GetLocationUploadFileQuery>({
    query: GetLocationUploadFileDocument,
  });
  const newData = cloneDeep(storeData);
  newData.locationUploadFile.file = file;

  cache.writeQuery({ data: newData, query: GetLocationUploadFileDocument });
  return newData.locationUploadFile;
};

export const locationUploadFileMutations = {
  setLocationUploadFile,
};
