import * as React from 'react';
import { LocationSliceStats } from 'onec-types';
import { useTranslation } from 'react-i18next';

import { CardHeader, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Chip from '../../../CommonComponents/Chip/Chip';
import { getDisplayableAddress } from '../../../../util/addresses/addressUtils';
import { GetLocationQuery } from '../../../../__generated__/graphql';
import useGetLocOrBobj from '../../../../Hooks/useGetLocOrBobj';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    overflow: 'hidden',
    width: '420px',
    paddingBottom: '72px',
  },
  header: {
    marginLeft: theme.spacing(16),
    marginTop: theme.spacing(16),
    padding: 0,
  },
  helpText: {
    marginLeft: theme.spacing(16),
    padding: 0,
  },
  locationInfo: {
    marginLeft: theme.spacing(16),
    marginTop: theme.spacing(10.5),
  },
  navContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    marginLeft: theme.spacing(14),
    marginTop: theme.spacing(8),
    marginRight: theme.spacing(2),
  },
  lifelineNav: {
    overflow: 'auto',
    paddingRight: `${theme.spacing(22)} !important`,
  },
}));

type SYWSidebarProps = LocationSliceStats & {
  builtObjectDetails?: {
    propertyType?: string;
    id?: string;
    builtObjectID?: string;
    type: 'LocationObject' | 'BuiltObject';
  };
};

const PowerROISidebarFC: React.FC<SYWSidebarProps> = ({ builtObjectDetails }) => {
  const { t } = useTranslation();
  const { container, header, locationInfo } = useStyles();
  const { locOrBobj, loading, error } = useGetLocOrBobj(builtObjectDetails.id, true);

  if (error || loading || !locOrBobj) {
    return (
      <div className={container} data-test-id="PowerROISidebar">
        <CardHeader
          className={header}
          title={t('roi:title')}
          titleTypographyProps={{ variant: 'h2' }}
        />
      </div>
    );
  }

  const { name, address } = locOrBobj;
  const { group, type } = locOrBobj as GetLocationQuery['location'];
  const [aLine1, aLine2] = getDisplayableAddress(address);
  return (
    <div className={container} data-test-id="PowerROISidebar">
      <CardHeader
        className={header}
        title={t('roi:title')}
        titleTypographyProps={{ variant: 'h2' }}
      />

      <div className={locationInfo} data-test-id="PowerROISidebar-locationInfo">
        {name && (
          <Typography variant="h4" gutterBottom data-test-id="PowerROISidebar-locationName">
            {name}
          </Typography>
        )}
        {aLine1 && (
          <Typography variant="caption" data-test-id="PowerROISidebar-addressLine1">
            {aLine1}
          </Typography>
        )}
        {aLine2 && (
          <Typography variant="caption" paragraph data-test-id="PowerROISidebar-addressLine2">
            {aLine2}
          </Typography>
        )}
        {(group || type) && (
          <div>
            {group && <Chip label={group} data-test-id="PowerROISidebar-locationGroup" />}
            {type && (
              <Chip
                label={type}
                additionalClasses={group ? 'u-marginleft--6' : null}
                data-test-id="PowerROISidebar-locationType"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const PowerROISidebar = React.memo(PowerROISidebarFC);
PowerROISidebar.displayName = 'PowerROISidebar';
export default PowerROISidebar;
