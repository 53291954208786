import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Theme, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { App } from '../../../PlanningApp/AppConfig';
import DominoLogo from '../../../../assets/images/domino.svg';
import { GLASS_BG } from '../../../util/productGlobals';
import Icon from '../../CommonComponents/Icon/Icon';
import { useGetPlanViewFiltersQuery } from '../../../__generated__/graphql';

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    margin: theme.spacing(0, 2),
  },
  card: {
    margin: theme.spacing(2),
    backgroundColor: GLASS_BG,
    borderRadius: theme.spacing(1.5),
    backdropFilter: 'blur(18px)',
    padding: theme.spacing(5, 4),
  },
  divider: {
    margin: theme.spacing(0, 8),
  },
  logo: {
    height: '42px',
  },
}));

const FirstToFailHeaderFC: React.FC = () => {
  const { t } = useTranslation();
  const { text, card, divider, logo } = useStyles();
  const {
    data: {
      planView: {
        planFilters: { hazardType, historicalId },
      },
    },
  } = useGetPlanViewFiltersQuery();

  if (historicalId) {
    App.error('[FirstToFailHeader: Route changed to stress test and historical event is selected');
    return null;
  }

  return (
    <div data-test-id="first-to-fail-header">
      <Toolbar className={card}>
        <img src={DominoLogo} alt={t('dominoLogo')} className={logo} />
        <Divider className={divider} orientation="vertical" />

        <Icon name={hazardType.toLowerCase()} variant="24" />
        <Typography
          className={text}
          variant="body1"
          color="textPrimary"
          data-test-id="header"
          noWrap
        >
          {t(`symbolicView:firstToFail:title:${hazardType}`)}
        </Typography>
      </Toolbar>
    </div>
  );
};

const FirstToFailHeader = React.memo(FirstToFailHeaderFC);
export default FirstToFailHeader;
