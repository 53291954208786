import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  LocationStatus,
  NoticeCode,
  ParsedFileLineFragFragment,
} from '../../../__generated__/graphql';
import { getLocationMatchInfo } from './locationMatchHelpers';
import MatchStatusIcon from './MatchStatusIcon';

const MatchQuality: React.FC<{ locRecord: ParsedFileLineFragFragment }> = ({ locRecord }) => {
  const { t } = useTranslation();

  const matchInfo = getLocationMatchInfo(locRecord);
  const { matchStatus } = matchInfo;

  const { notice, processStatus } = locRecord.audit;
  const matchLabel = t(`locationMatch:quality:${matchStatus}`);
  const matchErrDetail =
    processStatus === LocationStatus.Failure &&
    notice?.noticeCode === NoticeCode.ErrorGeocode &&
    notice?.label === 'zipcode'
      ? t('locationMatch:quality:errorCheckZipcode')
      : null;

  return (
    <Grid
      container
      direction="column"
      data-test-id="MatchQuality"
      data-match-quality={JSON.stringify(matchInfo)}
      data-process-notice={JSON.stringify(notice)}
    >
      <Grid container direction="row" alignItems="center" sx={{ flexWrap: 'nowrap' }}>
        <MatchStatusIcon matchStatus={matchStatus} />
        <Grid container direction="column">
          <Typography data-test-id="MatchQualityLabel" sx={{ ml: 2 }}>
            {matchLabel}
          </Typography>
          {matchErrDetail && (
            <Typography data-test-id="MatchQualityDetail" sx={{ ml: 2 }} color="textSecondary">
              {matchErrDetail}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
MatchQuality.displayName = 'MatchQuality';
export default MatchQuality;
