import { App } from '../PlanningApp/AppConfig';
import i18next from '../PlanningApp/LocalizationClient';

// In seconds -- Unix time
export const SECS_PER_HOUR = 60 * 60;
export const SECS_PER_DAY = 24 * SECS_PER_HOUR;

// Milliseconds - Javascript time
export const ONE_HOUR = 60 * 60 * 1000;
export const ONE_DAY = 24 * ONE_HOUR;
export const FIVE_DAYS = 5 * ONE_DAY;
export const TWO_DAYS = 2 * ONE_DAY;

// Timezone utility function
const deriveTzAbbrev: (d: Date, ianaTz?: string) => string = (d, ianaTz) => {
  type optionsType = { [optKey: string]: string };
  const options: optionsType = {
    timeZoneName: 'short',
  };
  if (ianaTz) {
    options.timeZone = ianaTz;
  }
  const timeFormatter = new Intl.DateTimeFormat('en-US', options);
  const tzAbbrev = timeFormatter.formatToParts(d).find((x) => x.type === 'timeZoneName').value;
  if (tzAbbrev.includes('GMT')) {
    tzAbbrev.replace('GMT', 'UTC');
  }
  return tzAbbrev;
};

// Timezone utility function
export const getIanaTimeZoneAbbrev: (d: Date, ianaTzName: string) => string = (d, ianaTzName) => {
  switch (ianaTzName) {
    // This switch only handles timezones that never shift for daylight savings
    case 'UTC':
      return 'UTC';
    case 'Asia/Dhaka':
      return '+06';
    case 'Asia/Tokyo':
      return 'JST';
    case 'America/Phoenix': // no daylight savings time ever in Phoenix.
      return 'MST';
    default:
      // Daylight savings is possible, so ask browser to derive Tz abbreviation using date
      return deriveTzAbbrev(d, ianaTzName);
  }
};

// Timezone utility function
const getBrowserTimezoneAbbrev: (d: Date) => string = (d) => {
  // This function needs to work with IE11
  try {
    // Daylight savings is possible, so ask browser to derive Tz abbreviation using date
    return deriveTzAbbrev(d);
  } catch (e) {
    // IE11 does not support Intl.DateTimeFormat or toLocaleTimeString with timezone
    // so fallback to less specific abbrev like 'UTC-8', since we can't derive a typical
    // timezone abbrev soeley from timezone offset, due to possible daylight savings time &
    // underlying O/S locale settings.
    const utcOffset = d.getTimezoneOffset() / 60;
    if (utcOffset === 0) {
      return 'UTC';
    }
    let tzString = `UTC${utcOffset < 0 ? '+' : '-'}${Math.floor(Math.abs(utcOffset))}`;
    if (utcOffset - Math.floor(utcOffset) === 0.5) {
      tzString += ':30';
    }
    return tzString;
  }
};

export type dateTimePartsType = {
  yr: string;
  mo: string;
  dt: string;
  hr: string;
  mi: string;
  tz: string;
  wk: string;
};

export const getDateTimeParts: (
  isoTimeStr: string,
  options: optionsType,
  ianaTzone?: string,
) => dateTimePartsType = (isoTimeStr, options, ianaTzone) => {
  let dateTimeObj: { [optKey: string]: string } = {};
  let tz: string;
  const d = new Date(isoTimeStr);

  try {
    const timeFormatter = new Intl.DateTimeFormat(i18next.t('settings@languageCode'), options);
    // if the timeZone was not specified in options, then the time in dateTimeParts will be in
    // local browser time.
    const dateTimeParts = timeFormatter.formatToParts(d);
    dateTimeParts.forEach(({ type, value }) => {
      dateTimeObj[type] = value;
    });
    if (ianaTzone) {
      tz = getIanaTimeZoneAbbrev(d, ianaTzone);
    } else {
      // browser's timezone abbrev
      tz = dateTimeObj.timeZoneName;
      App.warn(
        `Time:getDateTimeParts: No timezone provided: fall back to browser local timezone: ${tz}`,
      );
    }
  } catch (e) {
    // IE11 because 'formatToParts' threw an exception.  IE11 can only convert to local
    // browsers time or UTC, so we just get browser tieme.
    // en-DE locale times are 24-hr clock (no am/pm), like 23:39:22
    const convertedTime = d.toLocaleTimeString('en-DE');
    const [hr, mi] = convertedTime.split(':');
    // 'en-CA' locale dates are 0-padded date like '2019-06-30'
    const convertedDate = d.toLocaleDateString('en-CA');
    const [yr, mo, dt] = convertedDate.split('-');
    dateTimeObj = { year: yr, month: mo, day: dt, hour: hr, minute: mi };
    tz = getBrowserTimezoneAbbrev(d);
    // App.debug(`Internet Explorer 11: using browser\'s local timezone of ${tz}`);
    // App.debug(`UTC time of ${isoTimeStr} converted:`);
    // App.debug(dateTimeObj);
  }
  const {
    year: yr,
    month: mo,
    day: dt,
    hour,
    minute: mi,
    weekday: wk,
    dayPeriod: amPm,
  } = dateTimeObj;
  const hr = hour === '24' ? '00' : hour;
  const translatedTz = i18next.t(tz);
  return { yr, mo, dt, wk, hr, mi, tz: translatedTz, amPm } as dateTimePartsType;
};

/** ***************
 Name: isValidDate - returns true if the string is one that can be parsed into a Date object
****************** */
export const isValidDate: (ds: string) => boolean = (ds) => {
  return !Number.isNaN(Date.parse(ds));
};

type optionsType = { [optKey: string]: string | boolean };

type FormatDateStringType =
  // The exact format depends on appStrings for the appropriate locale.
  | 'dateStringWithHourMin' // March 3, 2022, 6:00 AM (Japan: 2020年3月3日 6:00)
  | 'dateStringWithHour' // March 3, 2020 at 06:00 (Japan: 2020年3月3日 6:00)
  | 'dateStringWithMonth' // March 3, 2020 (Japan: 2020年3月3日)
  | 'dateStringNumbers' // 2020-02-23 (Japan: 2020年2月23日)
  | 'hourOclock' // 06:00   (japan: 6:00)
  | 'monthDay' // 01-23  (japan: 1月23日)
  | 'monthDayDoW' // 01/23 (Fri)  (japan: 1月23日(火))
  | 'monthDayAbv' // January 23  (japan: 1月23日)
  | 'timeString' // 01:05 (PST) (Japan: 1:05 (JST))
  | 'timestampString' // 2020-01-02 06:05 (PST) (Japan: the same)
  | 'dateID'; // '20200102' - same in Japan - use a key or identifier.

const DEFAULT_OPTIONS: optionsType = {
  timeZoneName: 'short',
  year: 'numeric',
  hour12: false,
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  weekday: 'short',
};

export const formatDateString: (
  isoTimeStr: string,
  ianaTzone?: string,
  translationKey?: FormatDateStringType,
) => string = (isoTimeStr, ianaTzone, translationKey = 'timestampString') => {
  const languageCode = i18next.t('settings@languageCode');

  const options = { ...DEFAULT_OPTIONS };
  if (ianaTzone) {
    options.timeZone = ianaTzone;
  }
  switch (translationKey) {
    case 'dateStringWithHourMin':
      options.month = 'long';
      options.day = 'numeric';
      options.hour = 'numeric';
      options.hour12 = languageCode !== 'ja';
      break;
    case 'dateStringWithHour':
    case 'dateStringWithMonth':
      options.month = 'long';
      options.day = 'numeric';
      break;
    case 'hourOclock':
    case 'timeString':
    case 'monthDay':
    case 'monthDayDoW':
      options.hour = languageCode === 'ja' ? 'numeric' : '2-digit';
      options.month = languageCode === 'ja' ? 'numeric' : '2-digit';
      options.day = languageCode === 'ja' ? 'numeric' : '2-digit';
      break;
    case 'monthDayAbv':
      options.month = languageCode === 'ja' ? 'long' : '2-digit';
      options.day = languageCode === 'ja' ? 'numeric' : '2-digit';
      break;
    case 'dateStringNumbers':
    case 'timestampString':
    case 'dateID':
    default:
      // use default options
      break;
  }

  const dateTimeParts: dateTimePartsType = getDateTimeParts(isoTimeStr, options, ianaTzone);
  return i18next.t(`datetime:${translationKey}`, dateTimeParts);
};

export const getUnixTimestamp: (utcString: string) => number = (utcString) => {
  const jsTime = new Date(utcString);
  return Math.floor(jsTime.getTime() / 1000);
};

export const unixTimeToIsoString: (unixTime: number) => string = (unixTime) => {
  const d = new Date(unixTime * 1000);
  return d.toISOString();
};

export const getClosestHourUnixTime: (origUnixTime?: number) => number = (origUnixTime) => {
  if (origUnixTime) {
    return Math.round(origUnixTime / SECS_PER_HOUR) * SECS_PER_HOUR;
  }
  // convert Date.now() to Unix time if no parameter is provided
  const currentTime = new Date(Date.now());
  const currentUnixTime = currentTime.getTime() / 1000;
  return Math.round(currentUnixTime / SECS_PER_HOUR) * SECS_PER_HOUR;
};

export const getClosestHourUTCTimestamp: (isoTimestamp?: string) => string = (isoTimestamp) => {
  // Rounds up or down to the nearest hour and returns an ISO timestamp string
  // uses current time as input if an isoTimestamp is not provided.
  let origDate: Date;
  if (isoTimestamp) {
    origDate = new Date(isoTimestamp);
  } else {
    origDate = new Date(Date.now()); // current time
  }
  const unixTime = origDate.getTime() / 1000;
  const newDate = new Date();
  newDate.setTime(getClosestHourUnixTime(unixTime) * 1000);
  return newDate.toISOString();
};

// Return timezone-converted time string in format `MM/DD HH:00`
export const makeTimeLabel: (timestamp: string, timezone: string) => string = (
  timestamp,
  timezone,
) => {
  const date = formatDateString(timestamp, timezone, 'monthDay');
  const time = formatDateString(timestamp, timezone, 'hourOclock');
  return `${date} ${time}`;
};

export const getUTCCurrentHour = (): number => {
  const currentDate = new Date(Date.now());
  return (
    Date.UTC(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate(),
      currentDate.getUTCHours(),
    ) / 1000
  );
};

export const fixIsoTimestamp = (nonConformingIsoTimestamp: string): string => {
  const isoTimestamp = new Date(Date.parse(nonConformingIsoTimestamp)).toISOString();
  return isoTimestamp;
};

export const getLaterTimestamp: (ts1: string, ts2: string) => string = (ts1, ts2) => {
  return Date.parse(ts1) < Date.parse(ts2) ? ts2 : ts1;
};

export const getEarlierTimestamp: (ts1: string, ts2: string) => string = (ts1, ts2) => {
  return Date.parse(ts1) < Date.parse(ts2) ? ts1 : ts2;
};

export const isEarlierTimestamp: (ts1: string, ts2: string) => boolean = (ts1, ts2) => {
  return Date.parse(ts1) <= Date.parse(ts2);
};

export const timestampIsBetween: (newTs: string, ts1: string, ts2: string) => boolean = (
  newTs,
  ts1,
  ts2,
) => {
  const interval = isEarlierTimestamp(ts1, ts2) ? [ts1, ts2] : [ts2, ts1];
  return isEarlierTimestamp(interval[0], newTs) && isEarlierTimestamp(newTs, interval[1]);
};

export const timestampDiffHours: (isoTs1: string, isoTs2: string) => number = (isoTs1, isoTs2) => {
  // If positive result, then isoTs1 is BEFORE isoTs2.  If negative, isoTs1 is AFTER isoTs2
  const diffMs = Date.parse(isoTs2) - Date.parse(isoTs1);
  return Math.round(diffMs / (1000 * SECS_PER_HOUR));
};

export const isHourLabelMidnight: (hourLabel: string) => boolean = (hourLabel) => {
  return hourLabel === '0:00' || hourLabel === '00:00';
};

export const isoTimestampToCode: (timestamp: string) => string = (timestamp) => {
  return (
    timestamp.slice(0, 4) + timestamp.slice(5, 7) + timestamp.slice(8, 10) + timestamp.slice(11, 13)
  );
};

export function hoursToDayHourAbbrev(hrs: number | string): string {
  if (Number.isNaN(+hrs)) return '';

  const days = Math.floor(+hrs / 24);
  const hours = +hrs % 24;

  if (days < 1) return i18next.t('datetime:hoursAbbrev', { hr: hours.toLocaleString() });

  if (hours < 1) return i18next.t('datetime:daysAbbrev', { dt: days.toLocaleString() });

  return i18next.t('datetime:dayHourAbbrev', {
    days: days.toLocaleString(),
    hours: hours.toLocaleString(),
  });
}

export function hoursToDayHourLonghand(hrs: number | string): string {
  if (Number.isNaN(+hrs)) return '';

  const days = Math.floor(+hrs / 24);
  const hours = +hrs % 24;

  const hourStr =
    hours > 0 ? i18next.t('datetime:hours', { hr: hours.toLocaleString(), count: hours }) : '';

  const dayStr =
    days > 0 ? i18next.t('datetime:days', { days: days.toLocaleString(), count: days }) : '';

  if (days < 1) return hourStr;
  if (hours < 1) return dayStr;

  return i18next.t('datetime:dayHourLonghand', { dayString: dayStr, hourString: hourStr });
}
