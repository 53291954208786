import * as React from 'react';
import { LayerMouseEvent, OnecWindow } from 'onec-types';

import {
  Airport,
  Bridge,
  GetBuiltObjectLifelineMarkersQuery,
  GetLocationLifelineMarkersQuery,
  GetThresholdsQuery,
  HighwaySegment,
  NavigatorViewType,
  Port,
  Subdivision,
  useGetNavigatorInfoQuery,
  useUpdateNavigatorInfoMutation,
} from '../../../../__generated__/graphql';
import {
  BUILT_OBJECT_SOURCE_ID,
  NON_VULNERABLE_LIFELINE_SOURCE,
  TOP_NON_VULNERABLE_LIFELINE_SOURCE,
  TOP_VULNERABLE_LIFELINE_SOURCE,
  VULNERABLE_LIFELINE_ARC_SOURCE,
  VULNERABLE_LIFELINE_SOURCE,
} from '../../../../util/productGlobals';
import { Lifeline } from '../../../../util/productEnums';
import { lifelineIcons } from '../../../../util/icons';
import LifelinePillMarker from './LifelinePillMarker';
import MapSource from '../../../CommonComponents/Map/MapSource';
import { splitLifelineAssets } from '../../../../util/productUtils';

export const defaultOpacity = 1;
export const lowOpacity = 0.4;

export type LifelineMarkersFCProps = {
  locOrBobj:
    | GetLocationLifelineMarkersQuery['location']
    | GetBuiltObjectLifelineMarkersQuery['builtObject'];
  thresholds: GetThresholdsQuery['user']['productSettings']['thresholds'];
};
const LifelineMarkersFC: React.FC<LifelineMarkersFCProps> = ({
  locOrBobj: {
    coordinates,
    airports,
    ports,
    structural,
    power,
    people,
    bridges,
    highways,
    resilienceStats,
  },
  thresholds: {
    structuralDamage,
    powerDowntime,
    peopleDowntime,
    highwayDowntime,
    bridgeDowntime,
    portDowntime,
    airportDowntime,
  },
}) => {
  const featureData: {
    topFeatures: GeoJSON.Feature<GeoJSON.Geometry>[];
    otherFeatures: GeoJSON.Feature<GeoJSON.Geometry>[];
  } = React.useMemo(() => {
    const topFeatures: GeoJSON.Feature<GeoJSON.Geometry>[] = [];
    const otherFeatures: GeoJSON.Feature<GeoJSON.Geometry>[] = [];
    const { topAssets: topAirportAssets, otherAssets: otherAirportAssets } =
      splitLifelineAssets(airports);
    const { topAssets: topPortAssets, otherAssets: otherPortAssets } = splitLifelineAssets(ports);
    const { topAssets: topBridgeAssets, otherAssets: otherBridgeAssets } =
      splitLifelineAssets(bridges);
    const { topAssets: topHighwayAssets, otherAssets: otherHighwayAssets } =
      splitLifelineAssets(highways);
    const { topAssets: topPeopleAssets, otherAssets: otherPeopleAssets } =
      splitLifelineAssets(people);
    const topLifelines: { [key: string]: any } = {
      airports: topAirportAssets,
      ports: topPortAssets,
      bridges: topBridgeAssets,
      highways: topHighwayAssets,
      people: topPeopleAssets,
    };
    const otherLifelines: { [key: string]: any } = {
      airports: otherAirportAssets,
      ports: otherPortAssets,
      bridges: otherBridgeAssets,
      highways: otherHighwayAssets,
      people: otherPeopleAssets,
    };

    for (const key in topLifelines) {
      if (Object.prototype.hasOwnProperty.call(topLifelines, key)) {
        const value = topLifelines[key];
        topFeatures.push(
          ...value.map((lifeline: Airport | Port | Bridge | HighwaySegment | Subdivision) => {
            let category = '';
            let isVulnerable = false;
            const mean = lifeline.stats[0]?.mean;
            switch (key) {
              case 'airports':
                category = Lifeline.AIRPORT;
                isVulnerable = mean > airportDowntime;
                break;
              case 'ports':
                category = Lifeline.PORT;
                isVulnerable = mean > portDowntime;
                break;
              case 'bridges':
                category = Lifeline.BRIDGE;
                isVulnerable = mean > bridgeDowntime;
                break;
              case 'highways':
                category = Lifeline.HIGHWAY;
                isVulnerable = mean > highwayDowntime;
                break;
              case 'people':
                category = Lifeline.PEOPLE;
                isVulnerable = mean > peopleDowntime;
                break;
              default:
                category = Lifeline.EMPTY;
            }
            return {
              id: lifeline.id,
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: lifeline.coordinates,
              },
              properties: {
                id: lifeline.id,
                category,
                mean,
                isVulnerable,
              },
            };
          }),
        );
      }
    }

    for (const key in otherLifelines) {
      if (Object.prototype.hasOwnProperty.call(otherLifelines, key)) {
        const value = otherLifelines[key];
        otherFeatures.push(
          ...value.map((lifeline: Airport | Port | Bridge | HighwaySegment | Subdivision) => {
            let category = '';
            let isVulnerable = false;
            const mean = lifeline.stats[0]?.mean;
            switch (key) {
              case 'airports':
                category = Lifeline.AIRPORT;
                isVulnerable = mean > airportDowntime;
                break;
              case 'ports':
                category = Lifeline.PORT;
                isVulnerable = mean > portDowntime;
                break;
              case 'bridges':
                category = Lifeline.BRIDGE;
                isVulnerable = mean > bridgeDowntime;
                break;
              case 'highways':
                category = Lifeline.HIGHWAY;
                isVulnerable = mean > highwayDowntime;
                break;
              case 'people':
                category = Lifeline.PEOPLE;
                isVulnerable = mean > peopleDowntime;
                break;
              default:
                category = Lifeline.EMPTY;
            }
            return {
              id: lifeline.id,
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: lifeline.coordinates,
              },
              properties: {
                id: lifeline.id,
                category,
                mean,
                isVulnerable,
              },
            };
          }),
        );
      }
    }

    return {
      topFeatures,
      otherFeatures,
    };
  }, [
    airports,
    airportDowntime,
    bridges,
    bridgeDowntime,
    highways,
    highwayDowntime,
    people,
    peopleDowntime,
    ports,
    portDowntime,
  ]);

  const [updateNavigatorInfoMutation] = useUpdateNavigatorInfoMutation();
  const {
    data: {
      navigatorInfo: { currentView, currentLifeline: category },
    },
  } = useGetNavigatorInfoQuery();
  const handleLifelineClick = React.useCallback(
    (evt: LayerMouseEvent<any>) => {
      const mapboxMap = evt.target;
      const lifeline = mapboxMap.queryRenderedFeatures(evt.point, {
        layers: [
          NON_VULNERABLE_LIFELINE_SOURCE,
          TOP_NON_VULNERABLE_LIFELINE_SOURCE,
          TOP_VULNERABLE_LIFELINE_SOURCE,
          VULNERABLE_LIFELINE_SOURCE,
        ],
      })[0];

      if (lifeline) {
        updateNavigatorInfoMutation({
          variables: {
            currentView: currentView || NavigatorViewType.MapView,
            currentLifeline: lifeline.properties.category as Lifeline,
          },
        });
      } else {
        updateNavigatorInfoMutation({
          variables: {
            currentView: currentView || NavigatorViewType.MapView,
            currentLifeline: Lifeline.EMPTY,
          },
        });
      }
    },
    [currentView, updateNavigatorInfoMutation],
  );

  const lifelineIconFilter = React.useMemo(() => {
    // eslint-disable-next-line no-shadow
    const { airports, ports, bridges, highways, people, power, structural } = lifelineIcons;
    const filter = [
      'case',
      [
        'all',
        ['==', ['get', 'category'], Lifeline.AIRPORT],
        ['<', airportDowntime, ['get', 'mean']],
      ],
      category === Lifeline.EMPTY || category === Lifeline.AIRPORT
        ? airports.vulnerable.selected
        : airports.vulnerable.default,
      [
        'all',
        ['==', ['get', 'category'], Lifeline.AIRPORT],
        ['>=', airportDowntime, ['get', 'mean']],
      ],
      category === Lifeline.EMPTY || category === Lifeline.AIRPORT
        ? airports.notVulnerable.selected
        : airports.notVulnerable.default,
      ['all', ['==', ['get', 'category'], Lifeline.PORT], ['<', portDowntime, ['get', 'mean']]],
      category === Lifeline.EMPTY || category === Lifeline.PORT
        ? ports.vulnerable.selected
        : ports.vulnerable.default,
      ['all', ['==', ['get', 'category'], Lifeline.PORT], ['>=', portDowntime, ['get', 'mean']]],
      category === Lifeline.EMPTY || category === Lifeline.PORT
        ? ports.notVulnerable.selected
        : ports.notVulnerable.default,
      ['all', ['==', ['get', 'category'], Lifeline.BRIDGE], ['<', bridgeDowntime, ['get', 'mean']]],
      category === Lifeline.EMPTY || category === Lifeline.BRIDGE
        ? bridges.vulnerable.selected
        : bridges.vulnerable.default,
      [
        'all',
        ['==', ['get', 'category'], Lifeline.BRIDGE],
        ['>=', bridgeDowntime, ['get', 'mean']],
      ],
      category === Lifeline.EMPTY || category === Lifeline.BRIDGE
        ? bridges.notVulnerable.selected
        : bridges.notVulnerable.default,
      [
        'all',
        ['==', ['get', 'category'], Lifeline.HIGHWAY],
        ['<', highwayDowntime, ['get', 'mean']],
      ],
      category === Lifeline.EMPTY || category === Lifeline.HIGHWAY
        ? highways.vulnerable.selected
        : highways.vulnerable.default,
      [
        'all',
        ['==', ['get', 'category'], Lifeline.HIGHWAY],
        ['>=', highwayDowntime, ['get', 'mean']],
      ],
      category === Lifeline.EMPTY || category === Lifeline.HIGHWAY
        ? highways.notVulnerable.selected
        : highways.notVulnerable.default,
      ['all', ['==', ['get', 'category'], Lifeline.PEOPLE], ['<', peopleDowntime, ['get', 'mean']]],
      category === Lifeline.EMPTY || category === Lifeline.PEOPLE
        ? people.vulnerable.selected
        : people.vulnerable.default,
      [
        'all',
        ['==', ['get', 'category'], Lifeline.PEOPLE],
        ['>=', peopleDowntime, ['get', 'mean']],
      ],
      category === Lifeline.EMPTY || category === Lifeline.PEOPLE
        ? people.notVulnerable.selected
        : people.notVulnerable.default,
      ['all', ['==', ['get', 'category'], Lifeline.POWER], ['<', powerDowntime, ['get', 'mean']]],
      category === Lifeline.EMPTY || category === Lifeline.POWER
        ? power.vulnerable.selected
        : power.vulnerable.default,
      ['all', ['==', ['get', 'category'], Lifeline.POWER], ['>=', powerDowntime, ['get', 'mean']]],
      category === Lifeline.EMPTY || category === Lifeline.POWER
        ? power.notVulnerable.selected
        : power.notVulnerable.default,
      [
        'all',
        ['==', ['get', 'category'], Lifeline.STRUCTURE],
        ['<', structuralDamage, ['get', 'mean']],
      ],
      category === Lifeline.EMPTY || category === Lifeline.STRUCTURE
        ? structural.vulnerable.selected
        : structural.vulnerable.default,
      [
        'all',
        ['==', ['get', 'category'], Lifeline.STRUCTURE],
        ['>=', structuralDamage, ['get', 'mean']],
      ],
      category === Lifeline.EMPTY || category === Lifeline.STRUCTURE
        ? structural.notVulnerable.selected
        : structural.notVulnerable.default,
      // Default cannot be null or empty string for symbol
      'tw-national-2',
    ];

    return { 'icon-image': filter };
  }, [
    category,
    airportDowntime,
    bridgeDowntime,
    highwayDowntime,
    peopleDowntime,
    portDowntime,
    powerDowntime,
    structuralDamage,
  ]);

  const lifelineIconOpacity = React.useMemo(
    () => [
      'case',
      ['all', ['==', ['get', 'category'], Lifeline.AIRPORT]],
      category === Lifeline.EMPTY || category === Lifeline.AIRPORT ? defaultOpacity : lowOpacity,
      ['all', ['==', ['get', 'category'], Lifeline.PORT]],
      category === Lifeline.EMPTY || category === Lifeline.PORT ? defaultOpacity : lowOpacity,
      ['all', ['==', ['get', 'category'], Lifeline.BRIDGE]],
      category === Lifeline.EMPTY || category === Lifeline.BRIDGE ? defaultOpacity : lowOpacity,
      ['all', ['==', ['get', 'category'], Lifeline.HIGHWAY]],
      category === Lifeline.EMPTY || category === Lifeline.HIGHWAY ? defaultOpacity : lowOpacity,
      ['all', ['==', ['get', 'category'], Lifeline.PEOPLE]],
      category === Lifeline.EMPTY || category === Lifeline.PEOPLE ? defaultOpacity : lowOpacity,
      defaultOpacity,
    ],
    [category],
  );

  const pillIcons = React.useMemo(() => {
    const icons = [];

    if (structural?.length > 0) {
      const isVulnerable = resilienceStats[0].structuralDamage.mean > structuralDamage;
      let name = 'Lifeline-structure';
      if (isVulnerable) name += '-down';

      icons.push({
        name,
        isVulnerable,
        showBorder: category === Lifeline.EMPTY || category === Lifeline.STRUCTURE,
        handleClick: () => {
          updateNavigatorInfoMutation({
            variables: {
              currentView: currentView || NavigatorViewType.MapView,
              currentLifeline: Lifeline.STRUCTURE,
            },
          });
        },
      });
    }

    if (power?.length > 0) {
      const isVulnerable = resilienceStats[0].nearbyPower.mean > powerDowntime;
      let name = 'Lifeline-power';
      if (isVulnerable) name += '-down';

      icons.push({
        name,
        isVulnerable,
        showBorder: category === Lifeline.EMPTY || category === Lifeline.POWER,
        handleClick: () => {
          updateNavigatorInfoMutation({
            variables: {
              currentView: currentView || NavigatorViewType.MapView,
              currentLifeline: Lifeline.POWER,
            },
          });
        },
      });
    }

    return icons;
  }, [
    structural,
    power,
    resilienceStats,
    structuralDamage,
    powerDowntime,
    updateNavigatorInfoMutation,
    currentView,
    category,
  ]);

  const mapboxMap = (window as OnecWindow).reactMap;
  const handleMouseEnter = React.useCallback(() => {
    mapboxMap.getCanvas().style.cursor = 'pointer';
  }, [mapboxMap]);
  const handleMouseLeave = React.useCallback(() => {
    mapboxMap.getCanvas().style.cursor = '';
  }, [mapboxMap]);

  return (
    <>
      <MapSource
        id={TOP_VULNERABLE_LIFELINE_SOURCE}
        type="symbol"
        features={featureData?.topFeatures.filter((feature) => feature.properties.isVulnerable)}
        handleIconClick={handleLifelineClick}
        iconLayout={{ ...lifelineIconFilter, 'icon-allow-overlap': true }}
        iconPaint={{ 'icon-opacity': lifelineIconOpacity }}
        previousSourceId={`${BUILT_OBJECT_SOURCE_ID}-halo`}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
      />
      <MapSource
        id={VULNERABLE_LIFELINE_SOURCE}
        type="symbol"
        features={featureData?.otherFeatures.filter((feature) => feature.properties.isVulnerable)}
        handleIconClick={handleLifelineClick}
        iconLayout={lifelineIconFilter}
        iconPaint={{ 'icon-opacity': lifelineIconOpacity }}
        previousSourceId={TOP_VULNERABLE_LIFELINE_SOURCE}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
      />
      <MapSource
        id={TOP_NON_VULNERABLE_LIFELINE_SOURCE}
        type="symbol"
        features={featureData?.topFeatures.filter((feature) => !feature.properties.isVulnerable)}
        handleIconClick={handleLifelineClick}
        iconLayout={{ ...lifelineIconFilter, 'icon-allow-overlap': true }}
        iconPaint={{ 'icon-opacity': lifelineIconOpacity }}
        previousSourceId={VULNERABLE_LIFELINE_ARC_SOURCE}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
      />
      <MapSource
        id={NON_VULNERABLE_LIFELINE_SOURCE}
        type="symbol"
        features={featureData?.otherFeatures.filter((feature) => !feature.properties.isVulnerable)}
        handleIconClick={handleLifelineClick}
        iconLayout={lifelineIconFilter}
        iconPaint={{ 'icon-opacity': lifelineIconOpacity }}
        previousSourceId={TOP_NON_VULNERABLE_LIFELINE_SOURCE}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
      />
      {currentView === NavigatorViewType.MapView && (
        <LifelinePillMarker coordinates={coordinates} icons={pillIcons} />
      )}
    </>
  );
};

const LifelineMarkers = React.memo(LifelineMarkersFC);
LifelineMarkers.displayName = 'LifelineMarkers';
export default LifelineMarkers;
