import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ForecastPeriod,
  useGetForecastPeriodQuery,
  useUpdateForecastPeriodMutation,
} from '../../../../__generated__/graphql';
import { DEFAULT_FORECAST_PERIOD } from '../../../../util/productGlobals';
import { sendAmplitudeData } from '../../../../plugins/amplitude';
import { AMP_EVENT_CHANGE_FORECAST_PERIOD_TYPE } from '../../../../plugins/amplitudeevents';
import PickerForm from '../Common/PickerForm';
import PlanningPeriodPickerFormBody from './PlanningPeriodPickerFormBody';

type PlanningPeriodPickerProps = {
  userId: string;
};
const PlanningPeriodPicker: React.FC<PlanningPeriodPickerProps> = ({ userId }) => {
  const { t } = useTranslation();
  const [preSubmitForecastPeriod, setPreSubmitForecastPeriod] = React.useState(null);

  const {
    data: {
      planView: {
        planFilters: { forecastPeriod: savedForecastPeriod },
      },
    },
  } = useGetForecastPeriodQuery();

  const [updateForecastPeriodMutation] = useUpdateForecastPeriodMutation();

  React.useEffect(() => {
    setPreSubmitForecastPeriod(savedForecastPeriod);
  }, [savedForecastPeriod, updateForecastPeriodMutation]);

  const onSubmit = React.useCallback(() => {
    updateForecastPeriodMutation({
      variables: {
        forecastPeriod: preSubmitForecastPeriod,
      },
    });
    if (userId) {
      sendAmplitudeData(AMP_EVENT_CHANGE_FORECAST_PERIOD_TYPE, {
        userId,
        ...preSubmitForecastPeriod,
      });
    }
  }, [preSubmitForecastPeriod, updateForecastPeriodMutation, userId]);

  const onCancel = React.useCallback(() => {
    setPreSubmitForecastPeriod(savedForecastPeriod);
  }, [savedForecastPeriod]);

  const onRestoreToDefaults = React.useCallback(() => {
    setPreSubmitForecastPeriod(DEFAULT_FORECAST_PERIOD);
  }, []);

  const onChangePreSubmitForecastPeriod = React.useCallback(
    (newForecast: Partial<ForecastPeriod>) => {
      const mergedNewForecastPeriod = { ...preSubmitForecastPeriod, ...newForecast };
      setPreSubmitForecastPeriod(mergedNewForecastPeriod);
    },
    [preSubmitForecastPeriod],
  );
  const buttonStatus = `${savedForecastPeriod?.duration}${t('inputs:yearAbbrev')}`;
  return (
    <PickerForm
      onSubmit={onSubmit}
      onCancel={onCancel}
      onRestoreToDefaults={onRestoreToDefaults}
      submitting={false}
      name={savedForecastPeriod?.forecastPeriodType}
      hoverDropdownComponent={null}
      pickerButtonText={buttonStatus}
    >
      {preSubmitForecastPeriod && (
        <PlanningPeriodPickerFormBody
          preSubmitVal={preSubmitForecastPeriod}
          onChangePreSubmitVal={onChangePreSubmitForecastPeriod}
        />
      )}
    </PickerForm>
  );
};
PlanningPeriodPicker.displayName = 'PlanningPeriodPicker';
export default PlanningPeriodPicker;
