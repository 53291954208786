import * as React from 'react';
import {
  Check as CheckIcon,
  Error as ErrorIcon,
  HourglassBottom as HourglassBottomIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';

import { BobjMatchStatus } from './locationMatchHelpers';

const MatchStatusIcon: React.FC<{ matchStatus: BobjMatchStatus }> = ({ matchStatus }) => {
  switch (matchStatus) {
    case BobjMatchStatus.MatchWarning:
      return <WarningIcon color="warning" data-test-id="LocationMatchWarning-Icon" />;
    case BobjMatchStatus.MatchError:
      return <ErrorIcon color="error" data-test-id="LocationMatchError-Icon" />;
    case BobjMatchStatus.MatchPending:
      return <HourglassBottomIcon color="info" data-test-id="LocationMatchPending-Icon" />;
    case BobjMatchStatus.MatchSuccess:
      return <CheckIcon color="success" data-test-id="LocationMatchSuccess-Icon" />;
    default:
      return null;
  }
};

MatchStatusIcon.displayName = 'MatchStatusIcon';
export default MatchStatusIcon;
