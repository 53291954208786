/* eslint-disable react/jsx-no-useless-fragment */
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SYWSectionProps } from 'onec-types';
import { useLocation } from 'react-router-dom';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import {
  Bridge,
  GeoJsonType,
  useGetCurrentUserQuery,
  useGetPlanViewFiltersQuery,
} from '../../../../__generated__/graphql';
import { BRIDGE_RESTRICTION_HAZARD_TYPES } from '../../../../util/productGlobals';
import Callout from '../../../CommonComponents/Callout/Callout';
import { getLatLongString } from '../../../CommonComponents/Statistic/Statistic';
import { hoursToDayHourAbbrev } from '../../../../util/time';
import RecoveryCurveChart from '../../Charts/RecoveryCurveChart';
import ReturnPeriodChart from '../../Charts/ReturnPeriodChart';
import { roundNumToPlace } from '../../../../util/utils';
import showYourWorkStyles from './showYourWorkStyles';
import useHasFinanceEntitlement from '../../../../Hooks/useHasFinanceEntitlement';

const SECTION_KEY = 'showYourWork:sections:bridge:';

const SYWBridgeFC: React.FC<SYWSectionProps> = ({ stats, gutterTop }) => {
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;
  const { t } = useTranslation();
  const {
    mean,
    stddev,
    threshold,
    topAssets,
    totalAssets,
    numVulnerable,
    recoveryCurve,
    returnPeriodData,
  } = stats;
  const { pathname }: { pathname: string } = useLocation();
  const { subtitle, statLabel, statValue, range, table, noWrap } = showYourWorkStyles({
    isVulnerable: mean > threshold,
    lightScrollBar: !pathname?.includes('/analysis/see-the-math'),
  });

  const {
    data: {
      planView: { planFilters },
    },
  } = useGetPlanViewFiltersQuery();
  const { data: userData } = useGetCurrentUserQuery();

  const { min, max, pctVulnerable } = React.useMemo(
    () => ({
      min: hoursToDayHourAbbrev(Math.max(0, mean - stddev)),
      max: hoursToDayHourAbbrev(mean + stddev),
      pctVulnerable: totalAssets > 0 ? roundNumToPlace((numVulnerable / totalAssets) * 100, 2) : 0,
    }),
    [mean, stddev, totalAssets, numVulnerable],
  );

  if (BRIDGE_RESTRICTION_HAZARD_TYPES.includes(planFilters?.hazardType)) {
    return (
      <section
        className="u-width--100"
        /// gutterTop is true when displayed under SYW content
        id={`bridge-section${!gutterTop ? '-detail' : ''}`}
        data-test-id="SYWBridge"
      >
        <Typography style={gutterTop && { marginTop: '28px' }} variant="h2" paragraph>
          {t(`${SECTION_KEY}downtime:title`)}
        </Typography>

        <Callout
          type="info"
          variant="quiet"
          className="MuiTypography-paragraph"
          dataTestId="Bridge-FloodWind-disclaimer"
        >
          {t(`${SECTION_KEY}downtime:note`)}
        </Callout>
      </section>
    );
  }

  return (
    <section
      className="u-width--100"
      /// gutterTop is true when displayed under SYW content
      id={`bridge-section${!gutterTop ? '-detail' : ''}`}
      data-test-id="SYWBridge"
    >
      <Typography style={gutterTop && { marginTop: '28px' }} variant="h2" paragraph>
        {t(`${SECTION_KEY}downtime:title`)}
      </Typography>

      <Typography className={statValue} data-test-id="SYWBridge-mean">
        {hoursToDayHourAbbrev(mean)}
      </Typography>
      <Typography className={statLabel} paragraph>
        {t(`${SECTION_KEY}downtime:averageDowntime`)}
      </Typography>
      <Typography variant="h5" className={range} gutterBottom data-test-id="downtimeRange">
        {min !== max && max !== '0h' && t('showYourWork:range', { min, max })}
      </Typography>
      <Typography className={statLabel} paragraph>
        {t('showYourWork:threshold', { value: hoursToDayHourAbbrev(threshold) })}
      </Typography>

      <Typography paragraph>{t(`${SECTION_KEY}downtime:desc`)}</Typography>
      <Typography paragraph>
        <strong>{t('showYourWork:resultsBasedOn')}</strong>
      </Typography>

      {!isFinanceEntitled && recoveryCurve?.length > 0 && (
        <>
          <Typography variant="subtitle1" className={subtitle} gutterBottom>
            {t(`${SECTION_KEY}recovery:title`)}
          </Typography>
          <Typography paragraph data-test-id="RecoveryChartDesc">
            {t(`${SECTION_KEY}recovery:desc`)}
          </Typography>
          <Typography paragraph data-test-id="RecoveryChartIntro">
            {t(`${SECTION_KEY}recovery:descChart`)}
          </Typography>
          <RecoveryCurveChart curve={recoveryCurve} lifeline="bridge" />
        </>
      )}

      <Typography variant="subtitle1" className={subtitle} gutterBottom>
        {t(`${SECTION_KEY}analysis:title`)}
      </Typography>
      <Typography paragraph>{t(`${SECTION_KEY}analysis:desc`)}</Typography>

      {!isFinanceEntitled && topAssets?.length > 0 && (
        <div data-test-id="topDisruptedAssetsIntro">
          <Typography variant="subtitle1" className={subtitle} gutterBottom>
            {t(`${SECTION_KEY}disruption:title`)}
          </Typography>
          <Typography paragraph>{t(`${SECTION_KEY}disruption:desc`)}</Typography>
        </div>
      )}
      {!isFinanceEntitled && (
        <Typography variant="h4">
          <Trans i18nKey={`${SECTION_KEY}disruption:numAnalyzed`}>
            <strong>Number of bridges in analysis:</strong>
            <Typography variant="h5" component="span" data-test-id="SYWBridge-numAnalyzed">
              <>{{ num: totalAssets.toLocaleString() }}</>
            </Typography>
          </Trans>
        </Typography>
      )}
      {!isFinanceEntitled && (
        <Typography variant="h4">
          <Trans i18nKey={`${SECTION_KEY}disruption:numVulnerable`}>
            <strong>At-risk bridges:</strong>
            <Typography variant="h5" component="span" data-test-id="SYWBridge-numVulnerable">
              <>{{ num: numVulnerable.toLocaleString() }}</>
            </Typography>
          </Trans>
        </Typography>
      )}
      {!isFinanceEntitled && (
        <Typography variant="h4" paragraph>
          <Trans i18nKey="showYourWork:pctVulnerable">
            <strong>% At risk:</strong>
            <Typography variant="h5" component="span" data-test-id="SYWBridge-pctVulnerable">
              <>{{ num: pctVulnerable }}</>
            </Typography>
          </Trans>
        </Typography>
      )}

      {!isFinanceEntitled && topAssets?.length > 0 && (
        <TableContainer className={table}>
          <Table data-test-id="SYWBridge-top3-table">
            <TableHead>
              <TableRow>
                <TableCell>{t(`${SECTION_KEY}table:bridgeCoordinates`)}</TableCell>
                <TableCell>{t(`${SECTION_KEY}table:yearBuilt`)}</TableCell>
                <TableCell>{t(`${SECTION_KEY}table:material`)}</TableCell>
                <TableCell>{t(`${SECTION_KEY}table:type`)}</TableCell>
                <TableCell>{t('showYourWork:tables:averageDowntime')}</TableCell>
                <TableCell>{t('showYourWork:tables:range')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {topAssets?.map(
                (
                  { id, coordinates, yearBuilt, material, bridgeType, stats: assetStats }: Bridge,
                  idx: number,
                ) => {
                  const assetMean = assetStats[0].mean;
                  const assetStdDev = assetStats[0].stddev;
                  const assetMin = Math.max(0, assetMean - assetStdDev);
                  const assetMax = assetMean + assetStdDev;
                  const assetMinStr = hoursToDayHourAbbrev(assetMin);
                  const assetMaxStr = hoursToDayHourAbbrev(assetMax);
                  return (
                    <TableRow key={id} data-test-id="row-top-asset-table">
                      <TableCell className={noWrap}>
                        {getLatLongString({
                          userPrefs: userData?.user?.preferences,
                          epicenter: {
                            coordinates,
                            type: GeoJsonType.Point,
                          },
                          coordLabelType: 'usesPlusMinus',
                        })}
                      </TableCell>
                      <TableCell>{yearBuilt ?? '-'}</TableCell>
                      <TableCell>{material ?? '-'}</TableCell>
                      <TableCell>{bridgeType ?? '-'}</TableCell>
                      <TableCell>{hoursToDayHourAbbrev(assetMean)}</TableCell>
                      <TableCell className={noWrap} data-test-id={`topAssets-row${idx}-range`}>
                        {t('showYourWork:tables:rangeValues', {
                          min: assetMinStr,
                          max: assetMaxStr,
                          context: assetMin === assetMax && assetMax === 0 ? 'zero' : 'nonZero',
                        })}
                      </TableCell>
                    </TableRow>
                  );
                },
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Typography variant="subtitle1" className={subtitle} gutterBottom>
        {t(`${SECTION_KEY}severityImpact:title`)}
      </Typography>
      <Typography paragraph data-test-id="ReturnPeriodChartIntro">
        {t(`${SECTION_KEY}severityImpact:desc`)}
      </Typography>
      {returnPeriodData?.length > 0 && (
        <ReturnPeriodChart points={returnPeriodData} threshold={threshold} lifeline="bridge" />
      )}

      <Typography paragraph>
        <Trans
          i18nKey={`showYourWork:${
            isFinanceEntitled ? 'exploreAnalysisCatnet' : 'exploreAnalysis'
          }`}
        >
          <strong>Did you know?</strong>
        </Trans>
      </Typography>
    </section>
  );
};

const SYWBridge = React.memo(SYWBridgeFC);
SYWBridge.displayName = 'SYWBridge';
export default SYWBridge;
