import * as lenvenshtein from 'damerau-levenshtein';
import distance from '@turf/distance';
import { point } from '@turf/helpers';

import {
  EditAddressInput,
  LocationStatus,
  MibRequestStatus,
  ParsedFileLineFragFragment,
} from '../../../__generated__/graphql';

export type LocRecord = ParsedFileLineFragFragment;
interface LevenshteinResponse {
  steps: number;
  relative: number;
  similarity: number;
}

// eslint-disable-next-line no-shadow
export enum BobjMatchStatus {
  MatchPending = 'matchPending',
  MatchError = 'matchError',
  MatchWarning = 'matchWarning',
  MatchSuccess = 'matchSuccess',
}

export const MATCH_WARNING_DISTANCE_THRESHOLD_M = 50;
export const MATCH_ERROR_DISTANCE_THRESHOLD_M = 250;

const MATCH_ERROR_ADDRTEXT_THRESHOLD = 0.2;
const MATCH_WARNING_ADDRTEXT_THRESHOLD = 0.99;

const MAPBOX_ACCURACY_SCORE_THRESHOLD = 0.79;
const MAPBOX_RELEVANCE_SCORE_THRESHOLD = 0.7;
const MAPBOX_SCORE_WARNING_THRESHOLD = 0.6;
const MAPBOX_SCORE_ERROR_THRESHOLD = 0.4;

export type MapboxAddressDetails = {
  // relevance_score: text matching score of the resulting address against the input address
  // (forward geocoding only). 1 is a perfect match.
  relevance_score?: number;
  // accuracy_score: a (0,1) accuracy score, corresponding to the accuracy code of the result
  // (reflects our interpretation of the accuracy field). 1 is better than 0.
  accuracy_score?: number;
  // accuracy corresponds to the "accuracy_score" above , e.g.
  // accuracy of 'rooftop' => accuracy_score = 1
  /// 'point' (=> accuracy_score = 0.9)
  // 'parcel' (=> accuracy_score = 0.8)
  // 'interpolated' (=> accuracy_score = 0.7)
  // 'approximate' (=> accuracy_score = 0.3)
  // 'street' (=> accuracy_score = 0.1)
  accuracy?: string;
  // types: the types of place returned by the geocoding API (e.g. 'address')
  types?: string[];
  // category: category of the returned place, for POI's (currently not supported by mapbox
  // permanent places API)
  category?: string;
};

export type MatchInfo = {
  matchStatus: BobjMatchStatus;
  overallMatchScore: number;
  mapboxMatchScore: number;
  distMatchScore: number;
  addrTextMatchScore: number;
  coordDistance?: number;
  geoDetails?: MapboxAddressDetails;
  bobjDetails?: MapboxAddressDetails;
};

const getMetersDistBetween: (locRecord: LocRecord) => number = (locRecord) => {
  let dist;

  const { locationMeta, builtObjectCoordinates, geocodedCoordinates } = locRecord.audit;
  const { quality } = locationMeta;
  if (quality?.distance !== null && quality?.distance !== undefined) {
    dist = quality?.distance;
  } else if (builtObjectCoordinates) {
    dist = Math.round(
      distance(point(geocodedCoordinates), point(builtObjectCoordinates), {
        units: 'meters',
      }),
    );
  }
  return Math.round(dist);
};

const getNormalizedLevTextDiff: (s1: string, s2: string) => number = (s1, s2) => {
  // The Levenshtein distance measures the difference between two text strings. It's the number
  // of single-character edits (modifications, deletion, or insertions) required to change
  // one string to another.  It's min is 0 and max is the length of the largest string.
  // To find a language independent measure of how different the original address & matched
  // building's address, we get the Levenshtein distance between the two, and then "normalize"
  // it so that it's a value between 0 and 1, where 1 means the strings perfectly match.

  const lev: LevenshteinResponse = lenvenshtein(s1?.toLowerCase() ?? '', s2?.toLowerCase() ?? '');
  const normLevScore = lev.similarity;
  return normLevScore;
};

const getAddrTextMatchScore: (locRecord: ParsedFileLineFragFragment) => {
  status: BobjMatchStatus;
  addrTextMatchScore: number;
} = (locRecord) => {
  // Returns a score {0,1} where 1 is a perfect match, 0 is none of the characters match
  const { locationMeta: locMeta } = locRecord.audit;
  const { geocodedAddress, builtObjectAddress, address } = locMeta;

  // Note: we can't use the levenshtein score returned by quality.addressLDistance  because we
  // need the text diff between the
  // geocodedAddress & the builtObjectAddress, both of which have been "normalized" by Mapbox
  // (so abbreviations, diacritics, spacing, case, punctuation) will be normalized away
  // and thus these won't affect our similarity score.

  const weights: { [key: string]: number } = {
    state: 0.5,
    city: 0.25,
    street: 0.18,
    houseNumber: 0.05,
    postCode: 0.02,
  };

  const scores: { [key: string]: number } = {
    state: 0,
    city: 0,
    street: 0,
    houseNumber: 0,
    postCode: 0,
  };
  const geoAddr: Record<string, any> = geocodedAddress;
  const bobjAddr: Record<string, any> = builtObjectAddress;
  const origAddr: Record<string, any> = address;
  let scoreTotal = 0;
  Object.keys(weights).forEach((addrPart) => {
    let textScore = getNormalizedLevTextDiff(geoAddr?.[addrPart] ?? '', bobjAddr?.[addrPart] ?? '');
    if (textScore < 1 && addrPart === 'postCode') {
      // since mapbox changes the postalCode to something incorrect so often, double check to
      // make sure that we are not flagging a difference if the originally provided postal code
      // is the same as the matched builtObject's postal code.
      // NOTE: Mapbox's postCode issues apply to Japan & US
      textScore = getNormalizedLevTextDiff(origAddr?.postalCode, bobjAddr?.postalCode);
    }
    const scorePart = weights[addrPart] * textScore;
    scores[addrPart] = scorePart;
    scoreTotal += scorePart;
  });

  if (scoreTotal <= MATCH_ERROR_ADDRTEXT_THRESHOLD) {
    return { status: BobjMatchStatus.MatchError, addrTextMatchScore: scoreTotal };
  }
  if (scoreTotal < MATCH_WARNING_ADDRTEXT_THRESHOLD) {
    return { status: BobjMatchStatus.MatchWarning, addrTextMatchScore: scoreTotal };
  }
  return { status: BobjMatchStatus.MatchSuccess, addrTextMatchScore: scoreTotal };
};

const getMapboxMatchScore: (locRecord: LocRecord) => {
  status: BobjMatchStatus;
  mapboxMatchScore: number;
} = (locRecord) => {
  // Returns a score {0,1} where 1 is mapbox's geocoder and reverse geocoder claim they
  // absolutely know the exact coordinates of both the user-provided address
  // (via forward geocoder), and that the coordinates of the building in OneC's builtObject
  // database absolutely correspond to the builtObject address we are displaying via Mapbox's
  // reverse geocoder.
  // A score of 0 indicates that mapBox has no knowledge of these two addresses
  const locMeta = locRecord?.audit?.locationMeta;

  const geoDet: MapboxAddressDetails = locMeta?.geocodedAddress?.addressDetails;
  const geoAcc = geoDet?.accuracy_score ?? 1;
  const geoRel = geoDet?.relevance_score ?? 1;

  const bobjDet: MapboxAddressDetails = locMeta?.builtObjectAddress?.addressDetails;
  const bobjAcc = bobjDet?.accuracy_score ?? 1;
  const bobjRel = bobjDet?.relevance_score ?? 1;

  const mapboxMatchScore = geoAcc * geoRel * bobjAcc * bobjRel;
  if (
    geoAcc < MAPBOX_ACCURACY_SCORE_THRESHOLD ||
    geoRel < MAPBOX_RELEVANCE_SCORE_THRESHOLD ||
    bobjAcc < MAPBOX_ACCURACY_SCORE_THRESHOLD ||
    bobjRel < MAPBOX_RELEVANCE_SCORE_THRESHOLD
  ) {
    return {
      status: BobjMatchStatus.MatchError,
      // If there is an issue with the originalAddress's zipcode, sometimes there actually is a
      // matching builtObjectAddress, even though the processStatus is FAILURE.
      // The following line forces error locations with a matching builtObject to have a higher
      // score than error locations that truly did not match any buildings.
      mapboxMatchScore,
    };
  }
  const status =
    mapboxMatchScore < MAPBOX_SCORE_WARNING_THRESHOLD
      ? BobjMatchStatus.MatchWarning
      : BobjMatchStatus.MatchSuccess;

  return { status, mapboxMatchScore };
};

const getDistanceMatchScore: (locRecord: LocRecord) => {
  status: BobjMatchStatus;
  distMatchScore: number;
  distBetween: number;
} = (locRecord) => {
  // Returns a score {0,1} where 1 is that the geocoded coordinats of the
  // user-provided address are with 25m of to the coordinates of the matching
  // builtObject ==> These coordinates are basically the same.
  // A score of 0 indicates that the coordinates are over 250m
  // apart, and thus they are not likely to be the same building.
  const distBetween = getMetersDistBetween(locRecord);

  if (
    distBetween === null ||
    distBetween === undefined ||
    distBetween >= MATCH_ERROR_DISTANCE_THRESHOLD_M
  ) {
    return { status: BobjMatchStatus.MatchError, distBetween, distMatchScore: 0 };
  }
  if (distBetween <= MATCH_WARNING_DISTANCE_THRESHOLD_M) {
    return { status: BobjMatchStatus.MatchSuccess, distBetween, distMatchScore: 1 };
  }

  const warnRange = MATCH_ERROR_DISTANCE_THRESHOLD_M - MATCH_WARNING_DISTANCE_THRESHOLD_M;
  const distMatchScore = (MATCH_ERROR_DISTANCE_THRESHOLD_M - distBetween) / warnRange;
  return { status: BobjMatchStatus.MatchWarning, distBetween, distMatchScore };
};

export const getLocationMatchInfo: (locRecord: ParsedFileLineFragFragment) => MatchInfo = (
  locRecord,
) => {
  // Comparing the original user-entered address to the builtObject is problematic because of
  // many differences in the way a single addresses can be formatted (abbreviations,
  // street suffixes/prefixes, etc).  We need to "normalize" the original address and the matching
  // building's address before we can compare them.

  // FORTUNATELY, the matching builtObject's address is already normalized by Mapbox's reverse
  // geocoder.  Thus, we will Mapbox's forward geocoder to normalize the original address, and then
  // we will compare this geocoded address to the matching builtObject's address
  // (using the Levenshtein text distance algorithm to tell how far the match is).

  // NOTE that if Mapbox's geocoder complains of low accuracy or relevance for the forward geocoding
  // of the user-provided address, then the building we match to is almost certainly the wrong one.
  // The same holds if Mapbox's reverse geocoder complained of low-accuracy or relvance for the
  // reverse geocoding of the builtObject's coordinates (while trying to get the closest street
  // address for the building's coordinates).

  // THUS, even if the geocoded address string & builtObject address string perfectly match, if the
  // accuracy/relevance of either the builtObjectAddress or geocodedAddress is bad, then we should
  // flag a match WARNING with a very low overall match score.
  //
  // If the distance between the geocoded coordiantes & the builtObject coordinates is > 250 meters
  // it's an ERROR.

  // If the Mapbox geocoder & reverse geocoder are both happy and distance between them is <250m,
  // we then do a weighted compare of the builtObject & geocodedaddress, giving weight to each of
  // the following:
  // 1) The distance in meters between the two coordinates
  // 2) The product of the relevance & accuracy for builtObject and geocoded address
  // 3) The normalized levenshtein difference between the geocoded address string and the
  // builtObject address string

  // NOTE: currently the backend does not return very helpful data for this algorithm, so
  // we are calculating most of it on the frontend.

  const builtObjectRequestStatus = locRecord?.audit?.builtObjectRequestStatus;
  if (builtObjectRequestStatus === MibRequestStatus.Pending) {
    return {
      matchStatus: BobjMatchStatus.MatchPending,
      // This makes sure that Pending locations are listed LAST in the LocationMatchTable when
      // sorted by Match Status
      overallMatchScore: 100,
      mapboxMatchScore: 1,
      distMatchScore: 1,
      addrTextMatchScore: 1,
    } as MatchInfo;
  }

  const locMeta = locRecord?.audit?.locationMeta;
  const geoAddress = locMeta?.geocodedAddress;
  const bobjAddress = locMeta?.builtObjectAddress;
  const hasBuildingMatch = !!bobjAddress;
  const processStatus = locRecord?.audit?.processStatus;

  // If the backend says it's an error, we flag these locations as Match Errors. These Error
  // locations are hidden from the analysis view and don't appear on the map.
  if (processStatus === LocationStatus.Failure || !geoAddress || !hasBuildingMatch) {
    return {
      matchStatus: BobjMatchStatus.MatchError,
      // If there is an issue with the originalAddress's zipcode, sometimes there actually is a
      // matching builtObjectAddress, even though the processStatus is FAILURE.
      // The following line forces error locations with a matching builtObject to have a higher
      // score than error locations that truly did not match any buildings.
      overallMatchScore: hasBuildingMatch ? 0.0001 : 0,
      mapboxMatchScore: hasBuildingMatch ? 0.0001 : 0,
      addrTextMatchScore: 0,
      distMatchScore: 0,
    } as MatchInfo;
  }

  const { status: mbMatchStatus, mapboxMatchScore } = getMapboxMatchScore(locRecord);
  const { status: distMatchStatus, distMatchScore, distBetween } = getDistanceMatchScore(locRecord);
  const { status: addrTextMatchStatus, addrTextMatchScore } = getAddrTextMatchScore(locRecord);

  // Calculate an overall match score so that we can list locations in order from most uncertain
  // match to best match.
  // I just made this formula up based on a LOT of experimentation with US addresses.
  // It needs to be tweaked to better handle different countries.
  // future based on feedback from Product Management and customers.
  let overallMatchScore;

  if (distMatchScore === 0) {
    // The distance between the originalAddress & matching building is > 250m, which means this
    // is almost definitely a very big match miss.
    overallMatchScore = 0.05;
  } else if (mapboxMatchScore < MAPBOX_SCORE_ERROR_THRESHOLD) {
    // Mapbox his VERY unsure that it got the right coordinates for the given address. This almost
    // always means that builtObject we match to it is incorrect. So we are going to weight the
    // MapboxScore extra high in this situation.
    overallMatchScore = (8 * mapboxMatchScore + distMatchScore + addrTextMatchScore) / 10;
  } else {
    // Mapbox is pretty sure it found the right locations and we have a matching commercial building
    // that is relatively nearby, so now we weigh the DISTANCE between the original address &
    // the builtObject coordinates as the most important factor in the match score
    overallMatchScore = (6 * distMatchScore + 3 * mapboxMatchScore + addrTextMatchScore) / 10;
  }

  const subStatuses = [mbMatchStatus, distMatchStatus, addrTextMatchStatus];
  let overallStatus: BobjMatchStatus = BobjMatchStatus.MatchSuccess;
  if (
    subStatuses.includes(BobjMatchStatus.MatchWarning) ||
    subStatuses.includes(BobjMatchStatus.MatchError)
  ) {
    // NOTE: ideally, we'd change the overall status to "Error" if there is an "error" returned
    // from any of the mapboxMatchScore, DistanceMatchScore, or AddressTextMatchScore checks.
    // Even more ideally, the backend would just do all these checks and return the correct
    // processStatus (error, warning, or success).
    // But since we don't want to have a situation where SOME error locations are hidden (and thus
    // not shown on the map) but other error locations are shown on the map, we'll just make all
    // these locations have Warnings instead of errors.
    overallStatus = BobjMatchStatus.MatchWarning;
  }

  return {
    matchStatus: overallStatus,
    overallMatchScore,
    mapboxMatchScore,
    distMatchScore,
    coordDistance: distBetween,
    addrTextMatchScore,
    geoDetails: geoAddress?.addressDetails,
    bobjDetails: bobjAddress?.addressDetails,
  } as MatchInfo;
};

export type LocRecordWithMatchInfo = LocRecord & { matchInfo: MatchInfo };

export function sortLocRecordsByMatchAccuracy(
  recordsWithMatchInfo: LocRecordWithMatchInfo[],
): LocRecordWithMatchInfo[] {
  if (!recordsWithMatchInfo) {
    return null;
  }

  // sort the list, so the worst matches are on top
  const sortedLocs = recordsWithMatchInfo.sort((loc1, loc2) => {
    if (loc1.matchInfo.matchStatus !== loc2.matchInfo.matchStatus) {
      const matchStatusDict = {
        [BobjMatchStatus.MatchError]: -2,
        [BobjMatchStatus.MatchWarning]: -1,
        [BobjMatchStatus.MatchSuccess]: 0,
        [BobjMatchStatus.MatchPending]: 10,
      };
      return (
        matchStatusDict[loc1.matchInfo.matchStatus] - matchStatusDict[loc2.matchInfo.matchStatus]
      );
    }
    // The match statuses are the dame
    const scoreDiff = loc1.matchInfo.overallMatchScore - loc2.matchInfo.overallMatchScore;
    if (scoreDiff !== 0) {
      return scoreDiff;
    }
    // match scores are the same, so now look at Distance between
    return (
      (loc2?.matchInfo?.coordDistance ?? MATCH_ERROR_DISTANCE_THRESHOLD_M) -
      (loc1?.matchInfo?.coordDistance ?? MATCH_ERROR_DISTANCE_THRESHOLD_M)
    );
  });
  return sortedLocs;
}

export type LocInfo = {
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addressInput: EditAddressInput & { [key: string]: any };
  name: string;
  type: string;
  group: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type LocRecordCounts = {
  totalAccepted: number;
  totalRejected: number;
  totalWarnings: number;
  totalPending: number;
};
type LocationMatchStatuses = {
  locRecordsWithMatchStatus: LocRecordWithMatchInfo[];
  totalCounts: LocRecordCounts;
};

export const getLocationsMatchStatuses: (
  locRecords: ParsedFileLineFragFragment[],
) => LocationMatchStatuses = (locRecords) => {
  const counts: LocRecordCounts = {
    totalAccepted: 0,
    totalRejected: 0,
    totalPending: 0,
    totalWarnings: 0,
  };
  const locRecsWithStatus = locRecords?.map((locRecord) => {
    const matchInfo = getLocationMatchInfo(locRecord);
    switch (matchInfo.matchStatus) {
      case BobjMatchStatus.MatchError:
        counts.totalRejected += 1;
        break;
      case BobjMatchStatus.MatchPending:
        counts.totalPending += 1;
        break;
      case BobjMatchStatus.MatchWarning:
        counts.totalWarnings += 1;
        break;
      case BobjMatchStatus.MatchSuccess:
      default:
        counts.totalAccepted += 1;
        break;
    }
    return { ...locRecord, matchInfo } as LocRecordWithMatchInfo;
  });
  return { locRecordsWithMatchStatus: locRecsWithStatus, totalCounts: counts } as {
    locRecordsWithMatchStatus: LocRecordWithMatchInfo[];
    totalCounts: LocRecordCounts;
  };
};
