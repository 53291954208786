import * as React from 'react';
import { App } from '../PlanningApp/AppConfig';

import { HistoricalEvent, useGetHistoricalEventsQuery } from '../__generated__/graphql';

function useHistoricalEvent(id?: string): HistoricalEvent {
  const { data, loading, error } = useGetHistoricalEventsQuery({ skip: !id });

  return React.useMemo(() => {
    let selectedEvent;

    if (error) {
      App.error(`[useHistoricalEvent] error retrieving historical events: ${error.message}`);
    }

    if (!loading && !error && data?.historicalEvents?.length > 0) {
      selectedEvent = data.historicalEvents.find((event) => event.id === id);

      if (!selectedEvent) {
        App.warn(`[useHistoricalEvent] historical event ID ${id} not found`);
      }
    }

    return selectedEvent;
  }, [id, data, loading, error]);
}

export default useHistoricalEvent;
