import * as React from 'react';

import { SnackbarKey, useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

const DismissSnackbarButton: React.FC<{ id: SnackbarKey }> = ({ id }) => {
  const { closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const onClickDismiss = React.useCallback(() => closeSnackbar(id), [id, closeSnackbar]);

  return (
    <Button onClick={onClickDismiss} data-test-id="snackbar-dismiss-button">
      {t('buttons:dismiss')}
    </Button>
  );
};

DismissSnackbarButton.displayName = 'DismissSnackbarButton';
export default DismissSnackbarButton;
