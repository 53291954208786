import * as React from 'react';
import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { useTranslation } from 'react-i18next';

import { LIST_HIGHLIGHT, ORANGE, PURPLE, WHITE } from '../../../util/productGlobals';
import { hoursToDayHourAbbrev } from '../../../util/time';
import i18next from '../../../PlanningApp/LocalizationClient';
import ReturnPeriodDot from './ReturnPeriodDot';

const xTickFormatter = (value: number) => value ?? '';
const yTickFormatterDowntime = (value: number) => (value ? hoursToDayHourAbbrev(value) : '');
const yTickFormatterDamageRatio = (value: number) =>
  value ? i18next.t('damageRatioPercent', { pct: value }) : '';

type ReturnPeriodChartProps = {
  points: number[][];
  threshold: number;
  lifeline?: string;
};

const ReturnPeriodChartFC: React.FC<ReturnPeriodChartProps> = ({ points, threshold, lifeline }) => {
  const { t } = useTranslation();
  const xLabel: string = t('showYourWork:charts:returnXAxis');
  const yLabel =
    lifeline === 'structure'
      ? t('showYourWork:charts:returnYAxisDamageRatio')
      : t('showYourWork:charts:returnYAxisDowntime');
  const thresholdLabel: string = t('showYourWork:charts:threshold');

  // add initial point at [0, 0.0001] to force a delta in edge case where all values are 0:
  // - if all y-values (downtime) are equal, the line won't show up because recharts sets the
  //   element height to 0 (yMax - yMin = 0)
  const data = React.useMemo(
    () =>
      [{ years: 0, downtime: 0.0001 }].concat(
        points
          ?.map(([years, downtime]) => ({ years, downtime }))
          ?.sort((a: any, b: any) => a.years - b.years),
      ),
    [points],
  );

  const stops = React.useMemo(() => {
    const values: [number, string][] = [];

    for (let i = 0, len = data.length; i < len; i += 1) {
      const { years, downtime } = data[i];
      const isVulnerable = downtime > threshold;
      const color = isVulnerable ? PURPLE : ORANGE;
      values.push([years / 1000, color]);

      if (i < len - 1) {
        const { years: nextYears, downtime: nextDowntime } = data[i + 1];
        const isNextVulnerable = nextDowntime > threshold;

        // add purple stop where threshold meets line if line crosses threshold
        if (isVulnerable !== isNextVulnerable) {
          const slope = (downtime - nextDowntime) / (years - nextYears);
          const yIntercept = downtime - slope * years;
          const thresholdX = (threshold - yIntercept) / slope;
          values.push([thresholdX / 1000, PURPLE]);
        }
      }
    }

    const gradientStops = values.map(([offset, color]) => (
      <stop key={`${offset}-${color}`} offset={offset} stopColor={color} />
    ));

    return gradientStops;
  }, [data, threshold]);

  const maxYTick = React.useMemo(() => {
    const maxY = Math.max(threshold, points.sort((a, b) => b[1] - a[1])[0]?.[1]);
    // add minimum white space buffer to top of chart
    return maxY + Math.max(2, Math.round(maxY * 0.3));
  }, [points, threshold]);

  return (
    <div
      style={{ maxWidth: '640px', marginBottom: '16px' }}
      data-test-id={`ReturnPeriodChart-${lifeline}`}
    >
      <ResponsiveContainer width="100%" height={340}>
        <LineChart data={data} margin={{ top: 20, right: 30, bottom: 80, left: 100 }}>
          <defs>
            <linearGradient id={`${lifeline}-return-gradient`}>{stops}</linearGradient>
          </defs>
          <CartesianGrid stroke={LIST_HIGHLIGHT} strokeDasharray="10" vertical={false} />
          <XAxis
            data-test-id="xAxis"
            type="number"
            dataKey="years"
            domain={[0, 1000]}
            stroke={WHITE}
            tick={{ fill: WHITE }}
            tickSize={10}
            tickMargin={-40}
            tickFormatter={xTickFormatter}
            allowDecimals={false}
            mirror
          >
            <Label
              value={xLabel}
              fontWeight="bold"
              fill={WHITE}
              dy={75}
              data-test-id="xAxisTitle"
            />
          </XAxis>
          <YAxis
            data-test-id="yAxis"
            type="number"
            dataKey="downtime"
            domain={[0, maxYTick]}
            stroke={WHITE}
            tick={{ fill: WHITE, textAnchor: 'end' }}
            tickLine={false}
            tickCount={6}
            tickMargin={-20}
            tickFormatter={
              lifeline === 'structure' ? yTickFormatterDamageRatio : yTickFormatterDowntime
            }
            allowDecimals={false}
            mirror
          >
            <Label
              value={yLabel}
              fontWeight="bold"
              fill={WHITE}
              angle={-90}
              dx={-110}
              data-test-id="yAxisTitle"
            />
          </YAxis>
          <ReferenceLine y={maxYTick} />
          <ReferenceLine x={1000} />
          <ReferenceLine y={threshold} stroke={WHITE} strokeWidth={2}>
            <Label value={thresholdLabel} fontSize="8px" fill={WHITE} position="insideBottomLeft" />
          </ReferenceLine>
          <Line
            dataKey="downtime"
            stroke={`url(#${lifeline}-return-gradient)`}
            strokeWidth={2}
            isAnimationActive={false}
            dot={<ReturnPeriodDot threshold={threshold} />}
            data-test-id="returnperiod-chart-line"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

const ReturnPeriodChart = React.memo(ReturnPeriodChartFC);
ReturnPeriodChart.displayName = 'ReturnPeriodChart';
export default ReturnPeriodChart;
