import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { DARKBLUE } from '../util/productGlobals';
import AppNav from '../Components/ProductComponents/AppNav/AppNav';
import FiltersBar from '../Components/ProductComponents/Filters/FiltersBar';
import { NavigatorViewType } from '../__generated__/graphql';
import OnecLogo from '../Components/CommonComponents/Map/OnecLogo';
import Spinner from '../Components/CommonComponents/Spinner/Spinner';

const PlanningAppSkeleton: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div
      data-test-id="PlanningAppSkeleton"
      style={{ height: '100%', width: '100%', backgroundColor: DARKBLUE }}
    >
      {/* TODO - might want to change thes AppNav component so that the hazard selector, search, and
          user profile button are all visually disabled (and maybe blinking) while the
          initial app data is loading?
      */}
      <AppNav userId="" />
      <FiltersBar currentNavigatorView={NavigatorViewType.MapView} userId="" />
      <Spinner />
      <OnecLogo corner="bottom-left" url={t('settings@oneconcernURL')} />
    </div>
  );
};
PlanningAppSkeleton.displayName = 'PlanningAppSkeleton';
export default PlanningAppSkeleton;
