import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import RefreshIcon from '@mui/icons-material/Refresh';

import {
  useGetLocationsInfoWithFilterQuery,
  useGetMapMovedQuery,
} from '../../../__generated__/graphql';
import useUpdateVisibleLocations from '../../../Hooks/useUpdateVisibleLocations';

const useStyles = makeStyles({
  button: {
    height: 32,
  },
});

const UpdateResultsButton: React.FC = () => {
  const { button } = useStyles();
  const { t } = useTranslation();
  const {
    data: {
      mapMoved: { state },
    },
  } = useGetMapMovedQuery();

  const { data, error } = useGetLocationsInfoWithFilterQuery({
    variables: {
      input: {
        matchCriteria: {
          isPendingMIB: false,
        },
      },
    },
  });

  const updateVisibleLocations = useUpdateVisibleLocations();

  if (error || !data?.locations?.length) {
    return null;
  }

  if (state) {
    return (
      <Button
        data-test-id="UpdateResultsButton"
        className={button}
        variant="outlined"
        color="primary"
        startIcon={<RefreshIcon />}
        onClick={updateVisibleLocations}
      >
        {t('buttons:updateResults')}
      </Button>
    );
  }
  return <div />;
};

export default UpdateResultsButton;
