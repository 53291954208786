import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DARKBLUE2, GLASS_BG, GRAY1, ORANGE, PURPLE } from '../../../../util/productGlobals';

type showYourWorkStylesProps = {
  isVulnerable: boolean;
  lightScrollBar?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    color: GRAY1,
    fontWeight: 'bold',
  },
  bodyLink: {
    color: ORANGE,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  statLabel: {
    color: 'rgba(248, 247, 242, 0.5)',
  },
  statValue: {
    fontSize: theme.typography.pxToRem(53),
    fontWeight: 700,
    lineHeight: theme.typography.pxToRem(53),
    letterSpacing: theme.typography.pxToRem(-0.4),
    marginBottom: theme.typography.pxToRem(8),
    color: ({ isVulnerable }: showYourWorkStylesProps) => (isVulnerable ? PURPLE : ORANGE),
  },
  range: {
    lineHeight: theme.typography.pxToRem(22),
  },
  table: {
    marginBottom: theme.spacing(8),
    paddingBottom: theme.spacing(2),
    maxWidth: '800px',
    '& .MuiTableCell-root': {
      border: '1px solid #979794',
      ...theme.typography.caption,
    },
    '& .MuiTableCell-head': {
      fontWeight: 800,
      ...theme.typography.body2,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: ({ lightScrollBar }: showYourWorkStylesProps) =>
        lightScrollBar ? DARKBLUE2 : GLASS_BG,
    },
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
}));

export default useStyles;
