import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { CardHeader, MenuList, Theme, Typography } from '@mui/material';
import { MaterialitySidebarCategory } from 'onec-types';

import MaterialityNavItem from './MaterialityNav';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 1,
    overflow: 'hidden',
    minWidth: '300px',
    maxWidth: '420px',
    width: '100%',
    paddingBottom: theme.spacing(8),
  },
  title: {
    marginLeft: theme.spacing(16),
    marginTop: theme.spacing(16),
    padding: 0,
  },
  navContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    marginLeft: theme.spacing(14),
    marginRight: theme.spacing(14),
    marginBottom: 'auto',
  },
  description: {
    marginTop: theme.spacing(2),
  },
  subtitle: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(8),
    marginLeft: theme.spacing(16),
    marginRight: theme.spacing(10),
  },
  materialityNav: {
    paddingRight: theme.spacing(1),
  },
}));

type MaterialitySidebarProps = {
  categories: MaterialitySidebarCategory[];
  activeCategory: MaterialitySidebarCategory;
  handleClick: (e: React.MouseEvent) => void;
};

const MaterialitySidebarFC: React.FC<MaterialitySidebarProps> = ({
  activeCategory,
  handleClick,
  categories,
}) => {
  const { t } = useTranslation();
  const { container, title, navContainer, description, subtitle, materialityNav } = useStyles();

  // display logo on top of modal
  React.useEffect(() => {
    const logo = document.getElementById('onec-logo');
    const { zIndex } = logo?.style ?? {};
    if (logo) {
      logo.style.zIndex = '1301';
      logo.style.left = '8px';
    }

    return () => {
      if (logo) {
        logo.style.zIndex = zIndex;
        logo.style.left = '0';
      }
    };
  }, []);

  return (
    <div className={container} data-test-id="MaterialitySidebar">
      <CardHeader
        className={title}
        title={t('nav:materiality')}
        titleTypographyProps={{ variant: 'h2' }}
      />
      <div className={subtitle} data-test-id="MaterialitySidebarHeader">
        <Typography variant="h4">{t('materiality:subtitle')}</Typography>
        <div className={description}>
          <Typography variant="caption">{t('materiality:description')}</Typography>
        </div>
      </div>
      <nav className={navContainer} data-test-id="MaterialityNav">
        <MenuList className={materialityNav} disablePadding>
          {categories.map((category: MaterialitySidebarCategory) => (
            <MaterialityNavItem
              key={category.name}
              icon={category.icon}
              category={category.name}
              isActive={activeCategory.name === category.name}
              handleClick={handleClick}
            />
          ))}
        </MenuList>
      </nav>
    </div>
  );
};

const MaterialitySidebar = React.memo(MaterialitySidebarFC);
MaterialitySidebar.displayName = 'MaterialitySidebar';
export default MaterialitySidebar;
