import * as React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { Trans, useTranslation } from 'react-i18next';

import {
  Button,
  Card,
  CardContent,
  Grid,
  Grow,
  Modal,
  Stack,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { GLASS_BG, ONECONCERN_SUPPORT_EMAIL } from '../../../../util/productGlobals';
import { validateEmail } from '../../../../util/productUtils';
import StyledSnackbarContent from '../../../CommonComponents/Snackbar/StyledSnackbarContent';

import { App } from '../../../../PlanningApp/AppConfig';
import useShareableURL from '../../../../Hooks/useShareableURL';
import { sendAmplitudeData } from '../../../../plugins/amplitude';
import {
  useGetCurrentUserQuery,
  useShareAnalysisMutation,
} from '../../../../__generated__/graphql';
import { AMP_EVENT_VIEWER_SHARE_LOCATION } from '../../../../plugins/amplitudeevents';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    maxWidth: '600px',
    maxHeight: '350px',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%) !important',
    outline: 0,
    backgroundColor: GLASS_BG,
    padding: theme.spacing(6),
  },
  contentContainer: {
    paddingTop: theme.spacing(6),
  },
}));

// This error message is from ShareAnalysis mutation.
const ErrUserAlreadyDisabled = 'user already exists and disabled';

const ShareLocationModal: React.FC = () => {
  const { card, contentContainer } = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();
  const [isOpen, setIsOpen] = React.useState(true);
  const [email, setEmailEditing] = React.useState('');
  const [firstNameEditing, setFirstNameEditing] = React.useState('');
  const [lastNameEditing, setLastNameEditing] = React.useState('');

  const { data: userData } = useGetCurrentUserQuery();

  const handleChangeFirstName = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      setFirstNameEditing(e.target.value),
    [setFirstNameEditing],
  );

  const handleChangeLastName = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      setLastNameEditing(e.target.value),
    [setLastNameEditing],
  );

  const handleChangeEmail = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      setEmailEditing(e.target.value),
    [setEmailEditing],
  );

  const {
    url: analysisUrl,
    builtObjectId,
    loading: urlLoading,
    error: urlErr,
  } = useShareableURL(id);
  React.useEffect(() => {
    if (!urlLoading && !analysisUrl) {
      enqueueSnackbar(
        <StyledSnackbarContent
          title={t('shareLocation:snackbar:urlErrorTitle')}
          description={!urlErr && t('shareLocation:snackbar:urlErrorDesc')}
        />,
        { variant: 'error' },
      );
    }
  }, [analysisUrl, urlLoading, urlErr, enqueueSnackbar, t]);

  const goToDetailView = React.useCallback(() => {
    setIsOpen(false);
    // reset fields
    setEmailEditing('');
    setFirstNameEditing('');
    setLastNameEditing('');
    setTimeout(() => navigate(`/analysis/detail/${id}`), 300);
  }, [id, navigate]);

  const [shareAnalysisMutation] = useShareAnalysisMutation({
    onCompleted: () => {
      enqueueSnackbar(
        <StyledSnackbarContent
          title={t('shareLocation:snackbar:inviteSuccess', { email: email.trim() })}
        />,
        {
          variant: 'success',
        },
      );
      if (userData) {
        const userId = userData.user.personalInfo.contact.email;
        sendAmplitudeData(AMP_EVENT_VIEWER_SHARE_LOCATION, {
          userId,
          viewer: email.trim(),
          builtObjectId,
        });
      }
      goToDetailView();
    },
    onError: (error) => {
      enqueueSnackbar(
        <StyledSnackbarContent
          title={t('shareLocation:snackbar:inviteError')}
          description={
            <Trans
              i18nKey={
                error.message?.includes(ErrUserAlreadyDisabled)
                  ? 'shareLocation:snackbar:userAlreadyDisabledDesc'
                  : 'shareLocation:snackbar:unknownErrorDesc'
              }
              values={{
                email,
              }}
            >
              <a href={t('err:contactEmail')}>{ONECONCERN_SUPPORT_EMAIL}</a>
            </Trans>
          }
        />,
        {
          variant: 'error',
        },
      );
      App.error('[ShareLocationModal] share analysis mutation error:', error);
    },
  });

  const handleShareAnalysis = React.useCallback(() => {
    if (!validateEmail(email.trim())) {
      enqueueSnackbar(
        <StyledSnackbarContent
          title={t('shareLocation:snackbar:inviteError')}
          description={t('shareLocation:snackbar:invalidEmailDesc')}
        />,
        {
          variant: 'error',
        },
      );
      return;
    }
    shareAnalysisMutation({
      variables: {
        input: {
          analysisUrl,
          firstName: firstNameEditing.trim(),
          lastName: lastNameEditing.trim(),
          email: email.trim(),
        },
      },
    });
  }, [
    analysisUrl,
    email,
    enqueueSnackbar,
    firstNameEditing,
    lastNameEditing,
    shareAnalysisMutation,
    t,
  ]);

  if (!analysisUrl) {
    return null;
  }
  return (
    <Modal
      className="o-flex-center-container"
      onClose={goToDetailView}
      open={isOpen}
      closeAfterTransition
    >
      <Grow in={isOpen}>
        <Card className={card} data-test-id="ShareLocationModal-card">
          <CardContent>
            <Grid container>
              <Grid item>
                <Typography variant="h2" data-test-id="ShareModal-Title">
                  {t('shareLocation:title')}
                </Typography>
                <Grid item>
                  <Typography data-test-id="ShareModal-Instructions">
                    {t('shareLocation:instructions')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* text inputs */}
            <Stack gap={4}>
              <Grid container className={contentContainer} spacing={2}>
                <Grid item>
                  <TextField
                    label={t('settings:sections:userManagement:addUser:firstName')}
                    type="text"
                    variant="outlined"
                    size="small"
                    value={firstNameEditing}
                    onChange={handleChangeFirstName}
                    inputProps={{ 'data-test-id': 'ShareModal-FirstName' }}
                    disabled={!analysisUrl}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label={t('settings:sections:userManagement:addUser:lastName')}
                    type="text"
                    variant="outlined"
                    size="small"
                    value={lastNameEditing}
                    onChange={handleChangeLastName}
                    inputProps={{ 'data-test-id': 'ShareModal-LastName' }}
                    disabled={!analysisUrl}
                  />
                </Grid>
              </Grid>
              <TextField
                label={t('shareLocation:email')}
                type="email"
                size="small"
                variant="outlined"
                placeholder="example@example.com"
                value={email}
                onChange={handleChangeEmail}
                inputProps={{ 'data-test-id': 'ShareModal-email' }}
                disabled={!analysisUrl}
                sx={{ width: 398 }}
              />
            </Stack>
            <Grid container className={contentContainer} spacing={2}>
              <Grid item>
                <Button
                  type="reset"
                  name="cancel"
                  fullWidth
                  color="primary"
                  variant="outlined"
                  onClick={goToDetailView}
                  data-test-id="ShareLocationModal-CancelShareButton"
                >
                  {t('buttons:cancel')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  name="share"
                  fullWidth
                  color="primary"
                  disabled={
                    !email.trim() ||
                    !firstNameEditing.trim() ||
                    !lastNameEditing.trim() ||
                    !analysisUrl
                  }
                  variant="contained"
                  onClick={handleShareAnalysis}
                  data-test-id="ShareLocationModal-ShareButton"
                >
                  {t('shareLocation:shareButton')}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
          <Outlet context={{ handleClose: goToDetailView }} />
        </Card>
      </Grow>
    </Modal>
  );
};

export default ShareLocationModal;
