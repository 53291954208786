import * as React from 'react';

import { Button, Card, CardContent, CardHeader, Theme, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { LngLatBounds, Map } from 'mapbox-gl';
import { OnecWindow } from 'onec-types';
import CreateIcon from '@mui/icons-material/Create';
import { GLASS_BG } from '../../../util/productGlobals';
import AddCompany from './AddCompany';
import FinanceTable from './FinanceTable';
import {
  useClearSimulationMutation,
  useGetCompaniesQuery,
  useGetCurrentUserQuery,
  useGetMapViewQuery,
  useGetUserCompaniesQuery,
} from '../../../__generated__/graphql';
import Spinner from '../../CommonComponents/Spinner/Spinner';

import { App } from '../../../PlanningApp/AppConfig';
import useHasFinanceEntitlement from '../../../Hooks/useHasFinanceEntitlement';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'absolute',
    top: 72,
    right: 10,
    width: 'calc(100% - 18px)',
    height: 'calc(100% - 144px)',
  },
  card: {
    overflowY: 'auto',
    maxHeight: '100%',
    height: '100%',
    backgroundColor: GLASS_BG,
    borderRadius: theme.spacing(1.5),
    backdropFilter: 'blur(16px)',
    pointerEvents: 'auto',
  },
}));

const FinanceViewContainer = () => {
  const { card, container } = useStyles();
  const { data: userData } = useGetCurrentUserQuery();
  const { data, loading } = useGetCompaniesQuery();
  const userId = userData?.user?.personalInfo?.contact?.email;
  const { data: dataUserCompanies, loading: loadingUserCompanies } = useGetUserCompaniesQuery();
  const { data: previousMapViewData } = useGetMapViewQuery();
  const [clearSimulationMutation] = useClearSimulationMutation();
  const { data: financeModules } = useHasFinanceEntitlement();
  const isSimulationEnabled = financeModules?.enablesimulations ?? false;

  React.useEffect(() => {
    // If coming from Detail View (or another URL) back to List View, then move the map
    // bounds back to bounds  previously saved just before last leaving list view.
    if (previousMapViewData) {
      const { southEast, northWest } = previousMapViewData.previousMapView;
      const map: Map = (window as OnecWindow).reactMap;
      if (map) {
        App.debug('[FinanceViewContainer] setting map bounds back to previous');
        const bounds = new LngLatBounds(
          [southEast.longitude, southEast.latitude],
          [northWest.longitude, northWest.latitude],
        );
        map.fitBounds(bounds, {}, { fitboundUpdate: true });
      }
    }
  }, [previousMapViewData]);

  const companyList = data?.getCompanies ?? [];
  const userPortfolio = dataUserCompanies?.getUserPortfolio ?? [];

  const handleNewSimulation = React.useCallback(() => {
    clearSimulationMutation();
  }, [clearSimulationMutation]);

  return (
    <div className={`u-zindex--5 ${container}`} data-test-id={FinanceViewContainer.displayName}>
      <Card className={`u-padding--32 u-margintop--8 ${card}`} elevation={0}>
        <CardHeader
          className="u-no-padding"
          title={
            <Typography variant="h1" sx={{ fontSize: 28 }}>
              Financial Impacts of Company Physical Risks
            </Typography>
          }
          action={
            isSimulationEnabled && (
              <Button
                variant="outlined"
                component={RouterLink}
                to="/finance/simulation/create"
                aria-label="create simulation"
                title="Create Simulation"
                startIcon={<CreateIcon />}
                onClick={handleNewSimulation}
                data-test-id="create-simulation-btn"
              >
                Create Simulation
              </Button>
            )
          }
          // action={
          //   <IconButton
          //     component={RouterLink}
          //     to="/finance/analysis/settings"
          //     aria-label="financial loss drivers settings"
          //     title="financial loss drivers settings"
          //   >
          //     <SettingsIcon sx={{ color: WHITE }} />
          //   </IconButton>
          // }
        />
        <CardContent sx={{ px: 0 }}>
          {!companyList?.length || loading ? (
            <Spinner />
          ) : (
            <>
              <AddCompany
                list={companyList}
                companies={userPortfolio?.map((company) => company.id)}
                userId={userId}
              />
              {loadingUserCompanies ? <Spinner /> : <FinanceTable companies={userPortfolio} />}
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

FinanceViewContainer.displayName = 'FinanceViewContainer';

export default FinanceViewContainer;
