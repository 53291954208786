/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-extraneous-dependencies */
import * as Chance from 'chance';
import { GraphQLError } from 'graphql';
import { LocationProps } from 'onec-types';
import { merge } from 'lodash';
import { MockList } from 'graphql-tools';
import { point } from '@turf/helpers';
import transformTranslate from '@turf/transform-translate';

import {
  Airport,
  BasicLifelineAssetStatsArgs,
  Bridge,
  Building,
  BuiltObject,
  BuiltObjectResilienceStatsArgs,
  GetBuiltObjectQueryVariables,
  GetLocationQueryVariables,
  HighwaySegment,
  HistoricalEvent,
  LocationObject,
  LocationObjectResilienceStatsArgs,
  Port,
  Product,
  QuerySearchArgs,
  QuerySearchNearestBuiltObjectArgs,
  ResilienceThresholds,
  SearchNearestBuiltObjectQuery,
  SliceHazardType,
  Subdivision,
  Substation,
  UpdateThresholdsMutationVariables,
  User,
} from '../__generated__/graphql';
import { prefsImperialEnUS, prefsMetricJaJP } from './testConstants';
import {
  airports as whAirports,
  bridges as whBridges,
  highways as whHighways,
  people as whPeople,
  ports as whPorts,
  resilienceStats as whResilienceStats,
  returnPeriodStats as whReturnPeriodStats,
  structural as whStructural,
} from './fixtures/builtObjectAnalysisFixture';
import makeMockLifelineAssets from './mockServerData/makeMockLifelineAssets';
import makeMockLifelineStats from './mockServerData/makeMockLifelineStats';
import makeMockLocationData from './mockServerData/makeMockLocationData';
import makeMockResilienceStats from './mockServerData/makeMockResilienceStats';
import makeMockSlices from './mockServerData/makeMockSlices';
import tinyMockLocations from './mockServerData/tinyMockLocations';

const chance = new Chance(1);

const USE_FIXED_TINY_MOCK = false;
const locations: LocationProps[] = USE_FIXED_TINY_MOCK
  ? tinyMockLocations
  : makeMockLocationData(chance);

const MOCK_JAPAN = false;
const currentYear = new Date().getFullYear();

// We use these as a "database" so that Mutations can change their values and then Queries can
// read the new threshold values.
const storedThresholds: ResilienceThresholds = {
  structuralDamage: 5,
  powerDowntime: 2,
  peopleDowntime: 3,
  airportDowntime: 4,
  portDowntime: 5,
  highwayDowntime: 6,
  bridgeDowntime: 7,
};
const storedHistoricalEvents: HistoricalEvent[] = [
  {
    id: '1',
    name: 'Napa',
    type: SliceHazardType.Earthquake,
    year: '2014-08-24T10:20:00.000Z',
    __typename: 'HistoricalEvent',
  },
  {
    id: '2',
    name: 'Sandy',
    type: SliceHazardType.Hurricane,
    year: '2012-10-22T00:00:00.000Z',
    __typename: 'HistoricalEvent',
  },
  {
    id: '3',
    name: 'Harvey',
    type: SliceHazardType.Hurricane,
    year: '2017-08-17T00:00:00.000Z',
    __typename: 'HistoricalEvent',
  },
  {
    id: '4',
    name: 'Katrina',
    type: SliceHazardType.Hurricane,
    year: '2005-08-23T00:00:00.000Z',
    __typename: 'HistoricalEvent',
  },
  {
    id: '5',
    name: 'Loma Prieta',
    type: SliceHazardType.Earthquake,
    year: '1989-10-17T00:00:00.000Z',
    __typename: 'HistoricalEvent',
  },
];

export const mocks = {
  Query: () => ({
    organization: () => ({
      id: () => (MOCK_JAPAN ? '/Kumomoto City' : `/${chance.company()}`),
      name: () => (MOCK_JAPAN ? 'Kumomoto City TEST' : chance.company()),
      users: () => [] as User[],
    }),
    location: (root: unknown, args: GetLocationQueryVariables) => {
      const matchingloc = locations.find((loc) => String(loc.id) === args.id);
      if (!matchingloc) {
        throw new GraphQLError(
          'do not count on this fake error message: ' +
            `Graphql error - location with id ${args.id} not found`,
        );
      }
      return matchingloc;
      // throw new GraphQLError('error from location query');
    },
    locations: () => {
      return locations;
      // throw new GraphQLError('error from locations query');
    },
    builtObject: (root: unknown, args: GetBuiltObjectQueryVariables) => {
      if (args.id === '100100100100') {
        return {
          id: '100100100100',
          name: 'BUILT OBJECT FOUND',
          address: {
            houseNumber: '1234',
            street: 'Placeholder Street',
            postCode: '100010100101',
            state: 'California',
            stateCode: 'CA',
            country: 'United States',
            countryCode: 'US',
            city: 'Placeholder city (Pomona)',
            addressDetails: {
              accuracy: 'rooftop',
            },
          },
          coordinates: [-117.82514228473924, 34.05579761483877],
        } as BuiltObject;
      }
      if (args.id === 'bobj-1') {
        return {
          // This builtObject is NOT vulnerable
          id: 'bobj-1',
          name: 'One Concern Office (id: bobj-1)',
          coordinates: [-122.187, 37.451],
          address: {
            houseNumber: '855',
            street: 'Oak Grove Ave',
            city: 'Menlo Park',
            state: 'California',
            stateCode: 'CA',
            country: 'United States',
            countryCode: 'US',
            postCode: '94025',
            formattedAddress: '855 Oak Grove Ave, Menlo Park, CA 94025',
            addressDetails: {
              accuracy: 'rooftop',
            },
          },
        } as BuiltObject;
      }
      if (args.id === 'whitehouse') {
        return {
          // This builtObject IS vulnerable
          id: 'whitehouse',
          name: 'The White House',
          // Mapbox returns the coordinates of the White House as [-77.036556, 38.8977365]
          // This built Object is about 400 meters from that.
          coordinates: [-77.033, 38.9],
          address: {
            houseNumber: '1600',
            street: 'Pennsylvania Avenue',
            city: 'Washington',
            state: 'District of Columbia',
            stateCode: 'DC',
            postCode: '20500',
            country: 'United States',
            countryCode: 'US',
            formattedAddress: '1600 Pennsylvania Avenue, Washington, DC 20500',
            addressDetails: {
              accuracy: 'rooftop',
            },
          },
          buildingsCount: 1,
        } as BuiltObject;
      }
      throw new GraphQLError(
        `[serverMocks - builtObject] object not found (builtObject with id ${args.id} not found)`,
      );
    },
    historicalEvents: () => {
      // throw new GraphQLError('historicalEvents: graphql error');
      return storedHistoricalEvents;
    },
    search: (root: unknown, args: QuerySearchArgs) => {
      const searchText = args.input.text.toLowerCase();
      return locations
        .filter((loc) => {
          return (
            loc.name.toLowerCase().includes(searchText) ||
            loc.address.formattedAddress.toLowerCase().includes(searchText)
          );
        })
        .slice(0, args.limit);
    },
    searchNearestBuiltObject: (root: unknown, args: QuerySearchNearestBuiltObjectArgs) => {
      const [lon, lat] = args.input.coordinates;
      if (lon > -122.6 && lon < -121.5 && lat > 37 && lat < 38) {
        return {
          id: 'bobj-1', // One Concern office
          coordinates: [-122.187, 37.451],
        } as SearchNearestBuiltObjectQuery['searchNearestBuiltObject'];
      }
      if (lon > -78 && lon < -76 && lat > 38 && lat < 39.5) {
        return {
          // The White House
          id: 'whitehouse',
          coordinates: [-77.033, 38.9],
          // Mapbox returns the coordinates of the White House as [-77.036556, 38.8977365]
          // This built Object is about 400 meters from that.
        } as SearchNearestBuiltObjectQuery['searchNearestBuiltObject'];
      }
      throw new GraphQLError(
        '[serverMocks searchNearestBuiltObject] - ' +
          `no matching builtObject near [${lon}, ${lat}]`,
      );
    },
    sliceIndexes: () => makeMockSlices(),
  }),
  LocationObject: () => {
    return {
      resilienceStats: (root: LocationObject, args: LocationObjectResilienceStatsArgs) => {
        let sliceNums: number[] = [];
        if (args?.slices && args?.slices?.length > 0) {
          sliceNums = args?.slices;
        } else {
          sliceNums = makeMockSlices().map((sliceDef) => sliceDef.slice);
        }
        return makeMockResilienceStats(root.id, sliceNums, chance);
      },
      airports: (root: LocationObject) => makeMockLifelineAssets(root, 8, 'Airport', chance),
      ports: (root: LocationObject) => makeMockLifelineAssets(root, 4, 'Port', chance),
      people: (root: LocationObject) => makeMockLifelineAssets(root, 48, 'Subdivision', chance),
      highways: (root: LocationObject) => makeMockLifelineAssets(root, 40, 'Highway', chance),
      bridges: (root: LocationObject) => makeMockLifelineAssets(root, 34, 'Bridge', chance),
      power: (root: LocationObject) => makeMockLifelineAssets(root, 1, 'Power', chance),
      structural: () => new MockList(1),
    };
  },
  BuiltObject: () => {
    return {
      __resolveType(builtObject: any) {
        if (builtObject.group || builtObject.type) {
          return 'LocationObject';
        }
        // This is default
        return 'BuiltObject';
      },
      resilienceStats: (root: BuiltObject, args: BuiltObjectResilienceStatsArgs) => {
        let sliceNums: number[] = [];

        if (root.id === 'whitehouse') {
          return args?.slices?.length > 1 ? whReturnPeriodStats : whResilienceStats;
        }

        if (args?.slices && args?.slices?.length > 0) {
          sliceNums = args?.slices;
        } else {
          sliceNums = makeMockSlices().map((sliceDef) => sliceDef.slice);
        }
        return makeMockResilienceStats(root.id, sliceNums, chance);
      },
      airports: (root: BuiltObject) =>
        root.id === 'whitehouse' ? whAirports : makeMockLifelineAssets(root, 8, 'Airport', chance),
      ports: (root: BuiltObject) =>
        root.id === 'whitehouse' ? whPorts : makeMockLifelineAssets(root, 4, 'Port', chance),
      people: (root: BuiltObject) =>
        root.id === 'whitehouse'
          ? whPeople
          : makeMockLifelineAssets(root, 48, 'Subdivision', chance),
      highways: (root: BuiltObject) =>
        root.id === 'whitehouse' ? whHighways : makeMockLifelineAssets(root, 40, 'Highway', chance),
      bridges: (root: BuiltObject) =>
        root.id === 'whitehouse' ? whBridges : makeMockLifelineAssets(root, 34, 'Bridge', chance),
      power: (root: BuiltObject) => makeMockLifelineAssets(root, 1, 'Power', chance),
      structural: (root: BuiltObject) =>
        root.id === 'whitehouse' ? whStructural : new MockList(1),
    };
  },
  Airport: (root: LocationObject | BuiltObject) => {
    const distance = 50;
    const builtObjectCoords = point(root.coordinates);
    const coords = transformTranslate(
      builtObjectCoords,
      chance.floating({ min: 0.1, max: 1 }) * distance,
      chance.floating({ min: -180, max: 180 }),
      { units: 'miles' },
    );
    return {
      id: `${root.id}|Airport|${chance.guid()}`,
      actualID: `${root.id}|${chance.ssn()}`,
      name: `Airport-${chance.word()}`,
      coordinates: coords.geometry.coordinates,
      stats: (root: Airport, args: BasicLifelineAssetStatsArgs) => {
        let sliceNums: number[] = [];
        if (args?.slices && args?.slices?.length > 0) {
          sliceNums = args?.slices;
        } else {
          sliceNums = makeMockSlices().map((sliceDef) => sliceDef.slice);
        }
        return makeMockLifelineStats(root.id, sliceNums);
      },
      yearBuilt: (
        chance.date({ year: chance.integer({ min: 1919, max: 2019 }) }) as Date
      ).getFullYear(),
      enplanements: `${chance.integer({ min: 50, max: 1_000_000 })}`,
    };
  },
  Port: (root: LocationObject | BuiltObject) => {
    const distance = 50;
    const builtObjectCoords = point(root.coordinates);
    const coords = transformTranslate(
      builtObjectCoords,
      chance.floating({ min: 0.1, max: 1 }) * distance,
      chance.floating({ min: -180, max: 180 }),
      { units: 'miles' },
    );
    return {
      id: `${root.id}|Port|${chance.guid()}`,
      actualID: `${root.id}|${chance.ssn()}`,
      name: `Port-${chance.word()}`,
      coordinates: coords.geometry.coordinates,
      stats: (root: Port, args: BasicLifelineAssetStatsArgs) => {
        let sliceNums: number[] = [];
        if (args?.slices && args?.slices?.length > 0) {
          sliceNums = args?.slices;
        } else {
          sliceNums = makeMockSlices().map((sliceDef) => sliceDef.slice);
        }
        return makeMockLifelineStats(root.id, sliceNums);
      },
      yearBuilt: (
        chance.date({ year: chance.integer({ min: 1900, max: 2020 }) }) as Date
      ).getFullYear(),
      cranes: `${chance.integer({ min: 10, max: 50 })}`,
      wharves: `${chance.integer({ min: 3, max: 25 })}`,
    };
  },
  Subdivision: (root: LocationObject | BuiltObject) => {
    const distance = 30;
    const builtObjectCoords = point(root.coordinates);
    const coords = transformTranslate(
      builtObjectCoords,
      chance.floating({ min: 0.1, max: 1 }) * distance,
      chance.floating({ min: -180, max: 180 }),
      { units: 'miles' },
    );
    const homes = chance.integer({ min: 10, max: 50 });

    return {
      homes,
      vulnerableHomes: chance.integer({ min: 0, max: homes }),
      id: `${root.id}|Subdivision|${chance.guid()}`,
      actualID: `${root.id}|${chance.ssn()}`,
      metadata: [] as string[][],
      name: `Subdivision-${chance.pickone(['Yolo', 'Tippah', 'Santa Clara', 'San Gabriel'])}`,
      coordinates: coords.geometry.coordinates,
      stats: (root: Subdivision, args: BasicLifelineAssetStatsArgs) => {
        let sliceNums: number[] = [];
        if (args?.slices && args?.slices?.length > 0) {
          sliceNums = args?.slices;
        } else {
          sliceNums = makeMockSlices().map((sliceDef) => sliceDef.slice);
        }
        return makeMockLifelineStats(root.id, sliceNums);
      },
    };
  },
  HighwaySegment: (root: LocationObject | BuiltObject) => {
    const distance = 50;
    const builtObjectCoords = point(root.coordinates);
    const coords = transformTranslate(
      builtObjectCoords,
      chance.floating({ min: 0.1, max: 1 }) * distance,
      chance.floating({ min: -180, max: 180 }),
      { units: 'miles' },
    );
    return {
      id: `${root.id}|Highway|${chance.guid()}`,
      actualID: `${root.id}|${chance.ssn()}`,
      metadata: [] as string[][],
      name: `Highway-${chance.pickone(['I-10', 'US-52', 'I-94', 'I-35W'])}`,
      coordinates: coords.geometry.coordinates,
      stats: (root: HighwaySegment, args: BasicLifelineAssetStatsArgs) => {
        let sliceNums: number[] = [];
        if (args?.slices && args?.slices?.length > 0) {
          sliceNums = args?.slices;
        } else {
          sliceNums = makeMockSlices().map((sliceDef) => sliceDef.slice);
        }
        return makeMockLifelineStats(root.id, sliceNums);
      },
      length: chance.integer({ min: 2, max: 50 }),
    };
  },
  Bridge: (root: LocationObject | BuiltObject) => {
    const distance = 50;
    const builtObjectCoords = point(root.coordinates);
    const coords = transformTranslate(
      builtObjectCoords,
      chance.floating({ min: 0.1, max: 1 }) * distance,
      chance.floating({ min: -180, max: 180 }),
      { units: 'miles' },
    );
    return {
      id: `${root.id}|Bridge|${chance.guid()}`,
      actualID: `${root.id}|${chance.ssn()}`,
      name: `Bridge-${chance.word()}`,
      coordinates: coords.geometry.coordinates,
      stats: (root: Bridge, args: BasicLifelineAssetStatsArgs) => {
        let sliceNums: number[] = [];
        if (args?.slices && args?.slices?.length > 0) {
          sliceNums = args?.slices;
        } else {
          sliceNums = makeMockSlices().map((sliceDef) => sliceDef.slice);
        }
        return makeMockLifelineStats(root.id, sliceNums);
      },
      yearBuilt: (
        chance.date({ year: chance.integer({ min: 1900, max: 2020 }) }) as Date
      ).getFullYear(),
      material: `${chance.pickone([
        'Steel',
        'Concrete',
        'Lumber',
        'Asphalt',
        'Iron',
        'Aluminum',
        'Plastic',
      ])}`,
      type: `${chance.pickone([
        'Arch',
        'Beam',
        'Cantilever',
        'Suspension',
        'Cable-stayed',
        'Tied-arch',
        'Truss',
      ])}`,
    };
  },
  Substation: (root: LocationObject | BuiltObject) => {
    const distance = 50;
    const builtObjectCoords = point(root.coordinates);
    const coords = transformTranslate(
      builtObjectCoords,
      chance.floating({ min: 0.1, max: 1 }) * distance,
      chance.floating({ min: -180, max: 180 }),
      { units: 'miles' },
    );
    return {
      id: `${root.id}|Power|${chance.guid()}`,
      actualID: `${root.id}|${chance.ssn()}`,
      metadata: [['maxVoltage', `${chance.integer({ min: 0, max: 3000 })}`]],
      name: `Power-${chance.word()}}`,
      coordinates: coords.geometry.coordinates,
      stats: (root: Substation, args: BasicLifelineAssetStatsArgs) => {
        let sliceNums: number[] = [];
        if (args?.slices && args?.slices?.length > 0) {
          sliceNums = args?.slices;
        } else {
          sliceNums = makeMockSlices().map((sliceDef) => sliceDef.slice);
        }
        return makeMockLifelineStats(root.id, sliceNums);
      },
    };
  },
  Building: (root: LocationObject | BuiltObject) => {
    return {
      id: `${root.id}|Building|1`,
      actualID: root.id,
      name: 'Building-1',
      builtObjectCoords: point(root.coordinates),
      stats: (root: Building, args: BasicLifelineAssetStatsArgs) => {
        let sliceNums: number[] = [];
        if (args?.slices && args?.slices?.length > 0) {
          sliceNums = args?.slices;
        } else {
          sliceNums = makeMockSlices().map((sliceDef) => sliceDef.slice);
        }
        return makeMockLifelineStats(root.id, sliceNums);
      },
      yearBuilt: (
        chance.date({ year: chance.integer({ min: 1900, max: 2020 }) }) as Date
      ).getFullYear(),
      constructionType: chance.pickone([
        'Wood frame',
        'Steel frame',
        'Concrete frame',
        'Tilt-up concrete',
        'Concrete shear wall',
        'Unreinforced masonry',
        'Reinforced masonry',
        'Unknown',
      ]),
      stories: `${chance.integer({ min: 1, max: 50 })}`,
    };
  },
  Address: () => ({
    country: MOCK_JAPAN ? 'Japan' : 'United States',
    countryCode: MOCK_JAPAN ? 'JP' : 'US',
    formattedAddress: () =>
      `${chance.address()}, ${chance.city()}, ${chance.state()} ${chance.zip()}`,
  }),

  ContactInfo: () => ({ email: () => chance.email(), phoneNumber: () => chance.phone() }),
  Time: () => chance.date({ year: currentYear }),
  User: () => ({
    id: 'fakeuser-123',
    title: () => chance.profession(),
    personalInfo: () => ({
      firstName: () => chance.first(),
      lastName: () => chance.last(),
      contact: () => ({
        email: () => chance.email(),
        phoneNumber: () => chance.phone(),
      }),
    }),
    preferences: () => (MOCK_JAPAN ? prefsMetricJaJP : prefsImperialEnUS),
    productSettings: () => ({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      thresholds: (_parent: unknown, _args: unknown, _context: unknown, info: unknown) => {
        // uncomment this if wanting to test failure of GetThresholds AFTER the app starts up.
        // if (info.operation.name.value === 'GetThresholds') {
        //   throw new GraphQLError('error from GetThresholds query');
        // }
        return storedThresholds;
      },
    }),
  }),
  Entitlements: () => ({
    id: 1,
    scopes: [Product.Domino],
    enabledProducts: [Product.Domino],
  }),

  Stats: () => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    __resolveType(stats: any) {
      if (stats.recoveryCurve) {
        return 'AggregateStats';
      }
      // This is default
      return 'Stats';
    },
  }),
  AggregateStats: () => ({
    mean: chance.integer({ min: 0, max: 90 }),
    stddev: chance.integer({ min: 0, max: 10 }),
  }),
  Coordinates: () => [
    chance.floating({ min: -180, max: 180 }),
    chance.floating({ min: -90, max: 90 }),
  ],

  LocationFile: () => {
    const totalRows = chance.integer({ min: 0, max: 10_000 });
    const totalRejected = chance.pickone([0, chance.integer({ min: 1, max: 20 })]);

    return {
      totalRows,
      totalRejected,
      totalAccepted: totalRows - totalRejected,
      uploadedAt: new Date().toISOString(),
      name: `${chance.string({
        alpha: true,
        numeric: true,
        length: chance.integer({ min: 10, max: 30 }),
      })}.csv`,
    };
  },

  Mutation: () => ({
    updateThresholds: (_: unknown, { thresholds }: UpdateThresholdsMutationVariables) => {
      merge(storedThresholds, thresholds);
      return {
        id: 'fakeuser-123',
        productSettings: {
          thresholds: storedThresholds,
        },
      };
    },
  }),
};
