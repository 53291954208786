import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import { Divider, Theme, Toolbar } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {
  DEMO_ACCOUNT_SUFFIX,
  HISTORICAL_EVENTS_MENU_VISIBLE,
} from '../../../../util/productGlobals';
import DominoLogo from '../../../../../assets/images/domino.svg';
import HazardSelector from './HazardSelector';
import LocationsSearch from '../../Search/LocationsSearch';
import SubscriberBlockDialog from '../../Search/SubscriberBlock';
import useHasFinanceEntitlement from '../../../../Hooks/useHasFinanceEntitlement';
import { renderLocationsSearchInputField } from '../../Search/locationsSearchHelpers';
import ContactUs from '../../Filters/ContactUs';
import { NavigatorViewType, useGetNavigatorInfoQuery } from '../../../../__generated__/graphql';

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    height: '42px',
  },
  divider: {
    marginRight: theme.spacing(8),
  },
}));

type PrimaryNavProps = {
  userId: string;
};

const PrimaryNav: React.FC<PrimaryNavProps> = ({ userId }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { pathname }: { pathname: string } = useLocation();
  const isLocationMatch = React.useMemo(
    () => pathname.includes('/locations/') && !pathname.includes('/locations/list'),
    [pathname],
  );
  const isDemoUser = userId?.endsWith(DEMO_ACCOUNT_SUFFIX);
  const [isBlockedModalOpen, setIsBlockedModalOpen] = React.useState<boolean>(false);
  const isFinanceDetailView = React.useMemo(
    () => pathname.includes('/finance/analysis/detail/'),
    [pathname],
  );
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;

  const {
    data: {
      navigatorInfo: { currentView },
    },
  } = useGetNavigatorInfoQuery();
  const is3DView =
    currentView === NavigatorViewType.TopConcernsView ||
    currentView === NavigatorViewType.FirstToFailView;

  const showHazardSelector =
    (is3DView || !isFinanceEntitled) &&
    !isLocationMatch &&
    (!isFinanceEntitled || isFinanceDetailView);
  return (
    <Toolbar data-test-id="primary-nav">
      <img src={DominoLogo} alt={t('dominoLogo')} className={classes.logo} />
      {isDemoUser && <ContactUs userId={userId} />}
      <Divider orientation="vertical" />
      {showHazardSelector && (
        <>
          <HazardSelector historicalEventsMenuVisible={HISTORICAL_EVENTS_MENU_VISIBLE} />
          <Divider orientation="vertical" className={classes.divider} />
        </>
      )}
      {isDemoUser && (
        <div onClick={() => setIsBlockedModalOpen(true)}>
          <Autocomplete
            id="location-autocomplete-search-skeleton"
            style={{ width: isDemoUser ? 270 : 340 }}
            size="small"
            freeSolo
            autoComplete
            autoHighlight={false} // so first option is NOT automatically highlighted
            clearOnEscape
            clearOnBlur
            blurOnSelect
            renderInput={renderLocationsSearchInputField}
            options={[]}
          />
        </div>
      )}
      <SubscriberBlockDialog
        userId={userId}
        open={isBlockedModalOpen}
        onClose={() => setIsBlockedModalOpen(false)}
      />
      {!isDemoUser && <LocationsSearch userId={userId} />}
    </Toolbar>
  );
};
PrimaryNav.displayName = 'PrimaryNav';
export default PrimaryNav;
