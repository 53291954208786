import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import {
  NavigatorViewType,
  PlayState,
  SliceHazardType,
  useGetPlayStateQuery,
  useGetSelectedHazardQuery,
} from '../../../__generated__/graphql';
import ClimateToggle from './Climate/ClimateToggle';
import HistoricalSeverity from './Climate/HistoricalSeverity';
import LocationsPicker from './LocationsPicker/LocationsPicker';
import PlanningPeriodPicker from './PlanningPeriodPicker/PlanningPeriodPicker';
import ThresholdsPickerContainer from './ThresholdsPicker/ThresholdsPickerContainer';
import useHasFinanceEntitlement from '../../../Hooks/useHasFinanceEntitlement';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 3,
    margin: theme.spacing(2),
    top: '72px',
  },
  rootMaterial: {
    display: 'flex',
    alignItems: 'center',
  },
}));

type FiltersBarProps = {
  currentNavigatorView: NavigatorViewType;
  userId: string;
};

const FiltersBarFC: React.FC<FiltersBarProps> = ({ currentNavigatorView, userId }) => {
  const { root, rootMaterial } = useStyles();
  const {
    data: {
      planView: {
        planFilters: {
          hazardType,
          historicalId,
          isHistoricalSeverityWorse,
          isClimateChangeEnabled,
        },
      },
    },
  } = useGetSelectedHazardQuery();
  const {
    data: {
      firstToFailView: { playState },
    },
  } = useGetPlayStateQuery();
  const { pathname }: { pathname: string } = useLocation();
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;
  const isFinanceDetailView = pathname.includes('/finance/analysis/detail/');

  const disableClimateChangeToggle =
    (currentNavigatorView === NavigatorViewType.FirstToFailView &&
      playState === PlayState.Playing) ||
    (hazardType === SliceHazardType.Earthquake &&
      (!isFinanceDetailView ||
        !isFinanceEntitled ||
        currentNavigatorView !== NavigatorViewType.MapView));

  const isSYW = pathname.includes('/finance/analysis/see-the-math/');
  const divStyle =
    currentNavigatorView !== NavigatorViewType.Materiality && !isSYW ? root : rootMaterial;

  const is3DView =
    currentNavigatorView === NavigatorViewType.TopConcernsView ||
    currentNavigatorView === NavigatorViewType.FirstToFailView;

  return (
    <div className={divStyle} data-test-id="filters-bar">
      {historicalId ? (
        <HistoricalSeverity isHistoricalSeverityWorse={isHistoricalSeverityWorse} />
      ) : (
        (!isFinanceEntitled || isFinanceDetailView || isSYW) && (
          <ClimateToggle
            isClimateSwitchOn={
              (isFinanceDetailView || hazardType !== SliceHazardType.Earthquake) &&
              isClimateChangeEnabled
            }
            disabled={disableClimateChangeToggle}
          />
        )
      )}
      {currentNavigatorView !== NavigatorViewType.FirstToFailView &&
        currentNavigatorView !== NavigatorViewType.Materiality && (
          <>
            {(!isFinanceEntitled || is3DView || isSYW) && (
              <ThresholdsPickerContainer userId={userId} />
            )}
            {!historicalId && (!isFinanceEntitled || is3DView || isSYW) && (
              <PlanningPeriodPicker userId={userId} />
            )}
            {!isFinanceEntitled && currentNavigatorView !== NavigatorViewType.TopConcernsView && (
              <LocationsPicker />
            )}
          </>
        )}
      {currentNavigatorView === NavigatorViewType.Materiality && (
        <PlanningPeriodPicker userId={userId} />
      )}
    </div>
  );
};
const FiltersBar = React.memo(FiltersBarFC);
FiltersBar.displayName = 'FiltersBar';
export default FiltersBar;
