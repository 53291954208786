import * as React from 'react';
import { ApolloError } from '@apollo/client';

import { calcVulScoreFromResStats, sortVulnerableLocs } from '../util/vulnerability';
import {
  filterScoredLocationsByGroupType,
  LocationType,
  LocationWithScore,
} from '../Components/ProductComponents/locationViewHelpers';
import {
  GetLocationsWithFilterQuery,
  ResilienceThresholds,
  useGetLocationsFilterQuery,
  useGetThresholdsQuery,
} from '../__generated__/graphql';
import useLifelineEntitlements, { LifelineEntitlementsType } from './useLifelineEntitlements';
import { App } from '../PlanningApp/AppConfig';

export const removeMatchingLocation: (
  locations: LocationWithScore[],
  findLocationId: string,
) => { removedLocation?: LocationWithScore; newLocations: LocationWithScore[] } = (
  locations,
  findLocationId,
) => {
  if ((!findLocationId && +findLocationId !== 0) || !locations || locations.length === 0) {
    return { removedLocation: null, newLocations: locations };
  }
  const idx = locations.findIndex((loc) => loc.id === findLocationId);
  if (idx === -1) {
    return { removedLocation: null, newLocations: locations };
  }

  return {
    removedLocation: locations[idx],
    newLocations: [...locations.slice(0, idx), ...locations.slice(idx + 1)],
  };
};

export const recalcLocationsScores: (
  allLocations: GetLocationsWithFilterQuery['locations'],
  thresholds: ResilienceThresholds,
  entitlements: LifelineEntitlementsType,
  debugName?: string,
) => LocationWithScore[] = (allLocations, thresholds, entitlements, debugName) => {
  App.debug(
    `[recalcLocationsScores (debugName: ${debugName})] - Recalculating ALL location scores `,
    `for ${allLocations?.length} #locations`,
  );
  if (!allLocations || !thresholds) {
    return null;
  }

  const locsWithScores = sortVulnerableLocs(
    allLocations.map((loc) => {
      return {
        ...loc,
        vulnerabilityScore: calcVulScoreFromResStats(
          loc.resilienceStats[0],
          thresholds,
          entitlements,
        ),
      };
    }),
  );
  return locsWithScores;
};

/* ****  useCalcLocsVulnerabilityScores - Query for user thresholds and use them to 
   calculate the vulnerability score for each location */
export function useCalcLocsVulnerabilityScores(
  locations: LocationType[],
  debugName?: string,
): { locsWithScores: LocationWithScore[]; loading?: boolean; error?: ApolloError | Error } {
  const { data: userData, loading: userLoading, error: userError } = useGetThresholdsQuery();
  const thresholds: ResilienceThresholds = userData?.user?.productSettings?.thresholds;
  const entitlements = useLifelineEntitlements();

  const locsWithScores = React.useMemo(() => {
    if (userData && entitlements) {
      return recalcLocationsScores(locations, thresholds, entitlements, debugName);
    }
    return null;
  }, [locations, thresholds, debugName, userData, entitlements]);

  if (userError) {
    App.error('[useCalcLocationsVulnerability] - GetCurrentUserQuery error: ', userError);
    return { loading: false, locsWithScores: null, error: userError };
  }
  if (userLoading || !userData || !entitlements) {
    return { loading: true, locsWithScores: null, error: null };
  }

  if (!locations) {
    return {
      error: new Error(
        '[useCalcLocsVulnerabilityScores] - cannot calculate vulnerability score of null locations list',
      ),
      loading: false,
      locsWithScores: null,
    };
  }
  return { locsWithScores, loading: false, error: null };
}

/* ***  useFilterLocations - filter the locsWithScores list so that all the locations match 
   the locFilter stored in the cache. If the activeLocationId is provided as a parameter, 
   that location will also be filtered out of the resultant filteredLocs */
type UseFilterLocationsResult = {
  filteredLocs: LocationWithScore[];
  activeLocation: LocationWithScore;
};
export function useFilterLocations(
  locsWithScores: LocationWithScore[],
  activeLocationId?: string,
): UseFilterLocationsResult {
  const {
    data: {
      planView: { locFilter: locGroupFilter },
    },
  } = useGetLocationsFilterQuery();

  if (!locsWithScores) {
    return { filteredLocs: null, activeLocation: null };
  }

  let allLocsExceptActive: LocationWithScore[] = locsWithScores;
  let activeLoc: LocationWithScore;

  if (activeLocationId) {
    // Remove the active location (detail view) if there is one, so it won't get clustered.
    const res = removeMatchingLocation(locsWithScores, activeLocationId);
    activeLoc = res.removedLocation;
    allLocsExceptActive = res.newLocations;
  }
  const filteredLocations = filterScoredLocationsByGroupType(allLocsExceptActive, locGroupFilter);
  return {
    activeLocation: activeLoc ?? null,
    filteredLocs: filteredLocations,
  };
}

/* ***  useCalcAndFilterLocs  */
type UseCalcAndFilterLocsResult = {
  locations: LocationWithScore[];
  loading: boolean;
  error?: Error;
};

function useCalcAndFilterLocs(
  locations: LocationType[],
  // Id of a location (usually the active location) that we want to remove from locations result
  removeLocationId?: string,
  debug?: string,
): UseCalcAndFilterLocsResult {
  const {
    locsWithScores,
    error: calcError,
    loading: calcLoading,
  } = useCalcLocsVulnerabilityScores(locations, `useCalcAndFilterLocs:${debug}`);

  const { filteredLocs } = useFilterLocations(locsWithScores, removeLocationId);
  React.useDebugValue({
    numFilteredLocs: filteredLocs?.length,
    calcError,
    calcLoading,
  });

  if (calcError) {
    return { error: calcError, loading: false, locations: null };
  }
  if (calcLoading) {
    return { loading: true, error: null, locations: null };
  }
  return { locations: filteredLocs, loading: false, error: null };
}

export default useCalcAndFilterLocs;
