import { ApolloCache, NormalizedCacheObject, Resolver } from '@apollo/client';
import { cloneDeep } from 'lodash/fp';

import {
  GetNavigatorInfoDocument,
  GetNavigatorInfoQuery,
  MutationUpdateNavigatorInfoArgs,
  NavigatorViewType,
} from '../../../__generated__/graphql';

export const updateNavigatorInfo: Resolver = (
  _: unknown,
  { currentView, currentLifeline }: MutationUpdateNavigatorInfoArgs,
  { cache }: { cache: ApolloCache<NormalizedCacheObject> },
) => {
  const storeData = cache.readQuery<GetNavigatorInfoQuery>({ query: GetNavigatorInfoDocument });
  const newData = cloneDeep(storeData);
  if (newData.navigatorInfo.currentView === NavigatorViewType.MapView)
    newData.navigatorInfo.userEngagedTimes += 1;
  newData.navigatorInfo.currentView = currentView;
  newData.navigatorInfo.currentLifeline = currentLifeline;

  cache.writeQuery({ data: newData, query: GetNavigatorInfoDocument });
  return newData.navigatorInfo;
};

export const navigatorInfoMutations = {
  updateNavigatorInfo,
};
