import * as React from 'react';

import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReplayIcon from '@mui/icons-material/Replay';

import {
  PlayState,
  useGetFirstToFailQuery,
  useUpdatePlayStateMutation,
} from '../../../__generated__/graphql';
import { RETURN_PERIOD_YEAR_OPTIONS } from '../../../util/productGlobals';

const useStyles = makeStyles({
  button: {
    backgroundColor: '#F29220 !important',
    width: 60,
    height: 60,
    borderRadius: 50,
  },
  icon: {
    color: '#F8F7F2',
  },
});
const PLAYBACK_SPPED = 2000;

const FirstToFailPlayButtonFC: React.FC = () => {
  const { button, icon } = useStyles();
  const {
    data: {
      firstToFailView: { playState, returnPeriodIndex },
    },
  } = useGetFirstToFailQuery();
  const [updatePlayState] = useUpdatePlayStateMutation();

  const [currentReturnPeriod, setCurrentReturnPeriod] = React.useState<number>(returnPeriodIndex);

  const changePlayState = React.useCallback(() => {
    const newState = playState === PlayState.Playing ? PlayState.Paused : PlayState.Playing;
    updatePlayState({
      variables: {
        playState: newState,
        returnPeriodIndex:
          newState === PlayState.Playing
            ? (currentReturnPeriod + 1) % (RETURN_PERIOD_YEAR_OPTIONS.length + 1)
            : currentReturnPeriod,
      },
    });
  }, [currentReturnPeriod, playState, updatePlayState]);

  React.useEffect(() => {
    let interval: NodeJS.Timeout;
    if (playState === PlayState.Playing) {
      interval = setInterval(() => {
        const newIndex = (currentReturnPeriod + 1) % (RETURN_PERIOD_YEAR_OPTIONS.length + 1);
        if (newIndex === RETURN_PERIOD_YEAR_OPTIONS.length) {
          updatePlayState({
            variables: {
              playState: PlayState.Paused,
              returnPeriodIndex: newIndex,
            },
          });
        } else {
          updatePlayState({
            variables: {
              returnPeriodIndex: newIndex,
            },
          });
        }
        setCurrentReturnPeriod(newIndex);
      }, PLAYBACK_SPPED);
    }
    return () => clearInterval(interval);
  }, [currentReturnPeriod, playState, returnPeriodIndex, updatePlayState]);

  React.useEffect(() => {
    setCurrentReturnPeriod(returnPeriodIndex);
  }, [returnPeriodIndex]);

  return (
    <IconButton onClick={changePlayState} className={button} data-test-id="PlayButton">
      {playState === PlayState.Paused &&
        currentReturnPeriod === RETURN_PERIOD_YEAR_OPTIONS.length && (
          <ReplayIcon fontSize="large" data-test-id="RewindIcon" className={icon} />
        )}
      {(playState === PlayState.Initial ||
        (playState === PlayState.Paused &&
          currentReturnPeriod !== RETURN_PERIOD_YEAR_OPTIONS.length)) && (
        <PlayArrowIcon fontSize="large" data-test-id="PlayIcon" id="PlayIcon" className={icon} />
      )}
      {playState === PlayState.Playing && (
        <PauseIcon fontSize="large" data-test-id="PauseIcon" id="PauseIcon" className={icon} />
      )}
    </IconButton>
  );
};

const FirstToFailPlayButton = React.memo(FirstToFailPlayButtonFC);
FirstToFailPlayButton.displayName = 'FirstToFailPlayButton';
export default FirstToFailPlayButton;
