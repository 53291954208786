import * as React from 'react';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

interface CardExpandButtonFCProps {
  onClick: () => void;
  expanded: boolean;
}

const useStyles = makeStyles(() => ({
  button: {
    minWidth: 32,
    padding: 0,
  },
  startIcon: {
    margin: '0px !important',
    transform: (props: { expanded: boolean }) => (props.expanded ? 'rotate(90deg)' : 'none'),
    '& svg': {
      fontSize: '0.875rem !important',
    },
  },
}));

const CardExpandButtonFC: React.FC<CardExpandButtonFCProps> = ({ onClick, expanded }) => {
  const classes = useStyles({
    expanded,
  });

  return (
    <Button
      aria-label="CardExpandButton"
      className={`u-clickable ${classes.button}`}
      startIcon={<ArrowForwardIosSharpIcon fontSize="small" />}
      size="small"
      classes={{
        startIcon: classes.startIcon,
      }}
      onClick={onClick}
    />
  );
};

const CardExpandButton = React.memo(CardExpandButtonFC);
export default CardExpandButton;
