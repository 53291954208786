import * as React from 'react';

import {
  Card,
  Grid,
  IconButton,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link as RouterLink } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApolloError } from '@apollo/client';
import { useSnackbar } from 'notistack';
import {
  DeleteSimulationMutation,
  GetSimulationsDocument,
  GetSimulationsQuery,
  GetUserCompaniesQuery,
  SimulationStatus,
  useDeleteSimulationMutation,
  useGetSimulationsQuery,
} from '../../../__generated__/graphql';
import { App } from '../../../PlanningApp/AppConfig';
import useApiErrSnackbar from '../../CommonComponents/Snackbar/useApiErrSnackbar';
import StyledSnackbarContent from '../../CommonComponents/Snackbar/StyledSnackbarContent';
import { RED, WHITE } from '../../../util/productGlobals';
import useHasFinanceEntitlement from '../../../Hooks/useHasFinanceEntitlement';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    marginTop: 16,
  },
  table: {
    width: '100%',
    '& .MuiTableCell-root': {
      border: '1px solid #979794',
      padding: '8px',
      textAlign: 'center',
      ...theme.typography.body2,
    },
    '& .MuiTableCell-head': {
      textAlign: 'center',
      fontWeight: 800,
      ...theme.typography.body1,
    },
  },
}));

type FinanceTableProps = {
  companies: GetUserCompaniesQuery['getUserPortfolio'];
};

const FinanceTableCell = ({ amount, threshold }: { amount?: number; threshold: number }) => (
  <TableCell width={150} sx={{ color: amount >= threshold ? RED : WHITE }}>
    {amount ? Math.round(amount) : '-'}
  </TableCell>
);

const FinanceTableHead = ({
  loading,
  isSimulationEnabled,
  simulations,
  handleDeleteSimulation,
}: {
  loading: boolean;
  isSimulationEnabled: boolean;
  simulations: GetSimulationsQuery['getSimulations'];
  handleDeleteSimulation: (simulationId: string) => void;
}) => (
  <TableHead>
    <TableRow>
      <TableCell rowSpan={2}>Company</TableCell>
      <TableCell rowSpan={2}>Sector</TableCell>
      <TableCell rowSpan={2}>BI / Uninsured Direct</TableCell>
      <TableCell rowSpan={2}>Present Value of BI Loss / Market Cap (%)</TableCell>
      <TableCell rowSpan={2}>Percentile Relative to Sector</TableCell>
      <TableCell rowSpan={2}>Percentile Relative to S&P 500</TableCell>
      {isSimulationEnabled && (
        <>
          {loading
            ? Array.from({ length: 3 }).map((_, index) => {
                const key = `${index}_loading1}`;
                return (
                  <TableCell key={key} colSpan={2}>
                    <Skeleton />
                  </TableCell>
                );
              })
            : simulations.map((simulation) => (
                <TableCell key={simulation.id} colSpan={2}>
                  <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Typography mr={1}>{simulation.name}</Typography>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      id={`DeleteSimulation-${simulation.id}`}
                      onClick={() => handleDeleteSimulation(simulation.id)}
                      data-test-id={`DeleteSimulation-${simulation.id}`}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </TableCell>
              ))}
        </>
      )}
    </TableRow>
    {isSimulationEnabled && (
      <TableRow data-test-id="simulation-subrow">
        {loading
          ? Array.from({ length: 3 }).map((_, index) => {
              const key = `${index}_loading2}`;
              return (
                <React.Fragment key={key}>
                  <TableCell>Percentile Relative to Sector</TableCell>
                  <TableCell>Percentile Relative to S&P 500</TableCell>
                </React.Fragment>
              );
            })
          : simulations.map((simulation) => (
              <React.Fragment key={simulation.id}>
                <TableCell>Percentile Relative to Sector</TableCell>
                <TableCell>Percentile Relative to S&P 500</TableCell>
              </React.Fragment>
            ))}
      </TableRow>
    )}
  </TableHead>
);

const FinanceTable: React.FC<FinanceTableProps> = ({ companies }) => {
  const { container, table } = useStyles();
  const [pollInterval, setPollInterval] = React.useState(0);
  const { data, loading, error } = useGetSimulationsQuery({ pollInterval });

  const { enqueueApiErrSnackbar } = useApiErrSnackbar();
  const { enqueueSnackbar } = useSnackbar();

  const { data: financeModules } = useHasFinanceEntitlement();
  const isSimulationEnabled = financeModules?.enablesimulations ?? false;

  const [deleteSimulation] = useDeleteSimulationMutation({
    refetchQueries: [{ query: GetSimulationsDocument }],
    awaitRefetchQueries: true,
    onError: (err: ApolloError) => {
      App.error('[Finance Table] - Delete Simulation ', err.message);
      enqueueApiErrSnackbar();
    },
    onCompleted: (data: DeleteSimulationMutation) => {
      App.debug('[Finance Table] - Delete Simulation successful ', data.deleteSimulation);
      enqueueSnackbar(<StyledSnackbarContent title="Simulation has been successfully deleted." />, {
        variant: 'success',
      });
    },
  });

  const handleDeleteSimulation = React.useCallback(
    (id: string) => {
      deleteSimulation({ variables: { id } });
    },
    [deleteSimulation],
  );

  const simulations = React.useMemo(() => data?.getSimulations ?? [], [data?.getSimulations]);

  React.useEffect(() => {
    if (
      simulations.every((simulation) =>
        [SimulationStatus.Completed, SimulationStatus.Error].includes(simulation.status),
      )
    ) {
      setPollInterval(0);
    } else {
      setPollInterval(3000);
    }
  }, [simulations]);

  if (error) {
    return null;
  }

  return (
    <Card className={container}>
      <TableContainer data-test-id="company-finance-table">
        <Table className={table}>
          <FinanceTableHead
            loading={loading}
            isSimulationEnabled={isSimulationEnabled}
            simulations={simulations}
            handleDeleteSimulation={handleDeleteSimulation}
          />
          <TableBody>
            {companies?.map(({ id, name, meta }) => {
              const directPvOverMktcapPercent = meta?.directPvOverMktcapPercent ?? 0;
              return (
                <TableRow role="row" tabIndex={-1} key={id} data-test-id={`company-${id}`}>
                  <TableCell sx={{ minWidth: 150 }}>
                    <Link component={RouterLink} to={`/finance/analysis/company/${id}`}>
                      {name}
                    </Link>
                  </TableCell>
                  <TableCell>{meta?.gicsSector}</TableCell>
                  <TableCell width={150}>
                    {directPvOverMktcapPercent > 0
                      ? ((meta?.pvOverMktcapPercent ?? 0) / directPvOverMktcapPercent).toFixed(1)
                      : '-'}
                  </TableCell>
                  <TableCell width={150}>{meta?.pvOverMktcapPercent?.toFixed(3)}</TableCell>
                  <TableCell width={150}>
                    {Math.round(meta?.pvOverMktcapRankInSectorPercentile)}
                  </TableCell>
                  <TableCell width={150}>{Math.round(meta?.pvOverMktcapRankPercentile)}</TableCell>
                  {isSimulationEnabled && (
                    <>
                      {loading
                        ? Array.from({ length: 3 }).map((_, index) => {
                            const key = `${index}-loading3`;
                            return (
                              <React.Fragment key={key}>
                                <TableCell>
                                  <Skeleton />
                                </TableCell>
                                <TableCell>
                                  <Skeleton />
                                </TableCell>
                              </React.Fragment>
                            );
                          })
                        : simulations?.map((simulation) =>
                            /* eslint-disable-next-line no-nested-ternary */
                            simulation.status === SimulationStatus.Completed ? (
                              <React.Fragment key={simulation.id}>
                                <FinanceTableCell
                                  amount={
                                    simulation.result.find((result) => result.companyId === id)
                                      ?.pvOverMktcapRankInSectorPercentile
                                  }
                                  threshold={90}
                                />
                                <FinanceTableCell
                                  amount={
                                    simulation.result.find((result) => result.companyId === id)
                                      ?.pvOverMktcapRankPercentile
                                  }
                                  threshold={80}
                                />
                              </React.Fragment>
                            ) : simulation.status === SimulationStatus.Error ? (
                              <React.Fragment key={simulation.id}>
                                <TableCell>n/a</TableCell>
                                <TableCell>n/a</TableCell>
                              </React.Fragment>
                            ) : (
                              <React.Fragment key={simulation.id}>
                                <TableCell>
                                  <Skeleton />
                                </TableCell>
                                <TableCell>
                                  <Skeleton />
                                </TableCell>
                              </React.Fragment>
                            ),
                          )}
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default FinanceTable;
