import { ResilienceStats } from '../../__generated__/graphql';

function makeRecoveryCurve(chance: Chance.Chance): number[][] {
  const curve: number[][] = [[chance.integer({ min: 1, max: 30 }), 0]];
  let x = curve[0][0];
  let y = 0;

  while (y < 100) {
    x += chance.floating({ min: 1, max: 30 });
    y += chance.floating({ min: 1, max: 30 });

    // max 10 values
    if (y > 100 || curve.length === 9) y = 100;
    curve.push([x, y]);
  }

  return curve;
}

const makeMockResilienceStats = (
  locationId: string,
  sliceNums: Array<number>,
  chance: Chance.Chance,
): ResilienceStats[] => {
  const stats: ResilienceStats[] = [];
  sliceNums.forEach((sliceIdx) => {
    const statsId = `${locationId}-${sliceIdx}`;

    // This is good for creating good random data
    // const getNumber: () => number = (pair = { mean: sliceIdx - 120, dev: 10 }) => {
    //   const { mean, dev } = pair;
    //   return Math.max(0, Math.floor(chance.normal({ mean, dev })));
    // };

    /// Need to improve better but this can be fixed data for multi-queries of resilienceStats
    const getNumber: (id: string) => number = (id) => {
      const ret =
        id
          .split('')
          .map((x, index) => x.charCodeAt(0) * (index + 1))
          .reduce((a, b) => a + b, 0) % 100;
      return ret >= 30 ? 0 : ret;
    };

    const getStddevNumber: (id: string) => number = (id) => {
      const ret =
        id
          .split('')
          .map((x, index) => x.charCodeAt(0) * (index + 1))
          .reduce((a, b) => a + b, 0) % 50;
      return ret >= 5 ? 1 : ret;
    };

    stats.push({
      id: statsId,
      slice: sliceIdx,
      structuralDamage: {
        id: `${statsId}-Structure`,
        mean: getNumber(`${statsId}-Structure`),
        stddev: getStddevNumber(`${statsId}-Structure`),
        __typename: 'AggregateStats',
      },
      nearbyPower: {
        id: `${statsId}-Power`,
        mean: getNumber(`${statsId}-Power`),
        stddev: getStddevNumber(`${statsId}-Power`),
        recoveryCurve: makeRecoveryCurve(chance),
        __typename: 'AggregateStats',
      },
      nearbyPeople: {
        id: `${statsId}-People`,
        mean: getNumber(`${statsId}-People`),
        stddev: getStddevNumber(`${statsId}-People`),
        recoveryCurve: makeRecoveryCurve(chance),
        __typename: 'AggregateStats',
      },
      nearbyHighway: {
        id: `${statsId}-Highway`,
        mean: getNumber(`${statsId}-Highway`),
        stddev: getStddevNumber(`${statsId}-Highway`),
        recoveryCurve: makeRecoveryCurve(chance),
        __typename: 'AggregateStats',
      },
      nearbyBridge: {
        id: `${statsId}-Bridge`,
        mean: 0, /// Set bridge vulnerability to 0 for now
        stddev: getStddevNumber(`${statsId}-Bridge`),
        recoveryCurve: makeRecoveryCurve(chance),
        __typename: 'AggregateStats',
      },
      nearbyPort: {
        id: `${statsId}-Port`,
        mean: getNumber(`${statsId}-Port`),
        stddev: getStddevNumber(`${statsId}-Port`),
        recoveryCurve: makeRecoveryCurve(chance),
        __typename: 'AggregateStats',
      },
      nearbyAirport: {
        id: `${statsId}-Airport`,
        mean: getNumber(`${statsId}-Airport`),
        stddev: getStddevNumber(`${statsId}-Airport`),
        recoveryCurve: makeRecoveryCurve(chance),
        __typename: 'AggregateStats',
      },
      nearbyIngressEgress: {
        id: `${statsId}-IngressEgress`,
        mean: getNumber(`${statsId}-IngressEgress`),
        stddev: getStddevNumber(`${statsId}-IngressEgress`),
        recoveryCurve: makeRecoveryCurve(chance),
        __typename: 'AggregateStats',
      },
      nearbyIntegrated: {
        id: `${statsId}-Integrated`,
        mean: getNumber(`${statsId}-Integrated`),
        stddev: getStddevNumber(`${statsId}-Integrated`),
        recoveryCurve: makeRecoveryCurve(chance),
        __typename: 'AggregateStats',
      },
      nearbyRepairTime: {
        id: `${statsId}-RepairTime`,
        mean: getNumber(`${statsId}-RepairTime`),
        stddev: getStddevNumber(`${statsId}-RepairTime`),
        recoveryCurve: makeRecoveryCurve(chance),
        __typename: 'AggregateStats',
      },
    });
  });
  return stats;
};

export default makeMockResilienceStats;
