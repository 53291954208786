import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';
import Icon from '../Icon/Icon';

type CalloutProps = React.HTMLAttributes<HTMLElement> & {
  type?: 'info' | 'success' | 'warning' | 'error';
  variant?: 'primary' | 'outlined' | 'quiet' | 'inline';
  iconOverride?: React.ReactNode;
  centerVertically?: boolean;
  text?: string;
  dataTestId?: string;
};

const COLOR_MAP = {
  info: 'blue',
  success: 'green',
  warning: 'orange',
  error: 'red',
};

const useStyles = makeStyles(({ spacing, palette, typography }: Theme) => {
  return {
    root: {
      display: 'inline-flex',
      flexDirection: 'row',
      borderRadius: '6px',
      alignItems: ({ centerVertically }: CalloutProps) =>
        centerVertically ? 'center' : 'flex-start',
      padding: ({ variant }: CalloutProps) => (variant === 'inline' ? 0 : spacing(3, 2)),
      backgroundColor: ({ type, variant }: CalloutProps) => {
        switch (variant) {
          case 'primary':
            return palette[type].main;

          case 'quiet':
            return palette[type].dark;

          default:
            return 'none';
        }
      },
      color: ({ type, variant }: CalloutProps) =>
        variant === 'primary' || variant === 'quiet' ? palette.text.primary : palette[type].main,
      border: ({ type, variant }: CalloutProps) =>
        variant === 'outlined' ? `1px solid ${palette[type].main}` : 'none',
      fontSize: ({ variant }: CalloutProps) =>
        typography[variant === 'inline' ? 'subtitle1' : 'body2'].fontSize,
      fontWeight: ({ variant }: CalloutProps) =>
        typography[variant === 'inline' ? 'subtitle1' : 'body2'].fontWeight,
      lineHeight: ({ variant }: CalloutProps) =>
        typography[variant === 'inline' ? 'subtitle1' : 'body2'].lineHeight,
    },
    icon: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      width: ({ variant }: CalloutProps) =>
        typography[variant === 'inline' ? 'subtitle1' : 'body2'].lineHeight,
      height: ({ variant }: CalloutProps) =>
        typography[variant === 'inline' ? 'subtitle1' : 'body2'].lineHeight,
      marginRight: `${spacing(2)}`,
    },
  };
});

const CalloutFC: React.FC<CalloutProps> = ({
  type = 'info',
  variant = 'primary',
  iconOverride,
  centerVertically,
  text,
  className,
  style,
  children,
  dataTestId,
}) => {
  const { root, icon } = useStyles({ type, variant, centerVertically });
  const classes = `${root}${className ? ` ${className}` : ''}`;
  const iconName = `${type === 'warning' || type === 'error' ? 'emphasis' : type}2`;
  const iconColor = variant === 'primary' || variant === 'quiet' ? 'white' : COLOR_MAP[type];

  return (
    <aside className={classes} style={style} data-test-id={`Callout-${type}-${variant}`}>
      <div className={icon}>{iconOverride ?? <Icon name={iconName} variant={iconColor} />}</div>
      {text ? (
        <Typography variant="subtitle1">{text}</Typography>
      ) : (
        <div data-test-id={dataTestId}>{children}</div>
      )}
    </aside>
  );
};

const Callout = React.memo(CalloutFC);
Callout.displayName = 'Callout';
export default Callout;
