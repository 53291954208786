import * as React from 'react';
import { LngLatBounds, Map } from 'mapbox-gl';
import { OnecWindow } from 'onec-types';
import { useSnackbar } from 'notistack';

import {
  useGetCurrentUserQuery,
  useGetMapViewQuery,
  UserRole,
} from '../../../../__generated__/graphql';
import { App } from '../../../../PlanningApp/AppConfig';
import useApiErrSnackbar from '../../../CommonComponents/Snackbar/useApiErrSnackbar';
import UnknownErrSnackbar, {
  unknownErrEnqueueOptions,
} from '../../../CommonComponents/Snackbar/UnknownErrSnackbar';
import useSliceLocations from '../../../../Hooks/useSliceLocations';
import VulnerableLocationsList from './VulnerableLocationsList';

type VulnerableLocationsListContainerProps = {
  expanded?: boolean;
  onClickExpanded?: (card: string) => void;
};

const VulnerableLocationsListContainer: React.FC<VulnerableLocationsListContainerProps> = ({
  expanded = true,
  onClickExpanded,
}) => {
  const { data: previousMapViewData } = useGetMapViewQuery();
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();
  const { enqueueSnackbar } = useSnackbar();

  const {
    locations,
    loading: locationsLoading,
    error: locationsError,
  } = useSliceLocations('VLLContainer');

  const { data: userData, loading: userLoading, error } = useGetCurrentUserQuery();

  const allLocations = React.useMemo(() => {
    return locations || [];
  }, [locations]);
  const vllLoading = locationsLoading || !!locationsError;

  React.useEffect(() => {
    // If coming from Detail View (or another URL) back to List View, then move the map
    // bounds back to bounds  previously saved just before last leaving list view.
    if (previousMapViewData) {
      const { southEast, northWest } = previousMapViewData.previousMapView;
      const map: Map = (window as OnecWindow).reactMap;
      if (map) {
        App.debug('[VulnerableLocationsListContainer] setting map bounds back to previous');
        const bounds = new LngLatBounds(
          [southEast.longitude, southEast.latitude],
          [northWest.longitude, northWest.latitude],
        );
        map.fitBounds(bounds, {}, { fitboundUpdate: true });
      }
    }
  }, [previousMapViewData]);

  React.useEffect(() => {
    if (locationsError && !locationsLoading && enqueueSnackbar) {
      enqueueSnackbar(<UnknownErrSnackbar />, unknownErrEnqueueOptions);
    }
  }, [enqueueSnackbar, locationsError, locationsLoading]);

  React.useEffect(() => {
    if (error) {
      App.error('[SidePanel] - GetCurrentUser failed: ', error);
      enqueueApiErrSnackbar();
    }
  }, [error, enqueueApiErrSnackbar]);

  const isViewer = userData?.user?.role === UserRole.Viewer;
  if (userLoading || isViewer) return null;

  return (
    <VulnerableLocationsList
      allLocations={allLocations}
      loading={vllLoading}
      expanded={expanded}
      onClickExpanded={onClickExpanded}
    />
  );
};

VulnerableLocationsListContainer.displayName = 'VulnerableLocationsListContainer';
export default VulnerableLocationsListContainer;
