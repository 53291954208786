import * as React from 'react';
import { Map as MapboxMap } from 'mapbox-gl';
import { MapEvent } from 'react-mapbox-gl/lib/map-events';
import { Outlet } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import {
  GetCurrentUserQuery,
  MapView,
  useGetOldMapBoundsQuery,
  useUpdateMapMovedMutation,
  useUpdateOldMapBoundsMutation,
  useUpdatePreviousMapViewMutation,
} from '../../__generated__/graphql';
import { ALL_LOCATIONS_SOURCE_ID } from '../../util/productGlobals';
import { App } from '../../PlanningApp/AppConfig';
import AppNavContainer from './AppNav/AppNav';
import { areMapBoundsSame } from '../../util/productUtils';
import BaseMap from '../CommonComponents/Map/BaseMap';
import LocationsUploadProgressBar from './LocationsUploadProgressBar/LocationsUploadProgressBar';
import OnecLogo from '../CommonComponents/Map/OnecLogo';
import useEntitledMapBounds from '../../Hooks/useEntitledMapExtents';
import useUpdateVisibleLocations from '../../Hooks/useUpdateVisibleLocations';
import ROIBackgroundRunner from './ROIBackgroundRunner/ROIBackgroundRunner';

type BaseMapViewProps = {
  user: GetCurrentUserQuery['user'];
};

const BaseMapView: React.FC<React.PropsWithChildren<BaseMapViewProps>> = ({ user, children }) => {
  const { language, units } = user.preferences;
  const { t } = useTranslation();

  const mapExtents = useEntitledMapBounds();

  const { data: dataOldMapBounds } = useGetOldMapBoundsQuery();
  const oldMapBoundsRef = React.useRef(null);
  const [updateOldMapBounds] = useUpdateOldMapBoundsMutation();
  const [enableUpdateButton] = useUpdateMapMovedMutation({ variables: { didMapMove: true } });
  const updateVisibleLocations = useUpdateVisibleLocations();
  const [updatePreviousMapView] = useUpdatePreviousMapViewMutation();
  React.useEffect(() => {
    /// Since default previous map bounds is CONTINENTAL_USA_MAX_EXTENTS,
    /// need to set previous map bounds from mapExtents for JP version
    updatePreviousMapView({
      variables: {
        northWest: {
          latitude: mapExtents[3],
          longitude: mapExtents[0],
        },
        southEast: {
          latitude: mapExtents[1],
          longitude: mapExtents[2],
        },
      },
    });
  }, [mapExtents, updatePreviousMapView]);

  React.useEffect(() => {
    oldMapBoundsRef.current = dataOldMapBounds?.oldMapBounds;
  }, [dataOldMapBounds]);

  const onMapMoveEnd = React.useCallback(
    (reactMap: MapboxMap, evt: any) => {
      App.debug('[BaseMapView] - onMapMoveEnd called', evt);
      const allLocsSourceIsLoaded = !!reactMap.getSource(ALL_LOCATIONS_SOURCE_ID);
      if (!allLocsSourceIsLoaded) {
        return;
      }

      const mapBounds: mapboxgl.LngLatBounds = reactMap.getBounds();
      const bounds: MapView = {
        southEast: {
          latitude: mapBounds.getSouthEast().lat,
          longitude: mapBounds.getSouthEast().lng,
        },
        northWest: {
          latitude: mapBounds.getNorthWest().lat,
          longitude: mapBounds.getNorthWest().lng,
        },
      };
      const isMapMoved =
        !oldMapBoundsRef.current || !areMapBoundsSame(bounds, oldMapBoundsRef.current);

      if (isMapMoved)
        updateOldMapBounds({
          variables: bounds,
        });

      if (evt?.fitboundUpdate) {
        updateVisibleLocations();
      } else if (isMapMoved) {
        enableUpdateButton();
      }
    },
    [enableUpdateButton, updateOldMapBounds, updateVisibleLocations],
  );

  const onAllLocationsSourceLoaded = React.useCallback(
    (_: MapboxMap, evt: any) => {
      if (evt.sourceId === ALL_LOCATIONS_SOURCE_ID && evt.isSourceLoaded) {
        updateVisibleLocations();
      }
    },
    [updateVisibleLocations],
  );

  return (
    <>
      <AppNavContainer userId={user.personalInfo.contact.email} />
      <LocationsUploadProgressBar userId={user.personalInfo.contact.email} />
      <ROIBackgroundRunner />
      <OnecLogo corner="bottom-left" url={t('settings@oneconcernURL')} />

      <BaseMap
        onSourceData={onAllLocationsSourceLoaded as MapEvent}
        onMoveEnd={onMapMoveEnd as MapEvent}
        maxExtents={mapExtents}
        units={units}
        language={language}
      >
        {children}
        <Outlet />
      </BaseMap>
    </>
  );
};

BaseMapView.displayName = 'BaseMapView';
export default BaseMapView;
