// eslint-disable-next-line no-shadow
export enum Lifeline {
  PEOPLE = 'people',
  POWER = 'power',
  STRUCTURE = 'structure',
  PORT = 'port',
  AIRPORT = 'airport',
  BRIDGE = 'bridge',
  HIGHWAY = 'highway',
  EMPTY = '',
}
export type ValidLifeline = Exclude<Lifeline, Lifeline.EMPTY>;

// eslint-disable-next-line no-shadow
export enum EntType {
  HAZARDS,
  LIFELINES,
}

export enum ERROR_FIELDS_JP {
  LOCATIONNAME,
  ZIPCODE,
  PREFECTURE,
  CITY,
  STREETADDRESS,
  LATITUDE,
  LONGITUDE,
  TYPE,
  GROUP,
  EMPTY,
}

export enum LOCATIONS_MATCH_TABLE_SORT {
  ACCURACY = 'accuracy',
  ORIGINAL_FILE_ORDER = 'fileOrder',
}

export type Order = 'asc' | 'desc';

export enum SimulationType {
  PANDEMIC = 'Pandemic',
  CYBERATTACK = 'Cyber attack',
  EXTENDEDPOWEROUTAGE = 'Extended Power Outage',
  HURRICANEIAN = 'Hurricane Ian',
  HURRICANEIDA = 'Hurricane Ida',
}
