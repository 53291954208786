import * as React from 'react';

import {
  useGetSelectedHazardQuery,
  useGetUserEntitlementsFeaturesQuery,
} from '../__generated__/graphql';
import { App } from '../PlanningApp/AppConfig';
import { ValidLifeline } from '../util/productEnums';
import { convertSliceHazardType } from '../util/productUtils';

export type LifelineEntitlementsType = {
  structure?: boolean;
  power?: boolean;
  bridge?: boolean;
  highway?: boolean;
  port?: boolean;
  airport?: boolean;
  people?: boolean;
};

export const initEntitlements: LifelineEntitlementsType = {
  structure: true,
  power: false,
  bridge: false,
  highway: false,
  port: false,
  airport: false,
  people: false,
};

function useLifelineEntitlements(): LifelineEntitlementsType {
  const { data: matrixData, error: matrixError } = useGetUserEntitlementsFeaturesQuery();
  const { data: cacheData } = useGetSelectedHazardQuery();
  const hazardType = cacheData?.planView?.planFilters?.hazardType;

  if (matrixError) {
    App.error('[useLifelineEntitlements] error: ', matrixError);
  }

  const entitledFeatures = matrixData?.user?.entitlements?.hasFeaturesForProduct;

  const lifelineEntitlementsObj = React.useMemo(() => {
    const enabledLifelinesList: ValidLifeline[] = entitledFeatures
      ?.filter(
        ({ hazard, feature }) =>
          hazard && convertSliceHazardType(hazard) === hazardType && feature?.includes('lifeline-'),
      )
      ?.map(({ feature }) => feature.replace('lifeline-', '') as ValidLifeline);

    if (enabledLifelinesList) {
      App.debug('[useLifelineEntitlements] enabled lifelines: ', enabledLifelinesList);
      const lifelineObj = { ...initEntitlements };

      if (enabledLifelinesList) {
        enabledLifelinesList.forEach((lifelineName) => {
          lifelineObj[lifelineName] = true;
        });
      }

      return lifelineObj;
    }

    return null;
  }, [entitledFeatures, hazardType]);

  return lifelineEntitlementsObj;
}
export default useLifelineEntitlements;
