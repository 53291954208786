import * as React from 'react';
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import useUpdateMaterialitySettings from '../../../../../Hooks/useUpdateMaterialitySettings';
import { LocTypeAnnualRevenue } from '../../../../../Hooks/useGetMaterialityData';
import CurrencyInput from '../../../../CommonComponents/InputControls/CurrencyInput/CurrencyInput';
import { App } from '../../../../../PlanningApp/AppConfig';
import { useGetMaterialitySettingsQuery } from '../../../../../__generated__/graphql';
import { omitTypename } from '../../../../../util/productUtils';
import Spinner from '../../../../CommonComponents/Spinner/Spinner';

type PriceTableProps = {
  dataKey: 'annualRevenue' | 'additionalCost';
};

const useStyles = makeStyles((theme: Theme) => ({
  tableCell: {
    borderBottom: 'none',
    padding: 8,
  },
  button: {
    height: 36,
    marginBottom: theme.spacing(2),
    paddingLeft: 10,
    paddingRight: 10,
  },
}));

const PriceTable: React.FC<PriceTableProps> = ({ dataKey }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [values, setValues] = React.useState<LocTypeAnnualRevenue[]>([]);
  const { data: dataMaterialitySettings, loading, error } = useGetMaterialitySettingsQuery();

  const materialitySettings = dataMaterialitySettings?.getMaterialitySettings;
  const currentValues = React.useMemo(
    () =>
      materialitySettings?.locationTypeMaterialitySettings?.map((setting) => ({
        name: setting.locationType,
        [dataKey]:
          dataKey === 'annualRevenue'
            ? setting.annualRevenue?.toString()
            : setting.oneTimeLoss?.toString(),
      })),
    [materialitySettings?.locationTypeMaterialitySettings, dataKey],
  );

  const updateMaterialitySettings = useUpdateMaterialitySettings();

  React.useEffect(() => {
    if (currentValues) {
      setValues(currentValues);
    }
  }, [currentValues]);

  const onChange = React.useCallback(
    ({ inputKey, idx, value }: { inputKey: string; idx: number; value: string }) => {
      const newValues = [...values];
      newValues.splice(idx, 1, {
        ...newValues[idx],
        [inputKey]: value,
      });
      setValues(newValues);
    },
    [values],
  );

  const renderTableCellItem = (inputKey: string, value: string, idx: number) => {
    if (inputKey === 'name') {
      return <Typography variant="body2">{value}</Typography>;
    }
    return (
      <CurrencyInput
        id={`${idx}-${inputKey}`}
        value={value}
        onChange={({ value }: { value: string }) => onChange({ inputKey, idx, value })}
      />
    );
  };

  const isValueChanged = React.useMemo(
    () => values?.length > 0 && JSON.stringify(values) !== JSON.stringify(currentValues),
    [values, currentValues],
  );

  const handleCancelEditing = React.useCallback(() => {
    setValues(currentValues);
  }, [currentValues]);

  const handleSaveEditing = React.useCallback(() => {
    updateMaterialitySettings({
      variables: {
        input: {
          locationTypeLossRatesInput: [
            ...materialitySettings.locationTypeMaterialitySettings.map((setting) => {
              const filterValue = values.find((item) => item.name === setting.locationType);
              return {
                locationType: setting.locationType,
                annualRevenue:
                  dataKey === 'annualRevenue'
                    ? Number(filterValue?.annualRevenue ?? 0)
                    : setting.annualRevenue,
                oneTimeLoss:
                  dataKey === 'additionalCost'
                    ? Number(filterValue?.additionalCost ?? 0)
                    : setting.oneTimeLoss,
                lossRates: [...setting.lossRates.map((lossRate) => omitTypename(lossRate))],
              };
            }),
          ],
        },
      },
    });
  }, [values, materialitySettings, dataKey, updateMaterialitySettings]);

  if (error) {
    App.error(`[PriceTable] - GetMaterialitySettings error: `, error);
    return null;
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{ mt: 4, backgroundColor: 'transparent', boxShadow: 'none' }}
      >
        <Table aria-label="PriceTable" data-test-id="PriceTable">
          <TableBody>
            {values.map((rowData, idx) => (
              <TableRow
                key={rowData.name}
                data-test-id={`row-${rowData.name}`}
                sx={{ borderBottom: '0.5px solid #111212' }}
              >
                {Object.entries(rowData).map(([key, value]) => (
                  <TableCell key={key} className={classes.tableCell}>
                    {renderTableCellItem(key, value, idx)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isValueChanged && (
        <Stack direction="row" justifyContent="flex-end" mt={8} mr={2}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className={`u-clickable u-marginright--8 ${classes.button}`}
            data-test-id="CancelEditing"
            onClick={handleCancelEditing}
          >
            {t('buttons:cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={`u-clickable ${classes.button}`}
            data-test-id="SaveEditing"
            onClick={handleSaveEditing}
          >
            {t('settings:sections:profile:buttons:saveChanges')}
          </Button>
        </Stack>
      )}
    </div>
  );
};

export default PriceTable;
