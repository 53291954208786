import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { LocFilter } from '../../../../__generated__/graphql';

const useStyles = makeStyles(() => ({
  pickerListItemTextRoot: {
    marginTop: 0,
    marginBottom: 0,
  },
}));

type LocationsFiltersHoverProps = { locFilter: LocFilter };

const LocationsFiltersHoverFC: React.FC<LocationsFiltersHoverProps> = ({ locFilter }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { locTypes, locGroups } = locFilter;

  return (
    <>
      {locGroups.length > 0 && (
        <List disablePadding>
          <ListItem divider={false} disableGutters>
            <ListItemText
              primary={
                <Typography variant="caption" color="textPrimary">
                  {`${t('groups').toUpperCase()} ${locGroups.length}`}
                </Typography>
              }
              classes={{
                root: classes.pickerListItemTextRoot,
              }}
            />
          </ListItem>

          {locGroups.map((locGroup) => {
            return (
              <div key={locGroup} data-test-id={`locationGroups-${locGroup}`}>
                <ListItem divider={false} disableGutters>
                  <ListItemText
                    primary={<Typography color="textSecondary">{locGroup}</Typography>}
                    classes={{
                      root: classes.pickerListItemTextRoot,
                    }}
                  />
                </ListItem>
              </div>
            );
          })}
        </List>
      )}
      {locTypes.length > 0 && (
        <List disablePadding={locGroups.length === 0}>
          <ListItem divider={false} disableGutters>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="caption">
                  {`${t('types').toUpperCase()} ${locTypes.length}`}
                </Typography>
              }
              classes={{
                root: classes.pickerListItemTextRoot,
              }}
            />
          </ListItem>
          {locTypes.map((locType) => {
            return (
              <div key={locType} data-test-id={`locationGroups-${locType}`}>
                <ListItem divider={false} disableGutters>
                  <ListItemText
                    primary={<Typography color="textSecondary">{locType}</Typography>}
                    classes={{
                      root: classes.pickerListItemTextRoot,
                    }}
                  />
                </ListItem>
              </div>
            );
          })}
        </List>
      )}
    </>
  );
};
const LocationsFiltersHover = React.memo(LocationsFiltersHoverFC);
export default LocationsFiltersHover;
