import * as React from 'react';
import { ApolloError } from '@apollo/client';

import { Product, useGetUserEntitlementsModulesQuery } from '../__generated__/graphql';
import { App } from '../PlanningApp/AppConfig';

type EntitlementsResult = {
  error: ApolloError;
  loading: boolean;
  data: {
    enablebi: boolean | null;
    enablesimulations: boolean | null;
  };
};
/** π
 * Function to query the entitlements and also to be used
 * to evolve into more conditions around features for querying
 * @param ent Internal Enum for entitlements
 * @returns Errors, Domino entitlements, Specific logic per entitlement type
 */
function useHasFinanceEntitlement(): EntitlementsResult {
  const { enablesimulations } = App.config.features;
  const { data, error, loading } = useGetUserEntitlementsModulesQuery();

  if (error) {
    App.error(`[useHasFinanceEntitlement] Error: ${error.message}`);
  }

  const enabledProductsModule = data?.user?.entitlements?.enabledProductsModule;

  return React.useMemo(() => {
    if (error) return { error, data: null, loading };
    const financeEntitled =
      enabledProductsModule?.findIndex(
        (product) => product.module === 'finance' && product.product === Product.Domino,
      ) > -1;
    return {
      error,
      data: {
        enablebi: financeEntitled,
        enablesimulations: financeEntitled && enablesimulations,
      },
      loading,
    };
  }, [error, loading, enabledProductsModule, enablesimulations]);
}

export default useHasFinanceEntitlement;
