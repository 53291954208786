import * as React from 'react';
import { lineString, point } from '@turf/helpers';
import bearing from '@turf/bearing';
import bezierSpline from '@turf/bezier-spline';
import midpoint from '@turf/midpoint';
import transformRotate from '@turf/transform-rotate';

import {
  Airport,
  Bridge,
  GetBuiltObjectLifelineMarkersQuery,
  GetLocationLifelineMarkersQuery,
  GetThresholdsQuery,
  HighwaySegment,
  Port,
  SliceHazardType,
  Subdivision,
  useGetNavigatorInfoQuery,
  useGetPlanViewFiltersQuery,
} from '../../../../__generated__/graphql';
import { getTopRiskLifelineAssets } from '../../../../util/productUtils';
import { Lifeline } from '../../../../util/productEnums';
import {
  NON_VULNERABLE_LIFELINE_ARC_SOURCE,
  NON_VULNERABLE_LIFELINE_SOURCE,
  VULNERABLE_LIFELINE_ARC_SOURCE,
  VULNERABLE_LIFELINE_SOURCE,
} from '../../../../util/productGlobals';
import MapSource from '../../../CommonComponents/Map/MapSource';

export type LifelineArcsFCProps = {
  builtObject:
    | GetLocationLifelineMarkersQuery['location']
    | GetBuiltObjectLifelineMarkersQuery['builtObject'];
  thresholds: GetThresholdsQuery['user']['productSettings']['thresholds'];
};

const vulnerableColor = '#B200FD';
const nonVulnerableColor = '#FDFDFC';
export const highlightOpacity = 1;
export const defaultOpacity = 0.3;
export const hiddenVulnerableOpacity = 0.15;
export const hiddenOpacity = 0.05;

const LifelineArcsFC: React.FC<LifelineArcsFCProps> = ({
  builtObject: { coordinates, airports, ports, people, bridges, highways },
  thresholds: {
    structuralDamage,
    powerDowntime,
    peopleDowntime,
    highwayDowntime,
    bridgeDowntime,
    portDowntime,
    airportDowntime,
  },
}) => {
  const {
    data: {
      planView: { planFilters },
    },
  } = useGetPlanViewFiltersQuery();

  const calculateBezierCurve = React.useCallback((locationCoords: any, lifelineCoords: any) => {
    const center = midpoint(locationCoords, lifelineCoords);
    const options = { pivot: locationCoords };
    const angle = bearing(locationCoords, lifelineCoords);
    const rotatedCenter = transformRotate(center, angle > 0 ? -10 : 10, options);

    const line = lineString([
      locationCoords.geometry.coordinates,
      rotatedCenter.geometry.coordinates,
      lifelineCoords.geometry.coordinates,
    ]);
    return bezierSpline(line);
  }, []);

  const arcData = React.useMemo(() => {
    const features: GeoJSON.Feature<GeoJSON.Geometry>[] = [];
    const locationCoords = point(coordinates);
    const lifelines: { [key: string]: any } = {
      airports: getTopRiskLifelineAssets(airports),
      ports: getTopRiskLifelineAssets(ports),
      bridges:
        planFilters.hazardType === SliceHazardType.Flood ||
        planFilters.hazardType === SliceHazardType.Wind
          ? []
          : getTopRiskLifelineAssets(bridges),
      highways: getTopRiskLifelineAssets(highways),
      people: getTopRiskLifelineAssets(people),
    };

    for (const key in lifelines) {
      if (Object.prototype.hasOwnProperty.call(lifelines, key)) {
        const value = lifelines[key];
        features.push(
          ...value.map((lifeline: Airport | Port | Bridge | HighwaySegment | Subdivision) => {
            let category = '';
            let isVulnerable = false;
            const mean = lifeline.stats[0]?.mean;
            switch (key) {
              case 'airports':
                category = Lifeline.AIRPORT;
                isVulnerable = mean > airportDowntime;
                break;
              case 'ports':
                category = Lifeline.PORT;
                isVulnerable = mean > portDowntime;
                break;
              case 'bridges':
                category = Lifeline.BRIDGE;
                isVulnerable = mean > bridgeDowntime;
                break;
              case 'highways':
                category = Lifeline.HIGHWAY;
                isVulnerable = mean > highwayDowntime;
                break;
              case 'people':
                category = Lifeline.PEOPLE;
                isVulnerable = mean > peopleDowntime;
                break;
              default:
                category = '';
            }

            return {
              id: lifeline.id,
              type: 'Feature',
              geometry: calculateBezierCurve(locationCoords, point(lifeline.coordinates)).geometry,
              properties: {
                id: lifeline.id,
                mean,
                category,
                isVulnerable,
              },
            };
          }),
        );
      }
    }

    return features;
  }, [
    airports,
    airportDowntime,
    bridges,
    bridgeDowntime,
    calculateBezierCurve,
    coordinates,
    highways,
    highwayDowntime,
    people,
    peopleDowntime,
    planFilters,
    ports,
    portDowntime,
  ]);

  const {
    data: {
      navigatorInfo: { currentLifeline: category },
    },
  } = useGetNavigatorInfoQuery();

  const lifelineArcsColorFilter = React.useMemo(
    () => [
      'case',
      [
        'all',
        ['==', ['get', 'category'], Lifeline.AIRPORT],
        ['<', airportDowntime, ['get', 'mean']],
      ],
      vulnerableColor,
      [
        'all',
        ['==', ['get', 'category'], Lifeline.AIRPORT],
        ['>=', airportDowntime, ['get', 'mean']],
      ],
      nonVulnerableColor,
      ['all', ['==', ['get', 'category'], Lifeline.PORT], ['<', portDowntime, ['get', 'mean']]],
      vulnerableColor,
      ['all', ['==', ['get', 'category'], Lifeline.PORT], ['>=', portDowntime, ['get', 'mean']]],
      nonVulnerableColor,
      ['all', ['==', ['get', 'category'], Lifeline.BRIDGE], ['<', bridgeDowntime, ['get', 'mean']]],
      vulnerableColor,
      [
        'all',
        ['==', ['get', 'category'], Lifeline.BRIDGE],
        ['>=', bridgeDowntime, ['get', 'mean']],
      ],
      nonVulnerableColor,
      [
        'all',
        ['==', ['get', 'category'], Lifeline.HIGHWAY],
        ['<', highwayDowntime, ['get', 'mean']],
      ],
      vulnerableColor,
      [
        'all',
        ['==', ['get', 'category'], Lifeline.HIGHWAY],
        ['>=', highwayDowntime, ['get', 'mean']],
      ],
      nonVulnerableColor,
      ['all', ['==', ['get', 'category'], Lifeline.PEOPLE], ['<', peopleDowntime, ['get', 'mean']]],
      vulnerableColor,
      [
        'all',
        ['==', ['get', 'category'], Lifeline.PEOPLE],
        ['>=', peopleDowntime, ['get', 'mean']],
      ],
      nonVulnerableColor,
      ['all', ['==', ['get', 'category'], Lifeline.POWER], ['<', powerDowntime, ['get', 'mean']]],
      vulnerableColor,
      ['all', ['==', ['get', 'category'], Lifeline.POWER], ['>=', powerDowntime, ['get', 'mean']]],
      nonVulnerableColor,
      [
        'all',
        ['==', ['get', 'category'], Lifeline.STRUCTURE],
        ['<', structuralDamage, ['get', 'mean']],
      ],
      vulnerableColor,
      [
        'all',
        ['==', ['get', 'category'], Lifeline.STRUCTURE],
        ['>=', structuralDamage, ['get', 'mean']],
      ],
      nonVulnerableColor,
      // Default cannot be null or empty string for case
      nonVulnerableColor,
    ],
    [
      airportDowntime,
      bridgeDowntime,
      highwayDowntime,
      peopleDowntime,
      portDowntime,
      powerDowntime,
      structuralDamage,
    ],
  );

  const lifelineArcOpacity = React.useMemo(
    () => [
      'case',
      [
        'all',
        ['==', ['get', 'category'], Lifeline.AIRPORT],
        ['<', airportDowntime, ['get', 'mean']],
      ],
      category === Lifeline.EMPTY || category === Lifeline.AIRPORT
        ? highlightOpacity
        : hiddenVulnerableOpacity,
      [
        'all',
        ['==', ['get', 'category'], Lifeline.AIRPORT],
        ['>=', airportDowntime, ['get', 'mean']],
      ],
      category === Lifeline.EMPTY || category === Lifeline.AIRPORT ? defaultOpacity : hiddenOpacity,
      ['all', ['==', ['get', 'category'], Lifeline.PORT], ['<', portDowntime, ['get', 'mean']]],
      category === Lifeline.EMPTY || category === Lifeline.PORT
        ? highlightOpacity
        : hiddenVulnerableOpacity,
      ['all', ['==', ['get', 'category'], Lifeline.PORT], ['>=', portDowntime, ['get', 'mean']]],
      category === Lifeline.EMPTY || category === Lifeline.PORT ? defaultOpacity : hiddenOpacity,
      ['all', ['==', ['get', 'category'], Lifeline.BRIDGE], ['<', bridgeDowntime, ['get', 'mean']]],
      category === Lifeline.EMPTY || category === Lifeline.BRIDGE
        ? highlightOpacity
        : hiddenVulnerableOpacity,
      [
        'all',
        ['==', ['get', 'category'], Lifeline.BRIDGE],
        ['>=', bridgeDowntime, ['get', 'mean']],
      ],
      category === Lifeline.EMPTY || category === Lifeline.BRIDGE ? defaultOpacity : hiddenOpacity,
      [
        'all',
        ['==', ['get', 'category'], Lifeline.HIGHWAY],
        ['<', highwayDowntime, ['get', 'mean']],
      ],
      category === Lifeline.EMPTY || category === Lifeline.HIGHWAY
        ? highlightOpacity
        : hiddenVulnerableOpacity,
      [
        'all',
        ['==', ['get', 'category'], Lifeline.HIGHWAY],
        ['>=', highwayDowntime, ['get', 'mean']],
      ],
      category === Lifeline.EMPTY || category === Lifeline.HIGHWAY ? defaultOpacity : hiddenOpacity,
      ['all', ['==', ['get', 'category'], Lifeline.PEOPLE], ['<', peopleDowntime, ['get', 'mean']]],
      category === Lifeline.EMPTY || category === Lifeline.PEOPLE
        ? highlightOpacity
        : hiddenVulnerableOpacity,
      [
        'all',
        ['==', ['get', 'category'], Lifeline.PEOPLE],
        ['>=', peopleDowntime, ['get', 'mean']],
      ],
      category === Lifeline.EMPTY || category === Lifeline.PEOPLE ? defaultOpacity : hiddenOpacity,
      hiddenOpacity,
    ],
    [category, airportDowntime, portDowntime, bridgeDowntime, highwayDowntime, peopleDowntime],
  );

  return (
    <>
      <MapSource
        id={NON_VULNERABLE_LIFELINE_ARC_SOURCE}
        type="line"
        features={arcData.filter((feature) => !feature.properties.isVulnerable)}
        iconPaint={{
          'line-width': 3,
          'line-opacity': lifelineArcOpacity,
          'line-color': lifelineArcsColorFilter,
        }}
        previousSourceId={NON_VULNERABLE_LIFELINE_SOURCE}
      />
      <MapSource
        id={VULNERABLE_LIFELINE_ARC_SOURCE}
        type="line"
        features={arcData.filter((feature) => feature.properties.isVulnerable)}
        iconPaint={{
          'line-width': 3,
          'line-opacity': lifelineArcOpacity,
          'line-color': lifelineArcsColorFilter,
        }}
        previousSourceId={VULNERABLE_LIFELINE_SOURCE}
      />
    </>
  );
};

const LifelineArcs = React.memo(LifelineArcsFC);
LifelineArcs.displayName = 'LifelineArcs';
export default LifelineArcs;
