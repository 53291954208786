import {
  ApolloClient,
  ApolloLink,
  FetchResult,
  gql,
  HttpLink,
  NormalizedCacheObject,
  split,
} from '@apollo/client';
import { OperationDefinitionNode, print } from 'graphql';
import { addMocksToSchema } from 'graphql-tools';
import { buildClientSchema } from 'graphql/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { SchemaLink } from '@apollo/client/link/schema';
import { ServerError } from '@apollo/client/core';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { WebSocketLink } from '@apollo/client/link/ws';

import { buildDefaultStore, CreateClientStore } from './CreateClientStore';
import { App } from '../AppConfig';
import { AppLogout } from '../auth/admin';
import cache from './cache';
import { firstToFailStore } from './stores/FirstToFailStore';
import { locationMatchStore } from './stores/LocationMatchStore';
import { locationSearchStore } from './stores/LocationSearchStore';
import { locationUploadFileStore } from './stores/LocationUploadFileStore';
import { navigatorInfoStore } from './stores/NavigatorInfoStore';
import { planViewStore } from './stores/PlanViewStore';
import { recentViewStore } from './stores/RecentViewStore';
import { roiBuiltObjectStore } from './stores/ROIBuiltObjectStore';
import { simulationFormStore } from './stores/SimulationStore';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const introspectionResult = require('../../__generated__/schema.json');

// TODO Add more Queries here as we start integrating them with the real resilienceapi endpoint
const IMPLEMENTED_SERVER_API_QUERIES = [
  'GetCurrentUser',
  'GetCurrentUserAndSlices',
  'GetOrganizationUsers',
  'GetHistoricalEvents',
  'GetLocationsFileDetails',
  'GetOriginalLocationsFile',
  'GetSliceIndexes',
  'GetThresholds',
  'GetWidgetsInfo',
  'GetOrganization',
  /* disabling location-related queries while  location/builtobject APIs for getting lifeline 
     markers & stats is broken
  */

  'GetLocationsInfoWithFilter',
  'GetLocationsWithFilter',

  'GetLocation',
  'GetLocationResilienceStats',
  'GetLocationLifelineMarkers',
  'GetLocationLifelineStats',
  'GetLocationAnalysis',

  'GetBuiltObject',
  'GetBuiltObjectMarker',
  'GetBuiltObjectResilienceStats',
  'GetBuiltObjectLifelineMarkers',
  'GetBuiltObjectLifelineStats',
  'GetBuiltObjectAnalysis',

  'SearchLocationWithFilter',
  'SearchNearestBuiltObject',
  'GetUserEntitlements',
  'GetUserEntitlementsMatrix',
  'GetUserEntitlementsWithFeatures',
  'GetNearbyBuildings',
  'GetOriginalLocationsFileRecord',
  'GetReverseGeocodeAddress',
  'GetLocationTypes',

  'GetOrganizationEntitlements',
  'GetLocationsFileId',
  'GetMaterialityKPIs',
  'GetMaterialitySettings',

  'GetUserEntitlementsModules',
  'GetUserEntitlementsFeatures',
  'GetUserEntitlementsHazards',
  'GetUserEntitlementsMateriality',
  'GetLocationsFinanceInfo',
  'GetLocationsFinanceProperty',

  'GetCompanies',
  'GetCompany',
  'GetCompanyMarkers',
  'GetUserAllEntitlements',
  'GetUserCompanies',
  'GetSimulations',
];

// TODO Add more Mutations here as we start integrating them with the real resilienceapi endpoint
const IMPLEMENTED_SERVER_API_MUTATIONS = [
  'InviteUser',
  'UpdateThresholds',
  'UploadLocationsFile',
  'DeleteLocationsFile',
  'UpdateUser',
  'UpdatePassword',
  'UpdateOtherUser',
  'EnableUser',
  'DisableUser',
  'EditLocation',
  'SubmitMIBRequest',
  'ShareAnalysis',
  'UpdateMaterialitySettings',
  'CalculateROI',
  'AddUserCompanies',
  'CreateSimulation',
  'DeleteSimulation',
];

// Operations routed through the upload link: locations file upload, default
// sample file upload (for admin users)
const UPLOAD_OPERATIONS = ['UploadLocationsFile', 'UploadLocationsFileOld', 'UploadSampleFile'];

interface IDefinition {
  kind: string;
  operation?: string;
}

const { resolvers } = new CreateClientStore(cache, [
  planViewStore,
  recentViewStore,
  navigatorInfoStore,
  firstToFailStore,
  locationSearchStore,
  locationUploadFileStore,
  locationMatchStore,
  roiBuiltObjectStore,
  simulationFormStore,
]);

export const setupWebsocketSubs: () => WebSocketLink = () => {
  const websocketEvents = [
    'connecting',
    'connected',
    'reconnecting',
    'reconnected',
    'disconnected',
    'error',
  ];
  const wscallback = (error: Error[], result?: unknown) => {
    App.debug(`[Client] wscallback: ${result}, ${error}`);
  };

  const wsURL = App.config.endpoints.resilienceapi.replace(/(http)(s)?:\/\//, 'ws$2://');
  // bypass gateway by specifying direct route to upstream API (GraphQL gateway does not support ws)
  const wsclient = new SubscriptionClient(`${wsURL}/subscriptions/v1/query`, {
    reconnect: true,
    connectionCallback: wscallback,
    lazy: true,
  });

  if (App.config.features.enabledebug) {
    // eslint-disable-next-line guard-for-in
    for (const eventName in websocketEvents) {
      wsclient.on(eventName, () => {
        App.debug('[Client] ws-subscription-event: ', eventName);
      });
    }
  }
  const wsLink = new WebSocketLink(wsclient);
  return wsLink;
};

export const setupHttpLink: () => ApolloLink | HttpLink = () => {
  const federatedApiHttpLink = new HttpLink({
    // standard uri routed to the GraphQL gateway and federate backends
    uri: `${App.config.endpoints.resilienceapi}/query?`,
    credentials: 'include',
  });

  const uploadHttpLink = createUploadLink({
    // bypass gateway by specifying a direct route to upstream API (GraphQL gateway does not
    // support multipart http requests)
    // Notice that straight routes use a versioned endpoint.
    uri: `${App.config.endpoints.resilienceapi}/upload/v1/query?`,
    credentials: 'include',
  });

  const resilienceApiHttpLink = split(
    // Split based on the particular mutations that are not supported by the gateway because the
    // transport implies multipart http requests.
    ({ query }) => {
      const mainDef = getMainDefinition(query);
      const apiOperation = mainDef?.name?.value;
      const bypassGateway =
        mainDef?.kind === 'OperationDefinition' &&
        mainDef?.operation === 'mutation' &&
        UPLOAD_OPERATIONS.includes(apiOperation);
      if (bypassGateway) {
        App.debug(
          `[Client] - directing ${
            (mainDef as OperationDefinitionNode).operation
          } ${apiOperation} straight to resilienceapi endpoint (no federated gateway)`,
        );
      }
      return bypassGateway;
    },
    uploadHttpLink,
    federatedApiHttpLink,
  );

  if (App.config.features.enablemock) {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const { mocks } = require('../../__mocks__/serverMock');
    const mocksSchema = buildClientSchema(introspectionResult);
    addMocksToSchema({ schema: mocksSchema, mocks, preserveResolvers: true });
    const frontendMocksLink = new SchemaLink({ schema: mocksSchema });

    if (App.config.features.allowpartialmocks) {
      const partialMocksHttpLink = split(
        // Split based on whether the particular query/mutation is fully implemented in the
        // resilienceapi backend or whether we have to use the frontend mocks instead
        ({ query }) => {
          const mainDef = getMainDefinition(query);
          const apiOperation = mainDef?.name?.value;
          const directToRealServer =
            (mainDef?.kind === 'OperationDefinition' &&
              mainDef?.operation === 'query' &&
              IMPLEMENTED_SERVER_API_QUERIES.includes(apiOperation)) ||
            (mainDef?.kind === 'OperationDefinition' &&
              mainDef?.operation === 'mutation' &&
              IMPLEMENTED_SERVER_API_MUTATIONS.includes(apiOperation));
          if (directToRealServer) {
            App.debug(
              `[Client] - directing ${
                (mainDef as OperationDefinitionNode).operation
              } ${apiOperation} to resilienceapi endpoint (not frontend mocks)`,
            );
          }
          return directToRealServer;
        },
        resilienceApiHttpLink,
        frontendMocksLink,
      );
      return partialMocksHttpLink;
    }
    return frontendMocksLink;
  }
  return resilienceApiHttpLink;
};

export const client: () => Promise<ApolloClient<NormalizedCacheObject>> = async () => {
  const httpLink = setupHttpLink();
  const subscriptionLink = setupWebsocketSubs();
  const mainlink = split(
    // split based on whether web socket subscription or query/mutation
    ({ query }) => {
      const { kind, operation }: IDefinition = getMainDefinition(query);
      const directToWebSockets = kind === 'OperationDefinition' && operation === 'subscription';
      return directToWebSockets;
    },
    subscriptionLink,
    httpLink,
  );

  const reportErrors = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        App.warn(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
      );
    }
    if (networkError) {
      App.warn(`[Network error]: ${networkError}`);
      const err = networkError as ServerError;
      if (err.statusCode === 401) {
        AppLogout();
      }
    }
  });

  const defaultLinks = [reportErrors, mainlink];
  if (App.config.features.enabledebug) {
    const debugLink = new ApolloLink((operation, forward) => {
      App.groupCollapsed(`Call ${operation.operationName}: (click to expand)`);
      App.debug('variables:');
      App.debug(operation.variables);
      App.debug('query:');
      App.debug(print(operation.query).trim());
      App.groupEnd();
      return forward(operation).map((data: FetchResult) => {
        App.groupCollapsed(`Response of ${operation.operationName}: (click to expand)`);
        App.debug('variables:');
        App.debug(operation.variables);
        App.debug('data:');
        App.debug(data.data);
        App.groupEnd();
        return data;
      });
    });
    defaultLinks.unshift(debugLink);
  }

  const link = ApolloLink.from(defaultLinks);
  const ds: any = buildDefaultStore([
    planViewStore,
    recentViewStore,
    navigatorInfoStore,
    firstToFailStore,
    locationSearchStore,
    locationUploadFileStore,
    locationMatchStore,
    roiBuiltObjectStore,
    simulationFormStore,
  ]);
  const newClient = new ApolloClient({
    link,
    cache,
    resolvers,
    connectToDevTools: App.config.features.enabledebug,
    name: 'domino',
  });

  // TODO: Make this better for now let's just do this.
  newClient.onResetStore((): any => {
    cache.writeQuery({
      query: gql`
        {
          planView
          previousMapView
          oldMapBounds
          mapMoved
          hoveredLocation
          recentViews
          navigatorInfo
          firstToFailView
          notFoundLocation
          locationUploadFile
          locationBuildingMatch
          editLocationSource
          roiBuiltObjects
          simulationForm
        }
      `,
      data: ds,
    });
  });

  App.debug('[Client] cache: ', newClient.cache);
  return newClient;
};
