import * as React from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckIcon from '@mui/icons-material/Check';
import ListItemIcon from '@mui/material/ListItemIcon';

import MenuItemWithHighlight from '../../../CommonComponents/MenuItemWithHighlight/MenuItemWithHighlight';

type CheckableMenuItemProps = {
  name?: string;
  isChecked: boolean;
  onClick: () => void;
  divider: boolean;
  dataTestId?: string;
};

const CheckableMenuItem: React.FC<React.PropsWithChildren<CheckableMenuItemProps>> = ({
  name,
  isChecked,
  onClick,
  divider,
  dataTestId,
  children,
}) => {
  const testId = dataTestId ?? name;
  return (
    <MenuItemWithHighlight
      dataTestId={`menuitem-${name}`}
      role="menuitemcheckbox"
      aria-checked={isChecked}
      onClick={onClick}
      divider={divider}
      secondary={
        <ListItemIcon style={{ minWidth: 24 }}>
          {isChecked ? (
            <CheckIcon data-test-id={`menuitem-${testId}-checked`} />
          ) : (
            <CheckBoxOutlineBlankIcon
              style={{ opacity: 0 }}
              data-test-id={`menuitem-${testId}-unchecked`}
            />
          )}
        </ListItemIcon>
      }
    >
      {name ?? children}
    </MenuItemWithHighlight>
  );
};
CheckableMenuItem.displayName = 'CheckableMenuItem';
export default CheckableMenuItem;
