import * as React from 'react';

import CheckableMenuItem from './CheckableMenuItem';

type LocationsFilterGroupProps = {
  selectedCategories: string[];
  allCategories: string[];
  name: 'groups' | 'types';
  onChangeSelected: (newSelected: string[], name: string) => void;
};

const LocationsFilterGroup: React.FC<LocationsFilterGroupProps> = ({
  selectedCategories,
  allCategories,
  name,
  onChangeSelected,
}) => {
  const onClickItem = React.useCallback(
    (category: string) => () => {
      const currentIndex = selectedCategories.indexOf(category);
      const newSelectedCategories = [...selectedCategories];
      if (currentIndex === -1) {
        newSelectedCategories.push(category);
      } else {
        newSelectedCategories.splice(currentIndex, 1);
      }
      onChangeSelected(newSelectedCategories, name);
    },
    [name, onChangeSelected, selectedCategories],
  );

  return (
    <>
      {allCategories.map((category, idx) => {
        const isChecked = selectedCategories.includes(category);
        return (
          <CheckableMenuItem
            key={category}
            name={category}
            isChecked={isChecked}
            divider={idx < allCategories.length - 1}
            onClick={onClickItem(category)}
          />
        );
      })}
    </>
  );
};
LocationsFilterGroup.displayName = 'LocationsFilterGroup';
export default LocationsFilterGroup;
