import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTranslation } from 'react-i18next';

import { CardContent, Typography } from '@mui/material';

import {
  ForecastPeriodType,
  PlanFilters,
  ResilienceStats,
  ResilienceThresholds,
  Slice,
  useGetBuiltObjectResilienceStatsLazyQuery,
  useGetLocationResilienceStatsLazyQuery,
  useGetPlanViewFiltersQuery,
  useGetSliceIdxQuery,
  useGetSliceIndexesQuery,
} from '../../../../__generated__/graphql';
import { App } from '../../../../PlanningApp/AppConfig';
import Tabs from '../../../CommonComponents/Tabs/Tabs';
import FirstToFailTab from './Lifelines/FirstToFailTab';
import { getActiveSliceIdx } from '../../locationViewHelpers';
import { Lifeline } from '../../../../util/productEnums';
import NoLocationDataFound from './NoLocationDataFound';
import TabPanel from '../../../CommonComponents/TabPanel/TabPanel';
import TopConcernsTab from './Lifelines/TopConcernsTab';
import useApiErrSnackbar from '../../../CommonComponents/Snackbar/useApiErrSnackbar';
import { SWIPE_ANIMATE_STYLE, VALID_RETURN_PERIODS } from '../../../../util/productGlobals';

// eslint-disable-next-line no-shadow
enum LifelinesTabIndex {
  TopConcerns = 0,
  FirstToFail = 1,
}

const getFirstToFailSliceIds: (planFilters: PlanFilters, slices: Slice[]) => number[] | null = (
  planFilters,
  slices,
) => {
  if (!slices) {
    return null;
  }
  // Find the slices we need, and then query for resilienceStats for those slices.
  let sliceIds = [];

  // Stress test
  // For 'Stress test', we need to look at resilience stats for slices that match the user's
  // current filters, but force the Forecast Type to ReturnPeriod and then get all the
  // slices, each with a different return period duration, that match.
  const returnPeriodFilters = {
    ...planFilters,
    forecastPeriod: {
      ...planFilters.forecastPeriod,
      forecastPeriodType: ForecastPeriodType.ReturnPeriod,
      duration: VALID_RETURN_PERIODS[0],
    },
  };
  const matchSliceId = getActiveSliceIdx(slices, returnPeriodFilters);
  // slices might be null, if getSliceIndexes query in parent component failed
  const selectedSlice = slices?.find((sliceDef) => sliceDef.slice === matchSliceId);
  sliceIds = slices
    ?.filter(
      (sliceDef) =>
        VALID_RETURN_PERIODS.includes(sliceDef.returnPeriod) &&
        !sliceDef.planningHorizon &&
        selectedSlice &&
        sliceDef.climate === selectedSlice.climate &&
        sliceDef.hazardType === selectedSlice.hazardType &&
        sliceDef.historicalEventID === selectedSlice.historicalEventID &&
        sliceDef.severityIndex === selectedSlice.severityIndex,
    )
    ?.map((sliceDef) => sliceDef.slice);

  return sliceIds;
};

interface LifelinesProps {
  thresholds: ResilienceThresholds;
  slices?: Slice[];
  locOrBobjID: string;
  typename: string;
  selectLifeline: (lifeline: Lifeline) => void;
}

export const Lifelines: React.FC<LifelinesProps> = ({
  thresholds,
  slices,
  locOrBobjID,
  typename,
  selectLifeline,
}) => {
  const { t } = useTranslation();
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();
  const [currentTabIndex, setCurrentTabIndex] = React.useState(LifelinesTabIndex.TopConcerns);

  const {
    data: {
      planView: { sliceIdx: activeSlice },
    },
  } = useGetSliceIdxQuery();

  const {
    data: {
      planView: { planFilters },
    },
  } = useGetPlanViewFiltersQuery();

  const [
    getLocationResilienceStatsLazyQuery,
    { data: locData, loading: locLoading, error: locError },
  ] = useGetLocationResilienceStatsLazyQuery();

  const [
    getBuiltObjectResilienceStatsLazyQuery,
    { data: builtData, loading: builtLoading, error: builtError },
  ] = useGetBuiltObjectResilienceStatsLazyQuery();

  // slicesIds for 'top concerns' is just the activeSlice (i.e. just one slice).
  // The activeSlice corresponds to hazardType/forecastPeriod/climate/historicalEvent
  // filters the user has selected

  // slicesIds for 'Stress test' are the FIVE slices where
  // return period is one of [50, 100, 250, 500, 1000], and
  // planningHorizon is null, and
  // climate = activeSlice.climate,
  // hazardType = activeSlice.hazardType,
  // historicalEventID is activeSlice.historicalEventID,
  // severityIndex is activeSlice.severityIndex

  React.useEffect(() => {
    const sliceIds =
      currentTabIndex === LifelinesTabIndex.TopConcerns
        ? [activeSlice]
        : getFirstToFailSliceIds(planFilters, slices);
    // sliceIds could be null if slices was null and we're on the FirstToFail tab
    if (sliceIds) {
      if (typename === 'LocationObject') {
        getLocationResilienceStatsLazyQuery({
          variables: {
            id: locOrBobjID,
            slices: sliceIds,
          },
        });
      } else {
        getBuiltObjectResilienceStatsLazyQuery({
          variables: {
            id: locOrBobjID,
            slices: sliceIds,
          },
        });
      }
    }
  }, [
    getLocationResilienceStatsLazyQuery,
    getBuiltObjectResilienceStatsLazyQuery,
    activeSlice,
    slices,
    typename,
    locOrBobjID,
    currentTabIndex,
    planFilters,
  ]);

  let resilienceStatsArr: Array<ResilienceStats>;
  if (typename === 'LocationObject' && locData) {
    resilienceStatsArr = locData.location.resilienceStats;
  }
  if (typename === 'BuiltObject' && builtData) {
    resilienceStatsArr = builtData.builtObject.resilienceStats;
  }

  React.useEffect(() => {
    if (locError) {
      App.error('[Lifelines] - GetLocationObjectResilienceStatsQuery error: ', locError);
      enqueueApiErrSnackbar();
    }
    if (builtError) {
      App.error('[Lifelines] - GetBuiltObjectResilienceStatsQuery error: ', builtError);
      enqueueApiErrSnackbar();
    }
  }, [builtError, locError, enqueueApiErrSnackbar]);

  const showLifelineDetailView = React.useCallback(
    (lifeline: Lifeline) => {
      selectLifeline(lifeline);
    },
    [selectLifeline],
  );

  const handleChangeIndex = React.useCallback((index: number) => {
    setCurrentTabIndex(index);
  }, []);

  if (slices && (builtError || locError)) {
    App.debug(
      '[LifelineContainer] - getResilienceStats queries failed, probably because location not found',
      builtError || locError,
    );
    // TODO - later, distinguish between unexpected errors (which need a snackbar and other error )
    // handling, and "location not found" error, which is expected if user clicks on a bookmarked
    // URL with an old locationID and is thus handled gracefully.
    return <NoLocationDataFound />;
  }

  const showSkeleton =
    !(locData || builtData) || builtLoading || locLoading || !resilienceStatsArr?.length;

  return (
    <div className="o-flexcol-container" data-test-id="LifelinesContainer">
      <Typography color="textPrimary" variant="h4">
        {t('detailView:atRisk')}
      </Typography>
      <CardContent
        className="u-radius--6 u-no-padding u-margintop--16"
        data-test-id="LocationDetail-Lifelines-Section"
      >
        <Tabs
          tabList={[
            t('detailView:lifelines:topConcerns:title'),
            t('detailView:lifelines:firstToFail:title'),
          ]}
          currentTabIndex={currentTabIndex}
          setCurrentTabIndex={handleChangeIndex}
        />
        <SwipeableViews
          index={currentTabIndex}
          onChangeIndex={handleChangeIndex}
          containerStyle={SWIPE_ANIMATE_STYLE}
        >
          <TabPanel value={currentTabIndex} index={0} data-test-id="TabPanel-TopConcerns">
            <TopConcernsTab
              resilienceStatsArr={resilienceStatsArr}
              thresholds={thresholds}
              activeSlice={activeSlice}
              showSkeleton={showSkeleton}
              showLifelineDetailView={showLifelineDetailView}
              hazardType={planFilters.hazardType}
            />
          </TabPanel>
          <TabPanel value={currentTabIndex} index={1} data-test-id="TabPanel-FirstToFail">
            <FirstToFailTab
              resilienceStatsArr={resilienceStatsArr}
              planFilters={planFilters}
              thresholds={thresholds}
              slices={slices}
              showSkeleton={showSkeleton || !slices}
              showLifelineDetailView={showLifelineDetailView}
            />
          </TabPanel>
        </SwipeableViews>
      </CardContent>
    </div>
  );
};
Lifelines.displayName = 'Lifelines';

type LifelinesContainerProps = Omit<LifelinesProps, 'slices'>;

const LifelinesContainer: React.FC<LifelinesContainerProps> = ({ ...restProps }) => {
  const { data: slicesData, error: slicesError } = useGetSliceIndexesQuery();
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();
  const slices = slicesData?.sliceIndexes;

  React.useEffect(() => {
    if (slicesError) {
      App.error('[LifelinesContainer] error: GetSliceIndexesQuery');
      enqueueApiErrSnackbar();
    }
  }, [enqueueApiErrSnackbar, slicesError]);

  // slices can be null if sliceIndexes query is still loading or there's a query error.
  // The child component onlyneeds slices if showing the FirstToFail component
  return <Lifelines slices={slices} {...restProps} />;
};
LifelinesContainer.displayName = 'LifelinesContainer';
export default LifelinesContainer;
