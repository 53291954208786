import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Lifeline } from '../../../util/productEnums';
import { NavigatorViewType, useGetNavigatorInfoQuery } from '../../../__generated__/graphql';
import { ORANGE } from '../../../util/productGlobals';
import LifelineTag from '../SidePanel/Common/LifelineTag';
import PowerROIModal from './PowerROIModal/PowerROIModal';

type LifelineVulnerableDetailProps = {
  caption: Lifeline;
  isVulnerable: boolean;
  downtime: string;
  total?: number | null;
  vulnerableCount?: number | null;
  lifelineUnit?: string;
  hazardType: string;
  builtObjectDetails?: {
    propertyType?: string;
    id?: string;
    builtObjectID?: string;
    type: 'LocationObject' | 'BuiltObject';
  };
  coordinates?: number[];
  selectLifeline: (lifeline: Lifeline) => void;
  showROI?: boolean;
};

const useStyles = makeStyles({
  showDetail: {
    background: 'transparent',
    cursor: 'pointer',
    color: '#ACB5BD',
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: 1.5,
    border: 'none',
    marginTop: 4,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    textTransform: 'initial',
    justifyContent: 'space-between',
    width: 'fit-content',
  },
  body2: {
    fontSize: '0.75rem',
    textTransform: 'lowercase',
    lineHeight: 1.5,
  },
  forwardIcon: {
    fontSize: '0.75rem',
    marginLeft: 8,
  },
  roiLabel: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: ORANGE,
    fontSize: '0.75rem',
    fontWeight: 'bold',
    lineHeight: '1rem',
    cursor: 'pointer',
  },
});

const LifelineVulnerableDetail: React.FC<LifelineVulnerableDetailProps> = ({
  caption,
  isVulnerable,
  downtime,
  total = null,
  vulnerableCount = null,
  lifelineUnit,
  hazardType,
  builtObjectDetails,
  coordinates,
  selectLifeline,
  showROI,
}) => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [roiModalOpen, setROIModalOpen] = React.useState(showROI ?? false);
  const [roiVisible, setROIVisible] = React.useState(false);
  const { t } = useTranslation();
  const {
    data: {
      navigatorInfo: { currentView },
    },
  } = useGetNavigatorInfoQuery();

  const vulnerableDescription = React.useMemo(
    () =>
      caption === 'power'
        ? t('symbolicView:atSelectedLocation')
        : t('symbolicView:lifelineVulnerableDescription', {
            vulnerableCount,
            count: total,
            lifelineUnit,
          }),
    [caption, lifelineUnit, total, t, vulnerableCount],
  );

  const handleClick = React.useCallback(() => {
    selectLifeline(caption);
  }, [caption, selectLifeline]);

  const handleOpenPowerROIModal = () => {
    setROIModalOpen(true);
    const param = searchParams.get('showROI');
    if (!param) {
      searchParams.append('showROI', 'true');
      setSearchParams(searchParams);
    }
  };

  React.useEffect(() => {
    if (showROI) {
      setROIModalOpen(true);
    }
  }, [showROI]);

  return (
    <div className="u-flex-column" data-test-id={`LifelineVulnerableDetail-${caption}`}>
      <div className="u-inline-block u-position--rel">
        <LifelineTag
          caption={caption}
          isVulnerable={isVulnerable}
          hazardType={hazardType}
          handleClick={handleClick}
        />
        {roiVisible &&
          caption === Lifeline.POWER &&
          currentView === NavigatorViewType.TopConcernsView && (
            <Tooltip
              title={t('roi:labelTooltip')}
              placement="top"
              arrow
              data-test-id="PowerROI-Notification"
            >
              <Typography
                variant="caption"
                className={classes.roiLabel}
                onClick={handleOpenPowerROIModal}
              >
                {t('roi:label')}
              </Typography>
            </Tooltip>
          )}
      </div>
      <Typography
        className="f-lightgrey2 u-margintop--8"
        variant="body1"
        data-test-id={`LifelineVulnerableDetail-${caption}-value`}
      >{`${t(
        caption === 'structure' ? 'damage' : 'detailView:lifelines:downtime',
      )} ${downtime}`}</Typography>
      {caption !== 'structure' && total > 0 && (
        <Typography
          className={`f-lightgrey2 u-margintop--4 ${classes.body2}`}
          variant="body2"
          data-test-id={`LifelineVulnerableDetail-${caption}-assetCount`}
        >
          {vulnerableDescription}
        </Typography>
      )}
      {currentView === NavigatorViewType.TopConcernsView && (
        <div className={classes.showDetail} onClick={handleClick}>
          {t('symbolicView:showDetails')}
          <ArrowForwardIosIcon className={classes.forwardIcon} />
        </div>
      )}
      {caption === Lifeline.POWER && builtObjectDetails.id && (
        <PowerROIModal
          builtObjectDetails={builtObjectDetails}
          coordinates={coordinates}
          modalOpen={roiModalOpen}
          setModalOpen={setROIModalOpen}
          setROIVisible={setROIVisible}
        />
      )}
    </div>
  );
};

export default LifelineVulnerableDetail;
