import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SnackbarKey, useSnackbar } from 'notistack';
import {
  RoiStatus,
  useCalculateRoiMutation,
  useGetRoiBuiltObjectsQuery,
} from '../../../__generated__/graphql';
import ReviewSnackbarButton from '../../CommonComponents/Snackbar/ReviewSnackbarButton';
import StyledSnackbarContent from '../../CommonComponents/Snackbar/StyledSnackbarContent';

const ROIBackgroundRunner: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { data } = useGetRoiBuiltObjectsQuery();
  const [pendingIds, setPendingIds] = React.useState([]);
  const [calculateROIMutation] = useCalculateRoiMutation({
    onCompleted: (dataROI) => {
      const { status, builtObjectID, propertyType } = dataROI?.calculateROI ?? {};
      if (status === RoiStatus.InProgress || status === RoiStatus.Error) {
        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          calculateROI(
            {
              builtObjectID,
              propertyType,
            },
            status === RoiStatus.Error,
          );
        }, 4000);
      } else {
        const analysisURLID = data?.roiBuiltObjects?.find(
          (builtObject) => builtObject.builtObjectID === builtObjectID,
        )?.analysisURLID;
        enqueueSnackbar(<StyledSnackbarContent title={t('roi:notificationCompleted')} />, {
          variant: 'success',
          // eslint-disable-next-line react/no-unstable-nested-components
          action: (key: SnackbarKey) => (
            <ReviewSnackbarButton
              id={key}
              href={`/analysis/detail/${analysisURLID}?showROI=true`}
            />
          ),
          persist: true,
        });
      }
    },
  });

  const calculateROI = React.useCallback(
    (input: { builtObjectID: string; propertyType?: string }, retry?: boolean) => {
      calculateROIMutation({
        variables: {
          input: {
            builtObjectID: input.builtObjectID,
            propertyType: input.propertyType,
            retry,
          },
        },
      });
    },
    [calculateROIMutation],
  );

  React.useEffect(() => {
    if (data?.roiBuiltObjects) {
      data?.roiBuiltObjects.forEach((builtObject) => {
        if (!pendingIds.includes(builtObject.builtObjectID)) {
          calculateROI(builtObject);
          setPendingIds([...pendingIds, builtObject.builtObjectID]);
        }
      });
    }
  }, [calculateROI, data?.roiBuiltObjects, pendingIds]);

  return null;
};

ROIBackgroundRunner.displayName = 'ROIBackgroundRunner';
export default ROIBackgroundRunner;
