import * as React from 'react';

import { Product, useGetOrganizationEntitlementsQuery } from '../__generated__/graphql';
import { App } from '../PlanningApp/AppConfig';

/**
 * Hook that queries organization entitlements to search for the consultant module
 * @returns loading: boolean for whether entitlements query is loading;
 * error: ApolloError object;
 * isConsultantOrg: boolean for whether the organization is a consultant organization
 */
function useConsultantOrganization() {
  const { data, loading, error } = useGetOrganizationEntitlementsQuery();

  return React.useMemo(() => {
    if (!loading && (!data || error)) {
      App.error(`[useConsultantOrganization] Error: ${error?.message ?? 'No data returned'}`);
    }

    return {
      loading,
      error,
      isConsultantOrg: !!data?.organization?.entitlements?.enabledProductsModule?.some(
        ({ product, module }) => product === Product.Domino && module === 'consultant',
      ),
    };
  }, [data, loading, error]);
}

export default useConsultantOrganization;
