import * as React from 'react';

import { makeStyles } from '@mui/styles';
import { CircularProgress, Typography } from '@mui/material';

const useStyles = makeStyles(() => {
  return {
    spinnerRoot: {
      position: 'relative',
    },
    spinnerBottomCircle: {
      color: '#5c5c5c',
    },
    spinnerTopCircle: {
      animationDuration: '4000ms',
      position: 'absolute',
      left: 0,
      strokeLinecap: 'round',
    },
    spinnerDiv: {
      maxWidth: 20,
      alignSelf: 'center',
    },
    body: {
      overflowWrap: 'anywhere',
    },
  };
});

const TinySpinner: React.FC = (props) => {
  // This spinner has a grey "background" circle as well as the
  // spinning colored top circle
  const classes = useStyles();
  return (
    <div className={classes.spinnerRoot}>
      <CircularProgress
        variant="determinate"
        className={classes.spinnerBottomCircle}
        size={20}
        thickness={6}
        {...props}
        value={100}
      />
      <CircularProgress
        color="primary"
        variant="indeterminate"
        className={classes.spinnerTopCircle}
        size={20}
        thickness={6}
        value={40}
        {...props}
      />
    </div>
  );
};

type StyledSnackbarContentProps = {
  title: string;
  description?: string | React.ReactNode;
  spinner?: boolean;
};

const StyledSnackbarContent: React.FC<StyledSnackbarContentProps> = ({
  title,
  description,
  spinner = false,
}) => {
  const classes = useStyles();
  return (
    <div data-test-id="styled-snackbar-content" style={{ display: 'flex', alignSelf: 'center' }}>
      {spinner && (
        <div className={classes.spinnerDiv} data-test-id="snackbar-spinner">
          <TinySpinner />
        </div>
      )}
      <div className={`o-flexcol-container u-marginleft--8 ${classes.body}`}>
        <Typography variant="body1" data-test-id="snackbar-title">
          {title}
        </Typography>
        {description && (
          <Typography variant="caption" data-test-id="snackbar-description">
            {description}
          </Typography>
        )}
      </div>
    </div>
  );
};

StyledSnackbarContent.displayName = 'StyledSnackbarContent';
export default StyledSnackbarContent;
