import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import { Theme, Toolbar, Typography } from '@mui/material';
import { useGetCurrentUserQuery, UserRole } from '../../../../__generated__/graphql';
// import DummyDropdownMenu from '../Common/DummyDropdownMenu';
import { App } from '../../../../PlanningApp/AppConfig';
import {
  BLURRY,
  DEMO_ACCOUNT_SUFFIX,
  GLASS_BG,
  SIDE_PANEL_WIDTH,
} from '../../../../util/productGlobals';
import useApiErrSnackbar from '../../../CommonComponents/Snackbar/useApiErrSnackbar';
import UserProfileIcon from './UserProfileIcon';
import { setAmplitudeUserProperties } from '../../../../plugins/amplitude';

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    // Only show this title if the viewPort is at least "medium" size (960px wide)
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
    marginTop: 15,
    marginRight: 10,
    marginBottom: 15,
    marginLeft: 20,
  },
  title: {
    // Only show this title if the viewPort is at least "medium" size (960px wide)
    display: 'none',
    marginRight: 'auto',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  toolbar: {
    zIndex: 3,
    margin: 10,
    padding: 16,
    backgroundColor: GLASS_BG,
    borderRadius: '6px',
    backdropFilter: BLURRY,
    [theme.breakpoints.up('md')]: {
      width: `${SIDE_PANEL_WIDTH}px`,
      justifyContent: 'flex-end',
    },
  },
}));

const SecondaryNav: React.FC = () => {
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();

  const { data, loading, error } = useGetCurrentUserQuery();

  React.useEffect(() => {
    if (error) {
      App.error('[SecondaryNav] - GetCurrentUser failed: ', error);
      enqueueApiErrSnackbar();
    }
  }, [error, enqueueApiErrSnackbar]);

  React.useEffect(() => {
    if (data?.user?.role) {
      setAmplitudeUserProperties({
        role: data?.user?.role,
      });
    }
  }, [data?.user?.role]);

  const isViewer = data?.user?.role === UserRole.Viewer;
  const userId = data?.user?.personalInfo?.contact?.email;
  const isDemoUser = userId?.endsWith(DEMO_ACCOUNT_SUFFIX);

  if (isDemoUser) {
    return null;
  }

  return (
    <Toolbar className={classes.toolbar} data-test-id="secondary-nav">
      {!loading && !isViewer && (
        <Typography
          aria-label="Manage Locations"
          variant="body1"
          className={`${classes.title} u-clickable`}
          color="textPrimary"
          noWrap
          component={RouterLink}
          to="/locations"
          data-test-id="manage-locations"
        >
          {t('nav:manageLocations')}
        </Typography>
      )}
      <UserProfileIcon />

      {/* This will be coming later so I commented */}
      {/* <DummyDropdownMenu
        menuName="notifications"
        menuChoices={['notification 1', 'notification 2']}
      >
        <NotificationsIcon />
      </DummyDropdownMenu> */}
    </Toolbar>
  );
};
SecondaryNav.displayName = 'SecondaryNav';
export default SecondaryNav;
