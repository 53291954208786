import * as React from 'react';
import { CardContent, Skeleton, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { BORDER_RADIUS } from '../../../../util/productGlobals';

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    margin: theme.spacing(8, 0, 8, 0),
  },
  textLines: {
    borderRadius: BORDER_RADIUS,
    margin: 4,
  },
  chips: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginLeft: 0,
    borderRadius: 100,
  },
  listChipCountLine: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(0, 0, 4, 2),
  },
  scrollContainer: {
    overflowY: 'auto',
    margin: theme.spacing(2, 0),
    padding: 0,
    paddingRight: theme.spacing(3),
  },
}));

export const SkeletonMaterialityItem: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.itemWrapper} data-test-id="skeleton-materiality-item">
      <Skeleton
        variant="rectangular"
        height={16}
        width={120}
        classes={{ root: classes.textLines }}
      />
      <Skeleton
        variant="rectangular"
        height={14}
        width={140}
        classes={{ root: classes.textLines }}
      />
    </div>
  );
};

SkeletonMaterialityItem.displayName = 'SkeletonMaterialityItem';

type SkeletonMaterialityViewProps = {
  expanded?: boolean;
};

const SkeletonMaterialityView: React.FC<SkeletonMaterialityViewProps> = ({ expanded }) => {
  const classes = useStyles();
  const skeletonItems: React.ReactNode[] = [];
  for (let i = 0; i < 4; i += 1) {
    skeletonItems.push(<SkeletonMaterialityItem key={i} />);
  }
  return (
    <div data-test-id="skeleton-materiality-view">
      <div className={classes.listChipCountLine}>
        <Skeleton
          variant="rectangular"
          height={26}
          width={80}
          classes={{ root: classes.chips }}
          data-test-id="skeleton-chip-1"
        />
        <Skeleton
          variant="rectangular"
          height={26}
          width={100}
          classes={{ root: classes.chips }}
          data-test-id="skeleton-chip-2"
        />
      </div>
      {expanded && (
        <CardContent
          classes={{ root: classes.scrollContainer }}
          data-test-id="skeleton-materiality-view-body"
        >
          {skeletonItems}
        </CardContent>
      )}
    </div>
  );
};
SkeletonMaterialityView.displayName = 'SkeletonMaterialityView';
export default SkeletonMaterialityView;
