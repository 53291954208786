import * as React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

import ArcViewIcon from '../../../../assets/images/Navigator/ArcView.png';
import BackIcon from '../../../../assets/images/Navigator/Back.png';
import ForwardIcon from '../../../../assets/images/Navigator/Forward.png';
import SymbolicViewIcon from '../../../../assets/images/Navigator/SymbolicView.png';

import {
  DEMO_ACCOUNT_SUFFIX,
  GLASS_BG_MED,
  NAVIGATOR_USER_ENGAGE_THRESHOLD,
  ORANGE,
  WHITE,
} from '../../../util/productGlobals';
import {
  NavigatorViewType,
  PlayState,
  useGetNavigatorInfoQuery,
  useGetSelectedHazardQuery,
  useUpdateNavigatorInfoMutation,
  useUpdatePlayStateMutation,
} from '../../../__generated__/graphql';
import { App } from '../../../PlanningApp/AppConfig';
import FirstToFailControlsContainer from '../FirstToFail/FirstToFailControlsContainer';
import useHasFinanceEntitlement from '../../../Hooks/useHasFinanceEntitlement';

const useStyles = makeStyles({
  container: {
    margin: '0 0 66px 8px',
    height: '134px',
    boxSizing: 'border-box',
    backdropFilter: 'blur(18px)',
    position: 'absolute',
    left: 0,
    bottom: 0,
    zIndex: 2,
  },

  arcLayout: {
    background: GLASS_BG_MED,
    borderRadius: 6,
  },

  arcTileLayout: {
    padding: '20px 0px 17px 16px',
  },

  arcLayoutOnlyTile: {
    background: GLASS_BG_MED,
    borderRadius: 6,
    padding: '11px 6px 8px 6px',
    width: '134px',
    height: '134px',
  },

  introArcLayout: {
    width: '271px',
    padding: '31px 16px 31px 32px',
    boxSizing: 'content-box',
  },

  intro: {
    margin: 0,
    color: WHITE,
    fontWeight: 400,
    lineHeight: '18px',
    letterSpacing: 0,
  },

  launch: {
    margin: '16px 0 0 0',
    color: ORANGE,
    fontSize: '0.875rem',
    lineHeight: '20px',
    fontWeight: 500,
  },

  hoverLaunch: {
    margin: 0,
    color: ORANGE,
    fontSize: '0.75rem',
    lineHeight: '24px',
    fontWeight: 500,
    textAlign: 'center',
    letterSpacing: 0,
  },

  tileLayout: {
    width: '134px',
    height: '134px',
    minWidth: '134px',
    background: GLASS_BG_MED,
    borderRadius: 6,
  },

  introSymbolicLayout: {
    background: GLASS_BG_MED,
    borderRadius: 6,
    padding: '22px 16px 22px 32px',
    width: '385px',
    boxSizing: 'border-box',
    marginLeft: '8px',
  },

  forwardIcon: {
    marginLeft: '20px',
  },

  firstFailLayout: {
    width: '100vw',
    paddingRight: 16,
    overflow: 'hidden',
  },
});

type ViewNavigatorProps = {
  userId: string;
};

const ViewNavigator: React.FC<ViewNavigatorProps> = ({ userId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [hover, setHover] = React.useState<boolean>(false);
  const routeLocation = useLocation();
  const [updateNavigatorInfoMutation] = useUpdateNavigatorInfoMutation();
  const [updatePlayState] = useUpdatePlayStateMutation();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    data: {
      planView: {
        planFilters: { historicalId },
      },
    },
  } = useGetSelectedHazardQuery();

  const {
    data: {
      navigatorInfo: { currentView, userEngagedTimes },
    },
    error,
    loading,
  } = useGetNavigatorInfoQuery();
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;

  const goPreviousViewMode = React.useCallback(() => {
    if (currentView === NavigatorViewType.TopConcernsView) {
      updateNavigatorInfoMutation({
        variables: { currentView: NavigatorViewType.MapView, currentLifeline: '' },
      });
    }
    if (currentView === NavigatorViewType.FirstToFailView) {
      const param = searchParams.get('show3D');
      if (param) {
        searchParams.delete('show3D');
        setSearchParams(searchParams);
      }
      updateNavigatorInfoMutation({
        variables: { currentView: NavigatorViewType.TopConcernsView, currentLifeline: '' },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentView, updateNavigatorInfoMutation, searchParams]);

  const goNextViewMode = React.useCallback(async () => {
    if (currentView === NavigatorViewType.MapView)
      updateNavigatorInfoMutation({
        variables: { currentView: NavigatorViewType.TopConcernsView, currentLifeline: '' },
      });
    if (currentView === NavigatorViewType.TopConcernsView) {
      await updatePlayState({
        variables: {
          playState: PlayState.Initial,
          returnPeriodIndex: 0,
        },
      });
      updateNavigatorInfoMutation({
        variables: { currentView: NavigatorViewType.FirstToFailView, currentLifeline: '' },
      });
    }
  }, [currentView, updatePlayState, updateNavigatorInfoMutation]);

  const handleNavigatorOver = React.useCallback(() => {
    setHover(true);
  }, [setHover]);

  const handleNavigatorLeave = React.useCallback(() => {
    setHover(false);
  }, [setHover]);

  if (
    !routeLocation.pathname ||
    routeLocation.pathname.includes('/analysis/detail/notFound') ||
    (!routeLocation.pathname.includes('/analysis/detail/') &&
      !routeLocation.pathname.includes('/analysis/see-the-math/') &&
      !routeLocation.pathname.includes('/analysis/share-location/'))
  ) {
    if (currentView !== NavigatorViewType.MapView)
      updateNavigatorInfoMutation({
        variables: { currentView: NavigatorViewType.MapView, currentLifeline: '' },
      });
    return null;
  }

  if (error) {
    App.error('[ViewNavigator] - Error GetNavigatorInfoQuery: ', error);
  }

  if (error || loading) return null;
  const userEngaged = userEngagedTimes >= NAVIGATOR_USER_ENGAGE_THRESHOLD;

  const isDemoUser = userId.endsWith(DEMO_ACCOUNT_SUFFIX);

  return (
    <div
      id="viewNavigator"
      data-test-id="viewNavigator"
      className={`u-clickable ${classes.container}`}
    >
      {currentView === NavigatorViewType.MapView && !userEngaged && (
        <div
          data-test-id="navigatorInArc"
          className={`u-flex ${classes.arcLayout}`}
          onClick={goNextViewMode}
          onKeyDown={goNextViewMode}
          role="button"
          tabIndex={0}
        >
          <div className={`u-flex-column ${classes.arcTileLayout}`}>
            <img
              src={SymbolicViewIcon}
              width="103"
              height="78"
              alt={t('navigator:switchToTopConcerns')}
              srcSet={`${SymbolicViewIcon} 1x`}
            />
          </div>
          <div className={`u-flex-column ${classes.introArcLayout}`}>
            <Typography variant="body2" data-test-id="intro" className={classes.intro}>
              {t('navigator:descForArcView')}
            </Typography>
            <Typography variant="body1" data-test-id="launch" className={classes.launch}>
              {t('navigator:launch3DView')}
            </Typography>
          </div>
        </div>
      )}
      {currentView === NavigatorViewType.MapView && userEngaged && (
        <div
          data-test-id="navigatorInArcOnlyTile"
          className={`o-flex-center-container u-flex-column ${classes.arcLayoutOnlyTile}`}
          onClick={goNextViewMode}
          onKeyDown={goNextViewMode}
          onMouseOver={handleNavigatorOver}
          onFocus={handleNavigatorOver}
          onMouseOut={handleNavigatorLeave}
          onBlur={handleNavigatorLeave}
          tabIndex={0}
          role="button"
        >
          <img
            src={SymbolicViewIcon}
            width="103"
            height="78"
            alt={t('navigator:switchToTopConcerns')}
            srcSet={`${SymbolicViewIcon} 1x`}
          />
          <Typography
            variant="body1"
            data-test-id="hover-launch"
            className={`${classes.hoverLaunch} ${hover ? '' : 'u-hide'}`}
          >
            {t('navigator:launch3DView')}
          </Typography>
        </div>
      )}
      {currentView === NavigatorViewType.TopConcernsView && (
        <div className="u-flex">
          {(!isDemoUser || isFinanceEntitled) && (
            <div
              data-test-id="navigatorToArc"
              className={`o-flex-center-container ${classes.tileLayout}`}
              onClick={goPreviousViewMode}
              onKeyDown={goPreviousViewMode}
              role="button"
              tabIndex={0}
            >
              <img
                src={ArcViewIcon}
                width="126"
                height="127"
                alt={t('navigator:switchToMapView')}
                srcSet={`${ArcViewIcon} 1x`}
              />
            </div>
          )}
          {!historicalId && (
            <div
              data-test-id="navigatorToFirstFail"
              className={`o-flex-vcenter-container ${classes.introSymbolicLayout}`}
              onClick={goNextViewMode}
              onKeyDown={goNextViewMode}
              role="button"
              tabIndex={0}
            >
              <div className="u-flex-column">
                <Typography variant="body2" data-test-id="intro" className={classes.intro}>
                  {t('navigator:descFor3DView')}
                </Typography>
                <Typography variant="body1" data-test-id="launch" className={classes.launch}>
                  {t('navigator:playHazardSimulation')}
                </Typography>
              </div>
              <img
                src={ForwardIcon}
                width="32"
                height="32"
                alt={t('navigator:switchToFirstFail')}
                srcSet={`${ForwardIcon} 1x`}
                className={classes.forwardIcon}
              />
            </div>
          )}
        </div>
      )}
      {currentView === NavigatorViewType.FirstToFailView && (
        <div className={`u-flex ${classes.firstFailLayout}`}>
          <div
            data-test-id="navigatorBackToTopConcerns"
            className={`o-flex-center-container ${classes.tileLayout}`}
            onClick={goPreviousViewMode}
            onKeyDown={goPreviousViewMode}
            role="button"
            tabIndex={0}
          >
            <img
              src={BackIcon}
              width="32"
              height="32"
              alt={t('navigator:switchToTopConcerns')}
              srcSet={`${BackIcon} 1x`}
            />
          </div>
          <FirstToFailControlsContainer />
        </div>
      )}
    </div>
  );
};

ViewNavigator.displayName = 'ViewNavigator';
export default ViewNavigator;
