import * as React from 'react';

import { CountryCode } from '../__generated__/graphql';
import { App } from '../PlanningApp/AppConfig';

import useApiErrSnackbar from '../Components/CommonComponents/Snackbar/useApiErrSnackbar';
import useEntitledModules from './useEntitledModules';

export function useGetEntitledCountryCodes(): string[] {
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();
  const { data, error } = useEntitledModules();

  React.useEffect(() => {
    if (error) {
      App.error('[useGetEntitledCountryCodes] error: ', error);
      enqueueApiErrSnackbar();
    }
  }, [error, enqueueApiErrSnackbar]);

  const { hasJPModule, hasUSModule } = data ?? {};

  const maxExtents = React.useMemo(() => {
    const countryCodesList: string[] = [];
    if (hasJPModule) {
      countryCodesList.push(CountryCode.Jp);
    }
    if (hasUSModule) {
      countryCodesList.push(CountryCode.Us);
    }
    return countryCodesList;
  }, [hasJPModule, hasUSModule]);

  return maxExtents;
}

export default useGetEntitledCountryCodes;
