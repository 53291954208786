import { Dict, LocationProps } from 'onec-types';
import { point } from '@turf/helpers';
import transformTranslate from '@turf/transform-translate';

import { GeoJsonFeature, GeoJsonFeatureCollection } from '../../__generated__/graphql';
import { App } from '../../PlanningApp/AppConfig';
import { clamp } from '../../util/utils';

// TODO - erase this once we're not using the colleges JSON as sample data and instead are using a
// real GET_LOCATIONS graphql API

const localeCodeDict: Dict = {
  '11': 'Large City',
  '12': 'Mid-Sized City',
  '13': 'Small City',
  '21': 'Large Suburb',
  '22': 'Mid-Sized Suburb',
  '23': 'Small Suburb',
  '31': 'Town Fringe',
  '32': 'Town Distant',
  '33': 'Town Remote',
  '41': 'Rural Fringe',
  '42': 'Rural Distant',
  '43': 'Rural Remote',
};

const makeMockLocationData: (chance: Chance.Chance) => LocationProps[] = (chance) => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
  const schoolGeojson: GeoJsonFeatureCollection = require('./colleges+highschools.json');

  const lower48Schools = schoolGeojson.features.filter(
    (feature) =>
      !['AK', 'HI', 'PR', 'AS', 'GU', 'FM', 'PW', 'VI', 'MH', 'MP'].includes(
        feature.properties?.STABBR,
      ),
  );

  const locs: LocationProps[] = [];
  const numFeatures = lower48Schools.length;
  const { nummocklocations } = App.config.features;
  const skipInterval = Math.floor(numFeatures / clamp(+nummocklocations, 1, numFeatures));

  lower48Schools.forEach((feature: GeoJsonFeature, idx: number) => {
    if (idx % skipInterval !== 0 || idx >= nummocklocations * skipInterval) return;

    const {
      NAME: name,
      UNITID: locationId,
      STREET: streetWithNum,
      CITY: city,
      STATE: state,
      ZIP: postCode,
      NMCNTY: county,
      LON: lon,
      LAT: lat,
      LOCALE: locale,
    } = feature.properties;

    // create a location type
    let schoolType;
    if (
      name.includes('High School') ||
      name.includes('SHS') ||
      name.includes('Magnet') ||
      name.includes('Secondary')
    ) {
      schoolType = 'High School';
    } else if (name.includes('Community College')) {
      schoolType = 'Community College';
    } else if (name.includes('Junior College')) {
      schoolType = 'Junior College';
    } else if (
      name.includes('Beauty') ||
      name.includes('Hair') ||
      name.includes('Cosmetology') ||
      name.includes('Make-up') ||
      name.includes('Paul Mitchell') ||
      name.includes('Aesthetic') ||
      name.includes('Esthetics') ||
      name.includes('Skin Care') ||
      name.includes('Nail') ||
      name.includes('Salon')
    ) {
      schoolType = 'Cosmetology';
    } else if (name.includes('Auto')) {
      schoolType = 'Automotive';
    } else if (name.includes('Nursing') || name.includes('Nurse')) {
      schoolType = 'Nursing';
    } else if (
      name.includes('Healing') ||
      name.includes('Accupuncture') ||
      name.includes('Massage') ||
      name.includes('Holistic') ||
      name.includes('Natural Medicine') ||
      name.includes('Oriental Medicine') ||
      name.includes('Chinese Medicine') ||
      name.includes('Integrative Medicine') ||
      name.includes('Naturopathic Medicine') ||
      name.includes('Alternative Medicine')
    ) {
      schoolType = 'Alternative Medicine';
    } else if (
      name.includes('Medical') ||
      name.includes('Medicine') ||
      name.includes('Health') ||
      name.includes('Pharmacy')
    ) {
      schoolType = 'Medical';
    } else if (
      (name.includes('Art') ||
        name.includes('Arts') ||
        name.includes('Design') ||
        name.includes('Theatre') ||
        name.includes('Theater') ||
        name.includes('Conservatory') ||
        name.includes('Music')) &&
      !(name.includes('Arts and Science') || name.includes('Science and Art'))
    ) {
      schoolType = 'Arts';
    } else if (
      name.includes('Divinity') ||
      name.includes('Theological') ||
      name.includes('Theology') ||
      name.includes('Seminary') ||
      name.includes('Rabbinical') ||
      name.includes('Talmudical') ||
      name.includes('Teologica') ||
      name.includes('Torah')
    ) {
      schoolType = 'Divinity';
    } else if (
      name.includes('Technical College') ||
      name.includes('Technical Institute') ||
      name.includes('Technical School') ||
      name.includes('Technology Center') ||
      name.includes('Career') ||
      name.includes('Trade') ||
      name.includes('Business') ||
      name.includes('Training')
    ) {
      schoolType = 'Technical College';
    } else if (name.includes('University')) {
      schoolType = 'University';
    } else if (name.includes('College')) {
      schoolType = 'College';
    } else if (name.includes('School')) {
      schoolType = 'School';
    } else {
      schoolType = 'Unknown';
    }

    const [streetNum, ...streetPieces] = streetWithNum.split(' ');
    const street = streetPieces.join(' ');
    const locationCoords = point([lon ?? 0, lat ?? 0]);
    const transformFactor = locationId % 5.5;
    const bufferedPoint = transformTranslate(locationCoords, transformFactor * 0.03, 35, {
      units: 'kilometers',
    });
    locs.push({
      coordinates: [lon ?? 0, lat ?? 0],
      name,
      id: `loc-${locationId}`,
      address: {
        houseNumber: streetNum,
        street,
        city,
        state,
        stateCode: state,
        postCode,
        county,
        country: 'United States',
        countryCode: 'US',
        formattedAddress: `${streetNum} ${street}, ${city}, ${state}, ${postCode}`,
        addressDetails: {
          accuracy: chance.pickone([
            'rooftop',
            'point',
            'parcel',
            'interpolated',
            'approximate',
            'street',
          ]),
        },
      },
      buildingsCount: transformFactor < 3.5 ? 1 : Math.round(transformFactor),
      group: schoolType,
      type: localeCodeDict?.[locale] ?? 'Unknown', // the value is described by enum Locale.
      locationAddress: `${streetNum} ${street.toLowerCase()}, ${city.toUpperCase()}, ${county.toLowerCase()} ${state}-${postCode}`,
      locationCoordinates: bufferedPoint.geometry.coordinates,
    } as LocationProps);
  });
  return locs;
};

export default makeMockLocationData;
