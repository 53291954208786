import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Theme, Tooltip, Typography } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';

import GlassButton from '../../../CommonComponents/GlassButton/GlassButton';

const useStyles = makeStyles((theme: Theme) => ({
  pickerButtonHoverTooltip: {
    minWidth: 204,
    padding: theme.spacing(1, 4),
    margin: 0,
  },
  pickerButtonHoverPopper: {
    '&.MuiTooltip-popper .MuiTooltip-tooltip': {
      marginTop: theme.spacing(1),
    },
  },
}));

const PickerButtonFC: React.FC<{
  isSettingsOpen: boolean;
  onButtonClick: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  name: string;
  hoverDropdown?: React.ReactElement;
  buttonText?: string;
  debugForceHoverMenuOpen?: boolean;
}> = ({
  isSettingsOpen,
  onButtonClick,
  name,
  hoverDropdown,
  buttonText,
  debugForceHoverMenuOpen,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [isButtonHovered, setIsButtonHovered] = React.useState(debugForceHoverMenuOpen ?? false);

  const handleCloseTooltip = React.useCallback(() => {
    setIsButtonHovered(false);
  }, [setIsButtonHovered]);

  const handleOpenTooltip = React.useCallback(() => {
    setIsButtonHovered(true);
  }, [setIsButtonHovered]);

  const button = (
    <GlassButton
      name={`${name}-button`}
      onClick={onButtonClick}
      endIcon={
        isSettingsOpen ? (
          <ExpandLessIcon
            id={`${name}-button-icon-expandless`}
            titleAccess={t(`${name}:closeSettings`)}
          />
        ) : (
          <ExpandMoreIcon
            id={`${name}-button-icon-expandmore`}
            titleAccess={t(`${name}:openSettings`)}
          />
        )
      }
      data-test-id={`${name}-picker-button`}
    >
      <Typography variant="caption">
        <span className="u-marginright--16" data-test-id="picker-button-maintext">
          {t(`buttons:${name}`)}
        </span>
        {buttonText && <span data-test-id="picker-button-subtext">{buttonText}</span>}
      </Typography>
    </GlassButton>
  );

  if (!hoverDropdown) {
    return button;
  }

  return (
    <Tooltip
      open={hoverDropdown && !isSettingsOpen && isButtonHovered}
      onClose={handleCloseTooltip}
      onOpen={handleOpenTooltip}
      placement="bottom-start"
      title={hoverDropdown}
      classes={{
        tooltip: classes.pickerButtonHoverTooltip,
        popper: classes.pickerButtonHoverPopper,
      }}
      id={`${name}-button-hover`}
      data-test-id={`${name}-button-hover`}
    >
      {button}
    </Tooltip>
  );
};
const PickerButton = React.memo(PickerButtonFC);
PickerButton.displayName = 'PickerButton';
export default PickerButton;
