import * as React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { SettingsSidebarCategory } from 'onec-types';
import { useTranslation } from 'react-i18next';

import { Button, CardContent, IconButton, Theme, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@mui/styles';

import {
  DARKBLUE,
  DEMO_ACCOUNT_SUFFIX,
  GLASS_BG,
  OVERFLOW_Y,
  SETTINGS_SIDEBAR_CATEGORIES,
} from '../../../../util/productGlobals';
import {
  useGetCurrentUserQuery,
  useGetOrganizationQuery,
  UserRole,
} from '../../../../__generated__/graphql';
import { App } from '../../../../PlanningApp/AppConfig';
import Icon from '../../../CommonComponents/Icon/Icon';
import LocationMgmtList from '../../LocationMatch/LocationMgmtList';
import ManageLocationsContent from './ManageLocations/ManageLocations';
import SettingsSidebar from './SettingsSidebar';
import Spinner from '../../../CommonComponents/Spinner/Spinner';
import useApiErrSnackbar from '../../../CommonComponents/Snackbar/useApiErrSnackbar';
import UserManagementContent from './UserManagement/UserManagement';
import UserProfile from './UserProfile/UserProfile';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
    margin: 0,
    padding: 0,
    height: '100%',
  },
  articleContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%',
    '& article': {
      maxWidth: '900px',
    },
    backgroundColor: DARKBLUE,
  },
  scrollContainer: {
    flexGrow: 1,
    marginLeft: theme.spacing(18.5),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(4),
    paddingRight: theme.spacing(16),
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: GLASS_BG,
    },
  },
  closeContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(4),
  },
  closeButton: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(248, 247, 242, 0.1)',
    },
    '&:active': {
      backgroundColor: 'rgba(248, 247, 242, 0.2)',
    },
  },
  seperator: {
    marginTop: theme.spacing(2),
    borderTop: '0.5px solid rgba(248, 247, 242, 0.1)',
  },
  backIcon: {
    minWidth: 36,
    padding: 0,
    marginRight: 16,
  },
  backArrowIcon: {
    margin: '0px !important',
  },
}));

const SettingsContent: React.FC<{ path: string }> = ({ path }) => {
  const {
    backArrowIcon,
    backIcon,
    container,
    articleContainer,
    scrollContainer,
    closeContainer,
    closeButton,
    seperator,
  } = useStyles();
  const { t } = useTranslation();
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();

  const [pathRoot, subPath] = path.split('/');

  const navigate = useNavigate();
  const pathCategory = SETTINGS_SIDEBAR_CATEGORIES.find(
    (category: SettingsSidebarCategory) => category.name.toLowerCase() === pathRoot,
  );

  const { handleClose } = useOutletContext() as { handleClose: () => void };

  const { data: userData, loading: userLoading, error: userError } = useGetCurrentUserQuery();
  const {
    data: organizationData,
    loading: organizationLoading,
    error: organizationError,
  } = useGetOrganizationQuery();
  const isAdmin = userData?.user?.role === UserRole.Admin;
  const isViewer = userData?.user?.role === UserRole.Viewer;
  const userId = userData?.user?.personalInfo?.contact?.email;
  const isDemoUser = userId?.endsWith(DEMO_ACCOUNT_SUFFIX);

  React.useEffect(() => {
    if (pathCategory?.name === SETTINGS_SIDEBAR_CATEGORIES[0].name)
      document.title = t('windowProfileTitle');
    else if (pathCategory?.name === SETTINGS_SIDEBAR_CATEGORIES[1].name)
      document.title = t('windowUserManagementTitle');
    else if (pathCategory?.name === SETTINGS_SIDEBAR_CATEGORIES[2].name)
      document.title = t('windowManageLocsTitle');
  }, [pathCategory, t]);

  const handleClick = React.useCallback(
    (e: React.MouseEvent) => {
      if (e.currentTarget instanceof HTMLElement) {
        const { category } = e.currentTarget.dataset;
        navigate(`/${category.toLowerCase()}`);
      }
    },
    [navigate],
  );

  const goBackToManageLocations = React.useCallback(() => {
    navigate('/locations');
  }, [navigate]);

  React.useEffect(() => {
    if (userError) {
      enqueueApiErrSnackbar();
      App.error('[SettingsContent] - GetCurrentUser error: ', userError);
    }
  }, [userError, enqueueApiErrSnackbar]);

  React.useEffect(() => {
    if (organizationError) {
      enqueueApiErrSnackbar();
      App.error('[SettingsContent] - GetOrganization error: ', organizationError);
    }
  }, [organizationError, enqueueApiErrSnackbar]);

  if (userLoading || organizationLoading) return <Spinner />;
  if (pathCategory) {
    return (
      <CardContent className={container} data-test-id="SettingsContent-container">
        <SettingsSidebar
          activeCategory={pathCategory}
          handleClick={handleClick}
          categories={SETTINGS_SIDEBAR_CATEGORIES.filter(
            (category: SettingsSidebarCategory) =>
              (isAdmin || category.name !== 'userManagement') &&
              (!isViewer || category.name !== 'locations') &&
              (!isDemoUser || category.name !== 'locations'),
          )}
          user={userData?.user}
          organization={organizationData?.organization}
        />

        <div className={articleContainer} data-test-id="SettingsContent-articleContainer">
          <div className={closeContainer} data-test-id="SettingsContent-closeButtonContainer">
            <IconButton
              className={closeButton}
              onClick={handleClose}
              data-test-id="SettingsContent-closeButton"
            >
              <Icon name="close" variant="white" />
            </IconButton>
          </div>

          {(isAdmin || pathCategory.name !== 'userManagement') && (
            <div
              className={`${scrollContainer} ${OVERFLOW_Y} o-flexcol-container`}
              data-test-id="Settings-content"
              id="SettingsPanel"
            >
              <div className="o-flex-vcenter-container">
                {pathCategory.name === SETTINGS_SIDEBAR_CATEGORIES[2].name && subPath === 'list' && (
                  <Button
                    className={`u-clickable ${backIcon}`}
                    startIcon={<ArrowBackIcon fontSize="large" />}
                    size="large"
                    classes={{
                      startIcon: backArrowIcon,
                    }}
                    onClick={goBackToManageLocations}
                  />
                )}
                <Typography variant="h2">
                  {t(`settings:sections:${pathCategory.name}:title`)}
                </Typography>
              </div>
              <div className={seperator} />
              {pathCategory.name === SETTINGS_SIDEBAR_CATEGORIES[0].name && (
                <UserProfile user={userData?.user} />
              )}
              {pathCategory.name === SETTINGS_SIDEBAR_CATEGORIES[1].name && (
                <UserManagementContent user={userData?.user} />
              )}
              {pathCategory.name === SETTINGS_SIDEBAR_CATEGORIES[2].name && !subPath && (
                <ManageLocationsContent />
              )}
              {pathCategory.name === SETTINGS_SIDEBAR_CATEGORIES[2].name && subPath === 'list' && (
                <LocationMgmtList />
              )}
            </div>
          )}
        </div>
      </CardContent>
    );
  }
  App.error('[SettingsContent] - Invalid Category');
  return null;
};

export default SettingsContent;
