import * as React from 'react';
import { useParams } from 'react-router-dom';

import { Grid, Skeleton, Theme, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  BLUEGRAY,
  GLASS_BG_MED,
  PURPLE,
  SIDE_PANEL_WIDTH,
  WHITE,
} from '../../../util/productGlobals';
import { calcVulScoreFromResStats, isLocVulnerable } from '../../../util/vulnerability';
import {
  ResilienceThresholds,
  useGetSliceIdxQuery,
  useGetThresholdsQuery,
} from '../../../__generated__/graphql';
import { App } from '../../../PlanningApp/AppConfig';
import { getDisplayableAddress } from '../../../util/addresses/addressUtils';
import useApiErrSnackbar from '../../CommonComponents/Snackbar/useApiErrSnackbar';
import useGetLocOrBobj from '../../../Hooks/useGetLocOrBobj';
import useGetResStats from '../../../Hooks/useGetResStats';
import useLifelineEntitlements from '../../../Hooks/useLifelineEntitlements';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'absolute',
    width: '100%',
  },
  title: {
    lineHeight: 1.25,
    marginBottom: 8,
  },
  toolbar: {
    zIndex: 3,
    margin: theme.spacing(2),
    marginLeft: 'auto',
    backgroundColor: GLASS_BG_MED,
    borderRadius: theme.spacing(1.5),
    backdropFilter: 'blur(18px)',
    width: `${SIDE_PANEL_WIDTH}px`,
    height: 'auto',
    padding: theme.spacing(4, 4, 4, 2),
    alignItems: 'flex-start',
  },
  body: {
    width: '330px',
    marginLeft: '12px',
  },
  vulnerableChip: {
    width: 32,
    height: 32,
    justifyContent: 'center',
    position: 'relative',
  },
  vulnerableInner: {
    background: WHITE,
    border: `1px solid ${PURPLE}`,
    boxSizing: 'border-box',
    boxShadow: '0px 0px 20px 2px rgba(255, 67, 214, 0.75)',
    borderRadius: '50%',
    width: 10,
    height: 10,
    zIndex: 1,
  },
  vulnerableInnerHighlight: {
    width: 16,
    height: 16,
    background:
      'radial-gradient(46.78% 46.78% at 50% 50%, rgba(178, 0, 253, 0.8) 0%, rgba(178, 0, 253, 0) 100%)',
    opacity: 0.2,
    borderRadius: 46,
    position: 'absolute',
    top: 8,
    left: 8,
  },
  invulnerableInner: {
    background: BLUEGRAY,
    border: `0.5px solid ${WHITE}`,
    boxSizing: 'border-box',
    borderRadius: '50%',
    width: 14,
    height: 14,
  },
  addressText: {
    lineHeight: '20px',
  },
}));

const LocationBarSkeleton: React.FC = () => {
  const { container, title, toolbar, body, addressText } = useStyles();
  return (
    <div className={container} data-test-id="LocationBarSkeleton">
      <Toolbar className={`${toolbar} o-flex-container`} data-test-id="location-info-nav-skeleton">
        <Grid item className={body}>
          <Typography className={title} variant="h2">
            <Skeleton />
          </Typography>
          <Typography className={addressText} variant="body1">
            <Skeleton />
          </Typography>
          <Typography className={addressText} variant="body1">
            <Skeleton />
          </Typography>
        </Grid>
      </Toolbar>
    </div>
  );
};
LocationBarSkeleton.displayName = 'LocationBarSkeleton';

const LocationBar: React.FC = () => {
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();
  const { id } = useParams<{ id: string }>();
  const {
    container,
    title,
    toolbar,
    body,
    vulnerableChip,
    vulnerableInner,
    vulnerableInnerHighlight,
    invulnerableInner,
    addressText,
  } = useStyles();
  const entitlements = useLifelineEntitlements();

  const {
    data: {
      planView: { sliceIdx },
    },
  } = useGetSliceIdxQuery();

  const {
    data: userData,
    error: thresholdsError,
    loading: thresholdsLoading,
  } = useGetThresholdsQuery();
  const thresholds: ResilienceThresholds = userData?.user?.productSettings?.thresholds;

  const {
    locOrBobj,
    error: locOrBobjErr,
    loading: locOrBobjLoading,
  } = useGetLocOrBobj(
    id,
    true, // have the useGetLocOrBobj hook display error messages in snackbars
  );

  const { resStats, loading: resStatsLoading, error: resStatsErr } = useGetResStats(id, [sliceIdx]);

  const err = thresholdsError || locOrBobjErr || resStatsErr;
  React.useEffect(() => {
    if (err) {
      enqueueApiErrSnackbar();
      if (thresholdsError) {
        App.error('[LocationBar] - GetThresholdsQuery error: ', thresholdsError);
      }
      if (locOrBobjErr) {
        App.error('[LocationBar] - useGetLocOrBobj error: ', locOrBobjErr);
      }
      if (resStatsErr) {
        App.error('[LocationBar] - useGetResStats error: ', resStatsErr);
      }
    }
  }, [enqueueApiErrSnackbar, err, locOrBobjErr, resStatsErr, thresholdsError]);

  if (thresholdsLoading || locOrBobjLoading || resStatsLoading || err) {
    return <LocationBarSkeleton />;
  }

  if (resStats && locOrBobj) {
    const { name, address } = locOrBobj;
    const [aLine1, aLine2] = getDisplayableAddress(address);

    const vulnerabilityScore = calcVulScoreFromResStats(resStats[0], thresholds, entitlements);
    const isVulnerable = isLocVulnerable(vulnerabilityScore);

    return (
      <div className={container} id="LocationBar">
        <Toolbar className={`${toolbar} o-flex-container`} data-test-id="location-info-nav">
          <div
            className={`${vulnerableChip} o-flex-vcenter-container`}
            data-test-id={isVulnerable ? 'location-vulnerable-chip' : 'location-stable-chip'}
          >
            <div className={isVulnerable ? vulnerableInner : invulnerableInner} />
            {isVulnerable && <div className={vulnerableInnerHighlight} />}
          </div>
          <Grid item className={body}>
            <Typography
              className={title}
              variant="h2"
              color="textPrimary"
              data-test-id="location-info-name"
              noWrap
            >
              {name}
            </Typography>
            {aLine1 && (
              <Typography
                className={addressText}
                variant="body1"
                color="textPrimary"
                data-test-id="location-info-address-line1"
                noWrap
              >
                {aLine1}
              </Typography>
            )}
            {aLine2 && (
              <Typography
                className={addressText}
                variant="body1"
                color="textPrimary"
                data-test-id="location-info-address-line2"
                noWrap
              >
                {aLine2}
              </Typography>
            )}
          </Grid>
        </Toolbar>
      </div>
    );
  }

  return null;
};

LocationBar.displayName = 'LocationBar';
export default LocationBar;
