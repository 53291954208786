import { createTheme } from '@mui/material/styles';

import {
  BLACK,
  BLUE,
  BLUEGRAY,
  BLURRY,
  BORDER_RADIUS,
  BUTTON_DISABLE_OPACITY,
  DARKBLUE,
  DARKBLUE2,
  GLASS_BG,
  GLASS_BG_LIGHT,
  GLASS_BG_MED,
  GRAY3,
  GREEN,
  GREEN_DARK_TRANSPARENT,
  LIST_HIGHLIGHT,
  ORANGE,
  ORANGE_DARK_TRANSPARENT,
  ORANGEDARK,
  ORANGELIGHT,
  PURPLE,
  PURPLE_DARK_TRANSPARENT,
  QUIET_BG,
  QUIET_DARK,
  QUIET_LIGHT,
  RED,
  RED_DARK_TRANSPARENT,
  WHITE,
} from '../../util/productGlobals';
import { hexToRgba } from '../../util/utils';

const materialDefaultTheme = createTheme();
const { pxToRem, fontSize: defaultFontSize } = materialDefaultTheme.typography;

const globalTheme = createTheme({
  typography: {
    fontFamily: 'inherit',
    fontSize: defaultFontSize,
    h1: {
      fontSize: pxToRem(32),
      fontWeight: 500,
      lineHeight: pxToRem(48),
    },
    h2: {
      fontSize: pxToRem(24),
      fontWeight: 700,
      lineHeight: pxToRem(40),
    },
    h3: {
      fontSize: pxToRem(20),
      fontWeight: 500,
      lineHeight: pxToRem(30),
    },
    h4: {
      fontSize: pxToRem(18),
      fontWeight: 700,
      lineHeight: pxToRem(26),
    },
    h5: {
      fontSize: pxToRem(18),
      fontWeight: 400,
      lineHeight: pxToRem(26),
    },
    body1: {
      fontSize: pxToRem(16),
      fontWeight: 400,
      lineHeight: pxToRem(22),
    },
    body2: {
      fontSize: pxToRem(14),
      fontWeight: 500,
      lineHeight: pxToRem(20),
    },
    caption: {
      fontSize: pxToRem(12),
      fontWeight: 500,
      lineHeight: pxToRem(18),
    },
    subtitle1: {
      fontSize: pxToRem(14),
      fontWeight: 400,
      lineHeight: pxToRem(18),
    },
    overline: {
      fontSize: '0.75rem', // 12px
      fontWeight: 800,
      lineHeight: 1.43, // 20px
    },
    button: {
      fontSize: '0.875rem', // 14px
      fontWeight: 500,
      lineHeight: 1.43, // 20px
      textTransform: 'capitalize',
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: ORANGE,
      light: ORANGELIGHT,
      dark: ORANGEDARK,
      // primary contrastText can't be specified here. it is auto-calculated to contrast
      // with palette.primary.main
    },
    secondary: {
      main: PURPLE,
      dark: PURPLE_DARK_TRANSPARENT,
    },
    error: {
      main: RED,
      dark: RED_DARK_TRANSPARENT,
    },
    warning: {
      main: ORANGE,
      dark: ORANGE_DARK_TRANSPARENT,
    },
    success: {
      main: GREEN,
      dark: GREEN_DARK_TRANSPARENT,
    },
    info: {
      main: BLUE,
      dark: LIST_HIGHLIGHT,
    },
    glass: {
      main: GLASS_BG,
      light: GLASS_BG_LIGHT,
      dark: GLASS_BG,
      contrastText: WHITE,
    },
    quiet: {
      main: QUIET_BG,
      light: QUIET_LIGHT,
      dark: QUIET_DARK,
      contrastText: WHITE,
    },
    text: {
      primary: WHITE,
      secondary: GRAY3,
    },
    action: {
      active: LIST_HIGHLIGHT, // used by switch and MuiIconButton-root
      activatedOpacity: 0.5,
      disabledOpacity: 0.5, // applies to disabled buttons
      focus: '#FF0000',
      focusOpacity: 0.5,
      hover: LIST_HIGHLIGHT, // used for hover in the location search autocomplete list
      hoverOpacity: 0.5,
      selected: LIST_HIGHLIGHT, // used for hover in the listitems in VLLC
      selectedOpacity: 0.5,
    },
    background: {
      paper: GLASS_BG,
      default: GLASS_BG,
    },
  },
  components: {
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // No more button ripple, for the entire application
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
    },
    MuiPopover: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiSwitch: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  spacing: 4,
});

const overrides = {
  MuiAutocomplete: {
    styleOverrides: {
      clearIndicatorDirty: {
        '&.MuiIconButton-root': {
          backgroundColor: 'transparent',
        },
      },
      clearIndicator: {
        color: BLACK,
      },
      inputRoot: {
        '&.MuiFilledInput-root': {
          backgroundColor: DARKBLUE,
          paddingTop: 0,
          lineHeight: '22px',
          '& .MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)': {
            marginTop: 0,
          },
        },
      },
      listbox: {
        borderRadius: BORDER_RADIUS,
        maxHeight: '70vh',
        padding: 0,
      },
      option: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginRight: 0,
        marginLeft: 0,
        '&[data-focus="true"]': {
          backgroundColor: 'unset',
        },
        '&:last-of-type': {
          '& > div': {
            borderBottom: 'none',
          },
        },
      },
      paper: {
        borderRadius: BORDER_RADIUS,
        backgroundColor: 'transparent',
        marginBottom: 0,
      },
      popper: {
        backgroundColor: GLASS_BG,
        backdropFilter: BLURRY,
        borderRadius: BORDER_RADIUS,
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      colorDefault: {
        backgroundColor: globalTheme.palette.secondary.main,
        width: 30,
        height: 30,
        fontSize: globalTheme.typography.button.fontSize,
        color: '#000000',
        fontWeight: 500,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 4, // TODO - But the filter bar buttons need 6, and icon buttons need 5?
        minWidth: 32, // minimum size for icon buttons, which are the smallest
        height: 36,
        whiteSpace: 'nowrap',
        textTransform: 'unset',
      },
      textSizeSmall: {
        height: 32,
        paddingLeft: globalTheme.spacing(4),
        paddingRight: globalTheme.spacing(4),
      },
      sizeSmall: {
        height: 32,
        paddingLeft: globalTheme.spacing(4),
        paddingRight: globalTheme.spacing(4),
      },
      text: {
        padding: globalTheme.spacing(2, 4),
        whiteSpace: 'nowrap',
        '&:hover': {
          backgroundColor: hexToRgba(WHITE, 0.1),
        },
      },
      outlined: {
        padding: globalTheme.spacing(2, 4),
      },
      contained: {
        padding: globalTheme.spacing(2, 4),
      },
      containedPrimary: {
        color: DARKBLUE2,
        transition: 'color .05s ease-out',
        '&:hover': {
          color: WHITE,
          backgroundColor: globalTheme.palette.primary.main,
        },
        '&:focus': {
          color: DARKBLUE2,
          backgroundColor: globalTheme.palette.primary.light,
          transition: 'color .05s ease-out .15s, backgroundColor .05s ease-out .15s',
        },
        '&:active': {
          color: WHITE,
          backgroundColor: globalTheme.palette.primary.dark,
        },
        '&:disabled': {
          color: DARKBLUE2,
          backgroundColor: globalTheme.palette.primary.main,
          opacity: BUTTON_DISABLE_OPACITY,
        },
      },
      outlinedPrimary: {
        color: globalTheme.palette.primary.main,
        border: `1px solid ${globalTheme.palette.primary.main}`,
        transition: 'color .2s ease-out, backgroundColor .2s ease-out',
        '&:hover': {
          color: WHITE,
          backgroundColor: globalTheme.palette.primary.main,
        },
        '&:focus': {
          color: DARKBLUE2,
          backgroundColor: globalTheme.palette.primary.light,
        },
        '&:active': {
          color: WHITE,
          backgroundColor: globalTheme.palette.primary.dark,
          border: `1px solid ${globalTheme.palette.primary.dark}`,
        },
        '&:disabled': {
          color: globalTheme.palette.primary.main,
          backgroundColor: 'transparent',
          border: `1px solid ${globalTheme.palette.primary.main}`,
          opacity: BUTTON_DISABLE_OPACITY,
        },
      },
      textPrimary: {
        backgroundColor: 'transparent',
        color: globalTheme.palette.quiet.contrastText,
        transition: 'backgroundColor .2s ease-out',
        '&:hover': {
          backgroundColor: globalTheme.palette.quiet.main,
        },
        '&:focus': {
          backgroundColor: globalTheme.palette.quiet.main,
        },
        '&.Mui-focusVisible': {
          backgroundColor: globalTheme.palette.quiet.main,
        },
        '&:active': {
          backgroundColor: globalTheme.palette.quiet.dark,
        },
        '&:disabled': {
          color: globalTheme.palette.quiet.contrastText,
          backgroundColor: 'transparent',
          opacity: BUTTON_DISABLE_OPACITY,
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: hexToRgba(WHITE, 0.1),
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        backgroundColor: GLASS_BG_MED,
        padding: globalTheme.spacing(2),
        backdropFilter: 'blur(16px)',
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        '&:last-child': {
          paddingBottom: globalTheme.spacing(0),
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        backgroundColor: LIST_HIGHLIGHT,
        paddingLeft: globalTheme.spacing(2),
        paddingRight: globalTheme.spacing(2),
      },
      sizeSmall: {
        height: globalTheme.typography.pxToRem(26),
      },
      label: {
        fontSize: globalTheme.typography.caption.fontSize,
      },
      outlined: {
        backdropFilter: 'blur(4px)',
      },
      outlinedSecondary: {
        color: WHITE,
        backgroundColor: PURPLE_DARK_TRANSPARENT,
        backdropFilter: 'blur(4px)',
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        backgroundColor: DARKBLUE2,
        padding: globalTheme.spacing(2),
      },
      paperWidthSm: {
        maxWidth: 450,
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        backgroundColor: '#000000',
        height: '0.5px',
        borderBottomWidth: 'inherit',
      },
      vertical: {
        backgroundColor: BLUEGRAY,
        width: '0.5px',
        height: globalTheme.typography.pxToRem(24),
        marginLeft: globalTheme.spacing(4),
        marginRight: globalTheme.spacing(4),
        opacity: 0.55,
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontSize: globalTheme.typography.body2.fontSize,
        color: globalTheme.palette.text.primary,
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        borderTopLeftRadius: 40,
        borderTopRightRadius: 40,
        borderRadius: 40,
        height: 30,
        border: 0,
        backgroundColor: DARKBLUE2,
      },
      input: {
        margin: 'auto',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: BORDER_RADIUS,
        '&&& $input': {
          padding: '8px 0px',
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        height: globalTheme.typography.pxToRem(36),
      },
      input: {
        fontSize: globalTheme.typography.body2.fontSize,
        opacity: 1,
        '&::-webkit-input-placeholder': {
          color: WHITE,
          opacity: 0.5,
        },
        // For input[type="number"] field, get rid of up/down stepper arrows
        '&::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        // This is needed for Firefox
        '&[type=number]': {
          MozAppearance: 'textfield',
          textAlign: 'right',
        },
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        color: globalTheme.palette.text.secondary,
        fontSize: globalTheme.typography.body2.fontSize,
      },
      filled: {
        '&.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)': {
          marginTop: globalTheme.spacing(2),
        },
      },
    },
  },
  MuiList: {
    styleOverrides: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      button: {
        borderRadius: 6,
      },
      divider: {
        borderBottom: '0.5px solid #000000',
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: WHITE,
        minWidth: 20,
      },
    },
  },
  MuiListSubheader: {
    styleOverrides: {
      root: {
        backgroundColor: BLACK,
        height: globalTheme.typography.pxToRem(26),
        fontSize: globalTheme.typography.body2.fontSize,
        lineHeight: globalTheme.typography.pxToRem(26),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: WHITE,
        '& .MuiTypography-colorTextSecondary': {
          margin: 'auto 0',
        },
      },
      sticky: {
        backgroundColor: BLACK,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        padding: globalTheme.spacing(2, 4),
        fontSize: globalTheme.typography.button.fontSize,
        color: WHITE,
        borderRadius: BORDER_RADIUS,
        '&.Mui-selected': {
          backgroundColor: LIST_HIGHLIGHT,
          '&:hover': {
            backgroundColor: LIST_HIGHLIGHT,
          },
        },
      },
    },
  },

  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundColor: 'transparent',
        backgroundImage: 'none',
      },
      rounded: {
        borderRadius: BORDER_RADIUS,
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      paper: {
        backgroundColor: GLASS_BG,
        backdropFilter: BLURRY,
        margin: globalTheme.spacing(1, 0),
      },
    },
  },
  // MuiSkeleton: {}, // for future loading placeholders
  MuiToolbar: {
    styleOverrides: {
      root: {
        backgroundColor: GLASS_BG,
        backdropFilter: BLURRY,
        borderRadius: BORDER_RADIUS,
        height: 62,
        zIndex: 3,
        margin: globalTheme.spacing(2),
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: globalTheme.palette.glass.main,
        padding: globalTheme.spacing(4),
        borderRadius: BORDER_RADIUS,
        margin: 0,
      },
      popper: {
        backgroundColor: 'transparent',
        backdropFilter: BLURRY,
      },
    },
  },
};

const theme = createTheme(globalTheme, {
  components: overrides,
});

export default theme;
