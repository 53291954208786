import { ApolloCache, NormalizedCacheObject, Resolver } from '@apollo/client';
import { cloneDeep } from 'lodash/fp';
import { LngLatBounds } from 'mapbox-gl';

import { DEFAULT_SLICE_IDX, LOCALSTORAGE_KEY_PREFIX } from '../../../util/productGlobals';
import {
  GetCurrentUserDocument,
  GetCurrentUserQuery,
  GetHoveredLocationDocument,
  GetHoveredLocationQuery,
  GetMapMovedDocument,
  GetMapMovedQuery,
  GetMapViewDocument,
  GetMapViewQuery,
  GetOldMapBoundsDocument,
  GetOldMapBoundsQuery,
  GetPlanViewDocument,
  GetPlanViewQuery,
  GetSliceIndexesDocument,
  GetSliceIndexesQuery,
  MutationUpdateForecastPeriodArgs,
  MutationUpdateHistoricalSeverityArgs,
  MutationUpdateHoveredLocationArgs,
  MutationUpdateLocationsFilterArgs,
  MutationUpdateMapMovedArgs,
  MutationUpdateOldMapBoundsArgs,
  MutationUpdatePlanViewArgs,
  MutationUpdatePreviousMapViewArgs,
  MutationUpdateSelectedClimateArgs,
  PlanView,
  UpdateSelectedHazardMutationVariables,
} from '../../../__generated__/graphql';
import { App } from '../../AppConfig';
import { getActiveSliceIdx } from '../../../Components/ProductComponents/locationViewHelpers';

const deriveSliceIndex: (
  cache: ApolloCache<NormalizedCacheObject>,
  newData: GetPlanViewQuery,
) => number = (cache, newData) => {
  const slices = cache.readQuery<GetSliceIndexesQuery>({ query: GetSliceIndexesDocument });
  const newActiveSliceIdx = slices
    ? getActiveSliceIdx(slices.sliceIndexes, newData.planView.planFilters)
    : DEFAULT_SLICE_IDX; // Maybe just keep with the old value for SliceIdx
  App.debug('[deriveSliceIndex resolver helper] - New sliceIdx: ', newActiveSliceIdx);
  return newActiveSliceIdx;
};

const savePlanViewToLocalStorage = (
  newPlanView: PlanView,
  cache: ApolloCache<NormalizedCacheObject>,
) => {
  const userData = cache.readQuery<GetCurrentUserQuery>({ query: GetCurrentUserDocument });
  const userId = userData?.user?.id ?? 'UNKNOWN_USER';
  const localStorageKey = `${LOCALSTORAGE_KEY_PREFIX}${userId}:planView`;
  localStorage.setItem(localStorageKey, JSON.stringify(newPlanView));
};

export const updatePlanView: Resolver = (
  _: unknown,
  { planView }: MutationUpdatePlanViewArgs,
  { cache }: { cache: ApolloCache<NormalizedCacheObject> },
) => {
  const storeData = cache.readQuery<GetPlanViewQuery>({ query: GetPlanViewDocument });
  const newData = cloneDeep(storeData);

  newData.planView.locFilter = { ...planView.locFilter };
  newData.planView.planFilters = { ...planView.planFilters };
  newData.planView.sliceIdx = deriveSliceIndex(cache, newData);

  cache.writeQuery({ data: newData, query: GetPlanViewDocument });
  savePlanViewToLocalStorage(newData.planView, cache);
  return newData.planView;
};

export const updateSelectedHazard: Resolver = (
  _: unknown,
  { hazardFilter: { type, id } }: UpdateSelectedHazardMutationVariables,
  { cache }: { cache: ApolloCache<NormalizedCacheObject> },
) => {
  const storeData = cache.readQuery<GetPlanViewQuery>({ query: GetPlanViewDocument });
  const newData = cloneDeep(storeData);

  if (id) {
    // Historical event was selected from hazards menu
    newData.planView.planFilters.historicalId = id;
  } else {
    newData.planView.planFilters.hazardType = type;
    newData.planView.planFilters.historicalId = null;
  }

  newData.planView.sliceIdx = deriveSliceIndex(cache, newData);
  cache.writeQuery({ data: newData, query: GetPlanViewDocument });
  savePlanViewToLocalStorage(newData.planView, cache);
  return newData.planView;
};

export const updateSelectedClimate: Resolver = (
  _: unknown,
  { climate }: MutationUpdateSelectedClimateArgs,
  { cache }: { cache: ApolloCache<NormalizedCacheObject> },
) => {
  const storeData = cache.readQuery<GetPlanViewQuery>({ query: GetPlanViewDocument });
  const newData = cloneDeep(storeData);
  newData.planView.planFilters.isClimateChangeEnabled = climate;

  newData.planView.sliceIdx = deriveSliceIndex(cache, newData);

  cache.writeQuery({ data: newData, query: GetPlanViewDocument });
  savePlanViewToLocalStorage(newData.planView, cache);
  return newData.planView;
};

export const updateHistoricalSeverity: Resolver = (
  _: unknown,
  { isHistoricalSeverityWorse }: MutationUpdateHistoricalSeverityArgs,
  { cache }: { cache: ApolloCache<NormalizedCacheObject> },
) => {
  const storeData = cache.readQuery<GetPlanViewQuery>({ query: GetPlanViewDocument });
  const newData = cloneDeep(storeData);
  newData.planView.planFilters.isHistoricalSeverityWorse = isHistoricalSeverityWorse;

  newData.planView.sliceIdx = deriveSliceIndex(cache, newData);

  cache.writeQuery({ data: newData, query: GetPlanViewDocument });
  savePlanViewToLocalStorage(newData.planView, cache);
  return newData.planView;
};

export const updateForecastPeriod: Resolver = (
  _: unknown,
  { forecastPeriod }: MutationUpdateForecastPeriodArgs,
  { cache }: { cache: ApolloCache<NormalizedCacheObject> },
) => {
  const storeData = cache.readQuery<GetPlanViewQuery>({ query: GetPlanViewDocument });
  const newData = cloneDeep(storeData);

  newData.planView.planFilters.forecastPeriod.duration = forecastPeriod.duration;
  newData.planView.planFilters.forecastPeriod.financeDefaultPH = forecastPeriod.financeDefaultPH;
  newData.planView.planFilters.forecastPeriod.financeRP1 = forecastPeriod.financeRP1;
  newData.planView.planFilters.forecastPeriod.financeRP2 = forecastPeriod.financeRP2;
  newData.planView.planFilters.forecastPeriod.forecastPeriodType =
    forecastPeriod.forecastPeriodType;

  newData.planView.sliceIdx = deriveSliceIndex(cache, newData);
  cache.writeQuery({ data: newData, query: GetPlanViewDocument });
  savePlanViewToLocalStorage(newData.planView, cache);
  return newData.planView;
};

export const updateLocationsFilter: Resolver = (
  _: unknown,
  { locFilter }: MutationUpdateLocationsFilterArgs,
  { cache }: { cache: ApolloCache<NormalizedCacheObject> },
) => {
  const storeData = cache.readQuery<GetPlanViewQuery>({ query: GetPlanViewDocument });
  const newData = cloneDeep(storeData);
  newData.planView.locFilter.locTypes = [...locFilter.locTypes];
  newData.planView.locFilter.locGroups = [...locFilter.locGroups];
  cache.writeQuery({ data: newData, query: GetPlanViewDocument });
  savePlanViewToLocalStorage(newData.planView, cache);
  return newData.planView;
};

export const updatePreviousMapView: Resolver = (
  _: unknown,
  { bounds }: MutationUpdatePreviousMapViewArgs,
  { cache }: { cache: ApolloCache<NormalizedCacheObject> },
) => {
  const storeData = cache.readQuery<GetMapViewQuery>({ query: GetMapViewDocument });
  const newData = cloneDeep(storeData);
  const { southEast, northWest } = bounds;
  const checkBounds = new LngLatBounds(
    [southEast.longitude, southEast.latitude],
    [northWest.longitude, northWest.latitude],
  );
  if (checkBounds) {
    newData.previousMapView.southEast = southEast;
    newData.previousMapView.northWest = northWest;
    cache.writeQuery({ data: newData, query: GetMapViewDocument });
    return newData.previousMapView;
  }

  App.error('[updatePreviousMapView] Bad input for map bounds');
  return {
    mapView: newData.previousMapView,
  };
};

export const updateOldMapBounds: Resolver = (
  _: unknown,
  { bounds }: MutationUpdateOldMapBoundsArgs,
  { cache }: { cache: ApolloCache<NormalizedCacheObject> },
) => {
  const storeData = cache.readQuery<GetOldMapBoundsQuery>({ query: GetOldMapBoundsDocument });
  const newData = cloneDeep(storeData);
  const { southEast, northWest } = bounds;
  const checkBounds = new LngLatBounds(
    [southEast.longitude, southEast.latitude],
    [northWest.longitude, northWest.latitude],
  );
  if (checkBounds) {
    newData.oldMapBounds.southEast = southEast;
    newData.oldMapBounds.northWest = northWest;
    cache.writeQuery({ data: newData, query: GetOldMapBoundsDocument });
    return newData.oldMapBounds;
  }

  App.error('[updateOldMapBounds] Bad input for map bounds');
  return {
    oldMapBounds: newData.oldMapBounds,
  };
};

export const updateMapMoved: Resolver = (
  _: unknown,
  { didMapMove }: MutationUpdateMapMovedArgs,
  { cache }: { cache: ApolloCache<NormalizedCacheObject> },
) => {
  const storeData = cache.readQuery<GetMapMovedQuery>({ query: GetMapMovedDocument });
  const newData = cloneDeep(storeData);
  newData.mapMoved.state = didMapMove;

  cache.writeQuery({ data: newData, query: GetMapMovedDocument });
  return newData.mapMoved;
};

export const updateHoveredLocation: Resolver = (
  _: unknown,
  { locationId }: MutationUpdateHoveredLocationArgs,
  { cache }: { cache: ApolloCache<NormalizedCacheObject> },
) => {
  const storeData = cache.readQuery<GetHoveredLocationQuery>({
    query: GetHoveredLocationDocument,
  });
  const newData = cloneDeep(storeData);
  newData.hoveredLocation.hoveredLocationId = locationId;
  cache.writeQuery({ data: newData, query: GetHoveredLocationDocument });
  return newData.hoveredLocation.hoveredLocationId;
};

export const planViewMutations = {
  updateForecastPeriod,
  updateHistoricalSeverity,
  updateHoveredLocation,
  updateLocationsFilter,
  updateMapMoved,
  updateOldMapBounds,
  updatePlanView,
  updatePreviousMapView,
  updateSelectedClimate,
  updateSelectedHazard,
};
