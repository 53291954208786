import { point } from '@turf/helpers';
import transformTranslate from '@turf/transform-translate';

import {
  Airport,
  BasicLifelineAssetStatsArgs,
  Bridge,
  Building,
  BuiltObject,
  HighwaySegment,
  LocationObject,
  Port,
  Subdivision,
  Substation,
} from '../../__generated__/graphql';
import makeMockLifelineStats from './makeMockLifelineStats';
import makeMockSlices from './makeMockSlices';

const makeLifelineMetadata = (type: string, chance: Chance.Chance): any => {
  let result: any = {};
  switch (type) {
    case 'Airport':
      result = {
        yearBuilt: (
          chance.date({ year: chance.integer({ min: 1919, max: 2019 }) }) as Date
        ).toISOString(),
        enplanements: `${chance.integer({ min: 50, max: 1_000_000 })}`,
      };
      break;
    case 'Port':
      result = {
        cranes: `${chance.integer({ min: 10, max: 50 })}`,
        wharves: `${chance.integer({ min: 3, max: 25 })}`,
        yearBuilt: (
          chance.date({ year: chance.integer({ min: 1900, max: 2020 }) }) as Date
        ).toISOString(),
      };
      break;
    case 'Bridge':
      result = {
        yearBuilt: (
          chance.date({ year: chance.integer({ min: 1900, max: 2020 }) }) as Date
        ).toISOString(),
        material: `${chance.pickone([
          'Steel',
          'Concrete',
          'Lumber',
          'Asphalt',
          'Iron',
          'Aluminum',
          'Plastic',
        ])}`,
        bridgeType: `${chance.pickone([
          'Arch',
          'Beam',
          'Cantilever',
          'Suspension',
          'Cable-stayed',
          'Tied-arch',
          'Truss',
        ])}`,
      };
      break;
    case 'Power':
      result = {
        maxVoltage: `${chance.integer({ min: 0, max: 3000 })}`,
      };
      break;
    default:
      break;
  }

  return result;
};

const makeMockLifelineAssets = (
  root: LocationObject | BuiltObject,
  count: number,
  type: string,
  chance: Chance.Chance,
): any[] => {
  const distance = type === 'Subdivision' ? 30 : 50;
  const builtObjectCoords = point(root.coordinates);
  const assets = [];

  for (let i = 0; i < count; i += 1) {
    const coords = transformTranslate(
      builtObjectCoords,
      chance.floating({ min: 0.1, max: 1 }) * distance,
      chance.floating({ min: -180, max: 180 }),
      { units: 'miles' },
    );
    assets.push({
      id: `${root.id}|${type}|${i + 1}`,
      actualID: `${root.id}`,
      ...makeLifelineMetadata(type, chance),
      name: `${type}-${i + 1}`,
      coordinates: coords.geometry.coordinates,
      stats: (
        root: Airport | Port | HighwaySegment | Subdivision | Building | Substation | Bridge,
        args: BasicLifelineAssetStatsArgs,
      ) => {
        let sliceNums: number[] = [];
        if (args?.slices && args?.slices?.length > 0) {
          sliceNums = args?.slices;
        } else {
          sliceNums = makeMockSlices().map((sliceDef) => sliceDef.slice);
        }
        return makeMockLifelineStats(root.id, sliceNums);
      },
    });
  }
  return assets;
};

export default makeMockLifelineAssets;
