import * as React from 'react';
import { Expression, Map as MapboxMap } from 'mapbox-gl';
import { Layer, MapContext } from 'react-mapbox-gl';
import { useParams } from 'react-router-dom';

import {
  ACTIVE_LOCATION_LAYER_ID,
  ALL_LOCATIONS_SOURCE_ID,
  BUILT_OBJECT_SOURCE_ID,
} from '../../../../util/productGlobals';
import useGetBobjWithScore, { BuiltObjectWithScore } from '../../../../Hooks/useGetBobjWithScore';
import { App } from '../../../../PlanningApp/AppConfig';
import BuiltObjectSource from './BuiltObjectSource';
import { clusterLayersConfig } from './clusterHelpers';
import { isLocVulnerable } from '../../../../util/vulnerability';
import { LocationWithScore } from '../../locationViewHelpers';
import MapFlyTo from './MapFlyTo';
import useHasFinanceEntitlement from '../../../../Hooks/useHasFinanceEntitlement';

const ActiveLayers: React.FC<{
  layerId: string;
  sourceId: string;
  pointFilterExpr?: Expression;
  haloFilterExpr?: Expression;
  isVulnerable: boolean;
  isVisible: boolean;
  before?: string;
}> = ({ sourceId, layerId, pointFilterExpr, haloFilterExpr, isVulnerable, isVisible, before }) => {
  const { locationMarker: locConfig, halo: haloConfig } =
    clusterLayersConfig[isVulnerable ? 'vulnerable' : 'notVulnerable'];

  return (
    <>
      <Layer
        id={`${layerId}-halo`}
        sourceId={sourceId}
        filter={haloFilterExpr} // NULL if builtObject
        type="circle"
        paint={{
          'circle-radius': haloConfig.haloRadiusSelected,
          'circle-blur': 0.57,
          'circle-color': haloConfig.haloColor,
          'circle-opacity': haloConfig.showHalo ? 0.5 : 0,
        }}
        layout={{
          visibility: isVisible ? 'visible' : 'none',
        }}
        before={layerId}
      />
      <Layer
        id={layerId}
        sourceId={sourceId}
        filter={pointFilterExpr} //  NULL if builtObject
        type="circle"
        paint={{
          'circle-radius': locConfig.radius - locConfig.borderWidthSelected,
          'circle-stroke-width': locConfig.borderWidthSelected,
          'circle-stroke-color': locConfig.borderColorSelected ?? locConfig.borderColor,
          'circle-color': locConfig.backgroundColorSelected,
        }}
        layout={{
          visibility: isVisible ? 'visible' : 'none',
        }}
        before={before}
      />
    </>
  );
};

type ViewType = 'listView' | 'locationDetail' | 'builtObjectDetail';

const ActiveLocBobjLayers: React.FC<{
  activeLocationWithScore: LocationWithScore;
  locsAreLoading: boolean;
}> = ({ activeLocationWithScore, locsAreLoading }) => {
  const { id: urlActiveId } = useParams();
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;
  let viewType: ViewType;

  if (!urlActiveId) {
    viewType = 'listView';
  } else if (
    activeLocationWithScore?.id === urlActiveId ||
    (locsAreLoading && !activeLocationWithScore)
  ) {
    viewType = 'locationDetail';
  } else {
    viewType = 'builtObjectDetail';
    App.assert(!activeLocationWithScore && !locsAreLoading);
  }

  const {
    builtObjectWithScore,
    loading: bobjLoading,
    error: bobjError,
  } = useGetBobjWithScore({
    id: urlActiveId,
    skip: viewType !== 'builtObjectDetail',
  });

  if (bobjError) {
    // The error is probably because the builtObject in the URL is not valid.
    App.error('[ActiveLocBobjLayers] - invalid builtObjectId');
    // do not have to enqueue a snackbar because the VulnerableLocationList will do it
    return null;
  }

  let locOrBobj: LocationWithScore | BuiltObjectWithScore;

  switch (viewType) {
    case 'locationDetail':
      locOrBobj = activeLocationWithScore;
      break;
    case 'builtObjectDetail':
      if (!bobjLoading && builtObjectWithScore) {
        App.assert(builtObjectWithScore?.id === urlActiveId);
      }
      locOrBobj = builtObjectWithScore;
      break;
    default:
      //  'listView'
      break;
  }

  const isVulnerable = isFinanceEntitled
    ? false
    : isLocVulnerable(locOrBobj?.vulnerabilityScore ?? 0);

  // The ALL_LOCATIONS_SOURCE contains features for all locations, so we need the following
  // filter expressions to only display the feature corresponding to the active location
  const locationFilterExpr: Expression = [
    'all',
    !!activeLocationWithScore,
    ['==', ['get', 'id'], activeLocationWithScore?.id ?? null],
  ];
  const locationHaloFilterExpr: Expression = [...locationFilterExpr, isVulnerable];

  return (
    <>
      <BuiltObjectSource builtObject={builtObjectWithScore} />

      <ActiveLayers
        sourceId={BUILT_OBJECT_SOURCE_ID}
        layerId={BUILT_OBJECT_SOURCE_ID}
        isVulnerable={isFinanceEntitled ? false : locOrBobj?.vulnerabilityScore > 0}
        isVisible={!locsAreLoading && viewType === 'builtObjectDetail'}
      />

      <ActiveLayers
        sourceId={ALL_LOCATIONS_SOURCE_ID}
        layerId={ACTIVE_LOCATION_LAYER_ID}
        pointFilterExpr={locationFilterExpr}
        haloFilterExpr={locationHaloFilterExpr}
        isVulnerable={isFinanceEntitled ? false : locOrBobj?.vulnerabilityScore > 0}
        isVisible={!locsAreLoading && viewType === 'locationDetail'}
      />

      {viewType !== 'listView' && locOrBobj && (
        <MapContext.Consumer>
          {(map: MapboxMap) => {
            return (
              <MapFlyTo
                mapboxMap={map}
                locOrBobjId={locOrBobj?.id}
                coordinates={locOrBobj?.coordinates as [number, number]}
                zoom={13}
              />
            );
          }}
        </MapContext.Consumer>
      )}
    </>
  );
};

ActiveLocBobjLayers.displayName = 'ActiveLocBobjLayers';
export default ActiveLocBobjLayers;
