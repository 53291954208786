import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  EditAddressInput,
  GetLocationsFileDetailsDocument,
  GetLocationsInfoWithFilterDocument,
  GetLocationsWithFilterDocument,
  GetOriginalLocationsFileRecordDocument,
  useGetCurrentUserQuery,
  useGetLocationsFileDetailsQuery,
  useGetReverseGeocodeAddressQuery,
  useGetSliceIdxQuery,
  useSubmitMibRequestMutation,
} from '../../../__generated__/graphql';
import { AMP_EVENT_REQUEST_LOCATION } from '../../../plugins/amplitudeevents';
import ConfirmDialog from '../../CommonComponents/ConfirmDialog/ConfirmDialog';
import { sendAmplitudeData } from '../../../plugins/amplitude';
import useApiErrSnackbar from '../../CommonComponents/Snackbar/useApiErrSnackbar';

type MIBRequestDialogProps = {
  open: boolean;
  onClose: () => void;
  markerCoords: number[];
  locationID: string;
  lineNumber: number;
};

const MIBRequestDialog = ({
  open,
  onClose,
  markerCoords,
  locationID,
  lineNumber,
}: MIBRequestDialogProps) => {
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();
  const { t } = useTranslation();
  const { data: userData } = useGetCurrentUserQuery();
  const { data: userLocationsData } = useGetLocationsFileDetailsQuery();
  const {
    data: reverseGeocodeData,
    error: reverseGeocodeError,
    loading: reverseGeocodeLoading,
  } = useGetReverseGeocodeAddressQuery({
    variables: {
      coords: markerCoords,
    },
  });

  const {
    data: { planView },
  } = useGetSliceIdxQuery();

  const [submitMIBRequest] = useSubmitMibRequestMutation({
    refetchQueries: [
      // Update the upload file record for this location to reflect pending status
      { query: GetOriginalLocationsFileRecordDocument, variables: { locationID } },
      {
        query: GetLocationsWithFilterDocument,
        variables: {
          sliceIdx: planView?.sliceIdx,
          input: {
            matchCriteria: {
              isPendingMIB: false,
            },
          },
        },
      },
      {
        query: GetLocationsInfoWithFilterDocument,
        variables: {
          input: {
            matchCriteria: {
              isPendingMIB: false,
            },
          },
        },
      },
      {
        query: GetLocationsFileDetailsDocument,
      },
    ],
    onCompleted: (data) => {
      const { address, fileID } = data.submitMIBRequest;
      const userId = userData.user.personalInfo.contact.email;
      sendAmplitudeData(AMP_EVENT_REQUEST_LOCATION, {
        userId,
        fileId: fileID,
        locationId: locationID,
        newLocation: address?.formattedAddress,
      });
    },
  });

  const handleLocationRequest = React.useCallback(() => {
    const reverseGeocode = reverseGeocodeData?.reverseGeocode;
    if (!reverseGeocode || !userLocationsData) return;

    onClose();

    const fileId = userLocationsData.user.productSettings.locationFile.id;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { __typename, ...address } = reverseGeocode;
    submitMIBRequest({
      variables: {
        input: {
          address: address as EditAddressInput,
          fileID: fileId,
          lineNumber,
        },
      },
    });
  }, [
    reverseGeocodeData?.reverseGeocode,
    onClose,
    lineNumber,
    submitMIBRequest,
    userLocationsData,
  ]);

  React.useEffect(() => {
    if (reverseGeocodeError) {
      enqueueApiErrSnackbar();
    }
  }, [enqueueApiErrSnackbar, reverseGeocodeError]);

  if (reverseGeocodeError || reverseGeocodeLoading) return null;

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      actions={[
        {
          variant: 'contained',
          caption: t('locationMatch:request:yes'),
          callback: handleLocationRequest,
        },
        {
          variant: 'outlined',
          caption: t('locationMatch:request:no'),
          callback: onClose,
        },
      ]}
    >
      {t('locationMatch:request:description')}
    </ConfirmDialog>
  );
};

export default MIBRequestDialog;
