import * as React from 'react';
import { ApolloError } from '@apollo/client';

import {
  GetLocationsWithFilterQuery,
  LocFilter,
  useGetLocationsWithFilterQuery,
  useGetSliceIdxQuery,
} from '../__generated__/graphql';
import { allLocFilterChoicesVar } from '../PlanningApp/client/cache';
import { App } from '../PlanningApp/AppConfig';
import { getLocationsFilterChoices } from '../util/productUtils';

type UseSliceLocationsReturnType = {
  locations?: GetLocationsWithFilterQuery['locations'];
  loading?: boolean;
  error?: ApolloError;
};

function useSliceLocations(debugName?: string): UseSliceLocationsReturnType {
  const {
    data: {
      planView: { sliceIdx },
    },
  } = useGetSliceIdxQuery();

  if (!sliceIdx) {
    App.error('[useSliceLocations] - Bad sliceIdx.');
  }

  const { data, loading, error, called } = useGetLocationsWithFilterQuery({
    skip: !sliceIdx,
    variables: {
      sliceIdx,
      input: {
        matchCriteria: {
          isPendingMIB: false,
        },
      },
    },
  });

  React.useDebugValue({
    numLocations: data?.locations?.length,
    loadingUseGetLocations: loading,
    errorUseGetLocations: error,
  });

  React.useEffect(() => {
    let newLocFilter: LocFilter;

    if (data?.locations?.length > 0 && !loading && !error && called) {
      newLocFilter = getLocationsFilterChoices(data.locations);
      allLocFilterChoicesVar(newLocFilter);
    }
  }, [data, loading, error, called]);

  if (error) {
    App.error(
      `[useSliceLocations (debugName: ${debugName})] - GetLocationsWithFilterQuery error: `,
      error,
    );
    return { error, loading: false, locations: null };
  }

  if (loading || !called) {
    return { loading: true, error: null, locations: null };
  }

  // TODO -possible performance enhancement? write locationsData with sliceIdx as Id to the cache.

  return {
    loading: false,
    error: null,
    locations: data?.locations,
  };
}

export default useSliceLocations;
