import * as React from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  ForecastPeriodType,
  GetLocationQuery,
  useGetCurrentUserQuery,
  useGetPlanViewFiltersQuery,
} from '../../../../__generated__/graphql';
import { LocationWithScore } from '../../locationViewHelpers';

import { isLocVulnerable, sortVulnerableLocs } from '../../../../util/vulnerability';
import { QUIET_BG, QUIET_DARK } from '../../../../util/productGlobals';

import {
  AMP_EVENT_EXPORT_LOCATIONS,
  AMP_EVENT_EXPORT_VULNERABLE_LOCATIONS_TYPE,
} from '../../../../plugins/amplitudeevents';
import { escapeDelimiter } from '../../../../util/productUtils';
import { sendAmplitudeData } from '../../../../plugins/amplitude';
import useEntitledModules from '../../../../Hooks/useEntitledModules';

const useStyles = makeStyles(() => ({
  csvButton: {
    background: QUIET_BG,
    height: 32,
    letterSpacing: `0px !important`,
    padding: 8,
    '&:hover': {
      background: QUIET_BG,
    },
    '&:active': {
      background: QUIET_DARK,
    },
  },
}));

interface CSVDownloadButtonFCProps {
  locations: LocationWithScore[];
  disabled: boolean;
}

const CSVDownloadButtonFC: React.FC<CSVDownloadButtonFCProps> = ({ locations, disabled }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    data: {
      planView: { planFilters },
    },
  } = useGetPlanViewFiltersQuery();
  const { data: userData, loading: userLoading } = useGetCurrentUserQuery();
  const { data: entData } = useEntitledModules();

  const { hasUSModule, hasJPModule } = entData ?? {};

  const csvHeaders = React.useMemo(() => {
    const addressHeaders = [{ label: t('exportCSV:addressData'), key: 'createdAddress' }];

    if (hasUSModule) {
      addressHeaders.push(
        { label: t('exportCSV:state'), key: 'state' },
        { label: t('exportCSV:zipCode'), key: 'zipCode' },
      );
    }

    return [
      { label: t('exportCSV:locationName'), key: 'name' },
      ...addressHeaders,
      { label: t('exportCSV:locationType'), key: 'type' },
      { label: t('exportCSV:locationGroup'), key: 'group' },
      { label: t('exportCSV:hazard'), key: 'hazard' },
      { label: t('exportCSV:periodType'), key: 'periodType' },
      { label: t('exportCSV:climateChangeMode'), key: 'climate' },
      { label: t('exportCSV:structuralDamage', { unit: '%' }), key: 'structuralDamage' },
      {
        label: t('exportCSV:structuralDamage', { unit: t('exportCSV:unitSD') }),
        key: 'structuralDamageSD',
      },
      {
        label: t('exportCSV:powerDowntime', { unit: t('exportCSV:unitHours') }),
        key: 'powerDowntime',
      },
      {
        label: t('exportCSV:powerDowntime', { unit: t('exportCSV:unitSD') }),
        key: 'powerDowntimeSD',
      },
      {
        label: t('exportCSV:peopleDowntime', { unit: t('exportCSV:unitHours') }),
        key: 'peopleDowntime',
      },
      {
        label: t('exportCSV:portDowntime', { unit: t('exportCSV:unitHours') }),
        key: 'portDowntime',
      },
      {
        label: t('exportCSV:portDowntime', { unit: t('exportCSV:unitSD') }),
        key: 'portDowntimeSD',
      },
      {
        label: t('exportCSV:airportDowntime', { unit: t('exportCSV:unitHours') }),
        key: 'airportDowntime',
      },
      {
        label: t('exportCSV:airportDowntime', { unit: t('exportCSV:unitSD') }),
        key: 'airportDowntimeSD',
      },
      {
        label: t('exportCSV:bridgeDowntime', { unit: t('exportCSV:unitHours') }),
        key: 'bridgeDowntime',
      },
      {
        label: t('exportCSV:bridgeDowntime', { unit: t('exportCSV:unitSD') }),
        key: 'bridgeDowntimeSD',
      },
      {
        label: t('exportCSV:highwayDowntime', { unit: t('exportCSV:unitHours') }),
        key: 'highwayDowntime',
      },
    ];
  }, [t, hasUSModule]);

  const createAddress = React.useCallback(
    (address: GetLocationQuery['location']['address']) => {
      if (hasJPModule) {
        // TODO: Redo when we have to support english address for JPModule
        // postcode, perfecture, city, street address
        return `${address.postCode} ${address.addressDetails.prefecture} ${address.city} ${address.street}`;
      }

      return address.formattedAddress;
    },
    [hasJPModule],
  );

  const convertedCsvData = React.useMemo(() => {
    if (!locations) return [];

    const vulnerableLocs = sortVulnerableLocs(
      locations.filter(({ vulnerabilityScore }) => isLocVulnerable(vulnerabilityScore)),
    );

    return vulnerableLocs.map((location) => {
      const { address, resilienceStats } = location;
      const data: { [key: string]: unknown } = {
        ...location,
        name: escapeDelimiter(location.name),
        type: escapeDelimiter(location.type),
        group: escapeDelimiter(location.group),
        createdAddress: escapeDelimiter(createAddress(address)),
        hazard: planFilters?.hazardType,
        periodType: t(
          planFilters?.forecastPeriod.forecastPeriodType === ForecastPeriodType.ReturnPeriod
            ? 'exportCSV:returnPeriod'
            : 'exportCSV:planningHorizon',
          { num: planFilters?.forecastPeriod.duration },
        ) as string,
        climate: (planFilters?.isClimateChangeEnabled ? t('on') : t('off')) as string,
        structuralDamage: resilienceStats?.[0]?.structuralDamage?.mean,
        structuralDamageSD: resilienceStats?.[0]?.structuralDamage?.stddev,
        powerDowntime: resilienceStats?.[0]?.nearbyPower?.mean,
        powerDowntimeSD: resilienceStats?.[0]?.nearbyPower?.stddev,
        peopleDowntime: resilienceStats?.[0]?.nearbyPeople?.mean,
        portDowntime: resilienceStats?.[0]?.nearbyPort?.mean,
        portDowntimeSD: resilienceStats?.[0]?.nearbyPort?.stddev,
        airportDowntime: resilienceStats?.[0]?.nearbyAirport?.mean,
        airportDowntimeSD: resilienceStats?.[0]?.nearbyAirport?.stddev,
        bridgeDowntime: resilienceStats?.[0]?.nearbyBridge?.mean,
        bridgeDowntimeSD: resilienceStats?.[0]?.nearbyBridge?.stddev,
        highwayDowntime: resilienceStats?.[0]?.nearbyHighway?.mean,
      };

      if (hasUSModule) {
        data.state = address.stateCode;
        data.zipCode = address.postCode;
      }

      return data;
    });
  }, [
    createAddress,
    locations,
    planFilters?.forecastPeriod.duration,
    planFilters?.forecastPeriod.forecastPeriodType,
    planFilters?.hazardType,
    planFilters?.isClimateChangeEnabled,
    t,
    hasUSModule,
  ]);

  const handleDownloadVulnerableLocations = React.useCallback(
    (_: React.MouseEventHandler<HTMLAnchorElement>, done: (proceed?: boolean) => void) => {
      if (userLoading || !userData) {
        done(false);
        return;
      }

      const userId = userData.user.personalInfo.contact.email;
      sendAmplitudeData(AMP_EVENT_EXPORT_LOCATIONS, {
        userId,
        type: AMP_EVENT_EXPORT_VULNERABLE_LOCATIONS_TYPE,
      });

      done();
    },
    [userData, userLoading],
  );

  return (
    <CSVLink
      asyncOnClick
      onClick={handleDownloadVulnerableLocations}
      data={convertedCsvData}
      headers={csvHeaders}
      filename="vulnerableLocations.csv"
      enclosingCharacter=""
      style={{ pointerEvents: disabled ? 'none' : 'initial' }}
    >
      <Button
        disabled={disabled}
        className={`u-clickable ${classes.csvButton}`}
        data-test-id="DownloadToCSV"
      >
        <Typography variant="caption">{t('downloadCSV')}</Typography>
      </Button>
    </CSVLink>
  );
};

const CSVDownloadButton = React.memo(CSVDownloadButtonFC);
CSVDownloadButton.displayName = 'CSVDownloadButton';
export default CSVDownloadButton;
