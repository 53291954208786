import * as React from 'react';
import { OnecWindow } from 'onec-types';

import { ALL_LOCATIONS_SOURCE_ID } from '../util/productGlobals';
import { App } from '../PlanningApp/AppConfig';
import { locsInViewportIdsVar } from '../PlanningApp/client/cache';
import { useUpdateMapMovedMutation } from '../__generated__/graphql';

// Return the ids of all locations, vulnerable or not, that are within current map bounds
function useUpdateVisibleLocations(): () => void {
  const [disableButton] = useUpdateMapMovedMutation({ variables: { didMapMove: false } });

  return React.useCallback(() => {
    const mapboxMap = (window as OnecWindow).reactMap;
    const locsWithinViewport: Set<string> = new Set();

    if (!mapboxMap) {
      App.debug('[useUpdateVisibleLocations] - mapbox map is not loaded yet');
      return;
    }

    if (!mapboxMap.getSource(ALL_LOCATIONS_SOURCE_ID)) {
      App.debug(
        `[useUpdateVisibleLocations] - Not able to get mapbox Source: ${ALL_LOCATIONS_SOURCE_ID}`,
      );
      return;
    }
    mapboxMap
      .queryRenderedFeatures(null, {
        layers: [ALL_LOCATIONS_SOURCE_ID],
      })
      .forEach(({ properties: { id } }) => locsWithinViewport.add(id));

    App.debug(
      '[useUpdateVisibleLocations] -  ',
      `saving ${locsWithinViewport.size} locations inViewport to locsInViewportIdsVar`,
    );
    locsInViewportIdsVar(locsWithinViewport);

    disableButton();
  }, [disableButton]);
}

export default useUpdateVisibleLocations;
