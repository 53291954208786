import * as React from 'react';
import { Outlet, useMatch } from 'react-router-dom';

import MaterialityModal from './Materiality/MaterialityModal';
import SettingsModal from './Settings/SettingsModal';
import ShareLocationModal from './ShareLocation/ShareLocationModal';
import FinanceModal from './Finance/FinanceModal';
import useHasFinanceEntitlement from '../../../Hooks/useHasFinanceEntitlement';
import SimulationsFormContainer from '../FinanceViewV2/SimulationsForm';

type ModalsProps = {
  isViewer: boolean;
  hasMateriality?: boolean;
};

const Modals: React.FC<ModalsProps> = ({ isViewer, hasMateriality = false }) => {
  const showShareLocationModal = useMatch('/share-location');
  let showSettingsModal = useMatch('/profile');
  showSettingsModal = useMatch('/usermanagement') || showSettingsModal;
  const locationsPathMatch = useMatch('/locations');
  const locationsListPathMatch = useMatch('/locations/list');
  const showMaterialityModal = useMatch('/materiality/*') && hasMateriality;
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;
  const isSimulationsEntitled = financeModules?.enablesimulations ?? false;
  const showFinanceModal = useMatch('/finance/analysis/settings') && isFinanceEntitled;
  const showSimulations = useMatch('/finance/simulation/create') && isSimulationsEntitled;

  if (
    !!showSettingsModal ||
    (!isViewer && !!locationsPathMatch) ||
    (!isViewer && !!locationsListPathMatch)
  ) {
    return <SettingsModal />;
  }
  if (showMaterialityModal) {
    return <MaterialityModal />;
  }
  if (showFinanceModal) {
    return <FinanceModal />;
  }
  if (showShareLocationModal && !isViewer) {
    return <ShareLocationModal />;
  }
  if (showSimulations) {
    return <SimulationsFormContainer />;
  }
  return <Outlet />;
};

export default Modals;
