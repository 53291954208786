import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { getThresholdsDiff } from './thresholdsHelpers';
import PickerForm from '../Common/PickerForm';
import { ResilienceThresholds } from '../../../../__generated__/graphql';
import ThresholdsDiffList from './ThresholdsDiffList';
import ThresholdsPickerFormBody from './ThresholdsPickerFormBody';
import useLifelineEntitlements from '../../../../Hooks/useLifelineEntitlements';

type ThresholdsPickerProps = {
  serverThresholds: ResilienceThresholds;
  preSubmitThresholds: ResilienceThresholds;
  onChangeThreshold: (threshold: Partial<ResilienceThresholds>) => void;
  onSubmitThresholds: (e: React.FormEvent) => void;
  onCancelThresholdsChange: () => void;
  onRestoreToDefaults: () => void;
  submitting: boolean;
};

const ThresholdsPicker: React.FC<ThresholdsPickerProps> = ({
  serverThresholds,
  preSubmitThresholds,
  onChangeThreshold,
  onSubmitThresholds,
  onCancelThresholdsChange,
  onRestoreToDefaults,
  submitting,
}) => {
  const { t } = useTranslation();
  const entitlements = useLifelineEntitlements();

  const thresholdDiff = React.useMemo(() => {
    if (entitlements) {
      return getThresholdsDiff(serverThresholds, entitlements);
    }
    return null;
  }, [serverThresholds, entitlements]);

  return (
    <PickerForm
      onSubmit={onSubmitThresholds}
      onCancel={onCancelThresholdsChange}
      onRestoreToDefaults={onRestoreToDefaults}
      submitting={submitting}
      name="thresholds"
      hoverDropdownComponent={
        thresholdDiff?.length > 0 ? <ThresholdsDiffList thresholdDiff={thresholdDiff} /> : null
      }
      pickerButtonText={thresholdDiff?.length > 0 ? t('buttons:custom') : t('buttons:default')}
    >
      <ThresholdsPickerFormBody
        preSubmitVal={preSubmitThresholds}
        onChangePreSubmitVal={onChangeThreshold}
      />
    </PickerForm>
  );
};
ThresholdsPicker.displayName = 'ThresholdsPicker';
export default ThresholdsPicker;
