import * as React from 'react';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { CardActions, CardHeader, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  initializeLocsInViewportIds,
  locsInViewportIdsVar,
} from '../../../../PlanningApp/client/cache';
import { isLocVulnerable, sortVulnerableLocs } from '../../../../util/vulnerability';
import { App } from '../../../../PlanningApp/AppConfig';
import CSVDownloadButton from './CSVDownloadButton';
import { GetLocationsWithFilterQuery } from '../../../../__generated__/graphql';
import { PURPLE, SIDE_PANEL_VULNERABLE_LOCATIONS_LIST_VIEW } from '../../../../util/productGlobals';
import SkeletonVLL from './SkeletonVLL';
import UpdateResultsButton from '../../Map/UpdateResultsButton';
import useApiErrSnackbar from '../../../CommonComponents/Snackbar/useApiErrSnackbar';
import useCalcAndFilterLocs from '../../../../Hooks/useCalcAndFilterLocs';
import VLLContent from './VLLContent';
import CardExpandButton from '../Common/CardExpandButton';

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    margin: theme.spacing(2),
  },
  headerText: {
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 auto',
  },
  vulnerableIndicator: {
    width: theme.spacing(5.5),
    height: theme.spacing(5.5),
    borderRadius: '50%',
    backgroundColor: PURPLE,
    marginRight: '10px',
  },
}));

type VulnerableLocationsListProps = {
  allLocations: GetLocationsWithFilterQuery['locations'];
  loading: boolean;
  expanded?: boolean;
  onClickExpanded?: (card: string) => void;
};
const VulnerableLocationsList: React.FC<VulnerableLocationsListProps> = ({
  allLocations,
  loading,
  expanded = true,
  onClickExpanded,
}) => {
  const { t } = useTranslation();
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();
  const { headerContainer, headerText, vulnerableIndicator } = useStyles();
  const locIdsWithinViewport = useReactiveVar<Set<string>>(locsInViewportIdsVar);

  const {
    locations: locsWithScores,
    error: calcFilterError,
    loading: calcFilterLoading,
  } = useCalcAndFilterLocs(allLocations, null, 'VLL');

  React.useEffect(() => {
    if (!allLocations?.length && !loading) {
      App.debug('[VulnerableLocationsList] -  ', `Clear locsInViewportIdsVar since no locations`);
      initializeLocsInViewportIds();
    }
  }, [allLocations, loading]);

  const visibleVulnerableLocations = React.useMemo(() => {
    if (
      locIdsWithinViewport === null ||
      locIdsWithinViewport === undefined ||
      !locsWithScores ||
      calcFilterError ||
      calcFilterLoading
    ) {
      return null; // Map hasn't loaded yet or vulnerabilityScores haven't finished calculating
    }

    const vulnerableLocs = sortVulnerableLocs(
      locsWithScores.filter(
        ({ id, vulnerabilityScore }) =>
          locIdsWithinViewport.has(id) && isLocVulnerable(vulnerabilityScore),
      ),
    );

    return vulnerableLocs;
  }, [locsWithScores, locIdsWithinViewport, calcFilterError, calcFilterLoading]);

  if (!locIdsWithinViewport) {
    App.debug(
      `[VLL] - ${allLocations?.length} locations, ${locsWithScores?.length} locsWithScores. `,
      'Map not loaded into viewport yet',
    );
  } else {
    App.debug(
      `[VLL] - ${allLocations.length} total, ${locIdsWithinViewport.size} total within map bounds,`,
      ` ${visibleVulnerableLocations?.length} vulnerable within map bounds`,
    );
  }

  const showLoadingSkeleton =
    loading || calcFilterLoading || !!calcFilterError || !visibleVulnerableLocations;

  const disableCSVButton = showLoadingSkeleton || visibleVulnerableLocations?.length === 0;

  React.useEffect(() => {
    if (calcFilterError) {
      enqueueApiErrSnackbar();
    }
  }, [enqueueApiErrSnackbar, calcFilterError]);

  const handleChangeExpanded = React.useCallback(() => {
    if (!onClickExpanded) return;
    if (expanded) {
      onClickExpanded('');
    } else {
      onClickExpanded(SIDE_PANEL_VULNERABLE_LOCATIONS_LIST_VIEW);
    }
  }, [expanded, onClickExpanded]);

  return (
    <div className="u-paddingy--8" data-test-id="vulnerable-locations-list">
      <CardActions className="o-flexsb-container u-no-padding" disableSpacing>
        <div className="o-flex-vcenter-container">
          <div className="u-marginright--8">
            <CardExpandButton expanded={expanded} onClick={handleChangeExpanded} />
          </div>
          <UpdateResultsButton />
        </div>
        <CSVDownloadButton locations={locsWithScores} disabled={disableCSVButton} />
        {/* <IconButton className="u-clickable" data-test-id="VulnerableLocationsList-kebab">
          <MoreVertIcon />
        </IconButton> */}
      </CardActions>
      <CardHeader
        className={`${headerContainer} u-no-padding`}
        title={
          <div className={headerText}>
            {visibleVulnerableLocations?.length > 0 && <div className={vulnerableIndicator} />}
            <Typography variant="h2" data-test-id="VulnerableLocationsList-title">
              {t('vulnerableList:title')}
            </Typography>
          </div>
        }
      />

      {showLoadingSkeleton ? (
        <SkeletonVLL numItems={5} expanded={expanded} />
      ) : (
        <VLLContent
          visibleVulnerableLocations={visibleVulnerableLocations}
          totalLocationsInView={locIdsWithinViewport.size}
          expanded={expanded}
        />
      )}
    </div>
  );
};
VulnerableLocationsList.displayName = 'VulnerableLocationsList';
export default VulnerableLocationsList;
