import * as React from 'react';

import {
  ForecastPeriodType,
  SliceHazardType,
  useGetPlanViewFiltersQuery,
} from '../../../../__generated__/graphql';
import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import Callout from '../../../CommonComponents/Callout/Callout';
import showYourWorkStyles from './showYourWorkStyles';
import useHistoricalEvent from '../../../../Hooks/useHistoricalEvent';
import { ONECONCERN_SUPPORT_EMAIL } from '../../../../util/productGlobals';

const SECTION_KEY = 'showYourWork:sections:overview:';

const SYWOverviewFC: React.FC<{ multiBuilding: boolean }> = ({ multiBuilding }) => {
  const { t } = useTranslation();
  const {
    data: {
      planView: {
        planFilters: {
          historicalId,
          forecastPeriod: { duration, forecastPeriodType },
          hazardType,
          isClimateChangeEnabled,
        },
      },
    },
  } = useGetPlanViewFiltersQuery();
  const historicalEvent = useHistoricalEvent(historicalId);
  const hazard = t(`hazard:${hazardType.toLowerCase()}`);
  const { subtitle } = showYourWorkStyles({ isVulnerable: false });

  return (
    <section id="overview-section" data-test-id="SYWOverview">
      <Typography variant="h2" gutterBottom>
        {t(`${SECTION_KEY}intro:title`)}
      </Typography>

      {multiBuilding && (
        <Callout type="warning" variant="quiet" className="MuiTypography-paragraph">
          <Typography variant="body2">{t(`showYourWork:buildingsWarning`)}</Typography>
        </Callout>
      )}

      <Typography variant="body1" paragraph>
        {t(`${SECTION_KEY}intro:desc`)}
        <strong> {t('showYourWork:resultsBasedOn')}</strong>
      </Typography>

      <Typography
        variant="subtitle1"
        className={subtitle}
        style={{ fontWeight: 'normal' }}
        gutterBottom
      >
        {t(`${SECTION_KEY}selections:title`)}
      </Typography>
      {historicalEvent ? (
        <>
          <Typography variant="h4">
            <Trans i18nKey={`${SECTION_KEY}selections:historicalEvent`}>
              <strong>Historical event:</strong>
              <Typography variant="h5" component="span">
                <>{{ name: historicalEvent.name }}</>
              </Typography>
            </Trans>
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h4">
            <Trans i18nKey={`${SECTION_KEY}selections:hazard`}>
              <strong>Hazard:</strong>
              <Typography variant="h5" component="span">
                <>{{ hazard }}</>
              </Typography>
            </Trans>
          </Typography>
          <Typography variant="h4">
            <Trans
              i18nKey={`${SECTION_KEY}selections:${
                forecastPeriodType === ForecastPeriodType.ReturnPeriod
                  ? 'returnPeriod'
                  : 'planningHorizon'
              }`}
              count={duration}
            >
              <strong>Return period | Planning horizon:</strong>
              <Typography variant="h5" component="span">
                <>{{ count: duration }}</>
              </Typography>
            </Trans>
          </Typography>
          {hazardType !== SliceHazardType.Earthquake && (
            <Typography variant="h4">
              <Trans i18nKey={`${SECTION_KEY}selections:climateChange`}>
                <strong>Climate change:</strong>
                <Typography variant="h5" component="span">
                  <>{{ isEnabled: isClimateChangeEnabled ? t('on') : t('off') }}</>
                </Typography>
              </Trans>
            </Typography>
          )}
        </>
      )}

      <Typography variant="subtitle1" className={subtitle} gutterBottom>
        {t(`${SECTION_KEY}dataAnalysis:title`)}
      </Typography>
      <Typography paragraph>{t(`${SECTION_KEY}dataAnalysis:desc`)}</Typography>

      <Typography variant="subtitle1" className={subtitle} gutterBottom>
        {t(`${SECTION_KEY}dataReliability:title`)}
      </Typography>
      <Typography paragraph>{t(`${SECTION_KEY}dataReliability:desc`)}</Typography>
      <Typography paragraph>
        <Trans i18nKey={`${SECTION_KEY}dataReliability:cta`}>
          <a href={t('err:contactEmail')} className="u-link">
            {ONECONCERN_SUPPORT_EMAIL}
          </a>
        </Trans>
      </Typography>

      <Typography variant="subtitle1" className={subtitle} gutterBottom>
        {t(`${SECTION_KEY}vulnerability:title`)}
      </Typography>
      <Typography gutterBottom>{t(`${SECTION_KEY}vulnerability:desc`)}</Typography>
    </section>
  );
};

const SYWOverview = React.memo(SYWOverviewFC);
SYWOverview.displayName = 'SYWOverview';
export default SYWOverview;
