import * as React from 'react';
import i18next from '../../../PlanningApp/LocalizationClient';

import { GeoJsonGeometry, UserDetailsFragment } from '../../../__generated__/graphql';
import { getLocale, getUnitPrefs, PluralType, pluralTypeToNum } from '../../../util/LocaleUtils';
import {
  SettingType,
  StatisticType,
  StatPrecisionType,
  StatSettings,
} from '../../../../assets/i18n/StatUnitLabelsPrecision';

export type StatLabelType = 'abbrev' | 'long' | 'none';

export type StatisticStringProps = {
  statType: StatisticType;
  value: number;
  labelType?: StatLabelType;
  userPrefs?: UserDetailsFragment['preferences'];
  notAvailString?: string;
};

export type StatisticProps = StatisticStringProps & {
  parentID?: string;
  additionalClasses?: string;
};

export const getStatSettings: (statType: StatisticType) => SettingType = (statType) => {
  return StatSettings[statType as string];
};

export const getStatPrecision: (
  userPrefs: UserDetailsFragment['preferences'],
  statSettings: SettingType,
) => StatPrecisionType = (userPrefs, statSettings) => {
  // If stat has precision specified at metric/imperial level AND match user's metric/imperial
  // prefs, use that. otherwise return top-level precision
  const unitPrefs = getUnitPrefs(userPrefs);
  return statSettings?.[unitPrefs]?.precision ?? statSettings?.precision;
};

export const roundStatAndFormat: (
  value: number,
  userPrefs: UserDetailsFragment['preferences'],
  statSettings: SettingType,
) => { formattedVal: string; plural: PluralType } = (value, userPrefs, statSettings) => {
  const locale = getLocale(userPrefs);
  const {
    maximumFractionDigits,
    minimumFractionDigits,
    maximumSignificantDigits,
    doCustomRounding,
  } = getStatPrecision(userPrefs, statSettings);

  if (value < 100) {
    let newVal = value;
    if (!!doCustomRounding) {
      newVal = doCustomRounding(value);
    }

    let plural: PluralType;
    const pluralTest =
      Math.round(newVal * 10 ** maximumFractionDigits) / 10 ** maximumFractionDigits;
    switch (pluralTest) {
      case 0:
        plural = 'zero';
        break;
      case 1:
        plural = 'one';
        break;
      default:
        plural = 'other';
        break;
    }
    return {
      plural,
      formattedVal: newVal.toLocaleString(locale, { maximumFractionDigits, minimumFractionDigits }),
    };
  }
  return {
    formattedVal: value.toLocaleString(locale, { maximumSignificantDigits }),
    plural: 'other',
  };
};

export const getStatisticString: (props: StatisticStringProps) => string | null = ({
  statType,
  value,
  labelType = 'long',
  userPrefs,
  notAvailString,
}) => {
  if (value === null || value === undefined) {
    return notAvailString ?? null;
  }

  const statSettings: SettingType = getStatSettings(statType);
  if (statSettings.isInvalid) {
    if (statSettings.isInvalid(value)) {
      return notAvailString ?? null;
    }
  }
  const { formattedVal, plural } = roundStatAndFormat(value, userPrefs, statSettings);

  if (labelType === 'none') {
    return formattedVal;
  }
  const { labelKey } = statSettings;
  const translationKey = labelType === 'abbrev' ? `${labelKey}_${labelType}` : labelKey;
  return i18next.t(`stat:${translationKey}`, {
    formattedVal,
    count: pluralTypeToNum(plural),
    context: getUnitPrefs(userPrefs),
  });
};

export type LatLongStringProps = {
  epicenter: GeoJsonGeometry;
  userPrefs: UserDetailsFragment['preferences'];
  coordLabelType: 'usesNSEW' | 'usesPlusMinus';
  notAvailString?: string;
};

export const getLatLongString: (props: LatLongStringProps) => string | null = ({
  epicenter,
  userPrefs,
  coordLabelType,
  notAvailString,
}) => {
  const locale = getLocale(userPrefs);
  if (!epicenter?.coordinates) {
    return notAvailString ?? null;
  }
  const [long, lat] = epicenter.coordinates;
  const statSettings: SettingType = getStatSettings('geoCoordinate');
  const { maximumFractionDigits, minimumFractionDigits } = getStatPrecision(
    userPrefs,
    statSettings,
  );

  const latValStr = Math.abs(lat).toLocaleString(locale, {
    maximumFractionDigits,
    minimumFractionDigits,
  });
  const longValStr = Math.abs(long).toLocaleString(locale, {
    maximumFractionDigits,
    minimumFractionDigits,
  });

  let latStr = '';
  let longStr = '';
  if (coordLabelType === 'usesNSEW') {
    const latKey = lat >= 0 ? 'geoCoords:latNorth' : 'geoCoords:latSouth';
    latStr = i18next.t(latKey, { lat: latValStr });
    const longKey = long >= 0 ? 'geoCoords:longEast' : 'geoCoords:longWest';
    longStr = i18next.t(longKey, { long: longValStr });
    return i18next.t('geoCoords:latLongCoordinates', { latStr, longStr });
  }
  // coordLabelType ==== 'usesPlusMinus'
  const latLabel = lat >= 0 ? '+' : '-';
  const longLabel = long >= 0 ? '+' : '-';
  return `${latLabel}${latValStr}°, ${longLabel}${longValStr}°`;
};

/**
StatString returns a <span> containing the formatted number, wiht the following applied:
  1) Adding i18n-translated label (no label, abbreviated label, or full label), with the number
    displayed in the right place relative to the label for the locale
  2) Numbers rounded according to PM's "Displaying Numbers: Units & Precision" requirements, for
  always rounding up or usual rounding, number of fractional digits & number of signficant digits.
  3) Numbers formatted for the locale (thousands separator character, decimal place character)
  4) Units label in appropriate singular or plural form
*/
const Statistic: React.FC<StatisticProps> = ({
  parentID,
  additionalClasses = '',
  ...statStrProps
}) => {
  const statStr = getStatisticString(statStrProps);
  return (
    <span
      className={additionalClasses}
      data-test-id={`${parentID ? `${parentID}-` : ''}Stat-${statStrProps.statType}`}
    >
      {statStr}
    </span>
  );
};

export default Statistic;
