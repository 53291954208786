import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { CardActions, CardContent, Grid, Link, List, ListItem, Typography } from '@mui/material';

import {
  GetBuiltObjectQuery,
  GetLocationQuery,
  NavigatorViewType,
  ResilienceThresholds,
  useGetThresholdsQuery,
  useUpdateNavigatorInfoMutation,
} from '../../../../__generated__/graphql';
import { App } from '../../../../PlanningApp/AppConfig';
import { DebugFallbackComponent } from '../../../CommonComponents/FallbackComponent/FallbackComponent';
import DetailCardBackButton from './DetailCardBackButton';
import DetailCardHeader from './DetailCardHeader';
import { Lifeline } from '../../../../util/productEnums';
import LifelineDetailWidget from './LifelineDetailWidget';
import LifelinesContainer from './LifelinesContainer';
import LocationDetailCardSkeleton from './Skeleton/LocationDetailCardSkeleton';
import {
  DEMO_ACCOUNT_SUFFIX,
  FINANCE_HOME_PATH,
  HOME_PATH,
  OVERFLOW_Y,
} from '../../../../util/productGlobals';
import useApiErrSnackbar from '../../../CommonComponents/Snackbar/useApiErrSnackbar';
import VulnerableChip from './VulnerableChip';
import useHasFinanceEntitlement from '../../../../Hooks/useHasFinanceEntitlement';
import FinanceDowntimeTableContainer from './FinanceDowntimeTable';
import Callout from '../../../CommonComponents/Callout/Callout';
import Icon from '../../../CommonComponents/Icon/Icon';

interface LocationDetailCardProps {
  builtObject: GetLocationQuery['location'] | GetBuiltObjectQuery['builtObject'];
  currentView: NavigatorViewType;
  currentLifeline: string;
  currentUserEmail?: string;
}

// IMP: Every element should have the drag class otherwise
// that portion is not draggable in this component
const LocationDetailCard: React.FC<LocationDetailCardProps> = ({
  builtObject,
  currentView,
  currentLifeline,
  currentUserEmail = '',
}) => {
  const { t } = useTranslation();
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();
  const { id, __typename } = builtObject;
  const {
    data: userData,
    loading: thresholdsLoading,
    error: thresholdsError,
  } = useGetThresholdsQuery();

  const [updateNavigatorInfoMutation] = useUpdateNavigatorInfoMutation();
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;
  const isDemoUser = currentUserEmail.endsWith(DEMO_ACCOUNT_SUFFIX);

  const selectLifeline = React.useCallback(
    (lifeline: Lifeline) => {
      updateNavigatorInfoMutation({
        variables: {
          currentView: currentView || NavigatorViewType.MapView,
          currentLifeline: lifeline,
        },
      });
    },
    [currentView, updateNavigatorInfoMutation],
  );

  const clearLifeline = React.useCallback(() => {
    updateNavigatorInfoMutation({
      variables: {
        currentView: currentView || NavigatorViewType.MapView,
        currentLifeline: Lifeline.EMPTY,
      },
    });
  }, [currentView, updateNavigatorInfoMutation]);

  React.useEffect(() => {
    if (thresholdsError) {
      enqueueApiErrSnackbar();
      App.error('[LocationDetailCard] - GetThresholds error: ', thresholdsError);
    }
  }, [enqueueApiErrSnackbar, thresholdsError]);

  if (thresholdsLoading || thresholdsError) {
    return <LocationDetailCardSkeleton />;
  }

  const serverThresholds: ResilienceThresholds = userData?.user?.productSettings?.thresholds;
  return (
    <div data-test-id="LocationDetailCard">
      <CardActions className="o-flexfe-container u-no-padding u-paddingbottom--8" disableSpacing>
        <Grid container direction="column">
          <Grid>
            {currentLifeline !== (Lifeline.EMPTY as string) ? (
              <DetailCardBackButton clickFunc={clearLifeline} />
            ) : (
              <DetailCardBackButton
                backUrl={isFinanceEntitled ? FINANCE_HOME_PATH : HOME_PATH}
                buttonText={isFinanceEntitled && isDemoUser ? 'Back to Company View' : ''}
              />
            )}
          </Grid>
        </Grid>
        {!isFinanceEntitled && (
          <ErrorBoundary FallbackComponent={DebugFallbackComponent}>
            <VulnerableChip isLocation={__typename === 'LocationObject'} builtObjectID={id} />
          </ErrorBoundary>
        )}
      </CardActions>
      {currentView === NavigatorViewType.MapView && (
        <DetailCardHeader isLocation={__typename === 'LocationObject'} {...builtObject} />
      )}

      <CardContent
        className={`${OVERFLOW_Y} u-no-padding u-paddingx--8`}
        data-test-id="LocationDetail-content"
      >
        <List className="u-no-padding">
          <ListItem className="u-no-padding">
            {!isFinanceEntitled &&
              (currentLifeline !== (Lifeline.EMPTY as string) ? (
                <LifelineDetailWidget id={id} lifeline={currentLifeline} />
              ) : (
                <LifelinesContainer
                  thresholds={serverThresholds}
                  locOrBobjID={id}
                  typename={__typename}
                  selectLifeline={selectLifeline}
                />
              ))}
            {isFinanceEntitled &&
              (currentLifeline !== (Lifeline.EMPTY as string) ? (
                <LifelineDetailWidget id={id} lifeline={currentLifeline} />
              ) : (
                <div className="o-flexcol-container u-full-width" data-test-id="finance-downtime">
                  <Typography color="textPrimary" variant="h4" mb={2} fontSize="1rem">
                    {t('detailView:biDowntime:title')}
                  </Typography>
                  <Typography variant="subtitle1" component="div">
                    <Callout
                      type="warning"
                      variant="inline"
                      iconOverride={<Icon name="document" variant="orange" />}
                    >
                      <Link
                        href="https://developer.oneconcern.com/"
                        underline="hover"
                        data-test-id="api-link"
                      >
                        Explore our API!
                      </Link>
                    </Callout>
                  </Typography>
                  <FinanceDowntimeTableContainer locOrBobjID={id} typename={__typename} />
                </div>
              ))}
          </ListItem>
        </List>
      </CardContent>
    </div>
  );
};
LocationDetailCard.displayName = 'LocationDetailCard';
export default LocationDetailCard;
