import * as React from 'react';
import List from '@mui/material/List';
import { useTranslation } from 'react-i18next';

import CheckableMenuItem from './CheckableMenuItem';

type AllLocationsSelectorProps = {
  allIsChecked: boolean;
  onSelectAllLocations: () => void;
};

const AllLocationsSelector: React.FC<AllLocationsSelectorProps> = ({
  allIsChecked,
  onSelectAllLocations,
}) => {
  const { t } = useTranslation();

  const onClick = React.useCallback(() => {
    if (!allIsChecked) {
      // User can't uncheck 'All'. 'All' unchecks itself when 1+ types/groups are checked.
      onSelectAllLocations();
    }
  }, [onSelectAllLocations, allIsChecked]);

  return (
    <List disablePadding style={{ marginRight: 4 }}>
      <CheckableMenuItem
        name={t('locationGroups:all')}
        isChecked={allIsChecked}
        divider={false}
        onClick={onClick}
      />
    </List>
  );
};
AllLocationsSelector.displayName = 'AllLocationsSelector';
export default AllLocationsSelector;
