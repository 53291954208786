import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Card, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { GLASS_BG_MED, SIDE_PANEL_WIDTH } from '../../../util/productGlobals';
import { NavigatorViewType, useGetNavigatorInfoQuery } from '../../../__generated__/graphql';

import { Lifeline } from '../../../util/productEnums';
import LocationDetailView from './DetailView/LocationDetailView';
import NoLocationFound from './Common/NoLocationFound';
import CollapsibleListView from './CollapsibleListView';

const useStyles = makeStyles((theme: Theme) => ({
  sidebar: {
    pointerEvents: 'none',
    right: '10px',
    width: `${SIDE_PANEL_WIDTH}px`,
    top: (props?: { isTopConcernLifelineView?: boolean; topPosition?: number }) =>
      `${props.topPosition}px`,
    height: (props?: { isTopConcernLifelineView?: boolean; topPosition?: number }) =>
      `calc(100dvh - ${54 + props.topPosition}px)`,
  },
  card: {
    overflowY: 'auto',
    maxHeight: '100%',
    backgroundColor: GLASS_BG_MED,
    borderRadius: theme.spacing(1.5),
    backdropFilter: 'blur(16px)',
    pointerEvents: 'auto',
  },
}));

const SidePanel: React.FC = () => {
  const {
    data: {
      navigatorInfo: { currentView, currentLifeline },
    },
  } = useGetNavigatorInfoQuery();

  const isTopConcernLifelineView = React.useMemo(
    () => currentView !== NavigatorViewType.MapView && currentLifeline !== Lifeline.EMPTY,
    [currentView, currentLifeline],
  );
  const { card, sidebar } = useStyles({
    isTopConcernLifelineView,
    topPosition: isTopConcernLifelineView
      ? document.getElementById('LocationBar')?.clientHeight ?? 126
      : 86,
  });

  if (currentView === NavigatorViewType.MapView || currentLifeline !== Lifeline.EMPTY) {
    return (
      <div
        id="SidePanel-Wrapper"
        className={`u-position--abs u-zindex--5 u-overflow-hidden ${sidebar}`}
        data-test-id="SidePanel-Wrapper"
      >
        <Routes>
          <Route path="/" element={<CollapsibleListView />} />
          <Route path="detail">
            <Route
              path="notFound"
              element={
                <Card className={`o-flexcol-container ${card}`} elevation={0}>
                  <NoLocationFound />
                </Card>
              }
            />
            <Route
              path=":id"
              element={
                <LocationDetailView currentView={currentView} currentLifeline={currentLifeline} />
              }
            />
            <Route path="*" element={<Navigate to="/analysis/detail/notFound" replace />} />
          </Route>
          <Route
            path="see-the-math/:id"
            element={
              <LocationDetailView currentView={currentView} currentLifeline={currentLifeline} />
            }
          />
          <Route
            path="share-location/:id"
            element={
              <LocationDetailView currentView={currentView} currentLifeline={currentLifeline} />
            }
          />
          <Route path="company/:id" element={null} />
          <Route path="settings" element={null} />
          <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
      </div>
    );
  }
  return null;
};

SidePanel.displayName = 'SidePanel';
export default SidePanel;
