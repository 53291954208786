import * as React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';

import useHasFinanceEntitlement from '../../../../Hooks/useHasFinanceEntitlement';
import { FINANCE_HOME_PATH, HOME_PATH } from '../../../../util/productGlobals';

const LearnMore: React.FC = () => {
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();
  const id = params?.id;
  const { data: financeModules } = useHasFinanceEntitlement();

  if (!id) {
    return null;
  }
  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      component={RouterLink}
      to={`${financeModules?.enablebi ? FINANCE_HOME_PATH : HOME_PATH}/see-the-math/${id}`}
      data-test-id={`builtObject:${id}-math`}
    >
      {t('detailView:learnMore')}
    </Button>
  );
};

LearnMore.displayName = 'LearnMore';
export default LearnMore;
