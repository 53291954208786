import { CircularProgress, Grid, Stack } from '@mui/material';
import * as React from 'react';
import { NavigatorViewType, useGetCurrentUserQuery } from '../../../../../__generated__/graphql';
import HazardSelector from '../../../AppNav/Primary/HazardSelector';
import FiltersBar from '../../../Filters/FiltersBar';
import LocationsPicker from '../../../Filters/LocationsPicker/LocationsPicker';

const MaterialityDriversFC: React.FC = () => {
  const { data, loading, error } = useGetCurrentUserQuery();
  if (error) {
    return null;
  }
  if (loading) {
    return (
      <Grid container mt={4}>
        <CircularProgress />
      </Grid>
    );
  }
  if (data) {
    return (
      <Stack direction="row" data-test-id="Materiality:Drivers" sx={{ mt: 4 }}>
        <HazardSelector />
        <FiltersBar
          currentNavigatorView={NavigatorViewType.Materiality}
          userId={data.user.personalInfo.contact.email}
        />
        <LocationsPicker showLoadingSkeleton hiddenGroups />
      </Stack>
    );
  }
  return null;
};
const MaterialityDrivers = React.memo(MaterialityDriversFC);
export default MaterialityDrivers;
