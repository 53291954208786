import { Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useGetLocationTypesAndFileId from '../../../../../Hooks/useGetLocationTypesAndFileId';
import { App } from '../../../../../PlanningApp/AppConfig';
import { useGetMaterialitySettingsQuery } from '../../../../../__generated__/graphql';
import Spinner from '../../../../CommonComponents/Spinner/Spinner';
import MaterialityDriversSettings from './MaterialityDriversSettings';
import MaterialityThresholdSettings from './MaterialityThresholdSettings';

const MaterialitySettingsContainer = () => {
  const { t } = useTranslation();
  const { values, loading, error } = useGetLocationTypesAndFileId();
  const {
    data: dataMaterialitySettings,
    loading: loadingMaterialitySettings,
    error: errorMaterialitySettings,
  } = useGetMaterialitySettingsQuery();
  if (loading || loadingMaterialitySettings) {
    return <Spinner />;
  }

  if (error) {
    App.error(`[MaterialitySettingsContainer] ${error.message}`);
    return null;
  }

  if (errorMaterialitySettings) {
    App.error(`[MaterialitySettingsContainer] ${errorMaterialitySettings.message}`);
    return null;
  }

  const { locTypes, fileId } = values ?? {};
  if (locTypes?.length > 0 && fileId !== 'no-file') {
    const materialitySettings = dataMaterialitySettings?.getMaterialitySettings;
    if (!materialitySettings?.locationTypeMaterialitySettings?.length) {
      return (
        <div data-test-id="MaterialitySettings-Invalid">
          <div className="u-margintop--32">
            <Typography variant="h3">
              {t('materiality:sections:settings:invalidMessage')}
            </Typography>
          </div>
        </div>
      );
    }

    return (
      <div data-test-id="MaterialitySettings">
        <div className="u-margintop--32">
          <MaterialityThresholdSettings />
        </div>

        <div className="u-margintop--100 u-paddingbottom--40">
          <MaterialityDriversSettings />
        </div>
      </div>
    );
  }

  if (locTypes?.length === 0 && fileId !== 'no-file') {
    return (
      <div data-test-id="MaterialitySettings-Invalid">
        <div className="u-margintop--32">
          <Typography variant="body1">{t('materiality:invalidFile')}</Typography>
        </div>
      </div>
    );
  }

  return null;
};

MaterialitySettingsContainer.displayName = 'MaterialitySettingsContainer';

export default MaterialitySettingsContainer;
