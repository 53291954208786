import * as React from 'react';

import Spinner from '../../../CommonComponents/Spinner/Spinner';
import SYWAirport from '../../Modals/ShowYourWork/SYWAirport';
import SYWBridge from '../../Modals/ShowYourWork/SYWBridge';
import SYWHighway from '../../Modals/ShowYourWork/SYWHighway';
import SYWPeople from '../../Modals/ShowYourWork/SYWPeople';
import SYWPort from '../../Modals/ShowYourWork/SYWPort';
import SYWPower from '../../Modals/ShowYourWork/SYWPower';
import SYWStructure from '../../Modals/ShowYourWork/SYWStructure';
import useLocationSliceStats from '../../../../Hooks/useLocationSliceStats';

interface LifelineDetailWidgetFCProps {
  id: string;
  lifeline: string;
}

const LifelineDetailWidgetFC: React.FC<LifelineDetailWidgetFCProps> = ({ id, lifeline }) => {
  const { data, loading } = useLocationSliceStats(id);

  if (loading) return <Spinner />;
  if (!data) return null;

  const {
    stats: { airport, bridge, highway, people, port, power, structure },
  } = data;

  switch (lifeline) {
    case 'airport':
      return <SYWAirport stats={airport} />;

    case 'bridge':
      return <SYWBridge stats={bridge} />;

    case 'highway':
      return <SYWHighway stats={highway} />;

    case 'people':
      return <SYWPeople stats={people} />;

    case 'port':
      return <SYWPort stats={port} />;

    case 'power':
      return <SYWPower stats={power} />;

    case 'structure':
      return <SYWStructure stats={structure} />;

    default:
      return null;
  }
};

const LifelineDetailWidget = React.memo(LifelineDetailWidgetFC);
LifelineDetailWidget.displayName = 'LifelineDetailWidget';
export default LifelineDetailWidget;
