import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  GetCurrentUserQuery,
  useGetOrganizationUsersQuery,
} from '../../../../../__generated__/graphql';
import { App } from '../../../../../PlanningApp/AppConfig';
import { getFullName } from '../../../../../util/utils';
import Spinner from '../../../../CommonComponents/Spinner/Spinner';
import useApiErrSnackbar from '../../../../CommonComponents/Snackbar/useApiErrSnackbar';
import useConsultantOrganization from '../../../../../Hooks/useConsultantOrganization';
import UserAdd from './UserAdd';
import UserTable from './UserTable';

const useStyles = makeStyles((theme: Theme) => {
  return {
    addUserTitle: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(4),
    },
    allUsersTitle: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
    },
  };
});

const UserManagementContent: React.FC<{ user: GetCurrentUserQuery['user'] }> = ({ user }) => {
  const { addUserTitle, allUsersTitle } = useStyles();
  const { t } = useTranslation();
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();
  const { data, error } = useGetOrganizationUsersQuery();
  const { isConsultantOrg } = useConsultantOrganization();

  React.useEffect(() => {
    if (error) {
      enqueueApiErrSnackbar();
      App.error('[UserManagement] - useGetOrganizationUsersQuery error:  ', error);
    }
  }, [error, enqueueApiErrSnackbar]);

  if (!data) {
    return <Spinner parentId={`GetUserOrganizationUsersQuery-${error ? 'error' : 'loading'}`} />;
  }

  const users = data?.organization?.users
    .filter((itemUser) => itemUser.id !== user.id)
    .map(({ id, personalInfo, role, status }) => {
      const fullName = getFullName(personalInfo?.firstName, personalInfo?.lastName);

      return {
        name: fullName ?? t('nA'),
        email: personalInfo?.contact?.email ?? t('nA'),
        firstName: personalInfo?.firstName,
        id,
        lastName: personalInfo?.lastName,
        role,
        status,
      };
    });

  return (
    <div data-test-id="UserManagement-View">
      <Typography variant="h5" className={addUserTitle} data-test-id="AddUser-caption">
        {t('settings:sections:userManagement:addUser:title')}
      </Typography>
      <UserAdd isConsultantOrg={isConsultantOrg} />
      <Typography variant="h5" className={allUsersTitle} data-test-id="AllUsers-caption">
        {t('settings:sections:userManagement:allUsers:title')}
      </Typography>
      <UserTable data={users} user={user} isConsultantOrg={isConsultantOrg} />
    </div>
  );
};

export default UserManagementContent;
UserManagementContent.displayName = 'UserManagementContent';
