import * as React from 'react';
import { Layer, Source } from 'react-mapbox-gl';
import { LayerMouseEvent } from 'onec-types';
import { MAX_ZOOM } from '../../../util/productGlobals';

type MapSourceFCProps = {
  id: string;
  type: string;
  features: GeoJSON.Feature<GeoJSON.Geometry>[];
  previousSourceId: string;
  iconLayout?: any;
  iconPaint?: any;
  handleIconClick?: (evt: LayerMouseEvent<any>) => void;
  handleMouseEnter?: (evt: LayerMouseEvent<any>) => void;
  handleMouseLeave?: (evt: LayerMouseEvent<any>) => void;
};

const MapSourceFC: React.FC<MapSourceFCProps> = ({
  id,
  type,
  features,
  previousSourceId,
  iconLayout,
  iconPaint,
  handleIconClick,
  handleMouseEnter,
  handleMouseLeave,
}) => {
  return (
    <>
      <Source
        id={id}
        geoJsonSource={{
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features,
          },
          promoteId: 'id',
          maxzoom: MAX_ZOOM,
        }}
      />
      <Layer
        id={id}
        sourceId={id}
        type={type}
        onClick={handleIconClick}
        layout={iconLayout}
        paint={iconPaint}
        before={previousSourceId}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </>
  );
};

const MapSource = React.memo(MapSourceFC);
MapSource.displayName = 'MapSource';
export default MapSource;
