import * as React from 'react';
import { SnackbarProvider, SnackbarProviderProps } from 'notistack';
import { makeStyles } from '@mui/styles';
import { Slide, Theme } from '@mui/material';

import { BLURRY, BORDER_RADIUS } from '../../../util/productGlobals';
import Icon from '../Icon/Icon';

const useSnackbarStyles = makeStyles((theTheme: Theme) => {
  const { palette, spacing } = theTheme;
  return {
    variantSuccess: {
      backgroundColor: `${palette.success.main} !important`,
      backdropFilter: 'none !important',
    },
    variantError: {
      backgroundColor: `${palette.error.main} !important`,
      backdropFilter: 'none !important',
    },
    variantWarning: {
      backgroundColor: `${palette.warning.main} !important`,
      backdropFilter: 'none !important',
    },
    variantInfo: {
      backgroundColor: `${palette.info.main} !important`,
      backdropFilter: 'none !important',
    },
    root: {
      maxWidth: 420, // width is more than DesignMocks (388), per Product Mgmt request
      '& button': {
        marginRight: spacing(2),
      },
      '& .SnackbarItem-contentRoot': {
        backgroundColor: `${palette.glass.main}`,
        backdropFilter: BLURRY,
        color: `${palette.text.primary} !important`,
        boxShadow: 'none !important',
        padding: spacing(2),
        borderRadius: BORDER_RADIUS,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
      },
    },
    containerAnchorOriginBottomLeft: {
      bottom: 38, // just above the one concern logo
      left: spacing(2),
    },
    containerAnchorOriginBottomCenter: {
      bottom: spacing(2),
    },
  };
});

const StyledSnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  const emphasisIcon = <Icon name="emphasis2" variant="white" />;
  const successIcon = <Icon name="success2" variant="white" />;

  const classes = useSnackbarStyles();

  // Most of the following props (except classes) can be overridden by individual snackbar messages
  // by providing prop overrides to 'enqueueSnackbar' call

  const styledSbpProps: Omit<SnackbarProviderProps, 'children'> = {
    classes,
    maxSnack: 1,
    autoHideDuration: 10000, // disappear after 10 seconds
    variant: 'error',
    iconVariant: {
      error: emphasisIcon,
      warning: emphasisIcon,
      info: emphasisIcon,
      success: successIcon,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    TransitionComponent: Slide,
    preventDuplicate: true,
  };

  return <SnackbarProvider {...styledSbpProps}>{children}</SnackbarProvider>;
};

StyledSnackbarProvider.displayName = 'StyledSnackbarProvider';
export default StyledSnackbarProvider;
