import * as React from 'react';
import { OnecWindow } from 'onec-types';

import {
  GetBuiltObjectLifelineMarkersQuery,
  GetLocationLifelineMarkersQuery,
  NavigatorViewType,
  useGetNavigatorInfoQuery,
} from '../../../../__generated__/graphql';
import { BI_LIFELINE_SOURCE, BUILT_OBJECT_SOURCE_ID } from '../../../../util/productGlobals';
import { Lifeline } from '../../../../util/productEnums';
import { lifelineIcons } from '../../../../util/icons';
import MapSource from '../../../CommonComponents/Map/MapSource';
import LifelinePillMarker from './LifelinePillMarker';

export const defaultOpacity = 1;
export const lowOpacity = 0.4;

export type LifelineMarkersFCProps = {
  locOrBobj:
    | GetLocationLifelineMarkersQuery['location']
    | GetBuiltObjectLifelineMarkersQuery['builtObject'];
};
const LifelineMarkersFC: React.FC<LifelineMarkersFCProps> = ({
  locOrBobj: { coordinates, airports, ports, bridges, highways, structural, power },
}) => {
  const {
    data: {
      navigatorInfo: { currentView },
    },
  } = useGetNavigatorInfoQuery();
  const featureData: GeoJSON.Feature<GeoJSON.Geometry>[] = React.useMemo(() => {
    const airportsFeatures: GeoJSON.Feature<GeoJSON.Geometry>[] = airports?.map((airport) => {
      return {
        id: airport.id,
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: airport.coordinates,
        },
        properties: {
          id: airport.id,
          category: Lifeline.AIRPORT,
        },
      };
    });
    const portsFeatures: GeoJSON.Feature<GeoJSON.Geometry>[] = ports?.map((port) => {
      return {
        id: port.id,
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: port.coordinates,
        },
        properties: {
          id: port.id,
          category: Lifeline.PORT,
        },
      };
    });
    const bridgesFeatures: GeoJSON.Feature<GeoJSON.Geometry>[] = bridges?.map((bridge) => {
      return {
        id: bridge.id,
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: bridge.coordinates,
        },
        properties: {
          id: bridge.id,
          category: Lifeline.BRIDGE,
        },
      };
    });
    const highwaysFeatures: GeoJSON.Feature<GeoJSON.Geometry>[] = highways?.map((highway) => {
      return {
        id: highway.id,
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: highway.coordinates,
        },
        properties: {
          id: highway.id,
          category: Lifeline.HIGHWAY,
        },
      };
    });

    return [airportsFeatures, portsFeatures, bridgesFeatures, highwaysFeatures]
      .flat()
      .filter((item) => item);
  }, [airports, bridges, highways, ports]);

  const lifelineIconFilter = React.useMemo(() => {
    // eslint-disable-next-line no-shadow
    const { airports, ports, bridges, highways, power, structural } = lifelineIcons;
    const filter = [
      'case',
      ['==', ['get', 'category'], Lifeline.AIRPORT],
      airports.notVulnerable.default,
      ['==', ['get', 'category'], Lifeline.PORT],
      ports.notVulnerable.default,
      ['==', ['get', 'category'], Lifeline.BRIDGE],
      bridges.notVulnerable.default,
      ['==', ['get', 'category'], Lifeline.HIGHWAY],
      highways.notVulnerable.default,
      ['==', ['get', 'category'], Lifeline.POWER],
      power.notVulnerable.default,
      ['==', ['get', 'category'], Lifeline.STRUCTURE],
      structural.notVulnerable.default,
      'tw-national-2',
    ];

    return { 'icon-image': filter };
  }, []);

  const pillIcons = React.useMemo(() => {
    const icons = [];

    if (structural?.length > 0) {
      const name = 'Lifeline-structure';

      icons.push({
        name,
        isVulnerable: false,
        showBorder: true,
      });
    }

    if (power?.length > 0) {
      const name = 'Lifeline-power';

      icons.push({
        name,
        isVulnerable: false,
        showBorder: true,
      });
    }

    return icons;
  }, [structural, power]);

  const mapboxMap = (window as OnecWindow).reactMap;
  const handleMouseEnter = React.useCallback(() => {
    mapboxMap.getCanvas().style.cursor = 'pointer';
  }, [mapboxMap]);
  const handleMouseLeave = React.useCallback(() => {
    mapboxMap.getCanvas().style.cursor = '';
  }, [mapboxMap]);
  return (
    <>
      <MapSource
        id={BI_LIFELINE_SOURCE}
        type="symbol"
        features={featureData}
        iconLayout={lifelineIconFilter}
        iconPaint={{ 'icon-opacity': defaultOpacity }}
        previousSourceId={`${BUILT_OBJECT_SOURCE_ID}-halo`}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
      />
      {currentView === NavigatorViewType.MapView && (
        <LifelinePillMarker coordinates={coordinates} icons={pillIcons} />
      )}
    </>
  );
};

const LifelineMarkersDefault = React.memo(LifelineMarkersFC);
LifelineMarkersDefault.displayName = 'LifelineMarkers';
export default LifelineMarkersDefault;
