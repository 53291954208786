import * as React from 'react';

import { Card, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  GLASS_BG_MED,
  SIDE_PANEL_DOWNTIME_MATERIALITY_VIEW,
  SIDE_PANEL_VULNERABLE_LOCATIONS_LIST_VIEW,
} from '../../../util/productGlobals';
import {
  useGetCurrentUserQuery,
  useGetLocationsFileIdQuery,
  UserRole,
} from '../../../__generated__/graphql';

import { App } from '../../../PlanningApp/AppConfig';
import MaterialityViewContainer from './Materiality/MaterialityViewContainer';
import RecentlyViewedLocations from './ListView/RecentlyViewedLocations';
import VulnerableLocationsListContainer from './ListView/VulnerableLocationsListContainer';
import useHasMaterialityEntitlement from '../../../Hooks/useHasMaterialityEntitlement';
import useHasFinanceEntitlement from '../../../Hooks/useHasFinanceEntitlement';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    overflowY: 'auto',
    maxHeight: '100%',
    minHeight: 138,
    backgroundColor: GLASS_BG_MED,
    borderRadius: theme.spacing(1.5),
    backdropFilter: 'blur(16px)',
    pointerEvents: 'auto',
  },
}));

const CollapsibleListView = () => {
  // TODO: Do we need to save expanded state?
  const [expanded, setExpanded] = React.useState(SIDE_PANEL_VULNERABLE_LOCATIONS_LIST_VIEW);
  const { card } = useStyles();
  const { data, error } = useGetLocationsFileIdQuery();
  const fileId = data?.user?.productSettings?.locationFile?.id;
  const { data: userData, error: userError } = useGetCurrentUserQuery();
  const isViewer = userData?.user?.role === UserRole.Viewer;
  const { data: hasMateriality, error: matEntError } = useHasMaterialityEntitlement();
  const { data: financeModules, error: financeEntError } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;

  if (error) {
    App.error(`[CollapsibleListView] ${error.message ?? 'Error retrieving location file id'}`);
  }
  if (userError) {
    App.error(`[CollapsibleListView] ${userError.message ?? 'Error retrieving user details'}`);
  }
  if (matEntError || financeEntError) {
    App.error(
      `[CollapsibleListView] ${matEntError.message ?? 'Error retrieving user entitlements'}`,
    );
  }

  const onClickExpanded = (cardTitle: string) => {
    setExpanded(cardTitle);
  };
  return (
    <div className="o-flexcol-container u-height--100">
      {isViewer && (
        <Card className={`o-flexcol-container u-paddingy--0 ${card}`} elevation={0}>
          <RecentlyViewedLocations />
        </Card>
      )}
      {!isViewer && (!isFinanceEntitled || (isFinanceEntitled && fileId !== 'no-file')) && (
        <Card className={`o-flexcol-container u-paddingy--0 ${card}`} elevation={0}>
          <VulnerableLocationsListContainer
            expanded={expanded === SIDE_PANEL_VULNERABLE_LOCATIONS_LIST_VIEW}
            onClickExpanded={onClickExpanded}
          />
        </Card>
      )}
      {!isViewer &&
        fileId &&
        fileId !== 'no-file' &&
        !matEntError &&
        hasMateriality &&
        !isFinanceEntitled && (
          <Card
            className={`o-flexcol-container u-paddingy--0 u-margintop--8 ${card}`}
            elevation={0}
          >
            <MaterialityViewContainer
              expanded={expanded === SIDE_PANEL_DOWNTIME_MATERIALITY_VIEW}
              onClickExpanded={onClickExpanded}
            />
          </Card>
        )}
    </div>
  );
};

CollapsibleListView.displayName = 'CollapsibleListView';

export default CollapsibleListView;
