import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { CardActions, Grid } from '@mui/material';
import { useSetNotFoundLocationMutation } from '../../../../__generated__/graphql';
import useHasFinanceEntitlement from '../../../../Hooks/useHasFinanceEntitlement';
import { FINANCE_HOME_PATH, HOME_PATH } from '../../../../util/productGlobals';
import DetailCardBackButton from './DetailCardBackButton';
import NoDataChip from '../Common/NoDataChip';
import NoLocationDataFound from './NoLocationDataFound';

const LocationIdNotFound: React.FC = () => {
  const [setNotFoundLocationMutation] = useSetNotFoundLocationMutation();
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;
  React.useEffect(() => {
    // When initially mounting, write "location not found" as notFoundAddress to global state,
    // so that the "Learn More" button won't appear under this card.
    setNotFoundLocationMutation({
      variables: { input: { address: 'Unknown Location Id' } },
    });
  }, [setNotFoundLocationMutation]);

  const navigate = useNavigate();
  const goBack = React.useCallback(() => {
    navigate(isFinanceEntitled ? FINANCE_HOME_PATH : HOME_PATH);
    setNotFoundLocationMutation({
      variables: { input: { address: '' } },
    });
  }, [isFinanceEntitled, navigate, setNotFoundLocationMutation]);

  return (
    <>
      <CardActions className="o-flexfe-container u-no-padding u-paddingbottom--8" disableSpacing>
        <Grid container direction="column">
          <Grid>
            <DetailCardBackButton
              clickFunc={goBack}
              backUrl={isFinanceEntitled ? FINANCE_HOME_PATH : HOME_PATH}
            />
          </Grid>
        </Grid>
        <NoDataChip />
      </CardActions>
      <NoLocationDataFound />
    </>
  );
};
export default LocationIdNotFound;
