import { locationSearchMutations } from '../resolvers/LocationSearchResolver';

export const STORE_DEFAULTS = {
  notFoundLocation: {
    address: '',
    lng: 0,
    lat: 0,
  },
};

export const locationSearchStore = {
  defaults: STORE_DEFAULTS,
  mutations: locationSearchMutations,
};
