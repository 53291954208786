import { Expression, GeoJSONSourceRaw, LngLat, Map as MapboxMap, SymbolLayer } from 'mapbox-gl';
import { FitBounds } from 'react-mapbox-gl/lib/map';

import { App } from '../PlanningApp/AppConfig';
import { Coordinate } from '../__generated__/graphql';
import { MAX_ZOOM } from './productGlobals';

export type HexColors = {
  break: number;
  color: string;
};

type RGB = {
  R: number;
  G: number;
  B: number;
};

type HSL = {
  H: number;
  S: number;
  L: number;
};

function rgbToHsl(rgb: RGB): HSL {
  const r = rgb.R / 255;
  const g = rgb.G / 255;
  const b = rgb.B / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = (max + min) / 2;
  let s = (max + min) / 2;
  const l = (max + min) / 2;

  if (max === min) {
    h = 0;
    s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        break;
    }
    h /= 6;
  }
  return {
    H: h,
    S: s,
    L: l,
  };
}

function hue2rgb(p: number, q: number, t: number) {
  // eslint-disable-next-line no-param-reassign
  if (t < 0) t += 1;
  // eslint-disable-next-line no-param-reassign
  if (t > 1) t -= 1;
  if (t < 1 / 6) return p + (q - p) * 6 * t;
  if (t < 1 / 2) return q;
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
  return p;
}

function hslToRgb(color: HSL): RGB {
  let l = color.L;

  if (color.S === 0) {
    l = Math.round(l * 255);
    return { R: l, G: l, B: l };
  }
  {
    const s = color.S;
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    const r = hue2rgb(p, q, color.H + 1 / 3);
    const g = hue2rgb(p, q, color.H);
    const b = hue2rgb(p, q, color.H - 1 / 3);
    return {
      R: Math.round(r * 255),
      G: Math.round(g * 255),
      B: Math.round(b * 255),
    };
  }
}

function rgbToHex(rgb: RGB): string {
  // eslint-disable-next-line no-bitwise
  return `#${((1 << 24) + (rgb.R << 16) + (rgb.G << 8) + rgb.B).toString(16).slice(1)}`;
}

function hexToRgb(hex: string): { rgb: RGB } {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const rgb: RGB = {
    R: parseInt(result[1], 16),
    G: parseInt(result[2], 16),
    B: parseInt(result[3], 16),
  };
  return { rgb };
}

const interpolateColor = (color1: RGB, color2: RGB, factor: number) => {
  const result = color1;
  result.R = Math.round(result.R + factor * (color2.R - color1.R));
  result.G = Math.round(result.G + factor * (color2.G - color1.G));
  result.B = Math.round(result.B + factor * (color2.B - color1.B));
  return result;
};

const interpolateHSL = (color1: RGB, color2: RGB, factor: number) => {
  const hsl1 = rgbToHsl(color1);
  const hsl2 = rgbToHsl(color2);
  hsl1.H += factor * (hsl2.H - hsl1.H);
  hsl1.S += factor * (hsl2.S - hsl1.S);
  hsl1.L += factor * (hsl2.L - hsl1.L);
  return hslToRgb(hsl1);
};

/**
 * Function to interpolate colors between a range where if you have 4 colors [A,C,E,G]
 * and a breaking factor of 1 you will end up with [A,B,C,D,E,F,G] (4 * breakingFactor - 1  + 4)
 * colors
 * linearly interpolated between each. We expect variation between A and C and E and G to
 *  not be equal
 * @param colorArray Array of hex colors with exclusive break and color
 * @param breakingFactor There will obviously be a limit to this as the colors are limited to 255
 */
export function interpolateColors(
  colorArray: HexColors[],
  breakingFactor: number,
  type?: string,
): HexColors[] {
  if (colorArray.length <= 1) {
    return colorArray;
  }
  if (breakingFactor < 1) {
    return colorArray;
  }
  const genHexColors: HexColors[] = [];
  const genRGBColors: RGB[] = [];
  for (let i = 0; i < colorArray.length; i += 1) {
    const { rgb: rgb1 } = hexToRgb(colorArray[i].color);
    genRGBColors[i] = rgb1;
  }

  for (let j = 0; j < genRGBColors.length - 1; j += 1) {
    genHexColors.push(colorArray[j]);
    for (let k = 1; k <= breakingFactor; k += 1) {
      const factor = 1 / (breakingFactor + 1);
      const color1 = genRGBColors[j];
      const color2 = genRGBColors[j + 1];
      const newcolor =
        type === 'RGB'
          ? interpolateColor(color1, color2, factor * k)
          : interpolateHSL(color1, color2, factor * k);
      const newHexcolor = rgbToHex(newcolor);
      genHexColors.push({
        break: colorArray[j].break + factor * k * (colorArray[j + 1].break - colorArray[j].break),
        color: `${newHexcolor}`,
      });
    }
  }
  genHexColors.push(colorArray[colorArray.length - 1]);
  return genHexColors;
}

export function createColorStops(colors: string[], breaks: number[], value: string): Expression {
  const stops: Expression = ['step', ['feature-state', value], 'hsla(0, 0%, 0%, 0)'];
  for (let i = 0, len = colors.length; i < len; i += 1) {
    stops.push(breaks[i], colors[i]);
  }
  return stops;
}

export function getColorStops(property: string, colors: string[], breaks: number[]): Expression {
  const stops: Expression = ['step', ['get', property]];

  for (let i = 0, len = colors.length; i < len; i += 1) {
    stops.push(colors[i]);
    if (i < len - 1) stops.push(breaks[i + 1]);
  }

  return stops;
}
export const lngLatToCoord: (lngLat: LngLat) => Coordinate = (lngLat) => {
  return {
    latitude: lngLat.lat,
    longitude: lngLat.lng,
  } as Coordinate;
};

export const getBucketedOpacity = ({
  property,
  buckets,
  filters,
  opacity,
}: {
  property: string[];
  buckets: number[];
  filters: boolean[];
  opacity: number;
}): Expression => {
  const exp: Expression = ['step', property, 0];
  const stops = filters.reduce((acc, isVisible, idx) => {
    acc.push(buckets[idx], isVisible ? opacity : 0);
    return acc;
  }, exp);

  return ['case', ['==', property, null], 0, stops];
};

export const GEOJSON_SOURCE: GeoJSONSourceRaw = {
  type: 'geojson',
  data: {
    type: 'FeatureCollection',
    features: [],
  },
  maxzoom: MAX_ZOOM,
};

export const roundGeoCoordinate: (coord: number) => number = (coord) => {
  // The GeoJSON specification recommends 6 decimal places for latitude and longitude
  // which equates to roughly 10cm of precision. By rounding to 6 decimal places instead of JS's
  // default of 15, hopefully we can get some more re-use of data already in the cache.
  return Math.round(coord * 1000000) / 1000000;
};

export const getFeaturesAtClickedPoint: (mapboxMap: MapboxMap, evt: any) => any[] = (
  mapboxMap,
  evt,
) => {
  const bbox: [[number, number], [number, number]] = [
    [evt.point.x - 5, evt.point.y - 5],
    [evt.point.x + 5, evt.point.y + 5],
  ];
  const features = mapboxMap.queryRenderedFeatures(bbox);
  const filteredFeatures = features
    .filter((feat) => feat.source !== 'composite')
    .map((feat) => {
      return {
        id: feat.id,
        layer: feat.layer.id,
        state: feat.state,
        properties: feat?.properties,
      };
    });

  if (App.config.features.enabledebug) {
    App.debug(
      `***MOUSECLICK: Map Features underneath click at point [${evt.lngLat}]: `,
      filteredFeatures,
    );
  }
  return filteredFeatures;
};

export const getFitBoundsFromMaxExtents: (maxExtents: number[]) => FitBounds = (maxExtents) => {
  if (!maxExtents) {
    return null;
  }
  const fitBounds: FitBounds = [
    [maxExtents[0], maxExtents[1]],
    [maxExtents[2], maxExtents[3]],
  ];
  return fitBounds;
};

export const changeMapLanguage: (map: MapboxMap, language: string) => void = (map, language) => {
  const textLabelLayers = map
    .getStyle()
    .layers.filter(
      (layer) =>
        layer?.type === 'symbol' && layer?.source === 'composite' && layer?.layout?.['text-field'],
    );
  textLabelLayers.forEach((layer: SymbolLayer) => {
    map.setLayoutProperty(layer.id, 'text-field', [
      'coalesce',
      ['get', `name_${language}`],
      ['get', 'name'],
      ['get', 'name_en'],
      ['get', 'house_num'],
    ]);
  });
};
