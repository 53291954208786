import i18next from '../PlanningApp/LocalizationClient';

export const getFullName: (firstName: string, lastName: string) => string = (
  firstName,
  lastName,
) => {
  if (firstName && lastName) {
    return i18next.t('settings:sections:profile:personalInfo:fullName', { firstName, lastName });
  }
  if (firstName) {
    return firstName;
  }
  if (lastName) {
    return lastName;
  }
  return null;
};

export const clamp: (num: number, min: number, max: number) => number = (num, min, max) => {
  return Math.max(min, Math.min(num, max));
};

export const getNetworkStatusStr: (networkStatusCode: number) => string = (networkStatusCode) => {
  switch (networkStatusCode) {
    case 1:
      return 'loading';
    case 2:
      return 'setVariables';
    case 3:
      return 'fetchMore';
    case 4:
      return 'refetch';
    case 6:
      return 'poll';
    case 7:
      return 'ready';
    case 8:
      return 'error';
    default:
      return `unknown network status: ${networkStatusCode}`;
  }
};

export const averageArr: (nums: Array<number>, isDecimal: boolean) => number = (
  nums,
  isDecimal,
) => {
  if (!nums || !nums.length) return 0;
  const sum = nums.reduce((a, b) => a + b, 0);
  return isDecimal ? Math.floor(sum / nums.length) : sum / nums.length;
};

export const hexToRgba: (hexStr: string, opacity: number) => string = (hexStr, opacity) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, r1, r2, g1, g2, b1, b2] = hexStr.split('');
  const r = parseInt([r1, r2].join(''), 16);
  const g = parseInt([g1, g2].join(''), 16);
  const b = parseInt([b1, b2].join(''), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export function roundNumToPlace(num: number, digits?: number): number {
  return Number(num.toFixed(digits));
}
