import * as React from 'react';
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Translation } from 'react-i18next';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { ThemeProvider as ThemeProviderLegacy } from '@mui/styles';

import '../../assets/stylesheets/application.scss';
import { AMP_EVENT_END_SESSION, AMP_EVENT_LOGIN } from '../plugins/amplitudeevents';
import { AuthConfig, AuthContext, AuthInfo } from './auth/Authorizer';
import {
  getAmplitudeUserId,
  sendAmplitudeData,
  setAmplitudeUserId,
  setAmplitudeUserProperties,
} from '../plugins/amplitude';
import { Admin } from './auth/admin';
import { App } from './AppConfig';
import { client } from './client/Client';
import { DARKBLUE } from '../util/productGlobals';
import DatadogWidget from './DatadogWidget';
import ErrorPage from '../Components/CommonComponents/Errors/ErrorPage';
import LoadPrevSessionData from './LoadPrevSessionData';
import LocalizationClient from './LocalizationClient';
import { Login } from './auth/Login';
import LoginPoller from './auth/LoginPoller';
import { LogoutErr } from './auth/AuthError';
import PlanningApp from './PlanningApp';
import PlanningAppSkeleton from './PlanningAppSkeleton';
import { RouteFallbackComponent } from '../Components/CommonComponents/FallbackComponent/FallbackComponent';
import Spinner from '../Components/CommonComponents/Spinner/Spinner';
import StyledSnackbarProvider from '../Components/CommonComponents/Snackbar/StyledSnackbarProvider';
import theme from './theme/theme';
import useApiErrSnackbar from '../Components/CommonComponents/Snackbar/useApiErrSnackbar';
import { useGetCurrentUserAndSlicesQuery } from '../__generated__/graphql';

type PlanningAppContainerInnerProps = {
  handleLogout: () => void;
};
export const PlanningAppContainerInner: React.FC<PlanningAppContainerInnerProps> = ({
  handleLogout,
}) => {
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();

  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useGetCurrentUserAndSlicesQuery();

  const onClickLogout = React.useCallback(() => {
    handleLogout();
  }, [handleLogout]);

  React.useEffect(() => {
    if (userError) {
      enqueueApiErrSnackbar();
      App.error('[PlanningApp] - GetCurrentUserAndSlices error: ', userError);
    }
  }, [enqueueApiErrSnackbar, userError]);

  const user = userData?.user;
  const username = user?.personalInfo?.contact?.email;

  const handleAppUnload = React.useCallback(() => {
    // App is closing due to either 1) user clicking on logout button, 2) browser window/tab
    // closing, or 3) session expires
    App.debug('[PlanningAppContainerInner] - handleAppUnload');
    const ampUserId = getAmplitudeUserId();
    if (username && ampUserId) {
      sendAmplitudeData(AMP_EVENT_END_SESSION, {
        userId: username,
      });
      setAmplitudeUserId(null);
    }
    return null;
  }, [username]);

  React.useEffect(() => {
    if (!username) return undefined;
    window.addEventListener('beforeunload', handleAppUnload);
    return () => {
      window.removeEventListener('beforeunload', handleAppUnload);
    };
  }, [handleAppUnload, username]);

  const language = user?.preferences?.language;
  React.useEffect(() => {
    if (language) {
      document.documentElement.lang = language;
      if (language !== LocalizationClient.language) {
        LocalizationClient.changeLanguage(language);
      }
    }
  }, [language]);

  if (userLoading) {
    return <PlanningAppSkeleton />;
  }

  if (userError || !userData) {
    return (
      <ErrorPage
        errorCode={500}
        internalErrMsg={userError?.message ?? 'GetCurrentUserAndSlicesQuery: no data returned'}
      />
    );
  }

  return (
    <main
      id="main"
      className="o-flexrow-container o-flexstretch-container"
      style={{ backgroundColor: DARKBLUE }}
    >
      <PlanningApp user={user} onClickLogout={onClickLogout} />
      {/* load recentView data from LocalStorage */}
      {user && <LoadPrevSessionData userId={user.id} />}
    </main>
  );
};

type PlanningAppContainerState = {
  expired: boolean;
  showLogin: boolean;
  authcontext: AuthContext;
  poller: LoginPoller;
  apolloClient: ApolloClient<NormalizedCacheObject>;
  apolloClientLoaded: boolean;
};

class PlanningAppContainer extends React.Component<unknown, PlanningAppContainerState> {
  static displayName = 'PlanningAppContainer';

  constructor(props: unknown) {
    super(props);

    const authConfig = new AuthConfig({
      loginURL: App.config.authconfig.loginurl,
      homeURL: App.config.authconfig.homeurl,
      clientID: App.config.authconfig.clientid,
      cookieDomain: App.config.authconfig.cookiedomain,
    });

    this.state = {
      expired: false,
      showLogin: true,
      // state handed over to specialized components provided by our SDK
      authcontext: new AuthContext({
        config: authConfig,
      }),
      poller: new LoginPoller(
        authConfig.tokenURL,
        App.config.authconfig.refreshtokeninterval * 1000,
      ),
      apolloClient: null,
      apolloClientLoaded: false,
    };

    // tell the authcontext how to call use back on context change
    // TODO: support being called like this.state.authcontext.registerStateHandler(this);
    this.state.authcontext.registerStateHandler(this.handleAuthChange);
  }

  async componentDidMount() {
    try {
      const newClient = await client();
      this.setState((prevState) => ({
        ...prevState,
        apolloClient: newClient,
        apolloClientLoaded: true,
      }));
    } catch (error) {
      App.error('[PlanningAppContainer]  Error restoring Apollo cache', error);
    }
    // start polling to keep session alive
    const { poller } = this.state;
    if (poller) {
      poller.start();
    }
  }

  componentWillUnmount(): void {
    // stop polling
    const { poller } = this.state;
    if (poller && poller.started) {
      poller.stop();
    }
  }

  // handleAuthChange maintains state for managed components.
  //
  // This is used as a callback by managed components to lift
  // state and share important properties such as the login status,
  // user information or the value of the latest CSRF token.
  //
  // @param managedState is an AuthContext object.
  // TODO: we could do this more straightforward for SDK user
  // e.g. if passed a react object then the setState method is invoked
  handleAuthChange: (managedState: AuthContext) => void = (managedState) => {
    // app-specific logic here:
    // in this demo, after logout we want to just have a login button, not
    // to get the iframe again automatically. Hence the
    // showLogin state change. Remove this state change
    // if you want to automatically show the authentication iframe.
    this.setState({
      // this must be done to maintain state
      authcontext: managedState,

      // this is custom state property
      // TODO - we can probably remove the last line to get the app to navigate away from the
      // page to the login screen when the session times out.
      showLogin: managedState.isLogged(),
    });
  };

  // handleHasLogged is a custom (optional) login handler catching the
  // login-done event (e.g. saying hello)
  static handleHasLogged = (authctx: AuthInfo): void => {
    const claims = authctx.getClaims();
    const username = claims.preferred_username || claims.email || claims.name;
    setAmplitudeUserId(username);
    sendAmplitudeData(AMP_EVENT_LOGIN, { userId: username });
    setAmplitudeUserProperties({ organizations: authctx?.userinfo?.organizations });
    App.debug(`[PlanningApp] user is now logged in as username: '${username}'`);
  };

  handleLogout = (): void => {
    const { authcontext, apolloClient } = this.state;
    sessionStorage.removeItem('impersonator');

    const adm = new Admin(authcontext.config);
    adm
      .logout()
      .catch((e: any) => {
        // non blocking error
        App.error('[PlanningAppContainer] handleLogout warning: ', new LogoutErr(e));
      })
      .then(() => {
        this.setState({
          showLogin: false,
        });
      })
      .finally(() => {
        this.setState({
          showLogin: false,
        });
        authcontext.onAuthInfoChange(new AuthInfo({ didLogout: true }));
        // reset CSRF state
        if (authcontext.onCsrfChange && typeof authcontext.onCsrfChange === 'function') {
          authcontext.onCsrfChange('');
        }
        apolloClient.resetStore();
      });
  };

  toggleExpiry: () => void = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        expired: !prevState.expired,
      };
    });
  };

  // showExpiry displays some specific content when session has expired
  // TODO jake test session expiry
  showExpiry = (): React.ReactNode => {
    return (
      <Translation>
        {(t) => (
          <div>
            <div>{t('err:sessionExpired')}</div>
            <div>
              <button type="button" onClick={this.toggleExpiry}>
                {t('err:loginAgain')}
              </button>
            </div>
          </div>
        )}
      </Translation>
    );
  };

  render(): React.ReactNode {
    const { expired, authcontext, apolloClient, apolloClientLoaded } = this.state;

    if (expired) {
      return this.showExpiry();
    }

    if (!authcontext.isLogged()) {
      return <Login authcontext={authcontext} onChange={PlanningAppContainer.handleHasLogged} />;
    }

    if (!apolloClientLoaded) {
      return <Spinner />;
    }

    return (
      <ErrorBoundary FallbackComponent={RouteFallbackComponent}>
        <ApolloProvider client={apolloClient}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <ThemeProviderLegacy theme={theme}>
                <StyledSnackbarProvider>
                  <PlanningAppContainerInner handleLogout={this.handleLogout} />
                </StyledSnackbarProvider>
              </ThemeProviderLegacy>
            </ThemeProvider>
          </StyledEngineProvider>
          {(!App.config.features.enablemock || App.config.features.allowpartialmocks) && (
            <DatadogWidget />
          )}
        </ApolloProvider>
      </ErrorBoundary>
    );
  }
}

export default PlanningAppContainer;
