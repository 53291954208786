import * as React from 'react';

const usePreviousNonNullish = <T,>(value: T): T => {
  const ref = React.useRef<T>(value);
  React.useEffect(() => {
    if (value !== null && value !== undefined) {
      ref.current = value;
    }
  });
  return ref.current;
};

export default usePreviousNonNullish;
