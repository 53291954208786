import * as React from 'react';
import { debounce, isNull } from 'lodash';
import Geocoding, { GeocodeFeature, GeocodeRequest } from '@mapbox/mapbox-sdk/services/geocoding';
import { OnecWindow, SearchOptions } from 'onec-types';
import distance from '@turf/distance';
import { Map } from 'mapbox-gl';
import { MapiError } from '@mapbox/mapbox-sdk/lib/classes/mapi-error';
import { MapiResponse } from '@mapbox/mapbox-sdk/lib/classes/mapi-response';
import { point } from '@turf/helpers';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Autocomplete, { AutocompleteChangeReason } from '@mui/material/Autocomplete';

import {
  AMP_EVENT_FINANCE_SEARCH_LOCATION,
  AMP_EVENT_SEARCH_LOCATION,
  AMP_EVENT_SEARCH_LOCATION_LOCATION,
  AMP_EVENT_SEARCH_LOCATION_MAPBOX,
} from '../../../plugins/amplitudeevents';
import {
  BuiltFragmentFragmentDoc,
  LanguagePreferences,
  LocationFragmentFragmentDoc,
  NavigatorViewType,
  RecentViewType,
  SearchLocationWithFilterQueryResult,
  useAddRecentViewMutation,
  useGetCurrentUserQuery,
  useGetNavigatorInfoQuery,
  useGetRecentViewsQuery,
  UserRole,
  useSearchLocationWithFilterLazyQuery,
  useSearchNearestBuiltObjectLazyQuery,
  useSetNotFoundLocationMutation,
  useUpdateNavigatorInfoMutation,
} from '../../../__generated__/graphql';
import {
  getOptionLabel,
  renderLocationsSearchGroup,
  renderLocationsSearchInputField,
} from './locationsSearchHelpers';
import {
  DEMO_ACCOUNT_SUFFIX,
  FINANCE_HOME_PATH,
  HOME_PATH,
  MAPBOX_ADDRESS_LIMIT,
  MAPBOX_SEARCH_API_URL,
  MAPBOX_SEARCH_CHARACTER_LIMIT,
  MATCH_ADDRESS_DIST_LIMIT,
} from '../../../util/productGlobals';
import { App } from '../../../PlanningApp/AppConfig';
import { Lifeline } from '../../../util/productEnums';
import LocationsSearchListItemContainer from './LocationsSearchListItem';
import { sendAmplitudeData } from '../../../plugins/amplitude';
import useEntitledCountryCodes from '../../../Hooks/useEntitledCountryCodes';
import useSetPreviousMapBounds from '../../../Hooks/useSetPreviousMapBounds';
import useHasFinanceEntitlement from '../../../Hooks/useHasFinanceEntitlement';

type LocationsSearchProps = {
  userId: string;
};

const LocationsSearch: React.FC<LocationsSearchProps> = ({ userId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const mountedRef = React.useRef(true);
  const [inputValue, setInputValue] = React.useState<string>('');

  const [locationOptions, setLocationOptions] = React.useState<SearchOptions>([]);
  const [mapboxOptions, setMapboxOptions] = React.useState<SearchOptions>([]);
  const [selectedOption, setSelectedOption] = React.useState<SearchOptions[0]>(null);
  const [addRecentViewMutation] = useAddRecentViewMutation();
  const [updateNavigatorInfoMutation] = useUpdateNavigatorInfoMutation();

  const { data: RecentViewData, client } = useGetRecentViewsQuery({ variables: {} });
  const {
    data: {
      navigatorInfo: { currentView },
    },
  } = useGetNavigatorInfoQuery();

  const [
    searchLocationsWithFilter,
    {
      data: searchLocDataWithFilter,
      error: searchLocErrorWithFilter,
      loading: searchLocLoadingWithFilter,
    },
  ] = useSearchLocationWithFilterLazyQuery();

  const [
    matchBuiltObject,
    { data: matchData, error: matchError }, // TODO - deal with loading state
  ] = useSearchNearestBuiltObjectLazyQuery({
    onCompleted: () => handleNearestBuiltObject(), //eslint-disable-line
    onError: () => handleNearestBuiltObject(), //eslint-disable-line
  });
  const setMapBounds = useSetPreviousMapBounds();
  const entitledCountryCodes = useEntitledCountryCodes();
  const { data: userData } = useGetCurrentUserQuery();
  const [setNotFoundLocationMutation] = useSetNotFoundLocationMutation();
  const { enabledebug } = App.config.features;
  const isViewer = userData?.user?.role === UserRole.Viewer;
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;
  const ampSearchEvent = isFinanceEntitled
    ? AMP_EVENT_FINANCE_SEARCH_LOCATION
    : AMP_EVENT_SEARCH_LOCATION;
  const isDemoUser = userId?.endsWith(DEMO_ACCOUNT_SUFFIX);

  const setNoResults = React.useCallback(
    (type: SearchOptions[0]['groupType']) => {
      if (type === 'LocationObject') {
        setLocationOptions([
          {
            data: {
              id: 'INVALID_SEARCH',
              coordinates: [0, 0],
              name: inputValue,
              address: {
                formattedAddress: '',
                __typename: 'Address',
              },
            },
            groupType: 'LocationObject',
          },
        ] as SearchOptions);
      }
      if (type === 'MapboxObject') {
        const addressData: GeocodeFeature = {
          id: 'INVALID_SEARCH',
          text: inputValue,
        } as GeocodeFeature;
        setMapboxOptions([
          {
            data: addressData,
            groupType: 'MapboxObject',
          },
        ] as SearchOptions);
      }
    },
    [inputValue],
  );

  React.useEffect(() => {
    // Cleanup when leaving the autocomplete component
    return () => {
      mountedRef.current = false;
    };
  }, []);

  // Setting the data from the two apis
  React.useEffect(() => {
    if (inputValue === '' && RecentViewData) {
      // If the Search input control is empty (nothing typed into it), display the list
      // of RecentViews
      const { recentViews } = RecentViewData;
      const options: SearchOptions = [];
      if (recentViews && recentViews.length > 0) {
        recentViews.forEach((recentView) => {
          const locOrBobj = client.readFragment({
            id:
              recentView.type === RecentViewType.Location
                ? `LocationObject:{"id":"${recentView.id}"}`
                : `BuiltObject:{"id":"${recentView.id}"}`,
            fragment:
              recentView.type === RecentViewType.Location
                ? LocationFragmentFragmentDoc
                : BuiltFragmentFragmentDoc,
            fragmentName:
              recentView.type === RecentViewType.Location ? 'locationFragment' : 'builtFragment',
          });
          if (locOrBobj) {
            options.push({
              data: locOrBobj,
              groupType: 'RecentViewObject',
            } as SearchOptions[0]);
          } else {
            App.error(
              `[LocationSearch - RecentView error] - No ${
                recentView.type === RecentViewType.Location ? 'Location' : 'BuiltObject'
              } found in Cache for ${recentView.type}:${recentView.id}`,
            );
          }
        });
        // If we are displaying RecentViews, don't display other location matches
        // or mapbox address matches
        setLocationOptions(options);
      } else {
        setLocationOptions([] as SearchOptions);
      }
      setMapboxOptions([] as SearchOptions);
    } else {
      if (searchLocErrorWithFilter) {
        setNoResults('LocationObject');
        App.error(`[LocationSearch] API returned error ${searchLocErrorWithFilter.message}`);
      }

      if (searchLocDataWithFilter) {
        if (searchLocDataWithFilter.search.length === 0 && !searchLocLoadingWithFilter) {
          // If search comes up empty and is not loading
          // prompt options to contain invalid search
          setNoResults('LocationObject');
        } else {
          const options: SearchOptions = searchLocDataWithFilter.search.map(
            (searchData: SearchLocationWithFilterQueryResult['data']['search'][0]) =>
              ({
                data: searchData,
                groupType: 'LocationObject',
              } as SearchOptions[0]),
          );
          setLocationOptions(options);
        }
      }
    }
  }, [
    RecentViewData,
    client,
    searchLocDataWithFilter,
    searchLocErrorWithFilter,
    inputValue,
    searchLocLoadingWithFilter,
    setNoResults,
  ]);

  const sendAmplitudeEvent = React.useCallback(
    (selected: string, option: string) => {
      if (userId) {
        sendAmplitudeData(ampSearchEvent, {
          userId,
          input: inputValue,
          selected,
          searchOption: option,
        });
      }
    },
    [ampSearchEvent, inputValue, userId],
  );

  // After Selection has occured
  React.useEffect(() => {
    if (!isNull(selectedOption)) {
      if (selectedOption.data.id !== 'INVALID_SEARCH') {
        const map = (window as OnecWindow).reactMap as Map;
        if (!isNull(map)) {
          if (
            selectedOption.groupType === 'LocationObject' ||
            selectedOption.groupType === 'RecentViewObject'
          ) {
            const option =
              selectedOption.data as SearchLocationWithFilterQueryResult['data']['search'][0];
            setMapBounds();
            if (currentView !== NavigatorViewType.MapView)
              updateNavigatorInfoMutation({
                variables: {
                  currentView: NavigatorViewType.MapView,
                  currentLifeline: Lifeline.EMPTY,
                },
              });
            if (selectedOption.groupType === 'LocationObject') {
              sendAmplitudeEvent(option.name, AMP_EVENT_SEARCH_LOCATION_LOCATION);
            }
            navigate(`${isFinanceEntitled ? FINANCE_HOME_PATH : HOME_PATH}/detail/${option.id}`);
            // After selection remove selected id from autocomplete
            setSelectedOption(null);
          }
          if (selectedOption.groupType === 'MapboxObject') {
            const mapboxObject = selectedOption.data as GeocodeFeature;
            const { coordinates } = mapboxObject.geometry;
            // Japan Search API returns the matching address in mapboxObject.properties.place_name.
            // The Mapbox Geocoding API (used for US address) returns it in mapboxObject.place_ame
            const matchAddress = mapboxObject?.properties?.place_name ?? mapboxObject?.place_name;
            sendAmplitudeEvent(matchAddress, AMP_EVENT_SEARCH_LOCATION_MAPBOX);
            matchBuiltObject({ variables: { coordinates } });
          }
        } else App.error('[LocationsSearch] Map not found');
      }
    }
    /// Adding eslint-disable because sendAmplitudeEvent shouldn't be included
    /// in useEffect dependency since inputValue is changed after dropdown selection.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentView,
    isFinanceEntitled,
    matchBuiltObject,
    navigate,
    selectedOption,
    setMapBounds,
    updateNavigatorInfoMutation,
  ]);

  // After getting the buildObject data
  const handleNearestBuiltObject = React.useCallback(() => {
    let notFoundLocation = false;
    if (matchData?.searchNearestBuiltObject?.id && selectedOption?.groupType === 'MapboxObject') {
      const distanceBetween = distance(
        point((selectedOption?.data as GeocodeFeature).geometry?.coordinates),
        point(matchData?.searchNearestBuiltObject?.coordinates),
        {
          // don't need to use 'miles' here even if user's prefs are for imperial units,
          // since this "distance away" value is not displayed to the user.
          units: 'kilometers',
        },
      );
      if (distanceBetween * 1000 > MATCH_ADDRESS_DIST_LIMIT) {
        notFoundLocation = true;
      } else {
        addRecentViewMutation({
          variables: {
            id: String(matchData.searchNearestBuiltObject.id),
            type: RecentViewType.Building,
            userInput: JSON.stringify(selectedOption.data as GeocodeFeature),
          },
        });
        setMapBounds();
        if (currentView !== NavigatorViewType.MapView)
          updateNavigatorInfoMutation({
            variables: {
              currentView: NavigatorViewType.MapView,
              currentLifeline: Lifeline.EMPTY,
            },
          });
        navigate(
          `${isFinanceEntitled ? FINANCE_HOME_PATH : HOME_PATH}/detail/${
            matchData.searchNearestBuiltObject.id
          }`,
        );
        setSelectedOption(null);
        return;
      }
    }
    if (
      matchData?.searchNearestBuiltObject === null &&
      selectedOption?.groupType === 'MapboxObject'
    ) {
      notFoundLocation = true;
    }
    if (matchError) {
      App.error([
        '[SearchNearestBuiltObjectQuery] - No builtObject found error:',
        matchError.message,
      ]);
      notFoundLocation = true;
    }
    if (notFoundLocation) {
      const selectedOpt: GeocodeFeature = selectedOption?.data;
      setNotFoundLocationMutation({
        variables: {
          input: {
            address:
              selectedOpt?.place_name ??
              selectedOpt?.properties?.place_name ??
              selectedOpt?.properties?.address ??
              '',
            lng: selectedOpt.geometry?.coordinates?.[0],
            lat: selectedOpt.geometry?.coordinates?.[1],
          },
        },
      });
      navigate(`${isFinanceEntitled ? FINANCE_HOME_PATH : HOME_PATH}/detail/notFound`);
      setSelectedOption(null);
    }
  }, [
    addRecentViewMutation,
    currentView,
    isFinanceEntitled,
    matchData,
    matchError,
    navigate,
    selectedOption,
    setMapBounds,
    setNotFoundLocationMutation,
    updateNavigatorInfoMutation,
  ]);

  const onChange = React.useCallback(
    (_: React.SyntheticEvent, value: SearchOptions[0], reason: AutocompleteChangeReason) => {
      if (reason === 'selectOption' && value.data.id !== 'INVALID_SEARCH') {
        setSelectedOption(value);
      }
    },
    [],
  );

  const getMapboxAddrMatches = React.useCallback(
    (value: string) => {
      const geoCodingClient = Geocoding({
        accessToken: App.config.tokens.mapboxapi,
      });
      const proximity = (window as OnecWindow)?.reactMap?.getCenter().toArray() as [number, number];
      const geoCodingOptions: GeocodeRequest = {
        mode: 'mapbox.places',
        query: value,
        limit: MAPBOX_ADDRESS_LIMIT,
        types: ['address', 'neighborhood', 'locality', 'place', 'poi'],
      };
      if (userData?.user) {
        const { language } = userData.user.preferences;
        geoCodingOptions.language =
          enabledebug && language !== LanguagePreferences.En ? [language, 'en'] : [language];
        geoCodingOptions.countries = entitledCountryCodes;
      }
      if (proximity) {
        geoCodingOptions.proximity = proximity;
      }
      geoCodingClient
        .forwardGeocode(geoCodingOptions)
        .send()
        .then((response: MapiResponse) => {
          if (response.statusCode === 200) {
            const { features } = response.body;
            const results: SearchOptions = features.map(
              (feature: GeocodeFeature) =>
                ({
                  data: feature,
                  groupType: 'MapboxObject',
                } as SearchOptions[0]),
            );
            if (results.length > 0) {
              setMapboxOptions(results);
            } else setNoResults('MapboxObject');
          }
        })
        .catch((response: MapiError) => {
          App.error(
            `[LocationsSearch] Mapbox Geocoding API Error:${response.statusCode}: Reason: ${
              (response as MapiError).type
            }`,
            [response],
          );
          setNoResults('MapboxObject');
        });
    },
    [userData, enabledebug, setNoResults, entitledCountryCodes],
  );

  const getJpMapboxAddrMatches = React.useCallback(
    (value: string) => {
      const accessToken = App.config.tokens.mapboxapi;
      const proximity = (window as OnecWindow)?.reactMap?.getCenter().toArray() as [number, number];
      const urlParams = `access_token=${accessToken}&limit=${MAPBOX_ADDRESS_LIMIT}&proximity=${proximity}&language=ja&country=jp`;

      if (value.trim().length >= MAPBOX_SEARCH_CHARACTER_LIMIT) {
        const geocoderUrl = `${MAPBOX_SEARCH_API_URL}/forward/${value.trim()}?${urlParams}`;
        fetch(geocoderUrl)
          .then(
            (response) => {
              return response.json();
            },
            (err) => {
              App.error(
                `[LocationsSearch getJpMapboxAddrMatches] Mapbox Japan Search API Error:${
                  err.statusCode
                }: Reason: ${(err as MapiError).type}`,
                [err],
              );
              setNoResults('MapboxObject');
            },
          )
          .then(
            (jpMatchData: any) => {
              const addrMatches: SearchOptions = jpMatchData?.features?.map(
                (feature: GeocodeFeature) =>
                  ({
                    data: feature,
                    groupType: 'MapboxObject',
                  } as SearchOptions[0]),
              );

              App.debug('[LocationsSearch getJpMapboxAddrMatches]: addrMatches: ', addrMatches);
              if (addrMatches.length > 0) {
                setMapboxOptions(addrMatches);
              } else setNoResults('MapboxObject');
            },
            (err) => {
              App.error(
                `[LocationsSearch getJpMapboxAddrMatches] Mapbox Japan Search API Error:${
                  err.statusCode
                }: Reason: ${(err as MapiError).type}`,
                [err],
              );
              setNoResults('MapboxObject');
            },
          );
      }
    },
    [setNoResults],
  );

  const debounced = debounce((value: string) => {
    if (mountedRef.current) {
      setInputValue(value);

      if (value) {
        searchLocationsWithFilter({
          variables: {
            input: {
              text: value,
              matchCriteria: {
                isPendingMIB: false,
              },
            },
          },
        });

        if (value.trim().length >= MAPBOX_SEARCH_CHARACTER_LIMIT) {
          if (entitledCountryCodes.includes('JP')) {
            getJpMapboxAddrMatches(value);
          } else {
            getMapboxAddrMatches(value);
          }
        }
      }
    }
  }, 200);

  const clearText = t('search:clear');

  return (
    <Autocomplete
      id="location-autocomplete-search"
      getOptionLabel={getOptionLabel}
      options={[...locationOptions, ...mapboxOptions]}
      groupBy={(option) => option.groupType}
      filterOptions={(options) => options}
      style={{ width: isDemoUser ? 270 : 340 }}
      size="small"
      freeSolo
      autoComplete
      autoHighlight={false} // so first option is NOT automatically highlighted
      clearOnEscape
      clearOnBlur
      blurOnSelect
      clearText={clearText}
      onInputChange={(_, newInputValue) => {
        debounced(newInputValue);
      }}
      onChange={onChange}
      renderInput={renderLocationsSearchInputField}
      renderGroup={renderLocationsSearchGroup}
      renderOption={(props, option: SearchOptions[0]) => (
        <LocationsSearchListItemContainer
          containerProps={props}
          option={option}
          inputValue={inputValue}
          isViewer={isViewer}
          key={option?.data?.action?.body?.id || option?.data?.id || props?.id}
        />
      )}
    />
  );
};

LocationsSearch.displayName = 'LocationsSearch';
export default LocationsSearch;
