import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';

type SkeletonInputProps = {
  id: string;
};

const SkeletonInput = ({ id }: SkeletonInputProps) => {
  return (
    <Skeleton
      variant="rectangular"
      height={36}
      width={76}
      data-test-id={`skeleton-input-${id}`}
      sx={{ borderRadius: 100 }}
    />
  );
};

export default SkeletonInput;
