import { RoiBuiltObject } from '../../../__generated__/graphql';
import { roiBuiltObjectMutations } from '../resolvers/ROIBuiltObjectResolver';

export const STORE_DEFAULTS = {
  roiBuiltObjects: [] as RoiBuiltObject[],
};

export const roiBuiltObjectStore = {
  defaults: STORE_DEFAULTS,
  mutations: roiBuiltObjectMutations,
};
