import * as React from 'react';
import { Source } from 'react-mapbox-gl';

import { LocationType } from '../../locationViewHelpers';
import { makeGeoJson } from './clusterHelpers';

type ClusterLayerSourceProps = {
  locations: LocationType[];
  sourceId: string;
  clusterRadius?: number;
};
export const ClusterLayerSourceFC: React.FC<ClusterLayerSourceProps> = ({
  locations,
  sourceId,
  clusterRadius = 75,
}) => {
  const geoJson = makeGeoJson(locations);

  return (
    <Source
      id={sourceId}
      geoJsonSource={{
        ...geoJson,
        cluster: true,
        clusterMaxZoom: 15,
        clusterRadius,
      }}
    />
  );
};

const ClusterLayerSource = React.memo(ClusterLayerSourceFC);
ClusterLayerSource.displayName = 'ClusterLayerSource';
export default ClusterLayerSource;
