import { createStyles, withStyles } from '@mui/styles';
import LinearProgress from '@mui/material/LinearProgress';

import { GRAY5 } from '../../../util/productGlobals';

const StyledLinearProgress = withStyles(() =>
  createStyles({
    root: {
      height: 6,
      borderRadius: 100,
    },
    colorPrimary: {
      backgroundColor: GRAY5,
    },
    bar: {
      borderRadius: 40,
      backgroundColor: '#C4C4C4',
    },
  }),
)(LinearProgress);

export default StyledLinearProgress;
