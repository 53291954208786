export function cssSupported(property: string, value: string): boolean {
  return window.CSS && CSS.supports && CSS.supports(property, value);
}

export function getCheckboxState(filters: boolean[]): {
  isChecked: boolean;
  isIndeterminate: boolean;
} {
  let shown = 0;
  let hidden = 0;

  for (const isSelected of filters) {
    if (isSelected) {
      shown += 1;
    } else {
      hidden += 1;
    }
  }

  const { length } = filters;
  const allChecked = shown === length;
  return { isChecked: allChecked, isIndeterminate: !allChecked && hidden !== length };
}

export default { cssSupported, getCheckboxState };
