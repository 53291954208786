import * as React from 'react';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LIST_HIGHLIGHT, MAX_CHIP_LABEL_LENGTH, WHITE } from '../../../util/productGlobals';

export type ChipProps = {
  label: string;
  additionalClasses?: string;
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      borderRadius: 100,
      display: 'inline-flex',
      position: 'relative',
      backgroundColor: (props: { isLabelOver: boolean }) =>
        props.isLabelOver ? '#373E45' : LIST_HIGHLIGHT,
    },
    label: {
      color: WHITE,
    },
    cutOff: {
      background:
        'linear-gradient(270deg, #373E45 37.14%, rgba(55, 62, 69, 0.56) 73.17%, rgba(55, 62, 69, 0) 102.55%)',
      position: 'absolute',
      width: 26,
      height: 26,
      top: 0,
      right: 0,
      borderTopRightRadius: 100,
      borderBottomRightRadius: 100,
    },
  };
});

const Chip: React.FC<ChipProps> = ({ label, additionalClasses = '', ...other }) => {
  const isLabelOver = React.useMemo(() => label.length > MAX_CHIP_LABEL_LENGTH, [label]);
  const classes = useStyles({ isLabelOver });
  return (
    <div className={`${classes.root} ${additionalClasses}`} {...other}>
      <Typography variant="caption" className={classes.label}>
        {label.substring(0, MAX_CHIP_LABEL_LENGTH)}
      </Typography>
      {isLabelOver && <div className={classes.cutOff} />}
    </div>
  );
};

Chip.displayName = 'Chip';
export default Chip;
