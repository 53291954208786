import amplitude from 'amplitude-js';

import { AMPLITUDE_INSTANCE } from '../util/productGlobals';
import { App } from '../PlanningApp/AppConfig';

export const initAmplitude = () => {
  if (App.config.features.enableamplitude) {
    amplitude.getInstance(AMPLITUDE_INSTANCE).init(App.config.tokens.amplitude);
  }
};

// export const setAmplitudeUserDevice = (installationToken: string) => {
//   if (App.config.features.enableamplitude) {
//     amplitude.getInstance(AMPLITUDE_INSTANCE).setDeviceId(installationToken);
//   }
// };

export const setAmplitudeUserId = (userId: string) => {
  if (App.config.features.enableamplitude) {
    //  Prevent usernames in the local or development environments from polluting the staging
    // amplitude logs when there are staging users with the same email/username in dev/local.
    let envPrefix = '';
    const [hostPart, envPart] = document.location.hostname.split('.');
    if (hostPart.includes('local')) {
      envPrefix = 'LOCAL:';
    } else if (envPart === 'dev') {
      envPrefix = 'DEV:';
    }
    amplitude.getInstance(AMPLITUDE_INSTANCE).setUserId(`${envPrefix}${userId}`);
  }
};

export const getAmplitudeUserId = (): string => {
  if (App.config.features.enableamplitude) {
    return amplitude.getInstance(AMPLITUDE_INSTANCE).getUserId();
  }
  return null;
};

export const setAmplitudeUserProperties = (properties: any) => {
  if (App.config.features.enableamplitude) {
    amplitude.getInstance(AMPLITUDE_INSTANCE).setUserProperties(properties);
  }
};

export const sendAmplitudeData = (eventType: string, eventProperties: any) => {
  if (App.config.features.enableamplitude) {
    amplitude.getInstance(AMPLITUDE_INSTANCE).logEvent(eventType, eventProperties);
  }
};
