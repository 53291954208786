import { MeasurementPreferences, UserDetailsFragment } from '../__generated__/graphql';
import { DEFAULT_USER_PREFS } from './productGlobals';

// We need this 'string' enum type instead of the Enum in UserDetails.Preferences
// because i18next uses it in translation lookup.
// i.e. t('velocity', {context: 'metric'}  looks up the translation key 'velocity_metric'
export type MeasureSystemType = 'metric' | 'imperial';

// Used so we can display proper plural/singular form of unit labels across several languages
export type PluralType = 'zero' | 'one' | 'other';
type PluralDictType = {
  [key: string]: number;
};

export const pluralTypeToNum: (plural: PluralType) => number = (plural) => {
  const pluralTypeDict: PluralDictType = {
    zero: 0,
    one: 1,
    other: 101,
  };
  return pluralTypeDict[plural];
};

export const getLocale: (userPrefs?: UserDetailsFragment['preferences']) => string = (
  userPrefs = DEFAULT_USER_PREFS,
) => {
  return `${userPrefs.language.toLowerCase()}-${userPrefs.countryCode.toUpperCase()}`;
};

export const getUnitPrefs: (userPrefs?: UserDetailsFragment['preferences']) => MeasureSystemType = (
  userPrefs = DEFAULT_USER_PREFS,
) => {
  return userPrefs?.units === MeasurementPreferences.ImperialSystem ? 'imperial' : 'metric';
};
