import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

interface DetailCardBackButtonFCProps {
  clickFunc?: () => void;
  backUrl?: string;
  buttonText?: string;
}

const useStyles = makeStyles(() => ({
  button: {
    minWidth: 32,
    padding: 0,
  },
  startIcon: {
    margin: '0px !important',
  },
  buttonWithText: {
    padding: 5,
  },
}));

const DetailCardBackButtonFC: React.FC<DetailCardBackButtonFCProps> = ({
  clickFunc,
  backUrl,
  buttonText,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const goBackToPreviousView = React.useCallback(() => {
    if (backUrl) {
      navigate(backUrl);
    } else {
      // Go to previous view if backUrl is not given
      navigate(-1);
    }
  }, [backUrl, navigate]);

  if (buttonText) {
    return (
      <Button
        className={`u-clickable ${classes.buttonWithText}`}
        startIcon={<ArrowBackIcon fontSize="small" />}
        size="small"
        classes={{
          startIcon: classes.startIcon,
        }}
        onClick={clickFunc || goBackToPreviousView}
      >
        {buttonText}
      </Button>
    );
  }
  return (
    <Button
      className={`u-clickable ${classes.button}`}
      startIcon={<ArrowBackIcon fontSize="small" />}
      size="small"
      classes={{
        startIcon: classes.startIcon,
      }}
      onClick={clickFunc || goBackToPreviousView}
    />
  );
};

const DetailCardBackButton = React.memo(DetailCardBackButtonFC);
export default DetailCardBackButton;
