import * as React from 'react';
import { ApolloError } from '@apollo/client';

import {
  GetBuiltObjectMarkerQuery,
  useGetBuiltObjectMarkerQuery,
  useGetSliceIdxQuery,
  useGetThresholdsQuery,
} from '../__generated__/graphql';
import { App } from '../PlanningApp/AppConfig';
import { calcVulScoreFromResStats } from '../util/vulnerability';
import useApiErrSnackbar from '../Components/CommonComponents/Snackbar/useApiErrSnackbar';
import useLifelineEntitlements from './useLifelineEntitlements';

export type BuiltObjectWithScore = GetBuiltObjectMarkerQuery['builtObject'] & {
  vulnerabilityScore: number;
};

type UseGetBobjWithScoreReturnType = {
  builtObjectWithScore?: BuiltObjectWithScore;
  loading?: boolean;
  error?: ApolloError;
};

type UseGetBobjWithScoreInputType = { id: string; skip: boolean };

function useGetBobjWithScore({
  id,
  skip = false,
}: UseGetBobjWithScoreInputType): UseGetBobjWithScoreReturnType {
  const entitlements = useLifelineEntitlements();
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();

  const {
    data: {
      planView: { sliceIdx: activeSlice },
    },
  } = useGetSliceIdxQuery();
  if (!activeSlice) {
    App.error('[useSliceLocations] - Bad sliceIdx.');
  }

  const {
    data: builtData,
    loading: builtLoading,
    error: builtError,
  } = useGetBuiltObjectMarkerQuery({
    skip: !id || skip,
    variables: { id, slice: activeSlice },
  });

  const {
    data: thresholdsData,
    loading: thresholdsLoading,
    error: thresholdsError,
  } = useGetThresholdsQuery({
    skip: !id || skip,
  });

  React.useEffect(() => {
    if (thresholdsError) {
      enqueueApiErrSnackbar();
      App.error('[useGetBobjWithScore] GetThresholdsQuery error: ', thresholdsError);
    }
  }, [thresholdsError, enqueueApiErrSnackbar]);

  const bobjVulScore = React.useMemo<number>(() => {
    if (skip) return null;
    const thresholds = thresholdsData?.user?.productSettings?.thresholds;
    const bobjResStats = builtData?.builtObject?.resilienceStats?.[0];
    // TODO: DO I NEED TO TEST FOR thresholdsError, thresholdsLoading, builtData, builtLoading ??
    if (thresholds && bobjResStats && entitlements) {
      const score = calcVulScoreFromResStats(bobjResStats, thresholds, entitlements);
      return score;
    }
    return null;
  }, [thresholdsData, builtData, skip, entitlements]);

  if (skip) {
    return { builtObjectWithScore: null, loading: false, error: null };
  }

  if (builtError) {
    App.warn(
      '[useGetBobjWithScore]: GetBuiltObjectMarkerQuery error. ',
      +`A builtObject with id ${id} probably does not exist. error: `,
      builtError,
    );
  }

  if (!builtData?.builtObject || bobjVulScore === undefined || bobjVulScore === null) {
    return {
      builtObjectWithScore: null,
      loading: thresholdsLoading || builtLoading,
      error: thresholdsError || builtError,
    };
  }

  const builtObjectWithScore: BuiltObjectWithScore = {
    ...builtData?.builtObject,
    vulnerabilityScore: bobjVulScore,
  };

  return {
    builtObjectWithScore,
    loading: thresholdsLoading || builtLoading || !entitlements,
    error: thresholdsError || builtError,
  };
}
useGetBobjWithScore.dispalyName = 'useGetBobjWithScore';
export default useGetBobjWithScore;
