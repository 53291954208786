/* eslint-disable jsx-a11y/anchor-has-content */
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { App } from '../../../PlanningApp/AppConfig';

/**
 * The fallback component is a generic error page used with <ErrorBoundary> components to catch
 * unforeseen errors.
 */

export function DebugFallbackComponent({ componentStack, error }: any) {
  if (App.config.features.enabledebug) {
    return (
      <div>
        <p>
          <strong>Oops! An error occurred!</strong>
        </p>
        <p>Here’s what we know…</p>
        <p>
          <strong>Error:</strong> {error.toString()}
        </p>
        <p>
          <strong>Stacktrace:</strong> {componentStack}
        </p>
      </div>
    );
  }

  return <div />;
}

export function RouteFallbackComponent({ componentStack, error }: any) {
  const { t } = useTranslation();

  if (App.config.features.enabledebug) {
    return DebugFallbackComponent({ componentStack, error });
  }

  return (
    <div className="o-flex-center-container o-flexcol-container u-width--100 u-height--100">
      <h4>{t('err:fallbackError')}</h4>
      <div>
        <Trans i18nKey="err:fallbackCTA">
          <a className="f-blue" href={t('err:contactEmail')} aria-label="One Concern Contact" />
        </Trans>
      </div>
    </div>
  );
}

RouteFallbackComponent.displayName = 'RouteFallbackComponent';
