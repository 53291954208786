import * as React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import LocationAirportStableIcon from '../../../../assets/images/Symbolic/icon-airport-stable.png';
import LocationAirportVulnerableIcon from '../../../../assets/images/Symbolic/icon-airport-vulnerable.png';
import LocationBridgeStableIcon from '../../../../assets/images/Symbolic/icon-bridge-stable.png';
import LocationBridgeVulnerableIcon from '../../../../assets/images/Symbolic/icon-bridge-vulnerable.png';
import LocationHighwayStableIcon from '../../../../assets/images/Symbolic/icon-highway-stable.png';
import LocationHighwayVulnerableIcon from '../../../../assets/images/Symbolic/icon-highway-vulnerable.png';
import LocationPeopleStableIcon from '../../../../assets/images/Symbolic/icon-people-stable.png';
import LocationPeopleVulnerableIcon from '../../../../assets/images/Symbolic/icon-people-vulnerable.png';
import LocationPowerStableIcon from '../../../../assets/images/Symbolic/icon-power-stable.png';
import LocationPowerVulnerableIcon from '../../../../assets/images/Symbolic/icon-power-vulnerable.png';
import LocationSeaportStableIcon from '../../../../assets/images/Symbolic/icon-seaport-stable.png';
import LocationSeaportVulnerableIcon from '../../../../assets/images/Symbolic/icon-seaport-vulnerable.png';
import LocationStableIcon from '../../../../assets/images/Symbolic/icon-location-stable.png';
import LocationStructureStableIcon from '../../../../assets/images/Symbolic/icon-structure-stable.png';
import LocationStructureVulnerableIcon from '../../../../assets/images/Symbolic/icon-structure-vulnerable.png';
import LocationVulnerableIcon from '../../../../assets/images/Symbolic/icon-location-vulnerable.png';

import {
  ForecastPeriodType,
  GetBuiltObjectLifelineStatsQuery,
  GetLocationLifelineStatsQuery,
  NavigatorViewType,
  ResilienceStats,
  ResilienceThresholds,
  Slice,
  useGetBuiltObjectLifelineStatsLazyQuery,
  useGetBuiltObjectLifelineStatsQuery,
  useGetFirstToFailQuery,
  useGetLocationLifelineStatsLazyQuery,
  useGetLocationLifelineStatsQuery,
  useGetPlanViewFiltersQuery,
  useGetSliceIdxQuery,
  useGetSliceIndexesQuery,
  useGetThresholdsQuery,
  useUpdateNavigatorInfoMutation,
} from '../../../__generated__/graphql';
import {
  DEMO_ACCOUNT_SUFFIX,
  RETURN_PERIOD_YEAR_OPTIONS,
  TOP_CONCERNS_DARK_BG_GRADIENT,
  TOP_CONCERNS_LIGHT_BG_GRADIENT,
} from '../../../util/productGlobals';
import { App } from '../../../PlanningApp/AppConfig';
import { averageArr } from '../../../util/utils';
import { calcVulScoreFromResStats } from '../../../util/vulnerability';
import { capitalize } from '../../../util/string';
import { getActiveSliceIdx } from '../locationViewHelpers';
import { hoursToDayHourAbbrev } from '../../../util/time';
import { Lifeline } from '../../../util/productEnums';
import LifelineVulnerableDetail from './LifelineVulnerableDetail';
import LocationBar from './LocationBar';
import Spinner from '../../CommonComponents/Spinner/Spinner';
import SymbolicAsset from './SymbolicAsset';
import useApiErrSnackbar from '../../CommonComponents/Snackbar/useApiErrSnackbar';
import useLifelineEntitlements from '../../../Hooks/useLifelineEntitlements';
import useHasFinanceEntitlement from '../../../Hooks/useHasFinanceEntitlement';

interface SymbolicViewProps {
  currentView: NavigatorViewType;
  currentLifeline: string;
  locOrBobj:
    | GetLocationLifelineStatsQuery['location']
    | GetBuiltObjectLifelineStatsQuery['builtObject'];
  slices: Slice[];
  serverThresholds: ResilienceThresholds;
  showROI?: boolean;
  userId: string;
}

interface SymbolicViewContainerProps {
  currentView: NavigatorViewType;
  currentLifeline: string;
  showROI?: boolean;
  userId: string;
}

interface SymbolicViewStyleProps {
  currentLifeline: string;
  isClimateChangeEnabled: boolean;
  isDemoUser?: boolean;
}

type LifelineVulnerableInfo = {
  isVulnerable: boolean;
  downtime: string;
  vulnerableCount: number;
  totalCount: number;
};

type LocationVulnerableStatus = {
  location: boolean;
  people: LifelineVulnerableInfo;
  power: LifelineVulnerableInfo;
  structure: LifelineVulnerableInfo;
  port: LifelineVulnerableInfo;
  airport: LifelineVulnerableInfo;
  bridge: LifelineVulnerableInfo;
  highway: LifelineVulnerableInfo;
};

type LifelineStat = {
  id?: string;
  stats?: [
    {
      mean?: number;
    },
  ];
};

function createTopConcernsData(
  isVulnerable: boolean,
  downtime: string,
  vulnerableCount: number,
  totalCount: number,
) {
  return { isVulnerable, downtime, vulnerableCount, totalCount };
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    background: (props: SymbolicViewStyleProps) =>
      props.isClimateChangeEnabled ? TOP_CONCERNS_DARK_BG_GRADIENT : TOP_CONCERNS_LIGHT_BG_GRADIENT,
    zIndex: 2,
  },
  symbolicLayout: {
    position: 'relative',
    width: (props: SymbolicViewStyleProps) =>
      props.currentLifeline !== Lifeline.EMPTY && !props.isDemoUser ? 'calc(100% - 410px)' : '100%',
    height: 'calc(100% - 304px)',
    margin: '120px 0px 166px 0px',
  },
  location: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 277,
    height: 257,
  },
  lifeLinePeopleDetail: {
    position: 'absolute',
    left: 'calc(50% - 483px)',
    top: 'calc(50% - 204px)',
  },
  lifeLinePeople: {
    position: 'absolute',
    right: 'calc(50% + 62px)',
    top: 'calc(50% - 204px)',
    width: 358,
    height: 257,
  },
  lifeLinePowerDetail: {
    position: 'absolute',
    left: 'calc(50% - 60px)',
    top: 'calc(50% - 295px)',
  },
  financeLifeLinePowerDetail: {
    position: 'absolute',
    left: 'calc(50% - 360px)',
    top: 'calc(50% - 232px)',
  },
  lifeLinePower: {
    position: 'absolute',
    right: 'calc(50% -  35px)',
    top: 'calc(50% - 285px)',
    width: 314,
    height: 262,
  },
  financeLifeLinePower: {
    position: 'absolute',
    right: 'calc(50% -  19px)',
    top: 'calc(50% - 274px)',
    width: 314,
    height: 262,
  },
  lifeLineStructureDetail: {
    position: 'absolute',
    left: 'calc(50% + 280px)',
    top: 'calc(50% - 231px)',
  },
  lifeLineStructure: {
    position: 'absolute',
    right: 'calc(50% - 357px)',
    top: 'calc(50% - 248px)',
    width: 313,
    height: 254,
  },
  lifeLineSeaport: {
    position: 'absolute',
    left: 'calc(50% + 72px)',
    top: 'calc(50% - 57px)',
    width: 358,
    height: 267,
  },
  lifeLineSeaportDetail: {
    position: 'absolute',
    top: 'calc(50% + 128px)',
    left: 'calc(50% + 343px)',
  },
  lifeLineAirportDetail: {
    position: 'absolute',
    top: 'calc(50% + 202px)',
    left: 'calc(50% + 191px)',
  },
  lifeLineAirport: {
    position: 'absolute',
    left: 'calc(50% - 7px)',
    top: 'calc(50% + 34px)',
    width: 294,
    height: 242,
  },
  lifeLineBridgeDetail: {
    position: 'absolute',
    top: 'calc(50% + 201px)',
    left: 'calc(50% - 343px)',
  },
  lifeLineBridge: {
    position: 'absolute',
    right: 'calc(50% - 28px)',
    top: 'calc(50% + 27px)',
    width: 310,
    height: 257,
  },
  lifeLineHighwayDetail: {
    position: 'absolute',
    left: 'calc(50% - 483px)',
    top: 'calc(50% + 96px)',
  },
  lifeLineHighway: {
    position: 'absolute',
    right: 'calc(50% + 61px)',
    top: 'calc(50% - 56px)',
    width: 359,
    height: 257,
  },
});

const SymbolicViewSkeleton: React.FC<{ currentLifeline: string }> = ({ currentLifeline }) => {
  const classes = useStyles({ currentLifeline, isClimateChangeEnabled: false });
  return (
    <div className={classes.container} data-test-id="SymbolicViewSkeleton">
      <Spinner />
    </div>
  );
};
SymbolicViewSkeleton.displayName = 'SymbolicViewSkeleton';

const SymbolicViewFC: React.FC<SymbolicViewProps> = ({
  currentView,
  currentLifeline,
  locOrBobj,
  slices,
  serverThresholds,
  showROI,
  userId,
}) => {
  const { t } = useTranslation();
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();
  const entitlements = useLifelineEntitlements();
  const countryCode = locOrBobj?.address?.countryCode?.toUpperCase();
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;
  const loadDnaStats = isFinanceEntitled ? true : undefined;

  const [vulnerableStatus, setVulnerableStatus] = React.useState<LocationVulnerableStatus>({
    location: false,
    people: createTopConcernsData(false, '', 0, 0),
    power: createTopConcernsData(false, '', 0, 0),
    structure: createTopConcernsData(false, '', 0, 0),
    port: createTopConcernsData(false, '', 0, 0),
    airport: createTopConcernsData(false, '', 0, 0),
    bridge: createTopConcernsData(false, '', 0, 0),
    highway: createTopConcernsData(false, '', 0, 0),
  });
  const {
    data: {
      firstToFailView: { returnPeriodIndex },
    },
  } = useGetFirstToFailQuery();

  const {
    data: {
      planView: { planFilters },
    },
  } = useGetPlanViewFiltersQuery();

  const classes = useStyles({
    currentLifeline,
    isClimateChangeEnabled: planFilters?.isClimateChangeEnabled,
    isDemoUser: userId.endsWith(DEMO_ACCOUNT_SUFFIX),
  } as SymbolicViewStyleProps);
  const [updateNavigatorInfoMutation] = useUpdateNavigatorInfoMutation();

  // Get Location's Lifeline & ResStats for multiple slices, for First-to-Fail analysis
  const [
    getFtfLocationLifelineStatsLazyQuery,
    { data: locFtfLifelineData, error: locFtfLifelineErr },
  ] = useGetLocationLifelineStatsLazyQuery();

  // Get BuiltObject's Lifeline & ResStats for multiple slices, for First-to-Fail analysis
  const [
    getFtfBuiltObjectLifelineStatsLazyQuery,
    { data: bobjFtfLifelineData, error: bobjFtfLifelineErr },
  ] = useGetBuiltObjectLifelineStatsLazyQuery();

  const selectLifeline = React.useCallback(
    (lifeline: Lifeline) => {
      if (currentView === NavigatorViewType.TopConcernsView)
        updateNavigatorInfoMutation({
          variables: {
            currentView,
            currentLifeline: lifeline === currentLifeline ? Lifeline.EMPTY : lifeline,
          },
        });
    },
    [currentLifeline, currentView, updateNavigatorInfoMutation],
  );

  const formatToDayHours = React.useCallback((hr: number) => {
    return hoursToDayHourAbbrev(hr);
  }, []);

  const getLifelineInfo = React.useCallback(
    (lifeline: Lifeline, resilienceStats: ResilienceStats[], lifelineStats?: LifelineStat[]) => {
      const downtime =
        lifeline === Lifeline.STRUCTURE
          ? averageArr(
              resilienceStats.map((rStat) => rStat.structuralDamage?.mean),
              true,
            )
          : averageArr(
              resilienceStats.map(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (rStat: Record<string, any>) => rStat[`nearby${capitalize(lifeline)}`]?.mean,
              ),
              true,
            );
      const threshold =
        lifeline === Lifeline.STRUCTURE
          ? serverThresholds.structuralDamage
          : (serverThresholds as Record<string, number | string>)[`${lifeline}Downtime`] || 0;
      const isVulnerable = downtime > Number(threshold);

      const totalCount = isFinanceEntitled ? null : lifelineStats?.length;
      let vulnerableCount = 0;

      if (!isFinanceEntitled) {
        if (lifeline === Lifeline.STRUCTURE || lifeline === Lifeline.POWER) {
          vulnerableCount = isVulnerable ? 1 : 0;
        } else {
          vulnerableCount = lifelineStats?.filter(
            (item) =>
              averageArr(
                item.stats.map((stat) => stat?.mean),
                true,
              ) > Number(threshold),
          ).length;
        }
      } else vulnerableCount = null;

      return {
        isVulnerable,
        downtime: lifeline !== Lifeline.STRUCTURE ? formatToDayHours(downtime) : `${downtime}%`,
        vulnerableCount,
        totalCount,
      };
    },
    [serverThresholds, isFinanceEntitled, formatToDayHours],
  );

  React.useEffect(() => {
    const getLifelineStatsData = (
      queryData:
        | GetLocationLifelineStatsQuery['location']
        | GetBuiltObjectLifelineStatsQuery['builtObject'],
    ) => {
      const resilienceStats: ResilienceStats[] = queryData?.resilienceStats;
      if (resilienceStats.length === 0) return;
      const newVulnerableStatus = {
        location:
          averageArr(
            resilienceStats.map((rStat) => {
              const vulnerabilityScore = calcVulScoreFromResStats(
                rStat,
                serverThresholds,
                entitlements,
              );
              return vulnerabilityScore;
            }),
            false,
          ) > 0,
        people: getLifelineInfo(
          Lifeline.PEOPLE,
          resilienceStats,
          queryData?.people as LifelineStat[],
        ),
        power: getLifelineInfo(Lifeline.POWER, resilienceStats, queryData?.power),
        structure: getLifelineInfo(Lifeline.STRUCTURE, resilienceStats, queryData?.structural),
        port: getLifelineInfo(Lifeline.PORT, resilienceStats, queryData?.ports as LifelineStat[]),
        airport: getLifelineInfo(
          Lifeline.AIRPORT,
          resilienceStats,
          queryData?.airports as LifelineStat[],
        ),
        bridge: getLifelineInfo(
          Lifeline.BRIDGE,
          resilienceStats,
          queryData?.bridges as LifelineStat[],
        ),
        highway: getLifelineInfo(
          Lifeline.HIGHWAY,
          resilienceStats,
          queryData?.highways as LifelineStat[],
        ),
      };
      setVulnerableStatus(newVulnerableStatus);
    };

    if (currentView === NavigatorViewType.TopConcernsView) {
      getLifelineStatsData(locOrBobj);
    } else if (currentView === NavigatorViewType.FirstToFailView && returnPeriodIndex > 0) {
      if (locOrBobj.__typename === 'LocationObject' && locFtfLifelineData?.location) {
        getLifelineStatsData(locFtfLifelineData?.location);
      } else if (locOrBobj.__typename === 'BuiltObject' && bobjFtfLifelineData?.builtObject) {
        getLifelineStatsData(bobjFtfLifelineData?.builtObject);
      }
    }
  }, [
    bobjFtfLifelineData,
    locOrBobj,
    currentView,
    locFtfLifelineData,
    getLifelineInfo,
    returnPeriodIndex,
    serverThresholds,
    entitlements,
  ]);

  const ftfErr = locFtfLifelineErr || bobjFtfLifelineErr;
  React.useEffect(() => {
    if (ftfErr) {
      enqueueApiErrSnackbar();
      App.error(
        `[SymbolicViewContainer]: ${
          locOrBobj.__typename === 'LocationObject'
            ? 'GetLocationLifelineStatsQuery'
            : 'GetBuiltObjectLifelineStatsQuery'
        } error: ${ftfErr.message} }`,
      );
    }
  }, [ftfErr, locOrBobj, enqueueApiErrSnackbar]);

  React.useEffect(() => {
    if (currentView !== NavigatorViewType.FirstToFailView) return;
    if (returnPeriodIndex > 0) {
      const returnPeriodFilters = {
        ...planFilters,
        forecastPeriod: {
          ...planFilters.forecastPeriod,
          forecastPeriodType: ForecastPeriodType.ReturnPeriod,
          duration: RETURN_PERIOD_YEAR_OPTIONS[returnPeriodIndex - 1],
        },
      };
      const matchSliceId = getActiveSliceIdx(slices, returnPeriodFilters);
      const selectedSlice = slices.find((sliceDef) => sliceDef.slice === matchSliceId);
      const sliceIds = slices
        .filter(
          (sliceDef) =>
            sliceDef.returnPeriod === RETURN_PERIOD_YEAR_OPTIONS[returnPeriodIndex - 1] &&
            !sliceDef.planningHorizon &&
            selectedSlice &&
            sliceDef.climate === selectedSlice.climate &&
            sliceDef.hazardType === selectedSlice.hazardType &&
            sliceDef.historicalEventID === selectedSlice.historicalEventID,
        )
        .map((sliceDef) => sliceDef.slice);

      if (locOrBobj.__typename === 'LocationObject') {
        getFtfLocationLifelineStatsLazyQuery({
          variables: {
            id: locOrBobj.id,
            slices: sliceIds,
            loadDnaStats,
          },
        });
      } else {
        App.assert(
          locOrBobj.__typename === 'BuiltObject',
          '[SymbolicViewContainer] - expected__typename to be BuiltObject before triggering BuiltObjectLifelineStatsQuery',
        );
        getFtfBuiltObjectLifelineStatsLazyQuery({
          variables: {
            id: locOrBobj.id,
            slices: sliceIds,
            loadDnaStats,
          },
        });
      }
    } else {
      setVulnerableStatus({
        location: false,
        people: createTopConcernsData(
          false,
          formatToDayHours(0),
          0,
          locOrBobj?.people?.length ?? 0,
        ),
        power: createTopConcernsData(false, formatToDayHours(0), 0, locOrBobj?.power?.length ?? 0),
        structure: createTopConcernsData(false, '0%', 0, locOrBobj?.structural?.length ?? 0),
        port: createTopConcernsData(false, formatToDayHours(0), 0, locOrBobj?.ports?.length ?? 0),
        airport: createTopConcernsData(
          false,
          formatToDayHours(0),
          0,
          locOrBobj?.airports?.length ?? 0,
        ),
        bridge: createTopConcernsData(
          false,
          formatToDayHours(0),
          0,
          locOrBobj?.bridges?.length ?? 0,
        ),
        highway: createTopConcernsData(
          false,
          formatToDayHours(0),
          0,
          locOrBobj?.highways?.length ?? 0,
        ),
      });
    }
  }, [
    locOrBobj,
    currentView,
    formatToDayHours,
    getFtfBuiltObjectLifelineStatsLazyQuery,
    getFtfLocationLifelineStatsLazyQuery,
    planFilters,
    returnPeriodIndex,
    slices,
    loadDnaStats,
  ]);

  return (
    <div className={classes.container}>
      <div
        className={classes.symbolicLayout}
        data-test-id={`SymbolicView-Location-${locOrBobj.id}`}
      >
        <div className={classes.location}>
          <SymbolicAsset
            isVulnerable={vulnerableStatus.location}
            stableImg={LocationStableIcon}
            vulnerableImg={LocationVulnerableIcon}
            title={t('location')}
          />
        </div>
        {entitlements?.people && !isFinanceEntitled && (
          <>
            <div className={`${classes.lifeLinePeopleDetail} u-zindex--1`}>
              <LifelineVulnerableDetail
                caption={Lifeline.PEOPLE}
                isVulnerable={vulnerableStatus.people.isVulnerable}
                lifelineUnit={t(`symbolicView:communityUnit_${countryCode}`)}
                downtime={vulnerableStatus.people.downtime}
                vulnerableCount={vulnerableStatus.people.vulnerableCount}
                total={vulnerableStatus.people.totalCount}
                selectLifeline={selectLifeline}
                hazardType={planFilters.hazardType}
              />
            </div>
            <div className={`${classes.lifeLinePeople}`}>
              <SymbolicAsset
                isVulnerable={vulnerableStatus.people.isVulnerable}
                stableImg={LocationPeopleStableIcon}
                vulnerableImg={LocationPeopleVulnerableIcon}
                title={t('lifeline:people')}
              />
            </div>
          </>
        )}
        {entitlements?.power && (
          <>
            <div
              className={`${
                isFinanceEntitled ? classes.financeLifeLinePowerDetail : classes.lifeLinePowerDetail
              } u-zindex--1`}
            >
              <LifelineVulnerableDetail
                caption={Lifeline.POWER}
                isVulnerable={vulnerableStatus.power.isVulnerable}
                downtime={vulnerableStatus.power.downtime}
                vulnerableCount={vulnerableStatus.power.vulnerableCount}
                total={vulnerableStatus.power.totalCount}
                selectLifeline={selectLifeline}
                hazardType={planFilters.hazardType}
                builtObjectDetails={{
                  propertyType:
                    locOrBobj.__typename === 'LocationObject' ? locOrBobj.type : undefined,
                  id: locOrBobj.id,
                  type: locOrBobj.__typename,
                  builtObjectID:
                    locOrBobj.__typename === 'LocationObject'
                      ? locOrBobj.builtObjectId
                      : locOrBobj.id,
                }}
                coordinates={locOrBobj.coordinates}
                showROI={showROI}
              />
            </div>
            <div
              className={isFinanceEntitled ? classes.financeLifeLinePower : classes.lifeLinePower}
            >
              <SymbolicAsset
                isVulnerable={vulnerableStatus.power.isVulnerable}
                stableImg={LocationPowerStableIcon}
                vulnerableImg={LocationPowerVulnerableIcon}
                title={t('lifeline:power')}
              />
            </div>
          </>
        )}
        {entitlements?.structure && (
          <>
            <div className={`${classes.lifeLineStructureDetail} u-zindex--1`}>
              <LifelineVulnerableDetail
                caption={Lifeline.STRUCTURE}
                isVulnerable={vulnerableStatus.structure.isVulnerable}
                downtime={vulnerableStatus.structure.downtime}
                vulnerableCount={vulnerableStatus.structure.vulnerableCount}
                total={vulnerableStatus.structure.totalCount}
                selectLifeline={selectLifeline}
                hazardType={planFilters.hazardType}
              />
            </div>
            <div className={`${classes.lifeLineStructure}`}>
              <SymbolicAsset
                isVulnerable={vulnerableStatus.structure.isVulnerable}
                stableImg={LocationStructureStableIcon}
                vulnerableImg={LocationStructureVulnerableIcon}
                title={t('lifeline:structure')}
              />
            </div>
          </>
        )}
        {entitlements?.port && (
          <>
            <div className={`${classes.lifeLineSeaportDetail} u-zindex--1`}>
              <LifelineVulnerableDetail
                caption={Lifeline.PORT}
                isVulnerable={vulnerableStatus.port.isVulnerable}
                lifelineUnit={t('lifeline:port')}
                downtime={vulnerableStatus.port.downtime}
                vulnerableCount={vulnerableStatus.port.vulnerableCount}
                total={vulnerableStatus.port.totalCount}
                selectLifeline={selectLifeline}
                hazardType={planFilters.hazardType}
              />
            </div>
            <div className={`${classes.lifeLineSeaport}`}>
              <SymbolicAsset
                isVulnerable={vulnerableStatus.port.isVulnerable}
                stableImg={LocationSeaportStableIcon}
                vulnerableImg={LocationSeaportVulnerableIcon}
                title={t('lifeline:port')}
              />
            </div>
          </>
        )}
        {entitlements?.airport && (
          <>
            <div className={`${classes.lifeLineAirportDetail} u-zindex--1`}>
              <LifelineVulnerableDetail
                caption={Lifeline.AIRPORT}
                isVulnerable={vulnerableStatus.airport.isVulnerable}
                lifelineUnit={t('lifeline:airport')}
                downtime={vulnerableStatus.airport.downtime}
                vulnerableCount={vulnerableStatus.airport.vulnerableCount}
                total={vulnerableStatus.airport.totalCount}
                selectLifeline={selectLifeline}
                hazardType={planFilters.hazardType}
              />
            </div>
            <div className={`${classes.lifeLineAirport}`}>
              <SymbolicAsset
                isVulnerable={vulnerableStatus.airport.isVulnerable}
                stableImg={LocationAirportStableIcon}
                vulnerableImg={LocationAirportVulnerableIcon}
                title={t('lifeline:airport')}
              />
            </div>
          </>
        )}
        {entitlements?.bridge && (
          <>
            <div className={`${classes.lifeLineBridgeDetail} u-zindex--1`}>
              <LifelineVulnerableDetail
                caption={Lifeline.BRIDGE}
                isVulnerable={vulnerableStatus.bridge.isVulnerable}
                lifelineUnit={t('lifeline:bridge')}
                downtime={vulnerableStatus.bridge.downtime}
                vulnerableCount={vulnerableStatus.bridge.vulnerableCount}
                total={vulnerableStatus.bridge.totalCount}
                selectLifeline={selectLifeline}
                hazardType={planFilters.hazardType}
              />
            </div>
            <div className={`${classes.lifeLineBridge}`}>
              <SymbolicAsset
                isVulnerable={vulnerableStatus.bridge.isVulnerable}
                stableImg={LocationBridgeStableIcon}
                vulnerableImg={LocationBridgeVulnerableIcon}
                title={t('lifeline:bridge')}
              />
            </div>
          </>
        )}
        {entitlements?.highway && (
          <>
            <div className={`${classes.lifeLineHighwayDetail} u-zindex--1`}>
              <LifelineVulnerableDetail
                caption={Lifeline.HIGHWAY}
                isVulnerable={vulnerableStatus.highway.isVulnerable}
                lifelineUnit={t('lifeline:highway')}
                downtime={vulnerableStatus.highway.downtime}
                vulnerableCount={vulnerableStatus.highway.vulnerableCount}
                total={vulnerableStatus.highway.totalCount}
                selectLifeline={selectLifeline}
                hazardType={planFilters.hazardType}
              />
            </div>
            <div className={`${classes.lifeLineHighway}`}>
              <SymbolicAsset
                isVulnerable={vulnerableStatus.highway.isVulnerable}
                stableImg={LocationHighwayStableIcon}
                vulnerableImg={LocationHighwayVulnerableIcon}
                title={t('lifeline:highway')}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
const SymbolicView = React.memo(SymbolicViewFC);
SymbolicView.displayName = 'SymbolicView';

const SymbolicViewContainerFC: React.FC<SymbolicViewContainerProps> = ({
  currentView,
  currentLifeline,
  showROI,
  userId,
}) => {
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();
  const { id: selectedLocationId } = useParams<{ id: string }>();
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;
  const loadDnaStats = isFinanceEntitled ? true : undefined;

  const {
    data: userData,
    loading: thresholdsLoading,
    error: thresholdsError,
  } = useGetThresholdsQuery();
  const serverThresholds: ResilienceThresholds = userData?.user?.productSettings?.thresholds;

  const {
    data: slicesData,
    loading: slicesLoading,
    error: slicesError,
  } = useGetSliceIndexesQuery();
  const slices = slicesData?.sliceIndexes || [];

  const {
    data: {
      planView: { sliceIdx: activeSliceIdx },
    },
  } = useGetSliceIdxQuery();

  const {
    data: locLifelinesData,
    loading: locLifelinesLoading,
    error: locLifelinesError,
  } = useGetLocationLifelineStatsQuery({
    variables: { id: selectedLocationId, slices: [activeSliceIdx], loadDnaStats },
    skip: !selectedLocationId,
  });
  const {
    data: builtLifelinesData,
    loading: builtLifelinesLoading,
    error: builtLifelinesError,
  } = useGetBuiltObjectLifelineStatsQuery({
    variables: { id: selectedLocationId, slices: [activeSliceIdx], loadDnaStats },
    skip: !selectedLocationId || !locLifelinesError,
  });

  const locOrBobj = locLifelinesData?.location || builtLifelinesData?.builtObject;
  const loading =
    builtLifelinesLoading || locLifelinesLoading || slicesLoading || thresholdsLoading;
  const builtObjQueryIsWonky =
    !!locLifelinesError &&
    !(!!builtLifelinesData || builtLifelinesLoading || !!builtLifelinesError);
  const error = builtLifelinesError || slicesError || thresholdsError || builtObjQueryIsWonky;

  // Deal with query errors
  React.useEffect(() => {
    if (error) {
      enqueueApiErrSnackbar();
      if (builtLifelinesError) {
        // The user can't get to the SymbolicView page without a valid location/builtObject ID,
        // so put up a snackbar because error is therefore something unexpected that is not caused
        // by user using URL with a bad location ID.
        App.assert(
          !!locLifelinesError,
          '[SymbolicViewContainer] - GetLocationLifelineStatsQuery should have returned an error',
        );
        App.error(
          '[SymbolicViewContainer] - GetLocationLifelineStatsQuery && GetBuiltObjectLifelineStatsQuery both failed',
          builtLifelinesError,
        );
      }
      if (slicesError) {
        App.error('[SymbolicViewContainer] - GetSlicesIndexesQuery error: ', slicesError);
      }
      if (thresholdsError) {
        App.error('[SymbolicViewContainer] - GetThresholds error: ', thresholdsError);
      }
      if (builtObjQueryIsWonky) {
        App.error(
          '[SymbolicViewContainer] - GetBuiltObjectLifelineStatsQuery was called & is not loading, but returned no data',
        );
      }
    }
  }, [
    error,
    builtLifelinesError,
    locLifelinesError,
    slicesError,
    thresholdsError,
    builtObjQueryIsWonky,
    enqueueApiErrSnackbar,
  ]);
  if (loading || !!error) {
    return <SymbolicViewSkeleton currentLifeline={currentLifeline} />;
  }

  App.assert(!!locOrBobj, '[SymbolicView] - data should not be null since not loading & no errors');
  return (
    <>
      <SymbolicView
        currentView={currentView}
        currentLifeline={currentLifeline}
        locOrBobj={locOrBobj}
        slices={slices}
        serverThresholds={serverThresholds}
        showROI={showROI}
        userId={userId}
      />
      <LocationBar />
    </>
  );
};
const SymbolicViewContainer = React.memo(SymbolicViewContainerFC);
SymbolicViewContainer.displayName = 'SymbolicViewContainer';

const SymbolicViewContainerWrapperFC: React.FC<SymbolicViewContainerProps> = ({
  currentView,
  currentLifeline,
  showROI,
  userId,
}) => (
  <Routes>
    <Route
      path="detail/:id"
      element={
        <SymbolicViewContainer
          currentView={currentView}
          currentLifeline={currentLifeline}
          showROI={showROI}
          userId={userId}
        />
      }
    />
    <Route
      path="see-the-math/:id"
      element={
        <SymbolicViewContainer
          currentView={currentView}
          currentLifeline={currentLifeline}
          userId={userId}
        />
      }
    />
    <Route
      path="share-location/:id"
      element={
        <SymbolicViewContainer
          currentView={currentView}
          currentLifeline={currentLifeline}
          userId={userId}
        />
      }
    />
  </Routes>
);
const SymbolicViewContainerWrapper = React.memo(SymbolicViewContainerWrapperFC);
SymbolicViewContainerWrapper.displayName = 'SymbolicViewContainerWrapper';
export default SymbolicViewContainerWrapper;
