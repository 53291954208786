import {
  GetMaterialityKpIsDocument,
  GetMaterialitySettingsDocument,
  useGetSliceIdxQuery,
  useUpdateMaterialitySettingsMutation,
} from '../__generated__/graphql';

const useUpdateMaterialitySettings = () => {
  const {
    data: {
      planView: { sliceIdx },
    },
  } = useGetSliceIdxQuery();
  const [updateMaterialitySettings] = useUpdateMaterialitySettingsMutation({
    refetchQueries: [
      { query: GetMaterialitySettingsDocument },
      { query: GetMaterialityKpIsDocument, variables: { sliceIdx } },
    ],
  });
  return updateMaterialitySettings;
};

export default useUpdateMaterialitySettings;
