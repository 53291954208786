import * as React from 'react';
import { Popup } from 'react-mapbox-gl';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';

import { MAPBOX_POPUP_OFFSET, WHITE } from '../../../../util/productGlobals';
import { App } from '../../../../PlanningApp/AppConfig';
import { getDisplayableAddress } from '../../../../util/addresses/addressUtils';
import useGetLocOrBobj from '../../../../Hooks/useGetLocOrBobj';

const useTextWrapStyles = makeStyles(() => ({
  root: {
    color: WHITE,
    padding: 8,
  },
  mapPopupTextWrapping: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
  },
}));

const LocationPopupFC: React.FC<{ locationId: string }> = ({ locationId }) => {
  const classes = useTextWrapStyles();
  const { t } = useTranslation();
  const { locOrBobj, error } = useGetLocOrBobj(locationId);

  const location = locOrBobj;
  if (error) {
    App.error('[LocationPopup] - GetLocationQuery error: ', error);
  }
  if (!locOrBobj) {
    return null;
  }

  const { name, coordinates, address } = location;
  const [aLine1, aLine2] = getDisplayableAddress(address);

  return (
    /* @ts-ignore */
    <Popup coordinates={coordinates} offset={MAPBOX_POPUP_OFFSET} className="map-popup-location">
      <div className={classes.root} data-test-id="location-hover-popup-content">
        <Typography
          variant="body1"
          data-test-id="hover-location-name"
          classes={{ root: classes.mapPopupTextWrapping }}
        >
          {name ?? t('unknownLocation')}
        </Typography>
        {aLine1 && (
          <div className="o-flexcol-container u-marginy--4">
            <Typography
              variant="caption"
              classes={{ root: classes.mapPopupTextWrapping }}
              data-test-id="hover-location-address1"
            >
              {aLine1}
            </Typography>
            {aLine2 && (
              <Typography
                variant="caption"
                classes={{ root: classes.mapPopupTextWrapping }}
                data-test-id="hover-location-address2"
              >
                {aLine2}
              </Typography>
            )}
          </div>
        )}
      </div>
    </Popup>
  );
};

const LocationPopup = React.memo(LocationPopupFC);
LocationPopup.displayName = 'LocationPopup';
export default LocationPopup;
