/* eslint-disable camelcase */
import { Slice, SliceHazardType } from '../../__generated__/graphql';

const makeMockSlices: () => Slice[] = () => {
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  const sliceDefinitions = require('./slice_definitions.json');
  const cleanedSliceDefinitions = sliceDefinitions.map((sliceDef: any) => {
    const { id, layer_type, type, climate_change_id } = sliceDef;

    let hazardType: SliceHazardType;
    switch (type) {
      case 'WIND':
        hazardType = SliceHazardType.Wind;
        break;
      case 'EARTHQUAKE':
        hazardType = SliceHazardType.Earthquake;
        break;
      case 'FLOOD':
        hazardType = SliceHazardType.Flood;
        break;
      case 'INTEGRATED':
        hazardType = SliceHazardType.Integrated;
        break;
      default:
        hazardType = null;
    }
    const cleanedSlice: Slice = {
      id: String(id),
      slice: id,
      hazardType,
      climate: climate_change_id,
      // TODO: Remove returnPeriod and planningHorizon below
      returnPeriod: layer_type === 'return_period' ? sliceDef.return_period : null,
      planningHorizon: layer_type === 'planning_horizon' ? sliceDef.planning_horizon_period : null,
      historicalEventID:
        layer_type === 'historical_event' ? String(sliceDef.historical_event_id) : null,
      severityIndex:
        layer_type === 'historical_event' ? sliceDef.historical_event_severity_id : null,
    };
    return cleanedSlice;
  });
  return cleanedSliceDefinitions as Slice[];
};
export default makeMockSlices;
