import * as React from 'react';
import { ApolloError } from '@apollo/client';

import {
  DominoModule,
  Product,
  useGetUserEntitlementsModulesQuery,
} from '../__generated__/graphql';
import { App } from '../PlanningApp/AppConfig';

type EntitlementsResult = {
  error: ApolloError;
  loading: boolean;
  data: {
    hasJPModule: boolean;
    hasUSModule: boolean;
  } | null;
};

function useEntitledModules(): EntitlementsResult {
  const { data, error, loading } = useGetUserEntitlementsModulesQuery();

  if (error) {
    App.error(`[useEntitledModules] Error: ${error.message}`);
  }

  return React.useMemo(() => {
    if (error) return { error, data: null, loading };
    const allModules = data?.user?.entitlements?.enabledProductsModule;

    return {
      error,
      data: {
        hasUSModule: !!allModules?.find(
          ({ product, module }) =>
            product === Product.Domino && module?.toUpperCase() === DominoModule.Us,
        ),
        hasJPModule: !!allModules?.find(
          ({ product, module }) =>
            product === Product.Domino && module?.toUpperCase() === DominoModule.Jp,
        ),
      },
      loading,
    };
  }, [error, loading, data?.user?.entitlements?.enabledProductsModule]);
}

export default useEntitledModules;
