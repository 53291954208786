import * as React from 'react';
import { createStyles, withStyles } from '@mui/styles';
import { Tab, Tabs, Theme } from '@mui/material';
import { GRAY2, ORANGE } from '../../../util/productGlobals';

interface StyledTabsProps {
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  children?: any;
}

const StyledTabs = withStyles({
  root: {
    borderBottom: '0.5px solid #111212',
    overflow: 'visible',
    '& > div': {
      overflow: 'visible !important',
    },
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    bottom: '-0.5px',
    height: 1,
    '& > span': {
      width: '100%',
      backgroundColor: ORANGE,
      borderRadius: 10,
    },
  },
})(({ value, onChange, children, ...rest }: StyledTabsProps) => (
  <Tabs
    value={value}
    onChange={onChange}
    {...rest}
    className="u-minHeight--24"
    TabIndicatorProps={{ children: <span /> }}
  >
    {children}
  </Tabs>
));

interface StyledTabProps {
  label: string;
  active: string;
  disabled?: boolean;
  spacing: number;
}

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'inherit',
      color: (props: StyledTabProps) => (props.active === 'true' ? ORANGE : GRAY2),
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      lineHeight: theme.typography.pxToRem(22),
      marginRight: (props: StyledTabProps) => props.spacing,
      minWidth: 'inherit',
      opacity: 1,
    },
  }),
)((props: StyledTabProps) => (
  <Tab disableRipple {...props} className="u-no-padding u-paddingbottom--4 u-minHeight--24" />
));

type CustomizedTabsProps = {
  tabList: string[];
  currentTabIndex: number;
  setCurrentTabIndex: (index: number) => void;
  allDisabled?: boolean;
  spacing?: number;
};

const CustomizedTabs: React.FC<CustomizedTabsProps> = ({
  tabList,
  currentTabIndex,
  setCurrentTabIndex,
  allDisabled = false,
  spacing = 16,
}) => {
  const handleChange = (_: React.SyntheticEvent, newIndex: number) => {
    setCurrentTabIndex(newIndex);
  };

  return (
    <div className="u-flexgrow">
      <div className="u-paddingbottom--16">
        <StyledTabs
          value={currentTabIndex}
          onChange={handleChange}
          aria-label="Styled-Tabs"
          data-test-id="CustomizedTabs"
        >
          {tabList.map((tab, index) => (
            <StyledTab
              label={tab}
              spacing={spacing}
              active={(index === currentTabIndex).toString()}
              key={tab}
              aria-label={tab}
              aria-controls={`Styled-Tab-${index}`}
              data-test-id={`Tab-${index}`}
              disabled={allDisabled}
            />
          ))}
        </StyledTabs>
      </div>
    </div>
  );
};

CustomizedTabs.displayName = 'CustomizedTabs';
export default CustomizedTabs;
