import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Theme, Typography } from '@mui/material';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { makeStyles } from '@mui/styles';
import { WHITE } from '../../../../../util/productGlobals';
import Callout from '../../../../CommonComponents/Callout/Callout';
import Icon from '../../../../CommonComponents/Icon/Icon';

const useStyles = makeStyles((theme: Theme) => ({
  calloutText: {
    color: WHITE,
  },
  gridCol: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
  },
  locationsText: {
    marginLeft: theme.spacing(6),
    marginTop: 'auto',
  },
}));

const SECTION_KEY = 'settings:sections:locations:details:';

const CsvLocationsStats: React.FC<{
  totalProcessed: number;
  totalAccepted: number;
  totalWarnings: number;
  totalRejected: number;
  totalPending: number;
}> = ({ totalAccepted, totalRejected, totalWarnings, totalProcessed, totalPending }) => {
  const { t } = useTranslation();
  const { calloutText, gridCol, locationsText } = useStyles();

  return (
    <Grid container spacing={3} data-test-id="LocationsCsvStats-Container">
      <Grid item xs className={gridCol} data-test-id="LocationsCsvStats-processed">
        <Callout
          className={calloutText}
          variant="inline"
          iconOverride={<Icon name="success2" variant="white" />}
          text={t(`${SECTION_KEY}locationsProcessed`)}
        />
        <Typography className={locationsText} variant="h1" data-test-id="numLocationsProcessed">
          {totalProcessed.toLocaleString()}
        </Typography>
      </Grid>
      <Grid item xs className={gridCol} data-test-id="LocationsCsvStats-accepted">
        <Callout
          className={calloutText}
          variant="inline"
          iconOverride={<Icon name="success2" variant="green" />}
          text={t(`${SECTION_KEY}locationsAccepted`)}
        />
        <Typography className={locationsText} variant="h1" data-test-id="numLocationsAccepted">
          {totalAccepted.toLocaleString()}
        </Typography>
      </Grid>
      {totalWarnings > 0 && (
        <Grid item xs className={gridCol} data-test-id="LocationsCsvStats-warnings">
          <Callout
            className={calloutText}
            variant="inline"
            iconOverride={<Icon name="emphasis2" variant="orange" />}
            text={t(`${SECTION_KEY}locationsAcceptedWithWarnings`)}
          />
          <div className={locationsText}>
            <Typography variant="h1" data-test-id="numLocationsWarnings">
              {totalWarnings.toLocaleString()}
            </Typography>
          </div>
        </Grid>
      )}
      {totalRejected > 0 && (
        <Grid item xs className={gridCol} data-test-id="LocationsCsvStats-rejected">
          <Callout
            className={calloutText}
            variant="inline"
            iconOverride={<Icon name="emphasis2" variant="red" />}
            text={t(`${SECTION_KEY}locationsRejected`)}
          />
          <div className={locationsText}>
            <Typography variant="h1" data-test-id="numLocationsRejected">
              {totalRejected.toLocaleString()}
            </Typography>
          </div>
        </Grid>
      )}
      {totalPending > 0 && (
        <Grid item xs className={gridCol} data-test-id="LocationsCsvStats-pending">
          <Callout
            className={calloutText}
            variant="inline"
            iconOverride={<HourglassBottomIcon color="info" />}
            text={t(`${SECTION_KEY}locationsPending`)}
          />
          <div className={locationsText}>
            <Typography variant="h1" data-test-id="numLocationsPending">
              {totalPending.toLocaleString()}
            </Typography>
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(CsvLocationsStats);
