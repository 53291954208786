import * as React from 'react';
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { useGetMaterialitySettingsQuery } from '../../../../../__generated__/graphql';
import { App } from '../../../../../PlanningApp/AppConfig';
import { Lifeline, ValidLifeline } from '../../../../../util/productEnums';
import { GLASS_BG_DARK, VALID_LIFELINES } from '../../../../../util/productGlobals';
import { omitTypename } from '../../../../../util/productUtils';
import { LocTypeLossRate } from '../../../../../Hooks/useGetMaterialityData';
import useUpdateMaterialitySettings from '../../../../../Hooks/useUpdateMaterialitySettings';
import useLifelineEntitlements from '../../../../../Hooks/useLifelineEntitlements';
import PercentInput from '../../../../CommonComponents/InputControls/PercentInput/PercentInput';
import Spinner from '../../../../CommonComponents/Spinner/Spinner';

type LossTableProps = {
  downtime: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  tableHeaderCell: {
    borderBottom: 'none',
    padding: 16,
  },
  tableBodyCell: {
    borderBottom: '0.5px solid #111212',
    padding: 8,
  },
  button: {
    height: 36,
    marginBottom: theme.spacing(2),
    paddingLeft: 10,
    paddingRight: 10,
  },
  inputRoot: {
    width: 76,
    height: 36,
    margin: theme.spacing(0, 4),
    background: GLASS_BG_DARK,
  },
  inputInput: {
    fontSize: '14px',
    padding: theme.spacing(2, 0, 2, 3),
  },
  inputAdornedEnd: {
    fontSize: '14px',
  },
}));

const LossTable: React.FC<LossTableProps> = ({ downtime }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const entitlements = useLifelineEntitlements();
  const [lossRates, setLossRates] = React.useState<LocTypeLossRate[]>([]);
  const [headerFields, setHeaderFields] = React.useState<ValidLifeline[]>([]);

  const { data: dataMaterialitySettings, loading, error } = useGetMaterialitySettingsQuery();

  const materialitySettings = dataMaterialitySettings?.getMaterialitySettings;
  const currentLossRates = React.useMemo(
    () =>
      entitlements
        ? materialitySettings?.locationTypeMaterialitySettings?.map((setting) => {
            const lossRate = setting.lossRates.find(
              (item) => item.timeIntervalHours === downtime * 24,
            );
            const ret: LocTypeLossRate = {
              name: setting.locationType,
              [Lifeline.POWER]: lossRate?.powerLoss ?? 0,
              [Lifeline.PEOPLE]: lossRate?.communityLoss ?? 0,
              [Lifeline.HIGHWAY]: lossRate?.highwaysLoss ?? 0,
              [Lifeline.BRIDGE]: lossRate?.bridgesLoss ?? 0,
              [Lifeline.PORT]: lossRate?.portsLoss ?? 0,
              [Lifeline.AIRPORT]: lossRate?.airportsLoss ?? 0,
            };
            Object.keys(ret).forEach((key: ValidLifeline | 'name') => {
              if (key !== 'name' && key !== Lifeline.STRUCTURE && !entitlements[key]) {
                delete ret[key];
              }
            });
            return ret;
          })
        : null,
    [materialitySettings?.locationTypeMaterialitySettings, downtime, entitlements],
  );

  const updateMaterialitySettings = useUpdateMaterialitySettings();

  React.useEffect(() => {
    if (currentLossRates) {
      setLossRates(currentLossRates);
    }
  }, [currentLossRates]);

  React.useEffect(() => {
    if (entitlements) {
      const headers: ValidLifeline[] = [];
      VALID_LIFELINES.forEach((lifeline) => {
        if (entitlements[lifeline] && lifeline !== Lifeline.STRUCTURE) {
          headers.push(lifeline);
        }
      });
      setHeaderFields(headers);
    }
  }, [entitlements]);

  const onChange = React.useCallback(
    (key: string, idx: number, value: number) => {
      const newLossRates = [...lossRates];
      newLossRates.splice(idx, 1, {
        ...newLossRates[idx],
        [key]: value / 100,
      });
      setLossRates(newLossRates);
    },
    [lossRates],
  );

  const renderTableCellItem = (key: string, value: number | string, idx: number) => {
    if (key === 'name') {
      return <Typography variant="body2">{value}</Typography>;
    }
    return (
      <PercentInput
        valuePercent={Number(value) * 100}
        minPercent={0}
        maxPercent={100}
        immediate
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
          inputAdornedEnd: classes.inputAdornedEnd,
        }}
        onChangePercent={({ value }) => onChange(key, idx, value)}
        id={`LossInput${idx}-${key}`}
      />
    );
  };

  const isValueChanged = React.useMemo(
    () => lossRates?.length > 0 && JSON.stringify(lossRates) !== JSON.stringify(currentLossRates),
    [lossRates, currentLossRates],
  );

  const handleCancelEditing = React.useCallback(() => {
    setLossRates(currentLossRates);
  }, [currentLossRates]);

  const handleSaveEditing = React.useCallback(() => {
    updateMaterialitySettings({
      variables: {
        input: {
          locationTypeLossRatesInput: [
            ...materialitySettings.locationTypeMaterialitySettings.map((setting) => {
              return {
                locationType: setting.locationType,
                annualRevenue: setting.annualRevenue,
                oneTimeLoss: setting.oneTimeLoss,
                lossRates: [
                  ...setting.lossRates.map((lossRate) => {
                    const ret = { ...lossRate };
                    if (lossRate.timeIntervalHours === downtime * 24) {
                      const filterValue = lossRates.find(
                        (item) => item.name === setting.locationType,
                      );
                      ret.powerLoss = filterValue[Lifeline.POWER] ?? 0;
                      ret.communityLoss = filterValue[Lifeline.PEOPLE] ?? 0;
                      ret.highwaysLoss = filterValue[Lifeline.HIGHWAY] ?? 0;
                      ret.bridgesLoss = filterValue[Lifeline.BRIDGE] ?? 0;
                      ret.portsLoss = filterValue[Lifeline.PORT] ?? 0;
                      ret.airportsLoss = filterValue[Lifeline.AIRPORT] ?? 0;
                    }
                    return omitTypename(ret);
                  }),
                ],
              };
            }),
          ],
        },
      },
    });
  }, [lossRates, materialitySettings, downtime, updateMaterialitySettings]);

  if (!entitlements) return null;

  if (error) {
    App.error(`[LossTable] - GetMaterialitySettings error: `, error);
    return null;
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <TableContainer component={Paper} sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
        <Table aria-label="LossTable" data-test-id="LossTable">
          <TableHead>
            <TableRow data-test-id="LossHeader">
              <TableCell className={classes.tableHeaderCell} />
              {headerFields.map((headerField) => (
                <TableCell key={headerField} className={classes.tableHeaderCell}>
                  <Typography variant="body2" textAlign="center">
                    {t(`thresholds:${headerField}Downtime`)}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {lossRates.map((rowData, idx) => (
              <TableRow key={rowData.name} data-test-id={`row-${rowData.name}`}>
                {Object.entries(rowData).map(([key, value]) => (
                  <TableCell key={key} align="center" className={classes.tableBodyCell}>
                    {renderTableCellItem(key, value, idx)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isValueChanged && (
        <Stack direction="row" justifyContent="flex-end" mt={8} mr={2}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className={`u-clickable u-marginright--8 ${classes.button}`}
            data-test-id="CancelEditing"
            onClick={handleCancelEditing}
          >
            {t('buttons:cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={`u-clickable ${classes.button}`}
            data-test-id="SaveEditing"
            onClick={handleSaveEditing}
          >
            {t('settings:sections:profile:buttons:saveChanges')}
          </Button>
        </Stack>
      )}
    </div>
  );
};

export default LossTable;
