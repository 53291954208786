/* eslint-disable react/jsx-no-useless-fragment */
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SYWSectionProps } from 'onec-types';
import { useLocation } from 'react-router-dom';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { Airport } from '../../../../__generated__/graphql';
import { hoursToDayHourAbbrev } from '../../../../util/time';
import RecoveryCurveChart from '../../Charts/RecoveryCurveChart';
import ReturnPeriodChart from '../../Charts/ReturnPeriodChart';
import { roundNumToPlace } from '../../../../util/utils';
import showYourWorkStyles from './showYourWorkStyles';
import useHasFinanceEntitlement from '../../../../Hooks/useHasFinanceEntitlement';

const SECTION_KEY = 'showYourWork:sections:airport:';

const SYWAirportFC: React.FC<SYWSectionProps> = ({ stats, gutterTop }) => {
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;
  const { t } = useTranslation();
  const {
    mean,
    stddev,
    threshold,
    topAssets,
    totalAssets,
    numVulnerable,
    recoveryCurve,
    returnPeriodData,
  } = stats;
  const { pathname }: { pathname: string } = useLocation();
  const { subtitle, statLabel, statValue, range, table, noWrap } = showYourWorkStyles({
    isVulnerable: mean > threshold,
    lightScrollBar: !pathname?.includes('/analysis/see-the-math'),
  });

  const { min, max, pctVulnerable } = React.useMemo(
    () => ({
      min: hoursToDayHourAbbrev(Math.max(0, mean - stddev)),
      max: hoursToDayHourAbbrev(mean + stddev),
      pctVulnerable: totalAssets > 0 ? roundNumToPlace((numVulnerable / totalAssets) * 100, 2) : 0,
    }),
    [mean, stddev, totalAssets, numVulnerable],
  );

  return (
    <section
      className="u-width--100"
      /// gutterTop is true when displayed under SYW content
      id={`airport-section${!gutterTop ? '-detail' : ''}`}
      data-test-id="SYWAirport"
    >
      <Typography style={gutterTop && { marginTop: '28px' }} variant="h2" paragraph>
        {t(`${SECTION_KEY}downtime:title`)}
      </Typography>

      <Typography className={statValue} data-test-id="SYWAirport-mean">
        {hoursToDayHourAbbrev(mean)}
      </Typography>
      <Typography className={statLabel} paragraph>
        {t(`${SECTION_KEY}downtime:averageDowntime`)}
      </Typography>
      <Typography variant="h5" className={range} gutterBottom data-test-id="downtimeRange">
        {min !== max && max !== '0h' && t('showYourWork:range', { min, max })}
      </Typography>
      <Typography className={statLabel} paragraph>
        {t('showYourWork:threshold', { value: hoursToDayHourAbbrev(threshold) })}
      </Typography>

      <Typography paragraph>{t(`${SECTION_KEY}downtime:desc`)}</Typography>
      <Typography paragraph>
        <strong>{t('showYourWork:resultsBasedOn')}</strong>
      </Typography>

      {!isFinanceEntitled && recoveryCurve?.length > 0 && (
        <>
          <Typography variant="subtitle1" className={subtitle} gutterBottom>
            {t(`${SECTION_KEY}recovery:title`)}
          </Typography>
          <Typography paragraph data-test-id="RecoveryChartDesc">
            {t(`${SECTION_KEY}recovery:desc`)}
          </Typography>
          <Typography paragraph data-test-id="RecoveryChartIntro">
            {t(`${SECTION_KEY}recovery:descChart`)}
          </Typography>
          <RecoveryCurveChart curve={recoveryCurve} lifeline="airport" />
        </>
      )}

      <Typography variant="subtitle1" className={subtitle} gutterBottom>
        {t(`${SECTION_KEY}analysis:title`)}
      </Typography>
      <Typography paragraph>{t(`${SECTION_KEY}analysis:desc`)}</Typography>

      {!isFinanceEntitled && topAssets?.length > 0 && (
        <div data-test-id="topDisruptedAssetsIntro">
          <Typography variant="subtitle1" className={subtitle} gutterBottom>
            {t(`${SECTION_KEY}disruption:title`)}
          </Typography>
          <Typography paragraph>{t(`${SECTION_KEY}disruption:desc`)}</Typography>
        </div>
      )}
      {!isFinanceEntitled && (
        <Typography variant="h4">
          <Trans i18nKey={`${SECTION_KEY}disruption:numAnalyzed`}>
            <strong>Number of airports in analysis:</strong>
            <Typography variant="h5" component="span" data-test-id="SYWAirport-numAnalyzed">
              <>{{ num: totalAssets.toLocaleString() }}</>
            </Typography>
          </Trans>
        </Typography>
      )}
      {!isFinanceEntitled && (
        <Typography variant="h4">
          <Trans i18nKey={`${SECTION_KEY}disruption:numVulnerable`}>
            <strong>At-risk airports:</strong>
            <Typography variant="h5" component="span" data-test-id="SYWAirport-numVulnerable">
              <>{{ num: numVulnerable.toLocaleString() }}</>
            </Typography>
          </Trans>
        </Typography>
      )}
      {!isFinanceEntitled && (
        <Typography variant="h4" paragraph>
          <Trans i18nKey="showYourWork:pctVulnerable">
            <strong>% At risk:</strong>
            <Typography variant="h5" component="span" data-test-id="SYWAirport-pctVulnerable">
              <>{{ num: pctVulnerable }}</>
            </Typography>
          </Trans>
        </Typography>
      )}

      {!isFinanceEntitled && topAssets?.length > 0 && (
        <TableContainer className={table}>
          <Table data-test-id="SYWAirport-top3-table">
            <TableHead>
              <TableRow>
                <TableCell>{t(`${SECTION_KEY}table:airport`)}</TableCell>
                <TableCell>{t(`${SECTION_KEY}table:enplanements`)}</TableCell>
                <TableCell>{t('showYourWork:tables:averageDowntime')}</TableCell>
                <TableCell>{t('showYourWork:tables:range')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {topAssets?.map(
                ({ id, name, enplanements, stats: assetStats }: Airport, idx: number) => {
                  const assetMean = assetStats[0].mean;
                  const assetStdDev = assetStats[0].stddev;
                  const assetMin = Math.max(0, assetMean - assetStdDev);
                  const assetMax = assetMean + assetStdDev;
                  const assetMinStr = hoursToDayHourAbbrev(assetMin);
                  const assetMaxStr = hoursToDayHourAbbrev(assetMax);

                  return (
                    <TableRow key={id} data-test-id="row-top-asset-table">
                      <TableCell className={noWrap} data-test-id="assetName">
                        {name ?? t('showYourWork:tables:unknownName')}
                      </TableCell>
                      <TableCell>{enplanements?.toLocaleString() ?? '-'}</TableCell>
                      <TableCell>{hoursToDayHourAbbrev(assetMean)}</TableCell>
                      <TableCell className={noWrap} data-test-id={`topAssets-row${idx}-range`}>
                        {t('showYourWork:tables:rangeValues', {
                          min: assetMinStr,
                          max: assetMaxStr,
                          context: assetMin === assetMax && assetMax === 0 ? 'zero' : 'nonZero',
                        })}
                      </TableCell>
                    </TableRow>
                  );
                },
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Typography variant="subtitle1" className={subtitle} gutterBottom>
        {t(`${SECTION_KEY}severityImpact:title`)}
      </Typography>
      <Typography paragraph data-test-id="ReturnPeriodChartIntro">
        {t(`${SECTION_KEY}severityImpact:desc`)}
      </Typography>
      {returnPeriodData?.length > 0 && (
        <ReturnPeriodChart points={returnPeriodData} threshold={threshold} lifeline="airport" />
      )}

      <Typography paragraph>
        <Trans
          i18nKey={`showYourWork:${
            isFinanceEntitled ? 'exploreAnalysisCatnet' : 'exploreAnalysis'
          }`}
        >
          <strong>Did you know?</strong>
        </Trans>
      </Typography>
    </section>
  );
};

const SYWAirport = React.memo(SYWAirportFC);
SYWAirport.displayName = 'SYWAirport';
export default SYWAirport;
