import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import DaysHoursInput from '../../../CommonComponents/InputControls/DaysHoursInput/DaysHoursInput';
import PercentInput from '../../../CommonComponents/InputControls/PercentInput/PercentInput';
import { ResilienceThresholds } from '../../../../__generated__/graphql';
import { thresholdTableConfig } from './thresholdsHelpers';
import useLifelineEntitlements from '../../../../Hooks/useLifelineEntitlements';
import useHasFinanceEntitlement from '../../../../Hooks/useHasFinanceEntitlement';

type ThresholdsPickerFormBodyProps = {
  preSubmitVal: ResilienceThresholds;
  onChangePreSubmitVal: (threshold: Partial<ResilienceThresholds>) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  thresholdsTable: {
    maxWidth: 347,
    margin: theme.spacing(6, 4, 2, 4),
    width: 'calc(100% - 32px)',
  },
  thresholdsTableRow: {
    padding: theme.spacing(2, 0),
  },
  thresholdsHelpCell: {
    border: 'none',
    padding: theme.spacing(0, 0, 4, 0),
  },
  thresholdsTableHeaderCell: {
    padding: theme.spacing(2, 0),
    borderColor: '#000',
  },
  thresholdsControlCell: {
    width: 184,
    minWidth: 184,
    padding: theme.spacing(2, 0),
    borderColor: '#000',
  },
  inputRoot: {
    width: 76,
    height: 36,
    margin: theme.spacing(0, 2),
  },
  inputInput: {
    fontSize: '14px',
    padding: theme.spacing(2, 0, 2, 3),
  },
  inputAdornedEnd: {
    fontSize: '14px',
  },
}));

const ThresholdsPickerFormBodyFC: React.FC<ThresholdsPickerFormBodyProps> = ({
  preSubmitVal,
  onChangePreSubmitVal,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const onChangeHours = React.useCallback(
    ({ id, value: hours }: { id: string; value: number }) => {
      onChangePreSubmitVal({ [id]: hours });
    },
    [onChangePreSubmitVal],
  );

  const onChangePercent = React.useCallback(
    ({ id, value: valuePercent }: { id: string; value: number }) => {
      onChangePreSubmitVal({ [id]: valuePercent });
    },
    [onChangePreSubmitVal],
  );

  const entitlements = useLifelineEntitlements();
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;

  return (
    <TableContainer>
      <Table aria-label="simple table" className={classes.thresholdsTable}>
        <TableBody>
          <TableRow data-test-id="threshold-help-row">
            <TableCell
              data-test-id="threshold-help-cell"
              colSpan={2}
              classes={{ root: classes.thresholdsHelpCell }}
            >
              <Typography variant="body2">{t('thresholds:helpText')}</Typography>
            </TableCell>
          </TableRow>

          {entitlements &&
            thresholdTableConfig.map((lifeline) => {
              if (
                !entitlements[lifeline.llType] ||
                (isFinanceEntitled && lifeline.key === 'peopleDowntime')
              ) {
                return null;
              }
              return (
                <TableRow
                  key={`label-${lifeline.key}`}
                  classes={{ root: classes.thresholdsTableRow }}
                  data-test-id={`lifeline-${lifeline.key}`}
                >
                  <TableCell
                    data-test-id="label"
                    component="th"
                    scope="row"
                    classes={{ root: classes.thresholdsTableHeaderCell }}
                  >
                    <Typography variant="subtitle1">
                      <InputLabel htmlFor={lifeline.key}>
                        {t(`thresholds:${lifeline.key}`)}
                      </InputLabel>
                    </Typography>
                  </TableCell>

                  {lifeline.inputType === 'dayHours' ? (
                    <TableCell classes={{ root: classes.thresholdsControlCell }} align="right">
                      <DaysHoursInput
                        hours={(preSubmitVal as unknown as { [key: string]: number })[lifeline.key]}
                        minHours={1}
                        maxHours={999 * 24 + 23}
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                          inputAdornedEnd: classes.inputAdornedEnd,
                        }}
                        onChangeHours={onChangeHours}
                        id={lifeline.key}
                      />
                    </TableCell>
                  ) : (
                    <TableCell classes={{ root: classes.thresholdsControlCell }} align="left">
                      <PercentInput
                        valuePercent={
                          (preSubmitVal as unknown as { [key: string]: number })[lifeline.key]
                        }
                        minPercent={1}
                        maxPercent={100}
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                          inputAdornedEnd: classes.inputAdornedEnd,
                        }}
                        onChangePercent={onChangePercent}
                        id={lifeline.key}
                      />
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const ThresholdsPickerFormBody = React.memo(ThresholdsPickerFormBodyFC);
export default ThresholdsPickerFormBody;
