import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Marker } from 'react-mapbox-gl';

type PulsatingMarkerStyleProps = {
  color: string;
};

const useStyles = makeStyles(() => {
  const getColor = (props: PulsatingMarkerStyleProps) => props.color;

  return {
    '@keyframes pulsate': {
      '0%': {
        transform: 'scale(0 , 0)',
        opacity: 0,
      },
      '50%': {
        opacity: 0.8,
      },
      '100%': {
        transform: 'scale(1.5, 1.5)',
        opacity: 0,
      },
    },
    pulsingMarker: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& div': {
        position: 'absolute',
        borderRadius: '50%',
        '&:not(:first-child)': {
          background: getColor,
        },
      },
    },
    pulsingMarkerInner: {
      opacity: 0.8,
    },
    ripple: {
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: getColor,
      animation: '$pulsate 2s ease-out infinite',
    },
  };
});

const getRadii = (pointSize: number, rippleSize: number) => {
  return {
    inner: `${pointSize}px`,
    ripple: `${rippleSize}px`,
    container: `${Math.ceil(rippleSize * 2)}px`,
  };
};

const isIllegalCoords = (coordinates: number[]) => {
  if (
    coordinates?.[0] === undefined ||
    coordinates?.[0] === null ||
    coordinates?.[1] === undefined ||
    coordinates?.[1] === null
  ) {
    return true;
  }
  return false;
};

type PulsingMapMarkerProps = {
  coordinates: number[];
  pointSize: number;
  rippleSize: number;
  color: string;
};

const PulsingMapMarkerFC: React.FC<PulsingMapMarkerProps> = ({
  coordinates,
  pointSize,
  rippleSize,
  color,
}) => {
  const classes = useStyles({ color });
  const { inner, ripple, container } = getRadii(pointSize, rippleSize);

  if (rippleSize === 0 || pointSize === 0 || isIllegalCoords(coordinates)) {
    return null;
  }

  return (
    <Marker style={{ zIndex: 0, pointerEvents: 'none' }} coordinates={coordinates} anchor="center">
      <div
        className={classes.pulsingMarker}
        style={{ width: container, height: container }}
        data-test-id="pulsing-map-marker-container"
      >
        <div
          data-test-id="pulsing-map-marker-ripple"
          className={classes.ripple}
          style={{ width: ripple, height: ripple }}
        />
        <div
          data-test-id="pulsing-map-marker-inner"
          className={classes.pulsingMarkerInner}
          style={{ width: inner, height: inner }}
        />
      </div>
    </Marker>
  );
};

const PulsingMapMarker = React.memo(
  PulsingMapMarkerFC,
  ({ rippleSize, coordinates, color }, nextProps) =>
    rippleSize === nextProps.rippleSize &&
    color === nextProps.color &&
    coordinates[0] === nextProps.coordinates[0] &&
    coordinates[1] === nextProps.coordinates[1],
);

PulsingMapMarker.displayName = 'PulsingMapMarker';
export default PulsingMapMarker;
