import * as React from 'react';

/**
 * Given an array, determine how many items in the list to display
 *
 * @param list full array of items
 * @param initNum number of items to display on initial load
 * @param loadNum number of items to increment by
 * @returns
 * [{Array} array of items to display,
 * {boolean} whether there are more items to load,
 * {() => void} callback to add next set of items]
 */

function useListLoad<T>(list: T[], initNum = 5, loadNum = 5): [T[], boolean, () => void] {
  const [index, setIndex] = React.useState(initNum);
  const increment = React.useCallback(
    () => setIndex((idx) => (idx + loadNum > list.length ? list.length : idx + loadNum)),
    [list.length, loadNum],
  );

  // reset index if list is updated
  React.useEffect(() => setIndex(initNum), [list, initNum]);

  return React.useMemo(
    () => [list.slice(0, index), index < list.length, increment],
    [list, index, increment],
  );
}

export default useListLoad;
