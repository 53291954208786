import { InMemoryCache, InMemoryCacheConfig, makeVar, ReactiveVar } from '@apollo/client';

import { LocFilter } from '../../__generated__/graphql';

// When locsInViewportIdsVar is null, the "all locations" source layer has never been loaded,
// most likely because the Mapbox map is still initializing
export const locsInViewportIdsVar: ReactiveVar<Set<string>> = makeVar(null);
export const allLocFilterChoicesVar: ReactiveVar<LocFilter> = makeVar({
  locGroups: [],
  locTypes: [],
});

export const initializeLocsInViewportIds: () => void = () => {
  locsInViewportIdsVar(new Set());
};

export const cacheConfig: InMemoryCacheConfig = {
  possibleTypes: {
    BuiltObjectInterface: ['LocationObject', 'BuiltObject'],
    BasicLifelineAsset: [
      'Airport',
      'Port',
      'Bridge',
      'HighwaySegment',
      'Substation',
      'Subdivision',
      'Building',
    ],
    Stats: ['LifelineStats', 'AggregateStats'],
  },
  typePolicies: {
    Query: {
      fields: {
        locations: {
          merge: false,
        },
      },
    },
    FileNotice: {
      keyFields: ['id', 'label', 'lineNumber'],
    },
    ResilienceStats: {
      // Need to specify keyFields unless id is guaranteed unique for each sliceNum - locId combo
      keyFields: ['slice', 'id'],
    },

    ResilienceProduct: {
      merge: true,
      fields: {
        locationFile: {
          merge: false,
        },
      },
    },
    PersonInfo: {
      merge: true,
    },
    ContactInfo: {
      keyFields: ['email'],
    },
    BuiltObject: {
      keyFields: ['id'],
      fields: {
        address: {
          merge: true,
        },
      },
    },
    LocationObject: {
      keyFields: ['id'],
      fields: {
        address: {
          merge: true,
        },
      },
    },
    Address: {
      merge: true,
      fields: {
        countryCode: {
          read: (code) => code?.toUpperCase(),
        },
      },
    },
    Entitlements: {
      merge: true,
      fields: {
        enabledProductsModule: {
          merge: false,
        },
        hasFeaturesForProduct: {
          merge: false,
        },
        hasHazardsForProduct: {
          merge: false,
        },
      },
    },
  },
};

const cache = new InMemoryCache(cacheConfig);
export default cache;
