import * as request from 'superagent';
import { App } from '../AppConfig';

// Polls the token endpoint to keep the session alive.
class LoginPoller {
  private timeoutId: number | undefined;

  private interval: number;

  private resourceURL: string;

  static displayName = 'LoginPoller';

  constructor(resourceUrl: string, interval: number) {
    this.interval = interval;
    this.resourceURL = resourceUrl;
  }

  // Start polling
  start(): void {
    this.schedulePoll();
  }

  // Stop polling
  stop(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }
  }

  // True if polling is started
  get started() {
    return this.timeoutId !== undefined;
  }

  private schedulePoll(): void {
    this.timeoutId = window.setTimeout(async () => {
      if (this.poll()) {
        this.schedulePoll();
      }
    }, this.interval);
  }

  private async poll() {
    const res = await request
      .get(this.resourceURL)
      .then(() => {
        return true;
      })
      .catch((err) => {
        App.error(`[LoginPoller] poller error: ${err}`);
        return false;
      });
    return res;
  }
}

export default LoginPoller;
