import * as React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { MaterialitySidebarCategory } from 'onec-types';
import { useTranslation } from 'react-i18next';

import { CardContent, IconButton, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  DARKBLUE,
  GLASS_BG,
  MATERIALITY_SIDEBAR_CATEGORIES,
  OVERFLOW_Y,
} from '../../../../util/productGlobals';
import Dashboard from './Dashboard/Dashboard';
import Icon from '../../../CommonComponents/Icon/Icon';
import MaterialitySidebar from './MaterialitySidebar';
import MaterialitySettingsContainer from './Settings/SettingsContainer';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
    margin: 0,
    padding: 0,
    height: '100%',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%',
    backgroundColor: DARKBLUE,
  },
  scrollContainer: {
    flexGrow: 1,
    marginLeft: theme.spacing(18.5),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(4),
    paddingRight: theme.spacing(16),
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: GLASS_BG,
    },
  },
  closeContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(4),
  },
  closeButton: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(248, 247, 242, 0.1)',
    },
    '&:active': {
      backgroundColor: 'rgba(248, 247, 242, 0.2)',
    },
  },
  separator: {
    marginTop: theme.spacing(2),
    borderTop: '0.5px solid rgba(248, 247, 242, 0.1)',
  },
  backIcon: {
    minWidth: 36,
    padding: 0,
    marginRight: 16,
  },
  backArrowIcon: {
    margin: '0px !important',
  },
}));

const MaterialityContent: React.FC<{ path: string }> = ({ path }) => {
  const { body, container, scrollContainer, closeContainer, closeButton, separator } = useStyles();
  const { t } = useTranslation();

  const [pathRoot] = path.split('/');

  const navigate = useNavigate();
  const pathCategory =
    MATERIALITY_SIDEBAR_CATEGORIES.find(
      (category: MaterialitySidebarCategory) => category.name.toLowerCase() === pathRoot,
    ) ?? MATERIALITY_SIDEBAR_CATEGORIES[0];

  const { handleClose } = useOutletContext() as { handleClose: () => void };

  React.useEffect(() => {
    document.title = t('windowMaterialityTitle');
  }, [t]);

  const handleClick = React.useCallback(
    (e: React.MouseEvent) => {
      if (e.currentTarget instanceof HTMLElement) {
        const { category } = e.currentTarget.dataset;
        navigate(`/materiality/${category.toLowerCase()}`);
      }
    },
    [navigate],
  );
  return (
    <CardContent className={container} data-test-id="MaterialityContent-container">
      <MaterialitySidebar
        activeCategory={pathCategory}
        handleClick={handleClick}
        categories={MATERIALITY_SIDEBAR_CATEGORIES}
      />

      <div className={body} data-test-id="MaterialityContent-body">
        <div className={closeContainer} data-test-id="MaterialityContent-closeButtonContainer">
          <IconButton
            className={closeButton}
            onClick={handleClose}
            data-test-id="MaterialityContent-closeButton"
          >
            <Icon name="close" variant="white" />
          </IconButton>
        </div>
        <div
          className={`${scrollContainer} ${OVERFLOW_Y} o-flexcol-container`}
          data-test-id="Materiality-content"
          id="MaterialityPanel"
        >
          <div className="o-flex-vcenter-container">
            <Typography variant="h2">
              {t(`materiality:sections:${pathCategory.name}:title`)}
            </Typography>
          </div>
          <div className={separator} />
          {pathCategory.name === 'dashboard' && <Dashboard />}
          {pathCategory.name === 'settings' && <MaterialitySettingsContainer />}
        </div>
      </div>
    </CardContent>
  );
};

export default MaterialityContent;
