import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { Link } from '@mui/material';
import { App } from '../../../PlanningApp/AppConfig';
import { removePatchVersion } from '../../../util/productUtils';
import useEntitledCountryCodes from '../../../Hooks/useEntitledCountryCodes';
import onecMapLogo1x from '../../../../assets/images/oneconcern_fulllogo_white_1x.png';
import { CountryCode } from '../../../__generated__/graphql';

type MapCorner = 'bottom-left' | 'bottom-right' | 'uppper-left' | 'upper-right';

const useStyles = makeStyles({
  container: {
    margin: '0 0 8px 8px',
  },
  logoImg: {
    height: 26,
    margin: '0 8px 0 0',
  },
  logoImgClickable: {
    height: 26,
    margin: '0 8px 0 0',
    pointerEvents: 'auto',
  },
  copyright: {
    color: '#f8f7f2',
    fontSize: '9px',
    lineHeight: '8px',
    fontWeight: 500,
  },
  version: {
    color: '#f8f7f2',
    fontSize: '10px',
    lineHeight: '12px',
    fontWeight: 500,
    margin: '4px 0 0 0',
    pointerEvents: 'auto',
  },
});

const VersionInfo = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const countryCodes = useEntitledCountryCodes();
  const { dataus: dataUSVer, datajp: dataJPVer, planningapp: dominoVer } = App.config.version;

  if (!countryCodes?.length) {
    return null;
  }

  return (
    <div id="version-information" data-test-id="version-information" className={classes.version}>
      {t('version', {
        dominoVer: removePatchVersion(dominoVer),
        dataVer: countryCodes?.includes(CountryCode.Jp) ? dataJPVer : dataUSVer,
      })}
    </div>
  );
};

type OnecLogoProps = {
  corner: MapCorner;
  url?: string;
};
const OnecLogo: React.FC<OnecLogoProps> = ({ corner, url }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const copyrightStartYear = 2021;
  const currentYear = new Date().getFullYear();
  const copyrightYears =
    currentYear > copyrightStartYear
      ? `${copyrightStartYear}-${currentYear}`
      : `${copyrightStartYear}`;
  const copyright = t('copyright', { years: copyrightYears });

  return (
    <div
      id="onec-logo"
      className={`mapboxgl-ctrl-${corner} ${classes.container}`}
      data-test-id="onec-logo"
    >
      <div className="u-flex u-flex-alignend">
        {url ? (
          <Link href={url} target="_blank">
            <img
              src={onecMapLogo1x}
              className={`${classes.logoImgClickable} u-clickable`}
              alt={t('productLogo')}
              srcSet={`${onecMapLogo1x} 1x`}
            />
          </Link>
        ) : (
          <img
            src={onecMapLogo1x}
            className={`${classes.logoImg}`}
            alt={t('productLogo')}
            srcSet={`${onecMapLogo1x} 1x`}
          />
        )}
        <div data-test-id="onec-copyright" className={classes.copyright}>
          {copyright}
        </div>
      </div>
      <VersionInfo />
    </div>
  );
};

OnecLogo.displayName = 'OnecLogo';
export default OnecLogo;
