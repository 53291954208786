import { LocationBuildingMatch } from '../../../__generated__/graphql';
import { locationMatchMutations } from '../resolvers/LocationMatchResolvers';

export const STORE_DEFAULTS = {
  locationBuildingMatch: null as LocationBuildingMatch,
  editLocationSource: {
    url: '',
  },
};

export const locationMatchStore = {
  defaults: STORE_DEFAULTS,
  mutations: locationMatchMutations,
};
