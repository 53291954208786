import * as React from 'react';

import { makeStyles } from '@mui/styles';
import Popover from '@mui/material/Popover';

import FormActionButtons from './FormActionButtons';
import PickerButton from './PickerButton';

type PickerFormProps = {
  onSubmit: (e: React.FormEvent) => void;
  onRestoreToDefaults: () => void;
  onCancel: () => void;
  submitting: boolean;
  name: string;
  hoverDropdownComponent?: React.ReactElement;
  pickerButtonText: string;
  children: React.ReactNode;
  debugForceHoverMenuOpen?: boolean;
};

const useStyles = makeStyles(() => ({
  form: {
    '& > div': {},
  },
}));

const PickerFormFC: React.FC<PickerFormProps> = ({
  onSubmit,
  onRestoreToDefaults,
  onCancel,
  submitting,
  name,
  hoverDropdownComponent,
  pickerButtonText,
  children,
  debugForceHoverMenuOpen = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles();

  const handlePickerButtonClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!anchorEl) {
        setAnchorEl(event.currentTarget);
      }
    },
    [anchorEl],
  );

  const handlePopoverClose = React.useCallback(() => {
    setAnchorEl(null);
    onCancel();
  }, [onCancel]);

  const onClickApply = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      onSubmit(e);
      handlePopoverClose();
    },
    [handlePopoverClose, onSubmit],
  );

  return (
    <div data-test-id={`${name}-PickerForm`}>
      <PickerButton
        onButtonClick={handlePickerButtonClick}
        isSettingsOpen={!!anchorEl}
        hoverDropdown={hoverDropdownComponent}
        buttonText={pickerButtonText}
        name={name}
        debugForceHoverMenuOpen={debugForceHoverMenuOpen}
      />
      <Popover
        anchorEl={anchorEl}
        keepMounted
        elevation={0}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={Boolean(anchorEl)}
        onClose={handlePopoverClose}
      >
        <form onSubmit={onSubmit} data-test-id={`${name}-PickerForm-form`} className={classes.form}>
          {children}
          <FormActionButtons
            onClickRestoreDefaults={onRestoreToDefaults}
            onClickCancel={handlePopoverClose}
            onClickApply={onClickApply}
            submitting={submitting}
          />
        </form>
      </Popover>
    </div>
  );
};
const PickerForm = React.memo(PickerFormFC);
PickerForm.displayName = 'PickerForm';
export default PickerForm;
