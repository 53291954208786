import * as React from 'react';
import { LocationSliceStats, SliceAggregateStats } from 'onec-types';
import { ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { countVulnerableAssets, getTopVulnerableAssets } from '../util/vulnerability';
import {
  isHistoricalSliceMatch,
  isLegalSlice,
  isSimulatedSliceMatch,
} from '../util/sliceFilterUtils';
import {
  GetBuiltObjectAnalysisQuery,
  PlanFilters,
  ResilienceStats,
  Slice,
  useGetBuiltObjectAnalysisQuery,
  useGetLocationAnalysisQuery,
  useGetPlanViewFiltersQuery,
  useGetSliceIdxQuery,
  useGetSliceIndexesQuery,
  useGetThresholdsQuery,
} from '../__generated__/graphql';
import { App } from '../PlanningApp/AppConfig';
import useApiErrSnackbar from '../Components/CommonComponents/Snackbar/useApiErrSnackbar';
import { addNameToBobjInCache } from './useGetLocOrBobj';
import useHasFinanceEntitlement from './useHasFinanceEntitlement';

export function getReturnPeriodSliceIndices(slices: Slice[], filters: PlanFilters): number[] {
  const indices: number[] = [];

  for (const slice of slices) {
    if (
      isLegalSlice(slice) &&
      slice.returnPeriod > 0 &&
      (filters.historicalId
        ? isHistoricalSliceMatch(slice, filters)
        : isSimulatedSliceMatch(slice, filters))
    ) {
      indices.push(slice.slice);
    }
  }

  return indices;
}

export function makeReturnPeriodChartData(
  slices: Slice[],
  stats: ResilienceStats[],
): { [key: string]: number[][] } {
  const data: { [key: string]: number[][] } = {
    rpStructure: [],
    rpPower: [],
    rpPeople: [],
    rpHighway: [],
    rpBridge: [],
    rpPort: [],
    rpAirport: [],
  };

  if (stats) {
    for (const sliceStats of stats) {
      const {
        slice,
        structuralDamage,
        nearbyPower,
        nearbyPeople,
        nearbyHighway,
        nearbyBridge,
        nearbyPort,
        nearbyAirport,
      } = sliceStats;

      const returnPeriod = slices[slice - 1]?.returnPeriod;
      data.rpStructure.push([returnPeriod, structuralDamage.mean]);
      data.rpPower.push([returnPeriod, nearbyPower.mean]);
      data.rpPeople.push([returnPeriod, nearbyPeople.mean]);
      data.rpHighway.push([returnPeriod, nearbyHighway.mean]);
      data.rpBridge.push([returnPeriod, nearbyBridge.mean]);
      data.rpPort.push([returnPeriod, nearbyPort.mean]);
      data.rpAirport.push([returnPeriod, nearbyAirport.mean]);
    }
  }
  return data;
}

function useLocationSliceStats(locationId: string): {
  data?: LocationSliceStats;
  loading?: boolean;
  error?: ApolloError;
} {
  const { t } = useTranslation();
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();
  const {
    data: slicesData,
    loading: slicesLoading,
    error: slicesError,
  } = useGetSliceIndexesQuery();
  const {
    data: {
      planView: { sliceIdx },
    },
  } = useGetSliceIdxQuery();
  const {
    data: {
      planView: { planFilters },
    },
  } = useGetPlanViewFiltersQuery();
  const {
    data: userData,
    loading: thresholdsLoading,
    error: thresholdsError,
  } = useGetThresholdsQuery();

  const { chartSlices, allSlices }: { chartSlices: number[]; allSlices: number[] } =
    React.useMemo(() => {
      const indices: number[] = getReturnPeriodSliceIndices(
        slicesData?.sliceIndexes ?? [],
        planFilters,
      );

      return {
        chartSlices: indices,
        allSlices: indices.includes(sliceIdx) ? indices : [sliceIdx, ...indices],
      };
    }, [sliceIdx, slicesData, planFilters]);

  if (slicesError) {
    App.error(`[useLocationSliceStats] Error retrieving slice definitions: ${slicesError.message}`);
  }

  if (!slicesLoading && !chartSlices.length) {
    App.warn('[useLocationSliceStats] No return period slice indices found;');
  }

  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;
  const loadDnaStats = isFinanceEntitled ? true : undefined;

  const { data, loading, error } = useGetLocationAnalysisQuery({
    variables: { id: locationId, activeSlice: [sliceIdx], slices: allSlices, loadDnaStats },
  });
  const {
    data: builtData,
    loading: builtLoading,
    error: builtError,
    updateQuery,
    client,
  } = useGetBuiltObjectAnalysisQuery({
    variables: { id: locationId, activeSlice: [sliceIdx], slices: allSlices, loadDnaStats },
    skip: !error,
    onCompleted: (bobj) => {
      if (bobj) {
        updateQuery(
          (bobjInCache) =>
            addNameToBobjInCache(bobjInCache, client, t) as GetBuiltObjectAnalysisQuery,
        );
      }
    },
  });

  const isLoading = loading || builtLoading;
  const hasError = error && builtError;
  const locOrBobj = data?.location || builtData?.builtObject;
  const countryCode = locOrBobj?.address?.countryCode;

  React.useEffect(() => {
    if (hasError) {
      enqueueApiErrSnackbar();

      App.error(
        '[useLocationSliceStats] Error retrieving analysis for location and builtObject: ',
        builtError?.message || error?.message,
      );
    }
  }, [enqueueApiErrSnackbar, hasError, error, builtError]);

  React.useEffect(() => {
    if (thresholdsError) {
      enqueueApiErrSnackbar();

      App.error(
        '[useLocationSliceStats] Error retrieving user thresholds: ',
        thresholdsError?.message,
      );
    }
  }, [enqueueApiErrSnackbar, thresholdsError]);

  return React.useMemo(() => {
    let locSliceStats: LocationSliceStats = null;

    if (!isLoading && !locOrBobj) {
      App.error('[useLocationSliceStats] location and built object are undefined.');
    }

    const thresholds = userData?.user?.productSettings?.thresholds;

    if (!isLoading && !hasError && !thresholdsLoading && !thresholdsError && thresholds) {
      const {
        structuralDamage,
        powerDowntime,
        peopleDowntime,
        highwayDowntime,
        bridgeDowntime,
        airportDowntime,
        portDowntime,
      } = thresholds;

      const {
        structural,
        people,
        highways,
        bridges,
        airports,
        ports,
        resilienceStats,
        name,
        address,
        buildingsCount,
      } = locOrBobj;

      const {
        structuralDamage: nearbyStructuralDamage,
        nearbyPower,
        nearbyPeople,
        nearbyHighway,
        nearbyBridge,
        nearbyAirport,
        nearbyPort,
      } = resilienceStats.filter((stat) => stat.slice === sliceIdx)[0];

      const { rpStructure, rpPower, rpPeople, rpHighway, rpBridge, rpAirport, rpPort } =
        makeReturnPeriodChartData(
          slicesData?.sliceIndexes ?? [],
          resilienceStats.filter((stat) => chartSlices.includes(stat.slice)),
        );

      const stats: { [key: string]: SliceAggregateStats } = {
        structure: {
          mean: nearbyStructuralDamage.mean,
          stddev: nearbyStructuralDamage.stddev,
          threshold: structuralDamage,
          isVulnerable: nearbyStructuralDamage.mean > structuralDamage,
          topAssets: structural,
          returnPeriodData: rpStructure,
        },
        power: {
          mean: nearbyPower.mean,
          stddev: nearbyPower.stddev,
          threshold: powerDowntime,
          recoveryCurve: nearbyPower.recoveryCurve,
          isVulnerable: nearbyPower.mean > powerDowntime,
          returnPeriodData: rpPower,
        },
        people: {
          mean: nearbyPeople.mean,
          threshold: peopleDowntime,
          recoveryCurve: nearbyPeople.recoveryCurve,
          isVulnerable: nearbyPeople.mean > peopleDowntime,
          topAssets: getTopVulnerableAssets(people),
          totalAssets: people?.length ?? 0,
          numVulnerable: countVulnerableAssets(people ?? [], peopleDowntime),
          returnPeriodData: rpPeople,
          countryCode,
        },
        highway: {
          mean: nearbyHighway.mean,
          threshold: highwayDowntime,
          recoveryCurve: nearbyHighway.recoveryCurve,
          isVulnerable: nearbyHighway.mean > highwayDowntime,
          topAssets: getTopVulnerableAssets(highways),
          totalAssets: highways?.length ?? 0,
          numVulnerable: countVulnerableAssets(highways ?? [], highwayDowntime),
          returnPeriodData: rpHighway,
        },
        bridge: {
          mean: nearbyBridge.mean,
          stddev: nearbyBridge.stddev,
          threshold: bridgeDowntime,
          recoveryCurve: nearbyBridge.recoveryCurve,
          isVulnerable: nearbyBridge.mean > bridgeDowntime,
          topAssets: getTopVulnerableAssets(bridges),
          totalAssets: bridges?.length ?? 0,
          numVulnerable: countVulnerableAssets(bridges ?? [], bridgeDowntime),
          returnPeriodData: rpBridge,
        },
        airport: {
          mean: nearbyAirport.mean,
          stddev: nearbyAirport.stddev,
          threshold: airportDowntime,
          recoveryCurve: nearbyAirport.recoveryCurve,
          isVulnerable: nearbyAirport.mean > airportDowntime,
          topAssets: getTopVulnerableAssets(airports),
          totalAssets: airports?.length ?? 0,
          numVulnerable: countVulnerableAssets(airports ?? [], airportDowntime),
          returnPeriodData: rpAirport,
        },
        port: {
          mean: nearbyPort.mean,
          stddev: nearbyPort.stddev,
          threshold: portDowntime,
          recoveryCurve: nearbyPort.recoveryCurve,
          isVulnerable: nearbyPort.mean > portDowntime,
          topAssets: getTopVulnerableAssets(ports),
          totalAssets: ports?.length ?? 0,
          numVulnerable: countVulnerableAssets(ports ?? [], portDowntime),
          returnPeriodData: rpPort,
        },
      };

      locSliceStats = {
        stats,
        name,
        address,
        group: data?.location.group,
        type: data?.location.type,
        multiBuilding: buildingsCount > 1,
      };
    }

    return {
      data: locSliceStats,
      loading: isLoading,
      error: error ?? builtError,
    };
  }, [
    chartSlices,
    countryCode,
    data,
    hasError,
    error,
    builtError,
    isLoading,
    locOrBobj,
    slicesData,
    sliceIdx,
    userData,
    thresholdsLoading,
    thresholdsError,
  ]);
}

export default useLocationSliceStats;
