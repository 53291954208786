import * as React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

const ShareLocation: React.FC = () => {
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();
  const id = params?.id;

  if (!id) {
    return null;
  }

  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      component={RouterLink}
      to={`/analysis/share-location/${id}`}
      data-test-id={`ShareLocationBtn-${id}`}
    >
      {t('detailView:shareLocation')}
    </Button>
  );
};

ShareLocation.displayName = 'ShareLocation';
export default ShareLocation;
