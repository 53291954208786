// eslint-disable-next-line import/prefer-default-export
export const lifelineIcons = {
  airports: {
    vulnerable: {
      selected: 'airport-1cresi--purple--filled',
      default: 'airport-1cresi--purple',
    },
    notVulnerable: {
      selected: 'airport-1cresi--light-gray--filled',
      default: 'airport-1cresi--light-gray',
    },
  },
  ports: {
    vulnerable: {
      selected: 'port-1cresi--purple--filled',
      default: 'port-1cresi--purple',
    },
    notVulnerable: {
      selected: 'port-1cresi--light-gray--filled',
      default: 'port-1cresi--light-gray',
    },
  },
  bridges: {
    vulnerable: {
      selected: 'bridge-1cresi--purple--filled',
      default: 'bridge-1cresi--purple',
    },
    notVulnerable: {
      selected: 'bridge-1cresi--light-gray--filled',
      default: 'bridge-1cresi--light-gray',
    },
  },
  people: {
    vulnerable: {
      selected: 'people-1cresi--purple--filled',
      default: 'people-1cresi--purple',
    },
    notVulnerable: {
      selected: 'people-1cresi--light-gray--filled',
      default: 'people-1cresi--light-gray',
    },
  },
  highways: {
    vulnerable: {
      selected: 'highway-1cresi--purple--filled',
      default: 'highway-1cresi--purple',
    },
    notVulnerable: {
      selected: 'highway-1cresi--light-gray--filled',
      default: 'highway-1cresi--light-gray',
    },
  },
  power: {
    vulnerable: {
      selected: 'power-1cresi--purple--filled',
      default: 'power-1cresi--purple',
    },
    notVulnerable: {
      selected: 'power-1cresi--light-gray--filled',
      default: 'power-1cresi--light-gray',
    },
  },
  structural: {
    vulnerable: {
      selected: 'structure-1cresi--purple--filled',
      default: 'structure-1cresi--purple',
    },
    notVulnerable: {
      selected: 'structure-1cresi--light-gray--filled',
      default: 'structure-1cresi--light-gray',
    },
  },
};
