import { PlayState } from '../../../__generated__/graphql';
import { FirstToFailMutations } from '../resolvers/FirstToFailResolver';

export const STORE_DEFAULTS = {
  firstToFailView: {
    playState: PlayState.Initial,
    returnPeriodIndex: 0,
    __typename: 'FirstToFailView',
  },
};

export const firstToFailStore = {
  defaults: STORE_DEFAULTS,
  mutations: FirstToFailMutations,
};
