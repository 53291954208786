import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FormControl, ListItemIcon, MenuItem, Select, Skeleton, Theme } from '@mui/material';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import Icon from '../../../CommonComponents/Icon/Icon';
import {
  CLIMATE_OFF,
  CLIMATE_ON_PLANNING_HORIZON,
  CLIMATE_ON_RETURN_PERIOD,
  FINANCE_HAZARD_ORDER,
  PLANNING_HORIZON_YEAR_OPTIONS,
  RETURN_PERIOD_YEAR_OPTIONS,
  WHITE,
} from '../../../../util/productGlobals';
import { sendAmplitudeData } from '../../../../plugins/amplitude';
import { App } from '../../../../PlanningApp/AppConfig';
import { AMP_EVENT_FINANCE_EXPAND_LOCATION_BY_LIFELINE } from '../../../../plugins/amplitudeevents';
import {
  ForecastPeriod,
  ResilienceStats,
  Slice,
  SliceHazardType,
  useGetForecastPeriodQuery,
  useGetSelectedClimateQuery,
  useGetSliceIndexesQuery,
  useUpdateForecastPeriodMutation,
} from '../../../../__generated__/graphql';
import useGetResStats from '../../../../Hooks/useGetResStats';
import { findSlice } from '../../../../util/sliceFilterUtils';
import { titleize } from '../../../../util/string';
import { hoursToDayHourAbbrev } from '../../../../util/time';
import StyledSnackbarContent from '../../../CommonComponents/Snackbar/StyledSnackbarContent';

type FinanceDowntimeTableProps = {
  locOrBobjID: string;
  typename: string;
};

type FinanceTableStats = {
  hazard: SliceHazardType;
  rpStats: ResilienceStats[];
  phStats: ResilienceStats[];
};

const CATEGORIES = ['airport', 'port', 'bridge', 'highway', 'power', 'repair time'];

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    width: 'calc(100% - 2px)',
    '& .MuiTableCell-root': {
      ...theme.typography.caption,
      fontSize: 12,
      padding: '8px 4px',
    },
    '& .MuiTableCell-head': {
      ...theme.typography.caption,
      fontSize: 12,
      padding: '8px 4px',
    },
  },
  iconButton: {
    '& .MuiSvgIcon-root': {
      color: WHITE,
      width: 20,
      height: 20,
    },
  },
  select: {
    '& .MuiInputBase-input': {
      fontSize: 11,
      fontWeight: 500,
      padding: 0,
      paddingRight: '16px !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiSelect-iconOutlined': {
      right: '-6px',
    },
  },
}));

function Row(props: {
  row: FinanceTableStats[];
  locOrBobjID: string;
  typename: string;
  forecastPeriod: ForecastPeriod;
  lifeline: string;
}) {
  const { row, locOrBobjID, typename, forecastPeriod, lifeline } = props;
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleRowClick = () => {
    if (!open && App.config.features.enableamplitude) {
      sendAmplitudeData(AMP_EVENT_FINANCE_EXPAND_LOCATION_BY_LIFELINE, {
        locOrBobjID,
        typename,
        lifeline,
      });
    }
    setOpen(!open);
  };

  const allValues = FINANCE_HAZARD_ORDER.map((hazard) => {
    const hazardIndex = row.findIndex((r) => r.hazard === hazard);
    const values = [
      row[hazardIndex].phStats[
        PLANNING_HORIZON_YEAR_OPTIONS.findIndex((ph) => ph === forecastPeriod.financeDefaultPH)
      ],
      row[hazardIndex].rpStats[
        RETURN_PERIOD_YEAR_OPTIONS.findIndex((rp) => rp === forecastPeriod.financeRP1)
      ],
      row[hazardIndex].rpStats[
        RETURN_PERIOD_YEAR_OPTIONS.findIndex((rp) => rp === forecastPeriod.financeRP2)
      ],
    ];

    let lifelineValues: number[] = [];
    switch (lifeline) {
      case CATEGORIES[0]:
        lifelineValues = values.map((value) => value?.nearbyAirport?.mean);
        break;
      case CATEGORIES[1]:
        lifelineValues = values.map((value) => value?.nearbyPort?.mean);
        break;
      case CATEGORIES[2]:
        lifelineValues = values.map((value) => value?.nearbyBridge?.mean);
        break;
      case CATEGORIES[3]:
        lifelineValues = values.map((value) => value?.nearbyHighway?.mean);
        break;
      case CATEGORIES[4]:
        lifelineValues = values.map((value) => value?.nearbyPower?.mean);
        break;
      case CATEGORIES[5]:
        lifelineValues = values.map((value) => value?.nearbyRepairTime?.mean);
        break;
      default:
        break;
    }
    return lifelineValues;
  });

  const integratedIdx = FINANCE_HAZARD_ORDER.findIndex(
    (hazard) => hazard === SliceHazardType.Integrated,
  );

  return (
    <>
      <TableRow>
        <TableCell width={28} sx={{ paddingX: '0px !important' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleRowClick}
            className={classes.iconButton}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <div className="o-flex-vcenter-container">
            <ListItemIcon>
              <Icon
                name={`Lifeline-${lifeline.replace(' ', '-')}`}
                additionalClasses="u-marginright--4"
              />
            </ListItemIcon>
            <span>{titleize(lifeline)}</span>
          </div>
        </TableCell>
        <TableCell align="right">
          {hoursToDayHourAbbrev(allValues[integratedIdx][0]) || t('nA')}
        </TableCell>
        <TableCell align="right" width={70}>
          {hoursToDayHourAbbrev(Math.max(...allValues.map((value) => value[1] || 0))) || t('nA')}
        </TableCell>
        <TableCell align="right" width={70}>
          {hoursToDayHourAbbrev(Math.max(...allValues.map((value) => value[2] || 0))) || t('nA')}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ padding: '0px !important', borderBottom: 'none' }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small">
                <TableBody>
                  {FINANCE_HAZARD_ORDER.map((hazard, index) => {
                    if (hazard === SliceHazardType.Integrated) return null;
                    return (
                      <TableRow key={`${lifeline}-${hazard}`}>
                        <TableCell width={28} sx={{ paddingX: '0px !important' }} />
                        <TableCell component="th" scope="row">
                          <div className="o-flex-vcenter-container">
                            <ListItemIcon>
                              <Icon
                                name={hazard.toLowerCase()}
                                additionalClasses="u-marginright--4"
                              />
                            </ListItemIcon>
                            <span>{titleize(hazard.toLowerCase())}</span>
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          {hoursToDayHourAbbrev(allValues[index][0]) || t('nA')}
                        </TableCell>
                        <TableCell align="right" width={70}>
                          {hoursToDayHourAbbrev(allValues[index][1]) || t('nA')}
                        </TableCell>
                        <TableCell align="right" width={70}>
                          {hoursToDayHourAbbrev(allValues[index][2]) || t('nA')}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const FinanceDowntimeTable: React.FC<
  FinanceDowntimeTableProps & { stats: FinanceTableStats[] }
> = ({ locOrBobjID, typename, stats }) => {
  const { t } = useTranslation();
  const { select, table } = useStyles();
  const {
    data: {
      planView: {
        planFilters: { forecastPeriod },
      },
    },
  } = useGetForecastPeriodQuery();

  const [updateForecastPeriodMutation] = useUpdateForecastPeriodMutation();
  const handleChange = React.useCallback(
    (field: 'financeDefaultPH' | 'financeRP1' | 'financeRP2', value: number) => {
      updateForecastPeriodMutation({
        variables: {
          forecastPeriod: {
            ...forecastPeriod,
            [field]: value,
          },
        },
      });
    },
    [updateForecastPeriodMutation, forecastPeriod],
  );

  if (stats) {
    return (
      <TableContainer component={Paper}>
        <Table
          aria-label="BI Downtime Table"
          className={table}
          id="bi-downtime-table"
          data-test-id="bi-downtime-table"
        >
          <TableHead>
            <TableRow>
              <TableCell width={28} sx={{ paddingX: '0px !important' }} />
              <TableCell>{t('detailView:biDowntime:table:cause')}</TableCell>
              <TableCell align="right">
                <FormControl>
                  <Select
                    size="small"
                    labelId="ph-label"
                    id="ph-select"
                    value={forecastPeriod.financeDefaultPH}
                    renderValue={(value) =>
                      t('detailView:biDowntime:table:planningHorizonYears', {
                        count: value,
                      })
                    }
                    onChange={(e) => handleChange('financeDefaultPH', e.target.value as number)}
                    className={select}
                  >
                    {PLANNING_HORIZON_YEAR_OPTIONS.map((year) => (
                      <MenuItem value={year} key={year} sx={{ fontSize: 12 }}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell align="right" width={70}>
                <FormControl>
                  <Select
                    size="small"
                    labelId="rp-1-label"
                    id="rp-1-select"
                    value={forecastPeriod.financeRP1}
                    renderValue={(value) =>
                      t('detailView:biDowntime:table:returnPeriodYears', {
                        count: value,
                      })
                    }
                    onChange={(e) => handleChange('financeRP1', e.target.value as number)}
                    className={select}
                  >
                    {RETURN_PERIOD_YEAR_OPTIONS.filter(
                      (year) => year !== forecastPeriod.financeRP2,
                    ).map((year) => (
                      <MenuItem value={year} key={year} sx={{ fontSize: 12 }}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell align="right" width={70}>
                <FormControl>
                  <Select
                    size="small"
                    labelId="rp-2-label"
                    id="rp-2-select"
                    value={forecastPeriod.financeRP2}
                    renderValue={(value) =>
                      t('detailView:biDowntime:table:returnPeriodYears', {
                        count: value,
                      })
                    }
                    onChange={(e) => handleChange('financeRP2', e.target.value as number)}
                    className={select}
                  >
                    {RETURN_PERIOD_YEAR_OPTIONS.filter(
                      (year) => year !== forecastPeriod.financeRP1,
                    ).map((year) => (
                      <MenuItem value={year} key={year} sx={{ fontSize: 11 }}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {CATEGORIES.map((category) => {
              return (
                <Row
                  key={category}
                  row={stats}
                  locOrBobjID={locOrBobjID}
                  typename={typename}
                  forecastPeriod={forecastPeriod}
                  lifeline={category}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return null;
};

const FinanceDowntimeTableContainer: React.FC<FinanceDowntimeTableProps> = ({
  locOrBobjID,
  typename,
}) => {
  const {
    data: {
      planView: {
        planFilters: { isClimateChangeEnabled },
      },
    },
  } = useGetSelectedClimateQuery();
  const { table } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { data: slices, error: slicesError } = useGetSliceIndexesQuery();
  const climateValues = isClimateChangeEnabled
    ? [CLIMATE_ON_PLANNING_HORIZON, CLIMATE_ON_RETURN_PERIOD]
    : [CLIMATE_OFF];
  const filteredSlices: Slice[] = slices?.sliceIndexes.filter(
    (sliceDef: Slice) =>
      FINANCE_HAZARD_ORDER.includes(sliceDef.hazardType) &&
      (climateValues.includes(sliceDef.climate) ||
        (sliceDef.climate === CLIMATE_OFF && sliceDef.hazardType === SliceHazardType.Earthquake)) &&
      (RETURN_PERIOD_YEAR_OPTIONS.includes(sliceDef.returnPeriod) ||
        PLANNING_HORIZON_YEAR_OPTIONS.includes(sliceDef.planningHorizon)),
  );
  const filteredSlicesIdx: number[] = filteredSlices?.map((slice: Slice) => slice?.slice);

  const {
    resStats,
    loading: resStatsLoading,
    error: resStatsErr,
  } = useGetResStats(locOrBobjID, filteredSlicesIdx, true, true);

  React.useEffect(() => {
    if (resStatsErr) {
      enqueueSnackbar(<StyledSnackbarContent title={t('err:errorGeneral')} />, {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, t, resStatsErr]);

  if (resStatsErr || slicesError) {
    return null;
  }
  if (resStatsLoading) {
    return (
      <TableContainer component={Paper}>
        <Table
          aria-label="BI Downtime Table"
          className={table}
          id="bi-downtime-table-loading"
          data-test-id="bi-downtime-table-loading"
        >
          <TableHead>
            <TableRow>
              <TableCell width={28} sx={{ paddingX: '0px !important' }}>
                <Skeleton />
              </TableCell>
              <TableCell align="right" width={70}>
                <Skeleton />
              </TableCell>
              <TableCell align="right" width={70}>
                <Skeleton />
              </TableCell>
              <TableCell align="right" width={70}>
                <Skeleton />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell width={28} sx={{ paddingX: '0px !important' }}>
                <Skeleton />
              </TableCell>
              <TableCell align="right" width={70}>
                <Skeleton />
              </TableCell>
              <TableCell align="right" width={70}>
                <Skeleton />
              </TableCell>
              <TableCell align="right" width={70}>
                <Skeleton />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width={28} sx={{ paddingX: '0px !important' }}>
                <Skeleton />
              </TableCell>
              <TableCell align="right" width={70}>
                <Skeleton />
              </TableCell>
              <TableCell align="right" width={70}>
                <Skeleton />
              </TableCell>
              <TableCell align="right" width={70}>
                <Skeleton />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  const getStats = (sliceIdx: number) => {
    return resStats.find((stat) => stat.slice === sliceIdx);
  };
  if (resStats) {
    const tableData: FinanceTableStats[] = [];
    FINANCE_HAZARD_ORDER.forEach((hazard) => {
      const rpStats = RETURN_PERIOD_YEAR_OPTIONS.map((option) => {
        return getStats(
          findSlice(filteredSlices, {
            hazardType: hazard,
            returnPeriod: option,
            historicalEventID: null,
            planningHorizon: null,
            severityIndex: null,
            climate:
              isClimateChangeEnabled && hazard !== SliceHazardType.Earthquake
                ? CLIMATE_ON_RETURN_PERIOD
                : CLIMATE_OFF,
          })?.slice,
        );
      });
      const phStats = PLANNING_HORIZON_YEAR_OPTIONS.map((option) => {
        return getStats(
          findSlice(filteredSlices, {
            hazardType: hazard,
            returnPeriod: null,
            historicalEventID: null,
            planningHorizon: option,
            severityIndex: null,
            climate:
              isClimateChangeEnabled && hazard !== SliceHazardType.Earthquake
                ? CLIMATE_ON_PLANNING_HORIZON
                : CLIMATE_OFF,
          })?.slice,
        );
      });
      tableData.push({
        hazard,
        rpStats,
        phStats,
      });
    });

    return <FinanceDowntimeTable locOrBobjID={locOrBobjID} typename={typename} stats={tableData} />;
  }
  return null;
};

export default FinanceDowntimeTableContainer;
