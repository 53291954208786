import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { InputAdornment, TextField } from '@mui/material';

import { BLACK, GRAY2 } from '../../../util/productGlobals';
import Icon from '../../CommonComponents/Icon/Icon';

const useStyles = makeStyles(() => ({
  invertSearchInputColors: {
    '&.MuiAutocomplete-inputRoot.MuiFilledInput-root': {
      backgroundColor: GRAY2,
    },
    '& .MuiInputBase-input': {
      color: BLACK,
      '&::-webkit-input-placeholder': {
        color: BLACK,
      },
    },
  },
}));

type LocationsSearchInputProps = {
  params: AutocompleteRenderInputParams;
};

const LocationsSearchInput: React.FC<LocationsSearchInputProps> = ({ params }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [hover, setHover] = React.useState(false);
  const invertColors = hover;

  const defaultText = t('search:default');

  return (
    <TextField
      {...params}
      variant="filled"
      placeholder={defaultText}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      InputProps={{
        ...params.InputProps,
        classes: { root: hover && classes.invertSearchInputColors },
        startAdornment: (
          <>
            <InputAdornment position="start">
              <Icon name="search16" variant={invertColors ? 'dark' : 'white'} />
            </InputAdornment>
            {params.InputProps.startAdornment}
          </>
        ),
        endAdornment: params.InputProps.endAdornment,
      }}
    />
  );
};
LocationsSearchInput.displayName = 'LocationsSearchInput';
export default LocationsSearchInput;
