import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grow,
  Modal,
  Popper,
  Stack,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import {
  DEFAULT_FORECAST_PERIOD,
  GLASS_BG,
  LOCALSTORAGE_KEY_PREFIX,
} from '../../../../util/productGlobals';
import { sendAmplitudeData } from '../../../../plugins/amplitude';
import {
  AMP_DEMO_ACCEPT_TERMS,
  AMP_DEMO_COLLECT_CUSTOMER_INFO,
} from '../../../../plugins/amplitudeevents';
import useGetLocationTypesAndFileId from '../../../../Hooks/useGetLocationTypesAndFileId';
import { validateEmail } from '../../../../util/productUtils';
import useLocalStorage from '../../../../Hooks/useLocalStorage';
import {
  ForecastPeriodType,
  PlanViewInput,
  SliceHazardType,
  useGetLocationTypesQuery,
  useUpdatePlanViewMutation,
} from '../../../../__generated__/graphql';
import hazardMapping from './hazardMapping.json';
import useHasFinanceEntitlement from '../../../../Hooks/useHasFinanceEntitlement';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    maxWidth: '800px',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%) !important',
    outline: 0,
    backgroundColor: GLASS_BG,
    padding: theme.spacing(6),
    overflow: 'inherit',
  },
}));

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox} .MuiAutocomplete-option.Mui-focusVisible`]: {
    backgroundColor: 'rgba(248, 247, 242, 0.3)',
  },
});

const PickInterestModal: React.FC<{ userId: string }> = ({ userId }) => {
  const { card } = useStyles();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(true);
  const [email, setEmail] = React.useState('');
  const [termsChecked, setTermsChecked] = React.useState(false);
  const [locationType, setLocationType] = React.useState('');
  const [updatePlanViewMutation] = useUpdatePlanViewMutation();
  const [customerInfo, setCustomerInfo] = useLocalStorage<{
    email: string;
    locationType: string;
    locationId: string;
  }>(`${LOCALSTORAGE_KEY_PREFIX}${userId}:demoInfo`, null);

  const {
    values: locationTypes,
    error: errorLocationTypes,
    loading: loadingLocationTypes,
  } = useGetLocationTypesAndFileId();

  const { data: dataLocationTypes } = useGetLocationTypesQuery();
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEnabled = financeModules?.enablebi ?? false;

  const locationTypesList = React.useMemo(
    () =>
      locationTypes?.locTypes?.map((locType) => ({
        label: locType,
        value: locType,
      })),
    [locationTypes?.locTypes],
  );

  const handleChangeEmail = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setEmail(e.target.value),
    [setEmail],
  );

  const handleTermsChecked = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTermsChecked(event.target.checked);
    },
    [setTermsChecked],
  );

  const handleSubmit = React.useCallback(async () => {
    const locationId = dataLocationTypes?.locations?.find(
      (location) => location.type === locationType,
    )?.id;
    setCustomerInfo({
      email,
      locationType,
      locationId,
    });
    sendAmplitudeData(AMP_DEMO_ACCEPT_TERMS, {
      userId,
      customerEmail: email,
    });
    sendAmplitudeData(AMP_DEMO_COLLECT_CUSTOMER_INFO, {
      userId,
      customerEmail: email,
      locationType,
    });
    const hazard = hazardMapping
      .find((item) => item.locationType === locationType)
      ?.hazard?.toUpperCase() as SliceHazardType;

    const planView: PlanViewInput = !isFinanceEnabled
      ? {
          planFilters: {
            hazardType: hazard ?? SliceHazardType.Flood,
            forecastPeriod: {
              ...DEFAULT_FORECAST_PERIOD,
              duration: 100,
              forecastPeriodType: ForecastPeriodType.ReturnPeriod,
            },
            isHistoricalSeverityWorse: false,
            isClimateChangeEnabled: false,
            historicalId: null,
          },
          locFilter: {
            locTypes: [],
            locGroups: [],
          },
        }
      : {
          planFilters: {
            hazardType: hazard ?? SliceHazardType.Wind,
            forecastPeriod: {
              ...DEFAULT_FORECAST_PERIOD,
              duration: 250,
              forecastPeriodType: ForecastPeriodType.ReturnPeriod,
            },
            isHistoricalSeverityWorse: false,
            isClimateChangeEnabled: true,
            historicalId: null,
          },
          locFilter: {
            locTypes: [],
            locGroups: [],
          },
        };
    await updatePlanViewMutation({
      variables: {
        planView,
      },
    });
    setIsOpen(false);
    if (locationId) {
      if (isFinanceEnabled) {
        navigate('/finance/analysis');
      } else {
        navigate(`/analysis/detail/${locationId}?show3D=true`);
      }
    }
    if (isFinanceEnabled && email) {
      navigate('/finance/analysis');
    }
  }, [
    dataLocationTypes?.locations,
    setCustomerInfo,
    email,
    locationType,
    userId,
    updatePlanViewMutation,
    isFinanceEnabled,
    navigate,
  ]);

  if (customerInfo?.locationId && !isFinanceEnabled) {
    navigate(`/analysis/detail/${customerInfo?.locationId}?show3D=true`);
    return null;
  }

  if (customerInfo?.email && isFinanceEnabled) {
    navigate('/finance/analysis');
  }

  if (errorLocationTypes || loadingLocationTypes) {
    return null;
  }

  return (
    <Modal className="o-flex-center-container" open={isOpen} closeAfterTransition>
      <Grow in={isOpen}>
        <Card className={card}>
          <CardContent sx={{ paddingX: 0 }}>
            <Typography>
              {!isFinanceEnabled
                ? 'Please share your email and let us know which company you&apos;re interested in so we can provide you with tailored information.'
                : 'Please share your email before proceeding'}
            </Typography>
            <Stack mt={4} mb={2} sx={{ maxWidth: 400 }}>
              <TextField
                label="Email"
                type="email"
                size="small"
                variant="outlined"
                placeholder="example@example.com"
                value={email}
                onChange={handleChangeEmail}
                sx={{ mb: 4 }}
              />
              {!isFinanceEnabled && (
                <Autocomplete
                  disablePortal
                  size="small"
                  id="select-location-type"
                  options={locationTypesList}
                  renderInput={(params) => <TextField {...params} label="Select a company" />}
                  onChange={(_, option) => setLocationType(option?.value ?? '')}
                  PopperComponent={StyledPopper}
                  sx={{ mb: 2 }}
                />
              )}
              {isFinanceEnabled && (
                <Typography variant="caption">
                  The information and content presented in this application, including text,
                  graphics, and any other materials, are provided for informational purposes only.
                  The content is the intellectual property of One Concern, and all rights are
                  reserved. This presentation is not intended for commercial use, and no part of it
                  should be utilized for commercial purposes without the explicit written consent of
                  One Concern. The information and content presented is wholly confidential and may
                  not be further distributed or passed on to any other person or published or
                  reproduced, in whole or in part, by any medium or in any form for any purpose. Any
                  unauthorized use, disclosure, copying, or distribution of the contents of this
                  presentation is strictly prohibited. All market prices and market data contained
                  in this document are subject to change without notice. One Concern disclaims any
                  liability for actions taken or not taken based on the information provided in this
                  presentation. Recipients of this presentation are encouraged to seek professional
                  advice and conduct their own assessments before making any decisions based on the
                  content herein.
                </Typography>
              )}
              <FormControlLabel
                required
                control={<Checkbox checked={termsChecked} onChange={handleTermsChecked} />}
                label={
                  <Typography variant="body2">
                    I accept the{' '}
                    <a
                      href="https://oneconcern.com/en/terms/"
                      target="_blank"
                      rel="noreferrer"
                      className="u-link"
                    >
                      terms and conditions
                    </a>
                    {isFinanceEnabled && ' and have read the disclaimer'}
                  </Typography>
                }
              />
            </Stack>
            <Button
              type="submit"
              name="submit"
              color="primary"
              disabled={
                !validateEmail(email.trim()) ||
                (!isFinanceEnabled && !locationType) ||
                !termsChecked
              }
              variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </CardContent>
        </Card>
      </Grow>
    </Modal>
  );
};

export default PickInterestModal;
