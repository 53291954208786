import { cloneDeep } from 'lodash';

import {
  Airport,
  Bridge,
  Building,
  GetBuiltObjectAnalysisDocument,
  GetBuiltObjectAnalysisQuery,
  HighwaySegment,
  Port,
  ResilienceStats,
  Subdivision,
} from '../../__generated__/graphql';

export const airports: Omit<Airport, 'coordinates'>[] = [
  {
    __typename: 'Airport',
    actualID: 'whitehouse',
    id: 'whitehouse|Airport|1',
    name: 'Ronald Regan Washington National Airport',
    stats: [
      {
        id: 'whitehouse|Airport|1-107',
        mean: 0,
        stddev: 0,
      },
    ],
    yearBuilt: '1980',
    enplanements: 497613,
  },
  {
    __typename: 'Airport',
    actualID: 'whitehouse',
    id: 'whitehouse|Airport|2',
    name: 'College Park Airport',
    stats: [
      {
        id: 'whitehouse|Airport|2-107',
        mean: 0,
        stddev: 0,
      },
    ],
    yearBuilt: '1942',
    enplanements: 768824,
  },
  {
    __typename: 'Airport',
    actualID: 'whitehouse',
    id: 'whitehouse|Airport|3',
    name: 'Baltimore/Washington International Thurgood Marshall Airport',
    stats: [
      {
        id: 'whitehouse|Airport|3-107',
        mean: 3,
        stddev: 3,
      },
    ],
    yearBuilt: '1931',
    enplanements: 172201,
  },
  {
    __typename: 'Airport',
    actualID: 'whitehouse',
    id: 'whitehouse|Airport|4',
    name: 'Manassas Regional Airport',
    stats: [
      {
        id: 'whitehouse|Airport|4-107',
        mean: 23,
        stddev: 1,
      },
    ],
    yearBuilt: '1922',
    enplanements: 426269,
  },
  {
    __typename: 'Airport',
    actualID: 'whitehouse',
    id: 'whitehouse|Airport|5',
    name: 'Maryland Airport',
    stats: [
      {
        id: 'whitehouse|Airport|5-107',
        mean: 0,
        stddev: 0,
      },
    ],
    yearBuilt: '1941',
    enplanements: 547848,
  },
  {
    __typename: 'Airport',
    actualID: 'whitehouse',
    id: 'whitehouse|Airport|6',
    name: 'Dulles International Airport',
    stats: [
      {
        id: 'whitehouse|Airport|6-107',
        mean: 0,
        stddev: 0,
      },
    ],
    yearBuilt: '2006',
    enplanements: 212398,
  },
  {
    __typename: 'Airport',
    actualID: 'whitehouse',
    id: 'whitehouse|Airport|7',
    name: 'Leesburg Executive Airport',
    stats: [
      {
        id: 'whitehouse|Airport|7-107',
        mean: 0,
        stddev: 0,
      },
    ],
    yearBuilt: '1934',
    enplanements: 969146,
  },
  {
    __typename: 'Airport',
    actualID: 'whitehouse',
    id: 'whitehouse|Airport|8',
    name: 'Tipton Airport',
    stats: [
      {
        id: 'whitehouse|Airport|8-107',
        mean: 8,
        stddev: 3,
      },
    ],
    yearBuilt: '1990',
    enplanements: 492277,
  },
];

export const bridges: Bridge[] = [
  {
    __typename: 'Bridge',
    actualID: 'whitehouse',
    id: 'whitehouse|Bridge|1',
    coordinates: [-117.4002861, 34.00489167],
    stats: [
      {
        id: 'whitehouse|Bridge|1-107',
        mean: 0,
        stddev: 0,
      },
    ],
    length: 200,
    yearBuilt: '1946',
    material: 'Asphalt',
    bridgeType: 'Arch',
  },
  {
    __typename: 'Bridge',
    actualID: 'whitehouse',
    id: 'whitehouse|Bridge|2',
    coordinates: [-117.4002861, 34.00489167],
    stats: [
      {
        id: 'whitehouse|Bridge|2-107',
        mean: 0,
        stddev: 0,
      },
    ],
    length: 400,
    yearBuilt: '1952',
    material: 'Aluminum',
    bridgeType: 'Tied-arch',
  },
  {
    __typename: 'Bridge',
    actualID: 'whitehouse',
    id: 'whitehouse|Bridge|3',
    coordinates: [-117.4002861, 34.00489167],
    stats: [
      {
        id: 'whitehouse|Bridge|3-107',
        mean: 0,
        stddev: 0,
      },
    ],
    length: 100,
    yearBuilt: '1987',
    material: 'Asphalt',
    bridgeType: 'Truss',
  },
  {
    __typename: 'Bridge',
    actualID: 'whitehouse',
    id: 'whitehouse|Bridge|4',
    coordinates: [-117.4002861, 34.00489167],
    stats: [
      {
        id: 'whitehouse|Bridge|4-107',
        mean: 0,
        stddev: 0,
      },
    ],
    length: 50,
    yearBuilt: '1930',
    material: 'Asphalt',
    bridgeType: 'Cantilever',
  },
  {
    __typename: 'Bridge',
    actualID: 'whitehouse',
    id: 'whitehouse|Bridge|5',
    coordinates: [-117.4002861, 34.00489167],
    stats: [
      {
        id: 'whitehouse|Bridge|5-107',
        mean: 0,
        stddev: 0,
      },
    ],
    length: 75,
    yearBuilt: '1942',
    material: 'Steel',
    bridgeType: 'Cantilever',
  },
  {
    __typename: 'Bridge',
    actualID: 'whitehouse',
    id: 'whitehouse|Bridge|6',
    coordinates: [-117.4002861, 34.00489167],
    stats: [
      {
        id: 'whitehouse|Bridge|6-107',
        mean: 0,
        stddev: 0,
      },
    ],
    length: 25,
    yearBuilt: '1920',
    material: 'Asphalt',
    bridgeType: 'Cable-stayed',
  },
  {
    __typename: 'Bridge',
    actualID: 'whitehouse',
    id: 'whitehouse|Bridge|7',
    coordinates: [-117.4002861, 34.00489167],
    stats: [
      {
        id: 'whitehouse|Bridge|7-107',
        mean: 0,
        stddev: 0,
      },
    ],
    length: 150,
    yearBuilt: '1996',
    material: 'Concrete',
    bridgeType: 'Truss',
  },
  {
    __typename: 'Bridge',
    actualID: 'whitehouse',
    id: 'whitehouse|Bridge|8',
    coordinates: [-117.4002861, 34.00489167],
    stats: [
      {
        id: 'whitehouse|Bridge|8-107',
        mean: 0,
        stddev: 0,
      },
    ],
    length: 250,
    yearBuilt: '1977',
    material: 'Concrete',
    bridgeType: 'Arch',
  },
  {
    __typename: 'Bridge',
    actualID: 'whitehouse',
    id: 'whitehouse|Bridge|9',
    coordinates: [-117.4002861, 34.00489167],
    stats: [
      {
        id: 'whitehouse|Bridge|9-107',
        mean: 0,
        stddev: 0,
      },
    ],
    length: 300,
    yearBuilt: '1935',
    material: 'Concrete',
    bridgeType: 'Arch',
  },
  {
    __typename: 'Bridge',
    actualID: 'whitehouse',
    id: 'whitehouse|Bridge|10',
    coordinates: [-117.4002861, 34.00489167],
    stats: [
      {
        id: 'whitehouse|Bridge|10-107',
        mean: 0,
        stddev: 0,
      },
    ],
    length: 500,
    yearBuilt: '2004',
    material: 'Lumber',
    bridgeType: 'Truss',
  },
  {
    __typename: 'Bridge',
    actualID: 'whitehouse',
    id: 'whitehouse|Bridge|11',
    coordinates: [-117.4002861, 34.00489167],
    stats: [
      {
        id: 'whitehouse|Bridge|11-107',
        mean: 0,
        stddev: 0,
      },
    ],
    length: 10,
    yearBuilt: '1905',
    material: 'Asphalt',
    bridgeType: 'Beam',
  },
  {
    __typename: 'Bridge',
    actualID: 'whitehouse',
    id: 'whitehouse|Bridge|12',
    coordinates: [-117.4002861, 34.00489167],
    stats: [
      {
        id: 'whitehouse|Bridge|12-107',
        mean: 0,
        stddev: 0,
      },
    ],
    length: 100,
    yearBuilt: '2005',
    material: 'Lumber',
    bridgeType: 'Truss',
  },
  {
    __typename: 'Bridge',
    actualID: 'whitehouse',
    id: 'whitehouse|Bridge|13',
    coordinates: [-117.4002861, 34.00489167],
    stats: [
      {
        id: 'whitehouse|Bridge|13-107',
        mean: 0,
        stddev: 0,
      },
    ],
    length: 280,
    yearBuilt: '1903',
    material: 'Asphalt',
    bridgeType: 'Tied-arch',
  },
  {
    __typename: 'Bridge',
    actualID: 'whitehouse',
    id: 'whitehouse|Bridge|14',
    coordinates: [-117.4002861, 34.00489167],
    stats: [
      {
        id: 'whitehouse|Bridge|14-107',
        mean: 0,
        stddev: 0,
      },
    ],
    length: 20,
    yearBuilt: '1966',
    material: 'Concrete',
    bridgeType: 'Cantilever',
  },
  {
    __typename: 'Bridge',
    actualID: 'whitehouse',
    id: 'whitehouse|Bridge|15',
    coordinates: [-117.4002861, 34.00489167],
    stats: [
      {
        id: 'whitehouse|Bridge|15-107',
        mean: 0,
        stddev: 0,
      },
    ],
    length: 20,
    yearBuilt: '1961',
    material: 'Steel',
    bridgeType: 'Tied-arch',
  },
  {
    __typename: 'Bridge',
    actualID: 'whitehouse',
    id: 'whitehouse|Bridge|16',
    metadata: [
      'yearBuilt',
      '1975-03-11T20:50:52.615Z',
      'material',
      'Lumber',
      'type',
      'Cable-stayed',
    ],
    coordinates: [-117.4002861, 34.00489167],
    stats: [
      {
        id: 'whitehouse|Bridge|16-107',
        mean: 0,
        stddev: 0,
      },
    ],
    length: 225,
    yearBuilt: '1975',
    material: 'Lumber',
    bridgeType: 'Cable-stayed',
  },
];

export const highways: Omit<HighwaySegment, 'coordinates'>[] = [
  {
    __typename: 'HighwaySegment',
    actualID: 'whitehouse',
    id: 'whitehouse|Highway|1',
    length: 17,
    name: 'Interstate 495',
    stats: [
      {
        id: 'whitehouse|Highway|1-107',
        mean: 2,
        stddev: 2,
      },
    ],
  },
  {
    __typename: 'HighwaySegment',
    actualID: 'whitehouse',
    id: 'whitehouse|Highway|2',
    length: 9,
    name: 'Interstate 395',
    stats: [
      {
        id: 'whitehouse|Highway|2-107',
        mean: 22,
        stddev: 5,
      },
    ],
  },
  {
    __typename: 'HighwaySegment',
    actualID: 'whitehouse',
    id: 'whitehouse|Highway|3',
    length: 21,
    name: 'Interstate 295',
    stats: [
      {
        id: 'whitehouse|Highway|3-107',
        mean: 0,
        stddev: 0,
      },
    ],
  },
  {
    __typename: 'HighwaySegment',
    actualID: 'whitehouse',
    id: 'whitehouse|Highway|4',
    length: 9,
    name: 'Interstate 66',
    stats: [
      {
        id: 'whitehouse|Highway|4-107',
        mean: 0,
        stddev: 0,
      },
    ],
  },
  {
    __typename: 'HighwaySegment',
    actualID: 'whitehouse',
    id: 'whitehouse|Highway|5',
    length: 36,
    name: 'Route 50',
    stats: [
      {
        id: 'whitehouse|Highway|5-107',
        mean: 0,
        stddev: 0,
      },
    ],
  },
  {
    __typename: 'HighwaySegment',
    actualID: 'whitehouse',
    id: 'whitehouse|Highway|6',
    length: 28,
    name: 'Route 29',
    stats: [
      {
        id: 'whitehouse|Highway|6-107',
        mean: 2,
        stddev: 2,
      },
    ],
  },
  {
    __typename: 'HighwaySegment',
    actualID: 'whitehouse',
    id: 'whitehouse|Highway|7',
    length: 39,
    name: 'Route 1',
    stats: [
      {
        id: 'whitehouse|Highway|7-107',
        mean: 22,
        stddev: 3,
      },
    ],
  },
];

export const people: Omit<Subdivision, 'coordinates' | 'homes' | 'vulnerableHomes'>[] = [
  {
    __typename: 'Subdivision',
    actualID: 'whitehouse',
    id: 'whitehouse|Subdivision|1',
    name: '56902',
    postCode: '56902',
    units: 500,
    stats: [
      {
        id: 'whitehouse|Subdivision|1-107',
        mean: 21,
        stddev: 2,
      },
    ],
  },
  {
    __typename: 'Subdivision',
    actualID: 'whitehouse',
    id: 'whitehouse|Subdivision|2',
    name: '56904',
    postCode: '56904',
    units: 500,
    stats: [
      {
        id: 'whitehouse|Subdivision|2-107',
        mean: 0,
        stddev: 0,
      },
    ],
  },
  {
    __typename: 'Subdivision',
    actualID: 'whitehouse',
    id: 'whitehouse|Subdivision|3',
    name: '56945',
    postCode: '56945',
    units: 500,
    stats: [
      {
        id: 'whitehouse|Subdivision|3-107',
        mean: 0,
        stddev: 0,
      },
    ],
  },
  {
    __typename: 'Subdivision',
    actualID: 'whitehouse',
    id: 'whitehouse|Subdivision|4',
    name: '20201',
    postCode: '12345',
    units: 10,
    stats: [
      {
        id: 'whitehouse|Subdivision|4-107',
        mean: 0,
        stddev: 0,
      },
    ],
  },
  {
    __typename: 'Subdivision',
    actualID: 'whitehouse',
    id: 'whitehouse|Subdivision|5',
    name: '20204',
    postCode: '11111',
    units: 500,
    stats: [
      {
        id: 'whitehouse|Subdivision|5-107',
        mean: 17,
        stddev: 2,
      },
    ],
  },
  {
    __typename: 'Subdivision',
    actualID: 'whitehouse',
    id: 'whitehouse|Subdivision|6',
    name: '20206',
    postCode: '11111',
    units: 500,
    stats: [
      {
        id: 'whitehouse|Subdivision|6-107',
        mean: 0,
        stddev: 0,
      },
    ],
  },
  {
    __typename: 'Subdivision',
    actualID: 'whitehouse',
    id: 'whitehouse|Subdivision|7',
    name: '20207',
    postCode: '11111',
    units: 500,
    stats: [
      {
        id: 'whitehouse|Subdivision|7-107',
        mean: 0,
        stddev: 0,
      },
    ],
  },
  {
    __typename: 'Subdivision',
    actualID: 'whitehouse',
    id: 'whitehouse|Subdivision|8',
    name: '20208',
    postCode: '11111',
    units: 500,
    stats: [
      {
        id: 'whitehouse|Subdivision|8-107',
        mean: 0,
        stddev: 0,
      },
    ],
  },
  {
    __typename: 'Subdivision',
    actualID: 'whitehouse',
    id: 'whitehouse|Subdivision|9',
    name: '20210',
    postCode: '11111',
    units: 500,
    stats: [
      {
        id: 'whitehouse|Subdivision|9-107',
        mean: 13,
        stddev: 1,
      },
    ],
  },
  {
    __typename: 'Subdivision',
    actualID: 'whitehouse',
    id: 'whitehouse|Subdivision|10',
    name: '20211',
    postCode: '11111',
    units: 500,
    stats: [
      {
        id: 'whitehouse|Subdivision|10-107',
        mean: 18,
        stddev: 1,
      },
    ],
  },
  {
    __typename: 'Subdivision',
    actualID: 'whitehouse',
    id: 'whitehouse|Subdivision|11',
    name: '20212',
    postCode: '11111',
    units: 500,
    stats: [
      {
        id: 'whitehouse|Subdivision|11-107',
        mean: 0,
        stddev: 0,
      },
    ],
  },
  {
    __typename: 'Subdivision',
    actualID: 'whitehouse',
    id: 'whitehouse|Subdivision|12',
    name: '20213',
    postCode: '11111',
    units: 500,
    stats: [
      {
        id: 'whitehouse|Subdivision|12-107',
        mean: 0,
        stddev: 0,
      },
    ],
  },
  {
    __typename: 'Subdivision',
    actualID: 'whitehouse',
    id: 'whitehouse|Subdivision|13',
    name: '20214',
    postCode: '11111',
    units: 500,
    stats: [
      {
        id: 'whitehouse|Subdivision|13-107',
        mean: 0,
        stddev: 0,
      },
    ],
  },
  {
    __typename: 'Subdivision',
    actualID: 'whitehouse',
    id: 'whitehouse|Subdivision|14',
    name: '20215',
    postCode: '11111',
    units: 500,
    stats: [
      {
        id: 'whitehouse|Subdivision|14-107',
        mean: 18,
        stddev: 2,
      },
    ],
  },
  {
    __typename: 'Subdivision',
    actualID: 'whitehouse',
    id: 'whitehouse|Subdivision|15',
    name: '20216',
    postCode: '11111',
    units: 500,
    stats: [
      {
        id: 'whitehouse|Subdivision|15-107',
        mean: 0,
        stddev: 0,
      },
    ],
  },
  {
    __typename: 'Subdivision',
    actualID: 'whitehouse',
    id: 'whitehouse|Subdivision|16',
    name: '20217',
    postCode: '11111',
    units: 500,
    stats: [
      {
        id: 'whitehouse|Subdivision|16-107',
        mean: 0,
        stddev: 0,
      },
    ],
  },
  {
    __typename: 'Subdivision',
    actualID: 'whitehouse',
    id: 'whitehouse|Subdivision|17',
    name: '20218',
    postCode: '11111',
    units: 500,
    stats: [
      {
        id: 'whitehouse|Subdivision|17-107',
        mean: 0,
        stddev: 0,
      },
    ],
  },
  {
    __typename: 'Subdivision',
    actualID: 'whitehouse',
    id: 'whitehouse|Subdivision|18',
    name: '20219',
    postCode: '11111',
    units: 500,
    stats: [
      {
        id: 'whitehouse|Subdivision|18-107',
        mean: 18,
        stddev: 1,
      },
    ],
  },
  {
    __typename: 'Subdivision',
    actualID: 'whitehouse',
    id: 'whitehouse|Subdivision|19',
    name: '20222',
    postCode: '11111',
    units: 500,
    stats: [
      {
        id: 'whitehouse|Subdivision|19-107',
        mean: 0,
        stddev: 0,
      },
    ],
  },
  {
    __typename: 'Subdivision',
    actualID: 'whitehouse',
    id: 'whitehouse|Subdivision|20',
    name: '20223',
    postCode: '11111',
    units: 500,
    stats: [
      {
        id: 'whitehouse|Subdivision|20-107',
        mean: 0,
        stddev: 0,
      },
    ],
  },
  {
    __typename: 'Subdivision',
    actualID: 'whitehouse',
    id: 'whitehouse|Subdivision|21',
    name: '20224',
    postCode: '11111',
    units: 500,
    stats: [
      {
        id: 'whitehouse|Subdivision|21-107',
        mean: 0,
        stddev: 0,
      },
    ],
  },
  {
    __typename: 'Subdivision',
    actualID: 'whitehouse',
    id: 'whitehouse|Subdivision|22',
    name: '20226',
    postCode: '20226',
    units: 500,
    stats: [
      {
        id: 'whitehouse|Subdivision|22-107',
        mean: 0,
        stddev: 0,
      },
    ],
  },
  {
    __typename: 'Subdivision',
    actualID: 'whitehouse',
    id: 'whitehouse|Subdivision|23',
    name: '20227',
    postCode: '11111',
    units: 500,
    stats: [
      {
        id: 'whitehouse|Subdivision|23-107',
        mean: 17,
        stddev: 2,
      },
    ],
  },
  {
    __typename: 'Subdivision',
    actualID: 'whitehouse',
    id: 'whitehouse|Subdivision|24',
    name: '20228',
    postCode: '11111',
    units: 500,
    stats: [
      {
        id: 'whitehouse|Subdivision|24-107',
        mean: 0,
        stddev: 0,
      },
    ],
  },
  {
    __typename: 'Subdivision',
    actualID: 'whitehouse',
    id: 'whitehouse|Subdivision|25',
    name: '20229',
    postCode: '11111',
    units: 500,
    stats: [
      {
        id: 'whitehouse|Subdivision|25-107',
        mean: 0,
        stddev: 0,
      },
    ],
  },
  {
    __typename: 'Subdivision',
    actualID: 'whitehouse',
    id: 'whitehouse|Subdivision|26',
    name: '20230',
    postCode: '11111',
    units: 500,
    stats: [
      {
        id: 'whitehouse|Subdivision|26-107',
        mean: 0,
        stddev: 0,
      },
    ],
  },
];

export const ports: Omit<Port, 'coordinates'>[] = [
  {
    __typename: 'Port',
    actualID: 'whitehouse',
    id: 'whitehouse|Port|1',
    name: 'Pentagon Lagoon Yacht Basin',
    stats: [
      {
        id: 'whitehouse|Port|1-107',
        mean: 0,
        stddev: 0,
      },
    ],
    cranes: 21,
    wharves: 9,
    yearBuilt: '1982',
  },
  {
    __typename: 'Port',
    actualID: 'whitehouse',
    id: 'whitehouse|Port|2',
    name: 'Selby Bay Yacht Basin',
    stats: [
      {
        id: 'whitehouse|Port|2-107',
        mean: 0,
        stddev: 0,
      },
    ],
    cranes: 25,
    wharves: 20,
    yearBuilt: '1978',
  },
  {
    __typename: 'Port',
    actualID: 'whitehouse',
    id: 'whitehouse|Port|3',
    name: 'Anchor Yacht Basin',
    stats: [
      {
        id: 'whitehouse|Port|3-107',
        mean: 3,
        stddev: 3,
      },
    ],
    cranes: 18,
    wharves: 9,
    yearBuilt: '1970',
  },
  {
    __typename: 'Port',
    actualID: 'whitehouse',
    id: 'whitehouse|Port|4',
    name: 'Herrington Harbour',
    stats: [
      {
        id: 'whitehouse|Port|4-107',
        mean: 20,
        stddev: 3,
      },
    ],
    cranes: 32,
    wharves: 6,
    yearBuilt: '2000',
  },
];

export const structural: Omit<Building, 'coordinates'>[] = [
  {
    __typename: 'Building',
    actualID: 'whitehouse',
    id: 'whitehouse|Building|1',
    constructionType: 'Steel frame',
    yearBuilt: '1792',
    stories: 4,
  },
];

export const resilienceStats: ResilienceStats[] = [
  {
    __typename: 'ResilienceStats',
    id: 'whitehouse-107',
    nearbyAirport: {
      __typename: 'AggregateStats',
      id: 'whitehouse-107-Airport',
      mean: 16,
      recoveryCurve: [
        [4, 0],
        [18, 7],
        [36, 23],
        [54, 48],
        [64, 76],
        [81, 96],
        [92, 98],
        [101, 100],
      ],
      stddev: 1,
    },
    nearbyBridge: {
      __typename: 'AggregateStats',
      id: 'whitehouse-107-Bridge',
      mean: 0,
      recoveryCurve: [
        [9, 0],
        [38, 7],
        [50, 30],
        [73, 34],
        [86, 56],
        [96, 60],
        [114, 79],
        [140, 91],
        [145, 100],
      ],
      stddev: 1,
    },
    nearbyHighway: {
      __typename: 'AggregateStats',
      id: 'whitehouse-107-Highway',
      mean: 0,
      recoveryCurve: [
        [7, 0],
        [16, 23],
        [40, 30],
        [56, 54],
        [63, 76],
        [64, 78],
        [87, 98],
        [107, 100],
      ],
      stddev: 1,
    },
    nearbyPeople: {
      __typename: 'AggregateStats',
      id: 'whitehouse-107-People',
      mean: 0,
      recoveryCurve: [
        [14, 0],
        [29, 25],
        [37, 44],
        [53, 58],
        [54, 59],
        [68, 60],
        [89, 65],
        [92, 70],
        [119, 82],
        [140, 100],
      ],
      stddev: 1,
    },
    nearbyPort: {
      __typename: 'AggregateStats',
      id: 'whitehouse-107-Port',
      mean: 0,
      recoveryCurve: [
        [17, 0],
        [27, 22],
        [51, 40],
        [53, 55],
        [55, 79],
        [59, 98],
        [74, 100],
      ],
      stddev: 1,
    },
    nearbyPower: {
      __typename: 'AggregateStats',
      id: 'whitehouse-107-Power',
      mean: 0,
      recoveryCurve: [
        [28, 0],
        [29, 30],
        [40, 45],
        [55, 46],
        [74, 52],
        [87, 75],
        [93, 79],
        [112, 100],
      ],
      stddev: 3,
    },
    nearbyIngressEgress: {
      __typename: 'AggregateStats',
      id: 'whitehouse-107-IngressEgress',
      mean: 0,
      recoveryCurve: [
        [28, 0],
        [29, 30],
        [40, 45],
        [55, 46],
        [74, 52],
        [87, 75],
        [93, 79],
        [112, 100],
      ],
      stddev: 3,
    },
    nearbyRepairTime: {
      __typename: 'AggregateStats',
      id: 'whitehouse-107-RepairTime',
      mean: 0,
      recoveryCurve: [
        [28, 0],
        [29, 30],
        [40, 45],
        [55, 46],
        [74, 52],
        [87, 75],
        [93, 79],
        [112, 100],
      ],
      stddev: 3,
    },
    nearbyIntegrated: {
      __typename: 'AggregateStats',
      id: 'whitehouse-107-Integrated',
      mean: 0,
      recoveryCurve: [
        [28, 0],
        [29, 30],
        [40, 45],
        [55, 46],
        [74, 52],
        [87, 75],
        [93, 79],
        [112, 100],
      ],
      stddev: 3,
    },
    slice: 107,
    structuralDamage: {
      __typename: 'AggregateStats',
      id: 'whitehouse-107-Structure',
      mean: 0,
      stddev: 1,
    },
  },
];

export const returnPeriodStats: ResilienceStats[] = [
  {
    __typename: 'ResilienceStats',
    id: 'whitehouse-17',
    nearbyAirport: {
      __typename: 'AggregateStats',
      id: 'whitehouse-17-Airport',
      mean: 0,
      recoveryCurve: [
        [25, 0],
        [27, 10],
        [32, 15],
        [58, 25],
        [61, 52],
        [75, 79],
        [77, 84],
        [102, 85],
        [132, 96],
        [148, 100],
      ],
      stddev: 1,
    },
    nearbyBridge: {
      __typename: 'AggregateStats',
      id: 'whitehouse-17-Bridge',
      mean: 0,
      recoveryCurve: [
        [3, 0],
        [30, 18],
        [57, 22],
        [62, 38],
        [84, 59],
        [111, 79],
        [127, 100],
      ],
      stddev: 1,
    },
    nearbyHighway: {
      __typename: 'AggregateStats',
      id: 'whitehouse-17-Highway',
      mean: 0,
      recoveryCurve: [
        [19, 0],
        [48, 30],
        [78, 46],
        [108, 70],
        [123, 82],
        [151, 100],
      ],
      stddev: 1,
    },
    nearbyPeople: {
      __typename: 'AggregateStats',
      id: 'whitehouse-17-People',
      mean: 12,
      recoveryCurve: [
        [11, 0],
        [27, 1],
        [46, 16],
        [58, 46],
        [81, 68],
        [94, 86],
        [103, 100],
      ],
      stddev: 1,
    },
    nearbyPort: {
      __typename: 'AggregateStats',
      id: 'whitehouse-17-Port',
      mean: 23,
      recoveryCurve: [
        [2, 0],
        [4, 30],
        [10, 37],
        [40, 67],
        [67, 91],
        [69, 100],
      ],
      stddev: 1,
    },
    nearbyPower: {
      __typename: 'AggregateStats',
      id: 'whitehouse-17-Power',
      mean: 4,
      recoveryCurve: [
        [27, 0],
        [37, 14],
        [64, 15],
        [73, 27],
        [75, 54],
        [92, 83],
        [106, 100],
      ],
      stddev: 4,
    },
    nearbyIngressEgress: {
      __typename: 'AggregateStats',
      id: 'whitehouse-17-IngressEgress',
      mean: 0,
      recoveryCurve: [
        [28, 0],
        [29, 30],
        [40, 45],
        [55, 46],
        [74, 52],
        [87, 75],
        [93, 79],
        [112, 100],
      ],
      stddev: 3,
    },
    nearbyRepairTime: {
      __typename: 'AggregateStats',
      id: 'whitehouse-17-RepairTime',
      mean: 0,
      recoveryCurve: [
        [28, 0],
        [29, 30],
        [40, 45],
        [55, 46],
        [74, 52],
        [87, 75],
        [93, 79],
        [112, 100],
      ],
      stddev: 3,
    },
    nearbyIntegrated: {
      __typename: 'AggregateStats',
      id: 'whitehouse-17-Integrated',
      mean: 0,
      recoveryCurve: [
        [28, 0],
        [29, 30],
        [40, 45],
        [55, 46],
        [74, 52],
        [87, 75],
        [93, 79],
        [112, 100],
      ],
      stddev: 3,
    },
    slice: 17,
    structuralDamage: {
      __typename: 'AggregateStats',
      id: 'whitehouse-17-Structure',
      mean: 0,
      stddev: 1,
    },
  },
  {
    __typename: 'ResilienceStats',
    id: 'whitehouse-19',
    nearbyAirport: {
      __typename: 'AggregateStats',
      id: 'whitehouse-19-Airport',
      mean: 0,
      recoveryCurve: [
        [9, 0],
        [29, 7],
        [42, 9],
        [59, 36],
        [71, 55],
        [72, 82],
        [90, 88],
        [118, 100],
      ],
      stddev: 1,
    },
    nearbyBridge: {
      __typename: 'AggregateStats',
      id: 'whitehouse-19-Bridge',
      mean: 0,
      recoveryCurve: [
        [8, 0],
        [21, 4],
        [24, 33],
        [31, 47],
        [54, 52],
        [79, 66],
        [86, 96],
        [98, 100],
      ],
      stddev: 1,
    },
    nearbyHighway: {
      __typename: 'AggregateStats',
      id: 'whitehouse-19-Highway',
      mean: 0,
      recoveryCurve: [
        [9, 0],
        [39, 15],
        [61, 42],
        [79, 53],
        [107, 75],
        [112, 93],
        [141, 100],
      ],
      stddev: 1,
    },
    nearbyPeople: {
      __typename: 'AggregateStats',
      id: 'whitehouse-19-People',
      mean: 0,
      recoveryCurve: [
        [8, 0],
        [28, 10],
        [44, 22],
        [67, 52],
        [73, 80],
        [79, 93],
        [102, 100],
      ],
      stddev: 1,
    },
    nearbyPort: {
      __typename: 'AggregateStats',
      id: 'whitehouse-19-Port',
      mean: 0,
      recoveryCurve: [
        [14, 0],
        [27, 29],
        [53, 56],
        [82, 65],
        [109, 86],
        [124, 89],
        [152, 100],
      ],
      stddev: 1,
    },
    nearbyPower: {
      __typename: 'AggregateStats',
      id: 'whitehouse-19-Power',
      mean: 0,
      recoveryCurve: [
        [8, 0],
        [22, 3],
        [34, 25],
        [46, 31],
        [60, 41],
        [62, 62],
        [73, 63],
        [84, 85],
        [104, 94],
        [113, 100],
      ],
      stddev: 1,
    },
    nearbyIngressEgress: {
      __typename: 'AggregateStats',
      id: 'whitehouse-19-IngressEgress',
      mean: 0,
      recoveryCurve: [
        [28, 0],
        [29, 30],
        [40, 45],
        [55, 46],
        [74, 52],
        [87, 75],
        [93, 79],
        [112, 100],
      ],
      stddev: 3,
    },
    nearbyRepairTime: {
      __typename: 'AggregateStats',
      id: 'whitehouse-19-RepairTime',
      mean: 0,
      recoveryCurve: [
        [28, 0],
        [29, 30],
        [40, 45],
        [55, 46],
        [74, 52],
        [87, 75],
        [93, 79],
        [112, 100],
      ],
      stddev: 3,
    },
    nearbyIntegrated: {
      __typename: 'AggregateStats',
      id: 'whitehouse-19-Integrated',
      mean: 0,
      recoveryCurve: [
        [28, 0],
        [29, 30],
        [40, 45],
        [55, 46],
        [74, 52],
        [87, 75],
        [93, 79],
        [112, 100],
      ],
      stddev: 3,
    },
    slice: 19,
    structuralDamage: {
      __typename: 'AggregateStats',
      id: 'whitehouse-19-Structure',
      mean: 0,
      stddev: 1,
    },
  },
  {
    __typename: 'ResilienceStats',
    id: 'whitehouse-21',
    nearbyIngressEgress: {
      __typename: 'AggregateStats',
      id: 'whitehouse-21-IngressEgress',
      mean: 0,
      recoveryCurve: [
        [28, 0],
        [29, 30],
        [40, 45],
        [55, 46],
        [74, 52],
        [87, 75],
        [93, 79],
        [112, 100],
      ],
      stddev: 3,
    },
    nearbyRepairTime: {
      __typename: 'AggregateStats',
      id: 'whitehouse-21-RepairTime',
      mean: 0,
      recoveryCurve: [
        [28, 0],
        [29, 30],
        [40, 45],
        [55, 46],
        [74, 52],
        [87, 75],
        [93, 79],
        [112, 100],
      ],
      stddev: 3,
    },
    nearbyIntegrated: {
      __typename: 'AggregateStats',
      id: 'whitehouse-21-Integrated',
      mean: 0,
      recoveryCurve: [
        [28, 0],
        [29, 30],
        [40, 45],
        [55, 46],
        [74, 52],
        [87, 75],
        [93, 79],
        [112, 100],
      ],
      stddev: 3,
    },
    nearbyAirport: {
      __typename: 'AggregateStats',
      id: 'whitehouse-21-Airport',
      mean: 0,
      recoveryCurve: [
        [27, 0],
        [28, 7],
        [47, 30],
        [54, 59],
        [66, 66],
        [73, 72],
        [93, 81],
        [120, 100],
      ],
      stddev: 1,
    },
    nearbyBridge: {
      __typename: 'AggregateStats',
      id: 'whitehouse-21-Bridge',
      mean: 0,
      recoveryCurve: [
        [12, 0],
        [28, 18],
        [38, 24],
        [39, 41],
        [61, 45],
        [83, 53],
        [94, 56],
        [112, 67],
        [125, 83],
        [127, 100],
      ],
      stddev: 1,
    },
    nearbyHighway: {
      __typename: 'AggregateStats',
      id: 'whitehouse-21-Highway',
      mean: 0,
      recoveryCurve: [
        [16, 0],
        [26, 30],
        [48, 47],
        [52, 56],
        [67, 79],
        [94, 95],
        [122, 100],
      ],
      stddev: 1,
    },
    nearbyPeople: {
      __typename: 'AggregateStats',
      id: 'whitehouse-21-People',
      mean: 0,
      recoveryCurve: [
        [29, 0],
        [40, 20],
        [58, 40],
        [59, 68],
        [61, 70],
        [67, 96],
        [96, 100],
      ],
      stddev: 1,
    },
    nearbyPort: {
      __typename: 'AggregateStats',
      id: 'whitehouse-21-Port',
      mean: 0,
      recoveryCurve: [
        [30, 0],
        [42, 4],
        [72, 16],
        [73, 32],
        [74, 41],
        [91, 43],
        [114, 69],
        [123, 83],
        [141, 100],
      ],
      stddev: 1,
    },
    nearbyPower: {
      __typename: 'AggregateStats',
      id: 'whitehouse-21-Power',
      mean: 0,
      recoveryCurve: [
        [15, 0],
        [34, 24],
        [38, 53],
        [63, 62],
        [84, 81],
        [109, 100],
      ],
      stddev: 1,
    },
    slice: 21,
    structuralDamage: {
      __typename: 'AggregateStats',
      id: 'whitehouse-21-Structure',
      mean: 0,
      stddev: 1,
    },
  },
  {
    __typename: 'ResilienceStats',
    id: 'whitehouse-22',
    nearbyIngressEgress: {
      __typename: 'AggregateStats',
      id: 'whitehouse-22-IngressEgress',
      mean: 0,
      recoveryCurve: [
        [28, 0],
        [29, 30],
        [40, 45],
        [55, 46],
        [74, 52],
        [87, 75],
        [93, 79],
        [112, 100],
      ],
      stddev: 3,
    },
    nearbyRepairTime: {
      __typename: 'AggregateStats',
      id: 'whitehouse-22-RepairTime',
      mean: 0,
      recoveryCurve: [
        [28, 0],
        [29, 30],
        [40, 45],
        [55, 46],
        [74, 52],
        [87, 75],
        [93, 79],
        [112, 100],
      ],
      stddev: 3,
    },
    nearbyIntegrated: {
      __typename: 'AggregateStats',
      id: 'whitehouse-22-Integrated',
      mean: 0,
      recoveryCurve: [
        [28, 0],
        [29, 30],
        [40, 45],
        [55, 46],
        [74, 52],
        [87, 75],
        [93, 79],
        [112, 100],
      ],
      stddev: 3,
    },
    nearbyAirport: {
      __typename: 'AggregateStats',
      id: 'whitehouse-22-Airport',
      mean: 2,
      recoveryCurve: [
        [17, 0],
        [34, 23],
        [41, 48],
        [50, 67],
        [55, 84],
        [85, 100],
      ],
      stddev: 2,
    },
    nearbyBridge: {
      __typename: 'AggregateStats',
      id: 'whitehouse-22-Bridge',
      mean: 0,
      recoveryCurve: [
        [3, 0],
        [29, 2],
        [44, 32],
        [65, 34],
        [80, 48],
        [98, 60],
        [117, 83],
        [131, 89],
        [152, 100],
      ],
      stddev: 1,
    },
    nearbyHighway: {
      __typename: 'AggregateStats',
      id: 'whitehouse-22-Highway',
      mean: 0,
      recoveryCurve: [
        [3, 0],
        [26, 9],
        [47, 28],
        [77, 48],
        [107, 77],
        [124, 100],
      ],
      stddev: 1,
    },
    nearbyPeople: {
      __typename: 'AggregateStats',
      id: 'whitehouse-22-People',
      mean: 0,
      recoveryCurve: [
        [2, 0],
        [3, 26],
        [4, 45],
        [14, 58],
        [25, 80],
        [33, 85],
        [53, 100],
      ],
      stddev: 1,
    },
    nearbyPort: {
      __typename: 'AggregateStats',
      id: 'whitehouse-22-Port',
      mean: 0,
      recoveryCurve: [
        [2, 0],
        [14, 19],
        [35, 34],
        [54, 48],
        [70, 73],
        [82, 94],
        [112, 100],
      ],
      stddev: 1,
    },
    nearbyPower: {
      __typename: 'AggregateStats',
      id: 'whitehouse-22-Power',
      mean: 0,
      recoveryCurve: [
        [2, 0],
        [10, 2],
        [17, 24],
        [26, 50],
        [28, 61],
        [51, 63],
        [67, 76],
        [90, 89],
        [92, 92],
        [101, 100],
      ],
      stddev: 1,
    },
    slice: 22,
    structuralDamage: {
      __typename: 'AggregateStats',
      id: 'whitehouse-22-Structure',
      mean: 2,
      stddev: 2,
    },
  },
  {
    __typename: 'ResilienceStats',
    id: 'whitehouse-23',
    nearbyIngressEgress: {
      __typename: 'AggregateStats',
      id: 'whitehouse-23-IngressEgress',
      mean: 0,
      recoveryCurve: [
        [28, 0],
        [29, 30],
        [40, 45],
        [55, 46],
        [74, 52],
        [87, 75],
        [93, 79],
        [112, 100],
      ],
      stddev: 3,
    },
    nearbyRepairTime: {
      __typename: 'AggregateStats',
      id: 'whitehouse-23-RepairTime',
      mean: 0,
      recoveryCurve: [
        [28, 0],
        [29, 30],
        [40, 45],
        [55, 46],
        [74, 52],
        [87, 75],
        [93, 79],
        [112, 100],
      ],
      stddev: 3,
    },
    nearbyIntegrated: {
      __typename: 'AggregateStats',
      id: 'whitehouse-23-Integrated',
      mean: 0,
      recoveryCurve: [
        [28, 0],
        [29, 30],
        [40, 45],
        [55, 46],
        [74, 52],
        [87, 75],
        [93, 79],
        [112, 100],
      ],
      stddev: 3,
    },
    nearbyAirport: {
      __typename: 'AggregateStats',
      id: 'whitehouse-23-Airport',
      mean: 15,
      recoveryCurve: [
        [2, 0],
        [9, 17],
        [19, 25],
        [30, 39],
        [34, 69],
        [53, 76],
        [56, 100],
      ],
      stddev: 1,
    },
    nearbyBridge: {
      __typename: 'AggregateStats',
      id: 'whitehouse-23-Bridge',
      mean: 0,
      recoveryCurve: [
        [4, 0],
        [23, 14],
        [42, 38],
        [62, 55],
        [66, 73],
        [85, 88],
        [111, 100],
      ],
      stddev: 1,
    },
    nearbyHighway: {
      __typename: 'AggregateStats',
      id: 'whitehouse-23-Highway',
      mean: 6,
      recoveryCurve: [
        [7, 0],
        [17, 24],
        [25, 37],
        [51, 61],
        [79, 72],
        [93, 79],
        [114, 100],
      ],
      stddev: 1,
    },
    nearbyPeople: {
      __typename: 'AggregateStats',
      id: 'whitehouse-23-People',
      mean: 0,
      recoveryCurve: [
        [26, 0],
        [32, 26],
        [53, 50],
        [56, 72],
        [64, 97],
        [77, 100],
      ],
      stddev: 1,
    },
    nearbyPort: {
      __typename: 'AggregateStats',
      id: 'whitehouse-23-Port',
      mean: 0,
      recoveryCurve: [
        [30, 0],
        [37, 2],
        [43, 11],
        [64, 36],
        [91, 53],
        [100, 81],
        [107, 95],
        [134, 100],
      ],
      stddev: 1,
    },
    nearbyPower: {
      __typename: 'AggregateStats',
      id: 'whitehouse-23-Power',
      mean: 0,
      recoveryCurve: [
        [6, 0],
        [12, 15],
        [30, 40],
        [37, 51],
        [61, 57],
        [80, 58],
        [96, 64],
        [105, 77],
        [108, 95],
        [117, 100],
      ],
      stddev: 1,
    },
    slice: 23,
    structuralDamage: {
      __typename: 'AggregateStats',
      id: 'whitehouse-23-Structure',
      mean: 15,
      stddev: 1,
    },
  },
];

const data: GetBuiltObjectAnalysisQuery = {
  builtObject: {
    id: 'whitehouse',
    name: 'The White House',
    address: {
      houseNumber: '1600',
      street: 'Pennsylvania Avenue',
      city: 'Washington',
      suburb: '',
      county: '',
      state: '',
      stateCode: 'DC',
      stateDistrict: '',
      countryCode: 'JP',
      country: 'Japan',
      postCode: '20500',
      formattedAddress: '1600 Pennsylvania Avenue, Washington, DC 20500',
      addressDetails: '',
      __typename: 'Address',
    },
    buildingsCount: 1,
    coordinates: [-77.033, 38.9],
    resilienceStats,
    airports,
    bridges,
    highways,
    people,
    ports,
    structural,
    __typename: 'BuiltObject',
  },
};

const fullData = cloneDeep(data);
fullData.builtObject.resilienceStats.push(...returnPeriodStats);

const fullData2 = cloneDeep(data);
fullData2.builtObject.resilienceStats = [];
fullData2.builtObject.resilienceStats.push(...returnPeriodStats);

export const builtObjectAnalysisFixture = {
  request: {
    query: GetBuiltObjectAnalysisDocument,
    variables: {
      id: '100100100100',
      activeSlice: [107],
      slices: [107, 17, 19, 21, 22, 23],
    },
  },
  result: { data: fullData },
};

export const builtObjectDnaAnalysisFixture = {
  request: {
    query: GetBuiltObjectAnalysisDocument,
    variables: {
      id: '100100100100',
      activeSlice: [107],
      slices: [107, 17, 19, 21, 22, 23],
      loadDnaStats: true,
    },
  },
  result: { data: fullData },
};

export const builtObjectAnalysisFixture2 = {
  request: {
    query: GetBuiltObjectAnalysisDocument,
    variables: {
      id: '100100100100',
      activeSlice: [17],
      slices: [17, 19, 21, 22, 23],
    },
  },
  result: { data: fullData2 },
};

export const builtObjectAnalysisFixtureSkip = {
  request: {
    query: GetBuiltObjectAnalysisDocument,
    variables: {
      id: '100100100100',
      activeSlice: [107],
      slices: [107],
    },
  },
  result: { data },
};
