import * as React from 'react';
import { Map, ScaleControl } from 'mapbox-gl';

import { MeasurementPreferences } from '../../../__generated__/graphql';

const CONTAINER_STYLE: React.CSSProperties = {
  position: 'absolute',
  zIndex: 2,
  inset: 'auto 10px 10px auto',
  marginBottom: '15px',
  marginRight: '120px',
  padding: '3px 7px',
  display: 'flex',
  alignItems: 'baseline',
  opacity: 0.85,
};

const SCALE_STYLE: React.CSSProperties = {
  borderTop: 'none',
  borderRight: '2px solid rgb(126, 132, 144)',
  borderBottom: '2px solid rgb(126, 132, 144)',
  borderLeft: '2px solid rgb(126, 132, 144)',
  boxShadow: 'rgb(0 0 0 / 30%) 0px 1px 4px',
  height: '7px',
  borderBottomLeftRadius: '1px',
  borderBottomRightRadius: '1px',
};

const TEXT_STYLE: React.CSSProperties = {
  marginLeft: '10px',
  fontWeight: 'bold',
  color: 'white',
};

type CustomScaleControlProps = {
  units: MeasurementPreferences;
  map: Map;
  // eslint-disable-next-line react/no-unused-prop-types
  zoom: number; // force re-rerender when zoom level changes
};

const CustomScaleControlFC: React.FC<CustomScaleControlProps> = ({ units, map }) => {
  const [container, setContainer] = React.useState<HTMLDivElement>();

  React.useEffect(() => {
    const scale = new ScaleControl({
      unit: units === MeasurementPreferences.ImperialSystem ? 'imperial' : 'metric',
    });
    map.addControl(scale, 'bottom-right');
    const scaleControl = document.querySelector('.mapboxgl-ctrl-scale') as HTMLDivElement;
    scaleControl.style.display = 'none';
    setContainer(scaleControl);

    return () => {
      if (map.hasControl(scale)) {
        map.removeControl(scale);
      }
    };
  }, [map, units]);

  return container ? (
    <div style={CONTAINER_STYLE} data-test-id="CustomScaleControl-container">
      <div
        style={{ width: container.style.width, ...SCALE_STYLE }}
        data-test-id="CustomScaleControl-scale"
      />
      <div style={TEXT_STYLE} data-test-id="CustomScaleControl-text">
        {container.textContent}
      </div>
    </div>
  ) : null;
};

const CustomScaleControl = React.memo(CustomScaleControlFC);
CustomScaleControl.displayName = 'CustomScaleControl';
export default CustomScaleControl;
