import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { FilledInput, InputAdornment } from '@mui/material';

type DaysHoursInputProps = {
  hours: number;
  minHours: number;
  maxHours: number;
  onChangeHours: (result: { id: string; value: number }) => void;
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  classes?: any;
};

const DaysHoursInput: React.FC<DaysHoursInputProps> = ({
  hours,
  minHours,
  maxHours,
  onChangeHours,
  id,
  classes,
}) => {
  const daysElId = `${id}-days`;
  const hoursElId = `${id}-hours`;

  const { t } = useTranslation();

  const [displayDays, setDisplayDays] = React.useState<string>(String(Math.floor(hours / 24)));
  const [displayHrs, setDisplayHrs] = React.useState<string>(String(hours % 24));

  React.useEffect(() => {
    setDisplayDays(String(Math.floor(hours / 24)));
    setDisplayHrs(String(hours % 24));
  }, [hours]);

  const onFocus = React.useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    e.currentTarget.select();
  }, []);

  // When tabbing out / leaving focus / pressing enter key, autocorrect the user's input convert
  // hours input of > 24 hours, blank and '0 day 0 hour' inputs and total hours out of range
  const onBlur = React.useCallback(() => {
    const days = (document.querySelector(`input#${daysElId}`) as HTMLInputElement).value;
    const hrs = (document.querySelector(`input#${hoursElId}`) as HTMLInputElement).value;
    const newHours = +hrs + +days * 24;
    let boundedNewHours = newHours < minHours ? minHours : newHours;
    boundedNewHours = boundedNewHours > maxHours ? maxHours : boundedNewHours;
    onChangeHours({ id, value: boundedNewHours });
    setDisplayDays(String(Math.floor(boundedNewHours / 24)));
    setDisplayHrs(String(boundedNewHours % 24));
  }, [minHours, maxHours, daysElId, hoursElId, id, onChangeHours]);

  // The user's inputed value is not corrected WHILE the user is typing. That's in onBlur.
  const onChangeDisplayDays = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayDays(e.target.value);
  }, []);

  // The user's inputed value is not corrected WHILE the user is typing. That's in onBlur.
  const onChangeDisplayHrs = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayHrs(e.target.value);
  }, []);

  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') return;
      onBlur();
    },
    [onBlur],
  );

  return (
    <div data-test-id={`DaysHoursInput-${id}`} className={classes?.parent}>
      <FilledInput
        id={daysElId}
        value={displayDays}
        type="number"
        inputProps={{ 'data-test-id': `DaysHoursInput-days-${id}` }}
        endAdornment={
          <InputAdornment
            position="end"
            disablePointerEvents
            disableTypography
            classes={{ root: classes?.inputAdornedEnd }}
          >
            {t('inputs:dayAbbrev')}
          </InputAdornment>
        }
        classes={classes && { root: classes?.root, input: classes?.input }}
        disableUnderline
        onBlur={onBlur}
        onChange={onChangeDisplayDays}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
      />
      <FilledInput
        id={hoursElId}
        value={displayHrs}
        type="number"
        inputProps={{ 'data-test-id': `DaysHoursInput-hours-${id}` }}
        endAdornment={
          <InputAdornment
            position="end"
            disablePointerEvents
            disableTypography
            classes={{ root: classes?.inputAdornedEnd }}
          >
            {t('inputs:hourAbbrev')}
          </InputAdornment>
        }
        disableUnderline
        classes={{ root: classes?.root, input: classes?.input }}
        onBlur={onBlur}
        onChange={onChangeDisplayHrs}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};
DaysHoursInput.displayName = 'DaysHoursInput';
export default DaysHoursInput;
