import * as React from 'react';
import { Layer, Source } from 'react-mapbox-gl';
import { GeoJSONSourceRaw } from 'mapbox-gl';
import { useSearchParams } from 'react-router-dom';

import {
  GeoJsonFeature,
  GeoLookupInput,
  GetNearbyBuildingsQuery,
  useGetNearbyBuildingsQuery,
} from '../../../__generated__/graphql';
import {
  NEARBY_BUILDINGS_RADIUS_METERS,
  NEARBY_BUILDINGS_SOURCE_ID,
  ORANGE,
} from '../../../util/productGlobals';
import { App } from '../../../PlanningApp/AppConfig';
import useApiErrSnackbar from '../../CommonComponents/Snackbar/useApiErrSnackbar';

type NearbyBuildingsArr = GetNearbyBuildingsQuery['getNearbyBuiltObjects'];

type MakeGeojsonProps = {
  nearbyBuildings: NearbyBuildingsArr;
  promoteId?: string;
};

export const makeNearbyBuildingsGeoJson: (props: MakeGeojsonProps) => GeoJSONSourceRaw = ({
  nearbyBuildings,
  promoteId,
}) => {
  const nearbyBobjFeatures: GeoJsonFeature[] = (nearbyBuildings ?? []).map(
    (building, buildingIdx: number) => {
      const { id, coordinates } = building;
      const position: GeoJSON.Position = coordinates;
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: position,
        },
        id: String(buildingIdx), // Note that in a geojson, the feature id must be an int that is turned into a string
        properties: {
          loctype: 'nearbyBobj',
          id,
          coordinates: position,
          addressJSON: JSON.stringify(building.address),
        },
      } as GeoJsonFeature;
    },
  );
  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: nearbyBobjFeatures,
    },
    promoteId,
  } as GeoJSONSourceRaw;
};

const NearbyBobjSourceFC: React.FC<{ nearbyBuildings: NearbyBuildingsArr }> = ({
  nearbyBuildings,
}) => {
  const nearbyBuiltObjectsGeoJson = React.useMemo(
    () =>
      makeNearbyBuildingsGeoJson({
        nearbyBuildings,
        promoteId: 'id',
      }),
    [nearbyBuildings],
  );

  return <Source id={NEARBY_BUILDINGS_SOURCE_ID} geoJsonSource={nearbyBuiltObjectsGeoJson} />;
};
export const NearbyBobjSource = React.memo(NearbyBobjSourceFC);
NearbyBobjSource.displayName = 'NearbyBobjSource';

const NearbyBuildingsLayer: React.FC<{ locationId: string; coordinates: number[] }> = ({
  locationId,
  coordinates,
}) => {
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();

  const [searchParams] = useSearchParams();
  const isLayerVisible = searchParams?.get('bldgs') === 'true';

  const queryInputParams: GeoLookupInput = {
    coordinates,
    builtObjectType: null,
    withinMeters: NEARBY_BUILDINGS_RADIUS_METERS,
  };

  const { data, error: nearbyBuildingsError } = useGetNearbyBuildingsQuery({
    variables: { geoInput: queryInputParams },
  });

  React.useEffect(() => {
    if (nearbyBuildingsError) {
      App.error('[NearbyBuildingsLayer - GetNearbyBuildingsQuery] error: ', nearbyBuildingsError);
      enqueueApiErrSnackbar();
    }
  }, [enqueueApiErrSnackbar, nearbyBuildingsError]);

  const nearbyBuildings = data?.getNearbyBuiltObjects;
  if (nearbyBuildingsError || !nearbyBuildings || nearbyBuildings?.length === 0) {
    return null;
  }

  return (
    <>
      <NearbyBobjSource nearbyBuildings={nearbyBuildings} />
      <Layer
        id={NEARBY_BUILDINGS_SOURCE_ID}
        sourceId={NEARBY_BUILDINGS_SOURCE_ID}
        type="circle"
        layout={{ visibility: 'visible' }}
        paint={{
          'circle-radius': 4,
          'circle-color': ORANGE,
          'circle-opacity': isLayerVisible ? 1 : 0,
        }}
        filter={['==', ['get', 'loctype'], 'nearbyBobj']}
        minZoom={14}
        metadata={{ locationId, coordinates }} // For debugging purposes only, when inspecting the map
      />
    </>
  );
};

NearbyBuildingsLayer.displayName = 'NearbyBuildingsLayer';
export default NearbyBuildingsLayer;
