import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { List, ListItem, ListItemIcon, ListItemText, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import NoDataIcon from '../../../../../assets/images/NoData.svg';
import { WHITE } from '../../../../util/productGlobals';

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    padding: '0 !important',
  },
  listItemIcon: {
    width: '4px',
    height: '4px',
    minWidth: '4px',
    backgroundColor: WHITE,
    borderRadius: '50%',
    marginRight: 6,
  },
  listItemText: {
    margin: '0 !important',
  },
  listItemTextPrimary: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(18),
    fontWeight: 500,
  },
}));

const NoLocationDataFound: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div
      className="o-flex-center-container u-flex-column u-paddingx--16 u-paddingy--30 u-marginbottom--32"
      data-test-id="NoLocationDataFound"
    >
      <img
        src={NoDataIcon}
        alt={t('detailView:noData')}
        width={42}
        data-test-id="NoLocationDataFoundIcon"
      />
      <Typography
        variant="body1"
        className="u-paddingtop--10 u-paddingbottom--24"
        color="textPrimary"
      >
        {t('detailView:noDataAboutThisLocation')}
      </Typography>
      <div className="o-flexcol-container" data-test-id="NoLocationDataFoundDescription">
        <Typography variant="caption" color="textPrimary">
          {t('detailView:noDataSolution:description')}
        </Typography>
        <List>
          {[
            t('detailView:noDataSolution:fixUrl'),
            t('detailView:noDataSolution:changeFilteringCriteria'),
            t('detailView:noDataSolution:changeSearchCriteria'),
            t('detailView:noDataSolution:zoomOut'),
          ].map((body) => (
            <ListItem classes={{ root: classes.listItem }} key={body}>
              <ListItemIcon classes={{ root: classes.listItemIcon }} />
              <ListItemText
                classes={{ root: classes.listItemText, primary: classes.listItemTextPrimary }}
              >
                {body}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};

NoLocationDataFound.displayName = 'NoLocationDataFound';
export default NoLocationDataFound;
