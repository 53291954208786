import * as React from 'react';
import {
  Link as RouterLink,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
  Button,
  CardActions,
  CardContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  EditAddressInput,
  GetLocationsFileDetailsDocument,
  GetLocationsInfoWithFilterDocument,
  GetLocationsWithFilterDocument,
  GetOriginalLocationsFileDocument,
  GetOriginalLocationsFileRecordDocument,
  useEditLocationMutation,
  useGetLocationBuildingMatchQuery,
  useGetLocationsFileDetailsQuery,
  useGetSliceIdxQuery,
  useUpdateLocationBuildingMatchMutation,
} from '../../../__generated__/graphql';
import { GRAY5, ORANGE } from '../../../util/productGlobals';
import { addressToEditAddressInput } from '../../../util/addresses/addressUtils';
import { AMP_EVENT_SAVE_MATCH } from '../../../plugins/amplitudeevents';
import { App } from '../../../PlanningApp/AppConfig';
import cache from '../../../PlanningApp/client/cache';
import { LocRecord } from './locationMatchHelpers';
import { sendAmplitudeData } from '../../../plugins/amplitude';

const addressJSONToAddress: (addressJSON: string) => EditAddressInput = (addressJSON) => {
  let parsedBuildingAddress;
  try {
    parsedBuildingAddress = JSON.parse(addressJSON ?? null);
  } catch (parseError) {
    App.error(
      '[BuildingPickerCard] - error parsing address of selected Building. Not in JSON format:  ',
      parseError,
    );
    return null;
  }
  if (!parsedBuildingAddress) {
    return null;
  }

  return addressToEditAddressInput(parsedBuildingAddress);
};

const useStyles = makeStyles(() => ({
  callOut: {
    color: GRAY5,
    borderRadius: 6,
    padding: 8,
    border: `1px solid ${ORANGE}`,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

const BuildingPickerCard: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { locRecord } = useOutletContext() as { locRecord: LocRecord };
  const locationID = locRecord?.audit?.locationID;
  const locMatchURL = `/locations/${locationID}`;
  const buildingsTestID = `${BuildingPickerCard.displayName}-highlightBuildingLayers`;
  const satelliteTestID = `${BuildingPickerCard.displayName}-satelliteView`;
  const snapTestID = `${BuildingPickerCard.displayName}-snapToggle`;
  const { callOut } = useStyles();

  const { data: userData } = useGetLocationsFileDetailsQuery();
  const {
    data: { locationBuildingMatch },
  } = useGetLocationBuildingMatchQuery();

  const [resetLocationMatch] = useUpdateLocationBuildingMatchMutation({
    variables: { match: null },
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const satelliteEnabled = React.useMemo(
    () => searchParams?.get('satellite') === 'true',
    [searchParams],
  );
  const buildingsEnabled = React.useMemo(
    () => searchParams?.get('bldgs') === 'true',
    [searchParams],
  );
  const snapEnabled = React.useMemo(() => searchParams?.get('snap') === 'true', [searchParams]);

  App.assert(locationID === locationBuildingMatch?.locationID || !locationBuildingMatch);
  const buildingID = locationBuildingMatch?.buildingID;
  const buildingAddressJSON = locationBuildingMatch?.buildingAddressJSON;
  const newBuildingAddress = addressJSONToAddress(buildingAddressJSON);

  const {
    data: { planView },
  } = useGetSliceIdxQuery();

  const [editLocation, { data }] = useEditLocationMutation({
    refetchQueries: [
      // Update the upload file record for this location to reflect the new matching builtObject
      { query: GetOriginalLocationsFileRecordDocument, variables: { locationID } },
      // Need to update the #acceptedLocations & #errorLocations
      { query: GetOriginalLocationsFileDocument },
      { query: GetLocationsFileDetailsDocument },
      {
        query: GetLocationsWithFilterDocument,
        variables: {
          sliceIdx: planView?.sliceIdx,
          input: {
            matchCriteria: {
              isPendingMIB: false,
            },
          },
        },
      },
      {
        query: GetLocationsInfoWithFilterDocument,
        variables: {
          input: {
            matchCriteria: {
              isPendingMIB: false,
            },
          },
        },
      },
    ],
    onCompleted: () => {
      App.debug('[BuildingPickerCard]: editLocation mutation success. data: ', data?.editLocation);
      if (userData && locationID && buildingID) {
        const fileId = userData.user.productSettings.locationFile.id;
        const userId = userData.user.personalInfo.contact.email;
        sendAmplitudeData(AMP_EVENT_SAVE_MATCH, {
          userId,
          fileId,
          locationId: locationID,
          builtObjectId: buildingID,
        });
      }
      if (data?.editLocation) {
        cache.evict({ id: cache.identify(data?.editLocation) });
        cache.gc();
      }
      // When we change a LocationObject's associated builtObject, ALL of its resilience stats,
      // lifeline markers, lifeline stats, & SYW analysis data ALL change. Thus all these queries
      // need to be re-queried. Instead of refectching all these slightly different queries,
      // it's easiest to just evict the LocationObject from the cache and then garbage collect
      // to get rid of this location's stale resilience stats (for all slices) and
      // lifeline stats objects, etc.
      resetLocationMatch();
      navigate(locMatchURL);
    },
    onError: (err: ApolloError) => {
      if (!data?.editLocation) {
        // The mutation might throw an error even though it succeeded, due to the cache eviction
        // of the mutated locationObject that the mutation returns as data. It's fine. We don't need
        // that data in this component and other components will re-query for the locaiton that is
        // not in cache.
        App.error('[BuildingPickerCard - onError]: editLocationMutation failed: ', err);
      }
    },
  });

  const onClickCancel = React.useCallback(() => {
    resetLocationMatch();
    navigate(locMatchURL);
  }, [resetLocationMatch, navigate, locMatchURL]);

  const onClickSaveMatch = React.useCallback(() => {
    if (buildingID && locationID) {
      editLocation({
        variables: {
          input: {
            id: locationID,
            builtObjectID: buildingID,
            locationAddress: newBuildingAddress,
            visibility: true,
          },
        },
      });
    }
  }, [editLocation, locationID, buildingID, newBuildingAddress]);

  const updateSearchTerms = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      event.stopPropagation();
      const { name } = event.target;

      if (name === buildingsTestID) {
        setSearchParams({
          bldgs: checked ? 'true' : 'false',
          satellite: satelliteEnabled ? 'true' : 'false',
          snap: snapEnabled ? 'true' : 'false',
        });
      }

      if (name === satelliteTestID) {
        setSearchParams({
          bldgs: buildingsEnabled ? 'true' : 'false',
          satellite: checked ? 'true' : 'false',
          snap: snapEnabled ? 'true' : 'false',
        });
      }

      if (name === snapTestID) {
        setSearchParams({
          bldgs: buildingsEnabled ? 'true' : 'false',
          satellite: satelliteEnabled ? 'true' : 'false',
          snap: checked ? 'true' : 'false',
        });
      }
    },
    [
      buildingsEnabled,
      buildingsTestID,
      satelliteEnabled,
      satelliteTestID,
      snapEnabled,
      snapTestID,
      setSearchParams,
    ],
  );

  const satelliteLabel: string = t('locationMatch:mapPopup:matchingSatelliteLayer');
  const buildingLabel: string = t('locationMatch:mapPopup:matchingBuildingLayer');
  const snapLabel: string = t('locationMatch:mapPopup:snapToToggle');

  return (
    <div data-test-id="locationMatchSidePanel-BuildingPicker">
      <CardContent sx={{ pt: 0, pl: 2 }}>
        <div className={`${callOut} u-marginbottom--16`}>
          <Typography color="textSecondary">
            {t('locationMatch:mapPopup:dragInstructions')}
          </Typography>
        </div>
        <FormControl>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  data-test-id={buildingsTestID}
                  checked={buildingsEnabled}
                  onChange={updateSearchTerms}
                  name={buildingsTestID}
                />
              }
              label={buildingLabel}
            />
            <FormControlLabel
              control={
                <Switch
                  data-test-id={satelliteTestID}
                  checked={satelliteEnabled}
                  onChange={updateSearchTerms}
                  name={satelliteTestID}
                />
              }
              label={satelliteLabel}
            />
            <FormControlLabel
              control={
                <Switch
                  data-test-id={snapTestID}
                  checked={snapEnabled}
                  onChange={updateSearchTerms}
                  name={snapTestID}
                />
              }
              label={snapLabel}
            />
          </FormGroup>
        </FormControl>
      </CardContent>
      <CardActions>
        <Button
          variant="outlined"
          color="primary"
          component={RouterLink}
          to={`/locations/${locationID}`}
          onClick={onClickCancel}
          data-test-id="cancelBuildingMatch"
        >
          {t('locationMatch:mapPopup:cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          data-test-id="saveNewBuildingMatch"
          disabled={!buildingID}
          onClick={onClickSaveMatch}
          className="u-marginleft--16"
        >
          {t('locationMatch:mapPopup:save')}
        </Button>
      </CardActions>
    </div>
  );
};
BuildingPickerCard.displayName = 'BuildingPickerCard';
export default BuildingPickerCard;
