import * as React from 'react';

import { useGetUserAllEntitlementsQuery } from '../__generated__/graphql';
import { App } from '../PlanningApp/AppConfig';
import { setAmplitudeUserProperties } from '../plugins/amplitude';
import { omitTypename } from '../util/productUtils';

function useSetEntitlementsToAmplitude(): () => void {
  const { data, error } = useGetUserAllEntitlementsQuery();
  const entitlements = data?.user?.entitlements;
  React.useEffect(() => {
    if (entitlements) {
      setAmplitudeUserProperties({
        entitlements: {
          modules: entitlements.enabledProductsModule?.map((item) => item.module),
          hazards: entitlements.hasHazardsForProduct?.map((item) => item.hazard),
          features: entitlements.hasFeaturesForProduct?.map((item) => omitTypename(item)),
        },
      });
    }
  }, [entitlements]);

  if (error) {
    App.error(`[useSetEntitlementsToAmplitude] GetUserAllEntitlements Error: ${error?.message}`);
  }
  return null;
}

export default useSetEntitlementsToAmplitude;
