import * as React from 'react';

import { makeStyles } from '@mui/styles';
import { ListItemProps } from '@mui/material/ListItem';
import { ListItemText, MenuItem, Theme } from '@mui/material';

import { BORDER_RADIUS } from '../../../util/productGlobals';

const useStyles = makeStyles((theme: Theme) => ({
  hoverHighlightedBox: {
    padding: theme.spacing(2),
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      borderRadius: BORDER_RADIUS,
    },
  },
  listItemTextRoot: {
    display: 'flex', // TODO - make sure this doesn't break other stuff
    justifyContent: 'space-between', // TODO - make sure this doesn't break other stuff
    marginTop: 0,
    marginBottom: 0,
    '&.MuiListItemText-multiline': {
      alignItems: 'center',
      marginTop: 0,
      marginBottom: 0,
    },
  },
  listItemTextPrimary: {
    margin: 'auto 0',
  },
  listItemTextSecondary: {
    margin: 'auto 0',
  },
}));

const useMenuItemStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:focus div.menuitem-hover-highlight': {
      backgroundColor: theme.palette.action.hover,
      borderRadius: BORDER_RADIUS,
    },
    '&.MuiMenuItem-root': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&.Mui-focusVisible': {
        backgroundColor: 'transparent',
      },
      borderRadius: 0,
      padding: 0,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      width: `calc(100% - ${theme.spacing(4)})`,
    },
  },
}));

type MenuItemWithHighlightProps = ListItemProps & {
  dataTestId: string;
  divider?: boolean;
  primary?: React.ReactNode;
  secondary?: React.ReactNode;
};

const MenuItemWithHighlight = React.forwardRef<HTMLLIElement, MenuItemWithHighlightProps>(
  (props, ref) => {
    const {
      dataTestId,
      primary: primaryText,
      secondary: secondaryText,
      divider = true,
      children,
      ...restProps
    } = props;

    const classes = useStyles();
    const menuItemClasses = useMenuItemStyles();
    return (
      <MenuItem
        data-test-id={dataTestId}
        disableGutters
        divider={divider}
        ref={ref}
        classes={menuItemClasses}
        // A MUI Typescript bug (https://github.com/mui-org/material-ui/issues/21801)
        // keeps the ListItemClasses prop from working, so we have to do all the crazy CSS
        // within the useMenuItemStyles function above instead.
        {...restProps}
      >
        <div
          className={`${classes.hoverHighlightedBox} menuitem-hover-highlight`}
          data-test-id={`${dataTestId}-hoverhighlight`}
        >
          <ListItemText
            primary={primaryText}
            secondary={secondaryText}
            disableTypography
            data-test-id={`${dataTestId}-text`}
            classes={{
              root: classes.listItemTextRoot,
              primary: classes.listItemTextPrimary,
              secondary: classes.listItemTextSecondary,
            }}
          >
            {children}
          </ListItemText>
        </div>
      </MenuItem>
    );
  },
);
MenuItemWithHighlight.displayName = 'MenuItemWithHighlight';
export default MenuItemWithHighlight;
