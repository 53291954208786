import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Typography } from '@mui/material';

import NoDataAvailableIcon from '../../../../../assets/images/NoVulnerableLocationFound.svg';

const MaterialityEmptyView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div
      className="o-flex-center-container u-flex-column u-paddingx--16 u-paddingy--32 u-marginbottom--100"
      data-test-id="materiality-empty-view"
    >
      <img
        src={NoDataAvailableIcon}
        alt={t('materiality:empty:noDataAvailable')}
        width={80}
        data-test-id="no-data-available-icon"
      />
      <div className="u-paddingtop--10 u-paddingbottom--24">
        <Typography variant="body1" color="textPrimary" textAlign="center">
          {t('materiality:empty:noDataAvailable')}
        </Typography>
        <Typography variant="body1" color="textPrimary" textAlign="center">
          {t('materiality:empty:getStartedAnalysis')}
        </Typography>
      </div>
      <Button
        variant="contained"
        color="primary"
        component={RouterLink}
        to="/materiality"
        data-test-id="get-started-analysis-link"
      >
        {t('materiality:empty:cta')}
      </Button>
    </div>
  );
};

MaterialityEmptyView.displayName = 'MaterialityEmptyView';
export default MaterialityEmptyView;
