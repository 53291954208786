import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import useUpdateMaterialitySettings from '../../../../../Hooks/useUpdateMaterialitySettings';
import { App } from '../../../../../PlanningApp/AppConfig';
import { GLASS_BG_DARK } from '../../../../../util/productGlobals';
import { useGetMaterialitySettingsQuery } from '../../../../../__generated__/graphql';
import PercentInput from '../../../../CommonComponents/InputControls/PercentInput/PercentInput';
import SkeletonInput from './SkeletonInput';

const useStyles = makeStyles((theme: Theme) => ({
  inputRoot: {
    width: 76,
    height: 36,
    margin: theme.spacing(0, 4),
    background: GLASS_BG_DARK,
  },
  inputInput: {
    fontSize: '14px',
    padding: theme.spacing(2, 0, 2, 3),
  },
  inputAdornedEnd: {
    fontSize: '14px',
  },
}));

const MaterialityThresholdSettings = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data: dataMaterialitySettings, loading, error } = useGetMaterialitySettingsQuery();
  const materialityThreshold =
    dataMaterialitySettings?.getMaterialitySettings?.materialityThreshold;
  const [threshold, setThreshold] = React.useState<number | undefined>();
  const updateMaterialitySettings = useUpdateMaterialitySettings();

  React.useEffect(() => {
    if (materialityThreshold !== undefined) setThreshold(materialityThreshold);
  }, [materialityThreshold]);

  const onChangeThreshold = React.useCallback(
    ({ value }: { value: number }) => {
      setThreshold(value);
      updateMaterialitySettings({
        variables: {
          input: {
            materialityThreshold: value,
          },
        },
      });
    },
    [setThreshold, updateMaterialitySettings],
  );

  if (error) {
    App.error(`[MaterialityThresholdSettings] - GetMaterialitySettings error: `, error);
    return null;
  }

  return (
    <div className="o-flex-vcenter-container" data-test-id="MaterialityThresholdSettings">
      <Typography variant="body1">{t('materiality:sections:settings:threshold')}</Typography>
      {loading ? (
        <div className="u-marginleft--16">
          <SkeletonInput id="MaterialityThreshold" />
        </div>
      ) : (
        <PercentInput
          id="MaterialityThreshold"
          valuePercent={threshold}
          minPercent={0}
          maxPercent={100}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
            inputAdornedEnd: classes.inputAdornedEnd,
          }}
          onChangePercent={onChangeThreshold}
        />
      )}
    </div>
  );
};

export default MaterialityThresholdSettings;
