import * as React from 'react';
import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { useTranslation } from 'react-i18next';

import {
  LIST_HIGHLIGHT,
  ORANGE,
  PURPLE,
  RECOVERY_CHART_X_AXIS_MIN_VALUE,
  WHITE,
} from '../../../util/productGlobals';
import { App } from '../../../PlanningApp/AppConfig';
import { getRecoveryChartXTicks } from '../../../util/productUtils';

type RecoveryCurveChartProps = {
  curve: number[][];
  lifeline?: string;
};

const RecoveryCurveChartFC: React.FC<RecoveryCurveChartProps> = ({ curve, lifeline }) => {
  const { t } = useTranslation();
  const xLabel: string = t('showYourWork:charts:recoveryXAxis');
  const yLabel: string = t('showYourWork:charts:recoveryYAxis');

  const lastCurvePoint = curve[curve.length - 1];
  if (curve[curve.length - 1][1] !== 100) {
    App.error(
      '[RecoveryCurveChart] - chart data might be wrong: ',
      `Lifeline "${lifeline}" is not at 100% functionality by recovery timeframe end. `,
      `Last data point is ${lastCurvePoint[0]} days, functionality: ${lastCurvePoint[1]}%`,
    );
  }

  const { data, minY, maxY, maxX, ticksX, ticksY } = React.useMemo(() => {
    const firstReachToEndIndex = curve.findIndex((point) => point[1] === 100);
    const dataCalc = curve
      .map(([days, functionality]) => ({ days, functionality }))
      .filter((_, index) => firstReachToEndIndex < 0 || index <= firstReachToEndIndex);

    /// Y-Axis
    const firstY = curve[0][1];
    const lastY = curve[curve.length - 1][1];

    let minYCalc;
    const ticksYCalc: number[] = [];

    if (firstY === lastY && firstY === 100) {
      minYCalc = 99;
      ticksYCalc.push(minYCalc, 99.5);
    } else {
      minYCalc = Math.max(0, firstY - (100 - firstY) / 4);
      ticksYCalc.push(minYCalc);
      for (let val = firstY; val < 100; val += (100 - minYCalc) / 5) {
        if (val > minYCalc) {
          ticksYCalc.push(val);
        }
      }
    }
    ticksYCalc.push(100);
    const maxYCalc = 100 + (100 - minYCalc) / 10;

    /// X-Axis
    const lastX = dataCalc[dataCalc.length - 1].days;
    const maxXCalc =
      lastX >= RECOVERY_CHART_X_AXIS_MIN_VALUE
        ? Math.min(lastX * 1.1, curve[curve.length - 1][0])
        : RECOVERY_CHART_X_AXIS_MIN_VALUE * 1.1;
    if (maxXCalc > lastX) {
      dataCalc.push({
        days: maxXCalc,
        functionality: dataCalc[dataCalc.length - 1].functionality,
      });
    }
    // HACK ALERT: adding this tiny amount to the y-value for the line's last point. It is a hack to
    // ensure the charted line is not a purely horizontal line.  Because if it is a perfectly
    // flat line, SVG's linearGradient treats it as if it were 0 height and then does not draw the
    // line at all.
    // see https://stackoverflow.com/questions/21638169/svg-line-with-gradient-stroke-wont-display-straight
    dataCalc[dataCalc.length - 1].functionality += 0.000001;

    const ticksXCalc = getRecoveryChartXTicks(Math.max(lastX, RECOVERY_CHART_X_AXIS_MIN_VALUE));
    return {
      data: dataCalc,
      minY: minYCalc,
      maxY: maxYCalc,
      maxX: maxXCalc,
      ticksX: ticksXCalc,
      ticksY: ticksYCalc,
    };
  }, [curve]);

  const firstDayAt100Pct = React.useMemo(() => {
    const match = data.find((pt) => {
      return pt?.functionality >= 100;
    });
    return match?.days ?? maxX;
  }, [data, maxX]);

  return (
    <div
      style={{ maxWidth: '640px', marginBottom: '16px' }}
      data-test-id={`RecoveryCurveChart-${lifeline}`}
    >
      <ResponsiveContainer width="100%" height={340}>
        <LineChart data={data} margin={{ top: 20, right: 30, bottom: 80, left: 100 }}>
          <defs>
            <linearGradient id={`${lifeline}-recovery-gradient`}>
              <stop offset={0} stopColor={PURPLE} />
              <stop offset={data[0].days / maxX} stopColor={PURPLE} />
              <stop offset={firstDayAt100Pct / maxX ?? 1} stopColor={ORANGE} />
              <stop offset={1} stopColor={ORANGE} />
            </linearGradient>
          </defs>
          <CartesianGrid stroke={LIST_HIGHLIGHT} strokeDasharray="10" vertical={false} />
          <XAxis
            data-test-id="xAxis"
            type="number"
            dataKey="days"
            domain={[0, maxX]}
            stroke={WHITE}
            tick={{ fill: WHITE }}
            ticks={ticksX}
            tickSize={10}
            tickMargin={-40}
            allowDecimals={false}
            mirror
          >
            <Label
              value={xLabel}
              fontWeight="bold"
              fill={WHITE}
              dy={75}
              data-test-id="xAxisTitle"
            />
          </XAxis>
          <YAxis
            data-test-id="yAxis"
            type="number"
            dataKey="functionality"
            domain={[minY, maxY]}
            stroke={WHITE}
            tick={{ fill: WHITE, textAnchor: 'end' }}
            tickLine={false}
            ticks={ticksY}
            tickMargin={-20}
            allowDecimals={false}
            mirror
          >
            <Label
              value={yLabel}
              fontWeight="bold"
              fill={WHITE}
              angle={-90}
              dx={-110}
              data-test-id="yAxisTitle"
            />
          </YAxis>

          <Line
            type="monotone"
            dataKey="functionality"
            dot={false}
            stroke={`url(#${lifeline}-recovery-gradient)`}
            strokeWidth={2}
            data-test-id="recovery-chart-line"
          />

          {/* These reference lines form the right and top border of the chart area */}
          <ReferenceLine y={maxY} isFront data-test-id="maxY-referenceLine" />
          <ReferenceLine x={maxX} isFront data-test-id="maxX-referenceLine" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

const RecoveryCurveChart = React.memo(RecoveryCurveChartFC);
RecoveryCurveChart.displayName = 'RecoveryCurveChart';
export default RecoveryCurveChart;
