import * as React from 'react';
import { useGetNavigatorInfoQuery } from '../../../../__generated__/graphql';
import HazardSelector from '../../AppNav/Primary/HazardSelector';
import FiltersBar from '../../Filters/FiltersBar';

type SYWFiltersProps = {
  userId?: string;
};

const SYWFilters: React.FC<SYWFiltersProps> = ({ userId }) => {
  const {
    data: {
      navigatorInfo: { currentView },
    },
  } = useGetNavigatorInfoQuery();

  return (
    <div className="u-flex u-radius--6 u-marginbottom--16">
      <HazardSelector />
      <FiltersBar currentNavigatorView={currentView} userId={userId} />
    </div>
  );
};

export default SYWFilters;
