import * as React from 'react';
import { Button, IconButton, TextField, Theme, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import Icon from '../../../../CommonComponents/Icon/Icon';
import StyledLinearProgress from '../../../../CommonComponents/LinearProgress/StyledLinearProgress';
import StyledSnackbarContent from '../../../../CommonComponents/Snackbar/StyledSnackbarContent';
import { useUpdatePasswordMutation } from '../../../../../__generated__/graphql';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: 'max-content',
    alignItems: 'flex-end',
  },
  label: {
    width: 124,
  },
  input: {
    width: 320,
  },
  textPassword: {
    letterSpacing: '0.15em',
  },
  iconBtn: {
    marginRight: theme.spacing(2),
    padding: 0,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
}));

const PasswordFormFC: React.FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { form, label, input, textPassword, iconBtn } = useStyles();

  const [isEditing, setIsEditing] = React.useState(false);
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmedPassword, setConfirmedPassword] = React.useState('');
  const [strength, setStrength] = React.useState(0);

  const isSubmitDisabled = React.useMemo(
    () => strength < 4 || !currentPassword || newPassword !== confirmedPassword,
    [strength, currentPassword, newPassword, confirmedPassword],
  );
  const isCurrentPasswordErr = React.useMemo(
    () => !currentPassword && !!(newPassword || confirmedPassword),
    [currentPassword, newPassword, confirmedPassword],
  );
  const isNewPasswordErr = React.useMemo(
    () => !!newPassword && strength < 4,
    [newPassword, strength],
  );
  const isConfirmedPasswordErr = React.useMemo(
    () => !!confirmedPassword && newPassword !== confirmedPassword,
    [confirmedPassword, newPassword],
  );

  const [updatePassword] = useUpdatePasswordMutation({
    onCompleted: () => {
      setIsEditing(false);
      setCurrentPassword('');
      setNewPassword('');
      setConfirmedPassword('');
      enqueueSnackbar(
        <StyledSnackbarContent
          title={t('settings:sections:profile:alerts:successChangePassword')}
        />,
        {
          variant: 'success',
          key: 'updatePassword',
          preventDuplicate: true,
        },
      );
    },
    onError: () => {
      setCurrentPassword('');
      enqueueSnackbar(
        <StyledSnackbarContent title={t('settings:sections:profile:alerts:invalidPassword')} />,
        {
          variant: 'error',
          key: 'invalidPassword',
          preventDuplicate: true,
        },
      );
    },
  });
  const handleSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      updatePassword({ variables: { currentPassword, newPassword } });
    },
    [updatePassword, currentPassword, newPassword],
  );

  const handleClickEdit = React.useCallback(() => {
    setIsEditing(true);
  }, [setIsEditing]);
  const handleKeyDownEdit = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter' || e.key === 'Space') {
        setIsEditing(true);
      }
    },
    [setIsEditing],
  );
  const cancelEdit = React.useCallback(() => {
    setIsEditing(false);
    setCurrentPassword('');
    setNewPassword('');
    setConfirmedPassword('');
  }, [setIsEditing]);

  const handleCurrentPasswordChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentPassword(e.target.value);
    },
    [setCurrentPassword],
  );
  const handleNewPasswordChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewPassword(e.target.value);
    },
    [setNewPassword],
  );
  const handleConfirmedPasswordChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setConfirmedPassword(e.target.value);
    },
    [setConfirmedPassword],
  );

  const updateStrength = React.useCallback(
    (password: string) => {
      import('zxcvbn').then((zxcvbn) => {
        const newStrength = (zxcvbn.default || (zxcvbn as any))(password).score + 1;
        setStrength(newStrength);
      });
    },
    [setStrength],
  );
  const debouncedUpdateStrength = React.useMemo(
    () => debounce(updateStrength, 300),
    [updateStrength],
  );
  React.useEffect(() => {
    if (newPassword) {
      debouncedUpdateStrength(newPassword);
    } else if (strength > 0) {
      setStrength(0);
    }
  }, [debouncedUpdateStrength, newPassword, strength]);
  React.useEffect(() => () => debouncedUpdateStrength.cancel(), [debouncedUpdateStrength]);

  return isEditing ? (
    <form className={form} onSubmit={handleSubmit} data-test-id="PasswordForm">
      <div className="o-flex-vcenter-container u-marginbottom--12">
        <Typography variant="caption" className={label}>
          {t('settings:sections:profile:personalInfo:currentPassword')}
        </Typography>
        <TextField
          type="password"
          variant="outlined"
          placeholder={t('settings:sections:profile:placeholders:currentPassword')}
          value={currentPassword}
          onChange={handleCurrentPasswordChange}
          className={input}
          inputProps={{ 'data-test-id': 'PasswordForm-CurrentPassword' }}
          error={isCurrentPasswordErr}
        />
      </div>
      <div className="o-flex-vcenter-container u-marginbottom--12">
        <Typography variant="caption" className={label}>
          {t('settings:sections:profile:personalInfo:newPassword')}
        </Typography>
        <TextField
          type="password"
          variant="outlined"
          placeholder={t('settings:sections:profile:placeholders:newPassword')}
          value={newPassword}
          onChange={handleNewPasswordChange}
          className={input}
          inputProps={{ 'data-test-id': 'PasswordForm-NewPassword' }}
          error={isNewPasswordErr}
        />
      </div>
      <div className="o-flex-vcenter-container u-marginbottom--20">
        <TextField
          type="password"
          variant="outlined"
          placeholder={t('settings:sections:profile:placeholders:confirmPassword')}
          value={confirmedPassword}
          onChange={handleConfirmedPasswordChange}
          className={input}
          inputProps={{ 'data-test-id': 'PasswordForm-ConfirmedPassword' }}
          error={isConfirmedPasswordErr}
        />
      </div>
      <div className={`o-flexcol-container u-marginbottom--16 ${input}`}>
        <Typography variant="caption" className="u-marginbottom--8">
          {t('settings:sections:profile:personalInfo:passwordStrength')}
        </Typography>
        <StyledLinearProgress
          variant="determinate"
          value={strength * 20}
          data-test-id="PasswordForm-StrengthIndicator"
        />
      </div>
      <div>
        <Button
          name="cancelPassword"
          variant="outlined"
          color="primary"
          onClick={cancelEdit}
          className="u-marginright--16"
          data-test-id="PasswordForm-CancelButton"
        >
          {t('buttons:cancel')}
        </Button>
        <Button
          name="savePassword"
          variant="contained"
          color="primary"
          type="submit"
          disabled={isSubmitDisabled}
          data-test-id="PasswordForm-SaveButton"
        >
          {t('settings:sections:profile:buttons:saveChanges')}
        </Button>
      </div>
    </form>
  ) : (
    <div className="o-flex-vcenter-container" data-test-id="PasswordForm-Display">
      <Typography variant="caption" className={label}>
        {t('settings:sections:profile:personalInfo:password')}
      </Typography>
      <Typography variant="body2" className={textPassword} data-test-id="obscuredPasswordText">
        ••••••••
      </Typography>

      <div
        className="o-flex-vcenter-container u-clickable u-marginleft--32"
        onClick={handleClickEdit}
        onKeyDown={handleKeyDownEdit}
        role="button"
        tabIndex={0}
        data-test-id="PasswordForm-EditButton"
      >
        <IconButton size="small" className={iconBtn}>
          <Icon name="Edit" />
        </IconButton>
        <Typography variant="caption">
          {t('settings:sections:profile:buttons:updatePassword')}
        </Typography>
      </div>
    </div>
  );
};

const PasswordForm = React.memo(PasswordFormFC);
PasswordForm.displayName = 'PasswordForm';
export default PasswordForm;
