import * as React from 'react';
import { Map as MapboxMap } from 'mapbox-gl';
import { makeStyles } from '@mui/styles';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Button } from '@mui/material';
import {
  NavigatorViewType,
  useAddRegionMutation,
  useGetNavigatorInfoQuery,
  useGetSimulationFormQuery,
  useUpdateNavigatorInfoMutation,
} from '../../../__generated__/graphql';
import { Lifeline, SimulationType } from '../../../util/productEnums';
import { App } from '../../../PlanningApp/AppConfig';
import {
  SIMULATION_DEFAULT_DOWNTIMES,
  SIMULATION_DEFAULT_OCCUPANCY_TYPES,
} from '../../../util/productGlobals';

const useStyles = makeStyles(() => ({
  root: {
    background: '#131D22',
    backdropFilter: 'blur(13px)',
    '&:hover': {
      background: '#131D22',
      backdropFilter: 'blur(13px)',
    },
  },
}));

type MapDrawButtonProps = {
  mapboxMap: MapboxMap;
};

const Draw = new MapboxDraw({
  controls: {
    point: false,
    line_string: false,
    polygon: true,
    trash: true,
    combine_features: false,
    uncombine_features: false,
  },
});

const geojsonFromDrawing = () => {
  return Draw.getAll();
};

const MapDrawButton: React.FC<MapDrawButtonProps> = ({ mapboxMap }) => {
  const classes = useStyles();
  const [isDrawing, setIsDrawing] = React.useState<boolean>(false);
  const { data: simulationData } = useGetSimulationFormQuery();
  const [addRegionMutation] = useAddRegionMutation();
  const {
    data: {
      navigatorInfo: { currentView },
    },
  } = useGetNavigatorInfoQuery();
  const [updateNavigatorInfoMutation] = useUpdateNavigatorInfoMutation();

  const simulationType = simulationData.simulationForm?.type as SimulationType;

  React.useEffect(() => {
    if (mapboxMap && currentView === NavigatorViewType.SimulationFormDraw) {
      if (isDrawing && !mapboxMap.hasControl(Draw)) {
        // if user has started to draw, add controls to draw
        mapboxMap.addControl(Draw, 'top-left');
        mapboxMap.on('draw.create', geojsonFromDrawing);
      }
      if (!isDrawing && mapboxMap.hasControl(Draw)) {
        // if user has ended drawing

        const geojson = geojsonFromDrawing();
        if (geojson.features.length > 0) {
          App.debug(`Adding geojson region to apollo ${geojson}`);
          addRegionMutation({
            variables: {
              region: JSON.stringify(geojson),
              occupancyTypes: SIMULATION_DEFAULT_OCCUPANCY_TYPES[simulationType],
              downtimes: SIMULATION_DEFAULT_DOWNTIMES[simulationType],
            },
          });
        } else {
          App.debug("User hasn't drawn any region");
        }
        updateNavigatorInfoMutation({
          variables: {
            currentView: NavigatorViewType.MapView,
            currentLifeline: Lifeline.EMPTY,
          },
        });
        mapboxMap.removeControl(Draw);
        mapboxMap.off('draw.create', geojsonFromDrawing);
      }
    }
  }, [
    isDrawing,
    mapboxMap,
    addRegionMutation,
    updateNavigatorInfoMutation,
    currentView,
    simulationType,
  ]);

  const onClickMapDrawButton = React.useCallback(() => {
    setIsDrawing(!isDrawing);
  }, [isDrawing]);

  if (currentView === NavigatorViewType.SimulationFormDraw) {
    return (
      <div
        style={{
          position: 'absolute',
          top: 150,
          left: 'calc(50% - 160px)',
          width: 100,
          height: 100,
        }}
      >
        <Button
          onClick={onClickMapDrawButton}
          data-test-id="MapDrawButton"
          className={classes.root}
          aria-label="mapbounds"
        >
          {isDrawing ? 'End Drawing' : 'Start Drawing'}
        </Button>
      </div>
    );
  }
  return null;
};

MapDrawButton.displayName = 'MapDrawButton';
export default MapDrawButton;
