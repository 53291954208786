import * as React from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  GetThresholdsDocument,
  ResilienceThresholds,
  useGetThresholdsQuery,
  useUpdateThresholdsMutation,
} from '../../../../__generated__/graphql';
import { AMP_EVENT_CHANGE_THRESHOLDS } from '../../../../plugins/amplitudeevents';
import { App } from '../../../../PlanningApp/AppConfig';
import { defaultThresholds } from '../../../../util/productGlobals';
import { sendAmplitudeData } from '../../../../plugins/amplitude';
import ThresholdsPicker from './ThresholdsPicker';
import useApiErrSnackbar from '../../../CommonComponents/Snackbar/useApiErrSnackbar';

const legalifyThresholds: (
  possiblyIllegalThresholds: ResilienceThresholds,
) => ResilienceThresholds = (possiblyIllegalThresholds) => {
  const {
    structuralDamage,
    powerDowntime,
    peopleDowntime,
    portDowntime,
    bridgeDowntime,
    highwayDowntime,
    airportDowntime,
  } = possiblyIllegalThresholds;
  // The server is not supposed to return '0' as the value for any threshold, but if it does,
  // we correct it to be '1'.
  const legalThresholds: ResilienceThresholds = {
    structuralDamage: structuralDamage || 1,
    powerDowntime: powerDowntime || 1,
    peopleDowntime: peopleDowntime || 1,
    portDowntime: portDowntime || 1,
    airportDowntime: airportDowntime || 1,
    bridgeDowntime: bridgeDowntime || 1,
    highwayDowntime: highwayDowntime || 1,
  };
  return legalThresholds;
};

type ThresholdsPickerContainerProps = {
  userId: string;
};

const ThresholdsPickerContainer: React.FC<ThresholdsPickerContainerProps> = ({ userId }) => {
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();
  const { t } = useTranslation();

  const [preSubmitThresholds, setPreSubmitThresholds] =
    React.useState<ResilienceThresholds>(defaultThresholds);

  const {
    data: userData,
    loading: thresholdsLoading,
    error: thresholdsError,
  } = useGetThresholdsQuery();

  React.useEffect(() => {
    if (thresholdsError) {
      enqueueApiErrSnackbar();
      App.error('[ThresholdsPickerContainer] - getThresholdsQuery failed');
    }
  }, [enqueueApiErrSnackbar, thresholdsError]);

  const serverThresholds: ResilienceThresholds = userData?.user?.productSettings?.thresholds;

  const legalServerThresholds = React.useMemo(() => {
    if (serverThresholds && !isEmpty(serverThresholds)) {
      return legalifyThresholds(serverThresholds);
    }
    return null;
  }, [serverThresholds]);

  React.useEffect(() => {
    if (legalServerThresholds) {
      setPreSubmitThresholds(legalServerThresholds);
    }
  }, [legalServerThresholds]);

  const onCancelThresholdsChange = React.useCallback(() => {
    if (legalServerThresholds) {
      setPreSubmitThresholds(legalServerThresholds);
    }
  }, [legalServerThresholds]);

  const [submitNewThresholdsMutation, { error: submitError, loading: submitLoading }] =
    useUpdateThresholdsMutation();

  React.useEffect(() => {
    if (submitError) {
      App.error(
        '[ThresholdsPickerContainer] - Could not change thresholds.  OnSubmitThresholds mutation error: ',
        submitError,
      );
      enqueueApiErrSnackbar();
      // continue with the old thresholds because we were unable to use the new ones.
    }
  }, [submitError, enqueueApiErrSnackbar, t]);

  const onSubmitThresholds = React.useCallback(() => {
    App.debug('[ThresholdsPickerContainer] - submit thresholds to server: ', preSubmitThresholds);
    submitNewThresholdsMutation({
      variables: { thresholds: preSubmitThresholds },
      refetchQueries: [{ query: GetThresholdsDocument }],
    });
    if (userId) {
      sendAmplitudeData(AMP_EVENT_CHANGE_THRESHOLDS, {
        userId,
        previousThresholds: legalServerThresholds,
        newThresholds: preSubmitThresholds,
      });
    }
  }, [legalServerThresholds, preSubmitThresholds, submitNewThresholdsMutation, userId]);

  const onRestoreToDefaults = React.useCallback(() => {
    App.debug('ThresholdsPickerContainer] - onResetToDefaults: ');
    setPreSubmitThresholds(defaultThresholds);
  }, []);

  const onChangePreSubmitThreshold = React.useCallback(
    (threshold: Partial<ResilienceThresholds>) => {
      const newThresholds = { ...preSubmitThresholds, ...threshold };
      setPreSubmitThresholds(newThresholds);
    },
    [preSubmitThresholds],
  );

  if (!legalServerThresholds) {
    return null;
  }

  return (
    <ThresholdsPicker
      serverThresholds={legalServerThresholds}
      preSubmitThresholds={preSubmitThresholds}
      onChangeThreshold={onChangePreSubmitThreshold}
      onSubmitThresholds={onSubmitThresholds}
      onCancelThresholdsChange={onCancelThresholdsChange}
      onRestoreToDefaults={onRestoreToDefaults}
      submitting={submitLoading || thresholdsLoading}
    />
  );
};
ThresholdsPickerContainer.displayName = 'ThresholdsPickerContainer';
export default ThresholdsPickerContainer;
