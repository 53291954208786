import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { CardActions, CardHeader, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { SIDE_PANEL_DOWNTIME_MATERIALITY_VIEW } from '../../../../util/productGlobals';
import CardExpandButton from '../Common/CardExpandButton';
import SkeletonMaterialityView from './SkeletonMaterialityView';
import MaterialityView from './MaterialityView';
import useGetMaterialityData from '../../../../Hooks/useGetMaterialityData';

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    margin: theme.spacing(2),
  },
  headerText: {
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: '1.375rem',
  },
}));

type MaterialityViewContainerProps = {
  expanded?: boolean;
  onClickExpanded?: (card: string) => void;
};

const MaterialityViewContainer: React.FC<MaterialityViewContainerProps> = ({
  expanded,
  onClickExpanded,
}) => {
  const { t } = useTranslation();
  const { headerContainer, headerText, title } = useStyles();

  const { values, lifelineLosses, loading } = useGetMaterialityData();

  const handleChangeExpanded = React.useCallback(() => {
    if (!onClickExpanded) return;
    if (expanded) {
      onClickExpanded('');
    } else {
      onClickExpanded(SIDE_PANEL_DOWNTIME_MATERIALITY_VIEW);
    }
  }, [expanded, onClickExpanded]);

  return (
    <div className="u-paddingy--8" data-test-id="materiality-view-container">
      <CardActions className="o-flexsb-container u-no-padding" disableSpacing>
        <CardExpandButton expanded={expanded} onClick={handleChangeExpanded} />
      </CardActions>
      <CardHeader
        className={`${headerContainer} u-no-padding`}
        title={
          <div className="o-flexsb-container">
            <div className={headerText}>
              <Typography variant="h2" className={title}>
                {t('nav:materiality')}
              </Typography>
            </div>
          </div>
        }
      />
      {loading ? (
        <SkeletonMaterialityView expanded={expanded} />
      ) : (
        <MaterialityView values={values} losses={lifelineLosses} expanded={expanded} />
      )}
    </div>
  );
};

MaterialityViewContainer.displayName = 'MaterialityViewContainer';
export default MaterialityViewContainer;
