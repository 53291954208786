import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Map } from 'mapbox-gl';
import { OnecWindow } from 'onec-types';
import { CardActions, Grid, Typography } from '@mui/material';

import {
  useGetNotFoundLocationQuery,
  useSetNotFoundLocationMutation,
} from '../../../../__generated__/graphql';
import useHasFinanceEntitlement from '../../../../Hooks/useHasFinanceEntitlement';
import { FINANCE_HOME_PATH, HOME_PATH } from '../../../../util/productGlobals';
import DetailCardBackButton from '../DetailView/DetailCardBackButton';
import NoDataChip from './NoDataChip';
import { flyToDetail } from '../../Map/Cluster/MapFlyTo';
import NoDataIcon from '../../../../../assets/images/NoData.svg';

const NoLocationFound: React.FC = () => {
  const { t } = useTranslation();
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;
  const navigate = useNavigate();
  const {
    data: { notFoundLocation },
  } = useGetNotFoundLocationQuery();
  const [setNotFoundLocationMutation] = useSetNotFoundLocationMutation();
  const goBack = React.useCallback(() => {
    setNotFoundLocationMutation({
      variables: { input: { address: '' } },
    });
    navigate(isFinanceEntitled ? FINANCE_HOME_PATH : HOME_PATH);
  }, [isFinanceEntitled, navigate, setNotFoundLocationMutation]);

  React.useEffect(() => {
    if (notFoundLocation) {
      if (notFoundLocation.address) {
        const map: Map = (window as OnecWindow).reactMap;
        flyToDetail(map, [notFoundLocation?.lng, notFoundLocation?.lat]);
      }
    }
  }, [notFoundLocation]);

  return (
    <>
      <CardActions className="o-flexfe-container u-no-padding u-paddingbottom--8" disableSpacing>
        <Grid container direction="column">
          <Grid>
            <DetailCardBackButton clickFunc={goBack} />
          </Grid>
        </Grid>
        <NoDataChip />
      </CardActions>
      <div
        className="o-flex-center-container u-flex-column u-paddingy--120 u-marginbottom--32 u-paddingx--64"
        data-test-id="NoLocationFound"
      >
        <img
          src={NoDataIcon}
          alt={t('detailView:noData')}
          width={42}
          data-test-id="noLocationFoundIcon"
        />
        <Typography
          variant="body1"
          className="u-paddingtop--16 u-paddingbottom--8"
          color="textPrimary"
        >
          {t('detailView:noDataFoundForThisLocation')}
        </Typography>
        <Typography
          variant="caption"
          color="textPrimary"
          className="u-marginbottom--16 u-aligncenter"
        >
          {notFoundLocation?.address}
        </Typography>
        <Typography variant="caption" color="textPrimary" data-test-id="noDataFoundDescription">
          {t('detailView:noDataFoundForThisLocationDescription')}
        </Typography>
      </div>
    </>
  );
};

NoLocationFound.displayName = 'NoLocationFound';
export default NoLocationFound;
