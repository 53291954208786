import { Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
// import SwipeableViews from 'react-swipeable-views';
// import { SWIPE_ANIMATE_STYLE } from '../../../../util/productGlobals';
// import Tabs from '../../../CommonComponents/Tabs/Tabs';
// import TabPanel from '../../../CommonComponents/TabPanel/TabPanel';
// import FinanceLossTable from './FinanceLossTable';
import FinanceRevenueTable from './FinanceRevenueTable';

const FinanceDriversSettings = () => {
  const { t } = useTranslation();
  // const [currentTabIndex, setCurrentTabIndex] = React.useState(0);

  // const handleChangeIndex = React.useCallback((index: number) => {
  //   setCurrentTabIndex(index);
  // }, []);

  return (
    <div data-test-id="FinanceDriversSettings">
      <div className="o-flex-vcenter-container u-marginbottom--32">
        <Typography variant="h3">{t('materiality:revenueTable:title')}</Typography>
      </div>
      <FinanceRevenueTable />
      {/* <Tabs
        tabList={[
          t('datetime:hours', {
            hr: 12,
            count: 12,
          }),
          t('datetime:days', {
            days: 1,
            count: 1,
          }),
          t('datetime:days', {
            days: 7,
            count: 7,
          }),
          t('datetime:months', {
            months: 1,
            count: 1,
          }),
          t('datetime:months', {
            months: 6,
            count: 6,
          }),
          t('financeView:settings:drivers:revenue:title'),
        ]}
        spacing={32}
        currentTabIndex={currentTabIndex}
        setCurrentTabIndex={handleChangeIndex}
      />
      <SwipeableViews
        index={currentTabIndex}
        onChangeIndex={handleChangeIndex}
        containerStyle={SWIPE_ANIMATE_STYLE}
      >
        {[0.5, 1, 7, 30, 180].map((downtime, index) => (
          <TabPanel
            key={`TabPanel-FinanceDrivers-${downtime}`}
            value={currentTabIndex}
            index={index}
            data-test-id={`TabPanel-${index}`}
          >
            <FinanceLossTable downtime={downtime} />
          </TabPanel>
        ))}
        <TabPanel
          value={currentTabIndex}
          index={5}
          data-test-id="TabPanel-FinanceDrivers-AnnualRevenue"
        >
          <FinanceRevenueTable />
        </TabPanel>
      </SwipeableViews> */}
    </div>
  );
};

export default FinanceDriversSettings;
