import * as React from 'react';
import {
  CardContent,
  CardHeader,
  List,
  ListItem,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import Tabs from '../../../../CommonComponents/Tabs/Tabs';
import { OVERFLOW_Y } from '../../../../../util/productGlobals';
import useLifelineEntitlements from '../../../../../Hooks/useLifelineEntitlements';
import { useLifelinesDataTableStyles } from '../../Common/LifelinesDataTable';
import useHasFinanceEntitlement from '../../../../../Hooks/useHasFinanceEntitlement';

const useStyles = makeStyles((theme: Theme) => ({
  skeletonCardHeaderRoot: {
    padding: theme.spacing(0, 2, 8, 2),
  },
  skeletonLocName: {
    marginBottom: 4,
    maxWidth: 368,
  },
  skeletonAddressLine: {
    maxWidth: 200,
  },
  skeletonChipLine: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'row',
  },
  chips: {
    marginTop: 0,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    borderRadius: 100,
  },
  skeletonTableCell: {
    marginLeft: 32,
  },
}));

export const LifelineFirstToFailTableSkeleton: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const tableClasses = useLifelinesDataTableStyles();

  const entitlements = useLifelineEntitlements();

  let numRows = 0;

  if (entitlements) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(entitlements).forEach(([_lifeline, isEntitled]) => {
      if (isEntitled) {
        numRows += 1;
      }
    });
  }

  return (
    <TableContainer
      component={Paper}
      className={tableClasses.root}
      data-test-id="LifelineFirstToFailTableSkeleton"
    >
      <Table className={tableClasses.table}>
        <TableHead>
          <TableRow>
            {[t('lifeline:title'), t('detailView:lifelines:vulnerableAtReturnPeriod')].map(
              (header) => (
                <TableCell key={header} className={tableClasses.headerCell}>
                  {header}
                </TableCell>
              ),
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array(numRows)
            .fill(undefined)
            .map((rowNum, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={idx}>
                <TableCell className={tableClasses.bodyCell}>
                  <Skeleton
                    variant="rectangular"
                    height={28}
                    width={80}
                    classes={{ root: classes.chips }}
                  />
                </TableCell>
                <TableCell className={tableClasses.bodyCell}>
                  <Skeleton className={classes.skeletonTableCell} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
LifelineFirstToFailTableSkeleton.displayName = 'LifelineFirstToFailTableSkeleton';

export const LifelinesTopConcernsTableSkeleton: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const entitlements = useLifelineEntitlements();

  let numDownTimeRows = 0;
  let numStructureRows = 0;
  if (entitlements) {
    Object.entries(entitlements).forEach(([lifeline, isEntitled]) => {
      if (isEntitled) {
        if (lifeline === 'structure') {
          numStructureRows += 1;
        } else {
          numDownTimeRows += 1;
        }
      }
    });
  }
  const tableClasses = useLifelinesDataTableStyles();
  return (
    <TableContainer
      component={Paper}
      className={tableClasses.root}
      data-test-id="LifelinesTopConcernsTableSkeleton"
    >
      <Table className={tableClasses.table}>
        <TableHead>
          <TableRow>
            {[
              t('lifeline:title'),
              t('detailView:lifelines:downtime'),

              t('detailView:lifelines:threshold'),
            ].map((header) => (
              <TableCell key={header} className={tableClasses.headerCell}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array(numDownTimeRows)
            .fill(undefined)
            .map((rowNum, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={idx}>
                <TableCell className={tableClasses.bodyCell}>
                  <Skeleton
                    variant="rectangular"
                    height={28}
                    width={80}
                    classes={{ root: classes.chips }}
                  />
                </TableCell>
                <TableCell className={tableClasses.bodyCell}>
                  <Skeleton className={classes.skeletonTableCell} />
                </TableCell>
                <TableCell className={tableClasses.bodyCell}>
                  <Skeleton className={classes.skeletonTableCell} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
        {numStructureRows > 0 && (
          <>
            <TableHead>
              <TableRow>
                {['', t('damage'), t('detailView:lifelines:threshold')].map((header) => (
                  <TableCell key={header} className={tableClasses.headerCell}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={tableClasses.bodyCell}>
                  <Skeleton
                    variant="rectangular"
                    height={28}
                    width={80}
                    classes={{ root: classes.chips }}
                  />
                </TableCell>
                <TableCell className={tableClasses.bodyCell}>
                  <Skeleton className={classes.skeletonTableCell} />
                </TableCell>
                <TableCell className={tableClasses.bodyCell}>
                  <Skeleton className={classes.skeletonTableCell} />
                </TableCell>
              </TableRow>
            </TableBody>
          </>
        )}
      </Table>
    </TableContainer>
  );
};
LifelinesTopConcernsTableSkeleton.displayName = 'LifelinesTopConcernsTableSkeleton';

const LifelinesSkeleton: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="o-flexcol-container" data-test-id="LifelinesSkeleton">
      <Typography color="textPrimary" variant="h4">
        {t('detailView:atRisk')}
      </Typography>
      <CardContent className="u-radius--6 u-no-padding u-margintop--16">
        <Tabs
          tabList={[
            t('detailView:lifelines:topConcerns:title'),
            t('detailView:lifelines:firstToFail:title'),
          ]}
          currentTabIndex={0}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          setCurrentTabIndex={() => {}}
        />
        <Typography variant="body2" className="u-paddingbottom--16" color="textPrimary">
          <Skeleton />
          <Skeleton />
        </Typography>
        <LifelinesTopConcernsTableSkeleton />
      </CardContent>
    </div>
  );
};
LifelinesSkeleton.displayName = 'LifelinesSkeleton';

const LocationDetailCardSkeleton: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;

  return (
    <div style={{ marginTop: 40 }} data-test-id="LocationDetailCardSkeleton">
      <CardHeader
        classes={{ root: classes.skeletonCardHeaderRoot }}
        title={
          <Typography variant="h2" data-test-id="skeleton-title">
            <Skeleton className={classes.skeletonLocName} />
          </Typography>
        }
        subheader={
          <div>
            <Typography color="textPrimary" variant="body1" data-test-id="skeleton-address-1">
              <Skeleton className={classes.skeletonAddressLine} />
            </Typography>
            <Typography color="textPrimary" variant="body1" data-test-id="skeleton-address-2">
              <Skeleton className={classes.skeletonAddressLine} />
            </Typography>
            <div className={classes.skeletonChipLine}>
              <Skeleton
                variant="rectangular"
                height={26}
                width={80}
                classes={{ root: classes.chips }}
              />
              <Skeleton
                variant="rectangular"
                height={26}
                width={100}
                classes={{ root: classes.chips }}
              />
            </div>
          </div>
        }
      />
      <CardContent
        className={`${OVERFLOW_Y} u-no-padding u-paddingx--8`}
        style={{ height: '100%' }}
      >
        <List className="u-no-padding">
          <ListItem className="u-no-padding">
            {!isFinanceEntitled ? (
              <LifelinesSkeleton />
            ) : (
              <div className="o-flexcol-container" data-test-id="LifelinesSkeleton">
                <Typography color="textPrimary" variant="h4" mb={2} fontSize="1rem">
                  {t('detailView:biDowntime:title')}
                </Typography>
                <CardContent className="u-radius--6 u-no-padding u-margintop--16">
                  <Typography variant="body2" className="u-paddingbottom--16" color="textPrimary">
                    <Skeleton />
                    <Skeleton />
                  </Typography>
                </CardContent>
              </div>
            )}
          </ListItem>
        </List>
      </CardContent>
    </div>
  );
};
LocationDetailCardSkeleton.displayName = 'LocationDetailCardSkeleton';
export default LocationDetailCardSkeleton;
