import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { Button, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  GRAY3,
  UPLOAD_FILE_LIMIT,
  UPLOAD_LOCATIONS_LIMIT,
} from '../../../../../util/productGlobals';
import LocationsUploadIcon from '../../../../../../assets/images/LocationsUpload.svg';
import StyledSnackbarContent from '../../../../CommonComponents/Snackbar/StyledSnackbarContent';
import { useSetLocationUploadFileMutation } from '../../../../../__generated__/graphql';
import WarningDialog from '../../../../CommonComponents/ConfirmDialog/ConfirmDialog';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flex: 1,
  },
  noFileDesc: {
    color: GRAY3,
    marginBottom: theme.spacing(3),
  },
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '42px 32px 32px 32px',
    borderRadius: '6px',
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%238D9493FF' stroke-width='1' stroke-dasharray='4%2c5' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`,
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  body: {
    fontWeight: 400,
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  backDrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
}));

const LocationsCsvUpload: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openRowWarningDialog, setOpenRowWarningDialog] = React.useState(false);
  const [openSizeWarningDialog, setOpenSizeWarningDialog] = React.useState(false);
  const classes = useStyles();
  const [setLocationUploadFile] = useSetLocationUploadFileMutation();
  const { acceptedFiles, getRootProps, getInputProps, fileRejections, open } = useDropzone({
    accept: '.csv',
    multiple: false,
    noClick: true,
    noKeyboard: true,
  });

  const handleOpenRowWarningDialog = React.useCallback(() => {
    setOpenRowWarningDialog(true);
  }, [setOpenRowWarningDialog]);

  const handleCloseRowWarningDialog = React.useCallback(() => {
    setOpenRowWarningDialog(false);
  }, [setOpenRowWarningDialog]);

  const handleOpenSizeWarningDialog = React.useCallback(() => {
    setOpenSizeWarningDialog(true);
  }, [setOpenSizeWarningDialog]);

  const handleCloseSizeWarningDialog = React.useCallback(() => {
    setOpenSizeWarningDialog(false);
  }, [setOpenSizeWarningDialog]);

  const uploadFile = React.useCallback(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setLocationUploadFile({
        variables: {
          file: acceptedFiles[0],
        },
      });
      setTimeout(() => navigate('/'), 300);
    }
  }, [acceptedFiles, navigate, setLocationUploadFile]);

  React.useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const acceptFile = acceptedFiles[0];
      if (acceptFile.size > UPLOAD_FILE_LIMIT) {
        handleOpenSizeWarningDialog();
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result.toString() ?? '';
        const rows: string[] = text?.split('\n').filter((row) => !!row);
        /// Ignore header row while counting location rows
        const locationsCount = Math.max(rows.length - 1, 0);
        if (locationsCount > UPLOAD_LOCATIONS_LIMIT) {
          handleOpenRowWarningDialog();
          return;
        }
        uploadFile();
      };
      reader.readAsText(acceptFile);
    }
  }, [acceptedFiles, handleOpenSizeWarningDialog, handleOpenRowWarningDialog, uploadFile]);

  React.useEffect(() => {
    if (fileRejections && fileRejections.length > 0) {
      const rejectFile = fileRejections[0];
      if (rejectFile.errors[0]?.code === 'file-invalid-type')
        enqueueSnackbar(
          <StyledSnackbarContent title={t('manageLocations:warnings:invalidCsvFormat')} />,
          {
            variant: 'error',
          },
        );
    }
  }, [enqueueSnackbar, fileRejections, t]);

  return (
    <>
      <div data-test-id="Locations-CSV-Upload" className={`${classes.container} u-flex-column`}>
        <Typography variant="body1" className={classes.noFileDesc}>
          {t('manageLocations:noFileUploaded')}
        </Typography>
        <div
          {...getRootProps({ className: classes.dropzone })}
          data-test-id="Location-Upload-Dropzone"
        >
          <input {...getInputProps()} />
          <img
            src={LocationsUploadIcon}
            alt={t('manageLocations:uploadYourLocations')}
            data-test-id="Locations-Upload-Icon"
          />
          <Typography variant="body1" className="u-marginy--16 u-aligncenter">
            {t('manageLocations:uploadYourLocations')}
          </Typography>
          <Typography variant="caption" className={classes.body}>
            {t('manageLocations:dragAndDropCsvHere')}
          </Typography>
          <Typography variant="caption" className={classes.body}>
            {t('manageLocations:or')}
          </Typography>
          <Button variant="contained" color="primary" data-test-id="Browse-Files" onClick={open}>
            {t('manageLocations:buttons:browseFiles')}
          </Button>
        </div>
      </div>
      <WarningDialog
        open={openRowWarningDialog}
        onClose={handleCloseRowWarningDialog}
        actions={[
          {
            variant: 'outlined',
            caption: t('manageLocations:buttons:goBack'),
            callback: handleCloseRowWarningDialog,
          },
        ]}
      >
        {t('manageLocations:warnings:exceedRowLimit', {
          limit: UPLOAD_LOCATIONS_LIMIT,
        })}
      </WarningDialog>
      <WarningDialog
        open={openSizeWarningDialog}
        onClose={handleCloseSizeWarningDialog}
        actions={[
          {
            variant: 'outlined',
            caption: t('manageLocations:buttons:goBack'),
            callback: handleCloseSizeWarningDialog,
          },
        ]}
      >
        {t('manageLocations:warnings:exceedSizeLimit')}
      </WarningDialog>
    </>
  );
};

LocationsCsvUpload.displayName = 'LocationsCsvUpload';
export default LocationsCsvUpload;
