import * as React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { Card, Grow, Modal, Theme } from '@mui/material';
import { GLASS_BG } from '../../../../util/productGlobals';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    maxHeight: `calc(100vh - ${theme.spacing(4)})`,
    margin: theme.spacing(2),
    width: '100vw',
    height: '100vh',
    outline: 0,
    padding: 0,
    backgroundColor: GLASS_BG,
  },
}));

const MaterialityModal: React.FC = () => {
  const { card } = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(true);

  const goBackToPrevious = React.useCallback(() => {
    setIsOpen(false);
    setTimeout(() => {
      navigate('/');
    }, 300);
    document.title = t('windowTitle');
  }, [navigate, t]);

  return (
    <Modal className="o-flex-center-container" onClose={goBackToPrevious} open closeAfterTransition>
      <Grow in={isOpen}>
        <Card className={card} data-test-id="MaterialityModal-card">
          <Outlet context={{ handleClose: goBackToPrevious }} />
        </Card>
      </Grow>
    </Modal>
  );
};

export default MaterialityModal;
