import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Button, CardContent, Theme, Typography } from '@mui/material';

import { LocationsLoss, MaterialityKPI } from '../../../../Hooks/useGetMaterialityData';
import { ORANGE } from '../../../../util/productGlobals';

import Chip from '../../../CommonComponents/Chip/Chip';
import EmptyView from './EmptyView';

const useStyles = makeStyles((theme: Theme) => ({
  scrollContainer: {
    overflowY: 'auto',
    margin: theme.spacing(2, 0),
    padding: 0,
  },
  chipContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2),
  },
  materialityNumber: {
    color: ORANGE,
  },
  itemWrapper: {
    margin: theme.spacing(8, 0, 8, 4),
  },
  itemLabel: {
    opacity: 0.5,
  },
}));

type MaterialityKPIItemProps = {
  label: string;
  value: string;
};

const MaterialityKPIItem: React.FC<MaterialityKPIItemProps> = ({ label, value }) => {
  const { itemLabel, itemWrapper, materialityNumber } = useStyles();
  return (
    <div className={itemWrapper}>
      <Typography variant="h1" color="textPrimary" className={materialityNumber}>
        {value}
      </Typography>
      <Typography variant="body2" className={itemLabel}>
        {label}
      </Typography>
    </div>
  );
};

type MaterialityViewProps = {
  values: MaterialityKPI;
  losses: LocationsLoss[];
  expanded?: boolean;
};

const MaterialityView: React.FC<MaterialityViewProps> = ({ values, losses, expanded }) => {
  const { t } = useTranslation();
  const { scrollContainer, chipContainer } = useStyles();

  const overLossLifelineCount = React.useMemo(() => {
    if (!losses) return 0;
    return losses.filter((lifeline) => lifeline.loss >= values?.meanAnnualLoss).length;
  }, [values?.meanAnnualLoss, losses]);

  return (
    <div data-test-id="materiality-view-wrapper">
      <div className={chipContainer} data-test-id="materiality-lifelines">
        <Chip label={t('materiality:numLoss', { num: overLossLifelineCount })} />
        <Typography className="u-marginleft--16" variant="body2">
          {t('materiality:numTotal', { num: losses?.length ?? 0 })}
        </Typography>
      </div>
      {expanded && (
        <div data-test-id="materiality-view-body">
          {values ? (
            <CardContent className={scrollContainer} data-test-id="materiality-view-content">
              <MaterialityKPIItem
                label={t('materiality:kpis:meanLossLabel')}
                value={t('materiality:kpis:meanLoss', { val: values?.meanAnnualLoss })}
              />
              <MaterialityKPIItem
                label={t('materiality:kpis:revenuePercentageLabel')}
                value={t('materiality:kpis:revenuePercentage', { val: values?.percentByRevenue })}
              />
              <MaterialityKPIItem
                label={t('materiality:kpis:materialityPercentageLabel')}
                value={t('materiality:kpis:materialityPercentage', {
                  val: values?.overMaterialityPercent,
                })}
              />
              <MaterialityKPIItem
                label={t('materiality:kpis:materialityAmountLabel')}
                value={t('materiality:kpis:materialityAmount', { val: values?.overMateriality })}
              />
              <Button
                variant="contained"
                color="primary"
                component={RouterLink}
                fullWidth
                to="/materiality"
              >
                {t('detailView:learnMore')}
              </Button>
            </CardContent>
          ) : (
            <EmptyView />
          )}
        </div>
      )}
    </div>
  );
};

MaterialityView.displayName = 'MaterialityView';
export default MaterialityView;
