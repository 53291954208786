import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { OnecWindow } from 'onec-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CardContent, Divider, MenuItem, MenuList, Theme, Typography } from '@mui/material';

/// Commented now requested by Deedee
// import IconButton from '@mui/material/IconButton';
// import MoreVertIcon from '@mui/icons-material/MoreVert';

import {
  useGetHoveredLocationQuery,
  useUpdateHoveredLocationMutation,
} from '../../../../__generated__/graphql';
import { App } from '../../../../PlanningApp/AppConfig';
import Chip from '../../../CommonComponents/Chip/Chip';
import EmptyVulnerableLocationList from './EmptyVulnerableLocationList';
import { LocationWithScore } from '../../locationViewHelpers';
import MoreLocationsButton from './MoreLocationsButton';
import useListLoad from '../../../../Hooks/useListLoad';
import useSetPreviousMapBounds from '../../../../Hooks/useSetPreviousMapBounds';
import {
  FINANCE_HOME_PATH,
  HOME_PATH,
  VULNERABLE_SOURCE_ID,
} from '../../../../util/productGlobals';
import VulnerableLocation from './VulnerableLocation';
import useHasFinanceEntitlement from '../../../../Hooks/useHasFinanceEntitlement';

const useStyles = makeStyles((theme: Theme) => ({
  scrollContainer: {
    overflowY: 'auto',
    margin: theme.spacing(2, 0),
    padding: 0,
    paddingRight: theme.spacing(3),
  },
  chipContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2),
  },
  itemContainer: {
    whiteSpace: 'pre-wrap',
    alignItems: 'flex-start',
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

type VLLContentProps = {
  visibleVulnerableLocations: LocationWithScore[];
  totalLocationsInView: number;
  expanded?: boolean;
};

const VLLContent: React.FC<VLLContentProps> = ({
  visibleVulnerableLocations,
  totalLocationsInView,
  expanded = true,
}) => {
  const { t } = useTranslation();
  const [vulnerableSlice, isLoadable, showMore] = useListLoad(visibleVulnerableLocations ?? []);
  const setMapBounds = useSetPreviousMapBounds();
  const navigate = useNavigate();
  const { scrollContainer, chipContainer, itemContainer, divider } = useStyles();
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;

  const goToDetailView = React.useCallback(
    (e: React.MouseEvent) => {
      if (e.currentTarget instanceof HTMLElement && e.currentTarget.dataset.id) {
        const { id } = e.currentTarget.dataset;
        setMapBounds();
        navigate(`${isFinanceEntitled ? FINANCE_HOME_PATH : HOME_PATH}/detail/${id}`);
      }
    },
    [isFinanceEntitled, navigate, setMapBounds],
  );

  const {
    data: {
      hoveredLocation: { hoveredLocationId },
    },
  } = useGetHoveredLocationQuery();

  const [updateHoveredLocationMutation] = useUpdateHoveredLocationMutation();

  React.useEffect(() => {
    document.title = t('windowTitle');
  }, [t]);

  return (
    <div data-test-id="vll-content-wrapper">
      {visibleVulnerableLocations && (
        <div className={chipContainer} data-test-id="vll-location-counts-container">
          <Chip
            label={t('vulnerableList:numVulnerable', { num: visibleVulnerableLocations.length })}
            data-test-id="VulnerableLocations-loc-count"
          />
          <Typography
            className="u-marginleft--16"
            variant="body2"
            data-test-id="TotalLocations-loc-count"
          >
            {t('vulnerableList:numTotal', { num: totalLocationsInView })}
          </Typography>
        </div>
      )}
      {expanded && (
        <>
          {vulnerableSlice.length > 0 ? (
            <CardContent className={scrollContainer} data-test-id="VulnerableLocationsList-content">
              <MenuList disablePadding>
                {vulnerableSlice.map(
                  ({ id, name, address, group, type, vulnerabilityScore }, idx) => [
                    <MenuItem
                      key={id}
                      className={itemContainer}
                      onClick={goToDetailView}
                      tabIndex={0}
                      data-id={id}
                      data-test-id={`VulnerableLocation:${id}`}
                      data-score={vulnerabilityScore}
                      selected={hoveredLocationId && id === hoveredLocationId}
                      onMouseEnter={(x) => {
                        App.debug('[VLLContent] hover over location id: ', id, 'x: ', x);
                        updateHoveredLocationMutation({ variables: { locationId: id } });
                      }}
                      onMouseLeave={(x) => {
                        App.debug('[VLLContent] stop hover: ', id, '; x: ', x);
                        const mapboxMap = (window as OnecWindow).reactMap;
                        mapboxMap.removeFeatureState({ source: VULNERABLE_SOURCE_ID });
                        updateHoveredLocationMutation({ variables: { locationId: null } });
                      }}
                    >
                      <VulnerableLocation
                        id={id}
                        // Use idx as rank because the vulnerable locations are already sorted
                        rank={idx + 1}
                        group={group}
                        type={type}
                        name={name}
                        address={address}
                      />
                    </MenuItem>,
                    idx < vulnerableSlice.length - 1 && <Divider className={divider} />,
                  ],
                )}
              </MenuList>

              {isLoadable && (
                <>
                  <Divider className={divider} />
                  <MoreLocationsButton handleClick={showMore} />
                </>
              )}
            </CardContent>
          ) : (
            <EmptyVulnerableLocationList />
          )}
        </>
      )}
    </div>
  );
};
VLLContent.displayName = 'VLLContent';
export default VLLContent;
