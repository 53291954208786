import { ApolloCache, NormalizedCacheObject, Resolver } from '@apollo/client';
import { cloneDeep } from 'lodash/fp';

import {
  GetRoiBuiltObjectsDocument,
  GetRoiBuiltObjectsQuery,
  MutationAddRoiBuiltObjectArgs,
  RoiBuiltObject,
} from '../../../__generated__/graphql';

export const addROIBuiltObject: Resolver = (
  _: unknown,
  { input }: MutationAddRoiBuiltObjectArgs,
  { cache }: { cache: ApolloCache<NormalizedCacheObject> },
) => {
  const storeData = cache.readQuery<GetRoiBuiltObjectsQuery>({
    query: GetRoiBuiltObjectsDocument,
  });
  const oldData = cloneDeep(storeData);
  const newBuiltObject: RoiBuiltObject = {
    ...input,
    __typename: 'ROIBuiltObject',
  };

  const newData: GetRoiBuiltObjectsQuery['roiBuiltObjects'] = [newBuiltObject].concat([
    ...oldData.roiBuiltObjects.filter(
      (builtObject) => builtObject.builtObjectID !== newBuiltObject.builtObjectID,
    ),
  ]);
  cache.writeQuery({ data: { roiBuiltObjects: newData }, query: GetRoiBuiltObjectsDocument });
  return true;
};

export const roiBuiltObjectMutations = {
  addROIBuiltObject,
};
