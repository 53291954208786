import * as React from 'react';
import { partition } from 'lodash';

import { NOT_VULNERABLE_SOURCE_ID, VULNERABLE_SOURCE_ID } from '../../../../util/productGlobals';
import { App } from '../../../../PlanningApp/AppConfig';
import ClusterLayerSource from './ClusterLayerSource';
import { isLocVulnerable } from '../../../../util/vulnerability';
import { LocationWithScore } from '../../locationViewHelpers';
import useHasFinanceEntitlement from '../../../../Hooks/useHasFinanceEntitlement';

function propsAreEqual(
  prevProps: { filteredLocsWithScores: LocationWithScore[] },
  nextProps: { filteredLocsWithScores: LocationWithScore[] },
) {
  const oldLocArray = prevProps.filteredLocsWithScores;
  const newLocArray = nextProps.filteredLocsWithScores;

  if (!oldLocArray || !newLocArray || oldLocArray.length !== newLocArray.length) {
    return false;
  }

  let areEqual = true;
  let i = 0;
  while (areEqual && i < newLocArray.length) {
    if (
      newLocArray[i].id !== oldLocArray[i].id ||
      newLocArray[i].coordinates[0] !== oldLocArray[i].coordinates[0] ||
      newLocArray[i].coordinates[1] !== oldLocArray[i].coordinates[1] ||
      newLocArray[i].vulnerabilityScore !== oldLocArray[i].vulnerabilityScore
    ) {
      areEqual = false;
    }
    i += 1;
  }
  return areEqual;
}

const ClusterSourcesFC: React.FC<{ filteredLocsWithScores: LocationWithScore[] }> = ({
  filteredLocsWithScores,
}) => {
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;
  const [vulnerableLocations, notVulnerableLocations] = React.useMemo(() => {
    const [vulLocs, notVulLocs] = partition(filteredLocsWithScores, ({ vulnerabilityScore }) =>
      isLocVulnerable(vulnerabilityScore),
    );
    return [vulLocs, notVulLocs];
  }, [filteredLocsWithScores]);

  App.debug('[ClusterSourcesFC] - render');
  if (isFinanceEntitled) {
    return (
      <ClusterLayerSource
        locations={[...notVulnerableLocations, ...vulnerableLocations]}
        sourceId={NOT_VULNERABLE_SOURCE_ID}
        clusterRadius={75}
      />
    );
  }

  return (
    <>
      <ClusterLayerSource
        locations={notVulnerableLocations}
        sourceId={NOT_VULNERABLE_SOURCE_ID}
        clusterRadius={75}
      />
      <ClusterLayerSource
        locations={vulnerableLocations}
        sourceId={VULNERABLE_SOURCE_ID}
        clusterRadius={36}
      />
    </>
  );
};
const ClusterSources = React.memo(ClusterSourcesFC, propsAreEqual);
ClusterSources.displayName = 'ClusterSources';
export default ClusterSources;
