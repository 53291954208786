import { Button, Theme } from '@mui/material';
import * as React from 'react';
import { makeStyles } from '@mui/styles';
import useLocalStorage from '../../../Hooks/useLocalStorage';
import { LOCALSTORAGE_KEY_PREFIX } from '../../../util/productGlobals';
import { sendAmplitudeData } from '../../../plugins/amplitude';
import { AMP_DEMO_COLLECT_CUSTOMER_EMAIL } from '../../../plugins/amplitudeevents';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2),
  },
}));

const ContactUsFC: React.FC<{ userId: string }> = ({ userId }) => {
  // Only to be used for demo user
  const { root } = useStyles();
  const [customerInfo] = useLocalStorage<{ email: string; locationType: string }>(
    `${LOCALSTORAGE_KEY_PREFIX}${userId}:demoInfo`,
    null,
  );

  const subject = 'Interested%20in%20Domino%20Co-Pilot';
  const body = `Hi%2C%0D%0A%0D%0AI%20am%20interested%20in%20learning%20more%20about%20Domino%20Co-Pilot.%20My%20email%20address%20is%20${customerInfo?.email}.%0D%0A%0D%0AThank%20you!%0D%0A%0D%0A`;
  return (
    <div className={root}>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        className="u-clickable"
        data-test-id="contactus"
        href={`mailto:support@oneconcern.com?cc=cs%40oneconcern.com&subject=${subject}&body=${body}`}
        onClick={() =>
          sendAmplitudeData(AMP_DEMO_COLLECT_CUSTOMER_EMAIL, {
            userId,
            customerEmail: customerInfo?.email,
            locationType: customerInfo?.locationType,
          })
        }
      >
        Contact Us
      </Button>
    </div>
  );
};
const ContactUs = React.memo(ContactUsFC);
ContactUs.displayName = 'ContactUs';
export default ContactUs;
