import * as React from 'react';
import { GeocodeFeature } from '@mapbox/mapbox-sdk/services/geocoding';
import { SearchOptions } from 'onec-types';

import {
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  ListSubheader,
} from '@mui/material';

import i18next from '../../../PlanningApp/LocalizationClient';
import LocationsSearchInput from './LocationsSearchInput';
import { SearchLocationWithFilterQueryResult } from '../../../__generated__/graphql';

export const renderLocationsSearchGroup = (params: AutocompleteRenderGroupParams) => {
  // Only Locations do not have a subgroup header. The subgroup header is needed
  // for other types like 'recently viewed' and for other addresses (i.e. builtObjects)
  const { children, key, group } = params;

  if (group === 'LocationObject') {
    if ((children as Array<unknown>).length > 0) {
      return [children];
    }
  }
  let groupSubheader;
  if (group === 'MapboxObject') groupSubheader = i18next.t('search:mapboxAddresses');
  if (group === 'RecentViewObject') groupSubheader = i18next.t('search:recentlyViewed');
  return [
    <ListSubheader key={key} component="div">
      {groupSubheader}
    </ListSubheader>,
    children,
  ];
};

export const renderLocationsSearchInputField = (params: AutocompleteRenderInputParams) => (
  <LocationsSearchInput params={params} />
);

export const getOptionLabel = (option: SearchOptions[0]): string => {
  if (option.groupType === 'LocationObject' || option.groupType === 'RecentViewObject') {
    return (option.data as SearchLocationWithFilterQueryResult['data']['search'][0]).name ?? '';
  }
  // Option is a MapboxObject
  const feat: GeocodeFeature = option?.data;
  return feat?.place_name ?? feat?.properties?.place_name ?? feat?.properties?.address ?? '';
};
