import * as React from 'react';
import { LngLat, Map as MapboxMap } from 'mapbox-gl';
import { MapContext } from 'react-mapbox-gl';

import { LocationOn as MapPinIcon } from '@mui/icons-material';
import { SvgIconTypeMap } from '@mui/material';

import DraggableMapMarkerWithGhost from '../../CommonComponents/Map/DraggableMapMarker';

type SvgIconColor = SvgIconTypeMap['props']['color'];

/* This component just encapsulates the stuff specific to a draggable Map Pin marker, such 
   as it's anchored at the bottom and we have to also slightly shift/offset the SVG icon that
   we are using so that the pin's bottom actually touches the coordinates of the location */

const DraggableMapPin: React.FC<{
  draggable?: boolean;
  coordinates: number[];
  color?: SvgIconColor;
  dataTestId: string;
  onDrag?: (coordinates: LngLat, map: MapboxMap) => void;
  onDragStart?: (coordinates: LngLat, map: MapboxMap) => void;
  onDragEnd?: (dropCoordinates: LngLat, map: MapboxMap) => void;
}> = ({
  draggable = false,
  coordinates,
  color = 'primary',
  dataTestId,
  onDrag,
  onDragEnd,
  onDragStart,
}) => {
  return (
    <div data-test-id="map-pin-container">
      <MapContext.Consumer>
        {(mapboxMap: MapboxMap) => {
          return (
            <DraggableMapMarkerWithGhost
              draggable={draggable}
              coordinates={coordinates as [number, number]}
              onDrag={onDrag}
              onDragEnd={onDragEnd}
              onDragStart={onDragStart}
              anchor="bottom"
              offset={[0, +8]} // Remove the SVG's margin for the MUI LocationOn icon
              dataTestId={dataTestId}
              mapboxMap={mapboxMap}
            >
              <MapPinIcon
                color={color}
                fontSize="large"
                titleAccess="map pin icon"
                data-test-id="mapPinIcon"
              />
            </DraggableMapMarkerWithGhost>
          );
        }}
      </MapContext.Consumer>
    </div>
  );
};
DraggableMapPin.displayName = 'DraggableMapPin';
export default DraggableMapPin;
