import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { hoursToDayHourAbbrev } from '../../../../util/time';
import { Lifeline } from '../../../../util/productEnums';
import LifelineTag from './LifelineTag';
import { WHITE } from '../../../../util/productGlobals';

type LifelinesDataTableProps = {
  headerFields: string[];
  data: any[];
  showLifelineDetailView?: (lifeline: Lifeline) => void;
  hazardType: string;
  dataTestId?: string;
};

export const useLifelinesDataTableStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    table: {
      width: 'auto',
    },
    headerCell: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 500,
      lineHeight: theme.typography.pxToRem(18),
      color: WHITE,
      opacity: 0.5,
      borderBottom: 'none',
      padding: '0 0 8px 0',
      minWidth: 92,
      textAlign: 'right',
      '&:first-child': {
        width: 146,
        textAlign: 'left',
      },
    },
    bodyCell: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      color: WHITE,
      borderBottom: 'none',
      minWidth: 100,
      padding: '8px 0px 12px 8px',
      lineHeight: theme.typography.pxToRem(20),
      textAlign: 'right',
      '&:first-child': {
        paddingLeft: '0px',
        textAlign: 'left',
      },
    },
  };
});

const LifelinesDataTable: React.FC<LifelinesDataTableProps> = ({
  headerFields,
  data,
  showLifelineDetailView,
  hazardType,
  dataTestId,
}) => {
  const classes = useLifelinesDataTableStyles();
  const { t } = useTranslation();

  const renderTableCellItem = (row: any, key: string) => {
    if (key === 'lifeline') {
      return (
        <LifelineTag caption={row[key]} isVulnerable={!!row.isVulnerable} hazardType={hazardType} />
      );
    }

    if (typeof row[key] === 'number' && key === 'vulnerableAtReturnPeriod') {
      return t('symbolicView:firstToFail:returnPeriodYears', { returnPeriod: row[key] });
    }

    if (typeof row[key] === 'number' && (key === 'damage' || key === 'structureThreshold')) {
      return `${row[key]}%`;
    }

    if (typeof row[key] === 'number') return hoursToDayHourAbbrev(row[key]);
    return row[key];
  };

  const tableDataTestIdSuffix = dataTestId ? `-${dataTestId}` : '';
  return (
    <TableContainer className={classes.root} component={Paper}>
      <Table
        aria-label="Lifelines-DataTable"
        data-test-id={`Lifelines-DataTable${tableDataTestIdSuffix}`}
        className={classes.table}
      >
        <TableHead>
          <TableRow>
            {headerFields.map((header) => (
              <TableCell
                className={classes.headerCell}
                key={header}
                data-test-id={`lifeline-tbl-header-${header}`}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row.lifeline}
              onClick={() => showLifelineDetailView(row.lifeline as Lifeline)}
            >
              {Object.keys(row)
                .filter((k) => k !== 'isVulnerable')
                .map((key) => (
                  <TableCell
                    className={`${classes.bodyCell}`}
                    key={key}
                    data-test-id={`tableCell-value-${key}`}
                  >
                    {renderTableCellItem(row, key)}
                  </TableCell>
                ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

LifelinesDataTable.displayName = 'LifelinesDataTable';
export default LifelinesDataTable;
