import { Stats } from '../../__generated__/graphql';

const makeMockLifelineStats = (lifelineId: string, sliceNums: Array<number>): Stats[] => {
  const stats: Stats[] = [];
  sliceNums.forEach((sliceIdx) => {
    const statsId = `${lifelineId}-${sliceIdx}`;
    let meanValue: number =
      statsId
        .split('')
        .map((x, index) => x.charCodeAt(0) * (index + 1))
        .reduce((a, b) => a + b, 0) % 100;
    meanValue = meanValue >= 30 ? 0 : meanValue;

    let dev =
      statsId
        .split('')
        .map((x, index) => x.charCodeAt(0) * (index + 1))
        .reduce((a, b) => a + b, 0) % 50;
    dev = dev >= 5 ? 0 : dev;

    stats.push({
      id: statsId,
      mean: lifelineId.includes('Bridge') ? 0 : meanValue, /// Set bridge vulnerability to 0 for now
      stddev: dev,
    });
  });
  return stats;
};

export default makeMockLifelineStats;
