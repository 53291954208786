import * as React from 'react';

export type IconProps = {
  /**
   * Note that Icon names differ between our design styleguide and those used by engineering.
   * Engineering follows the naming convention `icon-This-Is-The-Icon-Name---variant`, with the
   * exception of icons that are used on the map (such as for critical infrastructures)
   */
  name: string;
  /**
   * Indicates the color of the icon. Shade-based variants: dark, light, med, white. Color-based
   * variants: red, orange, yellow, green, blue. Most icons only have some of these variants,
   * and some have none at all.
   */
  variant?: string;
  /**
   * Used in rare cases where icons exist without buttons.
   */
  handleClick?: (e: React.MouseEvent<HTMLElement>) => void;
  /**
   * Classes appended to those already used.
   */
  additionalClasses?: string;
  /**
   * Additional styling.
   */
  style?: React.CSSProperties;
  id?: string;
  dataTestId?: string;
};

const Icon: React.FC<IconProps> = ({
  name,
  variant,
  handleClick,
  additionalClasses = '',
  style,
  id,
  dataTestId,
}) => {
  let classes = `icon icon-${name}`;
  if (variant) classes += `---${variant}`;
  if (additionalClasses) classes += ` ${additionalClasses}`;

  return (
    <i
      role="img"
      id={id}
      className={classes}
      style={style}
      onClick={handleClick}
      data-test-id={dataTestId}
    />
  );
};

export default Icon;
