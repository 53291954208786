import { ApolloCache, NormalizedCacheObject, Resolver } from '@apollo/client';
import { cloneDeep } from 'lodash/fp';

import {
  GetFirstToFailDocument,
  GetFirstToFailQuery,
  MutationUpdatePlayStateArgs,
} from '../../../__generated__/graphql';

export const updatePlayState: Resolver = (
  _: unknown,
  { playState, returnPeriodIndex }: MutationUpdatePlayStateArgs,
  { cache }: { cache: ApolloCache<NormalizedCacheObject> },
) => {
  const storeData = cache.readQuery<GetFirstToFailQuery>({ query: GetFirstToFailDocument });
  const newData = cloneDeep(storeData);
  if (newData.firstToFailView) {
    if (
      newData.firstToFailView.playState === playState &&
      newData.firstToFailView.returnPeriodIndex === returnPeriodIndex
    ) {
      return newData.firstToFailView;
    }
    if (playState) {
      newData.firstToFailView.playState = playState;
    }
    if (returnPeriodIndex >= 0) {
      newData.firstToFailView.returnPeriodIndex = returnPeriodIndex;
    }
  }

  cache.writeQuery({ data: newData, query: GetFirstToFailDocument });
  return newData.firstToFailView;
};

export const FirstToFailMutations = {
  updatePlayState,
};
