import * as React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';

import {
  Address,
  LocationStatus,
  MibRequestStatus,
  useSetEditLocationSourceMutation,
} from '../../../__generated__/graphql';
import {
  DARKBLUE,
  LOCATIONS_MATCH_TABLE_PAGINATION_SIZE,
  selectMenuStyle,
} from '../../../util/productGlobals';
import {
  LocRecordWithMatchInfo,
  MATCH_ERROR_DISTANCE_THRESHOLD_M,
  MATCH_WARNING_DISTANCE_THRESHOLD_M,
  sortLocRecordsByMatchAccuracy,
} from './locationMatchHelpers';
import { App } from '../../../PlanningApp/AppConfig';
import { getDisplayableAddress } from '../../../util/addresses/addressUtils';
import { LOCATIONS_MATCH_TABLE_SORT } from '../../../util/productEnums';
import MatchStatusIcon from './MatchStatusIcon';

// TODO - Amplitude
const SECTION_KEY = 'settings:sections:locations:matchTable';
const useStyles = makeStyles(() => ({
  sortByControl: {
    marginLeft: 'auto',
  },
  sortByLabel: {
    opacity: 0.5,
  },
}));

type RowType = { [key: string]: any };

const MatchAddress: React.FC<{
  processStatus: LocationStatus;
  builtObjectAddress: Address;
  builtObjectRequestStatus: MibRequestStatus;
}> = ({ processStatus, builtObjectAddress, builtObjectRequestStatus }) => {
  const { t } = useTranslation();

  if (builtObjectRequestStatus === MibRequestStatus.Pending) {
    return <div>{t(`${SECTION_KEY}:matchPending`)}</div>;
  }
  if (processStatus === LocationStatus.Failure || !builtObjectAddress) {
    return <div>{t(`${SECTION_KEY}:none`)}</div>;
  }
  const [boL1, boL2] = getDisplayableAddress(builtObjectAddress);
  return (
    <>
      {boL1 && <div>{boL1}</div>}
      {boL2 && <div>{boL2}</div>}
    </>
  );
};
MatchAddress.displayName = 'MatchAddress';

const LocationsMatchTable: React.FC<{
  locRecords: LocRecordWithMatchInfo[];
}> = ({ locRecords }) => {
  const { enabledebugmatchquality: debugQuality } = App.config.features;
  const { sortByControl, sortByLabel } = useStyles();
  const { t } = useTranslation();
  const headerRef = React.useRef(null);
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [sortBy, setSortBy] = React.useState<LOCATIONS_MATCH_TABLE_SORT>(
    LOCATIONS_MATCH_TABLE_SORT.ORIGINAL_FILE_ORDER,
  );

  const sortedByMatchAccuracyRecords = React.useMemo(
    () => sortLocRecordsByMatchAccuracy(locRecords),
    [locRecords],
  );

  const location = useLocation();
  const [setEditLocationSource] = useSetEditLocationSourceMutation();
  const handleSetEditLocationSource = React.useCallback(() => {
    setEditLocationSource({
      variables: {
        url: location.pathname,
      },
    });
  }, [location, setEditLocationSource]);

  const tableRows: RowType[] = React.useMemo(
    () =>
      sortedByMatchAccuracyRecords?.map((record) => {
        const {
          id,
          matchInfo,
          matchInfo: { matchStatus },
          audit: { locationID, builtObjectRequestStatus, locationMeta, processStatus },
        } = record;
        const { name, address: originalAddress, builtObjectAddress } = locationMeta;
        const locName = name ?? t(`${SECTION_KEY}:unknownLocationName`);
        const [origL1, origL2] = getDisplayableAddress(originalAddress);

        return {
          id,
          locName,
          locAddr: (
            <div>
              {origL1 && <div>{origL1}</div>}
              {origL2 && <div>{origL2}</div>}
            </div>
          ),
          bobjAddr: (
            <MatchAddress
              processStatus={processStatus}
              builtObjectAddress={builtObjectAddress}
              builtObjectRequestStatus={builtObjectRequestStatus}
            />
          ),
          status: <MatchStatusIcon matchStatus={matchStatus} />,
          edit: (
            <IconButton
              component={RouterLink}
              to={`/locations/${locationID}`}
              onClick={handleSetEditLocationSource}
              data-test-id="LocationEditIcon"
            >
              <EditIcon />
            </IconButton>
          ),
          // The following fields are not displayed to the user, unless
          // the "enabledebugmatchquality" flag is turned on
          matchInfo,
          procStat: processStatus,
          geoAccuracy: matchInfo?.geoDetails?.accuracy_score ?? 0,
          geoRelevance: Math.round((matchInfo.geoDetails?.relevance_score ?? 0) * 100) / 100,
          bobjAccuracy: matchInfo?.bobjDetails?.accuracy_score ?? 0,
          bobjRelevance: Math.round((matchInfo?.bobjDetails?.relevance_score ?? 0) * 100) / 100,
          geoToBobjDistance:
            matchInfo?.coordDistance > MATCH_WARNING_DISTANCE_THRESHOLD_M
              ? Math.round((matchInfo?.coordDistance ?? MATCH_ERROR_DISTANCE_THRESHOLD_M) * 100) /
                100
              : '-',
          overallMatchScore: Math.round(matchInfo.overallMatchScore * 100) / 100,
          mapboxMatchScore: Math.round(matchInfo.mapboxMatchScore * 100) / 100,
          distMatchScore: Math.round(matchInfo.distMatchScore * 100) / 100,
          addrTextMatchScore: Math.round(matchInfo.addrTextMatchScore * 100) / 100,
          lineNumber: record.lineNumber,
        };
      }) ?? [],
    [handleSetEditLocationSource, sortedByMatchAccuracyRecords, t],
  );

  const sortedRows = React.useMemo(() => {
    const totalRows = [...tableRows];
    if (sortBy === LOCATIONS_MATCH_TABLE_SORT.ORIGINAL_FILE_ORDER) {
      // sort by  "Original File Order"
      totalRows.sort((row1, row2) => row1.lineNumber - row2.lineNumber);
    }
    // sort by "Match status"
    return totalRows;
  }, [tableRows, sortBy]);

  const pageSize = React.useMemo(
    () => Math.ceil(sortedRows.length / LOCATIONS_MATCH_TABLE_PAGINATION_SIZE),
    [sortedRows],
  );

  const columns = React.useMemo(() => {
    const mainColumns = [
      { field: 'edit', headerName: '', width: 50 },
      { field: 'locName', headerName: t(`${SECTION_KEY}:locationName`), width: 80 },
      { field: 'locAddr', headerName: t(`${SECTION_KEY}:providedAddress`), width: 150 },
      { field: 'bobjAddr', headerName: t(`${SECTION_KEY}:matchedAddress`), width: 150 },
      { field: 'status', headerName: t(`${SECTION_KEY}:matchStatus`), width: 50 },
      // The matchInfo column is not displayed, but is there so QA can inspect it and see the
      // match values
      {
        field: 'matchInfo',
        headerName: t(`${SECTION_KEY}:matchDetails`),
        width: 0,
      },
    ];

    const debugColumns = [
      {
        field: 'geoAccuracy',
        headerName: 'MB geo Acrcy',
        width: debugQuality ? 10 : 0,
      },
      {
        field: 'geoRelevance',
        headerName: 'MB geocode Relvc',
        width: debugQuality ? 10 : 0,
      },
      {
        field: 'bobjAccuracy',
        headerName: 'MB bobj Acrcy',
        width: debugQuality ? 10 : 0,
      },
      {
        field: 'bobjRelevance',
        headerName: 'MB bobj Relvnc',
        width: debugQuality ? 10 : 0,
      },
      {
        field: 'geoToBobjDistance',
        headerName: 'dist',
        width: debugQuality ? 10 : 0,
      },
      {
        field: 'mapboxMatchScore',
        headerName: 'mapbox Score',
        width: debugQuality ? 10 : 0,
      },
      {
        field: 'distMatchScore',
        headerName: 'dist Score',
        width: debugQuality ? 10 : 0,
      },
      {
        field: 'addrTextMatchScore',
        headerName: 'text Match Score',
        width: debugQuality ? 10 : 0,
      },
      {
        field: 'overallMatchScore',
        headerName: 'overall Score',
        width: debugQuality ? 10 : 0,
      },
    ];
    return debugQuality ? [...mainColumns, ...debugColumns] : mainColumns;
  }, [t, debugQuality]);

  const handleChangeSortBy = (event: SelectChangeEvent) => {
    setSortBy(event.target.value as LOCATIONS_MATCH_TABLE_SORT);
  };

  const handleChangePageNumber = (_: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
    const header = headerRef.current;
    if (header) header.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div data-test-id="LocationsMatchTable-Container">
      <div className="o-flexfe-container">
        <FormControl size="small" className={sortByControl}>
          <InputLabel
            className={sortByLabel}
            id="table-sort-by-select-label"
            data-test-id="LocationMatchTableSortByLabel"
          >
            {t(`${SECTION_KEY}:sortBy:label`)}
          </InputLabel>
          <Select
            size="small"
            labelId="table-sort-by-select-label"
            id="table-sort-by-select"
            value={sortBy}
            label={t('settings:sections:userManagement:addUser:accessType')}
            onChange={handleChangeSortBy}
            IconComponent={ExpandMoreIcon}
            data-test-id="LocationMatchTableSortBySelect"
            MenuProps={{
              PaperProps: {
                sx: selectMenuStyle,
              },
            }}
          >
            <MenuItem value={LOCATIONS_MATCH_TABLE_SORT.ORIGINAL_FILE_ORDER}>
              {t(`${SECTION_KEY}:sortBy:originalFileOrder`)}
            </MenuItem>
            <MenuItem value={LOCATIONS_MATCH_TABLE_SORT.ACCURACY}>
              {t(`${SECTION_KEY}:matchStatus`)}
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      <TableContainer sx={{ overflowX: 'initial' }}>
        <Table stickyHeader>
          <TableHead ref={headerRef}>
            <TableRow>
              {columns
                .filter((col) => col.field !== 'matchInfo')
                .map((col) => (
                  <TableCell
                    key={col.field}
                    sx={{
                      width: `${col.width}px`,
                      background: DARKBLUE,
                      top: -1,
                      textTransform: 'capitalize',
                    }}
                    data-test-id={`colhead-${col.field}`}
                  >
                    {col.headerName}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows &&
              sortedRows
                .slice(
                  (pageNumber - 1) * LOCATIONS_MATCH_TABLE_PAGINATION_SIZE,
                  pageNumber * LOCATIONS_MATCH_TABLE_PAGINATION_SIZE,
                )
                .map((row) => {
                  const colNames = columns.map((col) => col.field);
                  return (
                    <TableRow
                      key={`${row?.id}`}
                      onClick={() =>
                        App.debug(
                          `Location ${row.locName} matchInfo: ${JSON.stringify(row.matchInfo)}`,
                        )
                      }
                    >
                      {colNames
                        .filter((colName) => colName !== 'matchInfo')
                        .map((colName) => {
                          if (colName === 'status') {
                            return (
                              <TableCell
                                key={`${colName}`}
                                size="small"
                                data-match-info={JSON.stringify(row.matchInfo)}
                              >
                                {row[colName]}
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell key={`${colName}`} size="small">
                              {row[colName]}
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      {pageSize > 1 && (
        <div className="o-flexfe-container u-marginy--16">
          <Pagination
            count={pageSize}
            page={pageNumber}
            onChange={handleChangePageNumber}
            data-test-id="LocationsMatchTable-Pagination"
          />
        </div>
      )}
    </div>
  );
};
LocationsMatchTable.displayName = 'LocationsMatchTable';
export default LocationsMatchTable;
