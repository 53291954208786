import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  GetCurrentUserQuery,
  NavigatorViewType,
  useGetNavigatorInfoQuery,
  useUpdateNavigatorInfoMutation,
  useUpdatePlayStateMutation,
} from '../../__generated__/graphql';
import { App } from '../../PlanningApp/AppConfig';
import { Lifeline } from '../../util/productEnums';
import FiltersBar from './Filters/FiltersBar';
import SidePanel from './SidePanel/SidePanel';
import SymbolicViewContainer from './SymbolicView/SymbolicViewContainer';
import ViewNavigator from './ViewNavigator/ViewNavigator';
import { DEMO_ACCOUNT_SUFFIX } from '../../util/productGlobals';
import useHasFinanceEntitlement from '../../Hooks/useHasFinanceEntitlement';

type LocationViewProps = {
  user: GetCurrentUserQuery['user'];
};

const AnalysisViewContainer: React.FC<LocationViewProps> = ({ user }) => {
  const {
    data: {
      navigatorInfo: { currentView, currentLifeline },
    },
    error,
    loading,
  } = useGetNavigatorInfoQuery();
  const [updateNavigatorInfoMutation] = useUpdateNavigatorInfoMutation();
  const [updatePlayState] = useUpdatePlayStateMutation();
  const [searchParams] = useSearchParams();
  const showROI = React.useMemo(() => !!searchParams?.get('showROI'), [searchParams]);
  const show3D = React.useMemo(() => !!searchParams?.get('show3D'), [searchParams]);
  const isDemoUser = user.personalInfo.contact.email.endsWith(DEMO_ACCOUNT_SUFFIX);
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;

  React.useEffect(() => {
    if (show3D) {
      updateNavigatorInfoMutation({
        variables: {
          currentView: NavigatorViewType.FirstToFailView,
          currentLifeline: Lifeline.EMPTY,
        },
      });
      if (isDemoUser && !isFinanceEntitled) {
        updatePlayState({
          variables: {
            returnPeriodIndex: 2,
          },
        });
      }
    } else if (showROI || (isDemoUser && !isFinanceEntitled)) {
      updateNavigatorInfoMutation({
        variables: {
          currentView: NavigatorViewType.TopConcernsView,
          currentLifeline: Lifeline.EMPTY,
        },
      });
    }
  }, [
    isDemoUser,
    isFinanceEntitled,
    show3D,
    showROI,
    updateNavigatorInfoMutation,
    updatePlayState,
  ]);

  if (error) App.error(['[AnalysisViewContainer] Error retrieving Navigator Info', error.message]);
  if (error || loading) return null;

  return (
    <>
      <FiltersBar currentNavigatorView={currentView} userId={user.personalInfo.contact.email} />
      {(currentView === NavigatorViewType.TopConcernsView ||
        currentView === NavigatorViewType.FirstToFailView) && (
        <SymbolicViewContainer
          currentView={currentView}
          currentLifeline={currentLifeline}
          showROI={showROI}
          userId={user.personalInfo.contact.email}
        />
      )}
      <ViewNavigator userId={user.personalInfo.contact.email} />
      <SidePanel />
    </>
  );
};

AnalysisViewContainer.displayName = 'AnalysisViewContainer';
export default AnalysisViewContainer;
