import * as React from 'react';
import { Anchor, LngLat, Map as MapboxMap, Marker as MapboxMarker, PointLike } from 'mapbox-gl';
import { makeStyles } from '@mui/styles';

import { App } from '../../../PlanningApp/AppConfig';

const useStyles = makeStyles(() => ({
  draggableMarker: {
    cursor: 'move',
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 1,
  },
  notDraggableMarker: {
    cursor: 'default',
    width: 'fitContent',
  },
  ghostMarker: {
    cursor: 'default',
    opacity: '0.2 !important',
  },
}));

type DraggableMarkerProps = {
  draggable?: boolean;
  coordinates: [number, number];
  onDragStart?: (lngLat: LngLat, map: MapboxMap) => void;
  onDragEnd?: (dropCoordinates: LngLat, map: MapboxMap) => void;
  onDrag?: (lngLat: LngLat, map: MapboxMap) => void;
  mapboxMap: MapboxMap;
  anchor?: Anchor;
  offset?: PointLike;
  dataTestId: string;
  children?: React.ReactNode;
};

const DraggableMarker: React.FC<DraggableMarkerProps & { className: string }> = ({
  draggable = true,
  coordinates,
  onDragStart,
  onDrag,
  onDragEnd,
  mapboxMap,
  anchor = 'bottom',
  offset = [0, 0],
  dataTestId,
  children,
  className,
}) => {
  const element = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const [marker, setMarker] = React.useState<MapboxMarker>(null);

  React.useEffect(() => {
    if (!marker || !mapboxMap) return undefined;
    marker.setLngLat(coordinates).addTo(mapboxMap);
    return () => {
      marker.remove();
    };
  }, [marker, mapboxMap, coordinates]);

  React.useEffect(() => {
    if (!marker && element && mapboxMap) {
      // Note: you cannot create draggable markers with react-mapbox-gl, so
      // we have to create it via the mapbox-gl package.
      const newMarker = new MapboxMarker({
        draggable,
        anchor,
        offset,
        element: element.current,
      });
      setMarker(newMarker);
    }
  }, [marker, mapboxMap, draggable, coordinates, anchor, offset]);

  const handleDrag = React.useCallback(() => {
    const lngLat = marker.getLngLat();
    App.debug('[DraggableMapMarker] onDrag. lngLat=', lngLat);
    onDrag(lngLat, mapboxMap);
  }, [marker, mapboxMap, onDrag]);

  React.useEffect(() => {
    if (!marker || !mapboxMap || !onDrag) return undefined;
    marker.on('drag', handleDrag);
    return () => {
      marker.off('drag', handleDrag);
    };
  }, [marker, mapboxMap, onDrag, handleDrag]);

  const handleDragStart = React.useCallback(() => {
    const lngLat = marker.getLngLat();
    App.debug('[DraggableMapMarker] onDragStart. lngLat=', lngLat);
    onDragStart(lngLat, mapboxMap);
  }, [marker, mapboxMap, onDragStart]);

  React.useEffect(() => {
    if (!marker || !mapboxMap || !onDragStart) return undefined;
    marker.on('dragstart', handleDragStart);
    return () => {
      marker.off('dragstart', handleDragStart);
    };
  }, [marker, mapboxMap, onDragStart, handleDragStart]);

  const handleDragEnd = React.useCallback(() => {
    const lngLat = marker.getLngLat();
    App.debug('[DraggableMapMarker] onDragEnd. lngLat=', lngLat);
    onDragEnd(lngLat, mapboxMap);
  }, [marker, mapboxMap, onDragEnd]);

  React.useEffect(() => {
    if (!marker || !mapboxMap || !onDragEnd) return undefined;
    marker.on('dragend', handleDragEnd);
    return () => {
      marker.off('dragend', handleDragEnd);
    };
  }, [marker, mapboxMap, onDragEnd, handleDragEnd]);

  return (
    <div
      ref={element}
      data-test-id={dataTestId}
      data-test-type={`${dataTestId}-marker`}
      className={className}
    >
      {children}
    </div>
  );
};

const DraggableMapMarkerWithGhost: React.FC<DraggableMarkerProps> = ({
  coordinates,
  onDragStart,
  onDrag,
  onDragEnd,
  mapboxMap,
  draggable = true,
  anchor = 'bottom',
  offset = [0, 0],
  dataTestId,
  children,
}) => {
  const classes = useStyles();

  return (
    <div data-test-id={`${dataTestId}-draggable-map-marker-container`}>
      {draggable && (
        <DraggableMarker
          coordinates={coordinates}
          mapboxMap={mapboxMap}
          draggable={false}
          anchor={anchor}
          offset={offset}
          dataTestId={`${dataTestId}-ghost`}
          className={classes.ghostMarker}
        >
          {children}
        </DraggableMarker>
      )}
      <DraggableMarker
        coordinates={coordinates}
        mapboxMap={mapboxMap}
        draggable={draggable}
        anchor={anchor}
        offset={offset}
        onDragStart={draggable && onDragStart}
        onDrag={draggable && onDrag}
        onDragEnd={draggable && onDragEnd}
        dataTestId={`${dataTestId}-main`}
        className={draggable ? classes.draggableMarker : classes.notDraggableMarker}
      >
        {children}
      </DraggableMarker>
    </div>
  );
};

DraggableMapMarkerWithGhost.displayName = 'DraggableMapMarkerWithGhost';
export default DraggableMapMarkerWithGhost;
