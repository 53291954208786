import * as React from 'react';
import { makeStyles } from '@mui/styles';

import { NavigatorViewType, useGetNavigatorInfoQuery } from '../../../__generated__/graphql';
import { App } from '../../../PlanningApp/AppConfig';
import FirstToFailHeader from '../FirstToFail/FirstToFailHeader';
import PrimaryNav from './Primary/PrimaryNav';
import SecondaryNav from './Secondary/SecondaryNav';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    width: '100%',
    zIndex: 3,
    pointerEvents: 'none',
    '& > *': {
      pointerEvents: 'auto',
    },
  },
});

const AppNav: React.FC<{ userId: string }> = ({ userId }) => {
  const { root } = useStyles();
  const {
    data: {
      navigatorInfo: { currentView: currentNavigatorView },
    },
    error,
    loading,
  } = useGetNavigatorInfoQuery();

  if (error) App.error(['[AppNav] Error retrieving Navigator Info', error.message]);
  if (error || loading) return null;

  return (
    <div className={root} data-test-id="app-nav">
      {currentNavigatorView !== NavigatorViewType.FirstToFailView && <PrimaryNav userId={userId} />}
      {currentNavigatorView === NavigatorViewType.MapView && (
        <div className="u-flex u-flex-column">
          <SecondaryNav />
        </div>
      )}
      {currentNavigatorView === NavigatorViewType.FirstToFailView && <FirstToFailHeader />}
    </div>
  );
};

const AppNavContainer = React.memo(AppNav);
AppNavContainer.displayName = 'AppNav';
export default AppNavContainer;
