import * as React from 'react';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';

import {
  LocFilter,
  useGetLocationsFilterQuery,
  useUpdateLocationsFilterMutation,
} from '../../../../__generated__/graphql';
import { allLocFilterChoicesVar } from '../../../../PlanningApp/client/cache';
import LocationsFiltersHover from './LocationsFiltersHover';
import LocationsPickerFormBody from './LocationsPickerFormBody';
import PickerForm from '../Common/PickerForm';

const defaultLocFilter: LocFilter = {
  locTypes: [],
  locGroups: [],
};

type LocationsPickerProps = {
  showLoadingSkeleton?: boolean;
  hiddenGroups?: boolean;
};

const LocationsPicker: React.FC<LocationsPickerProps> = ({ showLoadingSkeleton, hiddenGroups }) => {
  const { t } = useTranslation();
  const allLocsTypesForFilter = useReactiveVar(allLocFilterChoicesVar);
  const locPickerWillRender =
    allLocsTypesForFilter &&
    (allLocsTypesForFilter?.locGroups?.length > 0 || allLocsTypesForFilter?.locTypes?.length > 0);

  const [preSubmitLocFilter, setPreSubmitLocFilter] = React.useState<LocFilter>(null);

  const {
    data: {
      planView: { locFilter: savedLocationsFilter },
    },
  } = useGetLocationsFilterQuery();

  React.useEffect(() => {
    setPreSubmitLocFilter(savedLocationsFilter);
  }, [savedLocationsFilter]);

  const [updateLocationsFilterMutation] = useUpdateLocationsFilterMutation();
  const onSubmit = React.useCallback(() => {
    updateLocationsFilterMutation({
      variables: {
        locFilter: preSubmitLocFilter,
      },
    });
  }, [preSubmitLocFilter, updateLocationsFilterMutation]);

  const onCancel = React.useCallback(() => {
    setPreSubmitLocFilter(savedLocationsFilter);
  }, [savedLocationsFilter]);

  const onRestoreToDefaults = React.useCallback(() => {
    setPreSubmitLocFilter(defaultLocFilter);
  }, []);

  const onChangePreSubmitLocations = React.useCallback((newLocFilter: LocFilter) => {
    setPreSubmitLocFilter(newLocFilter);
  }, []);

  const numFilters =
    (savedLocationsFilter?.locTypes?.length ?? 0) + (savedLocationsFilter?.locGroups?.length ?? 0);

  const pickerButtonText = React.useMemo(
    () =>
      numFilters > 0
        ? `${numFilters} ${t('locationGroups:filter', { count: numFilters })}`
        : t('locationGroups:all'),
    [numFilters, t],
  );

  const hoverDropdownComponent = React.useMemo(() => {
    return numFilters > 0 ? <LocationsFiltersHover locFilter={savedLocationsFilter} /> : null;
  }, [numFilters, savedLocationsFilter]);

  if (!locPickerWillRender) {
    if (showLoadingSkeleton) {
      return (
        <Skeleton variant="rectangular" height={36} width={144} sx={{ borderRadius: '4px' }} />
      );
    }
    return null;
  }

  return (
    <PickerForm
      onSubmit={onSubmit}
      onCancel={onCancel}
      onRestoreToDefaults={onRestoreToDefaults}
      submitting={false}
      name="locations"
      hoverDropdownComponent={hoverDropdownComponent}
      pickerButtonText={pickerButtonText}
    >
      {preSubmitLocFilter && (
        <LocationsPickerFormBody
          preSubmitVal={preSubmitLocFilter}
          onChangePreSubmitVal={onChangePreSubmitLocations}
          hiddenGroups={hiddenGroups}
        />
      )}
    </PickerForm>
  );
};
LocationsPicker.displayName = 'LocationsPicker';
export default LocationsPicker;
