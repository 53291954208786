import * as React from 'react';

import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import {
  GetBuiltObjectDocument,
  GetBuiltObjectQuery,
  GetLocationDocument,
  GetLocationQuery,
  PlanView,
  RecentView,
  useInitializeRecentViewsMutation,
  useUpdatePlanViewMutation,
} from '../__generated__/graphql';

import { App } from './AppConfig';
import { cloneDeep } from 'lodash';
import { getStreetWithNum } from '../util/addresses/addressUtilsUS';
import { DEFAULT_FORECAST_PERIOD, LOCALSTORAGE_KEY_PREFIX } from '../util/productGlobals';
import useLocalStorage from '../Hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';

// TODO: Make this into a hook vs a component.

const LoadPrevSessionData: React.FC<{ userId: string }> = ({ userId }) => {
  const client = useApolloClient();
  const { t } = useTranslation();

  const [initializeRecentViewsMutation] = useInitializeRecentViewsMutation();
  const recentViewsLSKey = `${LOCALSTORAGE_KEY_PREFIX}${userId}:recentViews`;
  const [prevRecentViews] = useLocalStorage<RecentView[]>(recentViewsLSKey, null);
  App.debug(
    `[LoadPrevSessionData] - previousSession RecentViews. key: ${recentViewsLSKey} ; value: `,
    prevRecentViews,
  );

  const [updatePlanViewMutation] = useUpdatePlanViewMutation();
  const planViewLSKey = `${LOCALSTORAGE_KEY_PREFIX}${userId}:planView`;
  const [prevSessionPlanView] = useLocalStorage<PlanView>(planViewLSKey, null);
  App.debug(
    `[LoadPrevSessionData] - previousSession PlanView. key: ${planViewLSKey} ; value: `,
    prevSessionPlanView,
  );

  React.useEffect(() => {
    // Restore PlanView (location Group/Type filter and filters (hazardSelector, forecastperiod,
    // climate switch)) from selections saved to localStorage in user's previous browser session.
    if (prevSessionPlanView) {
      const { forecastPeriod } = prevSessionPlanView.planFilters;
      const {
        financeDefaultPH: defaultFinanceDefaultPH,
        financeRP1: defaultFinanceRP1,
        financeRP2: defaultFinanceRP2,
      } = DEFAULT_FORECAST_PERIOD;
      updatePlanViewMutation({
        variables: {
          planView: {
            ...prevSessionPlanView,
            planFilters: {
              ...prevSessionPlanView.planFilters,
              forecastPeriod: {
                ...forecastPeriod,
                financeDefaultPH: forecastPeriod.financeDefaultPH ?? defaultFinanceDefaultPH,
                financeRP1: forecastPeriod.financeRP1 ?? defaultFinanceRP1,
                financeRP2: forecastPeriod.financeRP2 ?? defaultFinanceRP2,
              },
            },
          },
        },
      });
    }
  }, [prevSessionPlanView, updatePlanViewMutation]);

  React.useEffect(() => {
    // FOR RECENT VIEWS, prefetch each location or builtObject that is in the previous session's
    // RecentViews saved to the LocalStorage. If we don't do this prefetch, the LocationSearch
    // component won't work.
    let isMounted = true;
    if (!prevRecentViews || prevRecentViews?.length <= 0) {
      return;
    }
    const promises: Promise<ApolloQueryResult<any>>[] = [];
    prevRecentViews.forEach((recentView) => {
      const { id, type, userInput } = recentView;

      // We have to use client.query instead of useGetLocationLazyQuery and
      // useGetBuiltObjectLazyQuery because we might need to call each multiple times with
      // different variables. See https://github.com/apollographql/apollo-client/issues/6133
      let promise: Promise<ApolloQueryResult<GetBuiltObjectQuery | GetLocationQuery>>;
      if (type === 'Location') {
        promise = client.query({ query: GetLocationDocument, variables: { id } });
      } else if (type === 'Building') {
        promise = client.query<GetBuiltObjectQuery>({
          query: GetBuiltObjectDocument,
          variables: { id },
        });
        promise.then(({ data: builtObjectData }) => {
          if (!isMounted) {
            // if the component unmounted before this promise resolves, just ignore the results
            // so we don't cause a memory leak
            return;
          }

          // GetBuiltObjectQueries always return null for the name, so if the user searched
          // for this Location by name and the results from that search are in RecentViews,
          // then change the value of builtObject.name in the cache
          if (!(builtObjectData as GetBuiltObjectQuery)?.builtObject?.name) {
            const { street, houseNumber, countryCode } = (builtObjectData as GetBuiltObjectQuery)
              .builtObject.address;
            const labelName = getStreetWithNum(street, houseNumber, countryCode);
            const userSavedName = userInput ? JSON.parse(userInput).text : '';
            const builtObjectName = userSavedName || labelName || t('address:unknownAddress');
            const bobjDataWithName = cloneDeep(builtObjectData) as GetBuiltObjectQuery;
            bobjDataWithName.builtObject.name = builtObjectName;
            client.writeQuery({
              data: bobjDataWithName,
              query: GetBuiltObjectDocument,
            });
          }
        });
      }
      promises.push(promise);
    });
    Promise.all(promises)
      .then((results) => {
        if (!isMounted) {
          // if the component unmounted before this promise resolves, just ignore the results
          // so we don't cause a memory leak
          return;
        }
        // Once we've fetched ALL the locations and builtObjects in the previous Session's
        // recentViews, restore the recentViews in local storage to the cache.
        App.debug(
          '[LoadPrevSessionData] - All locations/builtObjects for RecentViews are loaded into cache:',
          results,
        );
        App.debug(
          '[LoadPrevSessionData] - restoring recentViews from previous session: ',
          prevRecentViews,
        );
        initializeRecentViewsMutation({
          variables: { recentViewsList: prevRecentViews },
        });
      })
      .catch((error) => {
        // TODO - Should I trigger a snackbar?
        App.error(
          '[LoadPrevSessionData] - Cannot restore previous session RecentViews due to getLoc/getBobj error: ',
          error,
        );
        initializeRecentViewsMutation({
          variables: { recentViewsList: [] },
        });
      });

    // eslint-disable-next-line consistent-return
    return () => {
      // We need this to avoid memory leaks from when this component unmaps before all the
      // above Promises resolve.
      isMounted = false;
    };
  }, [prevRecentViews, client, initializeRecentViewsMutation, t]);

  return null;
};
LoadPrevSessionData.displayName = 'LoadPrevSessionData';
export default LoadPrevSessionData;
