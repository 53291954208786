import * as React from 'react';
import { DotProps } from 'recharts';

import { ORANGE, PURPLE, WHITE } from '../../../util/productGlobals';

type ReturnPeriodDotProps = DotProps & {
  threshold: number;
};

const ReturnPeriodDotFC: React.FC<ReturnPeriodDotProps> = ({ cx, cy, threshold, payload }) => {
  const { years, downtime } = payload;

  if (years === 0 && downtime === 0.0001) return null;

  return (
    <svg
      x={cx - 6}
      y={cy - 6}
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6"
        cy="6"
        r="5"
        fill={Number(downtime) > threshold ? PURPLE : ORANGE}
        stroke={WHITE}
      />
    </svg>
  );
};

const ReturnPeriodDot = React.memo(ReturnPeriodDotFC);
ReturnPeriodDot.displayName = 'ReturnPeriodDot';
export default ReturnPeriodDot;
