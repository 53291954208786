import * as React from 'react';

import { ApolloClient, ApolloError } from '@apollo/client';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { cloneDeep } from 'lodash';
import { App } from '../PlanningApp/AppConfig';
import {
  GetBuiltObjectAnalysisQuery,
  GetBuiltObjectQuery,
  GetLocationQuery,
  GetRecentViewsDocument,
  GetRecentViewsQuery,
  useGetBuiltObjectQuery,
  useGetLocationQuery,
} from '../__generated__/graphql';
import { getStreetWithNum } from '../util/addresses/addressUtilsUS';
import useApiErrSnackbar from '../Components/CommonComponents/Snackbar/useApiErrSnackbar';

type UseGetLocOrBobjReturnType = {
  locOrBobj?: GetLocationQuery['location'] | GetBuiltObjectQuery['builtObject'];
  loading: boolean;
  error?: ApolloError | Error;
};

export const addNameToBobjInCache: (
  bobjInCache: GetBuiltObjectQuery | GetBuiltObjectAnalysisQuery,
  client: ApolloClient<any>,
  t: TFunction<'translation', undefined>,
) => GetBuiltObjectQuery | GetBuiltObjectAnalysisQuery = (bobjInCache, client, t) => {
  const copyBobjInCache = cloneDeep(bobjInCache);
  // Get the BuiltObject name from the recentSearch list's userInput, if it exists.
  if (bobjInCache?.builtObject?.name) {
    // builtObject already has a name, so no need to add a name.
    return copyBobjInCache;
  }

  // Look for the location name the user searched for in the RecentViews cache entries
  const { recentViews } = client.readQuery<GetRecentViewsQuery>({
    query: GetRecentViewsDocument,
  });
  const [matchingRecentView] = recentViews.filter(
    (recentView) => recentView.id === bobjInCache.builtObject.id,
  );
  const placeName = JSON.parse(matchingRecentView?.userInput ?? null)?.text;
  if (placeName) {
    copyBobjInCache.builtObject.name = placeName;
  } else {
    App.warn(
      '[useGetLocOrBobj] - builtObject doesnt have name & no recent view name, defaulting to address or unknown',
    );
    const { street, houseNumber, countryCode } = copyBobjInCache.builtObject.address;
    const labelName = getStreetWithNum(street, houseNumber, countryCode);
    copyBobjInCache.builtObject.name =
      labelName.length > 0 ? labelName : t('address:unknownAddress');
  }
  return copyBobjInCache;
};

function useGetLocOrBobj(
  id: string,
  showErrSnackbars = true,
  updateCache = true,
): UseGetLocOrBobjReturnType {
  const { enqueueApiErrSnackbar } = useApiErrSnackbar();
  const { t } = useTranslation();
  const {
    data: locData,
    loading: locLoading,
    error: locError,
  } = useGetLocationQuery({
    skip: !id,
    variables: { id },
  });

  // If the GetLocation query errors out, then have server look for a BuiltObject with this ID.
  const {
    data: builtData,
    loading: builtLoading,
    error: builtError,
    updateQuery,
    client,
  } = useGetBuiltObjectQuery({
    skip: !id || !locError,
    variables: { id },
    onCompleted: (bobj) => {
      if (bobj) {
        updateQuery((bobjInCache) => {
          return updateCache
            ? (addNameToBobjInCache(bobjInCache, client, t) as GetBuiltObjectQuery)
            : bobjInCache;
        });
      }
    },
  });

  React.useEffect(() => {
    if ((builtError || !id) && showErrSnackbars) {
      enqueueApiErrSnackbar();
    }
  }, [id, builtError, showErrSnackbars, enqueueApiErrSnackbar]);

  if (!id) {
    return {
      locOrBobj: null,
      loading: false,
      error: new Error('[useGetLocOrBobj] Error: id parameter to query was undefined or null'),
    };
  }

  if (locData || builtData) {
    return {
      locOrBobj: locData?.location || builtData?.builtObject,
      loading: false,
    };
  }

  if (locLoading || builtLoading) {
    return {
      locOrBobj: null,
      loading: true,
    };
  }

  if (builtError) {
    // assert that locError is also an error, because the GetBuiltObjectQuery that failed should
    // not have even run unless there was already a locError
    App.assert(!!locError, '[useGetLocOrBobj] - if builtError there should also be a locError.');
    return {
      locOrBobj: null,
      loading: false,
      error: builtError,
    };
  }

  App.assert(
    !!locError && !builtError && !builtLoading && !builtData,
    '[useGetLocOrBobj] GetLocationQuery failed, but fallback to GetBuiltObjectQuery has not started',
  );
  if (!locError && !(builtData || builtLoading || builtError)) {
    // Return error if GetBuiltObject query isn't finishing or at least returning a loading status
    return {
      locOrBobj: null,
      loading: false,
      error: builtError,
    };
  }
  return {
    locOrBobj: null,
    loading: true,
  };
}

export default useGetLocOrBobj;
