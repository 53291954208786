/* eslint-disable react/jsx-no-useless-fragment */
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SYWSectionProps } from 'onec-types';
import { useLocation } from 'react-router-dom';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import {
  HighwaySegment,
  MeasurementPreferences,
  useGetCurrentUserQuery,
} from '../../../../__generated__/graphql';
import { hoursToDayHourAbbrev } from '../../../../util/time';
import RecoveryCurveChart from '../../Charts/RecoveryCurveChart';
import ReturnPeriodChart from '../../Charts/ReturnPeriodChart';
import { roundNumToPlace } from '../../../../util/utils';
import showYourWorkStyles from './showYourWorkStyles';
import useHasFinanceEntitlement from '../../../../Hooks/useHasFinanceEntitlement';

const SECTION_KEY = 'showYourWork:sections:highway:';

const SYWHighwayFC: React.FC<SYWSectionProps> = ({ stats, gutterTop }) => {
  const { data: financeModules } = useHasFinanceEntitlement();
  const isFinanceEntitled = financeModules?.enablebi ?? false;
  const { t } = useTranslation();
  const {
    mean,
    threshold,
    topAssets,
    totalAssets,
    numVulnerable,
    recoveryCurve,
    returnPeriodData,
  } = stats;

  const { pathname }: { pathname: string } = useLocation();
  const { data: userData } = useGetCurrentUserQuery();
  const units = userData?.user?.preferences?.units;
  const { subtitle, statLabel, statValue, table, noWrap } = showYourWorkStyles({
    isVulnerable: mean > threshold,
    lightScrollBar: !pathname?.includes('/analysis/see-the-math'),
  });

  return (
    <section
      className="u-width--100"
      /// gutterTop is true when displayed under SYW content
      id={`highway-section${!gutterTop ? '-detail' : ''}`}
      data-test-id="SYWHighway"
    >
      <Typography style={gutterTop && { marginTop: '28px' }} variant="h2" paragraph>
        {t(`${SECTION_KEY}downtime:title`)}
      </Typography>

      <Typography className={statValue} data-test-id="SYWHighway-mean">
        {hoursToDayHourAbbrev(mean)}
      </Typography>
      <Typography className={statLabel} paragraph>
        {t(`${SECTION_KEY}downtime:averageDowntime`)}
      </Typography>
      <Typography className={statLabel} paragraph>
        {t('showYourWork:threshold', { value: hoursToDayHourAbbrev(threshold) })}
      </Typography>

      <Typography paragraph>{t(`${SECTION_KEY}downtime:desc`)}</Typography>
      <Typography paragraph>
        <strong>{t('showYourWork:resultsBasedOn')}</strong>
      </Typography>

      {!isFinanceEntitled && recoveryCurve?.length > 0 && (
        <>
          <Typography variant="subtitle1" className={subtitle} gutterBottom>
            {t(`${SECTION_KEY}recovery:title`)}
          </Typography>
          <Typography paragraph data-test-id="RecoveryChartDesc">
            {t(`${SECTION_KEY}recovery:desc`)}
          </Typography>
          <Typography paragraph data-test-id="RecoveryChartIntro">
            {t(`${SECTION_KEY}recovery:descChart`)}
          </Typography>
          <RecoveryCurveChart curve={recoveryCurve} lifeline="highway" />
        </>
      )}

      <Typography variant="subtitle1" className={subtitle} gutterBottom>
        {t(`${SECTION_KEY}analysis:title`)}
      </Typography>
      <Typography paragraph>{t(`${SECTION_KEY}analysis:desc`)}</Typography>

      {!isFinanceEntitled && topAssets?.length > 0 && (
        <div data-test-id="topDisruptedAssetsIntro">
          <Typography variant="subtitle1" className={subtitle} gutterBottom>
            {t(`${SECTION_KEY}disruption:title`)}
          </Typography>
          <Typography paragraph>{t(`${SECTION_KEY}disruption:desc`)}</Typography>
        </div>
      )}
      {!isFinanceEntitled && (
        <Typography variant="h4">
          <Trans i18nKey={`${SECTION_KEY}disruption:numAnalyzed`}>
            <strong>Total number of highways in analysis:</strong>
            <Typography variant="h5" component="span" data-test-id="SYWHighway-numAnalyzed">
              <>{{ num: totalAssets.toLocaleString() }}</>
            </Typography>
          </Trans>
        </Typography>
      )}
      {!isFinanceEntitled && (
        <Typography variant="h4" paragraph>
          <Trans i18nKey={`${SECTION_KEY}disruption:numVulnerable`}>
            <strong>At-risk number of highways:</strong>
            <Typography variant="h5" component="span" data-test-id="SYWHighway-numVulnerable">
              <>{{ num: numVulnerable.toLocaleString() }}</>
            </Typography>
          </Trans>
        </Typography>
      )}

      {!isFinanceEntitled && topAssets?.length > 0 && units && (
        <TableContainer className={table}>
          <Table data-test-id="SYWHighway-top3-table">
            <TableHead>
              <TableRow>
                <TableCell>{t(`${SECTION_KEY}table:highway`)}</TableCell>
                <TableCell>{t(`${SECTION_KEY}table:distanceAnalyzed`)}</TableCell>
                <TableCell>{t('showYourWork:tables:averageDowntime')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {topAssets.map(({ id, name, length, stats: assetStats }: HighwaySegment) => {
                const assetMean = assetStats[0].mean;
                return (
                  <TableRow key={id} data-test-id="row-top-asset-table">
                    <TableCell className={noWrap} data-test-id="assetName">
                      {name ?? t('showYourWork:tables:unknownName')}
                    </TableCell>
                    {!length && length !== 0 && <TableCell>-</TableCell>}
                    {(length || length === 0) &&
                      (units === MeasurementPreferences.ImperialSystem ? (
                        <TableCell>
                          {t('units:mileAbbrev', { num: length?.toLocaleString() ?? '-' })}
                        </TableCell>
                      ) : (
                        <TableCell>
                          {t('units:kmAbbrev', {
                            num: (length * 1.6)?.toLocaleString() ?? '-',
                          })}
                        </TableCell>
                      ))}
                    <TableCell>{hoursToDayHourAbbrev(roundNumToPlace(assetMean, 2))}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Typography variant="subtitle1" className={subtitle} gutterBottom>
        {t(`${SECTION_KEY}severityImpact:title`)}
      </Typography>
      <Typography paragraph data-test-id="ReturnPeriodChartIntro">
        {t(`${SECTION_KEY}severityImpact:desc`)}
      </Typography>
      {returnPeriodData?.length > 0 && (
        <ReturnPeriodChart points={returnPeriodData} threshold={threshold} lifeline="highway" />
      )}

      <Typography paragraph>
        <Trans
          i18nKey={`showYourWork:${
            isFinanceEntitled ? 'exploreAnalysisCatnet' : 'exploreAnalysis'
          }`}
        >
          <strong>Did you know?</strong>
        </Trans>
      </Typography>
    </section>
  );
};

const SYWHighway = React.memo(SYWHighwayFC);
SYWHighway.displayName = 'SYWHighway';
export default SYWHighway;
