/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CardHeader, Link, MenuList, Theme, Typography } from '@mui/material';
import { SettingsSidebarCategory } from 'onec-types';

import { GetCurrentUserQuery, GetOrganizationQuery } from '../../../../__generated__/graphql';
import { AppLogout } from '../../../../PlanningApp/auth/admin';
import { getFullName } from '../../../../util/utils';
import SettingsNavItem from './SettingsNav';
import UserRoleTag from './UserManagement/UserRoleTag';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 1,
    overflow: 'hidden',
    minWidth: '230px',
    maxWidth: '300px',
    width: '100%',
    paddingBottom: theme.spacing(8),
  },
  header: {
    marginLeft: theme.spacing(10),
    marginTop: theme.spacing(16),
    padding: 0,
  },
  locationInfo: {
    marginLeft: theme.spacing(16),
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(10.5),
  },
  navContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(2),
    marginBottom: 'auto',
  },
  organizationName: {
    fontWeight: 400,
  },
  personalInfo: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(13),
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(2),
  },
  settingsNav: {
    paddingRight: theme.spacing(1),
  },
  bottomLeftLinks: {
    '&.Mui-focusVisible': {
      textDecoration: 'underline',
      outline: 'none',
    },
    textTransform: 'none',
    marginLeft: theme.spacing(8),
  },
}));

type SettingsSidebarProps = {
  categories: SettingsSidebarCategory[];
  activeCategory: SettingsSidebarCategory;
  handleClick: (e: React.MouseEvent) => void;
  user: GetCurrentUserQuery['user'];
  organization: GetOrganizationQuery['organization'];
};

const SettingsSidebarFC: React.FC<SettingsSidebarProps> = ({
  activeCategory,
  handleClick,
  categories,
  user,
  organization,
}) => {
  const { t } = useTranslation();

  const {
    container,
    header,
    navContainer,
    organizationName,
    personalInfo,
    settingsNav,
    bottomLeftLinks,
  } = useStyles();
  const navigate = useNavigate();

  const firstName = React.useMemo(() => user?.personalInfo?.firstName ?? '', [user]);
  const lastName = React.useMemo(() => user?.personalInfo?.lastName ?? '', [user]);
  const fullName = React.useMemo(() => {
    return getFullName(firstName, lastName);
  }, [firstName, lastName]);

  const signOut = React.useCallback(() => {
    navigate('/');
    AppLogout();
  }, [navigate]);

  return (
    <div className={container} data-test-id="SettingsSidebar">
      <CardHeader
        className={header}
        title={t('settings:title')}
        titleTypographyProps={{ variant: 'h2' }}
      />
      <div className={personalInfo} data-test-id="PersonalInfo">
        <Typography variant="h4">{fullName}</Typography>
        <div className="u-margintop--8 u-marginbottom--16">
          <Typography variant="caption" className={organizationName}>
            {organization?.name}
          </Typography>
        </div>
        <UserRoleTag userRole={user?.role} />
      </div>
      <nav className={navContainer} data-test-id="SettingsNav">
        <MenuList className={settingsNav} disablePadding>
          {categories.map((category: SettingsSidebarCategory) => (
            <SettingsNavItem
              key={category.name}
              icon={category.icon}
              category={category.name}
              isActive={activeCategory.name === category.name}
              handleClick={handleClick}
            />
          ))}
        </MenuList>
      </nav>
      <Link
        underline="hover"
        color="textPrimary"
        component="button"
        onClick={signOut}
        classes={{ root: bottomLeftLinks }}
      >
        <Typography variant="h4" data-test-id="Signout">
          {t('nav:signOut')}
        </Typography>
      </Link>
    </div>
  );
};

const SettingsSidebar = React.memo(SettingsSidebarFC);
SettingsSidebar.displayName = 'SettingsSidebar';
export default SettingsSidebar;
