import * as React from 'react';
import { Card, CardContent, CardHeader, Tooltip } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { makeStyles } from '@mui/styles';
import { WHITE } from '../../../util/productGlobals';

const useStyles = makeStyles(() => ({
  icon: {
    fill: WHITE,
    marginLeft: 8,
  },
}));

const TooltipWithHeaderFC: React.FC<{
  title: string;
  body: React.ReactElement;
}> = ({ title, body }) => {
  const { icon } = useStyles();
  const titleObject = (
    <Card>
      <CardHeader title={title} avatar={<ErrorOutlineIcon />} />
      <CardContent>{body}</CardContent>
    </Card>
  );
  return (
    <Tooltip title={titleObject} arrow placement="top">
      <ErrorOutlineIcon className={`${icon} u-clickable`} fontSize="small" />
    </Tooltip>
  );
};
TooltipWithHeaderFC.displayName = 'TooltipWithHeaderFC';
export default TooltipWithHeaderFC;
