import * as React from 'react';

import { AMP_DEMO_COLLECT_CUSTOMER_EMAIL } from '../../../plugins/amplitudeevents';
import ConfirmDialog from '../../CommonComponents/ConfirmDialog/ConfirmDialog';
import { sendAmplitudeData } from '../../../plugins/amplitude';
import useLocalStorage from '../../../Hooks/useLocalStorage';
import { LOCALSTORAGE_KEY_PREFIX } from '../../../util/productGlobals';

const SubscriberBlockDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  userId: string;
}> = ({ open, onClose, userId }) => {
  const [customerInfo] = useLocalStorage<{ email: string; locationType: string }>(
    `${LOCALSTORAGE_KEY_PREFIX}${userId}:demoInfo`,
    null,
  );

  const subject = 'Interested%20in%20Domino%20Co-Pilot';
  const body = `Hi%2C%0D%0A%0D%0AI%20am%20interested%20in%20learning%20more%20about%20Domino%20Co-Pilot.%20My%20email%20address%20is%20${customerInfo?.email}.%0D%0A%0D%0AThank%20you!%0D%0A%0D%0A`;
  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      actions={[
        {
          variant: 'contained',
          caption: 'Contact Us',
          callback: () => {
            window.open(
              `mailto:support@oneconcern.com?cc=cs%40oneconcern.com&subject=${subject}&body=${body}`,
            );
            sendAmplitudeData(AMP_DEMO_COLLECT_CUSTOMER_EMAIL, {
              userId,
              customerEmail: customerInfo?.email,
              locationType: customerInfo?.locationType,
            });
          },
        },
      ]}
    >
      Searching for locations is an exclusive feature for subscribers. Contact us to join our
      platform.
    </ConfirmDialog>
  );
};

export default SubscriberBlockDialog;
