import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Diff, DiffList } from './thresholdsHelpers';

const useStyles = makeStyles(() => ({
  pickerListItemText: {
    marginBottom: 0,
  },
  pickerListItemTextMultiline: {
    margin: 0,
  },
}));

const ThresholdsDiffList: React.FC<{ thresholdDiff: DiffList }> = ({ thresholdDiff }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  if (thresholdDiff.length === 0) {
    return null;
  }

  return (
    <List disablePadding data-test-id="ThresholdsDiffList">
      {thresholdDiff.map((threshold: Diff) => {
        return (
          <div key={threshold.key} data-test-id="ThresholdsDiffListItem">
            <ListItem disableGutters>
              <ListItemText
                primary={
                  <Typography variant="caption">
                    {t(`thresholds:${threshold.key}`).toUpperCase()}
                  </Typography>
                }
                secondary={
                  <Typography variant="body1" color="textSecondary">
                    {threshold.value}
                  </Typography>
                }
                classes={{
                  root: classes.pickerListItemText,
                  multiline: classes.pickerListItemTextMultiline,
                }}
              />
            </ListItem>
          </div>
        );
      })}
    </List>
  );
};
ThresholdsDiffList.displayName = 'ThresholdDiffList';
export default ThresholdsDiffList;
