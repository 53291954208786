import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { GRAY3, QUIET_BG } from '../../../util/productGlobals';
import {
  LocationFile,
  useGetLocationsFileDetailsQuery,
  useGetOriginalLocationsFileQuery,
} from '../../../__generated__/graphql';
import { App } from '../../../PlanningApp/AppConfig';
import ErrorReportDownloadLink from './ErrorReportDownloadLink';
import { getLocationsMatchStatuses } from './locationMatchHelpers';
import LocationsCsvStats from '../Modals/Settings/ManageLocations/LocationsCsvStats';
import LocationsMatchTable from './LocationsMatchTable';
import Spinner from '../../CommonComponents/Spinner/Spinner';

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    margin: theme.spacing(4, 0),
    backgroundColor: QUIET_BG,
  },
  fullView: {
    width: '100%',
    height: '100%',
  },
  noFileDesc: {
    color: GRAY3,
  },
}));

const LocationMgmtList: React.FC = () => {
  const { divider, fullView, noFileDesc } = useStyles();
  const { t } = useTranslation();

  const { data, error, loading } = useGetLocationsFileDetailsQuery();
  const {
    data: dataOriginalLocationsFile,
    error: errorOriginalLocationsFile,
    loading: loadingOriginalLocationsFile,
  } = useGetOriginalLocationsFileQuery();
  const locationFile = data?.user?.productSettings?.locationFile as LocationFile;
  const locRecords = dataOriginalLocationsFile?.user?.productSettings?.originalFile;

  const { locRecordsWithMatchStatus, totalCounts } = React.useMemo(() => {
    const matchStatuses = getLocationsMatchStatuses(locRecords);
    return matchStatuses;
  }, [locRecords]);

  const totalProcessed = locationFile?.totalRows ?? 0;
  const { totalAccepted, totalRejected, totalPending, totalWarnings } = totalCounts;

  if (error || errorOriginalLocationsFile) {
    App.error(`[LocationMgmtList] ${error.message ?? 'Error retrieving original location file'}`);
  }

  if (!data || loading) return <Spinner />;

  if (locationFile?.id === 'no-file') {
    return (
      <div className={`o-flex-center-container ${fullView}`} data-test-id="LocationMgmtList">
        <Typography variant="body1" className={noFileDesc} data-test-id="No-LocationsFile-Uploaded">
          {t('manageLocations:noFileUploaded')}
        </Typography>
      </div>
    );
  }

  return (
    <div className="u-margintop--32" data-test-id="LocationMgmtList">
      <LocationsCsvStats
        totalProcessed={totalProcessed}
        totalAccepted={totalAccepted}
        totalWarnings={totalWarnings}
        totalRejected={totalRejected}
        totalPending={totalPending}
      />
      <div className="o-flexfe-container">
        <ErrorReportDownloadLink />
      </div>
      <Divider className={divider} />

      {loadingOriginalLocationsFile ? (
        <Spinner />
      ) : (
        <LocationsMatchTable locRecords={locRecordsWithMatchStatus} />
      )}
    </div>
  );
};

LocationMgmtList.displayName = 'LocationMgmtList';
export default LocationMgmtList;
