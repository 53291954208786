import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { App } from '../../../../../PlanningApp/AppConfig';
import Spinner from '../../../../CommonComponents/Spinner/Spinner';
import DashboardContent from './DashboardContent';
import useGetLocationTypesAndFileId from '../../../../../Hooks/useGetLocationTypesAndFileId';
import { Typography } from '@mui/material';

const Dashboard = () => {
  const { values, loading, error } = useGetLocationTypesAndFileId();
  const { t } = useTranslation();

  if (error) {
    App.error(`[MaterialityDashboard] ${error.message}`);
  }

  if (loading) {
    return <Spinner />;
  }

  const { locTypes, fileId } = values ?? {};

  if (locTypes?.length > 0 && fileId !== 'no-file') {
    return (
      <div data-test-id="MaterialityDashboard" className="u-height--100">
        <DashboardContent locTypes={locTypes} />
      </div>
    );
  }

  if (locTypes?.length === 0 && fileId !== 'no-file') {
    return (
      <div data-test-id="MaterialityDashboard-Invalid">
        <div className="u-margintop--32">
          <Typography variant="body1">{t('materiality:invalidFile')}</Typography>
        </div>
      </div>
    );
  }

  return null;
};

export default Dashboard;
