import * as React from 'react';
import { Expression, Map } from 'mapbox-gl';
import { Layer, Source } from 'react-mapbox-gl';
import { OnecWindow } from 'onec-types';

import { LocFilter, useGetLocationsFilterQuery } from '../../../../__generated__/graphql';
import { ALL_LOCATIONS_SOURCE_ID } from '../../../../util/productGlobals';
import { App } from '../../../../PlanningApp/AppConfig';
import { LocationType } from '../../locationViewHelpers';
import { makeGeoJson } from './clusterHelpers';

export const createLocFilterExpression: (selectedLocFilter: LocFilter) => Expression = (
  selectedLocFilter,
) => {
  const locTypeExpr: Expression =
    selectedLocFilter.locTypes.length > 0
      ? ['in', ['get', 'type'], ['literal', [...selectedLocFilter.locTypes]]]
      : null;

  const locGroupExpr: Expression =
    selectedLocFilter.locGroups.length > 0
      ? ['in', ['get', 'group'], ['literal', [...selectedLocFilter.locGroups]]]
      : null;

  if (locTypeExpr && locGroupExpr) return ['any', locTypeExpr, locGroupExpr];
  if (locTypeExpr) return locTypeExpr;
  if (locGroupExpr) return locGroupExpr;
  return null;
};

const setMapFitBounds = () => {
  // When source is loaded, call fitBounds of map so will trigger onMoveEnd and
  // updateVisibleLocations
  const map: Map = (window as OnecWindow).reactMap;
  if (map) map.fitBounds(map.getBounds(), {}, { fitboundUpdate: true });
};

const InvisibleLocationsSourceFC: React.FC<{ allLocations: LocationType[] }> = ({
  allLocations,
}) => {
  const allLocationsGeoJson = React.useMemo(() => makeGeoJson(allLocations, 'id'), [allLocations]);

  App.debug('[InvisibleLocationsSource] renders');
  return (
    <Source
      id={ALL_LOCATIONS_SOURCE_ID}
      geoJsonSource={allLocationsGeoJson}
      onSourceLoaded={setMapFitBounds}
    />
  );
};
export const InvisibleLocationsSource = React.memo(InvisibleLocationsSourceFC);
InvisibleLocationsSource.displayName = 'InvisibleLocationsSource';

const InvisibleLocationsFC: React.FC<{ allLocations: LocationType[] }> = ({ allLocations }) => {
  App.debug('[InvisibleLocations] renders.  #locations: ', allLocations.length);
  const {
    data: {
      planView: { locFilter: selectedLocFilter },
    },
  } = useGetLocationsFilterQuery();
  const isInitialMount = React.useRef(true);
  const locFilterMapboxExpression = createLocFilterExpression(selectedLocFilter);

  React.useEffect(() => {
    // If location filter(type and group) is changed, call setMapFitBounds to update list view
    // avoid call at initial render because Map Source is not loaded
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setMapFitBounds();
    }
  }, [selectedLocFilter]);

  return (
    <>
      <InvisibleLocationsSource allLocations={allLocations} />
      <Layer
        id={ALL_LOCATIONS_SOURCE_ID}
        sourceId={ALL_LOCATIONS_SOURCE_ID}
        type="circle"
        filter={locFilterMapboxExpression}
        paint={{ 'circle-opacity': 0 }}
      />
    </>
  );
};
const InvisibleLocations = React.memo(InvisibleLocationsFC);
InvisibleLocations.displayName = 'InvisibleLocations';

export default InvisibleLocations;
