import * as React from 'react';
import { Map } from 'mapbox-gl';
import { OnecWindow } from 'onec-types';

import { CoordinateInput, useUpdatePreviousMapViewMutation } from '../__generated__/graphql';
import { App } from '../PlanningApp/AppConfig';

// TODO: Set default value as vulnerable locations
const useSetPreviousMapBounds = (): (() => void) => {
  const [updatePreviousMapView] = useUpdatePreviousMapViewMutation();
  return React.useCallback(() => {
    App.debug('[UseSetPreviousMapBounds] - saving map bounds to apollo client');
    const map: Map = (window as OnecWindow).reactMap;
    if (map) {
      const bounds: mapboxgl.LngLatBounds = map.getBounds();
      const southEast: CoordinateInput = {
        latitude: bounds.getSouthEast().lat,
        longitude: bounds.getSouthEast().lng,
      };
      const northWest: CoordinateInput = {
        latitude: bounds.getNorthWest().lat,
        longitude: bounds.getNorthWest().lng,
      };
      updatePreviousMapView({
        variables: { southEast, northWest },
      });
    }
  }, [updatePreviousMapView]);
};

export default useSetPreviousMapBounds;
