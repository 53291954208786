import { Address, EditAddressInput } from '../../__generated__/graphql';
import i18next from '../../PlanningApp/LocalizationClient';

export const JAPAN_KANJI = '日本';
export const POSTAL_MARK = '〒';
const BAN = '番';
const CHI = '地';
const CHOME = '丁目';
const GO = '号';
const SPACE = '[\\s\u3000]';
const HYPHEN = '(?:−|－|-)';
const SPACE_CHAR = ' ';

const POSTCODE_REGEX = `^${SPACE}*(?:${POSTAL_MARK}?(?<postcode>[0-9]{3}${HYPHEN}[0-9]{4}))`;
const COUNTRY_REGEX = `(?:${JAPAN_KANJI},?)`;

type StringtoNumDict = { [key: string]: number | string };
const jpCharToNumDict: StringtoNumDict = {
  // These map the Japanese characters for numbers to the actual number.
  '０': 0,
  '１': 1,
  '２': 2,
  '３': 3,
  '４': 4,
  '５': 5,
  '６': 6,
  '７': 7,
  '８': 8,
  '９': 9,
  '−': '-',
  '－': '-',
  '-': '-',
};
// These map the numbers to the Japanese characters for that number.
export const numToJpCharDict: string[] = [
  '０',
  '１',
  '２',
  '３',
  '４',
  '５',
  '６',
  '７',
  '８',
  '９',
];

export const isJapaneseAddressStr: (addrStr: string) => boolean = (addrStr) => {
  const includesJapaneseChars: (addressStr: string) => boolean = (addressStr) => {
    const jpCharsRegEx =
      /[\u2212\uff0d\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff10-\uff19\uff66-\uff9f]/;
    return !!jpCharsRegEx.exec(addressStr);
  };
  return (
    addrStr.includes(JAPAN_KANJI) || addrStr.includes(POSTAL_MARK) || includesJapaneseChars(addrStr)
  );
};

export function convertJpNumCharstoNumbers(text: string): string {
  const letters = text?.split('') ?? [];
  const newText = letters
    .map((c) => {
      if (c in jpCharToNumDict) {
        return jpCharToNumDict[c];
      }
      return c;
    })
    .join('');
  return newText;
}

export const removeCountry = (addr: string) => {
  const noCountryRegEx = new RegExp(
    `^(?<beforeCountry>.*)${SPACE}+${COUNTRY_REGEX}${SPACE}+(?<afterCountry>.*)`,
  );
  const res = noCountryRegEx.exec(addr)?.groups;
  if (res) {
    const { beforeCountry, afterCountry } = res;
    return `${beforeCountry} ${afterCountry}`;
  }
  return addr;
};

export const removePostcodeAndCountry = (formattedAddress: string) => {
  // handle addresses of format "〒545-0052 日本, 大阪府大阪市阿倍野区阿倍野筋1丁目2番34号",
  // and "545-0052 大阪府大阪市阿倍野区阿倍野筋1丁目2番34号"
  // and "大阪府大阪市阿倍野区阿倍野筋1丁目2番34号", and trims all leading/trailing whitespace

  const noCountryAddr = removeCountry(formattedAddress);
  const removePostCodeRegExp = new RegExp(`(${POSTCODE_REGEX}${SPACE}+)?(?<addr>[^\\d\\s]+.*)`);

  const res = removePostCodeRegExp.exec(noCountryAddr)?.groups;
  return {
    postcode: res?.postcode?.trim(),
    addr: res?.addr?.trim() ?? noCountryAddr?.trim(),
  };
};

export function compareStreetAddressesJP(address1: string, address2: string): boolean {
  /* 
    In Japanese, the -chome (district number), -ban (block), and -go (building number) are part of  
    the "street" address.  These pieces can be formatted and joined together in several different 
    ways which are all equivalent. So we need to normalize both addresses's -chome, -ban, 
    and -go to the same format in order to compare two addresses. 
    (https://www.myjapan.careers/blog/how-to-read-and-write-a-japanese-address)
    
    For example, these are all equivalent representations of an address's -chome, -ban, and -go:
      みやぎ台６丁目３番２号
      みやぎ台6-chome 3-ban 2-go   (we won't support this one because it uses english chars)
      みやぎ台６丁目３ー２
      みやぎ台６ー３ー２
      みやぎ台6-3-2

      Also, we ignore postal codes and everything after the "go" portion of the address 
      (such as building name and suite numbers) when determining if street addresses are the same.

      TODO: Is this valid?  みやぎ台６丁目３番２   (no 'go' char at end, but uses 'ban' char)
    Note that we also have to handle equivalence of Japanese Numeric Characters and standard ASCII
    Number Characters.
  */
  const parsedAddr1 = parseJpStreetAddress(address1);
  const parsedAddr2 = parseJpStreetAddress(address2);
  return (
    parsedAddr1.beforeChome === parsedAddr2.beforeChome &&
    parsedAddr1.chome === parsedAddr2.chome &&
    parsedAddr1.ban === parsedAddr2.ban &&
    parsedAddr1.go === parsedAddr2.go
  );
}

type ParseResult = {
  postcode: string;
  beforeChome: string;
  chome: string;
  ban: string;
  go: string;
  afterGo: string;
};

function parseJpStreetAddress(address: string): ParseResult {
  const cleanedAddr = convertJpNumCharstoNumbers(address);
  const { postcode, addr } = removePostcodeAndCountry(cleanedAddr);
  let parsedResult: ParseResult = {
    postcode,
    beforeChome: '',
    chome: '',
    ban: '',
    go: '',
    afterGo: '',
  };

  // Look for first hyphen or CHOME CHARACTERS
  const chomeRe = new RegExp(`(\\d+)(?:(?:${CHOME})|${HYPHEN})`);

  const chomeStartIdx = addr.search(chomeRe);
  if (chomeStartIdx === -1) {
    // No Chome found, so set parsedResult.beforeChome to just the original string
    return { ...parsedResult, beforeChome: addr };
  }
  const beforeChome = addr.slice(0, chomeStartIdx);
  const chomeExpr = addr.match(chomeRe);
  const chome = chomeExpr?.[chomeExpr.length - 1];
  const afterChome = addr.slice(chomeStartIdx + chomeExpr[0].length, addr.length);
  parsedResult = { ...parsedResult, beforeChome, chome };

  // Look for the "BAN" and "GO" parts of the address
  if (afterChome && afterChome.length > 0) {
    // We don't look at any characters after the -go (house number) when testing if two
    // addresses are the same (we ignore aptartment/room numbers, building names, etc)
    const banMatcherResult = new RegExp(
      `^(?<ban>\\d+)(?:(?:${BAN}${CHI}?)|${HYPHEN})?(?:(?<go>\\d+)(?:${GO}?))?`,
    ).exec(afterChome);

    if (banMatcherResult) {
      const { ban, go } = banMatcherResult.groups;
      parsedResult = { ...parsedResult, ban, go };
    }
  }
  return parsedResult;
}

export const normalizeAddrJP: (addressStr: string) => string[] = (addressStr) => {
  if (!addressStr) {
    return [null, null];
  }

  const cleanedRes = removePostcodeAndCountry(addressStr);
  const postcode = cleanedRes?.postcode;
  const parsedAddr = parseJpStreetAddress(cleanedRes.addr);

  const { beforeChome, chome, ban, go } = parsedAddr as ParseResult;
  const line1 = postcode ? `${POSTAL_MARK}${postcode}` : undefined;
  const line2 = `${beforeChome ?? ''}${chome ?? ''}${ban ? '-' : ''}${ban ?? ''}${go ? '-' : ''}${
    go ?? ''
  }`;
  return [line1, line2];
};

export const fixBadAddressStrJP: (badAddressStr: string) => {
  line1: string;
  line2: string;
  oneLine: string;
} = (badAddressStr) => {
  const { postcode, addr: line2 } = removePostcodeAndCountry(badAddressStr);
  const line1 = postcode ? `${POSTAL_MARK}${postcode}` : undefined;
  const oneLine = `${line1 ?? ''}${line1 ? SPACE_CHAR : ''}${line2}`;
  return { line1, line2, oneLine };
};

export const getDisplayableAddressJP: (address: Address | EditAddressInput) => string[] = (
  address,
) => {
  const { houseNumber, postCode, stateCode, state, city, street } = address;
  const line1 = `${postCode ? POSTAL_MARK : ''}${postCode ?? ''}`.trim();

  // Hack to work around recent API changes where prefecture isn't consistently returned in
  // state vs stateCode, depending on query/mutation
  const prefecture = state || stateCode;
  const l2 = `${prefecture ?? ''}${city ?? ''}${street ?? ''}${houseNumber ?? ''}`;
  const [, line2] = normalizeAddrJP(l2);

  return [line1 || null, line2 || null];
};

export function getOneLineAddressJP(address: Address | EditAddressInput): string {
  const [line1, line2] = getDisplayableAddressJP(address);

  if (!line1 && !line2) {
    return i18next.t('address:unknownAddress');
  }
  return `${line1 ?? ''}${line1 && line2 ? SPACE_CHAR : ''}${line2 ?? ''}`;
}

export function getStreetWithNumJP(street: string, houseNumber: string) {
  return `${street ?? ''}${houseNumber ?? ''}`;
}
