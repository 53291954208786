import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import { Theme, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

import { BLACK, BLURRY, WHITE } from '../../../../util/productGlobals';
import { useUpdateHistoricalSeverityMutation } from '../../../../__generated__/graphql';

const useToggleButtonGroupStyles = makeStyles((theme: Theme) => ({
  buttonGroupRoot: {
    borderRadius: 100,
    minWidth: 140,
    background: theme.palette.glass.main,
    backdropFilter: BLURRY,
    '& > button:not(:last-child)': {
      borderRadius: 100,
    },
    '& > button:not(:first-child)': {
      borderRadius: 100,
      marginLeft: 0,
    },
  },
  buttonGroupGroupedHorizontal: {
    border: 'none',
    height: 30,
    width: '50%',
  },
  toggleButtonRoot: {
    color: 'white',
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      // The UI Kit is inconsistent about whether the label should be white or black
      // Picking Black because it provides more contrast & better accessibility.
      color: BLACK,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: BLACK,
      },
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '&.Mui-focusVisible': {
      boxShadow: `0 0 4px 2px ${WHITE}`,
    },
  },
}));

type HistoricalSeverityProps = {
  isHistoricalSeverityWorse: boolean;
};

type HistoricalSeverityControlProps = HistoricalSeverityProps & {
  onChangeSeverity: (isWorse: boolean) => void;
};

const HistoricalSeverityControlFC: React.FC<HistoricalSeverityControlProps> = ({
  isHistoricalSeverityWorse,
  onChangeSeverity,
}) => {
  const { t } = useTranslation();
  const classes = useToggleButtonGroupStyles();

  const onClickToggle = React.useCallback(
    (e: React.MouseEvent<HTMLElement>, value: string) => {
      onChangeSeverity(value !== 'actual');
    },
    [onChangeSeverity],
  );

  const translatedActual = t('buttons:intensityActual');
  const translatedWorse = t('buttons:intensityWorse');
  const buttonSize = 'small';
  return (
    <div
      className="o-flexrow-container o-flex-vcenter-container u-marginright--10"
      data-test-id="historical-severity"
    >
      <div className="u-marginright--10">
        <Typography variant="caption" data-test-id="historical-severity-label" color="textPrimary">
          {t('intensity')}
        </Typography>
      </div>
      <ToggleButtonGroup
        data-test-id="historical-severity-toggle"
        onChange={onClickToggle}
        value={isHistoricalSeverityWorse ? 'worse' : 'actual'}
        size={buttonSize}
        exclusive
        classes={{
          root: classes.buttonGroupRoot,
          groupedHorizontal: classes.buttonGroupGroupedHorizontal,
        }}
      >
        <ToggleButton
          data-test-id="historical-severity-actual"
          value="actual"
          aria-label={translatedActual}
          size={buttonSize}
          classes={{ root: classes.toggleButtonRoot }}
        >
          <Typography variant="caption">{translatedActual}</Typography>
        </ToggleButton>
        <ToggleButton
          data-test-id="historical-severity-worse"
          value="worse"
          aria-label={translatedWorse}
          size={buttonSize}
          classes={{ root: classes.toggleButtonRoot }}
        >
          <Typography variant="caption">{translatedWorse}</Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};
export const HistoricalSeverityControl = React.memo(HistoricalSeverityControlFC);
HistoricalSeverityControl.displayName = 'HistoricalSeverityControl';

const HistoricalSeverity: React.FC<HistoricalSeverityProps> = ({ isHistoricalSeverityWorse }) => {
  const [updateMutation] = useUpdateHistoricalSeverityMutation();

  const onChangeSeverity = React.useCallback(
    (isWorse: boolean) => {
      updateMutation({
        variables: {
          isHistoricalSeverityWorse: isWorse,
        },
      });
    },
    [updateMutation],
  );

  return (
    <HistoricalSeverityControl
      isHistoricalSeverityWorse={isHistoricalSeverityWorse}
      onChangeSeverity={onChangeSeverity}
    />
  );
};
HistoricalSeverity.displayName = 'HistoricalSeverity';
export default HistoricalSeverity;
