import * as React from 'react';
import { useGetMaterialitySettingsQuery } from '../../../../../__generated__/graphql';
import { App } from '../../../../../PlanningApp/AppConfig';
import Spinner from '../../../../CommonComponents/Spinner/Spinner';
import LocTypesAnnualRevenuesForm from './LocTypesAnnualRevenuesForm';
import MaterialityDriversContainer from './MaterialityDriversContainer';

type LossTableProps = {
  locTypes: string[];
};

const DashboardContent: React.FC<LossTableProps> = ({ locTypes }) => {
  const { data, loading, error } = useGetMaterialitySettingsQuery();

  if (error) {
    App.error(`[DashboardContent] - GetMaterialitySettings error: `, error);
  }

  if (loading) {
    return <Spinner />;
  }

  if (!data?.getMaterialitySettings?.locationTypeMaterialitySettings?.length) {
    return <LocTypesAnnualRevenuesForm locTypes={locTypes} />;
  }

  return <MaterialityDriversContainer />;
};

export default DashboardContent;
