import * as React from 'react';

import { Card, Switch, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  BLUEGRAY,
  BLURRY,
  DARKBLUE2,
  GLASS_BG,
  GLASS_BG_LIGHT,
  WHITE,
} from '../../../../util/productGlobals';
import { hexToRgba } from '../../../../util/utils';

const HALO_OFF_COLOR = '#B2B5C1';
const HALO_HOVER_OPACITY = 0.1;
const HALO_FOCUS_OPACITY = 0.2;
const HALO_BOX_SHADOW = '0px 3px 8px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.16)';

type OptionType = { label: string; value: boolean };

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: 'inline-block',
    padding: `0px ${theme.spacing(4)}`,
    marginRight: theme.spacing(2),
    color: WHITE,
    backgroundColor: GLASS_BG,
    backdropFilter: BLURRY,
    borderRadius: theme.spacing(1),
    height: 36,
    '&:hover': {
      backgroundColor: GLASS_BG_LIGHT,
      backdropFilter: BLURRY,
    },
  },
  cardDisabled: {
    // In Chrome, using opacity for the card disables the backdrop filter glass effect,
    // so we need to instead change the opacity of each sub-element and pick a GLASS_BG
    // color that has a higher opacity
    backgroundColor: 'rgba(2, 12, 17, 0.1)',
    '&:hover': {
      backgroundColor: 'rgba(2, 12, 17, 0.1)',
    },
    '& .MuiTypography-caption': {
      opacity: 0.3,
    },
    '& .MuiSwitch-root': {
      opacity: 0.3,
    },
  },
  switchRoot: {
    width: 50,
    padding: `3px ${theme.spacing(2)}`,
  },
  switchSizeSmall: {
    paddingRight: theme.spacing(2.5),
    height: 36,
    '& .MuiSwitch-switchBase': {
      height: 30,
      width: 30,
      margin: '3px 0',
      padding: 0,

      '&.Mui-checked': {
        transform: 'translateX(20px)',
      },
    },
    '& .MuiSwitch-thumb': {
      width: 18,
      height: 18,
    },
  },
  switchTrack: {
    border: `1px solid ${DARKBLUE2}`,
    borderRadius: 500,
    minWidth: 34,
    backgroundColor: BLUEGRAY,
    opacity: 1,
    height: 8,
    margin: '11px 0',
  },
  switchBase: {
    // This is the halo around the thumb
    '&:hover': {
      boxShadow: HALO_BOX_SHADOW,
      backgroundColor: hexToRgba(HALO_OFF_COLOR, HALO_HOVER_OPACITY),
    },
    '&.Mui-focusVisible': {
      boxShadow: HALO_BOX_SHADOW,
    },
    '&:.Mui-disabled': {
      opacity: 1,
    },
  },
  switchColorPrimary: {
    '& .MuiSwitch-thumb': {
      backgroundColor: WHITE, // switch is off
    },
    '&.MuiIconButton-root:hover': {
      backgroundColor: hexToRgba(HALO_OFF_COLOR, HALO_HOVER_OPACITY), //  halo around thumb
    },
    '&.Mui-focusVisible': {
      backgroundColor: hexToRgba(HALO_OFF_COLOR, HALO_FOCUS_OPACITY), //  halo around thumb
    },
    '&.Mui-checked': {
      // switch is on
      '&:hover': {
        backgroundColor: hexToRgba(theme.palette.primary.main, HALO_HOVER_OPACITY),
      },
      '&.Mui-focusVisible': {
        backgroundColor: hexToRgba(theme.palette.primary.main, HALO_FOCUS_OPACITY), //  halo around thumb
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.primary.main,
      },
      '& .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 0.3,
      },
    },
    '&.Mui-disabled': {
      '& + .MuiSwitch-track': {
        opacity: 0.5,
        backgroundColor: WHITE,
      },
      '&.Mui-checked': {
        '& + .MuiSwitch-track': {
          opacity: 0.5,
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  },
}));

interface StyledSwitchProps {
  label?: string;
  options: Array<OptionType>;
  selected?: boolean;
  dataTestId?: string;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
}

const StyledSwitchFC: React.FC<StyledSwitchProps> = ({
  label = '',
  options = [],
  selected = false,
  dataTestId,
  onChange,
  disabled = false,
}) => {
  const classes = useStyles();

  const onToggleChange = React.useCallback(() => {
    let unselectedOption: OptionType;
    options.some((opt) => {
      if (opt.value !== selected) {
        unselectedOption = opt;
        return true;
      }
      return false;
    });
    if (!unselectedOption) return;
    const newValue = unselectedOption.value;
    if (onChange) {
      onChange(newValue);
    }
  }, [selected, onChange, options]);

  const getSelectedLabel = React.useCallback(
    (selectedValue: boolean) => {
      const selectedOptions = options.filter((opt) => opt.value === selectedValue);
      if (!selectedOptions.length) {
        return null;
      }
      return selectedOptions[0].label;
    },
    [options],
  );

  return (
    <Card
      data-test-id={dataTestId}
      className={`${classes.card} ${disabled ? classes.cardDisabled : ''}`}
    >
      <Typography variant="caption" data-test-id={`${dataTestId}-title`}>
        {label}
      </Typography>
      <Switch
        color="primary"
        size="small"
        checked={selected}
        disableRipple
        onChange={onToggleChange}
        classes={{
          root: classes.switchRoot,
          switchBase: classes.switchBase,
          colorPrimary: classes.switchColorPrimary,
          track: classes.switchTrack,
          sizeSmall: classes.switchSizeSmall,
        }}
        disabled={disabled}
        data-test-id={`${dataTestId}-switch`}
      />
      <Typography variant="caption" data-test-id={`${dataTestId}-label`}>
        {getSelectedLabel(selected)}
      </Typography>
    </Card>
  );
};

const StyledSwitch = React.memo(StyledSwitchFC);
StyledSwitch.displayName = 'StyledSwitch';
export default StyledSwitch;
