import { DEFAULT_SLICE_IDX, VALID_RETURN_PERIODS } from '../../util/productGlobals';
import {
  ForecastPeriod,
  ForecastPeriodType,
  GetLocationsWithFilterQuery,
  LocFilter,
  PlanFilters,
  Slice,
} from '../../__generated__/graphql';
import {
  isForecastPeriodMatch,
  isHistoricalSliceMatch,
  isLegalSlice,
  isSimulatedSliceMatch,
} from '../../util/sliceFilterUtils';
import { App } from '../../PlanningApp/AppConfig';

export type LocationType = GetLocationsWithFilterQuery['locations'][0];
export type LocationWithScore = LocationType & { vulnerabilityScore: number };

export const getActiveSliceIdx: (slices: Slice[], filters: PlanFilters) => number = (
  slices,
  filters,
) => {
  const matchingSlice = (slices ?? []).find(
    (slice) =>
      isLegalSlice(slice) &&
      (filters.historicalId
        ? isHistoricalSliceMatch(slice, filters)
        : isForecastPeriodMatch(slice, filters) && isSimulatedSliceMatch(slice, filters)),
  );

  if (matchingSlice) {
    App.debug('[getActiveSliceIdx] matchingSlice: ', matchingSlice);
    return matchingSlice.slice;
  }

  App.error(
    `[getActiveSliceIdx] - No legal slice matches user filters. Using default slice num ${DEFAULT_SLICE_IDX}`,
  );

  return DEFAULT_SLICE_IDX;
};

export const filterScoredLocationsByGroupType = (
  locs: LocationWithScore[],
  selectedFilter: LocFilter,
): LocationWithScore[] => {
  if (!locs || locs.length === 0) {
    return [];
  }
  const { locTypes, locGroups } = selectedFilter;
  const filteredLocs = locs.filter(
    (loc) =>
      (locGroups.length === 0 && locTypes.length === 0) ||
      (locGroups.length > 0 && loc?.group && locGroups.includes(loc.group)) ||
      (locTypes.length > 0 && loc?.type && locTypes.includes(loc.type)),
  );
  return filteredLocs;
};

export const getFirstToFailSliceIds: (planFilters: PlanFilters, sliceDefs: Slice[]) => number[] = (
  planFilters,
  sliceDefs,
) => {
  const matchingSliceNums: number[] = VALID_RETURN_PERIODS.map((returnDuration: number) => {
    const sliceFilter = {
      ...planFilters,
      forecastPeriod: {
        forecastPeriodType: ForecastPeriodType.ReturnPeriod,
        duration: returnDuration,
      },
    };
    const matchingSliceNum = getActiveSliceIdx(sliceDefs, sliceFilter);
    return matchingSliceNum;
  });
  return matchingSliceNums;
};

export const sliceDefToPlanFilter: (sliceDef: Slice) => PlanFilters = (sliceDef) => {
  const forecastPeriod: ForecastPeriod = {
    forecastPeriodType: sliceDef.planningHorizon
      ? ForecastPeriodType.PlanningHorizon
      : ForecastPeriodType.ReturnPeriod,
    duration: sliceDef.planningHorizon || sliceDef.returnPeriod,
  };
  if (sliceDef.historicalEventID) {
    return {
      hazardType: null,
      forecastPeriod,
      isClimateChangeEnabled: null,
      historicalId: sliceDef.historicalEventID,
      isHistoricalSeverityWorse: sliceDef.severityIndex > 1,
    };
  }
  const historical = sliceDef.historicalEventID;
  return {
    hazardType: !historical && sliceDef.hazardType,
    forecastPeriod,
    isClimateChangeEnabled: historical ? null : sliceDef.climate > 1,
    historicalId: sliceDef.historicalEventID,
    isHistoricalSeverityWorse: historical && sliceDef.severityIndex > 1,
  } as PlanFilters;
};
