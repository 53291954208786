import * as React from 'react';
import { ORANGE } from '../../../util/productGlobals';

/**
 * Used to indicate that a component is in a loading state.
 */

type SpinnerProps = {
  size?: string;
  color?: string;
  speed?: string;
  gap?: 1 | 2 | 3 | 4 | 5;
  thickness?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  parentId?: string;
  style?: React.CSSProperties;
};

const Spinner: React.FC<SpinnerProps> = ({
  speed = '1100ms',
  size = '60px',
  color = ORANGE,
  gap = 3,
  thickness = 4,
  parentId,
  style,
}) => (
  <div
    className="o-flex-center-container"
    data-test-id={`${parentId ? `${parentId}-` : ''}${Spinner.displayName}`}
    style={{ width: '100%', height: '100%', ...style }}
  >
    <svg
      height={size}
      width={size}
      style={{ animationDuration: speed }}
      className="c-spinner-svg"
      role="img"
      viewBox="0 0 50 50"
    >
      <style
        dangerouslySetInnerHTML={{
          __html: `
        .c-spinner-svg{
          transition-property: transform;
          animation: infspin ${speed} infinite linear;
        }
        @keyframes infspin {
          100% {
            transform: rotate(360deg)
          }
        }
      `,
        }}
      />
      <circle
        cx={25}
        cy={25}
        r={15 - thickness / 2}
        stroke={color}
        fill="none"
        strokeWidth={thickness}
        strokeDasharray={Math.PI * 2 * (11 - gap)}
        strokeLinecap="round"
      />
    </svg>
  </div>
);

Spinner.displayName = 'Spinner';

export default Spinner;
