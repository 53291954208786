import { CONTINENTAL_USA_MAX_EXTENTS, DEFAULT_SLICE_IDX } from '../../../util/productGlobals';
import { ForecastPeriodType, SliceHazardType } from '../../../__generated__/graphql';
import { planViewMutations } from '../resolvers/PlanViewResolver';

export const STORE_DEFAULTS = {
  planView: {
    planFilters: {
      forecastPeriod: {
        forecastPeriodType: ForecastPeriodType.PlanningHorizon,
        duration: 5,
        financeDefaultPH: 1,
        financeRP1: 100,
        financeRP2: 250,
      },
      hazardType: SliceHazardType.Flood,
      historicalId: null as string,
      isHistoricalSeverityWorse: false,
      isClimateChangeEnabled: false,
    },
    locFilter: {
      locTypes: [] as string[],
      locGroups: [] as string[],
    },
    sliceIdx: DEFAULT_SLICE_IDX,
    __typename: 'PlanView',
  },
  hoveredLocation: {
    hoveredLocationId: null as string,
  },
  mapMoved: {
    state: false,
  },
  previousMapView: {
    northWest: {
      latitude: CONTINENTAL_USA_MAX_EXTENTS[3],
      longitude: CONTINENTAL_USA_MAX_EXTENTS[0],
      __typename: 'Coordinate',
    },
    southEast: {
      latitude: CONTINENTAL_USA_MAX_EXTENTS[1],
      longitude: CONTINENTAL_USA_MAX_EXTENTS[2],
      __typename: 'Coordinate',
    },
    __typename: 'MapView',
  },
  oldMapBounds: {
    northWest: {
      latitude: CONTINENTAL_USA_MAX_EXTENTS[3],
      longitude: CONTINENTAL_USA_MAX_EXTENTS[0],
      __typename: 'Coordinate',
    },
    southEast: {
      latitude: CONTINENTAL_USA_MAX_EXTENTS[1],
      longitude: CONTINENTAL_USA_MAX_EXTENTS[2],
      __typename: 'Coordinate',
    },
    __typename: 'MapView',
  },
};

export const planViewStore = {
  defaults: STORE_DEFAULTS,
  mutations: planViewMutations,
};
