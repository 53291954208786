export const AMP_EVENT_APPLY_HAZARD = 'apply hazard';
export const AMP_EVENT_CHANGE_FORECAST_PERIOD_TYPE = 'change forecast period type';
export const AMP_EVENT_CHANGE_HAZARD = 'change hazard';
export const AMP_EVENT_CHANGE_THRESHOLDS = 'change thresholds';
export const AMP_EVENT_CLIMATE_TOGGLE = 'climatetoggle';
export const AMP_EVENT_DELETE_LOCATIONS = 'delete locations';
export const AMP_EVENT_DISABLE_USER = 'disableuser';
export const AMP_EVENT_EDIT_LOCATION = 'edit location';
export const AMP_EVENT_END_SESSION = 'complete session';
export const AMP_EVENT_EXPORT_LOCATIONS = 'export locations';
export const AMP_EVENT_EXPORT_LOCATIONS_ERROR_REPORT_TYPE =
  'Download error report for uploaded locations';
export const AMP_EVENT_EXPORT_VULNERABLE_LOCATIONS_TYPE = 'Download at-risk locations';
export const AMP_EVENT_LOGIN = 'login';
export const AMP_EVENT_LOOKUP_LOCATION = 'lookuplocation';
export const AMP_EVENT_REQUEST_LOCATION = 'request location';
export const AMP_EVENT_SAVE_MATCH = 'save match';
export const AMP_EVENT_SEARCH_LOCATION = 'search location';
export const AMP_EVENT_SEARCH_LOCATION_MAPBOX = 'map geocoder';
export const AMP_EVENT_SEARCH_LOCATION_LOCATION = 'own location';
export const AMP_EVENT_UPLOAD_LOCATIONS = 'upload locations';
export const AMP_EVENT_VIEW_DETAILS = 'view details';
export const AMP_EVENT_VIEWER_SHARE_LOCATION = 'viewer share location';
export const AMP_EVENT_VIEWER_OPEN_LOCATION = 'viewer open location';
export const AMP_EVENT_ROI_ANALYSIS_REQUEST = 'roi analysis request';
export const AMP_EVENT_FINANCE_LOOKUP_LOCATION = 'finance lookup location';
export const AMP_EVENT_FINANCE_SEARCH_LOCATION = 'finance search location';
export const AMP_EVENT_FINANCE_EXPAND_LOCATION_BY_LIFELINE = 'finance expand location by lifeline';
export const AMP_DEMO_COLLECT_CUSTOMER_INFO = 'demo customer info';
export const AMP_DEMO_COLLECT_CUSTOMER_EMAIL = 'demo customer sent email';
export const AMP_DEMO_ACCEPT_TERMS = 'demo customer accept terms';
export const AMP_FINANCE_DEMO_INTEREST_MANAGE_LOCATIONS = 'finance demo interest manage locations';
export const AMP_FINANCE_DEMO_INTEREST_CHANGE_FINANCE_DRIVER_SETTINGS =
  'finance demo interest change finance driver settings';
export const AMP_FINANCE_ADD_COMPANY = 'finance add company';
export const AMP_FINANCE_DELETE_COMPANY = 'finance delete company';
export const AMP_FINANCE_QUOTA_EXCEEDED = 'finance free dna api quota exceeded';
export const AMP_FINANCE_LIMIT_STOCKS = 'finance ask more stocks';
