import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import {
  AMP_EVENT_FINANCE_LOOKUP_LOCATION,
  AMP_EVENT_LOOKUP_LOCATION,
} from '../../../plugins/amplitudeevents';
import { App } from '../../../PlanningApp/AppConfig';
import { sendAmplitudeData } from '../../../plugins/amplitude';
import { useGetLocationQuery } from '../../../__generated__/graphql';

const SendLocationDataAnalytics: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { pathname }: { pathname: string } = useLocation();
  const { data, loading, error } = useGetLocationQuery({ variables: { id } });
  if (App.config.features.enableamplitude) {
    const isFinanceView = pathname?.includes('/finance');
    const ampEvent = isFinanceView ? AMP_EVENT_FINANCE_LOOKUP_LOCATION : AMP_EVENT_LOOKUP_LOCATION;
    if (error) {
      App.warn(`Amplitude event for ${ampEvent} not sent across`);
    }
    if (!loading && data) {
      const {
        // eslint-disable-next-line no-shadow
        location: { id, name, type, group, address },
      } = data;
      sendAmplitudeData(ampEvent, {
        type,
        group,
        address,
        locationid: id,
        locationName: name,
      });
    }
  }

  return null;
};

export default SendLocationDataAnalytics;
