import { NavigatorViewType } from '../../../__generated__/graphql';
import { navigatorInfoMutations } from '../resolvers/NavigatorInfoResolver';

export const STORE_DEFAULTS = {
  navigatorInfo: {
    currentView: NavigatorViewType.MapView,
    userEngagedTimes: 0,
    currentLifeline: '',
    __typename: 'NavigatorInfo',
  },
};

export const navigatorInfoStore = {
  defaults: STORE_DEFAULTS,
  mutations: navigatorInfoMutations,
};
