import * as React from 'react';
import { Layer, Source } from 'react-mapbox-gl';
import { GeoJsonFeature, useGetSimulationFormQuery } from '../../../__generated__/graphql';
import { REGION_COLOR_SPECTRUM } from '../../../util/productGlobals';

const SimulationRegionMapWrapper: React.FC = () => {
  const { data: simulationData } = useGetSimulationFormQuery();

  const createJSON = React.useMemo(() => {
    const features: GeoJsonFeature[][] = simulationData?.simulationForm?.simulationFormRegions.map(
      (region) => {
        const geojson: GeoJSON.FeatureCollection<GeoJSON.Geometry> = JSON.parse(region.region);
        geojson.features.forEach((feature) => {
          feature.properties = {
            id: region.id,
            priority: region.priority,
            color: REGION_COLOR_SPECTRUM[Number(region.id) % 12],
          };
        });
        return geojson.features as GeoJsonFeature[];
      },
    );
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [].concat(...features).reverse(),
      },
    };
  }, [simulationData?.simulationForm?.simulationFormRegions]);
  return (
    <>
      <Source
        id="simulation-regions"
        geoJsonSource={{
          ...createJSON,
        }}
      />
      <Layer
        id="simulation-regions-layer"
        sourceId="simulation-regions"
        type="fill"
        paint={{
          'fill-color': ['get', 'color'],
          'fill-opacity': 0.5,
        }}
        before="water"
      />
    </>
  );
};

export default SimulationRegionMapWrapper;
