export type StatisticType =
  | 'geoCoordinate'
  | 'earthquakeDepth'
  | 'magnitude'
  | 'inundatedArea'
  | 'waterDepth'
  | 'velocity'
  | 'numPeople'
  | 'numBuildings'
  | 'rainfall';

export type StatPrecisionType = {
  maximumSignificantDigits?: number;
  maximumFractionDigits: number;
  minimumFractionDigits: number;
  doCustomRounding?: (val: number) => number;
};

export type SettingType = {
  labelKey: string;
  precision?: StatPrecisionType;
  imperial?: {
    precision: StatPrecisionType;
  };
  metric?: {
    precision: StatPrecisionType;
  };
  isInvalid?: (val: number) => boolean;
};

export type StatSettingsType = {
  // TODO: I really want this key to be a StatType, not string
  // [key: string]: {
  //   labelKey: string;
  //   precision?: StatPrecisionType;
  //   imperial?: {
  //     precision: StatPrecisionType;
  //   };
  //   metric?:{
  //     precision: StatPrecisionType;
  //   };
  // },
  [key: string]: SettingType;
};

type TopLevelPrecisionType = {
  labelKey: string;
  // precision: StatPrecisionType;
  precision: {
    maximumSignificantDigits: number;
    maximumFractionDigits: number;
    minimumFractionDigits: number;
    doCustomRounding?: (val: number) => number;
  };
};

type MeasureLevelPrecisionType = {
  labelKey: string;
  imperial: {
    // precision: StatPrecisionType;
    precision: {
      maximumSignificantDigits: number;
      maximumFractionDigits: number;
      minimumFractionDigits: number;
      doCustomRounding?: (val: number) => number;
    };
  };
  metric: {
    // precision: StatPrecisionType;
    precision: {
      maximumSignificantDigits?: number;
      maximumFractionDigits: number;
      minimumFractionDigits: number;
      doCustomRounding?: (val: number) => number;
    };
  };
};

export type StatSettingsType1 = {
  [key: string]: TopLevelPrecisionType & MeasureLevelPrecisionType;
};

export const StatSettings: StatSettingsType = {
  geoCoordinate: {
    labelKey: 'geoCoordinate',
    // In degrees and decimal minutes:
    // Latitude  -90.0000 to +90.0000
    // Longitude in -180.0000 to +180.0000
    precision: {
      maximumFractionDigits: 4,
      minimumFractionDigits: 4,
    },
    isInvalid: (val: number) => val < 180 || val > 180,
  },
  magnitude: {
    labelKey: 'earthquakeMagnitudeUnit',
    // Richter magnitude
    precision: {
      maximumSignificantDigits: 2,
      maximumFractionDigits: 1,
      minimumFractionDigits: 1,
    },
    isInvalid: (val: number) => val <= 0 || val >= 10,
  },
  earthquakeDepth: {
    labelKey: 'earthquakeDepthUnit',
    // always in kilometers, even in Imperial
    precision: {
      maximumSignificantDigits: 3,
      maximumFractionDigits: 1,
      minimumFractionDigits: 1,
    },
  },
  numPeople: {
    labelKey: 'numPeopleUnit',
    precision: {
      maximumSignificantDigits: 3,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      doCustomRounding: (val) => Math.ceil(val),
    },
  },
  numBuildings: {
    labelKey: 'numBuildingsUnit',
    precision: {
      maximumSignificantDigits: 3,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      doCustomRounding: (val) => Math.ceil(val),
    },
  },
  waterDepth: {
    labelKey: 'waterDepthUnit',
    metric: {
      // meters
      precision: {
        maximumSignificantDigits: 3,
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
      },
    },
    imperial: {
      // feet
      precision: {
        maximumSignificantDigits: 3,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      },
    },
  },
  velocity: {
    labelKey: 'velocityUnit',
    metric: {
      // meters
      precision: {
        maximumSignificantDigits: 3,
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
      },
    },
    imperial: {
      // feet
      precision: {
        maximumSignificantDigits: 3,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      },
    },
  },
  inundatedArea: {
    labelKey: 'inundatedAreaUnit',
    metric: {
      // sq kilometers
      precision: {
        maximumSignificantDigits: 3,
        maximumFractionDigits: 3,
        minimumFractionDigits: 1,
      },
    },
    imperial: {
      // acres
      precision: {
        maximumSignificantDigits: 3,
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
      },
    },
  },

  rainfall: {
    labelKey: 'rainfallUnit', // millimeters (mm)
    // For now, assume that rainfall is always in metric (mm/hr) even if user prefers imperial
    precision: {
      maximumSignificantDigits: 3,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    },
  },
};
