import * as React from 'react';
import { SnackbarKey, useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ReviewSnackbarButton: React.FC<{ id: SnackbarKey; href: string }> = ({ id, href }) => {
  const navigate = useNavigate();
  const { closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const onClickReview = React.useCallback(() => {
    navigate(href);
    closeSnackbar(id);
  }, [href, id, closeSnackbar, navigate]);

  return (
    <Button onClick={onClickReview} data-test-id="snackbar-review-button">
      {t('buttons:review')}
    </Button>
  );
};

ReviewSnackbarButton.displayName = 'ReviewSnackbarButton';
export default ReviewSnackbarButton;
