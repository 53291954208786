import * as React from 'react';
import { GeoJSONSourceRaw } from 'mapbox-gl';
import { Source } from 'react-mapbox-gl';

import { BUILT_OBJECT_SOURCE_ID, MAX_ZOOM } from '../../../../util/productGlobals';
import { BuiltObjectWithScore } from '../../../../Hooks/useGetBobjWithScore';

const emptySource = {
  type: 'geojson',
  data: {
    type: 'FeatureCollection',
    features: [],
  },
  maxzoom: MAX_ZOOM,
} as GeoJSONSourceRaw;

const BuiltObjectSource: React.FC<{ builtObject: BuiltObjectWithScore }> = ({ builtObject }) => {
  const [sourceData, setSourceData] = React.useState<GeoJSONSourceRaw>(emptySource);

  React.useEffect(() => {
    if (!builtObject) {
      // If we're showing this builtObject layer and then want to change to a different builtObject
      // location, we still keep the component's state (so we don't reinitialize it)
      // We need to NOT reinitialize it because then if we do & change slices, the map will
      // unexpectedly pan/zoom to center the selected builtObject, overriding how the user
      // positioned the map.
      setSourceData(emptySource);
    } else {
      setSourceData({
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: builtObject?.coordinates,
          },
          properties: {
            id: builtObject.id,
            vulnerable: builtObject?.vulnerabilityScore > 0,
          },
        },
      });
    }
  }, [builtObject]);

  return <Source id={BUILT_OBJECT_SOURCE_ID} geoJsonSource={sourceData} />;
};

BuiltObjectSource.displayName = 'BuiltObjectSource';
export default BuiltObjectSource;
