import distance from '@turf/distance';
import { point } from '@turf/helpers';

import { Address, EditAddressInput } from '../../__generated__/graphql';
import {
  compareStreetAddressesJP,
  fixBadAddressStrJP,
  getDisplayableAddressJP,
  getOneLineAddressJP,
  isJapaneseAddressStr,
  JAPAN_KANJI,
} from './addressUtilsJP';
import {
  compareStreetAddressesUS,
  fixBadAddressStrUS,
  getDisplayableAddressUS,
  getOneLineAddressUS,
  getStreetWithNum,
  isUnitedStatesAddressStr,
} from './addressUtilsUS';
import { App } from '../../PlanningApp/AppConfig';
import i18next from '../../PlanningApp/LocalizationClient';
import { MATCH_ADDRESS_DIST_LIMIT } from '../productGlobals';

type SupportedCountryCode = 'US' | 'JP' | 'UNKNOWN';

export const deriveCountryCode: (country: string) => SupportedCountryCode = (country) => {
  let addrCountryCode: SupportedCountryCode;
  if (['JAPAN', 'JP', JAPAN_KANJI].includes(country?.toUpperCase())) {
    addrCountryCode = 'JP';
  } else if (['UNITED STATES', 'US', 'USA'].includes(country?.toUpperCase())) {
    addrCountryCode = 'US';
  } else {
    if (country) {
      App.warn(`[deriveCountryCode] - unknown or unsupported country: ${country}.`);
    } else {
      App.warn('[deriveCountryCode] - country is null');
    }
    addrCountryCode = 'UNKNOWN';
  }
  return addrCountryCode;
};

export function fixBadAddressStr(
  badAddressStr: string,
  countryCode?: string,
): {
  line1: string;
  line2: string;
  oneLine: string;
} {
  // This function works around backend returning badly formatted or unlocalized addresses strings.

  // If the countryCode is provided, we use it.  If not, we attempt to parse the countryCode from
  // the badAddressStr;
  const addrCountryCode = countryCode?.toUpperCase();

  if (addrCountryCode === 'JP' || isJapaneseAddressStr(badAddressStr)) {
    return fixBadAddressStrJP(badAddressStr);
  }
  if (addrCountryCode === 'US' || isUnitedStatesAddressStr(badAddressStr)) {
    return fixBadAddressStrUS(badAddressStr);
  }
  App.warn('[addressUtils - fixBadAddressStr] - unknown country code.');
  return { line1: badAddressStr, line2: '', oneLine: badAddressStr };
}

export function getDisplayableAddress(address: Address): string[] {
  if (!address) return null;

  const addrCountryCode =
    address?.countryCode?.toUpperCase() || deriveCountryCode(address?.country);
  let line1 = '';
  let line2 = null;

  switch (addrCountryCode) {
    // We display addresses using the ordering/formatting conventions of the country within which
    // the address is located.  Don't just use the user's language.
    // If an English user looks at an address in Japan, that address should be displayed
    // and ordered  according to Japanese conventions.
    case 'JP': {
      [line1, line2] = getDisplayableAddressJP(address);
      break;
    }
    case 'US': {
      [line1, line2] = getDisplayableAddressUS(address);
      break;
    }

    default:
      App.warn(
        `[getDisplayableAddress]: Address in unsupported country (countryCode: '${addrCountryCode}', country: '${address?.country}')`,
      );
      return [i18next.t('address:unknownAddress')];
  }
  const result = [];
  if (line1) result.push(line1);
  if (line2) result.push(line2);
  return result;
}

export function getOneLineAddress(address: Address | EditAddressInput): string {
  const supportedCountryCode = address?.country ? deriveCountryCode(address.country) : 'N/A';

  switch (supportedCountryCode) {
    case 'US':
      return getOneLineAddressUS(address);
    case 'JP':
      return getOneLineAddressJP(address);
    default:
      App.warn(
        `[getOneLineAddress]: Address in unsupported country (countryCode: '${supportedCountryCode}')`,
      );
      return i18next.t('address:unknownAddress');
  }
}

export const isAddressMatch = (address: Address, addressString: string) => {
  if (!addressString) return true;
  switch (address?.countryCode) {
    case 'US': {
      const [aLine1] = getDisplayableAddress(address);
      return compareStreetAddressesUS(aLine1, addressString.trim());
    }
    case 'JP': {
      const { formattedAddress } = address;
      return compareStreetAddressesJP(formattedAddress, addressString.trim());
    }
    default:
      App.warn(
        `[isAddressMatch]: Address in unsupported country (countryCode: '${address?.countryCode}')`,
      );
      return false;
  }
};

export const isCoordinateMatch: (
  coords1: number[],
  coords2: number[],
  withinMeters?: number,
) => boolean = (coords1, coords2, withinMeters = MATCH_ADDRESS_DIST_LIMIT) => {
  const distanceBetween = distance(point(coords1), point(coords2), {
    units: 'meters',
  });

  return distanceBetween <= withinMeters;
};

export const addressToEditAddressInput: (address: Address) => EditAddressInput = (address) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { __typename, addressDetails, ...restAddrComponents } = address;
  const { city, country, countryCode, stateCode, state, postCode, houseNumber, street } =
    restAddrComponents;
  if (!city || !countryCode || !(stateCode || state)) {
    App.error('[addressToEditAddressInput] - address object missing required field');
  }

  const fixedCountryCode =
    countryCode?.toUpperCase() ?? (country ? deriveCountryCode(country) : '');

  const fixedNewBuildingAddress: EditAddressInput = {
    // The following is to get around the nearbyBuildings API potentially returning NULL for city,
    // countryCode, postCode, and stateCode, even though the API's editLocation mutation then
    // requires that these fields be non-null in the "address" parameter).
    ...restAddrComponents,
    houseNumber: null,
    // hacks to get around editAddress not accepting the houseNumber field and requiring
    // it be provided in the street field
    // if the street is not set, we use an empty string
    street: street ? getStreetWithNum(street, houseNumber, fixedCountryCode) : '',
    city: city ?? '',
    countryCode: fixedCountryCode,
    postCode: postCode ?? '',
    // Some hacks to get around inconsistencies in field where JP API returns prefecture.
    stateCode: fixedCountryCode === 'JP' ? stateCode ?? state ?? '' : stateCode ?? '',
    state: fixedCountryCode === 'JP' ? state ?? stateCode ?? '' : state ?? '',
  };
  return fixedNewBuildingAddress;
};
