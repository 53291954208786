import * as React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { BLURRY, GRAY3 } from '../../../util/productGlobals';

type GlassButtonProps = Omit<ButtonProps, 'variant' | 'color'>;

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      height: 36,
      background: theme.palette.glass.main,
      backdropFilter: BLURRY,
      color: theme.palette.glass.contrastText,
      marginRight: theme.spacing(2),
      '&:focus': {
        background: theme.palette.glass.main,
        color: theme.palette.glass.contrastText,
      },
      '&:hover': {
        background: theme.palette.glass.light,
      },
      '&:disabled': {
        background: theme.palette.glass.light,
        color: theme.palette.glass.contrastText,
      },
      small: {
        height: 28,
      },
      textTransform: 'unset',
      '&.Mui-focusVisible': {
        background: theme.palette.glass.light,
        boxShadow: `0 0 4px 1px ${GRAY3}`,
        color: theme.palette.glass.contrastText,
      },
    },
  };
});

const GlassButton = React.forwardRef(
  (props: GlassButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    const classes = useStyles();
    return (
      <Button variant="contained" classes={classes} ref={ref} {...props}>
        {props.children}
      </Button>
    );
  },
);

GlassButton.displayName = 'GlassButton';
export default GlassButton;
