import * as React from 'react';
import { Card, Grid, LinearProgress, Typography } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { ORANGE } from '../../../../../util/productGlobals';
import StyledSnackbarContent from '../../../../CommonComponents/Snackbar/StyledSnackbarContent';
import useGetMaterialityData from '../../../../../Hooks/useGetMaterialityData';

const useStyles = makeStyles(() => ({
  materialityNumber: {
    color: ORANGE,
  },
}));

const MaterialityKPIsContainer: React.FC = () => {
  const { materialityNumber } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { values, loading, error } = useGetMaterialityData();

  React.useEffect(() => {
    if (error) {
      enqueueSnackbar(<StyledSnackbarContent title={t('err:errorGeneral')} />, {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, t, error]);

  if (error) {
    return (
      <Grid
        container
        className="u-paddingtop--20"
        direction="column"
        data-test-id="MaterialityMetrics"
      >
        <Typography variant="h3" className="u-marginbottom--20">
          {t('materiality:kpis:title')}
        </Typography>
        <Card sx={{ flexGrow: 1 }} className="u-padding--20">
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="u-marginbottom--10">
                {t('materiality:kpis:meanLossLabel')}
              </Typography>
              <Typography variant="h1" color="textPrimary" className={materialityNumber}>
                {t('nA')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="u-marginbottom--10">
                {t('materiality:kpis:revenuePercentageLabel')}
              </Typography>
              <Typography variant="h1" color="textPrimary" className={materialityNumber}>
                {t('nA')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="u-marginbottom--10">
                {t('materiality:kpis:materialityPercentageLabel')}
              </Typography>
              <Typography variant="h1" color="textPrimary" className={materialityNumber}>
                {t('nA')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="u-marginbottom--10">
                {t('materiality:kpis:materialityAmountLabel')}
              </Typography>
              <Typography variant="h1" color="textPrimary" className={materialityNumber}>
                {t('nA')}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  }

  if (loading) {
    return (
      <Grid
        container
        className="u-paddingtop--20"
        direction="column"
        data-test-id="MaterialityMetrics"
      >
        <Typography variant="h3" className="u-marginbottom--20">
          {t('materiality:kpis:title')}
        </Typography>
        <Card sx={{ flexGrow: 1 }} className="u-padding--20">
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="u-marginbottom--10">
                {t('materiality:kpis:meanLossLabel')}
              </Typography>
              <LinearProgress />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="u-marginbottom--10">
                {t('materiality:kpis:revenuePercentageLabel')}
              </Typography>
              <LinearProgress />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="u-marginbottom--10">
                {t('materiality:kpis:materialityPercentageLabel')}
              </Typography>
              <LinearProgress />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="u-marginbottom--10">
                {t('materiality:kpis:materialityAmountLabel')}
              </Typography>
              <LinearProgress />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  }
  if (values) {
    return (
      <Grid
        container
        direction="column"
        className="u-paddingtop--20"
        data-test-id="MaterialityMetrics"
      >
        <Typography variant="h3" className="u-marginbottom--20">
          {t('materiality:kpis:title')}
        </Typography>
        <Card sx={{ flexGrow: 1 }} className="u-padding--20">
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="u-marginbottom--10">
                {t('materiality:kpis:meanLossLabel')}
              </Typography>
              <Typography
                variant="h1"
                color="textPrimary"
                className={materialityNumber}
                data-test-id="MaterialityKPIs:meanLoss"
              >
                {t('materiality:kpis:meanLoss', { val: values?.meanAnnualLoss })}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="u-marginbottom--10">
                {t('materiality:kpis:revenuePercentageLabel')}
              </Typography>
              <Typography
                variant="h1"
                color="textPrimary"
                className={materialityNumber}
                data-test-id="MaterialityKPIs:revenuePercentage"
              >
                {t('materiality:kpis:revenuePercentage', { val: values?.percentByRevenue })}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="u-marginbottom--10">
                {t('materiality:kpis:materialityPercentageLabel')}
              </Typography>
              <Typography
                variant="h1"
                color="textPrimary"
                className={materialityNumber}
                data-test-id="MaterialityKPIs:materialityPercentage"
              >
                {t('materiality:kpis:materialityPercentage', {
                  val: values?.overMaterialityPercent,
                })}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="u-marginbottom--10">
                {t('materiality:kpis:materialityAmountLabel')}
              </Typography>
              <Typography
                variant="h1"
                color="textPrimary"
                className={materialityNumber}
                data-test-id="MaterialityKPIs:overMateriality"
              >
                {t('materiality:kpis:materialityAmount', { val: values?.overMateriality })}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  }

  return (
    <Grid
      container
      className="u-paddingtop--20"
      direction="column"
      data-test-id="MaterialityMetrics"
    >
      <Typography variant="h3" className="u-marginbottom--20">
        {t('materiality:kpis:title')}
      </Typography>
      <Card sx={{ flexGrow: 1 }} className="u-padding--20" />
    </Grid>
  );
};

export default MaterialityKPIsContainer;
