import * as React from 'react';

import {
  Box,
  Button,
  Card,
  Grid,
  LinearProgress,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Theme,
  Typography,
} from '@mui/material';
import { ResilienceStats, useGetLocationsFileIdQuery } from '../../../../../__generated__/graphql';

import { CSVLink } from 'react-csv';
import { escapeDelimiter, getComparator, stableSort } from '../../../../../util/productUtils';
import { hoursToDayHourAbbrev } from '../../../../../util/time';
import { Lifeline, Order } from '../../../../../util/productEnums';
import makeStyles from '@mui/styles/makeStyles';
import { Link as RouterLink } from 'react-router-dom';
import useGetMaterialityData from '../../../../../Hooks/useGetMaterialityData';
import { useTranslation } from 'react-i18next';

interface Column {
  id: 'loss' | 'downtime' | 'lifeline' | 'locationAddress';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

type Data = {
  loss: number;
  downtime: number;
  lifeline: string;
  locationAddress: string;
  locationID: string;
};

function createData(
  loss: number,
  stats: ResilienceStats,
  lifeline: Lifeline,
  lifelineReal: string,
  locationAddress: string,
  locationID?: string,
): Data {
  let downtime = 0;
  switch (lifeline) {
    case Lifeline.AIRPORT:
      downtime = stats?.nearbyAirport?.mean;
      break;
    case Lifeline.BRIDGE:
      downtime = stats?.nearbyBridge.mean;
      break;
    case Lifeline.HIGHWAY:
      downtime = stats?.nearbyHighway.mean;
      break;
    case Lifeline.PEOPLE:
      downtime = stats?.nearbyPeople.mean;
      break;
    case Lifeline.PORT:
      downtime = stats?.nearbyPort.mean;
      break;
    case Lifeline.POWER:
      downtime = stats?.nearbyPower.mean;
      break;
    default:
      downtime = 0;
  }
  return { loss, downtime, lifeline: lifelineReal, locationAddress, locationID };
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(4),
    width: '100%',
    overflow: 'hidden',
    marginTop: '20px',
  },
  table: {
    paddingBottom: theme.spacing(2),
    width: '100%',
    '& .MuiTableCell-root': {
      border: '1px solid #979794',
      ...theme.typography.caption,
    },
    '& .MuiTableCell-head': {
      fontWeight: 800,
      ...theme.typography.body2,
    },
  },
  pagination: {
    '& .MuiToolbar-root': {
      background: 'transparent',
      backdropFilter: 'none',
      margin: 0,
    },
  },
  visuallyHidden: {
    border: '0px solid',
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: '-4px',
    overflow: 'hidden',
    padding: '0px',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px',
  },
}));

interface RevenueTableHeadProps {
  columns: readonly Column[];
  order: Order;
  orderBy: string;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
}

const RevenueTableHead: React.FC<RevenueTableHeadProps> = ({
  columns,
  order,
  orderBy,
  onRequestSort,
}) => {
  const { visuallyHidden } = useStyles();
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.id}
            align={column.align}
            sx={{ minWidth: column.minWidth }}
            sortDirection={orderBy === column.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : 'asc'}
              onClick={createSortHandler(column.id)}
              data-test-id={`RevenueTableHeadCellSortable-${column.id}`}
            >
              {column.label}
              {orderBy === column.id ? (
                <Box component="span" className={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const RevenueTableFC: React.FC = () => {
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<string>('loss');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { data, error } = useGetLocationsFileIdQuery();
  const { t } = useTranslation();
  const { container, pagination, table } = useStyles();

  const {
    lifelineLosses: losses,
    loading: isLoading,
    error: lossesError,
  } = useGetMaterialityData();

  const isError = lossesError || error;
  const name = data?.user?.productSettings?.locationFile?.name.split('.')[0];
  const filename = t('materiality:filename', {
    val: name || '_',
  });

  const columns: readonly Column[] = React.useMemo(
    () => [
      {
        id: 'locationAddress',
        label: t('materiality:revenueTable:location:label'),
        minWidth: 170,
      },
      {
        id: 'loss',
        label: t('materiality:revenueTable:loss:label'),
        minWidth: 170,
        format: (value: number) => t('materiality:revenueTable:loss:amount', { val: value }),
      },
      {
        id: 'downtime',
        label: t('materiality:revenueTable:downtime:label'),
        minWidth: 100,
        format: (value: number) => hoursToDayHourAbbrev(value),
      },
      {
        id: 'lifeline',
        label: t('materiality:revenueTable:lifeline:label'),
        minWidth: 170,
      },
    ],
    [t],
  );

  const csvHeaders: string[] | void = columns.map((column) => column.label);

  const rows: Data[] = React.useMemo(
    () =>
      stableSort(
        losses?.map(({ loss, location, lifeline }) =>
          createData(
            loss,
            location.resilienceStats?.[0],
            lifeline,
            t(`materiality:revenueTable:lifeline:${lifeline}`),
            location.address.formattedAddress,
            location.id,
          ),
        ) ?? [],
        getComparator(order, orderBy),
      ) as Data[],
    [losses, order, orderBy, t],
  );

  const formattedRowData = React.useMemo<string[][]>(() => {
    const rowData: string[][] = [];
    rows.forEach((row: Data) => {
      const rowLine: string[] = [];
      columns.forEach((column) => {
        let value: string | number | Lifeline = '';
        switch (column.id) {
          case 'loss':
            value = row.loss;
            rowLine.push(escapeDelimiter(column.format(value)));
            break;
          case 'downtime':
            value = row.downtime;
            rowLine.push(escapeDelimiter(column.format(value)));
            break;
          case 'lifeline':
            value = row.lifeline;
            rowLine.push(value as string);
            break;
          case 'locationAddress':
            value = row.locationAddress;
            rowLine.push(escapeDelimiter(value));
            break;
          default:
            value = '';
            rowLine.push(value);
            break;
        }
      });
      rowData.push(rowLine);
    });
    return rowData;
  }, [columns, rows]);

  if (isError) {
    return (
      <>
        <Typography variant="h3" className="u-marginbottom--20 u-margintop--20">
          {t('materiality:revenueTable:title')}
        </Typography>
        <Skeleton variant="rectangular" height={300} />
      </>
    );
  }

  const handleRequestSort = (_: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  if (isLoading) {
    return (
      <>
        <Typography variant="h3" className="u-marginy--20">
          {t('materiality:revenueTable:title')}
        </Typography>
        <Card className={container}>
          <TableContainer>
            <Table className={table} aria-label="RevenueTable">
              <RevenueTableHead
                columns={columns}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                <TableRow hover role="row" tabIndex={-1} key={1}>
                  {columns.map((column, index) => {
                    const key = `${index}_loading1}`;
                    return (
                      <TableCell key={key} align={column.align}>
                        <LinearProgress />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow hover role="row" tabIndex={-1} key={2}>
                  {columns.map((column, index) => {
                    const key = `${index}_loading2}`;
                    return (
                      <TableCell key={key} align={column.align}>
                        <LinearProgress />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow hover role="row" tabIndex={-1} key={3}>
                  {columns.map((column, index) => {
                    const key = `${index}_loading3`;
                    return (
                      <TableCell key={key} align={column.align}>
                        <LinearProgress />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow hover role="row" tabIndex={-1} key={4}>
                  {columns.map((column, index) => {
                    const key = `${index}_loading4`;
                    return (
                      <TableCell key={key} align={column.align}>
                        <LinearProgress />
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </>
    );
  }

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: 6 }}>
        <Typography variant="h3">{t('materiality:revenueTable:title')}</Typography>
        {formattedRowData?.length > 0 && (
          <CSVLink
            asyncOnClick
            data={formattedRowData}
            headers={csvHeaders}
            enclosingCharacter=""
            filename={filename}
          >
            <Button
              variant="outlined"
              color="primary"
              data-test-id="export-financial-losses"
              aria-label={t('materiality:export')}
            >
              {t('materiality:export')}
            </Button>
          </CSVLink>
        )}
      </Grid>

      <Card className={container}>
        <TableContainer>
          <Table className={table} aria-label="RevenueTable" data-test-id="RevenueTable">
            <RevenueTableHead
              columns={columns}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: Data, index) => {
                  return (
                    <TableRow
                      hover
                      role="row"
                      tabIndex={-1}
                      key={`${row.locationID}-${row.lifeline}`}
                      data-test-id={`RevenueTable_${row.locationID}_${row.lifeline}`}
                    >
                      {columns.map((column) => {
                        const key = `${index}_${column.id}`;
                        let value: string | number | Lifeline = '';
                        switch (column.id) {
                          case 'loss':
                            value = row.loss;
                            break;
                          case 'downtime':
                            value = row.downtime;
                            break;
                          case 'lifeline':
                            value = row.lifeline;
                            break;
                          case 'locationAddress':
                            value = row.locationAddress;
                            return (
                              <TableCell
                                key={key}
                                align={column.align}
                                data-test-id={`RevenueTableCell_${key}`}
                              >
                                <Link
                                  component={RouterLink}
                                  to={`/analysis/detail/${row.locationID}`}
                                  id={`RevenueTable_go_to_location_${key}`}
                                  data-test-id={`RevenueTable_go_to_location-${key}`}
                                >
                                  {value}
                                </Link>
                              </TableCell>
                            );
                          default:
                            value = '';
                            break;
                        }
                        return (
                          <TableCell
                            key={key}
                            align={column.align}
                            data-test-id={`RevenueTableCell_${key}`}
                          >
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={pagination}
        />
      </Card>
    </>
  );
};

const RevenueTable = React.memo(RevenueTableFC);
export default RevenueTable;
