import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Theme, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';

import Icon from '../../../CommonComponents/Icon/Icon';
import { SliceHazardType } from '../../../../__generated__/graphql';

export const HAZARD_ICON_NAMES: { [key: string]: string } = {
  [SliceHazardType.Flood]: 'flood',
  [SliceHazardType.Wind]: 'wind',
  [SliceHazardType.Earthquake]: 'earthquake',
  [SliceHazardType.Hurricane]: 'hurricane',
};

type HazardFilterButtonProps = {
  hazardType: SliceHazardType;
  historicalName?: string;
  handleClick: (e: React.MouseEvent) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const HazardIcon: React.FC<{ hazardType: SliceHazardType }> = ({ hazardType }) => (
  <Icon name={HAZARD_ICON_NAMES[hazardType]} variant="24" />
);

const HazardFilterButtonFC: React.FC<HazardFilterButtonProps> = ({
  hazardType,
  historicalName,
  handleClick,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Button
      onClick={handleClick}
      startIcon={HazardIcon && <HazardIcon hazardType={hazardType} />}
      endIcon={<ExpandMoreIcon />}
      data-test-id="HazardFilterButton"
      classes={{ text: classes.text }}
    >
      {historicalName ? (
        <div className="o-flexcol-container">
          <Typography variant="caption" align="left">
            {t('hazard:historical:selectorTag')}
          </Typography>
          <Typography className="u-overflow-ellipsis">{historicalName}</Typography>
        </div>
      ) : (
        <Typography className="u-overflow-ellipsis">
          {hazardType && t(`hazard:${hazardType?.toLowerCase()}`)}
        </Typography>
      )}
    </Button>
  );
};

const HazardFilterButton = React.memo(HazardFilterButtonFC);
HazardFilterButton.displayName = 'HazardFilterButton';
HazardFilterButtonFC.displayName = 'HazardFilterButtonFC';
export default HazardFilterButton;
