import * as React from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

import { Button, Card, Grow, Modal, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { App } from '../../../../../PlanningApp/AppConfig';
import { DARKBLUE } from '../../../../../util/productGlobals';
import { useGetOriginalLocationsFileQuery } from '../../../../../__generated__/graphql';
import useLocationsCsv from '../../../../../Hooks/useLocationsCsv';

const SECTION_KEY = 'settings:sections:locations:details:';

const useStyles = makeStyles((theme: Theme) => ({
  modalCard: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    maxWidth: '560px',
    background: DARKBLUE,
    padding: theme.spacing(4),
    '&:focus-visible': {
      outline: 'none',
    },
  },
  promptText: {
    marginBottom: theme.spacing(8),
  },
  cancelButton: {
    marginRight: theme.spacing(4),
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 0,
    flexShrink: 0,
    marginTop: 0,
    alignItems: 'center',
    width: '100%',
  },
  noBackupButton: {
    marginRight: theme.spacing(2),
  },
  downloadBackupLink: {
    display: 'none',
  },
}));

type DeleteCsvModalProps = {
  isOpen: boolean;
  toggleModal: () => void;
  deleteLocationsFile: () => void;
};

const DeleteCsvModal: React.FC<DeleteCsvModalProps> = ({
  isOpen,
  toggleModal,
  deleteLocationsFile,
}) => {
  const { t } = useTranslation();
  const {
    modalCard,
    promptText,
    cancelButton,
    buttonsContainer,
    noBackupButton,
    downloadBackupLink,
  } = useStyles();

  const handleDelete = React.useCallback(() => deleteLocationsFile(), [deleteLocationsFile]);
  const handleDownloadDelete = React.useCallback(() => {
    const link = document.getElementById('downloadOriginalLink');

    if (link) {
      link.click();
      deleteLocationsFile();
    }
  }, [deleteLocationsFile]);

  const { data, loading, error } = useGetOriginalLocationsFileQuery();
  const { isDataLoaded, originalFile, csvName } = React.useMemo(() => {
    const dataLoaded = Boolean(!loading && data);
    const file = dataLoaded ? data.user?.productSettings?.originalFile : null;
    let fileName = data?.user?.productSettings?.locationFile?.name;
    fileName = `${fileName ? fileName.slice(0, -4) : 'locations'}_${Date.now()}.csv`;

    return { isDataLoaded: dataLoaded, originalFile: file, csvName: fileName };
  }, [data, loading]);
  const { csvData, csvHeaders } = useLocationsCsv(originalFile);

  if (error) {
    App.error(`[DeleteCsvModal] ${error.message ?? 'Error retrieving original location file'}`);
  }

  return (
    <Modal
      className="o-flex-center-container"
      open={isOpen}
      closeAfterTransition
      data-test-id="DeleteCsvModal"
    >
      <Grow in={isOpen}>
        <Card className={modalCard}>
          <Typography
            className={promptText}
            variant="body1"
            component="div"
            data-test-id="areYouSureDelete"
          >
            {t(`${SECTION_KEY}deleteBackupMsg`)}
          </Typography>

          <div className={buttonsContainer}>
            <Button
              className={cancelButton}
              variant="outlined"
              color="primary"
              onClick={toggleModal}
              key="cancelButton"
              data-test-id="cancelButton"
            >
              {t(`${SECTION_KEY}buttonCancel`)}
            </Button>
            <div>
              <Button
                className={noBackupButton}
                variant="outlined"
                color="primary"
                onClick={handleDelete}
                key="noBackupButton"
                data-test-id="noBackupButton"
              >
                {t(`${SECTION_KEY}buttonNoBackup`)}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDownloadDelete}
                key="backupButton"
                data-test-id="backupButton"
                disabled={!isDataLoaded}
              >
                {t(`${SECTION_KEY}buttonBackup`)}
              </Button>
              {isDataLoaded && (
                <CSVLink
                  data={csvData}
                  headers={csvHeaders}
                  filename={csvName}
                  enclosingCharacter=""
                  id="downloadOriginalLink"
                  data-test-id="downloadOriginalLink"
                  className={downloadBackupLink}
                  tabIndex={-1}
                />
              )}
            </div>
          </div>
        </Card>
      </Grow>
    </Modal>
  );
};

export default DeleteCsvModal;
